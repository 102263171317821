import { ComboBox } from "@fluentui/react";
import React from "react";
import { Style } from "util";

export interface IComboOptions {
    key: string,
    text: string,
}

interface ComboProps {
    onChange?: any,
    onInputChange?: any,
    options: IComboOptions[],
    label?: string,
    style?: React.HTMLProps<Style>,
    disabled?: boolean,
    value?: any,
}

interface ComboState {
    items: any,
    InputValue?: string,
}

const defaultProps = {
    noOptionsText: 'Sin opciones',
    getOptionLabel: (option: any) => option.text,
};

class Combo extends React.Component<ComboProps, ComboState> {
    constructor(props: ComboProps) {
        super(props);
        // this.state = { :  };
    }
    render() {
        return (
            <>
                <ComboBox
                    {...defaultProps}
                    selectedKey={this.props.value}
                    options={this.props.options}
                    // getOptionLabel={(parameter: any) => parameter.title}
                    // limitTags={2}
                    style={{ width: '100%', minWidth: 100, marginTop: 0, ...this.props.style }}
                    id="debug"
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                />
            </>
        );
    }
}

export default Combo;