import { DetailsList, ICommandBarItemProps, IColumn, SelectionMode, DetailsListLayoutMode, IDetailsHeaderProps, MarqueeSelection, ISelection } from "@fluentui/react";
import { CSSProperties } from "styled-components";
import { IPagination } from "../../interfaces/base/ITable";
import { isObjectEmpty, _initialPagination, _onRenderHeader, _onRenderRow } from "../../utils/Helper/helper";
import { CustomCommandBar } from "../Generic/customCommandBar"
import SpinnerCenter from "../Generic/SpinnerCenter";
import '../Pagination/style.css';
import { PaginationFooter } from "../PaginationFooter/PaginationFooter";

const _rigthBar: ICommandBarItemProps[] = [];
const _leftBar: ICommandBarItemProps[] = [];
let _data: any[];
let _column: IColumn[];
const _style: CSSProperties = { padding: '20px' };
let _pagination: IPagination = _initialPagination();
let _selection: any = {};

export const TableBase = ({
    rigthBar = _rigthBar,
    leftBar = _leftBar,
    data = _data,
    column = _column,
    style = _style,
    isLoading = true,
    layout = DetailsListLayoutMode.fixedColumns,
    pagination = _pagination,
    selection = _selection
}) => {

    function onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    paddingTop: '0px',
                    textAlign: ''
                },
            },
        })
    }

    return (
        <>
            <div style={style}>
                {(rigthBar.length > 0 || leftBar.length > 0) &&
                    <CustomCommandBar item={leftBar} farItem={rigthBar} style={{ paddingLeft: '0px', paddingRight: '0px' }}></CustomCommandBar>
                }

                <div className="ms-Grid" dir="ltr" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <SpinnerCenter isLoad={isLoading} />

                            {!isLoading &&
                                <>
                                    {!isObjectEmpty(selection) ? <>
                                        {/* Table selection multiple */}
                                        <MarqueeSelection selection={selection}>
                                            <DetailsList
                                                items={data}
                                                compact={true}
                                                columns={column}
                                                selectionMode={SelectionMode.multiple}
                                                setKey='multiple'
                                                layoutMode={layout}
                                                isHeaderVisible={true}
                                                selection={selection}
                                                selectionPreservedOnEmptyClick={true}
                                                enterModalSelectionOnTouch={true}
                                                ariaLabelForSelectionColumn='Toggle selection'
                                                ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                                                checkButtonAriaLabel='select row'
                                                styles={_onRenderHeader}
                                                onRenderRow={_onRenderRow}
                                                onRenderDetailsHeader={onRenderDetailsHeader}
                                            />
                                        </MarqueeSelection>
                                    </> : <>
                                        {/* Table normal */}
                                        <DetailsList
                                            items={data}
                                            compact={true}
                                            columns={column}
                                            selectionMode={SelectionMode.none}
                                            setKey='Key'
                                            layoutMode={layout}
                                            isHeaderVisible={true}
                                            selectionPreservedOnEmptyClick={true}
                                            enterModalSelectionOnTouch={true}
                                            ariaLabelForSelectionColumn="Toggle selection"
                                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                            checkButtonAriaLabel="select row"
                                            styles={_onRenderHeader}
                                            onRenderRow={_onRenderRow}
                                            onRenderDetailsHeader={onRenderDetailsHeader}
                                        />
                                    </>}
                                    {/* <PaginationFooter data={data} pagination={pagination}></PaginationFooter> */}
                                    <div style={{
                                        position: "fixed",
                                        width: 'calc(100% - 235px)',
                                        bottom: 0,
                                    }}>
                                        <PaginationFooter data={data} pagination={pagination}></PaginationFooter>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}