/* eslint-disable react-hooks/exhaustive-deps */
import { IColumn, IComboBox, IComboBoxOption, ICommandBarItemProps, Icon, Stack, TooltipHost } from "@fluentui/react";
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { IItemComboBaseDTO, ISearch } from "../../../interfaces/base/IBase";
import { _initialFilter, stackTokensHorizontal } from "../../../utils/Helper/helper";
import PersonaService from "../../../services/PersonaService";
import { IPersona } from "../../../interfaces/IPersona";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { TableBase } from "../../../components/Table/TableBase";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import { useNavigate } from "react-router-dom";
import ThemeBase from "../../../utils/Theme/themeBase";

interface Helos5x2Props {

}
interface IModel {
    personaId: string | null
}
const INITIAL_MODULE: IModel = {
    personaId: null
}

const Helos5x2: FunctionComponent<Helos5x2Props> = () => {
    const navigate = useNavigate();

    const comboBoxRef = useRef<IComboBox>(null);
    const _openOptionPersona = useCallback(
        () => comboBoxRef.current?.focus(true),
        []
    );

    const [filterPersona, setFilterPersona] = useState<ISearch>(_initialFilter);
    const [persona, setPersona] = useState<IItemComboBaseDTO[]>([]);
    const [personaIsLoad, setpersonaIsLoad] = useState(false);
    const [personaOptionSelect, setpersonaOptionSelect] =
        useState<IComboBoxOption>();

    const [model, setmodel] = useState<IModel>(INITIAL_MODULE);

    const [column, setColumn] = useState<IColumn[]>([]);
    const [data, setData] = useState<any[]>([]);

    const _onInputPersona = (e: React.KeyboardEvent<IComboBox>) => {
        _openOptionPersona();
        let { value } = e?.target as HTMLInputElement;
        console.log(value);
        setmodel(INITIAL_MODULE);
        if (value.length > 2) {
            setFilterPersona({
                ...filterPersona,
                search: value,
            });
        }
    };
    /* Api */
    const dataPersona = () => {
        PersonaService.listarPersonaMMG(filterPersona)
            .then((res) => {
                /* v2 persona */
                let d: IPersona[] = res.data;
                let t: IItemComboBaseDTO[] = d.map((x) => {
                    return {
                        key: x.id,
                        text: `${x.nroDOI}  | ${x.persona} | ${x.tipo}`,
                    };
                });
                setPersona(t);
                setpersonaIsLoad(true);
            })
            .catch((e: Error) => {
                alert(e.message);
            });
    };

    const _onChangePersona = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string
    ) => {
        if (event !== undefined) {
            if (option !== undefined) {
                setpersonaOptionSelect(option);
            }
        }
    };

    const _onBlurPersona = () => {
        if (model?.personaId === "" || model?.personaId === undefined) {
            setFilterPersona({
                ...filterPersona,
                search: "",
            });
        }
    };

    const NoDataOptions = (sizeData: number = 0) => {
        return (
            <>
                {sizeData === 0 && (
                    <div style={{ textAlign: "center", padding: 20 }}>
                        Persona no encontrada en teseo
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        TitleColumn()
    }, []);

    useEffect(() => {
        if (filterPersona.search != null) {
            dataPersona();
        }
    }, [filterPersona]);

    useEffect(() => {
        if (personaOptionSelect)
            setmodel({
                ...model,
                personaId: personaOptionSelect.key.toString(),
            });
    }, [personaOptionSelect]);

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => navigate(`${ConfigHelper.urlBase()}/configuraciones`),
            },
            {
                ...IconHelper.ICON_ADD,
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: setup,
            },
        ];
        return _items;
    };
    const TitleColumn = () => {
        let col: IColumn[] = [
            {
                key: "column3",
                name: "Días Trabajo",
                fieldName: "trabajo",
                minWidth: 70,
                maxWidth: 80,
                isResizable: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column4",
                name: "Días Descanso",
                fieldName: "descanso",
                minWidth: 70,
                maxWidth: 80,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column5",
                name: "Turno Día",
                fieldName: "dia",
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: any) => {
                    return <span>{`${item.dia ? "Si" : "No"}`}</span>;
                },
            },
            {
                key: "column6",
                name: "Turno Noche",
                fieldName: "noche",
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: any) => {
                    return <span>{`${item.noche ? "Si" : "No"}`}</span>;
                },
            },
            {
                key: "column7",
                name: "Fecha Creación",
                fieldName: "creacion",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column8",
                name: "Opciones",
                fieldName: "",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: any) => (
                    <>
                        <Stack
                            horizontalAlign="end"
                            horizontal
                            tokens={stackTokensHorizontal}
                        >
                            <>
                                <TooltipHost content={`${item.isPax ? 'Lista Staff' : 'Desactivado'}`}>
                                    <Icon
                                        iconName="SecurityGroup"
                                        style={{
                                            cursor: "pointer",
                                            color: "#e1dfdd",
                                        }}
                                        className={ThemeBase.classes.iconFont}
                                    />
                                </TooltipHost>
                            </>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    };


    /* Function */
    const setup = () => {
        dataPersonasPax();
    };
    const dataPersonasPax = () => {
        setData([]);
      };

    return (
        <>
            <HeadingBar
                title="Guardias"
                subTitle="Gestión de guardias"
                searchShow={false}
            ></HeadingBar>

            <TableBase
                leftBar={_leftBar()}
                column={column}
                data={data}
                isLoading={false}
            ></TableBase>


            {/* <ComboBox
                componentRef={comboBoxRef}
                label="Selecionar Persona"
                allowFreeform
                autoComplete="off"
                useComboBoxAsMenuWidth
                calloutProps={{ directionalHintFixed: true }}
                options={persona}
                // errorMessage={empresa}
                // onKeyUp={_onUpPersona}
                onInput={_onInputPersona}
                selectedKey={model?.personaId}
                onChange={_onChangePersona}
                onBlur={_onBlurPersona}
                onRenderLowerContent={() => NoDataOptions(persona.length)}
                required
            /> */}
        </>
    );
}

export default Helos5x2;