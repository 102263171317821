import { Stack, FontIcon, IStackStyles } from '@fluentui/react';
import ThemeBase from "../../utils/Theme/themeBase"


const stackStyles: IStackStyles = {
    root: {
        //   background: "yellow",
        background: '#ffff',

    },
};

export const State = ({ estado = '', colorEstado = '', width = 100 }) => {


    return (
        <>
            {/* <Stack horizontal styles={{
                root: {
                    background: '#ffff',
                    width: width,
                },
            }} tokens={{
                childrenGap: 5,
                padding: 2,
            }}>
                <span style={ThemeBase.estadoStyleDos}>
                    <FontIcon
                        iconName="LocationDot"
                        style={{ fontSize: 20, color: colorEstado }}
                    />
                    <span style={ThemeBase.estadoStyleDos}>{estado}</span>
                </span>
            </Stack> */}

            <Stack horizontal horizontalAlign="start" styles={stackStyles}>
                <FontIcon
                    iconName="LocationDot"
                    style={{ fontSize: 18, color: colorEstado, marginLeft: "0px" }}
                />
                <span style={{ marginLeft: '4px' }}>{estado}</span>
            </Stack>
        </>
    )
}