import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import ThemeTitleBase from "../../utils/Theme/themeBase";

function SpinnerCenter({ isLoad = false, message = 'Obteniendo Datos', left = '45%', height = '100vh' }) {
    return(
        <>
        {isLoad && 
        <Stack tokens={ThemeTitleBase.stackTokens}>
            <Spinner style={{
                display: 'flex',
                top: '50%',
                left: left,
                height: height,
                zIndex: '1000',
                color: 'yellow'
            }} size={SpinnerSize.medium} label={`${message}...`}/>
        </Stack>}
        </>
    )
  }
  
  export default SpinnerCenter;