import { IColumn, ICommandBarItemProps } from "@fluentui/react";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TableBase } from "../../../../components/Table/TableBase";
import { IException } from "../../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../../interfaces/base/ITable";
import { IFilterProgramacionAdicional } from "../../../../interfaces/filter/IFilterProgramacion";
import { IProgramacion } from "../../../../interfaces/IProgramacion";
import PersonaService from "../../../../services/PersonaService";
import { ConfigHelper } from "../../../../utils/Helper/configHelper";
import { _initialFilter, _initialPagination, splitExceptionDetail } from "../../../../utils/Helper/helper";
import { IconHelper } from "../../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../../utils/Helper/tableHelper";
import ThemeBase from "../../../../utils/Theme/themeBase";
import { ColumnAdicional } from "../../recursivo/columnAdicional";

export const ProgAdicionalLogistica = ({ actividad = 0 }) => {
    const navigate = useNavigate();

    const [hidden, setHidden] = useState(false);
    const [filter, setFilter] = useState<IFilterProgramacionAdicional>({
        ..._initialFilter(),
        actividad: actividad,
    });
    const [data, setData] = useState<IDataCollection<IProgramacion>>(_initialDataCollection);
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    const selection = new Selection({
        onSelectionChanged: () => {
            /* Nada */
        },
        getKey: (item: any) => item.programacionPersonaBusId
    });

    /* Hook */
    useEffect(() => {
        reloadInitial();
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    /* Api */
    const dataProgramacion = (param = filter) => {
        setData(_initialDataCollection);

        PersonaService.adicionalLogistica(param).then(res => {
            setData(res.data);
            setTotal(res.data.total);
            setHidden(true);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        });
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setColumn(ColumnAdicional(`${ConfigHelper.urlBase()}/adicionales/aprobador`, navigate));
        dataProgramacion(param);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial()
            },
        ];
        return _items;
    }

    /* Event Tab */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterProgramacionAdicional = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    return (
        <>
            <TableBase leftBar={_leftBar()} column={column} data={data.items} isLoading={!hidden} pagination={pager} /* selection={selection} */ style={ThemeBase.notPaddingTableBase}></TableBase>
        </>
    )
}