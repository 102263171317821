import { ICreateAccesoPersona } from "../../../interfaces/IAccesoPersona";

const initialTurno = [
    { key: "D", text: "Turno Día" },
    { key: "N", text: "Turno Noche" },
    { key: "D/N", text: "Turno Día / Noche" },
];

const initialTipoTemporada = [
    { key: "01", text: "Seca" },
    { key: "02", text: "Húmeda" },
];

const initialUbigeo = {
    departamentoId: "",
    provinciaId: null,
    distritoId: null,
};

const initialForm: ICreateAccesoPersona = {
    programacionId: "",
    personaId: "",
    prioridad: 0,
    requiereBus: true,
    ubigeoId: null,
    email: "",
    celular: "",
    direccion: "",
    paraderoId: "",
    ubicacionCuarentenaId: null,
    ubicacionCampamentoId: "",
    turno: "",
    transporteId: "",
    hotelId: null,
    usuarioRegistraId: null,
    esTitular: true,
    lugarMovilizacionId: null,
    centroCostoId: null,
};

export const AccesoGeneric = {
    initialTurno,
    initialUbigeo,
    initialForm,

    /* Viaje */
    initialTipoTemporada,
}