import { ISearch } from "../interfaces/base/IBase";
import { IFilterProgramacionBus } from "../interfaces/filter/IFilterProgramacionBus";
import { Ifilter } from "../interfaces/IFilter";
import { ICreateProgramacion, IEmailHeloValido } from "../interfaces/IProgramacion";
import { fetchMovilizacion } from "./http-common";

const urlTipoProgramacion = 'TipoProgramacion';
const urlProgramacion = 'AccesoProgramadoProgramacion';
const urlAccesoProgramadoProgramacionBus = 'AccesoProgramadoProgramacionBus';
const urlMotivoEliminacion = 'MotivoEliminacion';
const urlLugarMovilizacion = 'LugarMovilizacion';
const urlProgramacionBus = 'AccesoProgramadoBus';

const reporteProgramacion = () => {
  return fetchMovilizacion.get(

    `AccesoProgramadoProgramacion/ReporteProgramaciones`,
    {
      responseType: "blob"
    }
  );
}

const allTipoProgramacion = () => {
  return fetchMovilizacion.get(urlTipoProgramacion);
}

/* Personal asignado */
const personalAsignadoEmpresaTransportista = (id: string, filter: Ifilter) => {
  return fetchMovilizacion.get(`${urlAccesoProgramadoProgramacionBus}/ListaPersonasBus?ProgramacionId=${id}`, { params: filter });
}

/* Crear bajada */
const crearBajada = (data: ICreateProgramacion) => {
  return fetchMovilizacion.post(`${urlProgramacion}/Bajada`, data)
}

/* Motivo Eliminacion */
const allMotivoEliminacion = (codigo: number) => {
  return fetchMovilizacion.get(`${urlMotivoEliminacion}/${codigo}`);
}

/* Lugar Movilizacion */
const allLugarMovilizacion = () => {
  return fetchMovilizacion.get(`${urlLugarMovilizacion}`);
}
const allLugarMovilizacion1 = (filter: ISearch) => {
  return fetchMovilizacion.get(`${urlLugarMovilizacion}`, { params: filter });
}

/* Personal Abordado */
const allAbordado = (filter: IFilterProgramacionBus) => {
  return fetchMovilizacion.get(`${urlProgramacionBus}`, { params: filter });
}

/* Eliminar programacion */
const deleteProgramacion = (programacionId: string) => {
  return fetchMovilizacion.delete(`${urlProgramacion}/${programacionId}`);
}

/* Chart */
const infoProgramacionChart = (id: string) => {
  return fetchMovilizacion.get(`${urlProgramacion}/Chart/${id}`);
}

/* Email Cierre Agencia Vuelo */
const emailCierreAgenciaVuelo = (data: IEmailHeloValido) => {
  return fetchMovilizacion.post(`${urlProgramacion}/Email/CierreAgenciaViaje`, data)
}


const emailFechaCierre = () => {
  return fetchMovilizacion.post(`${urlProgramacion}/Email/CierreMovilizacion`)
}

const ubigeosProgramacion = (ProgramacionId: string) => {
  return fetchMovilizacion.get(`${urlProgramacion}/UbigeosProgramacion`, { params: { ProgramacionId: ProgramacionId } })
}

const ProgramacionService = {
  reporteProgramacion,
  allTipoProgramacion,

  personalAsignadoEmpresaTransportista,

  /* Crear solo bajada */
  crearBajada,

  /* Motivo Eliminacion */
  allMotivoEliminacion,

  /* Lugar Movilizacion */
  allLugarMovilizacion,
  allLugarMovilizacion1,

  /* Personal Aboradado */
  allAbordado,

  /* Delete Programacion */
  deleteProgramacion,

  /* Chart */
  infoProgramacionChart,

  /* Email */
  emailCierreAgenciaVuelo,
  emailFechaCierre,

  ubigeosProgramacion
}

export default ProgramacionService;