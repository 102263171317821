import { IFilterRegla } from "../interfaces/filter/IFilterRegla";
import { Ifilter } from "../interfaces/IFilter";
import { ICreateRegla } from "../interfaces/IRegla";
import { fetchMovilizacion } from "./http-common";

const urlRegla = 'Regla';

const listarPorFiltro = (filter: IFilterRegla) => {
    return fetchMovilizacion.get(`${urlRegla}/ListarPorFiltro`, { params: filter });
}

const all = (filter: Ifilter) => {
    return fetchMovilizacion.get(`${urlRegla}`, { params: filter });
}

const crearRegla = (data: ICreateRegla) => {
    return fetchMovilizacion.post(`${urlRegla}`, data);
}

const elminarRegla = (reglaId: string) => {
    return fetchMovilizacion.delete(`${urlRegla}/${reglaId}`);
}

export const ReglaService = {
    listarPorFiltro,
    all,
    crearRegla,
    elminarRegla,
}