import { ComboBox, FontIcon, Label, Stack, TextField } from "@fluentui/react";
import { GenButtonAdd } from "../../components/Generic/botonesCrear";
import './hotelcss.css';

import { getTheme } from '@fluentui/react';
import Combo from "../../components/Generic/combo";
import { useEffect, useState } from "react";
import HoteleriaService from "../../services/HoteleriaService";
import { useNavigate } from "react-router";
import { IHotel } from "../../interfaces/IHotel";
import { ConfigHelper } from "../../utils/Helper/configHelper";

const theme = getTheme();


const INITIAL_MODEL = {
    contratoId: '-',
    ubicacionId: '-',
    codigo: '-',
    nombre: '-',
    direccion: '-',
}
/* {
    "contratoId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "ubicacionId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "codigo": "2056",
    "nombre": "Hotel 1",
    "direccion": "Av. direccion 123"
  } */

interface AgregarHotelProps {

}
const linkCancelar = `${ConfigHelper.urlBase()}/hoteles`
export const AgregarHotel = () => {
    const [modelHotel, setmodelHotel] = useState<IHotel>(INITIAL_MODEL);

    /*    useEffect(() => {
           console.log(modelHotel)
       }, [modelHotel]); */

    function _onChangeTextFild(e: any, newValue: any) {
        console.log(e.target.name)
        setmodelHotel({ ...modelHotel, [e.target.name]: newValue })
        console.log(newValue, modelHotel)
    }

    const _onChangeLatitud = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setmodelHotel({
                ...modelHotel,
                latitud: newValue,
            });
        }
    }

    const _onChangeLongitud = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setmodelHotel({
                ...modelHotel,
                longitud: newValue,
            });
        }
    }

    function enviarHotel() {
        HoteleriaService.postHotel(modelHotel).then(() => {
            alert('Enviado')
            goRBack()
        }).catch((e: Error) => {
            alert("Datos no registrados")
            console.log(e)
        })
    }

    let navigate = useNavigate();
    function goRBack() {
        navigate(`${ConfigHelper.urlBase()}/hoteles`)
    }

    return (
        <div>

            <div className="panelGeneral"  >
                <div style={{ minWidth: '600px', boxShadow: theme.effects.elevation8, margin: '20px', padding: '20px' }}>
                    <div className="iconoCrear"  >
                        <FontIcon
                            aria-label="Compass"
                            iconName="Hotel"
                            style={{ fontSize: '50pt' }}
                        />
                        <Label >Crear Hotel</Label>
                        <Label style={{ fontSize: '9pt', color: 'gray' }} >Complete los campos para crear un hotel</Label>
                    </div>

                    <div style={{ height: '3px', backgroundColor: '#faf9f8' }}>
                    </div>

                    <div style={{ margin: '20px' }}>
                        <div style={{ marginBottom: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '20px' }}>
                            <Combo
                                label="Ubicacion"
                                options={[
                                    { key: 'A', text: 'Lima' },
                                    { key: 'B', text: 'Cajamarca' },
                                ]}
                                onChange={(event: any, value: any) => {
                                    if (value != null)
                                        setmodelHotel({ ...modelHotel, ubicacionId: '3fa85f64-5717-4562-b3fc-2c963f66afa6' })
                                }}
                            />
                            <Combo
                                label="Contrato"
                                options={[
                                    { key: 'A', text: 'op 1' },
                                    { key: 'B', text: 'op 2' },
                                ]}
                                onChange={(event: any, value: any) => {
                                    if (value != null)
                                        setmodelHotel({ ...modelHotel, contratoId: '3fa85f64-5717-4562-b3fc-2c963f66afa6' })
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: '25px', }}>
                            <TextField
                                name="nombre"
                                placeholder="Nombre"
                                onChange={_onChangeTextFild}
                                autoComplete='off'
                                underlined
                                required
                            />
                        </div>
                        <div style={{ marginBottom: '25px', }}>
                            <TextField
                                name="codigo"
                                // placeholder="Url Direccion:"
                                placeholder="Codigo"
                                onChange={_onChangeTextFild}
                                autoComplete='off'
                                underlined
                                required
                            />
                        </div>
                        <div style={{ marginBottom: '25px', }}>

                            <TextField
                                name="direccion"
                                placeholder="Direccion"
                                onChange={_onChangeTextFild}
                                autoComplete='off'
                                underlined
                                required
                            />
                        </div>
                        <div style={{ marginBottom: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '20px' }}>
                            <TextField placeholder="Longitud" underlined onChange={_onChangeLongitud} />
                            <TextField placeholder="Latitud" underlined onChange={_onChangeLatitud} />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <GenButtonAdd
                            calcelar={() => { alert("cancelar") }}
                            href1={linkCancelar}
                            text='GUARDAR'
                            cancelar={() => { goRBack() }}
                            guardar={() => { enviarHotel() }}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AgregarHotel;