import React from "react";
import { Dialog, getTheme, ProgressIndicator, FontSizes } from '@fluentui/react';

import { DialogType } from '@fluentui/react/lib/Dialog';
import { NeutralColors } from '@fluentui/theme';

const theme = getTheme();

const _hideDialog: boolean = true;
const _toggleHideDialog = () => { };
const _title: string = "";
const _subText: string = "";
const _iconName: string | undefined = "";
const _onclickButtonPrimary = (param) => { };
const _nameButtonPrimary: string = "";
const _onclickButtonDefault = () => { };
const _nameButtonDefault: string = "";


const DialogBaseWait = ({
  hideDialog = _hideDialog,
  toggleHideDialog = _toggleHideDialog,
  title = _title,
  subText = _subText,
  // iconName = _iconName,
  // onclickButtonPrimary = (param: any) => _onclickButtonPrimary(parametro)
  onclickButtonPrimary = (parametro) => _onclickButtonPrimary(parametro)

}) => {

  const myElement = <div className="LayoutPage-demoBlock">
    <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size14 }}>Enviando Información</div>
  </div>

  return (

    <Dialog
      hidden={hideDialog}
      onDismiss={toggleHideDialog}
      dialogContentProps={{
        // title: "Enviando Información",
        title: myElement,
        type: DialogType.normal,
        subText: "Por favor espere un momento",
      }}
    >
      <ProgressIndicator barHeight={5} />
    </Dialog>
  );
}

export { DialogBaseWait };