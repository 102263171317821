import React, { useEffect, useState } from "react";
import { IDispositivoPersonaId } from "../interfaces/base/IBase";
import DispositivoService from "../services/dispositivoService";

export const usePerfilPersonaDIspositivo = (personaId, dismissDialog) => {
  const [loadingAutorizar, setLoadingAutorizar] = useState(true)
  const [
    dataUsuarioHistorialDispositivos,
    setDataUsuarioHistorialDispositivos,
  ] = useState<IDispositivoPersonaId[]>([]);

  useEffect(() => {
    if (personaId !== null) {
      getDataDispositivoPersonaId();
    }
  }, [personaId]);

  function getDataDispositivoPersonaId() {
    DispositivoService.LitarDispositivoPersonaID(personaId).then((res) => {
      if (res.status == 200) {
        setDataUsuarioHistorialDispositivos(res.data.items);
      }
    });
  }

  function autorizarDispositivo() {
    setLoadingAutorizar(false)
    const objetoAutorizar = {
      personaId: personaId,
      isSendEmail: true,
    };
    DispositivoService.AutorizarDispositivo(objetoAutorizar)
      .then((res) => {
        if (res.status == 200) {
          getDataDispositivoPersonaId();
          setLoadingAutorizar(true)
          dismissDialog()
        }
      })
      .catch((error) => {
        console.log(error.message);
        getDataDispositivoPersonaId();
        setLoadingAutorizar(true)
        dismissDialog()
      });
  }
  return {
    dataUsuarioHistorialDispositivos,
    getDataDispositivoPersonaId,
    autorizarDispositivo,
    loadingAutorizar
  };
};
