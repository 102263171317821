import { AuthProvider } from "./auth/provider/auth-provider";
import { ContentRouter } from "./routes/content-router";

export const App = () => {
  return (
    
    <AuthProvider>
      <ContentRouter />
    </AuthProvider>
  );
};
