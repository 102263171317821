import { ComboBox, DialogType, IColumn, IComboBox, IComboBoxOption, ICommandBarItemProps, Icon, IconButton, Modal, Persona, PersonaSize, Stack, TextField, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DialogBase } from "../../components/Dialog/DialogBase";
import { Card } from "../../components/Generic/card";
import { CustomCommandBar } from "../../components/Generic/customCommandBar"
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { TableBase } from "../../components/Table/TableBase";
import { ICard } from "../../interfaces/base/IBase";
import { IFilterUsuarioContratista } from "../../interfaces/filter/IFilterContrato";
import { ICreateUsuarioContratista, IDetalleContrato, IEliminarUsuarioContratista, IUsuarioContratista } from "../../interfaces/IContrato";
import { baseImageUrl, optionSexo, splitExceptionDetail, stackTokens, _initialFilter } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { useBoolean } from '@fluentui/react-hooks';
import { ModalHelper } from "../../utils/Helper/modalHelper";
import { ButtonForm } from "../../components/Generic/buttonForm";
import ContratoService from "../../services/contratoService";
import { ConfigHelper } from "../../utils/Helper/configHelper";

export const DetalleContrato = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    const [data, setData] = useState<IDetalleContrato>(null!);
    const [filterUsuarioContratista, setFilterUsuarioContratista] = useState<IFilterUsuarioContratista>(null!);
    const [usuarioContratista, setUsuarioContratista] = useState<IUsuarioContratista[]>([]);
    const [columnUsuarioContratista, setColumnUsuarioContratista] = useState<IColumn[]>([]);

    /* Hidden */
    const [hiddenUsuarioContratista, setHiddenUsuarioContratista] = useState(false);

    /* Dialog */
    const [hideActualizarClave, { toggle: toggleHideActualizarClave }] = useBoolean(true);
    const [selUsuarioContratistaId, setSelUsuarioContratista] = useState<string>(null!);
    const [hideEliminarContratista, { toggle: toggleEliminarContratista }] = useBoolean(true);

    /* Modal */
    const [isOpenRegistrarUsuario, { setTrue: showRegistrarUsuario, setFalse: hideRegistrarUsuario }] = useBoolean(false);
    const [formRegistrar, setFormRegistrar] = useState<ICreateUsuarioContratista>(null!);

    /* Hook */
    useEffect(() => {
        if (id != undefined) {
            dataInfo();

            setTitleUsuarioContratista();

            setFilterUsuarioContratista({
                ..._initialFilter(),
                contratoId: id,
            });
            
            setFormRegistrar({
                ...formRegistrar,
                contratoId: id,
            })
        }
    }, [id]);

    useEffect(() => {
        dataUsuarioContratista();
    }, [filterUsuarioContratista])

    /* Api */
    const dataInfo = () => {
        if (id != undefined) {
            ContratoService.detalleContrato(id).then(res => {
                setData(res.data);
            }).catch((err) => {
                alert(err.message);
            })
        }
    }

    const dataUsuarioContratista = () => {
        setUsuarioContratista([]);

        if (id != undefined) {
            // TeseoContratoService.usuarioContratistaByContrato(filterUsuarioContratista).then(res => {
            //     setUsuarioContratista(res.data.data);
            //     setHiddenUsuarioContratista(true);
            // }).catch((err) => {
            //     alert(err.message);
            // })

            ContratoService.allContratistas(filterUsuarioContratista).then(res => {
                setUsuarioContratista(res.data);
                setHiddenUsuarioContratista(true);
            }).catch((err) => {
                alert(err.message);
            })
        }
    }

    const actualizarClaveContratista = () => {
        // TeseoContratoService.actualizarClaveContratista(selUsuarioContratistaId).then(res => {
        //     if (res.status == 200) {
        //         alert('se actualizó correctamente');
        //         reloadUsuarioContratista();
        //         toggleHideActualizarClave();
        //     }
        // }).catch((err) => {
        //     alert(err.message);
        // })
    }

    const eliminarContratista = () => {
        if (id != undefined) {
            let d: IEliminarUsuarioContratista = {
                contratoId: id,
                usuarioId: selUsuarioContratistaId,
            };

            // TeseoContratoService.eliminarUsuarioContratista(d).then(res => {
            //     if (res.status == 200) {
            //         alert('se eliminó correctamente');
            //         reloadUsuarioContratista();
            //         toggleEliminarContratista();
            //     }
            // }).catch((err) => {
            //     alert(err.message);
            // })
        }
    }

    const registrarUsuarioContratista = () => {
        ContratoService.addUsuarioContratista(formRegistrar).then((res) => {
            if (res.status == 200) {
                reloadUsuarioContratista();
                hideRegistrarUsuario();
            }
        }).catch((e) => {
            alert(splitExceptionDetail(e));
        })
    }

    /* Function */
    const _goBack = () => {
        navigate(`${ConfigHelper.urlBase()}/contrato`);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => _goBack(),
            },
        ];

        return _items;
    }

    const _leftBarUsuarioContratista = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_ADD,
                onClick: () => showRegistrarUsuario(),
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadUsuarioContratista(),
            },
        ];

        return _items;
    }

    const dataCard = () => {
        let d: ICard = {
            style: {
                paddingTop: '2%',
                paddingLeft: '15px',
            },
            card: [
                {
                    title: 'Contratistas',
                    icon: 'AddGroup',
                }
            ]
        }

        return d;
    }

    const setTitleUsuarioContratista = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Nombre',
                fieldName: 'nombreSuper',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Correo Electrónico',
                fieldName: 'correo',
                minWidth: 160,
                maxWidth: 170,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Celular',
                fieldName: 'celular',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Estado',
                fieldName: 'estado',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Fecha Inicio',
                fieldName: 'fechaInicio',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Registrado Por',
                fieldName: 'registradoPor',
                minWidth: 120,
                maxWidth: 130,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Opciones',
                fieldName: 'opciones',
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                onRender: (item: IUsuarioContratista) => (
                    <>
                        <TooltipHost content={`Cambiar Contraseña`}>
                            <Icon iconName='AuthenticatorApp'
                                style={{ cursor: 'pointer' }}
                                className={ThemeBase.classes.iconFont}
                                onClick={() => {
                                    setSelUsuarioContratista(item.id);
                                    toggleHideActualizarClave();
                                }}
                            />
                        </TooltipHost>

                        <TooltipHost content={`Eliminar`}>
                            <Icon iconName='Delete'
                                style={{ cursor: 'pointer' }}
                                className={ThemeBase.classes.iconFont}
                                onClick={() => {
                                    setSelUsuarioContratista(item.id);
                                    toggleEliminarContratista();
                                }}
                            />
                        </TooltipHost>
                    </>
                ),
            },
        ];

        setColumnUsuarioContratista(col);
    }

    const reloadUsuarioContratista = () => {
        setHiddenUsuarioContratista(false);
        dataUsuarioContratista();
    }

    /* Event */
    const _onChangeText = (type: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                switch (type) {
                    case 'nombreUsuario':
                        setFormRegistrar({
                            ...formRegistrar,
                            nombres: newValue,
                        })
                        break;
                    case 'email':
                        setFormRegistrar({
                            ...formRegistrar,
                            correo: newValue,
                        })
                        break;
                    case 'celular':
                        setFormRegistrar({
                            ...formRegistrar,
                            celular: newValue,
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'sexo':
                        setFormRegistrar({
                            ...formRegistrar,
                            sexo: String(option.key),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return (
        <>
            <HeadingBar title={data ? data.codigo : ''} subTitle='Código del Contrato' searchShow={false}></HeadingBar>

            <div className={ThemeBase.classes.base}>
                <CustomCommandBar item={_leftBar()}></CustomCommandBar>
            </div>

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4" style={{
                        padding: '2%',
                    }}>
                        <div className="ms-depth-8" style={{
                            padding: '15px',
                        }}>
                            <div className="ms-Grid-row">
                                <div className="ms-fontSize-16" style={{
                                    color: ThemeBase.theme.palette.themePrimary,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>Información</div>
                                <br />

                                <Stack tokens={stackTokens}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <div className="ms-fontSize-14 ms-fontWeight-semibold">Tipo Contrato</div>
                                                <div className="ms-fontSize-12">{data ? data.contratoTipo : ''}</div>
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <div className="ms-fontSize-14 ms-fontWeight-semibold">Empresa</div>
                                                <div className="ms-fontSize-12">{data ? data.empresa : ''}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <div className="ms-fontSize-14 ms-fontWeight-semibold">Fecha Inicio</div>
                                                <div className="ms-fontSize-12">{data ? data.fechaInicio : ''}</div>
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <div className="ms-fontSize-14 ms-fontWeight-semibold">Fecha Vencimiento</div>
                                                <div className="ms-fontSize-12">{data ? data.fechaVencimiento : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4" style={{
                        padding: '2%',
                    }}>
                        <div className="ms-depth-8" style={{
                            padding: '15px',
                        }}>
                            <div className="ms-Grid-row">
                                <div className="ms-fontSize-16" style={{
                                    color: ThemeBase.theme.palette.themePrimary,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>Contacto Comercial</div>
                                <br />

                                <Stack tokens={stackTokens}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <div className="ms-fontSize-14 ms-fontWeight-semibold">Nombre</div>
                                                <div className="ms-fontSize-12">{data ? data.contactoNombre : ''}</div>
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <div className="ms-fontSize-14 ms-fontWeight-semibold">Correo</div>
                                                <div className="ms-fontSize-12">{data ? data.contactoCorreo : ''}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <div className="ms-fontSize-14 ms-fontWeight-semibold">Celular</div>
                                                <div className="ms-fontSize-12">{data ? data.contactoCelular : ''}</div>
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <div className="ms-fontSize-14 ms-fontWeight-semibold">Fijo</div>
                                                <div className="ms-fontSize-12">{data ? data.contactoFijo : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4" style={{
                        padding: '2%',
                    }}>
                        <div className="ms-depth-8" style={{
                            padding: '15px',
                        }}>
                            <div className="ms-Grid-row">
                                <div className="ms-fontSize-16" style={{
                                    color: ThemeBase.theme.palette.themePrimary,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>Comprador</div>
                                <br />

                                {data && data.compradores.map(function (item) {
                                    return <>
                                        <Stack tokens={stackTokens}>
                                            <Persona
                                                imageUrl={baseImageUrl}
                                                text={item.nombre}
                                                size={PersonaSize.size24}
                                                imageAlt={item.nombre}
                                            />
                                        </Stack>
                                    </>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <Card data={dataCard()}></Card>

                <TableBase leftBar={_leftBarUsuarioContratista()} column={columnUsuarioContratista} data={usuarioContratista ? usuarioContratista : []} isLoading={!hiddenUsuarioContratista}></TableBase>

                {/* Cambiar Contraseña */}
                <DialogBase isHidden={hideActualizarClave} subTitle={'La contraseña será cambiada permanentemente'} tipo={DialogType.normal} toggleHidden={toggleHideActualizarClave} dependency={[]} confirm={actualizarClaveContratista}></DialogBase>

                {/* Eliminar usuario contratista */}
                <DialogBase isHidden={hideEliminarContratista} subTitle={'El usuario será eliminado permanentemente'} tipo={DialogType.normal} toggleHidden={toggleEliminarContratista} dependency={[]} confirm={eliminarContratista}></DialogBase>

                {/* Modal agregar usuario */}
                <Modal
                    isOpen={isOpenRegistrarUsuario}
                    onDismiss={hideRegistrarUsuario}
                    isBlocking={false}
                    containerClassName={ModalHelper.modalStyle.container}
                >
                    <form>
                        <div className={ModalHelper.modalStyle.header}>
                            <span id={''}>Asignar Usuario Contratista</span>
                            <IconButton
                                styles={ModalHelper.modalIconButtonStyles}
                                iconProps={ModalHelper.modalCancelIcon}
                                ariaLabel='Close popup modal'
                                onClick={hideRegistrarUsuario}
                            />
                        </div>

                        <div className={ModalHelper.modalStyle.body} style={{ minWidth: 500 }}>
                            <Stack tokens={stackTokens}>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <TextField
                                                label='Nombre Usuario'
                                                placeholder='Nombre Usuario'
                                                value={formRegistrar ? formRegistrar.nombres : ''}
                                                onChange={(e, o) => _onChangeText('nombreUsuario', e, o)}
                                                autoComplete="off" />
                                        </div>

                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <TextField
                                                label='Email'
                                                placeholder='Email'
                                                value={formRegistrar ? formRegistrar.correo : ''}
                                                onChange={(e, o) => _onChangeText('email', e, o)}
                                                autoComplete="off" />
                                        </div>
                                    </div>
                                </div>

                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <ComboBox
                                                label='Sexo'
                                                autoComplete='off'
                                                useComboBoxAsMenuWidth
                                                calloutProps={{ directionalHintFixed: true }}
                                                options={optionSexo()}
                                                selectedKey={formRegistrar ? formRegistrar.sexo : ''}
                                                onChange={(e, o) => _onChangeCombo('sexo', e, o)}
                                                required
                                            />
                                        </div>

                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <TextField
                                                label='Celular'
                                                placeholder='Celular'
                                                value={formRegistrar ? formRegistrar.celular : ''}
                                                onChange={(e, o) => _onChangeText('celular', e, o)}
                                                autoComplete="off" />
                                        </div>
                                    </div>
                                </div>

                                <ButtonForm back={hideRegistrarUsuario} register={registrarUsuarioContratista}></ButtonForm>
                            </Stack>
                        </div>
                    </form>
                </Modal>
            </div>
        </>
    )
}