import * as React from 'react';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { FocusTrapZone } from '@fluentui/react/lib/FocusTrapZone';

import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { useBoolean } from '@fluentui/react-hooks';
import { ComboBox, DatePicker, getTheme, IComboBoxOption, IComboBoxStyles, Icon, IStackProps, SelectionMode, Stack, TextField, Toggle } from '@fluentui/react';
import PersonaService from '../../services/PersonaService';
import { useNavigate } from 'react-router-dom';
import { ConfigHelper } from '../../utils/Helper/configHelper';

const theme = getTheme();

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

export const MenuPersona: React.FunctionComponent = () => {
  const [enableFocusTrap, setEnableFocusTrap] = React.useState(false);
  let navigate = useNavigate();
  const onChangeEnableFocusTrap = React.useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
      setEnableFocusTrap(!!checked);
    },
    [],
  );
  function goBack() {
    navigate(`${ConfigHelper.urlBase()}/personas/crear`)
  }
  const _items: ICommandBarItemProps[] = [
    {
      key: 'newItem',
      // text: 'Agregar',
      split: true,
      iconProps: { iconName: 'Mail' },
      onClick: () => (console.log('Share')),
    },
    {
      key: 'upload',
      // text: 'Upload',
      iconProps: { iconName: 'ExcelDocument' },
      onClick: () => (console.log('Share')),
    },
    {
      key: 'Refresh',
      // text: 'Agregar',
      split: true,
      iconProps: { iconName: 'Refresh' },
      onClick: () => console.log('Download'),
    },
    {
      key: 'download',
      // text: 'Agregar',
      iconProps: { iconName: 'Add' },
      onClick: () => (console.log('Download)'),goBack()),
      // href: `${ConfigHelper.urlBase()}/personas/crear`,
    },
  ];

  return (
    <FocusTrapZone disabled={!enableFocusTrap}>
      <CommandBar
        items={_items}
        // overflowItems={_overflowItems}
        overflowButtonProps={overflowProps}
        //farItems={_farItems}
        ariaLabel="Inbox actions"
        primaryGroupAriaLabel="Email actions"
        farItemsGroupAriaLabel="More actions"
      />
    </FocusTrapZone>

  );
};



//============================================


const options: IChoiceGroupOption[] = [
  { key: 'A', text: 'Option A' },
  { key: 'B', text: 'Option B' },
  { key: 'C', text: 'Option C', disabled: true },
];
const modelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 450 } },
};
const dialogContentProps = {
  type: DialogType.largeHeader,
  title: 'All emails together',
  subText: 'Your Inbox has changed. No longer does it include favorites, it is a singular destination for your emails.',
};

export const CrearNuevoProg: React.FunctionComponent = () => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  return (
    <>
      <DefaultButton secondaryText="Opens the Sample Dialog" onClick={toggleHideDialog} text="Nuevo" />
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}
      >
        <ChoiceGroup defaultSelectedKey="B" options={options} />
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text="Save" />
          <DefaultButton onClick={toggleHideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};



const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 400 } },
};
const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 500 } };
const lisPersonas: IComboBoxOption[] = [
  { key: 'A', text: 'Option A' },
  { key: 'B', text: 'Option B' },
  { key: 'C', text: 'Option C' },
  { key: 'D', text: 'Option D' },
];

export interface inputsFormulario {
  tipoDocumentoIdentidad?: string,
  fechaNacimiento?: string,
  apellidoPaterno?: string,
  apellidoMaterno?: string,
  primerNombre?: string,
  segundoNombre?: string,
  nroDOI?: string,
  sexo?: string,
  puestoId?: string,
  grupoSanguineo?: string,
  contratoId?: string,
  areaId?: string,
  email?: string,
  celular?: string,
  extension?: string,
  foto?: string,
}
const INITIAL_MODULE = {
  tipoDocumentoIdentidad: '7c8a676e-52b2-46ea-b6fc-2c5afb2839d3',
  puestoId: '635f513c-d23b-492c-a55a-000d8e484c49',
  contratoId: 'adf55a3f-5caf-4f4f-a8e1-006eca4acf97',
  areaId: '004a9f62-f18b-407d-a808-0036ab2e726a',
  sexo: 'M',
  grupoSanguineo: 'o-',
  foto: '',
  /*  "tipoDocumentoIdentidad": "7c8a676e-52b2-46ea-b6fc-2c5afb2839d3",
   "fechaNacimiento": "2022-01-11T13:32:54.676Z",
   "apellidoPaterno": "Bueno",
   "apellidoMaterno": "Bueno",
   "primerNombre": "Deyvis",
   "segundoNombre": "",
   "nroDOI": "78018040",
   "sexo": "M",
   "puestoId": "635f513c-d23b-492c-a55a-000d8e484c49",
   "grupoSanguineo": "o+",
   "contratoId": "adf55a3f-5caf-4f4f-a8e1-006eca4acf97",
   "areaId": "004a9f62-f18b-407d-a808-0036ab2e726a",
   "foto": "",
   "email": "dbueno@gmail.com",
   "celular": "983159968",
   "extension": "" */
}
const onFormatDate = (date?: Date): string => {
  return !date ? '' : date.getDate() + '-' + (date.getMonth() + 1) + '-' + (date.getFullYear() % 100);
};
export const CrearPersona: React.FunctionComponent = () => {
  const [model, setmodel] = React.useState<inputsFormulario>(INITIAL_MODULE);
  const [value, setValue] = React.useState<Date | undefined>();
  let navigate = useNavigate();
  //const datePickerRef = React.useRef<IDatePicker>(null);
  function _onChangeTextFild(e: any, newValue: any): void {
    console.log(e.target.name)
    setmodel({ ...model, [e.target.name]: newValue })
    console.log(newValue, model)
  }

  function savePersona() {
    PersonaService.postPersona(model).then(response => {
      console.log("Enviado");
      setmodel(INITIAL_MODULE)
      goBack()
    }).catch(e => {
      alert("Informacion no enviada")
    });
  };

  function goBack() {
    navigate(`${ConfigHelper.urlBase()}/personas`)
  }

  /*  const _allItems: any[] = [];
 
   for (let i = 0; i < 3; i++) {
     _allItems.push({
       key: i,
       nombre: 'Item ' + i,
       fecha: '17/12/2017',
       estado: 'Vigente',
     });
   } */
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div style={{ minWidth: '600px', boxShadow: theme.effects.elevation8, margin: '20px', padding: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '5px' }}>
            <Icon iconName="ReminderPerson" style={{ fontSize: '50pt' }} />
          </div>
          <hr />
          <div style={{ height: '5px' }}>

          </div>
          <div style={{ padding: '20px', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '50px' }}>
            <Stack {...columnProps}>
              <TextField
                name='primerNombre'
                placeholder='Primer Nombre'
                onChange={_onChangeTextFild}
              />
              <TextField
                name='apellidoPaterno'
                placeholder='Apellido Paterno'
                onChange={_onChangeTextFild}
              />
              <TextField
                name='nroDOI'
                placeholder='Nro DNI'
                onChange={_onChangeTextFild}
              />
              <ComboBox
                placeholder='Grupo Sanguineo'
                // defaultSelectedKey="C"
                options={[
                  { key: 'A', text: 'O+' },
                  { key: 'B', text: 'O-' },
                ]}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
              />
              <ComboBox
                placeholder='Contrato'
                // defaultSelectedKey="C"
                options={[
                  { key: 'A', text: 'A' },
                  { key: 'B', text: 'B' },
                ]}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
              />
              <TextField
                iconProps={{ iconName: 'Accounts' }}
                placeholder='Email'
                name='email'
                onChange={_onChangeTextFild}
              />
              <TextField
                iconProps={{ iconName: 'Phone' }}
                placeholder='Celular'
                name='celular'
                onChange={_onChangeTextFild}
              />
              <TextField
                iconProps={{ iconName: 'Photo2' }}
                placeholder='Foto'
              // type='file'
              />
            </Stack>
            <Stack  {...columnProps} >
              <TextField
                name='segundoNombre'
                onChange={_onChangeTextFild}
                placeholder='Segundo Nombre'
              />
              <TextField
                placeholder='Apellido Materno'
                name='apellidoMaterno'
                onChange={_onChangeTextFild}
              />
              <DatePicker
                placeholder='Fecha Naciminto'
                // componentRef={datePickerRef}
                allowTextInput
                ariaLabel="Select a date. Input format is day slash month slash year."
                value={value}
                onSelectDate={(date: any) => { setmodel({ ...model, fechaNacimiento: date?.toISOString() }); setValue(date); console.log(date?.toISOString()) }}
                formatDate={onFormatDate}
                //  parseDateFromString={onParseDateFromString}
                onChange={(e: any) => console.log(model)}
              />
              <ComboBox
                placeholder='Sexo'
                // defaultSelectedKey="C"
                options={[
                  { key: 'A', text: 'Masculino' },
                  { key: 'B', text: 'Femenino' },
                ]}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
              />
              <ComboBox
                placeholder='Puesto'
                // defaultSelectedKey="C"
                options={[
                  { key: 'A', text: 'A' },
                  { key: 'B', text: 'B' },
                ]}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
              />
              <ComboBox
                placeholder='Area'
                // defaultSelectedKey="C"
                options={[
                  { key: 'A', text: 'A' },
                  { key: 'B', text: 'B' },
                ]}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
              />
              <TextField
                placeholder='Extencion'
                name='extension'
                onChange={_onChangeTextFild}
              />
              {/*  <ComboBox
                placeholder='Origen satelital'
                // defaultSelectedKey="C"
                options={lisPersonas}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
              /> */}
            </Stack>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <BotonesAgregar
              cancelar={() => { goBack() }}
              guardar={savePersona}
            />
          </div>
        </div>
      </div>
    </>
  );
};



function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
  console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
}

export const BotonesAgregar = (props: any) => {
  return (
    <div>
      <DefaultButton
        text="Cancelar"
        style={{ borderColor: 'red', color: 'red' }}
        onClick={props.cancelar}
        // href={`${ConfigHelper.urlBase()}/personas`}
      />
      <PrimaryButton
        text='REGISTRAR'
        style={{ marginLeft: '20px' }}
        onClick={props.guardar}
      />
    </div>
  )
}