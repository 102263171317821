import { IActivityItemProps, ICommandBarItemProps, PrimaryButton, DefaultButton, IComboBox, IComboBoxOption, PivotItem, Link, Panel, ActivityItem, ComboBox, TextField, ProgressIndicator, PanelType } from "@fluentui/react";
import { Selection, IColumn } from "@fluentui/react/lib/DetailsList";
import React, { useState, useContext, useEffect, useImperativeHandle } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../../auth/context/auth-context";
import { IItemComboBaseDTO, IException, ISearch } from "../../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../../interfaces/base/ITable";
import { IFilterProgramacionPersona } from "../../../../interfaces/filter/IFilterProgramacionPersona";
import { IAdicional } from "../../../../interfaces/IAdicional";
import { IProgramacionPersona } from "../../../../interfaces/IProgramacion";
import AccionService from "../../../../services/accionService";
import ProgramacionPersonaService from "../../../../services/programacionPersonaService";
import { ConfigHelper } from "../../../../utils/Helper/configHelper";
import { _initialFilter, baseImageUrl, splitExceptionDetail, _initialPagination } from "../../../../utils/Helper/helper";
import { IconHelper } from "../../../../utils/Helper/iconHelper";
import { ProcesoHelper } from "../../../../utils/Helper/procesoHelper";
import { isRoleAccessAll, roleAdmin, ROLE_GERENTE_SOLICITANTE } from "../../../../utils/Helper/roleHelper";
import { _initialDataCollection } from "../../../../utils/Helper/tableHelper";
import ThemeBase from "../../../../utils/Theme/themeBase";
import { ColumnPersonaAdicional } from "../../recursivo/columnPersonaAdicional";
import { useBoolean } from "@fluentui/react-hooks";
import { TableBase } from "../../../../components/Table/TableBase";
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar";
import { MessageHelper } from "../../../../utils/Helper/messageHelper";
import { PanelPlantillaFiltro } from "../../../../components/PanelUpload/PanelPlantillaFiltro";
import { Ifilter } from "../../../../interfaces/IFilter";
import { IFilterUbicacion } from "../../../../interfaces/filter/IFilterUbicacion";
import { UbicacionHelper } from "../../../../utils/Helper/ubicacionHelper";
import EmpresaService from "../../../../services/empresaService";
import { IEmpresa } from "../../../../interfaces/IEmpresa";
import OrganigramaService from "../../../../services/organigramaService";
import ConfiguracionService from "../../../../services/configuracionService";
import ProgramacionService from "../../../../services/ProgramacionService";
import ContratoService from "../../../../services/contratoService";
import UbicacionService from "../../../../services/ubicacionesServices";

const roleValidarAdicional = () => {
    let a = [
        ROLE_GERENTE_SOLICITANTE,
    ];

    return roleAdmin().concat(a);
}

export const PendienteAdicionalGerencia = React.forwardRef((props, forwardedRef) => {
    let { id } = useParams();
    const navigate = useNavigate();

    const [sel, setSel] = useState<any[]>([]);

    const [hidden, setHidden] = useState(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [pendiente, setPendiente] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);
    const [accion, setAccion] = useState<IItemComboBaseDTO[]>([]);
    const [filter, setFilter] = useState<IFilterProgramacionPersona>({
        ..._initialFilter(),
        codProceso: ProcesoHelper.PROCESO_GERENTE_SOLICITANTE,
        programacionId: id
    });

    /* Panel */
    const [isOpenPanel, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [accionId, setAccionId] = useState<string>('');
    const [comentario, setComentario] = useState<string>('');
    const [isOpenPanelFiltroGerencia, { setTrue: openPanelFiltroGerencia, setFalse: dismissPanelFiltroGerencia }] = useBoolean(false)

    /* Selection */
    let selection = new Selection({
        onSelectionChanged: () => {
            let d: any[] = selection.getSelection();

            let t: IActivityItemProps[] = d
                .map((x) => {
                    return {
                        key: `1_${x.programacionPersonaId}`,
                        activityDescription: [
                            <Link
                                key={`d_${x.programacionPersonaId}`}
                                className={ThemeBase.activityClass.nameText}
                            >
                                {x.nombres}
                            </Link>,
                            <span key={`2_${x.programacionPersonaId}`}></span>,
                            <span
                                key={`3_${x.programacionPersonaId}`}
                                className={ThemeBase.activityClass.nameText}
                            ></span>,
                        ],
                        activityPersonas: [{ imageUrl: baseImageUrl }],
                        timeStamp: `DNI: ${x.nroDOI}`,
                        programacionPersonaId: `${x.programacionPersonaId}`,
                    };
                });

            setSel(t);
        },
    });

    /* Access & Role */
    const { access } = useContext(AuthContext);
    let role = access?.role ?? [];
    const [isGerenteSolicitante, setIsGerenteSolicitante] = useState(isRoleAccessAll(role, roleValidarAdicional()));

    const [empresaCombo, setEmpresaCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterEmpresa, setFilterEmpresa] = useState<Ifilter>({
        ..._initialFilter()
    });
    const empresaRef = React.useRef<IComboBox>(null);
    const _openOptionEmpresa = React.useCallback(() => empresaRef.current?.focus(true), []);

    const [gerenciaCombo, setGerenciaCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterGerencia, setFilterGerencia] = useState<ISearch>({
        search: ""
    });

    const empresaPersonaRef = React.useRef<IComboBox>(null);
    const _openOptionPersona = React.useCallback(() => empresaPersonaRef.current?.focus(true), []);

    const [embarqueCombo, setEmbaqueCombo] = useState<IItemComboBaseDTO[]>([]);
    const [destinoCombo, setDestinoCombo] = useState<IItemComboBaseDTO[]>([]);

    const [centroCostoCombo, setCentroCostoCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterCentroCosto, setFilterCentroCosto] = useState<Ifilter>({
        ..._initialFilter()
    });
    const centroCostoRef = React.useRef<IComboBox>(null);
    const _openOptionCentroCosto = React.useCallback(() => centroCostoRef.current?.focus(true), []);

    const [lugarMovilizacionCombo, setLugarMovilizacionCombo] = useState<IItemComboBaseDTO[]>([]);
    const embarqueRef = React.useRef<IComboBox>(null);
    const campamentoRef = React.useRef<IComboBox>(null);

    const LugarMovilizacionRef = React.useRef<IComboBox>(null);
    const [filterMovilizacion, setfilterMovilizacion] = useState<ISearch>(_initialFilter);
    const _openOptionMovilizacion = React.useCallback(() => LugarMovilizacionRef.current?.focus(true), []);

    const [filterEmbarque, setFilterEmbarque] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_ORIGEN
    });
    const _openOptionEmbarque = React.useCallback(() => embarqueRef.current?.focus(true), []);

    const [filterDestino, setFilterDestino] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_DESTINO,
    });
    const _openOptionCampamento = React.useCallback(() => campamentoRef.current?.focus(true), []);


    const [nivelCombo, setNivelCombo] = useState<IItemComboBaseDTO[]>([]);
    const [keysNivel, setKeysNivel] = useState<string[]>([]);
    const [filterNivel, setFilterNivel] = useState<ISearch>({
        search: ""
    });

    const [levelCombo, setLevelCombo] = useState<IItemComboBaseDTO[]>([]);
    const [keysLevel, setKeysLevel] = useState<string[]>([]);
    const [filterLevel, setFilterLevel] = useState<ISearch>({
        search: ""
    })

    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });


    /* Imperative Hook */
    useImperativeHandle(forwardedRef, () => {
        return {
            filterFromParent: (search = '') => {

                let p = _initialPagination().currentPage;

                let f: IFilterProgramacionPersona = {
                    ..._initialFilter(),
                    programacionId: id,
                    codProceso: ProcesoHelper.PROCESO_GERENTE_SOLICITANTE,
                    skip: (p - 1) * pageSize,
                    take: pageSize,
                    search: search.trim(),
                }

                //setFilter(f);
                setCurrentPage(p);
                reloadInitial(f);

                console.log(search)
            }
        }
    });

    useEffect(() => {
        if (id) {
            reloadInitial();
        }
    }, []);

    useEffect(() => {
        if (isOpenPanel) {
            dataAccion();
        } else {
            setAccionId('');
        }
    }, [isOpenPanel]);

    useEffect(() => {
        setSel([]);
    }, [pendiente]);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    useEffect(() => {

        dataEmpresaTransporte();

    }, [filterEmpresa]);

    useEffect(() => {
        dataGerencia();

    }, [filterGerencia]);

    useEffect(() => {
        dataNivel();

    }, [filterNivel]);

    useEffect(() => {
        dataLevel();

    }, [filterLevel]);

    useEffect(() => {
        dataLugarMovilizacion();

    }, [filterMovilizacion]);

    useEffect(() => {
        dataCentroCosto()
    }, [filterCentroCosto]);

    useEffect(() => {

        dataUbicacionOrigen();
        dataUbicacionDestino();

    }, [filterEmbarque, filterDestino]);


    /* Api */
    const dataPendiente = (param = filter) => {
        setPendiente(_initialDataCollection);

        ProgramacionPersonaService.allAdicionalGerencia(param).then(res => {
            if (res.status == 200) {
                setPendiente(res.data);
                setTotal(res.data.total);
                setHidden(true);
            }
        }).catch((e: IException) => {
            setHidden(true);
            alert(splitExceptionDetail(e));
        })
    }

    const dataAccion = () => {
        AccionService.allByCod(ProcesoHelper.PROCESO_GERENTE_SOLICITANTE).then(res => {
            if (res.status == 200) {
                setAccion(res.data);
                setHidden(true);
            }
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        })
    }

    const validar = () => {
        setIsSend(true);
        setChoice(undefined);

        let t: IAdicional = {
            programacionId: id,
            lista: sel.map((x) => x.programacionPersonaId),
            accionId: accionId,
            comentario: comentario,
        };

        ProgramacionPersonaService.validarAdicional(t)
            .then((res) => {
                if (res.status == 200) {
                    reloadInitial();
                    setChoice('');
                    dismissPanel();
                }

                setIsSend(false)
            })
            .catch((e) => {
                setIsSend(false);
                setChoice('error');
                settextError(splitExceptionDetail(e));
            });
    };

    const dataEmpresaTransporte = () => {

        dataEmpresa(filterEmpresa, setEmpresaCombo);
        // setFilterEmpresa()
    }

    const dataEmpresa = (filtro: Ifilter, setCombo: React.Dispatch<React.SetStateAction<any>>) => {

        EmpresaService.all(filtro)
            .then((res) => {
                if (res.status == 200) {
                    // console.log("***** empresa *****");
                    // console.table(res.data.items);
                    let d: IEmpresa[] = res.data.items;
                    let t: IItemComboBaseDTO[] = d.map((x) => {
                        return {
                            key: x.empresaId,
                            text: x.nombre as string,
                        };
                    });
                    setCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataGerencia = () => {
        setGerenciaCombo([]);
        OrganigramaService.organigramaByTipo('01', filterGerencia)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    // t.unshift(ItemNoneSelect());

                    setGerenciaCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataNivel = () => {

        ConfiguracionService.allNivel(filterNivel)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;
                    // t.unshift(ItemSelectAll());
                    setNivelCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataLevel = () => {

        ConfiguracionService.allLevel(filterLevel)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;
                    // t.unshift(ItemSelectAll());
                    setLevelCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion()
            .then((res) => {
                // console.log("************** lugar **********");
                // console.log(res.data);
                setLugarMovilizacionCombo(res.data);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataCentroCosto = () => {
        ContratoService.allCentroCosto1(filterCentroCosto)
            .then((res) => {
                setCentroCostoCombo(res.data);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataUbicacionOrigen = () => {

        dataUbicacion(filterEmbarque, setEmbaqueCombo);

    }

    const dataUbicacionDestino = () => {

        dataUbicacion(filterDestino, setDestinoCombo);

    }

    const dataUbicacion = (filtro: any, setCombo: React.Dispatch<React.SetStateAction<any>>) => {
        UbicacionService.ubicacionPorFiltro(filtro)
            .then((res) => {
                setCombo(res.data);

            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setColumn(ColumnPersonaAdicional);
        dataPendiente(param);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => { navigate(`${ConfigHelper.urlBase()}/adicionales/solicitante`) }
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial()
            },
        ];

        if (isGerenteSolicitante) {
            _items.push(
                {
                    ...IconHelper.ICON_CHECK,
                    onClick: () => {
                        if (sel.length <= 0) {
                            return alert('Debe seleccionar al menos un item');
                        }

                        openPanel();
                    },
                },
            )
        }

        return _items;
    }

    const _rigthBar = () => {

        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_FILTER,
                onClick: () => openPanelFiltroGerencia()
            }
        ]


        return _items
    }

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={validar} styles={ThemeBase.panelButtonStyle} disabled={accionId == '' || isSend}>
                    Validar
                </PrimaryButton>
                <DefaultButton onClick={dismissPanel}>Cancelar</DefaultButton>
            </div>
        ),
        [dismissPanel, sel, accionId, comentario, isSend, selection]
    );

    /* Event */
    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'accion':
                        setAccionId(option.key as string);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const _onChangeText = (type: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                switch (type) {
                    case 'comentario':
                        setComentario(newValue);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Event Tab */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterProgramacionPersona = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    const _onChangeComboFiltro = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'empresaTransporte':
                        setFilter({
                            ...filter,
                            empresaTransporteId: option.key.toString(),
                        })

                        break;

                    case 'gerencia':
                        setFilter({
                            ...filter,
                            gerenciaId: option.key.toString(),
                        })

                        break;
                    case 'movilizacion':
                        setFilter({
                            ...filter,
                            lugarMovilizacionId: option.key.toString()
                        })

                        break;
                    case 'centroCosto':
                        setFilter({
                            ...filter,
                            centroCostoId: option.key.toString()
                        })

                        break;
                    case 'origen':

                        //alert(option.key.toString())
                        setFilter({
                            ...filter,
                            origenId: option.key.toString()
                        })

                        break;
                    case 'destino':

                        //alert(option.key.toString())
                        setFilter({
                            ...filter,
                            destinoId: option.key.toString()
                        })

                        break;
                        case 'nivel':

                        //alert(option.key.toString())
                        setFilter({
                            ...filter,
                            nivelId: option.key.toString()
                        })

                        break;
                    case 'level':

                        //alert(option.key.toString())
                        setFilter({
                            ...filter,
                            levelId: option.key.toString()
                        })

                        break;
                    default:
                        break;
                }
            }
        }

    };

    const _onKeyUpCombo = (e: React.FormEvent<IComboBox>) => {

        let { id } = e?.target as HTMLInputElement;
        let { value } = e?.target as HTMLInputElement;

        switch (id) {

            case "empresaPersona-input":
                _openOptionPersona()

                /* setFilterEmpresaPersona({
                  ...filterEmpresaPersona,
                  search: value,
                }) */

                break;
            case "empresaTransporte-input":
                _openOptionEmpresa()

                setFilterEmpresa({
                    ...filterEmpresa,
                    search: value,
                })

                break;
            case "movilizacion-input":
                _openOptionMovilizacion();

                setfilterMovilizacion({
                    ...filterMovilizacion,
                    search: value
                })

                break;

            case "centroCosto-input":
                _openOptionCentroCosto();

                setFilterCentroCosto({
                    ...filterCentroCosto,
                    search: value
                })

                break;
            default:
                break;
        }
    }

    //filtro
    const _buscarFiltro = () => {

        reloadInitial(filter);

        dismissPanelFiltroGerencia();
    }

    const _limpiarFiltro = () => {

        setFilter({
            ...filter,
            empresaTransporteId: "",
            gerenciaId: "",
            lugarMovilizacionId: "",
            centroCostoId: "",
            origenId: "",
            destinoId: "",

        })

        let modelClear: IFilterProgramacionPersona = {
            ..._initialFilter(),
            codProceso: ProcesoHelper.PROCESO_GERENTE_APROBADOR, //codigo 08
            programacionId: id
        }

        reloadInitial(modelClear);

    }


    return (
        <>
            <TableBase leftBar={_leftBar()} rigthBar={_rigthBar()} column={column} data={pendiente.items} isLoading={!hidden} pagination={pager} selection={selection} style={ThemeBase.notPaddingTableBase}></TableBase>

            {/* Panel Validar Pendientes */}
            <Panel
                isOpen={isOpenPanel}
                onDismiss={dismissPanel}
                headerText="Validar personal seleccionado"
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
            >
                <>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    setChoice('');
                                    dismissPanel();
                                }}></CustomMessageBar>}
                            </div>
                        </div>
                    </div>

                    <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                        {sel.map((item: { key: string | number }) => (
                            <ActivityItem
                                {...item}
                                key={item.key}
                                className={ThemeBase.activityClass.exampleRoot}
                            />
                        ))}

                        <div style={{ paddingTop: '90%' }}>
                            <ComboBox
                                label="Acciones"
                                autoComplete='off'
                                useComboBoxAsMenuWidth
                                calloutProps={{ directionalHintFixed: true }}
                                options={accion}
                                openOnKeyboardFocus
                                selectedKey={accionId}
                                onChange={(e, o) => _onChangeCombo('accion', e, o)}
                                required
                            />

                            <TextField label='Comentario' multiline rows={5} onChange={(e, o) => _onChangeText('comentario', e, o)} />
                        </div>
                    </form>

                </>
            </Panel>
            <div id='panelFiltroGerencia'>
                <PanelPlantillaFiltro
                    textHeader='Filtro'
                    subTextHeader='Elija las condiciones para el filtro personalizado'
                    textBoton='Buscar'
                    textBotonDefault='Limpiar'
                    onclickBoton={_buscarFiltro}
                    onclickBotonDefault={_limpiarFiltro}
                    openPanel={isOpenPanelFiltroGerencia}
                    closePanel={dismissPanelFiltroGerencia}
                    // onRenderHeader={onRenderHeaderFilter}
                    size={PanelType.medium}
                    disabledBoton={false}
                    estadoBarraEnvio={false}
                    nameIconHeader={"Filter"}
                    nameIconButton={"Search"}
                // dep={[filter, keysNivel]}
                >
                    <>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <ComboBox
                                        label="Empresa Transporte:"
                                        id="empresaTransporte"
                                        options={empresaCombo}
                                        componentRef={empresaRef}
                                        selectedKey={filter?.empresaTransporteId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => _onChangeComboFiltro("empresaTransporte", e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    // onBlur={_onBlurEmpresa}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <ComboBox
                                        label="Gerencia:"
                                        options={gerenciaCombo}
                                        // componentRef={comboBoxRef}
                                        selectedKey={filter?.gerenciaId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        // multiSelect
                                        onChange={(e, o) => _onChangeComboFiltro("gerencia", e, o)}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                    <ComboBox
                                        label="Nivel:"
                                        options={nivelCombo}
                                        // componentRef={comboBoxRef}
                                        selectedKey={filter?.nivelId}
                                        // onChange={_onChangePersonaEstado}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform

                                        onChange={(e, o) => _onChangeComboFiltro("nivel", e, o)}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                    <ComboBox
                                        label="Level:"
                                        options={levelCombo}
                                        // componentRef={comboBoxRef}
                                        selectedKey={filter?.levelId}
                                        // calloutProps={{ doNotLayer: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform

                                        onChange={(e, o) => _onChangeComboFiltro("level", e, o)}
                                        // placeholder='Gerencia'
                                        autoComplete='off'
                                        calloutProps={{ directionalHintFixed: true }}
                                        // onKeyUp={(e) => _onUpCombo('gerencia', e)}
                                        // onBlur={() => _onBlurCombo('gerencia')}
                                        required
                                    />

                                </div>
                            </div>
                            <br />
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                    <ComboBox
                                        label="Lugar Movilización:"
                                        id="movilizacion"
                                        options={lugarMovilizacionCombo}
                                        componentRef={LugarMovilizacionRef}
                                        selectedKey={filter?.lugarMovilizacionId}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        // placeholder='Gerencia'
                                        autoComplete='off'
                                        calloutProps={{ directionalHintFixed: true }}
                                        onChange={(e, o) => _onChangeComboFiltro("movilizacion", e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    // onBlur={() => _onBlurCombo('gerencia')}
                                    />

                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                    <ComboBox
                                        label="Centro de Costo:"
                                        id="centroCosto"
                                        options={centroCostoCombo}
                                        //componentRef={comboBoxRef}
                                        selectedKey={filter?.centroCostoId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => _onChangeComboFiltro("centroCosto", e, o)}

                                    />
                                </div>
                            </div>
                            <br />
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label='Origen (Embarque)'
                                        componentRef={embarqueRef}
                                        allowFreeform
                                        autoComplete="off"
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={embarqueCombo}
                                        // onKeyUp={_onUpEmbarque}
                                        selectedKey={filter?.origenId}
                                        onChange={(e, o) => _onChangeComboFiltro("origen", e, o)}
                                    // onBlur={_onBlurEmbarque}

                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label='Destino (Desembarque)'
                                        componentRef={campamentoRef}
                                        allowFreeform
                                        autoComplete="off"
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        // onKeyUp={_onUpCampamento}
                                        options={destinoCombo}
                                        selectedKey={filter?.destinoId}
                                        onChange={(e, o) => _onChangeComboFiltro("destino", e, o)}
                                    //onBlur={_onBlurCampamento} */
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                </PanelPlantillaFiltro>

            </div>
        </>
    )
})