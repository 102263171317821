import { IComboBox, IComboBoxOption, Stack, ComboBox, Toggle, TextField, Panel, PrimaryButton, PanelType, ProgressIndicator } from "@fluentui/react";
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { AuthContext } from "../../auth/context/auth-context";
import { CustomMessageBar, typeMessage } from "../../components/MessageBar/customMessageBar";
import { IItemComboBaseDTO, IDetailUbigeo, ISearch, IBase, IException } from "../../interfaces/base/IBase";
import { IFilterUbicacion } from "../../interfaces/filter/IFilterUbicacion";
import { IHotel } from "../../interfaces/IHotel";
import { IListarPersonaById, IOptionPersona } from "../../interfaces/IPersona";
import { IEditProgramacionPersona } from "../../interfaces/IProgramacion";
import ContratoService from "../../services/contratoService";
import EmpresaService from "../../services/empresaService";
import HoteleriaService from "../../services/HoteleriaService";
import PersonaService from "../../services/PersonaService";
import ProgramacionPersonaService from "../../services/programacionPersonaService";
import ProgramacionService from "../../services/ProgramacionService";
import TransporteService from "../../services/transporteService";
import UbicacionService from "../../services/ubicacionesServices";
import UbigeoService from "../../services/ubigeoService";
import { splitExceptionDetail, stackTokens, _initialFilter } from "../../utils/Helper/helper";
import { MessageHelper } from "../../utils/Helper/messageHelper";
import { isRoleAccessAll, roleAdmin, ROLE_ADMIN, ROLE_CONTRATISTA, ROLE_KEY_USER_EMPLEADO, ROLE_KEY_USER_SOCIO } from "../../utils/Helper/roleHelper";
import { UbicacionHelper } from "../../utils/Helper/ubicacionHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { AccesoGeneric } from "./generic/accesoGeneric";

export const ActualizarAccesoPersona = ({ programacionPersonaId = '', isOpen = false, dismiss = () => { }, reloadParent = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Excepcion */
    const [isEnabled, setIsEnabled] = useState(false);

    const [programacion, setProgramacion] = useState<IEditProgramacionPersona>(null!);
    const [persona, setPersona] = useState<IOptionPersona[]>([]);
    const [empresa, setEmpresa] = useState<string>('');
    const [psGroup, setPsGroup] = useState<string>('');
    const [origen, setOrigen] = useState<IItemComboBaseDTO[]>([]);
    const [destino, setDestino] = useState<IItemComboBaseDTO[]>([]);
    const [turno, setTurno] = useState<IItemComboBaseDTO[]>(AccesoGeneric.initialTurno);
    const [Ubigueo, setUbigueo] = useState<IItemComboBaseDTO[]>([]);
    const [Provincias, setProvincias] = useState<IItemComboBaseDTO[]>([]);
    const [Distritos, setDistritos] = useState<IItemComboBaseDTO[]>([]);
    const [hotel, setHotel] = useState<IItemComboBaseDTO[]>([]);
    const [transporte, setTransporte] = useState<IItemComboBaseDTO[]>([]);
    const [centroCosto, setCentroCosto] = useState<IItemComboBaseDTO[]>([]);
    const [lugarMovilizacion, setLugarMovilizacion] = useState<IItemComboBaseDTO[]>([]);
    const [ubigeo, setUbigeo] = useState<IDetailUbigeo>(null!);

    const [filterOrigen, setFilterOrigen] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_ORIGEN
    });
    const [filterDestino, setFilterDestino] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_DESTINO,
    });

    /* Access & Role */
    const { access } = useContext(AuthContext);
    const role = access?.role ?? [];
    const isRolAdmin = isRoleAccessAll(role, [ROLE_ADMIN]);
    const isRolKeyUser = isRoleAccessAll(role, [ROLE_KEY_USER_EMPLEADO,ROLE_KEY_USER_SOCIO]);
    const isRolModifiedLugMov = isRoleAccessAll(role, roleAdmin());

    const [isMMG, setIsMMG] = useState<boolean | undefined>(undefined);
    const [cajaEnabled, setCajaEnabled] = useState(true);

    /* Hook */
    useEffect(() => {
        if (isOpen && programacionPersonaId != '') {
            dataProgramacionPersona();
        }
        loadData();
    }, [isOpen]);

    /* Callback reload */
    const refForm = React.useCallback(() => {
        dataProgramacionPersona();

        resetChoice()
        setEmpresa('')
        setPsGroup('');
    }, [dismiss])

    /* Function */
    const loadData = () => {
        dataOrigen();
        dataDestino();
        dataLugarMovilizacion();
        dataCentroCosto();
        dataHotel();
        dataByUbigeoId(1, undefined);
    }

    /* Api */
    const dataProgramacionPersona = () => {
        if (programacionPersonaId != '') {
            PersonaService.getAccesProgConfirm(programacionPersonaId)
                .then((res) => {
                    if (res.status == 200) {
                        let d: IEditProgramacionPersona = res.data;

                        setProgramacion({
                            programacionId: d.programacionId,
                            personaId: d.personaId,
                            programacionPersonaId: d.programacionPersonaId,
                            identificacion: d.identificacion,
                            paraderoId: d.paraderoId,
                            ubicacionCampamentoId: d.ubicacionCampamentoId,
                            turnoId: d.turnoId,
                            ubigeoId: d.ubigeoId,
                            transporteId: d.transporteId,
                            hotelId: d.hotelId,
                            direccion: d.direccion,
                            email: d.email,
                            celular: d.celular,
                            isCuarentena: d.isCuarentena,
                            isTitular: d.isTitular,
                            esTitular: d.esTitular,
                            lugarMovilizacionId: d.lugarMovilizacionId,
                            centroCostoId: d.centroCostoId,
                            requiereTransporte:d.requiereTransporte
                        });

                        _onInfoPersona(d.personaId as string);

                        /* Ubigeo */
                        if (d.ubigeoId != undefined) {
                            detalleUbigeo(d.ubigeoId);
                        }
                    }

                    setIsEnabled(false);
                })
                .catch((e: IException) => {
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                    setIsEnabled(true);
                });
        }
    }

    const _onInfoPersona = (id: string) => {
        let obj: IListarPersonaById = {
            listaPersonas: [id],
        };

        PersonaService.getInfoPersonaById(obj)
            .then((res) => {
                if (res.data) {
                    let d = res.data[0];
                    if (d) {
                        let t: IOptionPersona[] = [
                            {
                                key: d.personaId,
                                text: `${d.nroDOI} | ${d.persona}`,
                                foto: d.foto,
                                doi: d.nroDOI,
                                puesto: d.puesto,
                            }
                        ];

                        setPersona(t);

                        let isMMG = d.tipo == '01';
                        setIsMMG(isMMG);

                        if (isRolAdmin) {
                            if (isMMG) {
                                dataEmpresaTitularMMG();
                            } else {
                                dataTransporte();
                            }
                        } else {
                            if (isRolKeyUser) {
                                dataSocioTrPorEmpresaContratista(d.empresaId);
                            }
                        }

                        let ps = '';
                        if (d.nivel != null && d.level != null) {
                            ps = `${d.nivel} - ${d.level}`;
                        }

                        setEmpresa(d.empresa);
                        setPsGroup(ps);
                    }
                }
            })
            .catch((e) => {
                alert(e);
            });
    };

    const dataEmpresaTitularMMG = () => {
        setTransporte([]);

        EmpresaService.allTitularMMG(_initialFilter())
            .then((res) => {
                if (res.status == 200) {
                    setTransporte(res.data);
                }
            })
            .catch((e) => {
                return alert(e.message);
            });
    };

    const dataSocioTrPorEmpresaContratista = (empresaContratistaId: string) => {
        setTransporte([]);
        TransporteService.allSocioTrByEmpresaContratista(empresaContratistaId)
            .then((res) => {
                if (res.status == 200) {
                    let d: IBase[] = res.data;

                    let t: IItemComboBaseDTO[] = d.map((x) => {
                        return {
                            key: x.id,
                            text: x.nombre,
                        };
                    });

                    setTransporte(t);
                }
            })
            .catch((e) => {
                return alert(e.message);
            });
    };

    const dataOrigen = () => {
        UbicacionService.ubicacionPorFiltro(filterOrigen)
            .then((res) => {
                setOrigen(res.data);
            })
            .catch((e: IException) => {
                console.log(splitExceptionDetail(e));
            });
    }

    const dataDestino = () => {
        UbicacionService.ubicacionPorFiltro(filterDestino)
            .then((res) => {
                setDestino(res.data);
            })
            .catch((e: IException) => {
                console.log(splitExceptionDetail(e));
            });
    };

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion().then(res => {
            setLugarMovilizacion(res.data);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        });;
    }

    const dataCentroCosto = () => {
        ContratoService.allCentroCosto().then(res => {
            setCentroCosto(res.data);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        });;
    }

    const dataTransporte = () => {
        TransporteService.allSocioTransportista().then(res => {
            let d: IBase[] = res.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setTransporte(t);
        }).catch((e) => {
            return alert(e.message);
        })
    }

    const dataHotel = () => {
        HoteleriaService.getHoteles(50).then(res => {
            let d: IHotel[] = res.data.items;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.hotelID != null ? x.hotelID : '',
                    text: `${x.nombre} | ${x.direccion}`,
                })
            });

            setHotel(t);
        }).catch((e) => {
            return alert(e.message);
        });
    }

    const detalleUbigeo = (distritoId: string) => {
        UbigeoService.detalleById(distritoId).then(res => {
            let d: IDetailUbigeo = res.data;

            setUbigeo({
                ...programacion,
                departamentoId: d.departamentoId,
                provinciaId: d.provinciaId,
                distritoId: d.distritoId,
            })

            if (d.departamentoId) {
                dataByUbigeoId(2, d.departamentoId);
            }

            if (d.provinciaId) {
                dataByUbigeoId(3, d.provinciaId);
            }
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataByUbigeoId = (tipo: number, ubigeoId?: string) => {
        PersonaService.getComboUbigueo(ubigeoId).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            switch (tipo) {
                case 1:
                    setUbigueo(t);
                    break;
                case 2:
                    setProvincias(t);
                    break;
                case 3:
                    setDistritos(t);
                    break;
                default:
                    break;
            }
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const actualizar = () => {
        if (programacionPersonaId != '') {
            setIsSend(true);
            setChoice(undefined);

            ProgramacionPersonaService.actualizar(programacion)
                .then(res => {
                    setIsSend(false)
                    if (res.status == 200) {
                        refForm()
                        setChoice('send')
                        setEmpresa('');
                        setPsGroup('');
                        reloadParent();
                    }
                    setIsSend(false)
                })
                .catch((e: IException) => {
                    setIsSend(false);
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    }

    /* Event */
    const _onChangeParadero = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setProgramacion({
                    ...programacion,
                    paraderoId: option.key.toString(),
                });
            }
        }
    }

    const _onChangeCampamento = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                console.log(option);

                setProgramacion({
                    ...programacion,
                    ubicacionCampamentoId: option.key.toString(),
                });
            }
        }
    }

    const _onChangeClasificacion = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked != undefined) {
            setProgramacion({
                ...programacion,
                esTitular: checked,
            })
        }
    }

    const _onChangeDepartamento = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setUbigeo({
                    ...ubigeo,
                    departamentoId: option.key.toString(),
                    provinciaId: null,
                    distritoId: null
                });

                dataByUbigeoId(2, option.key.toString());

                setProgramacion({
                    ...programacion,
                    ubigeoId: null,
                });
            }
        }
    }

    const _onChangeProvincia = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setUbigeo({
                    ...ubigeo,
                    provinciaId: option.key.toString(),
                    distritoId: null,
                });

                dataByUbigeoId(3, option.key.toString());

                setProgramacion({
                    ...programacion,
                    ubigeoId: null,
                });
            }
        }
    }

    const _onChangeDistrito = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setUbigeo({
                    ...ubigeo,
                    distritoId: option.key.toString(),
                });

                setProgramacion({
                    ...programacion,
                    ubigeoId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeTransporte = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setProgramacion({
                    ...programacion,
                    transporteId: option.key.toString(),
                });
            }
        }
    }

    const _onChangeHotel = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setProgramacion({
                    ...programacion,
                    hotelId: option.key.toString(),
                });
            }
        }
    }

    const _onChangeDireccion = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setProgramacion({
                ...programacion,
                direccion: newValue,
            });
        }
    }

    const _onChangeEmail = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setProgramacion({
                ...programacion,
                email: newValue,
            });
        }
    }

    const _onChangeTelefono = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setProgramacion({
                ...programacion,
                celular: newValue,
            });
        }
    }

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'lugarMovilizacion':
                        setProgramacion({
                            ...programacion,
                            lugarMovilizacionId: String(option.key),
                        })
                        break;
                    case 'centroCosto':
                        setProgramacion({
                            ...programacion,
                            centroCostoId: String(option.key),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <Stack tokens={stackTokens}>
                    <PrimaryButton onClick={actualizar} styles={ThemeBase.panelButtonStyle} disabled={isSend || isEnabled}>
                        Actualizar
                    </PrimaryButton>
                </Stack>
            </div>
        ),
        [programacionPersonaId, programacion, isOpen, dismiss, isSend, reloadParent, empresa, psGroup, isMMG, isEnabled]
    );

    return (
        <>
            <Panel
                isOpen={isOpen}
                type={PanelType.medium}
                onDismiss={dismiss}
                headerText='Actualizar Datos'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                            <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                            {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                            {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                refForm()
                                resetChoice()
                            }}></CustomMessageBar>}
                        </div>
                    </div>
                </div>

                <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                    <Stack tokens={stackTokens}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <ComboBox
                                        label="Persona"
                                        allowFreeform
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={persona}
                                        selectedKey={persona.length > 0 ? persona[0].key : ''}
                                        errorMessage={empresa}
                                        disabled
                                    />

                                    <span style={{ color: 'rgb(164, 38, 44)', fontSize: 12 }}>{psGroup}</span>
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label="Embarque"
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={origen}
                                        selectedKey={programacion ? programacion.paraderoId : ''}
                                        onChange={_onChangeParadero}
                                        required
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label="Ubicación Campamento"
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={destino}
                                        selectedKey={programacion ? programacion.ubicacionCampamentoId : ''}
                                        onChange={_onChangeCampamento}
                                        required
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label='Seleccionar Turno'
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={turno}
                                        openOnKeyboardFocus
                                        selectedKey={programacion ? programacion.turnoId : ''}
                                        onChange={(e, o) => _onChangeCombo('turno', e, o)}
                                        required
                                        disabled
                                    />
                                </div>

                                {isMMG != undefined && isMMG &&
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <ComboBox
                                            label='Lugar Movilización'
                                            autoComplete='off'
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={lugarMovilizacion}
                                            selectedKey={programacion ? programacion.lugarMovilizacionId : ''}
                                            onChange={(e, o) => _onChangeCombo('lugarMovilizacion', e, o)}
                                            required
                                            disabled={!isRolModifiedLugMov}
                                        />
                                    </div>
                                }

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label='Centro Costos'
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={centroCosto}
                                        selectedKey={programacion ? programacion.centroCostoId : ''}
                                        onChange={(e, o) => _onChangeCombo('centroCosto', e, o)}
                                        required
                                    />
                                </div>

                                {/* <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                    <Toggle label='Clasificación Colaborador' onText='Titular' offText='Suplente' checked={programacion ? programacion.esTitular : true} onChange={_onChangeClasificacion} />
                                </div> */}

                            </div>
                            {/* <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <Toggle
                                        label='Requiere Transporte'
                                        placeholder='placeholder'
                                        onText='Si'
                                        offText='No'
                                        checked={programacion?.requiereTransporte}
                                        onChange={_onToggleComunidad}
                                    />
                                </div>
                            </div> */}
                        </div>

                        {/* Ubigeo */}
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <div className="ms-fontSize-16" style={{ color: '#4676DD' }}>Ubigeo (Ubicación actual)</div>
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                    <ComboBox
                                        label='Departamento'
                                        allowFreeform
                                        autoComplete='on'
                                        useComboBoxAsMenuWidth
                                        openOnKeyboardFocus
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={Ubigueo}
                                        selectedKey={ubigeo ? ubigeo.departamentoId : ''}
                                        onChange={_onChangeDepartamento}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                    <ComboBox
                                        label='Provincia'
                                        allowFreeform
                                        autoComplete='on'
                                        useComboBoxAsMenuWidth
                                        openOnKeyboardFocus
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={Provincias}
                                        selectedKey={ubigeo ? ubigeo.provinciaId : ''}
                                        onChange={_onChangeProvincia}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                    <ComboBox
                                        label='Distrito'
                                        allowFreeform
                                        autoComplete='on'
                                        useComboBoxAsMenuWidth
                                        openOnKeyboardFocus
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={Distritos}
                                        selectedKey={ubigeo ? ubigeo.distritoId : ''}
                                        onChange={_onChangeDistrito}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Transporte / hotel */}
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <ComboBox
                                        label='Empresa Transportista'
                                        allowFreeform
                                        autoComplete='on'
                                        useComboBoxAsMenuWidth
                                        openOnKeyboardFocus
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={transporte}
                                        selectedKey={programacion ? programacion.transporteId : ''}
                                        onChange={_onChangeTransporte}
                                        // disabled={cajaEnabled}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {programacion?.isCuarentena &&
                                        <ComboBox
                                            label='Hotel'
                                            allowFreeform
                                            autoComplete='on'
                                            useComboBoxAsMenuWidth
                                            openOnKeyboardFocus
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={hotel}
                                            selectedKey={programacion ? programacion.hotelId : ''}
                                            onChange={_onChangeHotel}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                        {/* Info contacto */}
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        label="Dirección"
                                        placeholder="Dirección"
                                        value={programacion?.direccion}
                                        onChange={_onChangeDireccion}
                                        autoComplete="off" />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <TextField
                                        label="Email"
                                        placeholder="Email"
                                        value={programacion?.email}
                                        onChange={_onChangeEmail}
                                        autoComplete="off" />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <TextField
                                        label="Teléfono"
                                        placeholder="Teléfono"
                                        value={programacion?.celular}
                                        onChange={_onChangeTelefono}
                                        autoComplete="off" />
                                </div>
                            </div>
                        </div>
                    </Stack>
                </form>
            </Panel>
        </>
    )
}