import { fetchMovilizacion } from "./http-common";

const postHotel = (data: any) => {
  return fetchMovilizacion.post(`Hoteles/Agregar`, data);
};

const postHabitacion = (data: any) => {
  return fetchMovilizacion.post(`Hoteles/AgregarHabitacion`,data);
};

const getHoteles = (take: number) => {
  return fetchMovilizacion.get(`Hoteles/Lista?skip=0&take=${take}`);
};

const getHabitaciones = (take: number, HotelId: string) => {
  return fetchMovilizacion.get(
    `Hoteles/ListaHabitaciones?skip=0&take=${take}&HotelId=${HotelId}`
  );
  //   Hoteles/ListaHabitaciones?take=50&HotelId=93425D59-091D-4B65-D9FA-08D9F0B1C42E
};

const ReporteHoteles=()=>{
  return fetchMovilizacion.get(
      `Hoteles/ReporteHoteles`,
      {
        responseType: "blob"
      }
    );
}

const HoteleriaService = {
  postHotel,
  postHabitacion,
  getHoteles,
  getHabitaciones,
  ReporteHoteles,
};

export default HoteleriaService;
