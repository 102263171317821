import { Label, Panel, PanelType, ProgressIndicator, Stack, TextField } from "@fluentui/react"
import React, { useEffect, useState } from "react";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import { CustomMessageBar, typeMessage } from "../../../components/MessageBar/customMessageBar";
import { IException } from "../../../interfaces/base/IBase";
import { IActualizarTransporteGrupo, IDetalleTransporteGrupo, IItemTransporteGrupo } from "../../../interfaces/IAccesoTransporteGrupo";
import { AccesoGrupoTransporteService } from "../../../services/accesoGrupoTransporteService";
import { splitExceptionDetail, stackTokens } from "../../../utils/Helper/helper";
import ThemeBase from "../../../utils/Theme/themeBase"

export const DetalleTransporteGrupo = ({ programacionBusId = '', isOpen = false, dissmiss = () => { }, reloadInitial = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Form */
    const [detalle, setDetalle] = useState<IDetalleTransporteGrupo[]>([]);

    /* Callback */
    const refForm = React.useCallback(() => {
        resetChoice();
    }, [dissmiss])

    /* Hook */
    useEffect(() => {
        if (isOpen) {
            if (programacionBusId != '') {
                detalleTransporteGrupo();
            }
        }
    }, [isOpen]);

    /* Api */
    const detalleTransporteGrupo = () => {
        AccesoGrupoTransporteService.detalleByProgramacionBus(programacionBusId)
            .then((res) => {
                setDetalle(res.data);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    }

    const actualizar = () => {
        if (programacionBusId != '') {
            setIsSend(true);
            setChoice(undefined);

            let f: IItemTransporteGrupo[] = detalle.map(x => {
                return {
                    accesoTransporteGrupoId: x.accesoTransporteGrupoId,
                    horaHelo: x.horaHelo,
                }
            });

            let t: IActualizarTransporteGrupo = {
                lista: f,
            }

            AccesoGrupoTransporteService.actualizarTransporteGrupo(t)
                .then((res) => {
                    setIsSend(false)
                    if (res.status == 200) {
                        reloadInitial();
                        setChoice('send');
                    }
                })
                .catch((e) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    }

    /* Event */
    const _onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let { name, id } = event?.target as HTMLInputElement;

                switch (name) {
                    case 'horaHelo':
                        var s = detalle.map(x => {
                            if (x.accesoTransporteGrupoId == id) {
                                return {
                                    ...x,
                                    horaHelo: newValue
                                }
                            }
                            return x;
                        });

                        setDetalle(s);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Footer Panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <ButtonForm register={actualizar} textRegister='Actualizar' back={dissmiss} disabledRegister={isSend}></ButtonForm>
            </div>
        ),
        [isSend, programacionBusId, isOpen, detalle, dissmiss]
    );

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dissmiss}
                type={PanelType.medium}
                headerText='Actualizar Grupos Helo'
                closeButtonAriaLabel='Close'
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                            <ProgressIndicator description="Enviando espere un momento" barHeight={3} />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                            {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                            {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                refForm()
                                resetChoice()
                                detalleTransporteGrupo()
                            }}></CustomMessageBar>}
                        </div>

                        <form ref={refForm} hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                            {detalle?.map(function (item: IDetalleTransporteGrupo, index) {
                                return <>
                                    <div className="ms-Grid-row" style={{ paddingTop: '5%' }}>
                                        <Label className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">{item.grupo}</Label>

                                        <TextField
                                            className='ms-Grid-col ms-sm12 ms-md6 ms-lg4'
                                            type='time'
                                            id={item.accesoTransporteGrupoId}
                                            name={`horaHelo`}
                                            placeholder='Hora Sale'
                                            value={item.horaHelo}
                                            onChange={_onChangeText}
                                            autoComplete='off'
                                            required
                                        />
                                    </div>

                                </>
                            })}
                        </form>
                    </div>
                </div>
            </Panel>
        </>
    )
}