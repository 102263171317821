import { Panel, ProgressIndicator, ActivityItem, PrimaryButton, Stack, ComboBox, IComboBox, IComboBoxOption, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar";
import { IItemComboBaseDTO, ISearch } from "../../../../interfaces/base/IBase";
import { IValidarTransporteHelo } from "../../../../interfaces/IProgramacion";
import GrupoService from "../../../../services/grupoService";
import ProgramacionBusService from "../../../../services/programacionBusService";
import ProgramacionPersonaService from "../../../../services/programacionPersonaService";
import { getSortedData, splitExceptionDetail, stackTokens, _initialFilter } from "../../../../utils/Helper/helper";
import { MessageHelper } from "../../../../utils/Helper/messageHelper";
import ThemeBase from "../../../../utils/Theme/themeBase";

let _sel: any[] = [];

export const ValidarTransporteAccesoHelo = ({ programacionId = '', grupoInicial = '', isBajada = false, isOpen = false, dismiss = () => { }, sel = _sel, reloadParent = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Form */
    const [horaHelo, setHoraHelo] = useState('');

    /* Combo */
    const [helo, setHelo] = useState<any[]>([]);
    const [grupo, setGrupo] = useState<any[]>([]);

    const [heloId, setHeloId] = useState<string | undefined>(undefined);
    const [grupoId, setGrupoId] = useState<string | undefined>(undefined);

    /* Filter */
    const [filterHelo, setFilterHelo] = useState<ISearch>(_initialFilter);

    /* Ref */
    const refHelo = React.useRef<IComboBox>(null);
    const _openHelo = React.useCallback(() => refHelo.current?.focus(true), []);

    /* Hook */
    useEffect(() => {
        if (isOpen) {
            setHeloId(undefined);
            dataHelo();
            dataGrupo();
        }

        if (sel.length > 0) {
            let f = getSortedData(sel, 'horaHelo', 0)[0].horaHelo;

            if (f !== '') {
                setHoraHelo(f)
            }

            setGrupoId(grupoInicial);
        }
    }, [isOpen]);

    useEffect(() => {
        if (filterHelo.search != null) {
            dataHelo();
        }
    }, [filterHelo]);

    /* Api */
    const dataHelo = () => {
        ProgramacionBusService.heloVigente(programacionId, filterHelo).then(res => {
            let d = res.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.transporteId,
                    text: x.placa,
                })
            });

            setHelo(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataGrupo = () => {
        GrupoService.allGrupo().then(res => {
            setGrupo(res.data);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const validar = () => {
        if (heloId == '') {
            return alert('Debe de seleccionar un helicoptero');
        }

        if (horaHelo == '' || horaHelo == undefined) {
            return alert('Debe de seleccionar una Hora Helo');
        }

        if (grupoId === undefined) {
            return alert('Debe de seleccionar un grupo para asignar');
        }

        if (programacionId != '') {
            setIsSend(true);
            setChoice(undefined);

            let lst = (isBajada) ? sel.map((x) => x.programacionPersonaId) : sel.map((x) => x.programacionPersonaBusId);

            if (lst.length > 8) {
                setIsSend(false);
                setChoice('error')
                settextError('Limite de asignados por Helo, verificar, Máximo permitido 8');
                return;
            }

            let t: IValidarTransporteHelo = {
                listaId: lst,
                vehiculoId: heloId as string,
                programacionId: programacionId,
                horaHelo: horaHelo,
                grupoId: grupoId,
            };

            /* Caso Helo aprobado hacia transporte */
            if (!isBajada) {
                ProgramacionBusService.asignarHelo(t)
                .then((res) => {
                    setIsSend(false)
                    if (res.status == 200) {
                        reloadParent();
                        dismiss();
                        setChoice('');
                    }
                })
                .catch((e) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
            } else {
                /* Caso Helo Bajada - directo de la inscripcion Helo */
                ProgramacionPersonaService.asignarHeloBajada(t)
                .then((res) => {
                    setIsSend(false)
                    if (res.status == 200) {
                        reloadParent();
                        dismiss();
                        setChoice('');
                    }
                })
                .catch((e) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
            }
        }
    }

    /* Event */
    const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
        let { value } = ev?.target as HTMLInputElement;

        switch (type) {
            case 'helo':
                _openHelo();

                setFilterHelo({
                    ...filterHelo,
                    search: value,
                })
                break;
            default:
                break;
        }
    };

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'helo':
                        setHeloId(String(option.key));
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const _onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let { name } = event?.target as HTMLInputElement;

                switch (name) {
                    case 'horaHelo':
                        setHoraHelo(newValue);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <Stack tokens={stackTokens}>
                    <PrimaryButton onClick={validar} styles={ThemeBase.panelButtonStyle} disabled={isSend || heloId === undefined || horaHelo == undefined || horaHelo == ''}>
                        Validar
                    </PrimaryButton>
                </Stack>
            </div>
        ),
        [programacionId, dismiss, sel, isSend, filterHelo, heloId, grupoId, isBajada, horaHelo]
    );

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText='Asignar a helicóptero'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    resetChoice();
                                }}></CustomMessageBar>}
                            </div>
                        </div>
                    </div>

                    <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                    <ComboBox
                                        label='Helo'
                                        componentRef={refHelo}
                                        placeholder='Helo'
                                        style={{ ...ThemeBase.notPaddingTopCombo, }}
                                        styles={ThemeBase.comboBoxStylesDos}
                                        allowFreeform
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={helo}
                                        onKeyUp={(e) => _onUpCombo('helo', e)}
                                        selectedKey={heloId}
                                        onChange={(e, o) => _onChangeCombo('helo', e, o)}
                                        required
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                    <TextField
                                        type='time'
                                        label='Hora Programada Helo'
                                        name='horaHelo'
                                        placeholder='Hora Programada Helo'
                                        value={horaHelo}
                                        onChange={_onChangeText}
                                        autoComplete='off'
                                        required
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                    <ComboBox
                                        label='Grupo'
                                        placeholder='Grupo'
                                        style={{ ...ThemeBase.notPaddingTopCombo, }}
                                        styles={ThemeBase.comboBoxStylesDos}
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={grupo}
                                        onKeyUp={(e) => _onUpCombo('grupo', e)}
                                        selectedKey={grupoId}
                                        onChange={(e, o) => _onChangeCombo('grupo', e, o)}
                                        // disabled
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        {sel.map((item: { key: string | number }) => (
                            <ActivityItem
                                {...item}
                                key={item.key}
                                className={ThemeBase.activityClass.exampleRoot}
                            />
                        ))}
                    </form>

                </>
            </Panel>
        </>
    )
}