import { IItemComboBaseDTO } from "../interfaces/base/IBase";
import { IRetirarPersonaMasivo, ISharedProgramacionPersona } from "../interfaces/IProgramacion";
import { fetchMovilizacion } from "./http-common";

const urlMotivoRetiro = 'MotivoRetiro';
const urlProgramacionPersona = 'AccesoProgramadoPersona';


const all = () => {
    return fetchMovilizacion.get<IItemComboBaseDTO[]>(urlMotivoRetiro);
}

const retirarPersona = (data: ISharedProgramacionPersona) => {
    return fetchMovilizacion.put(`${urlProgramacionPersona}/Retirar`, data);
}

const retirarPersonaMasivo = (data: IRetirarPersonaMasivo) => {
    return fetchMovilizacion.put(`${urlProgramacionPersona}/Retirar/Masivo`, data);
}

const retirarAdicional = (data: IRetirarPersonaMasivo) => {
    return fetchMovilizacion.put(`${urlProgramacionPersona}/Adicional/Retiro`, data);
}

const MotivoService = {
    all,
    retirarPersona,
    retirarPersonaMasivo,
    retirarAdicional
}

export default MotivoService;