import {
  Checkbox,
  ComboBox,
  DefaultButton,
  DetailsListLayoutMode,
  DialogType,
  IColumn,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  Icon,
  IconButton,
  Modal,
  Panel,
  PanelType,
  Persona,
  PersonaSize,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  TextField,
  Toggle,
  TooltipHost,
} from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { HeadingBar } from "../../../../components/Generic/HeadingBar";
import { TableBase } from "../../../../components/Table/TableBase";
import { IconHelper } from "../../../../utils/Helper/iconHelper";
import { useBoolean } from "@fluentui/react-hooks";
import { ModalHelper } from "../../../../utils/Helper/modalHelper";
import {
  splitExceptionDetail,
  stackTokens,
  stackTokensHorizontal,
  _initialFilter,
  _initialPagination,
  CONTIENE_NUMERO,
  CONTIENE_MINUSCULA,
  CONTIENE_MAYUSCULA,
  CONTIENE_NO_ALFANUMERICO,
  EXPRESION_EMAIL,
} from "../../../../utils/Helper/helper";
import {
  IAddRolIdentity,
  ICreateEmpresaUsuario,
  IDisabledUser,
  ISharedUsuario,
} from "../../../../interfaces/IUsuario";
import { ButtonForm } from "../../../../components/Generic/buttonForm";
import ContratoService from "../../../../services/contratoService";
import { useNavigate, useParams } from "react-router-dom";
import {
  ICollectionUsuarioContratista,
  IUpdatePasswordUsuarioEmpresa,
  IUsuarioContratista,
} from "../../../../interfaces/IContrato";
import ThemeBase from "../../../../utils/Theme/themeBase";
import {
  ICheckItemComboBaseDTO,
  IException,
} from "../../../../interfaces/base/IBase";
import ConfiguracionService from "../../../../services/configuracionService";
import React from "react";
import { _initialDataCollection } from "../../../../utils/Helper/tableHelper";
import { AuthContext } from "../../../../auth/context/auth-context";
import {
  isRoleAccessAll,
  roleAdmin,
} from "../../../../utils/Helper/roleHelper";
import { MessageHelper } from "../../../../utils/Helper/messageHelper";
import { DialogBase } from "../../../../components/Dialog/DialogBase";
import { ConfigHelper } from "../../../../utils/Helper/configHelper";
import { ColorHelper } from "../../../../utils/Helper/colorHelper";
import { IPagination } from "../../../../interfaces/base/ITable";
import { Ifilter } from "../../../../interfaces/IFilter";
import { Label } from "@fluentui/react-components";
import { IPoliticas } from "../../../../../../asistencia/src/Interfaces/base/IBase";
import usuarioServicio from "../../../../services/UsuarioService";

const _initialUser: ICollectionUsuarioContratista = {
  ..._initialDataCollection(),
  empresa: "",
};

export const ListarEmpresaUsuario = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [hidden, setHidden] = useState(false);
  const [column, setColumn] = useState<IColumn[]>([]);
  const [empresa, setEmpresaUsuario] =
    useState<ICollectionUsuarioContratista>(_initialUser);
  const [formRegistrar, setFormRegistrar] = useState<ICreateEmpresaUsuario>(
    null!
  );
  const [rol, setRol] = useState<ICheckItemComboBaseDTO[]>([]);
  const [sel, setSel] = useState<ISharedUsuario>(null!);
  const [formRol, setFormRol] = useState<IAddRolIdentity>(null!);
  const [rolAssigned, setRoleAssigned] = useState<ICheckItemComboBaseDTO[]>([]);

  /* Filter */
  const [filter, setFilter] = useState<Ifilter>(_initialFilter);

  /* Modal */
  const [
    isOpenRegistrarUsuario,
    { setTrue: showRegistrarUsuario, setFalse: hideRegistrarUsuario },
  ] = useBoolean(false);
  const [isOpenRol, { setTrue: showRol, setFalse: hideRol }] =
    useBoolean(false);
  const [
    isChangePassword,
    { setTrue: showChangePassword, setFalse: hideChangePassword },
  ] = useBoolean(false);

  /* Panel */
  const [isOpenPanel, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  /* Loading */
  const [
    isLoadingSendUser,
    { setTrue: loadingSendUser, setFalse: dismissLoadingSendUser },
  ] = useBoolean(false);
  const [
    isLoadingDisabledUser,
    { setTrue: loadingDisabledUser, setFalse: dismissLoadingDisabledUser },
  ] = useBoolean(false);

  const [
    isLoadingEnabledUser,
    { setTrue: loadingEnabledUser, setFalse: dismissLoadingEnabledUser },
  ] = useBoolean(false);

  const [
    isLoadingChangePassword,
    { setTrue: loadingChangePassword, setFalse: dismissLoadingChangePassword },
  ] = useBoolean(false);

  /* Dialog */
  const [hideBaja, { toggle: toggleBaja }] = useBoolean(true);
  const [userDisabled, setUserDisabled] = useState<IDisabledUser>(null!);

  //Habilitar Usuario
  const [hideAlta, { toggle: toggleAlta }] = useBoolean(true);
  const [userEnabled, setUserEnabled] = useState<IDisabledUser>(null!);

  /* Access & Role */
  const { access } = useContext(AuthContext);
  let role = access?.role ?? [];

  let isAdmin = isRoleAccessAll(role, roleAdmin());

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  // Valicación de contraseña
  const [disableForm, setDisableForm] = useState(true);

  const [reqDigito, setReqDigito] = useState(true);
  const [reqMinuscula, setReqMinuscula] = useState(true);
  const [reqNoAlfanumerico, setReqNoAlfanumerico] = useState(true);
  const [reqMayuscula, setReqMayuscula] = useState(true);
  const [cumpleLongitud, setCumpleLongitud] = useState(false);
  const [cumplePoliticas, setCumplePoliticas] = useState(false);
  const [politicas, setPoliticas] = useState<IPoliticas>(null!);

  const [auxAutogenerar, setAuxAutogenerar] = useState(true);

  //Setear datos PoliticasContrasenia
  const dataApiPoliticas = () => {
    usuarioServicio.politicasContrasenia().then((res) => {
      if (res.status == 200) {
        // let arrays: IItemComboBaseDTO[] = res.data;
        // setComboGuardia(arrays);
        setPoliticas(res.data);
      }
    });
  };

  // Validar politicas contraseña ingresada
  const validarContrasenia = () => {
    if (politicas != null) {
      var contrasenia = formRegistrar?.password ? formRegistrar.password : "";
      if (contrasenia == "") {
        setCumpleLongitud(false);
      } else {
        if (contrasenia.length >= politicas.longitud) {
          setCumpleLongitud(true);
        } else setCumpleLongitud(false);
        if (politicas.requiereDigito) {
          if (CONTIENE_NUMERO.test(contrasenia)) {
            setReqDigito(true);
          } else setReqDigito(false);
        } else setReqDigito(true);

        if (politicas.requiereMinuscula) {
          if (CONTIENE_MINUSCULA.test(contrasenia)) {
            setReqMinuscula(true);
          } else setReqMinuscula(false);
        } else setReqMinuscula(true);

        if (politicas.requiereMayuscula) {
          if (CONTIENE_MAYUSCULA.test(contrasenia)) {
            setReqMayuscula(true);
          } else setReqMayuscula(false);
        } else setReqMayuscula(true);

        if (politicas.requiereNoAlfanumerico) {
          if (CONTIENE_NO_ALFANUMERICO.test(contrasenia)) {
            setReqNoAlfanumerico(true);
          } else setReqNoAlfanumerico(false);
        } else setReqNoAlfanumerico(true);
      }
    }
  };

  const validarPoliticas = () => {
    if (
      reqDigito &&
      reqMinuscula &&
      reqNoAlfanumerico &&
      reqMayuscula &&
      cumpleLongitud
    ) {
      setCumplePoliticas(true);
    } else setCumplePoliticas(false);
  };

  const validarForm = () => {
    if (auxAutogenerar) {
      if (
        formRegistrar &&
        EXPRESION_EMAIL.test(formRegistrar.email) &&
        formRegistrar.nombre
      ) {
        setDisableForm(false);
      } else setDisableForm(true);
    } else {
      if (
        formRegistrar &&
        EXPRESION_EMAIL.test(formRegistrar.email) &&
        formRegistrar.nombre &&
        cumplePoliticas == true
      ) {
        setDisableForm(false);
      } else setDisableForm(true);
    }
  };

  useEffect(() => {
    if (isOpenRegistrarUsuario) {
      dataApiPoliticas();
    }
  }, [isOpenRegistrarUsuario]);

  useEffect(() => {
    if (!auxAutogenerar) {
      validarContrasenia();
    }
  }, [formRegistrar]);

  useEffect(() => {
    validarPoliticas();
  }, [
    reqDigito,
    reqMinuscula,
    reqNoAlfanumerico,
    reqMayuscula,
    cumpleLongitud,
  ]);

  useEffect(() => {
    validarForm();
  }, [auxAutogenerar, formRegistrar, cumplePoliticas]);

  /* Hook */
  useEffect(() => {
    if (id != undefined) {
      reloadInitial();

      setFormRegistrar({
        ...formRegistrar,
        empresaId: id,
        password: null,
      });
    }
  }, [id]);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  useEffect(() => {
    if (id != undefined) {
      if (isOpenRegistrarUsuario) {
        setFormRegistrar({
          ...formRegistrar,
          empresaId: id,
        });
      }
    }

    if (!isOpenRegistrarUsuario) {
      setFormRegistrar(null!);
    }
  }, [isOpenRegistrarUsuario]);

  useEffect(() => {
    if (isOpenRol) {
      allRol();
    }
  }, [isOpenRol]);

  useEffect(() => {
    if (isOpenPanel) {
      allRol();
    } else {
      setFormRol(null!);
    }
  }, [isOpenPanel]);

  /* Api */
  const dataEmpresaUsuario = (param = filter) => {
    if (id != undefined) {
      setEmpresaUsuario(_initialUser);

      ContratoService.allEmpresaUsuario(id, param)
        .then((res) => {
          if (res.status == 200) {
            setEmpresaUsuario(res.data);
            setTotal(res.data.total);
          }

          setHidden(true);
        })
        .catch((e) => {
          setHidden(true);
          alert(splitExceptionDetail(e));
        });
    }
  };

  const crearUsuarioEmpresa = () => {
    loadingSendUser();

    ContratoService.crearEmpresaUsuario(formRegistrar)
      .then((res) => {
        if (res.status == 200) {
          reloadInitial();
          dismissLoadingSendUser();
          hideRegistrarUsuario();
        }
      })
      .catch((e) => {
        alert(splitExceptionDetail(e));
        dismissLoadingSendUser();
      });
  };

  const allRol = () => {
    ConfiguracionService.allRol()
      .then((res) => {
        if (res.status == 200) {
          let r: ICheckItemComboBaseDTO[] = res.data;

          let t = r.map((item) => {
            let exis = rolAssigned.find((x) => x.key == item.key);

            return {
              ...exis,
              key: item.key,
              text: item.text,
              isChecked: exis != undefined,
            };
          });

          setRol(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const agregarRol = () => {
    ConfiguracionService.asignarRolByIdentity(formRol)
      .then((res) => {
        if (res.status == 200) {
          alert("Se registró correctamente");
          reloadInitial();
          hideRol();
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const actualizarRolUser = () => {
    let t: IAddRolIdentity = {
      identityId: String(sel.identityUserId),
      rol: rol.filter((x) => x.isChecked).map((x) => x.key),
    };

    ConfiguracionService.actualizarRolUser(t)
      .then((res) => {
        if (res.status == 200) {
          alert("Se actualizó correctamente");
          reloadInitial();
          dismissPanel();
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const actualizarContrasenia = () => {
    loadingChangePassword();

    let f: IUpdatePasswordUsuarioEmpresa = {
      personaRolId: String(sel.id),
    };

    ContratoService.actualizarContraseniaUsuarioEmpresa(f)
      .then((res) => {
        if (res.status == 200) {
          reloadInitial();
          dismissLoadingChangePassword();
          hideChangePassword();
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        dismissLoadingChangePassword();
      });
  };

  const darBaja = () => {
    loadingDisabledUser();

    ConfiguracionService.darBaja(userDisabled)
      .then((res) => {
        if (res.status == 200) {
          reloadInitial();
          dismissLoadingDisabledUser();
          toggleBaja();
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        dismissLoadingDisabledUser();
      });
  };

  const darAlta = () => {
    loadingEnabledUser();

    ConfiguracionService.habilitar(userEnabled)
      .then((res) => {
        if (res.status == 200) {
          reloadInitial();
          dismissLoadingEnabledUser();
          toggleAlta();
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        dismissLoadingEnabledUser();
      });
  };

  /* Function */
  const reloadInitial = (param = filter) => {
    setHidden(false);
    setTitleColumn();
    dataEmpresaUsuario(param);
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        onClick: () => {
          navigate(`${ConfigHelper.urlBase()}/configuraciones/empresas`);
        },
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => reloadInitial(),
      },
      {
        ...IconHelper.ICON_ADD,
        onClick: showRegistrarUsuario,
      },
    ];
    return _items;
  };

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Nombre",
        fieldName: "nombreSuper",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Email",
        fieldName: "correo",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Roles",
        fieldName: "textRole",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: "Bloqueado",
        fieldName: "bloqueado",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: IUsuarioContratista) => (
          <>
            <span style={ThemeBase.justifiedStyle}>
              {item.isLocked ? "Si" : "No"}
            </span>
          </>
        ),
      },
      {
        key: "column6",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IUsuarioContratista) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              <>
                {/* v2 - Panel */}
                <TooltipHost content={`Roles`}>
                  <Icon
                    iconName={IconHelper.ICON_ADD.iconProps?.iconName}
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      setSel({
                        ...sel,
                        identityUserId: item.identityId,
                        text: item.nombreSuper,
                        secondaryText: item.correo,
                      });

                      setRoleAssigned(item.role);

                      openPanel();
                    }}
                  />
                </TooltipHost>

                <TooltipHost content={`Cambiar Contraseña`}>
                  <Icon
                    iconName={"Permissions"}
                    style={{
                      cursor: "pointer",
                      color: ThemeBase.theme.palette.yellowDark,
                    }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      setSel({
                        ...sel,
                        id: item.id,
                        identityUserId: item.identityId,
                        text: item.nombreSuper,
                        secondaryText: item.correo,
                      });

                      showChangePassword();
                    }}
                  />
                </TooltipHost>

                <TooltipHost
                  content={item.isLocked ? "Bloqueado" : "Bloquear Usuario"}>
                  <Icon
                    iconName="LockSolid"
                    style={{
                      cursor: "pointer",
                      color: item.isLocked
                        ? ColorHelper.DANGER
                        : ColorHelper.SUCCESS,
                    }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      if (item.isLocked) {
                        //return alert("Usuario ya se encuentra bloqueado");
                        setUserEnabled({
                          identityUserId: item.identityId as string,
                        });
                        toggleAlta();

                      }else{
                        setUserDisabled({
                          identityUserId: item.identityId as string,
                        });

                        toggleBaja();
                      }
                    }}
                  />
                </TooltipHost>
              </>
            </Stack>
          </>
        ),
      },
    ];

    setColumn(col);
  };

  /* Event */
  const _onChangeText = (
    type: string,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        switch (type) {
          case "nombre":
            setFormRegistrar({
              ...formRegistrar,
              nombre: newValue,
            });
            break;
          case "email":
            setFormRegistrar({
              ...formRegistrar,
              email: newValue,
            });
            break;
          case "password":
            setFormRegistrar({
              ...formRegistrar,
              password: newValue,
            });
            break;
          case "celular":
            setFormRegistrar({
              ...formRegistrar,
              celular: newValue,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "sexo":
            setFormRegistrar({
              ...formRegistrar,
              sexo: String(option.key),
            });
            break;
          case "rol":
            setFormRol({
              ...formRol,
              rol: [String(option.key)],
            });
            break;
          default:
            break;
        }
      }
    }
  };

  function _onChangeCheck(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) {
    if (isChecked != undefined) {
      let val = ev?.target as HTMLInputElement;

      /* Setear checked */
      let t = rol.map((item) => {
        if (item.key == val.id) {
          item = { ...item, isChecked: isChecked };
        }
        return item;
      });

      setRol(t);
    }
  }

  function _onChangeToogglePassword(
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) {
    let p: string | null = null;

    if (checked) {
      if (formRegistrar && formRegistrar.autogenerar) {
        p = formRegistrar.password ? formRegistrar.password : null;
      }
    }

    setFormRegistrar({
      ...formRegistrar,
      autogenerar: checked ? true : false,
      password: p,
    });
    setAuxAutogenerar(checked ? false : true);
  }

  /* Panel - Rol */
  const footerPanelRol = React.useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={actualizarRolUser}
          styles={ThemeBase.panelButtonStyle}>
          Confirmar
        </PrimaryButton>
        <DefaultButton onClick={dismissPanel}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissPanel, sel, rol]
  );

  /* Event Pagination */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: Ifilter = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      };

      setFilter(f);
      setCurrentPage(page);

      reloadInitial(f);
    }
  };

  /* Event Filter */
  const _onChangeTextFilter = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      if (newValue === "") {
        _onClearFilter();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        });
      }
    }
  };

  const _onPressFilter = (newValue: string) => {
    let f: Ifilter = {
      ...filter,
      search: newValue,
    };

    setFilter(f);
    reloadInitial(f);
  };

  const _onClearFilter = () => {
    let f: Ifilter = {
      ..._initialFilter(),
    };

    setFilter(f);
    reloadInitial(f);
  };

  return (
    <>
      <>
        <HeadingBar
          title={`${empresa.empresa}`}
          subTitle="Gestión de usuarios"
          searchChange={_onChangeTextFilter}
          searchHandler={_onPressFilter}
          searchValue={filter.search ?? ""}
          onClearHandler={_onClearFilter}></HeadingBar>

        <TableBase
          leftBar={_leftBar()}
          column={column}
          data={empresa?.items}
          layout={DetailsListLayoutMode.justified}
          pagination={pager}
          isLoading={!hidden}></TableBase>

        {/* Modal Agregar Usuario */}
        <Modal
          isOpen={isOpenRegistrarUsuario}
          onDismiss={hideRegistrarUsuario}
          isBlocking={false}
          containerClassName={ModalHelper.modalStyle.container}>
          <form>
            <div className={ModalHelper.modalStyle.header}>
              <span id={""}>Nuevo Usuario</span>
              <IconButton
                styles={ModalHelper.modalIconButtonStyles}
                iconProps={ModalHelper.modalCancelIcon}
                ariaLabel="Close popup modal"
                onClick={hideRegistrarUsuario}
              />
            </div>

            <div
              className={ModalHelper.modalStyle.body}
              style={{ minWidth: 500 }}>
              <Stack tokens={stackTokens}>
                {isLoadingSendUser && (
                  <ProgressIndicator
                    description={MessageHelper.MESSAGE_LOADING}
                  />
                )}

                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                      <TextField
                        label="Nombre Usuario"
                        placeholder="Nombre Usuario"
                        value={formRegistrar ? formRegistrar.nombre : ""}
                        onChange={(e, o) => _onChangeText("nombre", e, o)}
                        autoComplete="off"
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                      <TextField
                        label="Email"
                        placeholder="Email"
                        value={formRegistrar ? formRegistrar.email : ""}
                        onChange={(e, o) => _onChangeText("email", e, o)}
                        autoComplete="off"
                      />
                      <div
                        hidden={
                          formRegistrar?.email
                            ? EXPRESION_EMAIL.test(formRegistrar.email)
                            : false
                        }>
                        <Label
                          required
                          style={{ color: "red", fontSize: "smaller" }}>
                          - Ingrese un email válido
                        </Label>
                      </div>
                    </div>
                  </div>
                </div>

                {isAdmin && (
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Toggle
                          label="Seleccionar Contraseña"
                          placeholder="Contraseña"
                          onText="Usar Constraseña"
                          offText="Generar"
                          onChange={_onChangeToogglePassword}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {formRegistrar && formRegistrar.autogenerar && (
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md7 ms-lg7">
                        <TextField
                          label="Contraseña"
                          placeholder="Contraseña"
                          value={
                            formRegistrar
                              ? formRegistrar.password?.toString()
                              : ""
                          }
                          onChange={(e, o) => _onChangeText("password", e, o)}
                          autoComplete="off"
                        />
                        <div hidden={cumpleLongitud}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Longitud mínima de {politicas?.longitud}{" "}
                            caracteres
                          </Label>
                        </div>
                        <div hidden={reqNoAlfanumerico}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Se requiere al menos un carácter no alfanumérico
                          </Label>
                        </div>
                        <div hidden={reqMinuscula}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Se requiere al menos una letra minúscula
                          </Label>
                        </div>
                        <div hidden={reqMayuscula}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Se requiere al menos una letra mayúscula
                          </Label>
                        </div>
                        <div hidden={reqDigito}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Se requiere al menos un dígito numérico
                          </Label>
                        </div>
                      </div>

                      {/* <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <ComboBox
                                                label='Sexo'
                                                autoComplete='off'
                                                useComboBoxAsMenuWidth
                                                calloutProps={{ directionalHintFixed: true }}
                                                options={optionSexo()}
                                                selectedKey={formRegistrar ? formRegistrar.sexo : ''}
                                                onChange={(e, o) => _onChangeCombo('sexo', e, o)}
                                                required
                                            />
                                        </div> */}
                    </div>
                  </div>
                )}

                {/* <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <TextField
                                                label='Celular'
                                                placeholder='Celular'
                                                value={formRegistrar ? formRegistrar.celular : ''}
                                                onChange={(e, o) => _onChangeText('celular', e, o)}
                                                autoComplete="off" />
                                        </div>
                                    </div>
                                </div> */}

                <ButtonForm
                  back={hideRegistrarUsuario}
                  register={crearUsuarioEmpresa}
                  //disabledRegister={isLoadingSendUser}
                  disabledRegister={disableForm}></ButtonForm>
              </Stack>
            </div>
          </form>
        </Modal>

        {/* OBSOLETO */}
        {/* Modal Asignar Roles */}
        <Modal
          isOpen={isOpenRol}
          onDismiss={hideRol}
          isBlocking={false}
          containerClassName={ModalHelper.modalStyle.container}>
          <form>
            <div className={ModalHelper.modalStyle.header}>
              <span id={""}>Asignar Rol</span>
              <IconButton
                styles={ModalHelper.modalIconButtonStyles}
                iconProps={ModalHelper.modalCancelIcon}
                ariaLabel="Close popup modal"
                onClick={hideRol}
              />
            </div>

            <div
              className={ModalHelper.modalStyle.body}
              style={{ minWidth: 500 }}>
              <Stack tokens={stackTokens}>
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <Persona
                        {...sel}
                        size={PersonaSize.size40}
                        hidePersonaDetails={false}
                        imageAlt="Annie Lindqvist, status is away"
                      />
                    </div>
                  </div>
                </div>

                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <ComboBox
                        label="Roles"
                        autoComplete="on"
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={rol}
                        selectedKey={
                          formRol ? (formRol.rol ? formRol.rol[0] : "") : ""
                        }
                        onChange={(e, o) => _onChangeCombo("rol", e, o)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <ButtonForm back={hideRol} register={agregarRol}></ButtonForm>
              </Stack>
            </div>
          </form>
        </Modal>

        {/* Panel Rol */}
        <Panel
          isOpen={isOpenPanel}
          onDismiss={dismissPanel}
          headerText="Gestión de Roles"
          closeButtonAriaLabel="Close"
          type={PanelType.medium}
          onRenderFooterContent={footerPanelRol}
          isFooterAtBottom={true}>
          <div>
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <Stack tokens={{ childrenGap: 20 }}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Persona
                      {...sel}
                      size={PersonaSize.size40}
                      hidePersonaDetails={false}
                      imageAlt="Annie Lindqvist, status is away"
                    />
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Stack tokens={stackTokens}>
                      {rol?.map(function (item: ICheckItemComboBaseDTO) {
                        return (
                          <Checkbox
                            id={`${item.key}`}
                            key={`c_${item.key}`}
                            label={item.text}
                            onChange={_onChangeCheck}
                            checked={item.isChecked}
                          />
                        );
                      })}
                    </Stack>
                  </div>
                </Stack>
              </div>
            </div>
          </div>
        </Panel>

        <Modal
          isOpen={isChangePassword}
          onDismiss={hideChangePassword}
          isBlocking={false}
          containerClassName={ModalHelper.modalStyle.container}>
          <form>
            <div className={ModalHelper.modalStyle.header}>
              <span id={""}>Actualizar Contraseña</span>
              <IconButton
                styles={ModalHelper.modalIconButtonStyles}
                iconProps={ModalHelper.modalCancelIcon}
                ariaLabel="Close popup modal"
                onClick={hideChangePassword}
              />
            </div>

            <div
              className={ModalHelper.modalStyle.body}
              style={{ minWidth: 500, paddingBottom: "0px" }}>
              <Stack tokens={stackTokens}>
                {isLoadingChangePassword && (
                  <ProgressIndicator
                    label={""}
                    description={MessageHelper.MESSAGE_LOADING}
                  />
                )}

                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <Persona
                        {...sel}
                        size={PersonaSize.size40}
                        hidePersonaDetails={false}
                        imageAlt="Annie Lindqvist, status is away"
                      />
                    </div>
                  </div>
                </div>

                <ButtonForm
                  back={hideChangePassword}
                  textRegister="Actualizar"
                  register={actualizarContrasenia}
                  disabledRegister={isLoadingChangePassword}></ButtonForm>
              </Stack>
            </div>
          </form>
        </Modal>

        <>

        {/* Dialog dar de baja Usuario */}
        <DialogBase
          isHidden={hideBaja}
          subTitle={`El usuario será dado de baja permanentemente`}
          tipo={DialogType.normal}
          toggleHidden={() => {
            dismissLoadingDisabledUser();
            toggleBaja();
          }}
          dependency={[]}
          confirm={darBaja}
          isDisableConfirm={isLoadingDisabledUser}></DialogBase>

          {/* Dialog dar de alta al Usuario */}  
          <DialogBase
            isHidden={hideAlta}
            subTitle={`El usuario será dado de alta`}
            tipo={DialogType.normal}
            toggleHidden={() => {
              dismissLoadingEnabledUser();
              toggleAlta();
            }}
            dependency={[]}
            confirm={darAlta}
            isDisableConfirm={isLoadingEnabledUser}></DialogBase>
          </>
      </>
    </>
  );
};
