import {
  DetailsListLayoutMode,
  FontIcon,
  IColumn,
  ICommandBarItemProps,
  Icon,
  IconButton,
  Label,
  Modal,
  Persona,
  PersonaSize,
  Stack,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { TableBase } from "../../../components/Table/TableBase";
import { IException } from "../../../interfaces/base/IBase";
import {
  IActualizarContrasenia,
  IActualizarContraseniaPoliticas,
  ISharedUsuario,
  IUsuario,
} from "../../../interfaces/IUsuario";
import ConfiguracionService from "../../../services/configuracionService";
import {
  splitExceptionDetail,
  stackTokens,
  stackTokensHorizontal,
  _initialFilter,
  _initialPagination,
  CONTIENE_NUMERO,
  CONTIENE_MINUSCULA,
  CONTIENE_MAYUSCULA,
  CONTIENE_NO_ALFANUMERICO,
} from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { ModalHelper } from "../../../utils/Helper/modalHelper";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { Ifilter } from "../../../interfaces/IFilter";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import { validatePassword } from "../../../utils/Helper/passwordValidator";
import { IPoliticas } from "../../../../../asistencia/src/Interfaces/base/IBase";
import usuarioServicio from "../../../services/UsuarioService";

export const ListarUsuario = () => {
  const [usuario, setUsuario] = useState<IDataCollection<IUsuario>>(
    _initialDataCollection
  );
  const [hidden, setHidden] = useState(false);
  const [column, setColumn] = useState<IColumn[]>([]);

  /* Change Password */
  const [sel, setSel] = useState<ISharedUsuario>(null!);
  const [equal, setEqual] = useState(false);
  const [form, setForm] = useState<IActualizarContraseniaPoliticas>({
    password: "",
    confirmPassword: "",
    usuarioId: "",
  });
  const [isOpenChange, { setTrue: showChange, setFalse: hideChange }] =
    useBoolean(false);

  /* Filter */
  const [filter, setFilter] = useState<Ifilter>(_initialFilter);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  // Politicas Contraseña
  const [reqDigito, setReqDigito] = useState(true);
  const [reqMinuscula, setReqMinuscula] = useState(true);
  const [reqNoAlfanumerico, setReqNoAlfanumerico] = useState(true);
  const [reqMayuscula, setReqMayuscula] = useState(true);
  const [cumpleLongitud, setCumpleLongitud] = useState(false);
  const [cumplePoliticas, setCumplePoliticas] = useState(false);

  const [coincideContrasenia, setCoincideContrasenia] = useState(true);
  const [confContrasenia, setConfContrasenia] = useState("");
  const [disableForm, setDisableForm] = useState(true);

  const [politicas, setPoliticas] = useState<IPoliticas>(null!);

  //Setear datos PoliticasContrasenia
  const dataApiPoliticas = () => {
    usuarioServicio.politicasContrasenia().then((res) => {
      if (res.status == 200) {
        // let arrays: IItemComboBaseDTO[] = res.data;
        // setComboGuardia(arrays);
        setPoliticas(res.data);
      }
    });
  };

  const validarInputs = () => {
    if (politicas != null && form != null) {
      if (form.confirmPassword.length >= 1) {
        //setConfContrasenia("");
        setForm({ ...form, confirmPassword: "" });
      }
      if (form.password.length >= politicas.longitud) {
        setCumpleLongitud(true);
      } else setCumpleLongitud(false);

      if (politicas.requiereDigito) {
        if (CONTIENE_NUMERO.test(form.password)) {
          setReqDigito(true);
        } else setReqDigito(false);
      } else setReqDigito(true);

      if (politicas.requiereMinuscula) {
        if (CONTIENE_MINUSCULA.test(form.password)) {
          setReqMinuscula(true);
        } else setReqMinuscula(false);
      } else setReqMinuscula(true);

      if (politicas.requiereMayuscula) {
        if (CONTIENE_MAYUSCULA.test(form.password)) {
          setReqMayuscula(true);
        } else setReqMayuscula(false);
      } else setReqMayuscula(true);

      if (politicas.requiereNoAlfanumerico) {
        if (CONTIENE_NO_ALFANUMERICO.test(form.password)) {
          setReqNoAlfanumerico(true);
        } else setReqNoAlfanumerico(false);
      } else setReqNoAlfanumerico(true);
    }
  };

  const validarPoliticas = () => {
    if (
      reqDigito &&
      reqMinuscula &&
      reqNoAlfanumerico &&
      reqMayuscula &&
      cumpleLongitud
    ) {
      setCumplePoliticas(true);
    } else setCumplePoliticas(false);
  };

  const validarForm = () => {
    if (form.confirmPassword != form.password) {
      setCoincideContrasenia(false);
    } else setCoincideContrasenia(true);

    if (
      form.password != "" &&
      form.confirmPassword != "" &&
      form.confirmPassword == form.password &&
      cumplePoliticas == true
    ) {
      setDisableForm(false);
    } else setDisableForm(true);
  };

  useEffect(() => {
    dataApiPoliticas();
  }, []);

  useEffect(() => {
    validarInputs();
  }, [form.password]);

  useEffect(() => {
    validarPoliticas();
  }, [
    reqDigito,
    reqMinuscula,
    reqNoAlfanumerico,
    reqMayuscula,
    cumpleLongitud,
  ]);

  useEffect(() => {
    validarForm();
  }, [form.confirmPassword]);

  /* Hooks */
  useEffect(() => {
    reloadInitial();
  }, []);

  useEffect(() => {
    if (form) {
      setEqual(form.password === form.confirmPassword);
    }
  }, [form]);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  /* Api */
  const dataUsuario = (param = filter) => {
    setUsuario(_initialDataCollection);
    ConfiguracionService.allUsuario(param)
      .then((res) => {
        if (res.status == 200) {
          setUsuario(res.data);
          setTotal(res.data.total);
        }
        setHidden(true);
      })
      .catch((e: IException) => {
        setHidden(true);
        alert(splitExceptionDetail(e));
      });
  };

  const changePassword = () => {
    if (form) {
      let { password, ...newForm } = form;

      ConfiguracionService.actualizarContraseniaUsario(newForm)
        .then((res) => {
          if (res.status == 200) {
            alert("Se actualizó correctamente");
          }
          hideChange();
          reloadInitial();
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  /* Function */
  const reloadInitial = (param = filter) => {
    setHidden(false);
    dataUsuario(param);
    setTitleColumn();
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => reloadInitial(),
      },
    ];
    return _items;
  };

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Nombre",
        fieldName: "nombre",
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Email",
        fieldName: "email",
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IUsuario) => (
          <>
            <div>
              <Stack horizontal tokens={stackTokensHorizontal}>
                <>
                  <TooltipHost content={`Cambiar Contraseña`}>
                    <Icon
                      iconName="AuthenticatorApp"
                      style={{ cursor: "pointer" }}
                      className={ThemeBase.classes.iconFont}
                      onClick={() => {
                        setForm({
                          usuarioId: item.usuarioId,
                          password: "",
                          confirmPassword: "",
                        });

                        setSel({
                          ...sel,
                          usuarioId: item.usuarioId,
                          text: item.nombre,
                          secondaryText: item.email,
                        });
                        showChange();
                      }}
                    />
                  </TooltipHost>
                </>
              </Stack>
            </div>
          </>
        ),
      },
    ];

    setColumn(col);
  };

  /* Event */
  const _onChangeText = (
    type: string,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        switch (type) {
          case "password":
            setForm({
              ...form,
              password: newValue,
            });
            break;
          case "confirmPassword":
            setForm({
              ...form,
              confirmPassword: newValue,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  /* Event Pagination */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: Ifilter = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      };

      setFilter(f);
      setCurrentPage(page);

      reloadInitial(f);
    }
  };

  /* Event Filter */
  const _onChangeTextFilter = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      if (newValue === "") {
        _onClearFilter();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        });
      }
    }
  };

  const _onPressFilter = (newValue: string) => {
    let f: Ifilter = {
      ...filter,
      search: newValue,
    };

    setFilter(f);
    reloadInitial(f);
  };

  const _onClearFilter = () => {
    let f: Ifilter = {
      ..._initialFilter(),
    };

    setFilter(f);
    reloadInitial(f);
  };

  const getRichErrorMessage = (value: string) => {
    return <>{validatePassword(value)} </>;
  };

  // Previene el pegado
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <HeadingBar
        title="Usuarios"
        subTitle="Gestión de Usuarios"
        searchChange={_onChangeTextFilter}
        searchHandler={_onPressFilter}
        searchValue={filter.search ?? ""}
        onClearHandler={_onClearFilter}></HeadingBar>

      <TableBase
        leftBar={_leftBar()}
        column={column}
        data={usuario.items}
        pagination={pager}
        layout={DetailsListLayoutMode.justified}
        isLoading={!hidden}></TableBase>

      {/* Modal Change Passowrd */}
      <Modal
        isOpen={isOpenChange}
        onDismiss={hideChange}
        isBlocking={false}
        containerClassName={ModalHelper.modalStyle.container}>
        <form>
          <div className={ModalHelper.modalStyle.header}>
            <span id={""}>Cambiar Contraseña</span>
            <IconButton
              styles={ModalHelper.modalIconButtonStyles}
              iconProps={ModalHelper.modalCancelIcon}
              ariaLabel="Close popup modal"
              onClick={hideChange}
            />
          </div>

          <div
            className={ModalHelper.modalStyle.body}
            style={{ minWidth: 400, maxWidth: 400 }}>
            <Stack tokens={stackTokens}>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Persona
                      {...sel}
                      size={PersonaSize.size40}
                      hidePersonaDetails={false}
                      imageAlt="Annie Lindqvist, status is away"
                    />
                  </div>
                </div>
                {politicas && (
                  <>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <TextField
                          type="password"
                          label="Contraseña"
                          placeholder="Contraseña"
                          onChange={(e, o) => _onChangeText("password", e, o)}
                          autoComplete="off"
                          onPaste={handlePaste}
                          //onGetErrorMessage={getRichErrorMessage}
                        />
                        {/* <TextField
                          type="password"
                          label="Confirmar Contraseña"
                          placeholder="Confirmar Contraseña"
                          onChange={(e, o) =>
                            _onChangeText("confirmPassword", e, o)
                          }
                          autoComplete="off"
                        /> */}
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div hidden={cumpleLongitud}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Longitud mínima de {politicas?.longitud}{" "}
                            caracteres
                          </Label>
                        </div>
                        <div hidden={reqNoAlfanumerico}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Se requiere al menos un carácter no alfanumérico
                          </Label>
                        </div>
                        <div hidden={reqMinuscula}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Se requiere al menos una letra minúscula
                          </Label>
                        </div>
                        <div hidden={reqMayuscula}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Se requiere al menos una letra mayúscula
                          </Label>
                        </div>
                        <div hidden={reqDigito}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            - Se requiere al menos un dígito numérico
                          </Label>
                        </div>
                      </div>
                      <br />
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <TextField
                          type="password"
                          label="Confirmar Contraseña"
                          placeholder="Confirmar Contraseña"
                          onChange={(e, o) =>
                            _onChangeText("confirmPassword", e, o)
                          }
                          onPaste={handlePaste}
                          autoComplete="off"
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div hidden={coincideContrasenia}>
                          <Label
                            required
                            style={{ color: "red", fontSize: "smaller" }}>
                            La contraseña no coincide
                          </Label>
                        </div>
                      </div>
                    </div>
                    <br />
                    <ButtonForm
                      back={hideChange}
                      register={changePassword}
                      //disabledRegister={!equal}
                      disabledRegister={disableForm}></ButtonForm>
                  </>
                )}
              </div>
            </Stack>
          </div>
        </form>
      </Modal>
    </>
  );
};
