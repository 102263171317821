import { DialogType, IActivityItemProps, ICommandBarItemProps, Icon, Link, PanelType, Persona, PersonaSize, TooltipHost, ComboBox, IComboBox, IComboBoxOption, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useImperativeHandle, useState } from "react"
import { TableBase } from "../../../../components/Table/TableBase"
import { IconHelper } from "../../../../utils/Helper/iconHelper";
import ThemeBase from "../../../../utils/Theme/themeBase";
import { CrearAccesoVuelo } from "../panel/crearAccesoVuelo";
import { useBoolean } from "@fluentui/react-hooks";
import { IDataCollection, IPagination } from "../../../../interfaces/base/ITable";
import { _initialDataCollection } from "../../../../utils/Helper/tableHelper";
import { IEmailHeloValido, IFilterProgramacion, IProgramacionPersona } from "../../../../interfaces/IProgramacion";
import ProgramacionPersonaService from "../../../../services/programacionPersonaService";
import { baseImageUrl, splitExceptionDetail, stackTokensHorizontal, _initialFilter, _initialPagination } from "../../../../utils/Helper/helper";
import { IColumn, Selection } from "@fluentui/react/lib/DetailsList";
import { ValidarAccesoVuelo } from "../panel/validarAccesoVuelo";
import { AuthContext } from "../../../../auth/context/auth-context";
import { isRoleAccessAll, roleAdmin, ROLE_KEY_USER_EMPLEADO, ROLE_KEY_USER_SOCIO,} from "../../../../utils/Helper/roleHelper";
import { DialogBase } from "../../../../components/Dialog/DialogBase";
import { RetiroMasivoVuelo } from "../panel/retiroMasivoVuelo";
import { PanelPlantillaFiltro } from "../../../../components/PanelUpload/PanelPlantillaFiltro";
import { IItemComboBaseDTO } from '../../../../interfaces/base/IBase';
import { ISearch } from '../../../../../../asistencia/src/Interfaces/base/IBase';
import OrganigramaService from '../../../../services/organigramaService';
import { IException } from '../../../../../../asistencia/src/Interfaces/base/IException';
import { UbicacionHelper } from '../../../../utils/Helper/ubicacionHelper';
import { IFilterUbicacion } from '../../../../interfaces/filter/IFilterUbicacion';
import UbicacionService from '../../../../services/ubicacionesServices';
import { ProgramacionProps } from '../../../../types/ITypes';
import ProgramacionService from '../../../../services/ProgramacionService';
import ContratoService from '../../../../services/contratoService';
import { ActualizarAccesoPersona } from '../../actualizarAccesoPersona';
import { LogActividad } from '../../logActividad/logActividad';

const roleOpcionRetiroMasivo = () => {
    let t = [
        ROLE_KEY_USER_EMPLEADO,
        ROLE_KEY_USER_SOCIO
    ]

    return roleAdmin().concat(t);
}

export const AccesoPersonaVueloPendiente = React.forwardRef((props: ProgramacionProps, forwardedRef) => {
    /* Access & Role */
    const { access } = useContext(AuthContext);
    let role = access?.role ?? [];
    let isAdmin = isRoleAccessAll(role, roleAdmin());
    let opcionRetiroMasivo = isRoleAccessAll(role, roleOpcionRetiroMasivo())

    const [hidden, setHidden] = useState(false);

    const [isOpenCrearAcceso, { setTrue: openCrearAcceso, setFalse: dismissCrearAcceso },] = useBoolean(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);
    const [filter, setFilter] = useState<IFilterProgramacion>({
        ..._initialFilter(),
        programacionId: props.programacionId
    })

    /* Panel Aprobar */
    const [isOpenValidar, { setTrue: openValidar, setFalse: dismissValidar }] = useBoolean(false);
    const [isOpenRetiroMasivo, { setTrue: openRetiroMasivo, setFalse: dismissRetiroMasivo },] = useBoolean(false);
    const [isOpenPanelFiltro, { setTrue: openPanelFiltro, setFalse: dismissPanelFiltro },] = useBoolean(false);

    /* Dialog */
    const [hideEmailCierre, { toggle: toggleEmailCierre }] = useBoolean(true);

    /* Loading */
    const [isLoadingEmail, { setTrue: loadingEmail, setFalse: dismissLoadingEmail }] = useBoolean(false);

    /* Panel Actualizar */
    const [selId, setSelId] = useState<string | undefined>(undefined);
    const [isOpenActualizar, { setTrue: openActualizar, setFalse: dismissActualizar },] = useBoolean(false);

    /* Panel Log */
    const [isOpenLog, { setTrue: openLog, setFalse: dismissLog },] = useBoolean(false);

    /* Selection */
    const [sel, setSel] = useState<any[]>([]);

    const selection = new Selection({
        onSelectionChanged: () => {
            let d: any[] = selection.getSelection();

            let t: IActivityItemProps[] = d
                .filter(x => !x.isDisabled)
                .map((x) => {
                    return {
                        key: `id_${x.programacionPersonaId}`,
                        activityDescription: [
                            <Link key={x.programacionPersonaId} className={ThemeBase.activityClass.nameText}>
                                {x.nombres}
                            </Link>,
                            <span key={`2_${x.programacionPersonaId}`}> </span>,
                            <span
                                key={`3_${x.programacionPersonaId}`}
                                className={ThemeBase.activityClass.nameText}
                            >
                            </span>,
                        ],
                        activityIcon: <Icon iconName={'ReminderPerson'} />,
                        timeStamp: `DNI: ${x.nroDOI}`,
                        programacionPersonaId: `${x.programacionPersonaId}`,
                    };
                });

            setSel(t);
        },
        getKey: (item: any) => item.programacionPersonaId
    });

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => setCurrentPage(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    const [gerenciaCombo, setGerenciaCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterGerencia, setFilterGerencia] = useState<ISearch>({
        search: ""
    });

    const [embarqueCombo, setEmbaqueCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterEmbarque, setFilterEmbarque] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_ORIGEN
    });

    const [destinoCombo, setDestinoCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterDestino, setFilterDestino] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_DESTINO,
    });

    const [lugarMovilizacionCombo, setLugarMovilizacionCombo] = useState<IItemComboBaseDTO[]>([]);
    const LugarMovilizacionRef = React.useRef<IComboBox>(null);
    const _openOptionMovilizacion = React.useCallback(() => LugarMovilizacionRef.current?.focus(true), []);
    const [filterMovilizacion, setfilterMovilizacion] = useState<ISearch>(_initialFilter);

    const [centroCostoCombo, setCentroCostoCombo] = useState<IItemComboBaseDTO[]>([]);

    /* Imperative Hook */
    useImperativeHandle(forwardedRef, () => {
        return {
            filterFromParent: (search = '') => {

                let p = _initialPagination().currentPage;

                let f: IFilterProgramacion = {
                    ..._initialFilter(),
                    programacionId: props.programacionId,
                    skip: (p - 1) * pageSize,
                    take: pageSize,
                    search: search.trim(),
                }

                setFilter(f);
                setCurrentPage(p);
                reloadInitial(f);
            }
        }
    });

    /* Hook */
    useEffect(() => {
        reloadInitial();
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            total: total,
        });
    }, [total]);

    useEffect(() => {
        dataGerencia();
        dataUbicacionOrigen();
        dataUbicacionDestino();

    }, [filterGerencia, filterEmbarque, filterDestino]);

    useEffect(() => {
        if (filterMovilizacion.search != null) {
            dataLugarMovilizacion();
        }
    }, [filterMovilizacion]);

    useEffect(() => {
        dataCentroCosto()
    }, []);


    /* Api */
    const dataVuelo = (param = filter) => {
        setData(_initialDataCollection);

        ProgramacionPersonaService.allAccesoVuelo(param)
            .then((res) => {
                if (res.status == 200) {
                    setData(res.data);
                    setTotal(res.data.total);
                    setHidden(true);
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
                setHidden(true);
            });
    }

    const dataGerencia = () => {
        setGerenciaCombo([]);
        OrganigramaService.organigramaByTipo('01', filterGerencia)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    // t.unshift(ItemNoneSelect());

                    setGerenciaCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataUbicacionOrigen = () => {

        dataUbicacion(filterEmbarque, setEmbaqueCombo);

    }

    const dataUbicacionDestino = () => {

        dataUbicacion(filterDestino, setDestinoCombo);

    }

    const dataUbicacion = (filtro: any, setCombo: React.Dispatch<React.SetStateAction<any>>) => {
        UbicacionService.ubicacionPorFiltro(filtro)
            .then((res) => {
                setCombo(res.data);

            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion()
            .then((res) => {
                // console.log("************** lugar **********");
                // console.log(res.data);
                setLugarMovilizacionCombo(res.data);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataCentroCosto = () => {
        ContratoService.allCentroCosto()
            .then((res) => {
                setCentroCostoCombo(res.data);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };


    const emailCierreData = () => {
        loadingEmail();

        let t: IEmailHeloValido = {
            programacionId: props.programacionId,
        }

        ProgramacionPersonaService.emailHeloValido(t)
            .then((res) => {
                if (res.status == 200) {
                    toggleEmailCierre();
                    dismissLoadingEmail();
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
                dismissLoadingEmail();
            });
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setSel([]);
        setHidden(false);
        setTitleColumn();
        dataVuelo(param);
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Foto",
                ariaLabel: "Column operations for File type, Press to sort on File type",
                isIconOnly: true,
                fieldName: "foto",
                minWidth: 45,
                maxWidth: 45,
                onRender: (item: IProgramacionPersona) => (
                    <TooltipHost content={`Foto`}>
                        <Persona
                            text=""
                            size={PersonaSize.size24}
                            imageUrl={baseImageUrl}
                        />
                    </TooltipHost>
                ),
            },
            {
                key: "column2",
                name: "Nombre",
                fieldName: "nombres",
                minWidth: 160,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span
                            style={{
                                fontSize: "90%",
                                textDecorationLine:
                                    item.motivoRetiro == "" ? "none" : "line-through",
                            }}
                        >
                            {item.nombres}
                        </span>
                    );
                },
            },
            {
                key: "column3",
                name: "Identificación",
                fieldName: "nroDOI",
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: "number",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span
                            style={{ fontSize: "95%" }}
                        >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
                    );
                },
            },
            {
                key: 'column3.1',
                name: 'Nivel',
                fieldName: 'nivel',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3.2',
                name: 'Level',
                fieldName: 'level',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column40",
                name: "Gerencia",
                fieldName: "gerencia",
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column41",
                name: "Email",
                fieldName: "email",
                minWidth: 170,
                maxWidth: 180,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column5",
                name: "Celular",
                fieldName: "celular",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column6",
                name: "Origen",
                fieldName: "origen",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Destino',
                fieldName: 'destino',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            /* {
                key: "column8",
                name: "Motivo Retiro",
                fieldName: "motivoRetiro",
                minWidth: 100,
                maxWidth: 110,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            }, */
            /* {
                key: "column9",
                name: "Fecha Salida",
                fieldName: "fechaSalida",
                minWidth: 140,
                maxWidth: 140,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            }, */
            /* {
                key: "column10",
                name: "Empresa Transportista",
                fieldName: "transporte",
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            }, */
            {
                key: "column11",
                name: "Turno",
                fieldName: "turno",
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
            /* {
                key: "column13",
                name: "Validado por",
                fieldName: "nombreUsuarioValida",
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            }, */
            {
                key: "column15",
                name: "Clasificación",
                fieldName: "clasificacion",
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
            {
                key: "column16",
                name: "Proceso - Estado",
                fieldName: "nivelActual",
                minWidth: 250,
                maxWidth: 250,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            // {
            //     key: "column17",
            //     name: "Estado",
            //     fieldName: "estadoActual",
            //     minWidth: 150,
            //     maxWidth: 160,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "number",
            // },
            {
                key: "column18",
                name: "Lugar Movilizacion",
                fieldName: "lugarMovilizacion",
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column19",
                name: "Centro Costo",
                fieldName: "centroCosto",
                minWidth: 150,
                maxWidth: 160,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column12",
                name: "Registrado por",
                fieldName: "nombreUsuarioRegistra",
                minWidth: 180,
                maxWidth: 180,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
            {
                key: "column20",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IProgramacionPersona) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            {isAdmin &&
                                <TooltipHost content={`Editar`}>
                                    <Icon
                                        iconName={IconHelper.ICON_EDIT.iconProps?.iconName}
                                        style={{ cursor: "pointer" }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            setSelId(item.programacionPersonaId);
                                            openActualizar();
                                        }}
                                    />
                                </TooltipHost>
                            }

                            {/* Actividad Log */}
                            <TooltipHost content={`Actividades`}>
                                <Icon
                                    iconName={IconHelper.ICON_INFO.iconProps?.iconName}
                                    style={{ cursor: "pointer" }}
                                    className={`${ThemeBase.classes.iconFont}`}
                                    onClick={() => {
                                        setSelId(item.programacionPersonaId);
                                        openLog();
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            // {
            //     ...IconHelper.ICON_BACK,
            //     onClick: goBack,
            // },
            // {
            //     ...IconHelper.ICON_ADD,
            //     onClick: openCrearAcceso,
            // },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },
        ];

        // if (opcionRetiroMasivo) {
        //     _items.push(
        //         {
        //             ...IconHelper.ICON_CANCEL,
        //             text: 'Retirar',
        //             onClick: () => {
        //                 if (sel.length == 0) {
        //                     return alert("Debe seleccionar al menos un item");
        //                 }

        //                 openRetiroMasivo();
        //             },
        //         },
        //     )
        // }

        if (isAdmin) {
            _items.push(
                {
                    ...IconHelper.ICON_CHECK,
                    text: 'Agencia Viaje',
                    onClick: () => {
                        if (sel.length == 0) {
                            return alert("Debe seleccionar al menos un item");
                        }

                        openValidar();
                    },
                },
                {
                    ...IconHelper.ICON_MAIL_CHECK,
                    text: 'Notificar',
                    onClick: toggleEmailCierre,
                },
            )
        }

        return _items;
    };

    const _rightNormal = () => {
        const _items: ICommandBarItemProps[] = [
            /* {
                ...IconHelper.ICON_EXCEL,
                onClick: openTelerik,
            }, */
            {
                ...IconHelper.ICON_FILTER,
                onClick: () => openPanelFiltro(),
            },
        ];

        return _items;
    };

    const _onChangeComboFiltro = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {

        if (event != undefined) {
            if (option != undefined) {

                switch (type) {

                    case 'gerencia':
                        setFilter({
                            ...filter,
                            gerenciaId: option.key.toString(),
                        })

                        break;
                    case 'origen':
                        setFilter({
                            ...filter,
                            origenId: option.key.toString()
                        })

                        break;
                    case 'destino':
                        setFilter({
                            ...filter,
                            destinoId: option.key.toString()
                        })

                        break;
                    case 'movilizacion':
                        setFilter({
                            ...filter,
                            lugarMovilizacionId: option.key.toString()
                        })

                        break;
                    case 'centroCosto':
                        setFilter({
                            ...filter,
                            centroCostoId: option.key.toString()
                        })

                        break;

                    default:
                        break;
                }
            }
        }
    };

    const _onKeyUpCombo = (e: React.FormEvent<IComboBox>) => {

        let { id } = e?.target as HTMLInputElement;
        let { value } = e?.target as HTMLInputElement;

        switch (id) {

            case "movilizacion-input":
                _openOptionMovilizacion();

                setfilterMovilizacion({
                    ...filterMovilizacion,
                    search: value
                })

                break;
            default:
                break;
        }
    }

    //filtro
    const _buscarFiltro = () => {

        setFilter(filter);
        reloadInitial();

        //dismissPanelFiltro();
    }

    const _limpiarFiltro = () => {

        let modelClear: IFilterProgramacion = {
            ...filter,
            search: "",
            gerenciaId: "",
            lugarMovilizacionId: "",
            centroCostoId: "",
            origenId: "",
            destinoId: "",

        }
        setFilter(modelClear);
        reloadInitial(modelClear);

    }

    return (
        <>
            <TableBase leftBar={_leftBar()} rigthBar={_rightNormal()} column={column} data={data.items} isLoading={!hidden} pagination={pager} style={ThemeBase.notPaddingTableBase} selection={selection}></TableBase>
            <CrearAccesoVuelo programacionId={props.programacionId} isOpen={isOpenCrearAcceso} onDismiss={dismissCrearAcceso} setup={reloadInitial} subTitle={`${data.title} | ${data.subTitle}`} isSubida={props.isSubida}></CrearAccesoVuelo>
            <ValidarAccesoVuelo programacionId={props.programacionId} isOpen={isOpenValidar} dismiss={dismissValidar} sel={sel} reloadParent={reloadInitial}></ValidarAccesoVuelo>
            <RetiroMasivoVuelo programacionId={props.programacionId} isOpen={isOpenRetiroMasivo} dismiss={dismissRetiroMasivo} sel={sel} reloadParent={reloadInitial}></RetiroMasivoVuelo>

            <DialogBase
                isHidden={hideEmailCierre}
                subTitle={'Al confirmar el envio de notificacion se tomará como confirmado la carga de datos por la Agencia de Viaje'}
                tipo={DialogType.normal}
                toggleHidden={() => {
                    dismissLoadingEmail();
                    toggleEmailCierre();
                }}
                dependency={[]}
                confirm={emailCierreData}
                isDisableConfirm={isLoadingEmail}
            ></DialogBase>

            <div id='panelFiltro'>
                <>
                    <PanelPlantillaFiltro
                        textHeader='Filtro'
                        subTextHeader='Elija las condiciones para el filtro personalizado'
                        textBoton='Buscar'
                        textBotonDefault='Limpiar'
                        onclickBoton={_buscarFiltro}
                        onclickBotonDefault={_limpiarFiltro}
                        openPanel={isOpenPanelFiltro}
                        closePanel={dismissPanelFiltro}
                        // onRenderHeader={onRenderHeaderFilter}
                        size={PanelType.medium}
                        disabledBoton={false}
                        estadoBarraEnvio={false}
                        nameIconHeader={"Filter"}
                        nameIconButton={"Search"}
                    >
                        <>

                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Gerencia:"
                                            id='gerencia'
                                            options={gerenciaCombo}
                                            // componentRef={comboBoxRef}
                                            // selectedKey={filter?.gerenciaId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            // multiSelect
                                            onChange={(e, o) => _onChangeComboFiltro("gerencia", e, o)}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <ComboBox
                                            label='Origen (Embarque)'
                                            // componentRef={embarqueRef}
                                            allowFreeform
                                            autoComplete="off"
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={embarqueCombo}
                                            // onKeyUp={_onUpEmbarque}
                                            selectedKey={filter?.origenId}
                                            onChange={(e, o) => _onChangeComboFiltro("origen", e, o)}
                                        // onBlur={_onBlurEmbarque}

                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <ComboBox
                                            label='Destino (Desembarque)'
                                            // componentRef={campamentoRef}
                                            allowFreeform
                                            autoComplete="off"
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            // onKeyUp={_onUpCampamento}
                                            options={destinoCombo}
                                            selectedKey={filter?.destinoId}
                                            onChange={(e, o) => _onChangeComboFiltro("destino", e, o)}
                                        // onBlur={_onBlurCampamento}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                        <ComboBox
                                            label="Lugar Movilización:"
                                            id="movilizacion"
                                            options={lugarMovilizacionCombo}
                                            componentRef={LugarMovilizacionRef}
                                            selectedKey={filter?.lugarMovilizacionId}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            // placeholder='Gerencia'
                                            autoComplete='off'
                                            calloutProps={{ directionalHintFixed: true }}
                                            onChange={(e, o) => _onChangeComboFiltro("movilizacion", e, o)}
                                            onKeyUp={_onKeyUpCombo}
                                        // onBlur={() => _onBlurCombo('gerencia')}
                                        />

                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                        <ComboBox
                                            label="Centro de Costo:"
                                            id="centroCosto"
                                            options={centroCostoCombo}
                                            // componentRef={comboBoxRef}
                                            selectedKey={filter?.centroCostoId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboFiltro("centroCosto", e, o)}

                                        />
                                    </div>
                                </div>

                            </div>
                        </>
                    </PanelPlantillaFiltro>

                </>

            </div>

            {/* Panel Actualizar */}
            <ActualizarAccesoPersona programacionPersonaId={selId} isOpen={isOpenActualizar} dismiss={dismissActualizar} reloadParent={reloadInitial}></ActualizarAccesoPersona>

            {/* Panel Log */}
            <LogActividad programacionPersonaId={selId} isOpen={isOpenLog} dismiss={dismissLog}></LogActividad>
        </>
    )
})