import { ICreateUsuarioCuenta } from "../interfaces/IUsuarioCuenta";
import { fetchAdministracion } from "./http-common";
let urlCuenta = 'Cuenta';
let urlUsuarioCuenta = 'UsuarioCuenta';

const cuentaByCustomer = (customer: string) => {
    return fetchAdministracion.get(`${urlCuenta}/${customer}`);
}

const agregarUsuarioCuenta = (data: ICreateUsuarioCuenta) => {
    return fetchAdministracion.post(`${urlUsuarioCuenta}`, data);
}

const CuentaService = {
    cuentaByCustomer,
    agregarUsuarioCuenta
}

export default CuentaService;