import { IUserAuthenticate } from "../../auth/interface/IAuth";

const ROLE_ADMIN = "Admin";
const ROLE_USRADMIN = "usr.Admin";

const ROLE_TRANSPORTE = "Transporte";
const ROLE_CONTRATISTA = "Contratista";
// const ROLE_KEY_USER = 'KeyUser';
const ROLE_KEY_USER_EMPLEADO = "KeyUserEmpleado";
const ROLE_KEY_USER_SOCIO = "KeyUserSSEE";
const ROLE_GERENTE_SOLICITANTE = "GerenteSolicitante";
const ROLE_GERENTE_APROBADOR = "GerenteAprobador";
const ROLE_AREA_TRANSPORTE_ADMIN = "AreaTransporteAdmin";
const ROLE_AREA_TRANSPORTE = "AreaTransporte";
const ROLE_UNIDAD_MEDICA = "UnidadMedica";
const ROLE_AGENCIA_VUELO = "AgenciaVuelo";
const ROLE_USUARIO = "Usuario";

/* JWT */
const CLAIM_ROLE =
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
const CLAIM_EXP = "exp";
const CLAIM_ADRESS =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
const CLAIM_USER_ID = "Bandicoot";
const CLAIM_PERSONA_ID = "Falcon";
const CLAIM_EMPRESA = "Empresa";
const CLAIM_EMPRESA_USUARIO_ID = "EmpresaUsuarioId";
const CLAIM_EMPRESA_USUARIO = "EmpresaUsuario";
const CLAIM_ES_EMPRESA_TRANSPORTISTA = "EsEmpresaTransportista";
const CLAIM_ES_EMPRESA_TITULAR = "EsEmpresaTitular";
const CLAIM_ES_EMPRESA_VUELO = "EsEmpresaVuelo";
const CLAIM_USER_NAME = "Gothan";
const CLAIM_COD_CONT = "Dixit";
const CLAIM_TOKEN_SESION_C = "Poker";

/* Text - Panel Persona */
// const TEXT_MASIVO_PERSONA_KEY_USER = 'Actualización masiva KeyUser';
// const TEXT_MASIVO_PERSONA_SSEE = 'Actualización masiva SSEE';
// const TEXT_MASIVO_PERSONA_ADM_TRANSPORTE = 'Actualización Rol Transporte';

const TEXT_MASIVO_PERSONA_KEY_USER = "Datos KeyUser";
const TEXT_MASIVO_PERSONA_SSEE = "Datos SSEE";
const TEXT_MASIVO_PERSONA_ADM_TRANSPORTE = "Datos Area Transporte";

/* Function */
const isRol = (access: IUserAuthenticate, rol: string) => {
  if (access.role) {
    if (access.role.find((x) => x == rol)) {
      return true;
    }
  }

  return false;
};

const isRoleAccessAll = (role, acceptedRole) => {
  let isAll = false;

  acceptedRole.forEach((item) => {
    let exis = role.find((x) => x == item);

    if (exis) {
      isAll = true;
      return;
    }
  });

  return isAll;
};

const roleAdmin = () => {
  let role = [ROLE_ADMIN, ROLE_AREA_TRANSPORTE_ADMIN, ROLE_AREA_TRANSPORTE];

  return role;
};

export {
  ROLE_ADMIN,
  ROLE_USRADMIN,
  ROLE_TRANSPORTE,
  ROLE_CONTRATISTA,
  // ROLE_KEY_USER,
  ROLE_KEY_USER_EMPLEADO,
  ROLE_KEY_USER_SOCIO,
  ROLE_GERENTE_SOLICITANTE,
  ROLE_GERENTE_APROBADOR,
  ROLE_AREA_TRANSPORTE_ADMIN,
  ROLE_AREA_TRANSPORTE,
  ROLE_UNIDAD_MEDICA,
  ROLE_AGENCIA_VUELO,
  ROLE_USUARIO,
  /* CLAIMS */
  CLAIM_ROLE,
  CLAIM_EXP,
  CLAIM_ADRESS,
  CLAIM_USER_ID,
  CLAIM_PERSONA_ID,
  CLAIM_EMPRESA,
  CLAIM_EMPRESA_USUARIO_ID,
  CLAIM_EMPRESA_USUARIO,
  CLAIM_ES_EMPRESA_TRANSPORTISTA,
  CLAIM_ES_EMPRESA_TITULAR,
  CLAIM_ES_EMPRESA_VUELO,
  CLAIM_USER_NAME,
  CLAIM_COD_CONT,
  CLAIM_TOKEN_SESION_C,

  /* Validar Role */
  isRol,

  /* Text Masivo Persona */
  TEXT_MASIVO_PERSONA_KEY_USER,
  TEXT_MASIVO_PERSONA_SSEE,
  TEXT_MASIVO_PERSONA_ADM_TRANSPORTE,

  /* Verificar si puede ver todo */
  isRoleAccessAll,
  roleAdmin,
};
