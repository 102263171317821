import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  Shimmer,
  Stack,
  TextField,
  ThemeProvider,
  Toggle,
} from "@fluentui/react";
import PersonaService from "../../services/PersonaService";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HoteleriaService from "../../services/HoteleriaService";
import { IPersona } from "../../interfaces/IPersona";
import {
  keyAccess,
  splitExceptionDetail,
  stackTokens5,
  _initialFilter,
} from "../../utils/Helper/helper";
import {
  IBase,
  IDetailUbigeo,
  IException,
  IItemComboBaseDTO,
  ISearch,
} from "../../interfaces/base/IBase";
import { IProgramacionPersona } from "../../interfaces/IProgramacion";
import { ButtonForm } from "../../components/Generic/buttonForm";
import TransporteService from "../../services/transporteService";
import { IUserAuthenticate } from "../../auth/interface/IAuth";
import {
  isRoleAccessAll,
  roleAdmin,
  ROLE_CONTRATISTA,
  ROLE_KEY_USER_EMPLEADO,
  ROLE_KEY_USER_SOCIO,
} from "../../utils/Helper/roleHelper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ProgramacionService from "../../services/ProgramacionService";
import ContratoService from "../../services/contratoService";
import UbigeoService from "../../services/ubigeoService";
import { IHotel } from "../../interfaces/IHotel";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import { ShimmerElementType } from "@fluentui/react";
import EmpresaService from "../../services/empresaService";
import { UbicacionHelper } from "../../utils/Helper/ubicacionHelper";
import { IFilterUbicacion } from "../../interfaces/filter/IFilterUbicacion";
import UbicacionService from "../../services/ubicacionesServices";
import { Ifilter } from "../../../../asistencia/src/Interfaces/filter";
import TemporadaService from "../../services/temporadaService";
import { IListarPersonaEsHelo } from "../../interfaces/ITemporada";
import { ReglaService } from "../../services/reglaService";
import { IFilterRegla } from "../../interfaces/filter/IFilterRegla";
import ProgramacionPersonaService from "../../services/programacionPersonaService";

interface formModel {
  requiereTransporte: boolean;
  programacionId?: string;
  personaId: string;
  prioridad?: number;
  ubigeoId?: string | null;
  email?: string;
  celular?: string;
  direccion?: string;
  paraderoId: string;
  ubicacionCuarentenaId?: string | null;
  ubicacionCampamentoId: string;
  turno: string;
  transporteId?: string | null;
  hotelId?: string | null;
  usuarioRegistraId?: string | null;
  esTitular: boolean;
  lugarMovilizacionId?: string | null;
  centroCostoId?: string | null;
}
const INITIAL_MODULE: formModel = {
  requiereTransporte: true,
  programacionId: "",
  personaId: "",
  prioridad: 0,
  ubigeoId: null,
  email: "",
  celular: "",
  direccion: "",
  paraderoId: "",
  ubicacionCuarentenaId: null,
  ubicacionCampamentoId: "",
  turno: "",
  transporteId: "",
  hotelId: null,
  usuarioRegistraId: null,
  esTitular: true,
  lugarMovilizacionId: null,
  centroCostoId: null,
};

const initialTurno = [
  { key: "D", text: "Turno Día" },
  { key: "N", text: "Turno Noche" },
  { key: "D/N", text: "Turno Día / Noche" },
];

export const NewAccessPerson = ({
  programacionId = "",
  onDismiss = () => {},
  setup = () => {},
  isOpen = false,
  subTitle = "",
  isSubida = false,
  codigoTipoProgramacion = "",
}) => {
  let { id } = useParams();
  const [embarque, setEmbaque] = useState<IItemComboBaseDTO[]>([]); //-----------lista------------
  const [destino, setDestino] = useState<IItemComboBaseDTO[]>([]); //-----------lista------------
  const [Ubigueo, setUbigueo] = useState<IItemComboBaseDTO[]>([]); //-----------lista------------
  const [Provincias, setProvincias] = useState<IItemComboBaseDTO[]>([]);
  const [Distritos, setDistritos] = useState<IItemComboBaseDTO[]>([]);
  const [detailUbigeo, setDetailUbigeo] = useState<IDetailUbigeo>(null!);
  const [hotel, setHotel] = useState<IItemComboBaseDTO[]>([]);
  const [transporte, setTransporte] = useState<IItemComboBaseDTO[]>([]);
  const [turno, setTurno] = useState<IItemComboBaseDTO[]>(initialTurno);
  const [centroCosto, setCentroCosto] = useState<IItemComboBaseDTO[]>([]);

  const [ubicacionCampamentoId, setubicacionCampamentoId] = useState("");
  const [paraderoId, setParaderoId] = useState("");

  const [lugarMovilizacion, setLugarMovilizacion] = useState<
    IItemComboBaseDTO[]
  >([]);

  const [model, setmodel] = useState<formModel>({
    ...INITIAL_MODULE,
    programacionId: id,
  });
  const [isSend, setIsSend] = useState(false);

  /* Reest */
  const [programacion, setProgramacion] = useState<IProgramacionPersona>();
  const [persona, setPersona] = useState<IItemComboBaseDTO[]>([]);
  const [personaIsLoad, setpersonaIsLoad] = useState(false);

  const [personaOptionSelect, setpersonaOptionSelect] =
    useState<IComboBoxOption>();

  /*filtros */
  const [filterPersona, setFilterPersona] = useState<ISearch>(_initialFilter);
  const [filterEmbarque, setFilterEmbarque] = useState<IFilterUbicacion>({
    tipo: UbicacionHelper.TIPO_ORIGEN,
  });

  const [filterDestino, setFilterDestino] = useState<IFilterUbicacion>({
    tipo: UbicacionHelper.TIPO_DESTINO,
  });
  const [filterMovilizacion, setfilterMovilizacion] =
    useState<ISearch>(_initialFilter);

  const comboBoxRef = React.useRef<IComboBox>(null);
  const departamentoRef = React.useRef<IComboBox>(null);
  const provinciaRef = React.useRef<IComboBox>(null);
  const embarqueRef = React.useRef<IComboBox>(null);
  const campamentoRef = React.useRef<IComboBox>(null);
  const LugarMovilizacoinRef = React.useRef<IComboBox>(null);

  const _openOptionPersona = React.useCallback(
    () => comboBoxRef.current?.focus(true),
    []
  );
  const _openOptionEmbarque = React.useCallback(
    () => embarqueRef.current?.focus(true),
    []
  );
  const _openOptionCampamento = React.useCallback(
    () => campamentoRef.current?.focus(true),
    []
  );
  const _openOptionMovilizacion = React.useCallback(
    () => LugarMovilizacoinRef.current?.focus(true),
    []
  );

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [celular, setCelular] = useState<string | undefined>(undefined);
  const [direccion, setDireccion] = useState<string | undefined>(undefined);
  const [ccostoId, setCCostoId] = useState<string | undefined>(undefined);
  const [lugarMovId, setLugarMovId] = useState<string | undefined>(undefined);
  const [ubigeoId, setUbigeoId] = useState<string | undefined>(undefined);
  const [empresa, setEmpresa] = useState<string>("");
  const [psGroup, setPsGroup] = useState<string>("");
  const [isVuelo, setIsVuelo] = useState(false);

  const [filterCentroCosto, setFilterCentroCosto] = useState<Ifilter>({
    ..._initialFilter(),
  });
  const centroCostoRef = React.useRef<IComboBox>(null);
  const _openOptionCentroCosto = React.useCallback(
    () => centroCostoRef.current?.focus(true),
    []
  );

  /* Reest */
  const [isMMG, setIsMMG] = useState<boolean | undefined>(undefined);
  const [requiereVuelo, setRequiereVuelo] = useState(false);

  /* Role */
  const access = localStorage.getItem(keyAccess);
  let iRole: string[] = [];
  if (access) {
    let d: IUserAuthenticate = JSON.parse(access);
    if (d.role) {
      iRole = d.role;
    }
  }
  /* Role */

  const [role, setRole] = useState<string[]>(iRole);

  /* Validar si es admin */
  let isAdmin = isRoleAccessAll(role, roleAdmin());
  let isKeyUser = isRoleAccessAll(role, [
    ROLE_KEY_USER_EMPLEADO,
    ROLE_KEY_USER_SOCIO,
  ]);
  let isSSEE = isRoleAccessAll(role, [ROLE_CONTRATISTA]);
  let isOnlyKeyUserEmpleado = isRoleAccessAll(role, [ROLE_KEY_USER_EMPLEADO]);

  /* Effect */
  useEffect(() => {
    setPsGroup("");
    setmodel({ ...model, programacionId: id });
    dataPersona();
    // dataUbicacionOrigen();
    // dataUbicacionDestino();
    dataLugarMovilizacion();
    dataCentroCosto();
    dataDepartamento();
    dataTransporte();
    dataHotel();
  }, []);

  useEffect(() => {
    if (filterPersona.search != null) {
      dataPersona();
    }
  }, [filterPersona]);

  // useEffect(() => {
  //   if (filterEmbarque.search != null) {
  //     dataUbicacionOrigen();
  //   }
  // }, [filterEmbarque]);

  // useEffect(() => {
  //   if (filterDestino.search != null) {
  //     dataUbicacionDestino();
  //   }
  // }, [filterDestino]);

  useEffect(() => {
    if (filterMovilizacion.search != null) {
      dataLugarMovilizacion();
    }
  }, [filterMovilizacion]);

  useEffect(() => {
    if (id != undefined) {
      dataInfoProgramacion();
    }
  }, [id]);

  useEffect(() => {
    if (email != undefined) {
      setmodel({
        ...model,
        email: email,
      });
    }
  }, [email]);

  useEffect(() => {
    if (celular != undefined) {
      setmodel({
        ...model,
        celular: celular,
      });
    }
  }, [celular]);

  useEffect(() => {
    if (direccion != undefined) {
      setmodel({
        ...model,
        direccion: direccion,
      });
    }
  }, [direccion]);

  useEffect(() => {
    if (ccostoId != undefined) {
      setmodel({
        ...model,
        centroCostoId: ccostoId,
      });
    }
  }, [ccostoId]);

  useEffect(() => {
    if (lugarMovId != undefined) {
      setmodel({
        ...model,
        lugarMovilizacionId: lugarMovId,
      });
    }
  }, [lugarMovId]);

  useEffect(() => {
    if (ubigeoId != undefined) {
      detalleUbigeo(ubigeoId);
    }
  }, [ubigeoId]);

  useEffect(() => {
    if (detailUbigeo != undefined) {
      if (detailUbigeo.distritoId != undefined) {
        setmodel({
          ...model,
          ubigeoId: detailUbigeo.distritoId,
        });
      }
    }
  }, [detailUbigeo]);

  useEffect(() => {
    dataCentroCosto();
  }, [filterCentroCosto]);

  /* Api */
  const dataPersona = () => {
    PersonaService.listarPersonaMMG(filterPersona)
      .then((res) => {
        /* v2 persona */
        let d: IPersona[] = res.data;
        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: `${x.nroDOI}  | ${x.persona} | ${x.tipo}`,
          };
        });

        setPersona(t);
        setpersonaIsLoad(true);
      })
      .catch((e: Error) => {
        alert(e.message);
      });
  };

  const dataInfoProgramacion = () => {
    PersonaService.getInfoProgramacion(id)
      .then((res) => {
        let d: IProgramacionPersona = res.data;
        setProgramacion(d);

        if (!d.isAllTurno) {
          setTurno(turno.filter((x) => x.key != "D"));
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataUbicacionOrigen = (
    lugarMovilizacionId: string | null,
    esVuelo: boolean,
    requiereTransporte: boolean,
    isMMG?: boolean
  ) => {
    let f: IFilterRegla = {
      programacionId: programacionId,
      lugarMovilizacionId: lugarMovilizacionId,
      tipoUbicacion: UbicacionHelper.TIPO_ORIGEN,
      esVuelo: esVuelo,
      requiereTransporte: requiereTransporte,
      skip: 0,
      take: 50,
    };

    ReglaService.listarPorFiltro(f)
      .then((res) => {
        setEmbaque(res.data);
        if (
          isMMG != null &&
          isMMG != undefined &&
          isMMG == true /*&& isKeyUser*/
        ) {
          if (
            res.data != null &&
            res.data[0] != null &&
            res.data[0].tieneRegla
          ) {
            // setmodel({ ...model, paraderoId: res.data[0].key });
            setParaderoId(res.data[0].key!);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataUbicacionDestino = (
    lugarMovilizacionId: string | null,
    esVuelo: boolean,
    requiereTransporte: boolean,
    isMMG?: boolean
  ) => {
    let f: IFilterRegla = {
      programacionId: programacionId,
      lugarMovilizacionId: lugarMovilizacionId,
      tipoUbicacion: UbicacionHelper.TIPO_DESTINO,
      esVuelo: esVuelo,
      requiereTransporte: requiereTransporte,
      skip: 0,
      take: 50,
    };

    ReglaService.listarPorFiltro(f)
      .then((res) => {
        setDestino(res.data);
        if (
          isMMG != null &&
          isMMG != undefined &&
          isMMG == true /*&& isKeyUser*/
        ) {
          if (
            res.data != null &&
            res.data[0] != null &&
            res.data[0].tieneRegla
          ) {
            // setmodel({ ...model, ubicacionCampamentoId: res.data[0].key });
            setubicacionCampamentoId(res.data[0].key!);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataLugarMovilizacion = () => {
    ProgramacionService.allLugarMovilizacion()
      .then((res) => {
        setLugarMovilizacion(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataCentroCosto = () => {
    ContratoService.allCentroCosto1(filterCentroCosto)
      .then((res) => {
        setCentroCosto(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataDepartamento = () => {
    PersonaService.getComboUbigueo()
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setUbigueo(t);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const _LoadProvincia = (key: any) => {
    PersonaService.getComboUbigueo(key)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setProvincias(t);
      })
      .catch((e) => {
        alert(e.message);
      });
  };

  const _LoadDistritos = (key: any) => {
    PersonaService.getComboUbigueo(key)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setDistritos(t);
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  const dataTransporte = () => {
    // Admin - ve todas la empresas transportistas
    // KeyUser - segun la empresa de la persona seleccionada
    // SSEE - segun su empresa asociada - ManPower
    // Demas - nada
    if (isAdmin || isSSEE) {
      TransporteService.allSocioTransportista()
        .then((res) => {
          let d: IBase[] = res.data;

          let t: IItemComboBaseDTO[] = d.map((x) => {
            return {
              key: x.id,
              text: x.nombre,
            };
          });

          setTransporte(t);
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  const dataHotel = () => {
    HoteleriaService.getHoteles(50)
      .then((res) => {
        let d: IHotel[] = res.data.items;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.hotelID != null ? x.hotelID : "",
            text: `${x.nombre} | ${x.direccion}`,
          };
        });

        setHotel(t);
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  function _enviarAccesoPersona() {
    setIsSend(true);

    let f: formModel = model;

    PersonaService.postAccesoPersona(f)
      // ProgramacionPersonaService.agregarAccesoProgramacionPersona(f)
      .then((res) => {
        if (res.status == 200) {
          setIsSend(false);
          refForm();
          setChoice("send");
          setEmpresa("");
          setPsGroup("");
          setup();
        }
      })
      .catch((e: any) => {
        setIsSend(false);
        setChoice("error");
        settextError(splitExceptionDetail(e));
      });
  }

  const resetUbigeos = () => {
    setDetailUbigeo(null!);
    setProvincias([]);
    setDistritos([]);
  };

  const _onInfoPersona = (id: string) => {
    let obj: IListarPersonaEsHelo = {
      listaPersona: [id],
      programacionId: programacionId,
    };
    setubicacionCampamentoId("");
    setParaderoId("");

    TemporadaService.listarPersonaInfoHelo(obj)
      .then((res) => {
        if (res.data) {
          let d = res.data[0];
          if (d) {
            let isMMG = d.tipo == "01";
            let isVuelo = d.esVuelo;
            setIsVuelo(isVuelo);

            // Regla Origen & Destino
            let lugMovId = d.lugarMovilizacionId;
            dataUbicacionOrigen(
              lugMovId,
              isVuelo,
              model.requiereTransporte,
              isMMG
            );
            dataUbicacionDestino(
              lugMovId,
              isVuelo,
              model.requiereTransporte,
              isMMG
            );

            setEmail(d.email);
            setCelular(d.celular);
            setDireccion(d.direccion);
            setCCostoId(d.centroCostoId);
            setLugarMovId(d.lugarMovilizacionId);
            setUbigeoId(d.ubigeoId);
            if (d.ubigeoId == null) {
              resetUbigeos();
            }
            detalleUbigeo(d.ubigeoId);
            setEmpresa(d.empresa);
            setIsMMG(isMMG);
            setRequiereVuelo(isVuelo);

            if (isVuelo) {
              dataEmpresaVuelo();
            } else {
              if (isAdmin) {
                if (isMMG) {
                  dataEmpresaTitularMMG();
                } else {
                  dataTransporte();
                }
              } else {
                if (isKeyUser) {
                  dataSocioTrPorEmpresaContratista(d.empresaId);
                }
              }
            }
            let ps = "";
            if (d.nivel != null) {
              ps += d.nivel;
            }

            if (d.level != null) {
              if (ps !== "") {
                ps += " - ";
              }
              ps += d.level;
            }

            setPsGroup(ps);
          }
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const dataSocioTrPorEmpresaContratista = (empresaContratistaId: string) => {
    setTransporte([]);
    TransporteService.allSocioTrByEmpresaContratista(empresaContratistaId)
      .then((res) => {
        if (res.status == 200) {
          let d: IBase[] = res.data;

          let t: IItemComboBaseDTO[] = d.map((x) => {
            return {
              key: x.id,
              text: x.nombre,
            };
          });

          setTransporte(t);
        }
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  const dataEmpresaTitularMMG = () => {
    setTransporte([]);

    EmpresaService.allTitularMMG(_initialFilter())
      .then((res) => {
        if (res.status == 200) {
          setTransporte(res.data);
        }
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  const dataEmpresaVuelo = () => {
    setTransporte([]);

    EmpresaService.allVuelo(_initialFilter())
      .then((res) => {
        if (res.status == 200) {
          setTransporte(res.data);
        }
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  /* Api Ubigeo */
  const detalleUbigeo = (distritoId: string) => {
    UbigeoService.detalleById(distritoId)
      .then((res) => {
        let d: IDetailUbigeo = res.data;

        setDetailUbigeo({
          departamentoId: d.departamentoId,
          provinciaId: d.provinciaId,
          distritoId: d.distritoId,
        });

        if (d.departamentoId) {
          dataByUbigeoId(2, d.departamentoId);
        }

        if (d.provinciaId) {
          dataByUbigeoId(3, d.provinciaId);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataByUbigeoId = (tipo: number, ubigeoId?: string) => {
    PersonaService.getComboUbigueo(ubigeoId)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        switch (tipo) {
          case 1:
            setUbigueo(t);
            break;
          case 2:
            setProvincias(t);
            break;
          case 3:
            setDistritos(t);
            break;
          default:
            break;
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  /* Event */
  const _onUpPersona = (ev?: React.KeyboardEvent<IComboBox>) => {
    _openOptionPersona();

    let { value } = ev?.target as HTMLInputElement;
    setmodel({
      ...model,
      personaId: "",
    });

    setEmpresa("");
    if (value.length > 2) {
      setFilterPersona({
        ...filterPersona,
        search: value,
      });
    }
  };

  const _onBlurPersona = () => {
    if (model.personaId == "" || model.personaId == undefined) {
      setFilterPersona({
        ...filterPersona,
        search: "",
      });
    }
  };

  const _onInputPersona = (e: React.KeyboardEvent<IComboBox>) => {
    _openOptionPersona();

    let { value } = e?.target as HTMLInputElement;
    setmodel({
      ...model,
      personaId: "",
    });

    setEmpresa("");
    if (value.length > 2) {
      setFilterPersona({
        ...filterPersona,
        search: value,
      });
    }
    // ===================================
    let embarqueAux = model.paraderoId;
    let turnoAux = model.turno;
    let campamentoAux = model.ubicacionCampamentoId;
    let empresaAux = model.transporteId;
    setmodel({
      ...INITIAL_MODULE,
      programacionId: id,
      paraderoId: embarqueAux,
      turno: turnoAux,
      ubicacionCampamentoId: campamentoAux,
      transporteId: empresaAux,
    });

    resetUbigeos();
    // setDetailUbigeo(INITIAL_UBIGUEO);
    // setProvincias([]);
    // setDistritos([]);
  };

  /* Event Combo Embarque */
  const _onUpEmbarque = (e?: React.KeyboardEvent<IComboBox>) => {
    _openOptionEmbarque();
    let { value } = e?.target as HTMLInputElement;
    setmodel({
      ...model,
      paraderoId: "",
    });
    setFilterEmbarque({
      ...filterEmbarque,
      search: value,
    });
  };

  const _onBlurEmbarque = () => {
    if (model.paraderoId == "" || model.paraderoId == undefined) {
      setFilterEmbarque({
        ...filterEmbarque,
        search: "",
      });
    }
  };

  /* Event Combo Campamento */
  const _onUpCampamento = (e?: React.KeyboardEvent<IComboBox>) => {
    _openOptionCampamento();

    let { value } = e?.target as HTMLInputElement;
    setmodel({
      ...model,
      ubicacionCampamentoId: "",
    });

    setFilterDestino({
      ...filterDestino,
      search: value,
    });
  };

  const _onBlurCampamento = () => {
    if (
      model.ubicacionCampamentoId == "" ||
      model.ubicacionCampamentoId == undefined
    ) {
      setFilterDestino({
        ...filterDestino,
        search: "",
      });
    }
  };

  /* Event Combo Campamento */
  const _onUpMOvilizacion = (e?: React.KeyboardEvent<IComboBox>) => {
    _openOptionMovilizacion();
    let { value } = e?.target as HTMLInputElement;
    setmodel({
      ...model,
      lugarMovilizacionId: "",
    });
    setfilterMovilizacion({
      ...filterMovilizacion,
      search: value,
    });
  };
  const _onChangePersona = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setEmbaque([]);
        setDestino([]);
        setpersonaOptionSelect(option);
        _onInfoPersona(option.key.toString());
      }
    }
  };

  useEffect(() => {
    if (personaOptionSelect)
      setmodel({
        ...model,
        personaId: personaOptionSelect.key.toString(),
        lugarMovilizacionId: lugarMovId,
        ubigeoId: detailUbigeo ? detailUbigeo.distritoId : "",
        celular: celular,
        email: email,
        direccion: direccion,
        centroCostoId: ccostoId,
        ubicacionCampamentoId: ubicacionCampamentoId,
        paraderoId: paraderoId,
      });
  }, [personaOptionSelect, ubicacionCampamentoId, paraderoId]);

  const _onChangeParadero = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        // alert(option.key.toString());
        setmodel({
          ...model,
          paraderoId: option.key.toString(),
        });
      }
    }
  };

  const _onChangeCampamento = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        // alert(option.key.toString());

        setmodel({
          ...model,
          ubicacionCampamentoId: option.key.toString(),
        });
      }
    }
  };

  const _onChangeTurno = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setmodel({
          ...model,
          turno: option.key.toString(),
        });
      }
    }
  };

  const _onToggleComunidad = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != undefined) {
      let t = model.transporteId;

      if (!checked) {
        t = null;
      }

      dataUbicacionOrigen(
        lugarMovId == undefined ? null : lugarMovId,
        isVuelo,
        checked
      );
      dataUbicacionDestino(
        lugarMovId == undefined ? null : lugarMovId,
        isVuelo,
        checked
      );

      setmodel({
        ...model,
        requiereTransporte: checked,
        transporteId: t,
      });
    }
  };

  const _onChangeClasificacion = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != undefined) {
      setmodel({
        ...model,
        esTitular: checked,
      });
    }
  };

  const _onChangeDepartamento = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setDetailUbigeo({
          ...detailUbigeo,
          departamentoId: String(option.key) as string,
        });
        _LoadProvincia(option.key);
      }
    } else {
      setmodel({
        ...model,
        ubigeoId: null,
      });
    }
  };

  const _onChangeProvincia = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setDetailUbigeo({
          ...detailUbigeo,
          provinciaId: String(option.key),
        });
        _LoadDistritos(option.key);
      }
    }
  };

  const _onChangeDistrito = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setmodel({
          ...model,
          ubigeoId: option.key.toString(),
        });
      }
    }
  };

  const _onChangeDireccion = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setmodel({
        ...model,
        direccion: newValue,
      });
    }
  };

  const _onChangeEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setmodel({
        ...model,
        email: newValue,
      });
    }
  };

  const _onChangeTelefono = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setmodel({
        ...model,
        celular: newValue,
      });
    }
  };

  const _onChangeTransporte = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setmodel({
          ...model,
          transporteId: option.key.toString(),
        });
      }
    }
  };

  const _onChangeHotel = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setmodel({
          ...model,
          hotelId: option.key.toString(),
        });
      }
    }
  };

  const _generateItemBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        // onClick: goBack,
      },
    ];

    return _items;
  };

  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "lugarMovilizacion":
            setmodel({
              ...model,
              lugarMovilizacionId: String(option.key),
            });
            break;
          case "centroCosto":
            setmodel({
              ...model,
              centroCostoId: String(option.key),
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const _onKeyUpComboCentroCosto = (e: React.FormEvent<IComboBox>) => {
    let { id } = e?.target as HTMLInputElement;
    let { value } = e?.target as HTMLInputElement;
    _openOptionCentroCosto();

    setFilterCentroCosto({
      ...filterCentroCosto,
      search: value,
    });
  };

  const shimmerWithElementFirstRow = [
    { type: ShimmerElementType.line, height: 32 },
  ];
  const shimmerWithElementSecondRow2 = [
    { type: ShimmerElementType.line, height: 32, width: "50%" },
    { type: ShimmerElementType.gap, width: "5%" },
    { type: ShimmerElementType.line, height: 32, width: "50%" },
  ];
  const shimmerWithElementSecondRow3 = [
    { type: ShimmerElementType.line, height: 32, width: "20%" },
    { type: ShimmerElementType.line, height: 32 },
    { type: ShimmerElementType.gap, width: "15%" },
    { type: ShimmerElementType.line, height: 32, width: "20%" },
    { type: ShimmerElementType.line, height: 32 },
    { type: ShimmerElementType.gap, width: "15%" },
    { type: ShimmerElementType.line, height: 32, width: "20%" },
    { type: ShimmerElementType.line, height: 32 },
  ];

  const NoDataOptions = () => {
    return (
      <>
        {persona.length == 0 && (
          <div style={{ textAlign: "center", padding: 20 }}>
            Persona no encontrada en teseo
          </div>
        )}
      </>
    );
  };

  const onRenderFooter = React.useCallback(() => {
    const filter =
      !model.personaId ||
      !model.paraderoId ||
      !model.ubicacionCampamentoId ||
      !model.turno;

    const disabledRegister = isSend || filter;
    return (
      <>
        <ButtonForm
          back={onDismiss}
          register={_enviarAccesoPersona}
          disabledRegister={disabledRegister}
        ></ButtonForm>
      </>
    );
  }, [
    model,
    persona,
    onDismiss,
    personaIsLoad,
    Ubigueo,
    Provincias,
    Distritos,
    centroCosto,
    isSend,
    requiereVuelo,
    isSubida,
    isAdmin,
    isMMG,
  ]);

  const refForm = React.useCallback(() => {
    setFilterPersona({
      ...filterPersona,
      search: "",
    });
    setmodel({
      ...INITIAL_MODULE,
      programacionId: id,
    });
    setDistritos([]);
    setProvincias([]);
    setDetailUbigeo(null!);
    resetChoice();
    setEmpresa("");
  }, [onDismiss]);

  const [choice, setChoice] = React.useState<string | undefined>(undefined);
  const resetChoice = React.useCallback(() => setChoice(undefined), []);
  const [textError, settextError] = useState("Error");

  const ErrorExample = (p: any) => {
    return (
      <MessageBar
        messageBarType={MessageBarType.blocked}
        isMultiline={false}
        onDismiss={p.resetChoice}
        dismissButtonAriaLabel="Close"
        truncated={true}
        overflowButtonAriaLabel="See more"
      >
        {textError}
      </MessageBar>
    );
  };
  const SuccessExample = () => (
    <MessageBar
      messageBarType={MessageBarType.success}
      isMultiline={false}
      onDismiss={() => {
        refForm();
        resetChoice();
      }}
    >
      Success
    </MessageBar>
  );

  return (
    <>
      <PanelGenerico
        isOpen={isOpen}
        onDismiss={onDismiss}
        isOnRenderHeader
        disabledFooter
        iconHeaderName="TemporaryUser"
        title="Inscripcion Colaborador"
        subTitle2="Complete el formulario para registar colaborador"
        subTitle={`Personal ${programacion?.tipoPersonal} | ${programacion?.guardia}`}
        onRendeFooter={onRenderFooter}
        disabledIconHeader={false}
      >
        <>
          {/* <pre>{JSON.stringify(model, null, 2)}</pre> */}
          <div hidden={!isSend}>
            <ProgressIndicator
              description="Enviando espere un momento"
              barHeight={3}
            />
          </div>
          <br />
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            {choice === "error" && <ErrorExample resetChoice={resetChoice} />}
            {choice === "send" && <SuccessExample />}
          </div>
          <br />
          {/* ANIMACION DE CARGA FORMULARIO */}
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
            hidden={personaIsLoad}
          >
            <ThemeProvider>
              <Shimmer shimmerElements={shimmerWithElementFirstRow} /> <br />
              <br />
              <Shimmer shimmerElements={shimmerWithElementSecondRow2} /> <br />
              <br />
              <Shimmer shimmerElements={shimmerWithElementSecondRow2} /> <br />
              <br />
              <Shimmer
                width="48%"
                shimmerElements={shimmerWithElementFirstRow}
              />{" "}
              <br />
              <br />
              <Shimmer shimmerElements={shimmerWithElementSecondRow3} /> <br />
              <br />
              <Shimmer shimmerElements={shimmerWithElementFirstRow} /> <br />
              <br />
              <Shimmer shimmerElements={shimmerWithElementFirstRow} /> <br />
              <br />
              <Shimmer shimmerElements={shimmerWithElementSecondRow2} /> <br />
              <br />
            </ThemeProvider>
          </div>
          <form
            ref={refForm}
            hidden={
              choice === "error" || choice === "send" || !personaIsLoad
                ? true
                : false
            }
          >
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <div>
                    {!isSend && (
                      <>
                        <Stack tokens={stackTokens5}>
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                  componentRef={comboBoxRef}
                                  label="Seleccionar Persona"
                                  allowFreeform
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={persona}
                                  errorMessage={empresa}
                                  // onKeyUp={_onUpPersona}
                                  onInput={_onInputPersona}
                                  selectedKey={model.personaId}
                                  onChange={_onChangePersona}
                                  onBlur={_onBlurPersona}
                                  onRenderLowerContent={NoDataOptions}
                                  required
                                />

                                <div className="ms-Grid" dir="ltr">
                                  <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                      <span
                                        style={{
                                          color: "rgb(164, 38, 44)",
                                          fontSize: 12,
                                        }}
                                      >
                                        {psGroup}
                                      </span>
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                      {requiereVuelo && (
                                        <span
                                          style={{
                                            color: "rgb(164, 38, 44)",
                                            fontSize: 12,
                                          }}
                                        >
                                          Requiere Vuelo
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                  componentRef={embarqueRef}
                                  label="Origen (Embarque)"
                                  allowFreeform
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  disabled={
                                    paraderoId.length > 0 &&
                                    isVuelo &&
                                    isOnlyKeyUserEmpleado
                                  }
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={embarque}
                                  onKeyUp={_onUpEmbarque}
                                  selectedKey={
                                    model &&
                                    model.paraderoId != null &&
                                    model.paraderoId != ""
                                      ? model.paraderoId
                                      : ""
                                  }
                                  onChange={_onChangeParadero}
                                  onBlur={_onBlurEmbarque}
                                  required
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                  componentRef={campamentoRef}
                                  label="Destino (Desembarque)"
                                  allowFreeform
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  disabled={
                                    ubicacionCampamentoId.length > 0 &&
                                    isVuelo &&
                                    isOnlyKeyUserEmpleado
                                  }
                                  calloutProps={{ directionalHintFixed: true }}
                                  onKeyUp={_onUpCampamento}
                                  options={destino}
                                  selectedKey={
                                    model &&
                                    model.ubicacionCampamentoId != null &&
                                    model.ubicacionCampamentoId != ""
                                      ? model.ubicacionCampamentoId
                                      : ""
                                  }
                                  onChange={_onChangeCampamento}
                                  onBlur={_onBlurCampamento}
                                  required
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                  label="Seleccionar Turno"
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={turno}
                                  openOnKeyboardFocus
                                  selectedKey={model.turno}
                                  onChange={_onChangeTurno}
                                  required
                                />
                              </div>

                              {/* {isMMG != undefined && isMMG && */}
                              {true && (
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                  <ComboBox
                                    componentRef={LugarMovilizacoinRef}
                                    label="Lugar Movilización"
                                    autoComplete="off"
                                    useComboBoxAsMenuWidth
                                    calloutProps={{
                                      directionalHintFixed: true,
                                    }}
                                    onKeyUp={_onUpMOvilizacion}
                                    options={lugarMovilizacion}
                                    selectedKey={
                                      model ? model.lugarMovilizacionId : ""
                                    }
                                    onChange={(e, o) =>
                                      _onChangeCombo("lugarMovilizacion", e, o)
                                    }
                                    disabled={isMMG}
                                    required
                                  />
                                </div>
                              )}

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                  label="Centro Costos"
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={centroCosto}
                                  selectedKey={model ? model.centroCostoId : ""}
                                  onChange={(e, o) =>
                                    _onChangeCombo("centroCosto", e, o)
                                  }
                                  required
                                  componentRef={centroCostoRef}
                                  onKeyUp={_onKeyUpComboCentroCosto}
                                  allowFreeform
                                  disabled={isMMG}
                                />
                              </div>

                              {/* <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <Toggle
                                  label="Clasificación Colaborador"
                                  defaultChecked
                                  onText="Titular"
                                  offText="Suplente"
                                  onChange={_onChangeClasificacion}
                                />
                              </div> */}

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <Toggle
                                  label="Requiere Transporte"
                                  placeholder="placeholder"
                                  onText="Si"
                                  offText="No"
                                  checked={
                                    model ? model.requiereTransporte : false
                                  }
                                  onChange={_onToggleComunidad}
                                  disabled={codigoTipoProgramacion != "02"}
                                />
                              </div>
                              {/* <div className="ms-Grid-row">
                              </div> */}
                            </div>
                          </div>
                          {/* Ubigeo */}
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div
                                  className="ms-fontSize-16"
                                  style={{ color: "#4676DD" }}
                                >
                                  Ubigeo (Ubicación actual)
                                </div>
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <ComboBox
                                  componentRef={departamentoRef}
                                  label="Departamento"
                                  allowFreeform
                                  autoComplete="on"
                                  useComboBoxAsMenuWidth
                                  openOnKeyboardFocus
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={Ubigueo}
                                  selectedKey={
                                    detailUbigeo
                                      ? detailUbigeo.departamentoId
                                      : ""
                                  }
                                  onChange={_onChangeDepartamento}
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <ComboBox
                                  label="Provincia"
                                  componentRef={provinciaRef}
                                  allowFreeform
                                  autoComplete="on"
                                  useComboBoxAsMenuWidth
                                  openOnKeyboardFocus
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={Provincias}
                                  selectedKey={
                                    detailUbigeo ? detailUbigeo.provinciaId : ""
                                  }
                                  onChange={_onChangeProvincia}
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <ComboBox
                                  label="Distrito"
                                  allowFreeform
                                  autoComplete="on"
                                  useComboBoxAsMenuWidth
                                  openOnKeyboardFocus
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={Distritos}
                                  selectedKey={model.ubigeoId}
                                  onChange={_onChangeDistrito}
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          {/* Transporte / hotel */}
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                  label={
                                    requiereVuelo
                                      ? "Empresa Helo"
                                      : "Empresa Transportista"
                                  }
                                  allowFreeform
                                  autoComplete="on"
                                  // disabled={isTitular}
                                  disabled={
                                    model ? !model.requiereTransporte : false
                                  }
                                  useComboBoxAsMenuWidth
                                  openOnKeyboardFocus
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={transporte}
                                  selectedKey={model.transporteId}
                                  onChange={_onChangeTransporte}
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                {programacion?.tieneCuarentena && (
                                  <ComboBox
                                    label="Hotel"
                                    allowFreeform
                                    autoComplete="on"
                                    useComboBoxAsMenuWidth
                                    openOnKeyboardFocus
                                    calloutProps={{
                                      directionalHintFixed: true,
                                    }}
                                    options={hotel}
                                    selectedKey={model.hotelId}
                                    onChange={_onChangeHotel}
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Info contacto */}
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                  label="Dirección"
                                  placeholder="Dirección"
                                  value={model.direccion}
                                  onChange={_onChangeDireccion}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                  label="Email"
                                  placeholder="Email"
                                  value={model.email}
                                  onChange={_onChangeEmail}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                  label="Teléfono"
                                  placeholder="Teléfono"
                                  value={model.celular}
                                  onChange={_onChangeTelefono}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </Stack>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      </PanelGenerico>
    </>
  );
};
