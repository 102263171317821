import { IUpdateEmpresa } from "../interfaces/IEmpresa";
import { Ifilter } from "../interfaces/IFilter";
import { fetchAdministracion } from "./http-common";

let urlEmpresa = 'Empresa';

const all = (filter: Ifilter) => {
    return fetchAdministracion.get(`${urlEmpresa}/ListarPorCustomer`, { params: filter });
}

const allVuelo = (filter: Ifilter) => {
    return fetchAdministracion.get(`${urlEmpresa}/Vuelo`, { params: filter });
}

const allTitularMMG = (filter: Ifilter) => {
    return fetchAdministracion.get(`${urlEmpresa}/TitularMMG`, { params: filter });
}

const actualizaEmpresa = (data: IUpdateEmpresa) => {
    return fetchAdministracion.put(`${urlEmpresa}`, data);
}

const EmpresaService = {
    all,
    allVuelo,
    allTitularMMG,
    actualizaEmpresa,
}

export default EmpresaService;