import { Pivot, PivotItem } from "@fluentui/react";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { TAB_BAJADA, TAB_SUBIDA, _initialFilter, _initialPagination } from "../../../utils/Helper/helper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import { ProgAdicionalGerencia } from "./tab/progAdicionalGerencia";

export const ListarProgAdicionalGerencia = () => {
    return (
        <>
            <HeadingBar
                title='Gerencia de Área'
                subTitle='Gestión de adicionales'
                searchShow={false}
            ></HeadingBar>

            <div style={{ padding: '20px', paddingTop: '10px' }}>
                <Pivot
                    aria-label="Pivot Overflow Menu Example"
                    // linkFormat={'tabs'}
                    overflowBehavior={'none'}
                    overflowAriaLabel="more items"
                    style={{ paddingTop: '10px' }}
                >
                    <PivotItem headerText={TAB_SUBIDA}>
                        <ProgAdicionalGerencia actividad={1}></ProgAdicionalGerencia>
                    </PivotItem>
                    <PivotItem headerText={TAB_BAJADA}>
                        <ProgAdicionalGerencia actividad={2}></ProgAdicionalGerencia>
                    </PivotItem>
                </Pivot>
            </div>
        </>
    )
}