import { FontSizes, NeutralColors, Panel, PrimaryButton, DefaultButton, Stack, ActivityItem, FontIcon, IStackStyles } from '@fluentui/react';
import * as React from 'react';
import { IPanelFiltro } from '../../interfaces/base/IPanel';
import ThemeBase from '../../utils/Theme/themeBase';

const stackStyles: IStackStyles = {
    root: {
        background: '#ffff',
    },
};

export function PanelPlantillaFiltro({
    textHeader = '',
    subTextHeader = '',
    textBoton = '',
    textBotonDefault = '',
    onclickBoton,
    onclickBotonDefault,
    openPanel,
    closePanel,
    size,
    disabledBoton,
    estadoBarraEnvio,
    nameIconHeader = '',
    nameIconButton = '',
    children

}: IPanelFiltro) {

    const onRenderHeader = React.useCallback(
        () => (
            <>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg11" style={{ paddingLeft: '2em' }} >
                    <ActivityItem
                        style={{
                            marginTop: 3
                        }}
                        activityDescription={[
                            <div>
                                <div className="LayoutPage-demoBlock">
                                    <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size18 }}>{textHeader}</div>
                                </div>
                            </div>

                        ]}
                        activityIcon={
                            <FontIcon
                                iconName={nameIconHeader}
                                style={{
                                    color: ThemeBase.theme.palette.blackTranslucent40,
                                    fontSize: 35,
                                    height: 35,
                                    width: 35,
                                    marginTop: 10
                                    // margin: "0 5px",

                                }}
                            />
                        }
                        comments={[
                            <div className="LayoutPage-demoBlock">
                                <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>{subTextHeader}</div>
                                <br />
                                <br />
                            </div>
                        ]}
                        // timeStamp={}
                        className={ThemeBase.activityClass.exampleRoot}
                    />
                </div>
            </>
        ),
        [openPanel],
    );

    const onRenderFooter = React.useCallback(
        () => (
            <>
                {estadoBarraEnvio == false && (
                    <>
                        <Stack horizontal horizontalAlign="end" styles={stackStyles}>
                            <DefaultButton onClick={onclickBotonDefault}>{textBotonDefault}</DefaultButton>
                            <PrimaryButton
                                onClick={onclickBoton}
                                style={{ marginLeft: '12px'}}
                                text={textBoton}
                                disabled={disabledBoton}
                                iconProps={
                                    {
                                        iconName: `${nameIconButton}`
                                    }
                                }
                            />
                        </Stack>
                    </>
                )}
            </>
        ),
        [textBoton, closePanel, onclickBoton],
    );

    return (
        <>
            <div>
                <Panel
                    isOpen={openPanel}
                    onDismiss={closePanel}
                    onRenderHeader={onRenderHeader}
                    type={size}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={onRenderFooter}
                    isFooterAtBottom={true}
                    isLightDismiss
                >
                    
                    {children}


                </Panel>
            </div>

        </>
    )
}
