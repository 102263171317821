import { INavLinkGroup } from "@fluentui/react";
import { IAuth, IGoogle, IMicrosoft, IUser } from "../auth/interface/IAuth";
import { keyAccess, keyJwt } from "../utils/Helper/helper";
import { CLAIM_EXP, CLAIM_USER_ID } from "../utils/Helper/roleHelper";
import { fetchIdentidad } from "./http-common";
import TokenService from "./TokenService";

const urlAuth = 'Login';
const urlAccess = 'Access';

const login = (data: IUser) => {
    return fetchIdentidad.post<IAuth>(`loginWeb`, data);
}

const access = () => {
    return fetchIdentidad.post<INavLinkGroup[]>(
        `${urlAccess}`,
        {}, {
        headers: {
            "Authorization": `Bearer ${TokenService.getToken()}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
    }
    );
}

const getAccess = () => {
    let c = localStorage.getItem(keyAccess);

    if (c) {
        return JSON.parse(c);
    }

    return null;
}

const getUserId = () => {
    let s = TokenService.getToken();

    if (s) {
        let t: any = JSON.parse(atob(s.split('.')[1]))
        return t[CLAIM_USER_ID];
    }

    return null;
}

const getInfoToken = (key: string) => {
    let s = TokenService.getToken();
    // console.log("SSSSS", JSON.parse(Buffer.from(s!.split('.')[1], 'base64').toString()))
    if (s) {
        // let t: any = JSON.parse(atob(s.split(".")[1]));
        let t: any = JSON.parse(Buffer.from(s!.split('.')[1], 'base64').toString())
        return t[key];
    }

    return null;
};

const removeToken = () => {
    localStorage.removeItem(keyJwt)
}

const verifiedExp = () => {
    let exp = new Date(AuthService.getInfoToken(CLAIM_EXP) * 1000);
    let now = new Date();

    if (exp < now) {
        return true;
    }
    return false;
}

const loginGoogle = (data: IGoogle) => {
    return fetchIdentidad.post<IAuth>(`${urlAuth}/Google`, data);
};

const loginMicrosoft = (data: IMicrosoft) => {
    return fetchIdentidad.post<IAuth>(`${urlAuth}/Microsoft`, data);
};



const AuthService = {
    login,
    access,
    getAccess,
    getUserId,
    removeToken,
    verifiedExp,
    loginGoogle,
    loginMicrosoft,
    getInfoToken,
}

export default AuthService;