import { ComboBox, IColumn, IComboBox, IComboBoxOption, ICommandBarItemProps, Icon, IconButton, Modal, SelectionMode, Stack, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadingBar } from "../../../components/Generic/HeadingBar"
import { IException, IItemComboBaseDTO, ISearch } from "../../../interfaces/base/IBase";
import { ICreateSupervisorOrganigrama, ISupervisorOrganigrama } from "../../../interfaces/IOrganigrama";
import SupervisorOrganigramaService from "../../../services/organigramaService";
import { splitExceptionDetail, stackTokens, stackTokensHorizontal, _initialFilter, _onRenderHeader, _onRenderRow } from "../../../utils/Helper/helper";
import { useBoolean } from '@fluentui/react-hooks';
import { ModalHelper } from "../../../utils/Helper/modalHelper";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import React from "react";
import OrganigramaService from "../../../services/organigramaService";
import ThemeBase from "../../../utils/Theme/themeBase";
import ConfiguracionService from "../../../services/configuracionService";
import { TableBase } from "../../../components/Table/TableBase";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { ConfigHelper } from "../../../utils/Helper/configHelper";

const initialTipo = [
    { key: '1', text: 'KeyUser' },
    { key: '2', text: 'Gerente Solicitante' },
];

export const AsignacionGerencia = () => {
    const navigate = useNavigate();

    const [hidden, setHidden] = useState(false);
    const [data, setData] = useState<ISupervisorOrganigrama[]>([]);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [user, setUser] = useState<IItemComboBaseDTO[]>([]);
    const [gerencia, setGerencia] = useState<IItemComboBaseDTO[]>([]);
    const [form, setForm] = useState<ICreateSupervisorOrganigrama>(null!);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [tipo, setTipo] = useState<IItemComboBaseDTO[]>(initialTipo);
    const search = ""

    /* Effect */
    useEffect(() => {
        setTitleColumn();
        setup();
    }, []);

    /* Api */
    const dataSupervisorOrganigrama = () => {
        setData([]);

        SupervisorOrganigramaService.allSupervisorOrganigrama().then(res => {
            setData(res.data);
            setHidden(true);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        })
    }

    const dataKeyUser = () => {
        setUser([]);
        ConfiguracionService.allKeyUser(search).then(res => {
            if (res.status == 200) {
                setUser(res.data);
            }
            setHidden(true);
        }).catch((e: IException) => {
            setHidden(true);
            alert(splitExceptionDetail(e));
        })
    }

    const dataGerenteSolicitante = () => {
        setUser([]);
        ConfiguracionService.allGerenteSolicitante().then(res => {
            if (res.status == 200) {
                setUser(res.data);
            }
            setHidden(true);
        }).catch((e: IException) => {
            setHidden(true);
            alert(splitExceptionDetail(e));
        })
    }

    const dataGerencia = () => {
        setGerencia([]);

        OrganigramaService.organigramaByTipo('01', _initialFilter()).then(res => {
            setGerencia(res.data);
            setHidden(true);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        })
    }

    const asignarGerencia = () => {
        OrganigramaService.asignarGerencia(form).then(res => {
            if (res.status == 200) {
                hideModal();
                setup();
                alert('Se asignó correctamente');
            }
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        })
    }

    const eliminarAsignacion = (id: string) => {
        OrganigramaService.eliminarAsignacion(id).then(res => {
            if (res.status == 200) {
                setup();
                alert('Se eliminó correctamente');
            }
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        })
    }

    /* Function */
    const setup = () => {
        setHidden(false);
        dataSupervisorOrganigrama();
        dataGerencia();
    }

    const goBack = () => {
        navigate(`${ConfigHelper.urlBase()}/configuraciones/keyuser`);
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Usuario Supervisor',
                fieldName: 'supervisor',
                minWidth: 300,
                maxWidth: 300,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column1.1',
                name: 'Tipo',
                fieldName: 'tipo',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Gerencia',
                fieldName: 'organigrama',
                minWidth: 300,
                maxWidth: 300,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Fecha Inicio',
                fieldName: 'inicio',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column14",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: ISupervisorOrganigrama) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <>
                                <TooltipHost content={`Eliminar`}>
                                    <Icon iconName='Delete'
                                        style={{ cursor: 'pointer' }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => eliminarAsignacion(item.supervisorOrganigramaId)}
                                    />
                                </TooltipHost>
                            </>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: goBack
            },
            {
                ...IconHelper.ICON_ADD,
                onClick: showModal
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: setup
            },
        ];
        return _items;
    }

    /* Event */
    const _onChange = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            console.log(option);
            if (option != undefined) {
                switch (type) {
                    case 'tipo':
                        setForm({
                            ...form,
                            supervisorId: '',
                        })

                        switch (option.key as string) {
                            case '1':
                                dataKeyUser();
                                break;
                            case '2':
                                dataGerenteSolicitante();
                                break;
                            default:
                                break;
                        }

                        setForm({
                            ...form,
                            tipo: parseInt(option.key as string),
                        })
                        break;
                    case 'supervisor':
                        setForm({
                            ...form,
                            supervisorId: String(option.key),
                        })
                        break;
                    case 'gerencia':
                        setForm({
                            ...form,
                            organigramaId: String(option.key),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return (
        <>
            <HeadingBar
                title='Asignación de Gerencias'
                subTitle='Gestión de Gerencias'
                searchShow={false}
            ></HeadingBar>

            <TableBase leftBar={_leftBar()} column={column} data={data} isLoading={!hidden}></TableBase>

            <>
                <Modal
                    isOpen={isModalOpen}
                    onDismiss={hideModal}
                    isBlocking={false}
                    containerClassName={ModalHelper.modalStyle.container}
                >
                    <form>
                        <div className={ModalHelper.modalStyle.header}>
                            <span id={''}>Asignar Gerencia</span>
                            <IconButton
                                styles={ModalHelper.modalIconButtonStyles}
                                iconProps={ModalHelper.modalCancelIcon}
                                ariaLabel="Close popup modal"
                                onClick={hideModal}
                            />
                        </div>

                        <div className={ModalHelper.modalStyle.body} style={{ minWidth: 500 }}>
                            <Stack tokens={stackTokens}>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <ComboBox
                                                label='Tipo'
                                                autoComplete='off'
                                                useComboBoxAsMenuWidth
                                                calloutProps={{ directionalHintFixed: true }}
                                                options={tipo}
                                                selectedKey={form ? form.tipo.toString() : ''}
                                                onChange={(e, o) => _onChange('tipo', e, o)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <ComboBox
                                                label='Usuario Supervisor'
                                                autoComplete='off'
                                                useComboBoxAsMenuWidth
                                                calloutProps={{ directionalHintFixed: true }}
                                                options={user}
                                                selectedKey={form ? form.supervisorId : ''}
                                                onChange={(e, o) => _onChange('supervisor', e, o)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <ComboBox
                                                label='Gerencia'
                                                autoComplete='off'
                                                useComboBoxAsMenuWidth
                                                calloutProps={{ directionalHintFixed: true }}
                                                options={gerencia}
                                                selectedKey={form ? form.organigramaId : ''}
                                                onChange={(e, o) => _onChange('gerencia', e, o)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <ButtonForm back={hideModal} register={asignarGerencia}></ButtonForm>
                            </Stack>
                        </div>
                    </form>
                </Modal>
            </>
        </>
    )
}