import {
  ComboBox,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  DialogType,
  IColumn,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  Icon,
  IconButton,
  IImageProps,
  Image,
  ImageFit,
  MessageBar,
  Modal,
  ProgressIndicator,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Generic/card";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import { TableBase } from "../../components/Table/TableBase";
import {
  IAsignarRol,
  IBase,
  ICard,
  IEliminarRolUser,
  IException,
  IItemComboBaseDTO,
  IRol,
  IUsuarioCuenta,
  IUsuarioHistorico,
} from "../../interfaces/base/IBase";
import ConfiguracionService from "../../services/configuracionService";
import {
  baseImageUrl,
  splitExceptionDetail,
  stackTokens,
  _initialFilter,
} from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { ModalHelper } from "../../utils/Helper/modalHelper";
import { ButtonForm } from "../../components/Generic/buttonForm";
import PersonaService from "../../services/PersonaService";
import { IListarPersonaById, IPersona } from "../../interfaces/IPersona";
import TokenService from "../../services/TokenService";
import CuentaService from "../../services/cuentaService";
import { ICreateUsuarioCuenta } from "../../interfaces/IUsuarioCuenta";
import { DialogBase } from "../../components/Dialog/DialogBase";
import { AuthContext } from "../../auth/context/auth-context";
import {
  isRol,
  isRoleAccessAll,
  roleAdmin,
  ROLE_ADMIN,
  ROLE_AREA_TRANSPORTE_ADMIN,
  ROLE_CONTRATISTA,
} from "../../utils/Helper/roleHelper";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import ProgramacionPersonaService from "../../services/programacionPersonaService";
import { IFilterHistorico } from "../../interfaces/filter/IFilterHistorico";
import { IHistoricoAcceso } from "../../interfaces/IProgramacion";
import { State } from "../../components/Generic/state";
import { usePerfilPersonaDIspositivo } from "../../hooks/usePerfilPersonaDIspositivo";

const roleWidget = () => {
  let a = [ROLE_AREA_TRANSPORTE_ADMIN];
  return roleAdmin().concat(a);
};

const roleWidgetSSEE = () => {
  let a = [ROLE_CONTRATISTA];
  return roleAdmin().concat(a);
};

const dialogContentProps = {
  type: DialogType.largeHeader,
  title: "Autorizar dispositivo",
  closeButtonAriaLabel: "Close",
  subText: "Autorice un nuevo dispositivo",
};
export const Perfil = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [info, setInfo] = useState<IPersona>(null!);
  const [dataRol, setDataRol] = useState<IRol>(null!);
  const [rol, setRol] = useState<IItemComboBaseDTO[]>([]);
  const [columnRol, setColumnRol] = useState<IColumn[]>([]);
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [form, setForm] = useState<IAsignarRol>(null!);
  const [formUsuarioCuenta, setFormUsuarioCuenta] =
    useState<ICreateUsuarioCuenta>(null!);

  /* Usuario Cuenta */
  const [dataUsuarioCuenta, setDataUsuarioCuenta] = useState<IUsuarioCuenta[]>(
    []
  );
  const [columnUsuarioCuenta, setColumnUsuarioCuenta] = useState<IColumn[]>([]);
  const [
    isOpenUsuarioCuenta,
    { setTrue: showUsuarioCuenta, setFalse: hideUsuarioCuenta },
  ] = useBoolean(false);
  const [cuenta, setCuenta] = useState<IItemComboBaseDTO[]>([]);

  /* Historial Programaciones Por Usuario */
  const [
    dataUsuarioHistorialProgramaciones,
    setDataUsuarioHistorialProgramaciones,
  ] = useState<IUsuarioHistorico[]>([]);
  const [
    columnUsuarioHistorialProgramaciones,
    setColumnUsuarioHistorialProgramaciones,
  ] = useState<IColumn[]>([]);
  const [filter, setFilter] = useState<IFilterHistorico>(_initialFilter);
  const [total, setTotal] = useState(0);

  /* Historial Dispositivos Por Usuario */
  const [
    columnUsuarioHistorialDispositivos,
    setColumnUsuarioHistorialDispositivos,
  ] = useState<IColumn[]>([]);

  const [hideDialog, { toggle: dismissDialog }] = useBoolean(true);
  const {
    dataUsuarioHistorialDispositivos,
    getDataDispositivoPersonaId,
    autorizarDispositivo,
    loadingAutorizar,
  } = usePerfilPersonaDIspositivo(id, dismissDialog);
  /* Dialog */
  const [hideGenerarUsuario, { toggle: toggleGenerarUsuario }] =
    useBoolean(true);

  /* Loading */
  const [
    isLoadingGenerar,
    { setTrue: loadingGenerar, setFalse: dismissLoadingGenerar },
  ] = useBoolean(false);

  /* Widget */
  const [widget, setWidget] = useState(0);

  const { access } = useContext(AuthContext);
  let role = access?.role ?? [];
  let isRoleAdmin = isRoleAccessAll(role, roleAdmin());

  const [isAdmin, setIsAdmin] = useState(false);
  const isShowWidget = isRoleAccessAll(role, roleWidget());
  const isShowWidgetOnlySEE = isRoleAccessAll(role, roleWidgetSSEE());

  /* useEffect */
  useEffect(() => {
    if (access) {
      setIsAdmin(isRol(access, ROLE_ADMIN));
    }
  }, [access]);

  useEffect(() => {
    if (id != undefined) {
      setTitleColumUsuarioCuenta();
      setup();
    }

    setForm({
      ...form,
      personaId: String(id),
    });

    setFormUsuarioCuenta({
      ...formUsuarioCuenta,
      personaId: String(id),
    });
  }, [id]);

  useEffect(() => {
    if (dataRol) {
      console.log(dataRol.identityId);
    }
    setTitleColumRol();
  }, [dataRol]);

  useEffect(() => {
    reloadInitial();
  }, []);

  useEffect(() => {
    allHistorialProgramacionesByPersona();
  }, []);

  const reloadInitial = (param = filter) => {
    //setHidden(false);
    allHistorialProgramacionesByPersona();
    setTitleUsuarioHistorialProgramaciones();
    setTitleUsuarioHistorialDispositivos();
  };

  /* Api */
  const infoPersona = () => {
    if (id != undefined) {
      let d: IListarPersonaById = {
        listaPersonas: [id],
      };

      PersonaService.getInfoPersonaById(d)
        .then((res) => {
          if (res.status == 200) {
            setInfo(res.data[0]);
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  const allRolByPersona = () => {
    if (id != undefined) {
      ConfiguracionService.rolByPersonaId(id)
        .then((res) => {
          if (res.status == 200) {
            setDataRol(res.data);
            console.log(res.data);
          }
        })
        .catch((e: IException) => {
          console.log(splitExceptionDetail(e));
        });
    }
  };

  const allRol = () => {
    ConfiguracionService.allRol()
      .then((res) => {
        if (res.status == 200) {
          let d: IItemComboBaseDTO[] = res.data;
          let t = d.filter((x) => x.text.toLowerCase());

          if (!isRoleAdmin) {
            t = t.filter((x) => x.text != ROLE_ADMIN);
          }
          setRol(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const asignarRol = () => {
    ConfiguracionService.asignarRolByPersona(form)
      .then((res) => {
        if (res.status == 200) {
          setForm({
            ...form,
            rol: [],
          });
          hideModal();
          setup();
          alert("Se registró correctamente");
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const eliminarRolUser = (item: IItemComboBaseDTO) => {
    if (dataRol) {
      let d: IEliminarRolUser = {
        identityId: dataRol.identityId,
        roleId: item.key,
      };

      ConfiguracionService.eliminarRolUser(d)
        .then((res) => {
          if (res.status == 200) {
            setup();
            alert("Se eliminó correctamente");
          }
        })
        .catch((e: IException) => {
          console.log(e.response);
        });
    }
  };

  const allCuentaByPersona = () => {
    if (id != undefined) {
      ConfiguracionService.cuentaByPersonaId(id)
        .then((res) => {
          if (res.status == 200) {
            console.log(res);
            setDataUsuarioCuenta(res.data);
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  const allHistorialProgramacionesByPersona = () => {
    if (id != undefined) {
      ProgramacionPersonaService.historicoByPersona(id)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setDataUsuarioHistorialProgramaciones(res.data.items);
            setTotal(res.data.total);
            console.log(res.data.items);
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  const allCuenta = () => {
    if (id != undefined) {
      let t = TokenService.getToken();
      if (t) {
        let d: any = JSON.parse(atob(t.split(".")[1]));
        let c = d["Mega"];

        if (c != undefined) {
          CuentaService.cuentaByCustomer(c)
            .then((res) => {
              if (res.status == 200) {
                let d: IBase[] = res.data;
                let t: IItemComboBaseDTO[] = d.map((x) => {
                  return {
                    key: x.id,
                    text: x.nombre,
                  };
                });

                setCuenta(t);
              }
            })
            .catch((e: IException) => {
              alert(splitExceptionDetail(e));
            });
        }
      }
    }
  };

  const agregarCuenta = () => {
    if (id != undefined) {
      CuentaService.agregarUsuarioCuenta(formUsuarioCuenta)
        .then((res) => {
          if (res.status == 200) {
            setup();
            hideUsuarioCuenta();
            alert("Se registró correctamente");
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  const generarUsuario = () => {
    if (id != undefined) {
      loadingGenerar();

      ConfiguracionService.crearUsuarioPorPersona(id)
        .then((res) => {
          if (res.status == 200) {
            setup();
            toggleGenerarUsuario();
            dismissLoadingGenerar();
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
          dismissLoadingGenerar();
        });
    }
  };

  /* Function */
  const setup = () => {
    infoPersona();
    allRolByPersona();
    allCuentaByPersona();
    allCuenta();
    allRol();
  };

  const imageProps: IImageProps = {
    imageFit: ImageFit.cover,
    src: baseImageUrl,
    styles: (props) => ({ root: { borderRadius: "50%" } }),
  };

  const leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        onClick: () => {
          navigate(`${ConfigHelper.urlBase()}/personas`);
        },
      },
    ];

    return _items;
  };

  const rigthBar = () => {
    let _items: ICommandBarItemProps[] = [];

    if (isAdmin) {
      _items = [
        {
          ...IconHelper.ICON_CHECK,
          text: "Generar Usuario",
          onClick: () => {
            if (info) {
              if (info.email == "" || info.email == undefined) {
                return alert("El colaborador no tiene un email válido");
              }

              if (info.nroDOI == "" || info.nroDOI == undefined) {
                return alert("El colaborador no tiene un nro DOI válido");
              }

              toggleGenerarUsuario();
            }
          },
        },
      ];
    }

    return _items;
  };

  const leftBarRole = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_ADD,
        onClick: showModal,
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: setup,
      },
    ];

    return _items;
  };

  const leftBarUsuarioCuenta = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_ADD,
        onClick: showUsuarioCuenta,
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: setup,
      },
    ];

    return _items;
  };

  const leftBarHistorialProgramaciones = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_RELOAD,
        onClick: getDataDispositivoPersonaId,
      },
      {
        ...IconHelper.ICON_ADD,
        onClick: dismissDialog,
      },
    ];

    return _items;
  };

  const dataCard = () => {
    let d: ICard = {
      card: [],
    };

    if (isShowWidget) {
      d = {
        style: {
          paddingTop: "2%",
        },
        card: [
          {
            title: "Roles",
            icon: "ChangeEntitlements",
            onClick: () => {
              setWidget(1);
            },
          },
          {
            title: "Cuentas",
            icon: "ChangeEntitlements",
            onClick: () => {
              setWidget(2);
            },
          },
          {
            title: "Programaciones",
            icon: "ChangeEntitlements",
            onClick: () => {
              setWidget(3);
            },
          },
          {
            title: "Dispositivos",
            icon: "ChangeEntitlements",
            onClick: () => {
              setWidget(4);
            },
          },
        ],
      };
    } else if (isShowWidgetOnlySEE) {
      d = {
        style: {
          paddingTop: "2%",
        },
        card: [
          {
            title: "Programaciones",
            icon: "ChangeEntitlements",
            onClick: () => {
              setWidget(3);
            },
          },
        ],
      };
    }

    return d;
  };

  const setTitleColumRol = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Rol",
        fieldName: "text",
        minWidth: 400,
        maxWidth: 500,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 400,
        maxWidth: 450,
        isResizable: true,
        isCollapsible: true,
        onRender: (item: IItemComboBaseDTO) => (
          <>
            {dataRol && (
              <TooltipHost content={`Eliminar`}>
                <Icon
                  iconName="Delete"
                  style={{ cursor: "pointer" }}
                  className={ThemeBase.classes.iconFont}
                  onClick={() => {
                    eliminarRolUser(item);
                  }}
                />
              </TooltipHost>
            )}
          </>
        ),
      },
    ];

    setColumnRol(col);
  };

  const setTitleColumUsuarioCuenta = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Cuenta",
        fieldName: "cuenta",
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Fecha Creación",
        fieldName: "creacion",
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        isCollapsible: true,
        onRender: (item: IItemComboBaseDTO) => (
          <>
            <TooltipHost content={`Eliminar`}>
              <Icon
                iconName="Delete"
                style={{ cursor: "pointer" }}
                className={ThemeBase.classes.iconFont}
                onClick={() => {
                  alert("delete cuenta");
                }}
              />
            </TooltipHost>
          </>
        ),
      },
    ];

    setColumnUsuarioCuenta(col);
  };

  const setTitleUsuarioHistorialProgramaciones = () => {
    let col: IColumn[] = [
      // {
      //   key: "column1",
      //   name: "Nombre",
      //   fieldName: "nombres",
      //   minWidth: 50,
      //   maxWidth: 100,
      //   isResizable: true,
      //   data: "string",
      //   isPadded: true,
      // },
      // {
      //   key: "column2",
      //   name: "Identificación",
      //   fieldName: "nroDOI",
      //   minWidth: 50,
      //   maxWidth: 100,
      //   isResizable: true,
      //   data: "string",
      //   isPadded: true,
      // },
      {
        key: "column1",
        name: "Programación",
        fieldName: "tituloProgramacion",
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Fecha Mov.",
        fieldName: "fechaMovilizacion",
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Actividad",
        fieldName: "actividad",
        minWidth: 50,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Turno",
        fieldName: "turno",
        minWidth: 50,
        maxWidth: 50,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      // {
      //   key: 'column6',
      //   name: 'Gerencia',
      //   fieldName: 'gerencia',
      //   minWidth: 50,
      //   maxWidth: 100,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: 'string',
      // },
      // {
      //   key: 'column7',
      //   name: 'Empresa Persona',
      //   fieldName: 'empresa',
      //   minWidth: 70,
      //   maxWidth: 70,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: 'string',
      // },
      {
        key: "column5",
        name: "Transporte",
        fieldName: "requiereTransporte",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item: IHistoricoAcceso) => {
          return (
            <span style={{ fontSize: "95%", ...ThemeBase.justifiedStyle }}>
              {item.requiereTransporte ? "Si" : "No"}
            </span>
          );
        },
      },
      // {
      //   key: 'column9',
      //   name: 'Comunidad',
      //   fieldName: 'esComunidad',
      //   minWidth: 80,
      //   maxWidth: 80,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: 'string',
      //   onRender: (item: IHistoricoAcceso) => {
      //       return (
      //           <span
      //               style={{ fontSize: '95%', ...ThemeBase.justifiedStyle }}>{item.esComunidad ? 'Si' : 'No'}</span>
      //       );
      //   },
      // },
      {
        key: "column6",
        name: "Visita",
        fieldName: "esVisita",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item: IHistoricoAcceso) => {
          return (
            <span style={{ fontSize: "95%", ...ThemeBase.justifiedStyle }}>
              {item.esVisita ? "Si" : "No"}
            </span>
          );
        },
      },
      {
        key: "column7",
        name: "Empresa Transportista",
        fieldName: "transporte",
        minWidth: 80,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column8",
        name: "Es Adicional",
        fieldName: "esAdicional",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column9",
        name: "Proceso - Estado",
        fieldName: "nivelActual",
        minWidth: 90,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      // {
      //   key: 'column14',
      //   name: 'Lugar Mov.',
      //   fieldName: 'lugarMovilizacion',
      //   minWidth: 50,
      //   maxWidth: 100,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: 'number',
      // },
      {
        key: "column10",
        name: "Origen",
        fieldName: "origen",
        minWidth: 50,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column11",
        name: "Destino",
        fieldName: "destino",
        minWidth: 50,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      // {
      //   key: "column17",
      //   name: "Centro de Costo",
      //   fieldName: "centroCosto",
      //   minWidth: 50,
      //   maxWidth: 100,
      //   isResizable: true,
      //   data: "string",
      //   isPadded: true,
      // },
      {
        key: "column12",
        name: "Usuario Registra",
        fieldName: "nombreUsuarioRegistra",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column13",
        name: "Estado",
        fieldName: "estado",
        minWidth: 70,
        maxWidth: 80,
        isResizable: true,
        isMultiline: true,
        data: "string",
        isPadded: true,
        onRender: (item: IHistoricoAcceso) => (
          <>
            <State
              estado={item.estadoProgramacion}
              colorEstado={item.colorProgramacion}
              width={100}
            ></State>
          </>
        ),
      },
      {
        key: "column11",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IHistoricoAcceso) => (
          <>
            <Stack horizontal>
              <>
                <TooltipHost content={`Ir a Programación`}>
                  <Icon
                    iconName={IconHelper.ICON_REDIRECT.iconProps?.iconName}
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      navigate(`${ConfigHelper.urlBase()}/programaciones/${item.programacionId}`);
                    }}
                  />
                </TooltipHost>
              </>
            </Stack>
          </>
        ),
      },
    ];

    setColumnUsuarioHistorialProgramaciones(col);
  };
  const setTitleUsuarioHistorialDispositivos = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Categoria",
        fieldName: "categoria",
        minWidth: 50,
        maxWidth: 90,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Puerta",
        fieldName: "puerta",
        minWidth: 50,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Estado",
        fieldName: "estado",
        minWidth: 50,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Tipo",
        fieldName: "tipo",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column5",
        name: "Ubicacion",
        fieldName: "ubicacion",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column6",
        name: "Modelo",
        fieldName: "modelo",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column7",
        name: "Ip",
        fieldName: "ip",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column8",
        name: "Ultima Sincronización",
        fieldName: "ultimaSincronizacion",
        minWidth: 130,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column9",
        name: "Opción",
        minWidth: 130,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item) => (
          <div>
            <a
              style={{ cursor: "pointer", fontSize: "20px" }}
              href={`${ConfigHelper.urlBase()}/movilizaciones/personas/escannerQR/${item.infoWeb}`}
              target="_blank" // Si quieres que se abra en una nueva pestaña
            >
              <TooltipHost content="Escanea el código QR">
                <Icon iconName="QRCode" />
              </TooltipHost>
            </a>
          </div>
        ),
      },
    ];

    setColumnUsuarioHistorialDispositivos(col);
  };
  /* Event */
  const _onChange = (
    type: number,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case 0:
            setForm({
              ...form,
              rol: [String(option.key)],
            });
            break;
          case 1:
            setFormUsuarioCuenta({
              ...formUsuarioCuenta,
              cuentaId: String(option.key),
            });
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <>
      <div className={ThemeBase.classes.base}>
        <Stack tokens={stackTokens}>
          <CustomCommandBar
            item={leftBar()}
            farItem={info ? (!info.isUser ? rigthBar() : []) : []}
          ></CustomCommandBar>

          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div
                className="ms-Grid-col ms-sm2 ms-md2 ms-lg3"
                style={{ paddingTop: "40px" }}
              >
                <Stack horizontalAlign="center" verticalAlign="center">
                  <Image
                    {...imageProps}
                    alt='Example of the image fit value "cover"'
                    width={120}
                    height={120}
                  />
                </Stack>
              </div>

              <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                <Stack tokens={stackTokens}>
                  <MessageBar>Datos Personales</MessageBar>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                      <div className="ms-fontSize-11 ms-fontWeight-semibold">
                        Persona:
                      </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg9">
                      <div className="ms-fontSize-11">
                        {info ? info.persona : ""}
                      </div>
                    </div>
                  </div>
                </Stack>
                <br />
                <div className="ms-Grid" dir="ltr">
                  <Stack tokens={stackTokens}>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          {info ? info.tipoDocumentoIdentidad : ""}:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg9">
                        <div className="ms-fontSize-11">
                          {info ? info.nroDOI : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Email:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg9">
                        <div className="ms-fontSize-11">
                          {info ? info.email : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Celular:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg9">
                        <div className="ms-fontSize-11">
                          {info ? info.celular : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          F. Nac. :
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg9">
                        <div className="ms-fontSize-11">
                          {info ? info?.fechaNacimineto.toString() : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Sexo :
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg9">
                        <div className="ms-fontSize-11">
                          {info ? info.sexo : ""}
                        </div>
                      </div>
                    </div>
                  </Stack>
                </div>
              </div>

              <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                <MessageBar>Datos Laborales</MessageBar>
                <br />
                <div className="ms-Grid" dir="ltr">
                  <Stack tokens={stackTokens}>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Gerencia:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg8">
                        <div className="ms-fontSize-11">
                          {info ? info.gerencia : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Superint. :
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm11 ms-md6 ms-lg8">
                        <div className="ms-fontSize-11">
                          {info ? info.superintendencia : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Tipo Per. :
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg8">
                        <div className="ms-fontSize-11">
                          {info ? info.tipoPersona : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Puesto:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg8">
                        <div className="ms-fontSize-11">
                          {info ? info.puesto : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Empresa:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg8">
                        <div className="ms-fontSize-11">
                          {info ? info.empresa : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Centro Costo:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? info.centroCosto : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Es Comunidad:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? (info.esComunidad ? "Si" : "No") : ""}
                        </div>
                      </div>
                    </div>
                  </Stack>
                </div>
              </div>

              <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                <MessageBar>Otros Datos</MessageBar>
                <br />
                <div className="ms-Grid" dir="ltr">
                  <Stack tokens={stackTokens}>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Codigo SAP:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? info.codigoSAP : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Nivel:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? info.nivel : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Level:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? info.level : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Roster:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? info.roster : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Tarjeta:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? info.tarjeta : ""}
                        </div>
                      </div>
                    </div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Estado:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? info.estado : ""}
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg5">
                        <div className="ms-fontSize-11 ms-fontWeight-semibold">
                          Lugar Movilización:
                        </div>
                      </div>
                      <div className="ms-Grid-col ms-sm14 ms-md6 ms-lg7">
                        <div className="ms-fontSize-11">
                          {info ? info.lugarMovilizacion : ""}
                        </div>
                      </div>
                    </div>
                  </Stack>
                </div>
              </div>
            </div>
          </div>

          {/* Widget */}
          <Card data={dataCard()}></Card>

          {widget == 1 && (
            /* Roles */
            <TableBase
              leftBar={leftBarRole()}
              data={dataRol ? dataRol.role : []}
              column={columnRol}
              layout={DetailsListLayoutMode.justified}
              isLoading={false}
            ></TableBase>
          )}

          {/* Cuentas */}
          {widget == 2 && (
            <TableBase
              leftBar={leftBarUsuarioCuenta()}
              data={dataUsuarioCuenta}
              column={columnUsuarioCuenta}
              layout={DetailsListLayoutMode.justified}
              isLoading={false}
            ></TableBase>
          )}

          {/* Historial Programaciones */}

          {widget == 3 && (
            <TableBase
              leftBar={leftBarHistorialProgramaciones()}
              data={dataUsuarioHistorialProgramaciones}
              column={columnUsuarioHistorialProgramaciones}
              //layout={DetailsListLayoutMode.justified}
              isLoading={false}
            ></TableBase>
          )}

          {/* Historial Dispositivos */}

          {widget === 4 && (
            <div style={{ overflowY: "scroll", height: "350px" }}>
              <TableBase
                leftBar={leftBarHistorialProgramaciones()}
                data={dataUsuarioHistorialDispositivos}
                column={columnUsuarioHistorialDispositivos}
                //layout={DetailsListLayoutMode.justified}
                isLoading={false}
              ></TableBase>
            </div>
          )}

          {/* Modal registrar rol */}
          <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            isBlocking={false}
            containerClassName={ModalHelper.modalStyle.container}
          >
            <form>
              <div className={ModalHelper.modalStyle.header}>
                <span id={""}>Asignar Rol</span>
                <IconButton
                  styles={ModalHelper.modalIconButtonStyles}
                  iconProps={ModalHelper.modalCancelIcon}
                  ariaLabel="Close popup modal"
                  onClick={hideModal}
                />
              </div>

              <div
                className={ModalHelper.modalStyle.body}
                style={{ minWidth: 500 }}
              >
                <Stack tokens={stackTokens}>
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ComboBox
                          label="Roles"
                          autoComplete="on"
                          useComboBoxAsMenuWidth
                          calloutProps={{ directionalHintFixed: true }}
                          options={rol}
                          selectedKey={
                            form ? (form.rol ? form.rol[0] : "") : ""
                          }
                          onChange={(e, o) => _onChange(0, e, o)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <ButtonForm
                    back={hideModal}
                    register={asignarRol}
                  ></ButtonForm>
                </Stack>
              </div>
            </form>
          </Modal>

          {/* Modal registrar cuenta por usuario */}
          <Modal
            isOpen={isOpenUsuarioCuenta}
            onDismiss={hideUsuarioCuenta}
            isBlocking={false}
            containerClassName={ModalHelper.modalStyle.container}
          >
            <form>
              <div className={ModalHelper.modalStyle.header}>
                <span id={""}>Agregar Cuenta</span>
                <IconButton
                  styles={ModalHelper.modalIconButtonStyles}
                  iconProps={ModalHelper.modalCancelIcon}
                  ariaLabel="Close popup modal"
                  onClick={hideUsuarioCuenta}
                />
              </div>

              <div
                className={ModalHelper.modalStyle.body}
                style={{ minWidth: 500 }}
              >
                <Stack tokens={stackTokens}>
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ComboBox
                          label="Cuentas"
                          autoComplete="on"
                          useComboBoxAsMenuWidth
                          calloutProps={{ directionalHintFixed: true }}
                          options={cuenta}
                          selectedKey={
                            formUsuarioCuenta ? formUsuarioCuenta.cuentaId : ""
                          }
                          onChange={(e, o) => _onChange(1, e, o)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <ButtonForm
                    back={hideUsuarioCuenta}
                    register={agregarCuenta}
                  ></ButtonForm>
                </Stack>
              </div>
            </form>
          </Modal>

          {/* Dialog Generar Usuario */}
          {info && (
            <DialogBase
              isHidden={hideGenerarUsuario}
              subTitle={`Se generará el usuario con el email ${info.email} y la clave ${info.nroDOI}`}
              tipo={DialogType.normal}
              toggleHidden={() => {
                dismissLoadingGenerar();
                toggleGenerarUsuario();
              }}
              dependency={[]}
              confirm={generarUsuario}
              isDisableConfirm={isLoadingGenerar}
            ></DialogBase>
          )}

          {/* Autorizar Dispositivo*/}
          <Dialog
            hidden={hideDialog}
            onDismiss={dismissDialog}
            dialogContentProps={dialogContentProps}
          >
            <ProgressIndicator
              progressHidden={loadingAutorizar}
            ></ProgressIndicator>
            <DialogFooter>
              <ButtonForm
                textRegister="Autorizar"
                back={dismissDialog}
                register={autorizarDispositivo}
              ></ButtonForm>
            </DialogFooter>
          </Dialog>
        </Stack>
      </div>
    </>
  );
};
