import { Panel, ProgressIndicator, ActivityItem, PrimaryButton, Stack, ComboBox, IComboBox, IComboBoxOption } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { CustomMessageBar, typeMessage } from "../../../components/MessageBar/customMessageBar";
import { IException } from "../../../interfaces/base/IBase";
import { IFilterTransporteVigente } from "../../../interfaces/filter/IFilterTransporte";
import { IReasignarTransporte } from "../../../interfaces/IProgramacionBus";
import ProgramacionBusService from "../../../services/programacionBusService";
import TransporteService from "../../../services/transporteService";
import { splitExceptionDetail, stackTokens, _initialFilter } from "../../../utils/Helper/helper";
import { MessageHelper } from "../../../utils/Helper/messageHelper";
import ThemeBase from "../../../utils/Theme/themeBase";

let _sel: any[] = [];

export const ReasignarTransporte = ({ programacionId = '', programacionBusId = '', transporteId = '', isOpen = false, dismiss = () => { }, sel = _sel, reloadParent = () => { } }) => {
    console.log(programacionId);

    const [transporte, setTransporte] = useState<any[]>([]);
    const [filterTransporte, setFilterTransporte] = useState<IFilterTransporteVigente>({
        ..._initialFilter(),
        programacionId: programacionId
    });
    const [selTransporteId, setSelTransporteId] = useState<string | undefined>(undefined);

    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Hook */
    useEffect(() => {
        if (isOpen && programacionId != '') {
            dataTransporte();
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen) {
            setSelTransporteId(undefined);
        }
    }, [isOpen]);

    /* Api */
    const dataTransporte = () => {
        if (programacionId != '') {
            setTransporte([]);

            let f: IFilterTransporteVigente = {
                ...filterTransporte,
                programacionId: programacionId,
            }

            setFilterTransporte(f);

            TransporteService.transporteVigente(f).then(res => {
                if (res.status == 200) {
                    setTransporte(res.data);
                }
            }).catch((e: IException) => {
                alert(splitExceptionDetail(e));
            })
        }
    }

    const reasignar = () => {
        if (selTransporteId != '') {
            setIsSend(true);
            setChoice(undefined);

            let d: IReasignarTransporte = {
                programacionBusId: programacionBusId,
                transporteId: selTransporteId as string,
                personaBusListaId: sel.map(x => x.programacionPersonaBusId),
            };

            ProgramacionBusService.reasignarTransporte(d).then(res => {
                if (res.status == 200) {
                    reloadParent();
                    setChoice('');
                    dismiss();
                    setIsSend(false);
                }
            }).catch((e: IException) => {
                setIsSend(false)
                setChoice('error')
                settextError(splitExceptionDetail(e))
            })
        }
    }

    /* Event */
    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'transporte':
                        setSelTransporteId(option.key as string);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <Stack tokens={stackTokens}>
                    <PrimaryButton onClick={reasignar} styles={ThemeBase.panelButtonStyle} disabled={isSend || selTransporteId == undefined}>
                        Reasignar
                    </PrimaryButton>
                </Stack>
            </div>
        ),
        [programacionId, programacionBusId, transporteId, dismiss, sel, isSend, selTransporteId]
    );

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText='Reasignar Transporte'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    resetChoice();
                                }}></CustomMessageBar>}
                            </div>
                        </div>
                    </div>

                    <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                        <ComboBox
                            label='Seleccionar Transporte'
                            autoComplete='off'
                            useComboBoxAsMenuWidth
                            calloutProps={{ directionalHintFixed: true }}
                            options={transporte}
                            styles={ThemeBase.comboBoxStylesDos}
                            openOnKeyboardFocus
                            selectedKey={selTransporteId}
                            onChange={(e, o) => _onChangeCombo('transporte', e, o)}
                            required
                        />

                        {sel.map((item: { key: string | number }) => (
                            <ActivityItem
                                {...item}
                                key={item.key}
                                className={ThemeBase.activityClass.exampleRoot}
                            />
                        ))}
                    </form>

                </>
            </Panel>
        </>
    )
}