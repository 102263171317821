import { ComboBox, IColumn, IComboBox, IComboBoxOption, ICommandBarItemProps, Persona, PersonaSize, TooltipHost } from "@fluentui/react"
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { TableBase } from "../../components/Table/TableBase"
import { IBase, IException, IItemComboBaseDTO, ISearch } from "../../interfaces/base/IBase";
import { IFilterPersonaTransporte, IProgramacionPersona } from "../../interfaces/IProgramacion";
import PersonaService from "../../services/PersonaService";
import ProgramacionService from "../../services/ProgramacionService";
import { baseImageUrl, IndexNone, ItemNoneSelect, splitExceptionDetail, _initialFilter } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase"
import { useBoolean } from "@fluentui/react-hooks";
import RepoteBase from "../../components/Generic/ReporteBase";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { ICollectionPersonaBus } from "../../interfaces/collection/IPersonaBus";
import { ConfigHelper } from "../../utils/Helper/configHelper";

export const ListarAccesoPersonaAreaTransporte = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    const [filterPersonaTransporte, setFilterPersonaTransporte] = useState<IFilterPersonaTransporte>({
        ..._initialFilter(),
        programacionId: id,
    });
    const [personaAsignado, setPersonaAsignado] = useState<ICollectionPersonaBus>(_initialDataCollection);
    const [columnPersonaAsignado, setColumnPersonaAsignado] = useState<IColumn[]>([]);
    const [bus, setBus] = useState<IItemComboBaseDTO[]>([]);
    const [campamento, setCampamento] = useState<IItemComboBaseDTO[]>([]);
    const [hidden, setHidden] = useState(false);

    /* Filter */
    const [filterBus, setFilterBus] = useState<ISearch>(_initialFilter);
    const [filterCampamento, setfilterCampamento] = useState<ISearch>(_initialFilter);

    /* Report Telerik */
    const [isOpenReport, { setTrue: openReport, setFalse: dismissReport }] = useBoolean(false);

    /* Hook */
    useEffect(() => {
        generateColumnPersonaAsignado();
        dataBus();
        dataCampamento();
    }, []);

    useEffect(() => {
        if (filterPersonaTransporte.programacionId != undefined) {
            dataPersonaAsignado();
        }
    }, [filterPersonaTransporte]);

    /* Api */
    const dataBus = () => {
        PersonaService.getComboTransportes()
            .then((res) => {
                if (res.status == 200) {
                    let d: IBase[] = res.data.data;
                    let t: IItemComboBaseDTO[] = [];

                    t = d.map((x) => {
                        return {
                            key: x.id,
                            text: x.nombre,
                        };
                    });

                    t.unshift(ItemNoneSelect());

                    setBus(t);
                }
            })
            .catch((e) => {
                alert(e.message);
            });
    };

    const dataCampamento = () => {
        PersonaService.getComboCampamento1(filterCampamento).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            t.unshift(ItemNoneSelect());

            setCampamento(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataPersonaAsignado = () => {
        if (id != undefined) {
            ProgramacionService.personalAsignadoEmpresaTransportista(id, filterPersonaTransporte)
                .then((res) => {
                    if (res.status == 200) {
                        setPersonaAsignado(res.data);
                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));
                });
        }
    };

    /* Function */
    const setup = () => {
        generateColumnPersonaAsignado();
        dataPersonaAsignado();
    }

    const generateColumnPersonaAsignado = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Foto",
                ariaLabel:
                    "Column operations for File type, Press to sort on File type",
                isIconOnly: true,
                fieldName: "foto",
                minWidth: 45,
                maxWidth: 45,
                onRender: (item: IProgramacionPersona) => (
                    <TooltipHost content={`Foto`}>
                        <Persona
                            text=""
                            size={PersonaSize.size24}
                            imageUrl={baseImageUrl}
                        />
                    </TooltipHost>
                ),
            },
            {
                key: "column2",
                name: "Nombre",
                fieldName: "nombres",
                minWidth: 160,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span
                            style={{
                                fontSize: "90%",
                                textDecorationLine:
                                    item.motivoRetiro == "" ? "none" : "line-through",
                            }}
                        >
                            {item.nombres}
                        </span>
                    );
                },
            },
            {
                key: "column3",
                name: "Identificación",
                fieldName: "nroDOI",
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: "number",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span
                            style={{ fontSize: "95%" }}
                        >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
                    );
                },
            },
            {
                key: "column4",
                name: "Email",
                fieldName: "email",
                minWidth: 170,
                maxWidth: 180,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column5",
                name: "Celular",
                fieldName: "celular",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column16",
                name: "Nivel",
                fieldName: "nivelActual",
                minWidth: 150,
                maxWidth: 160,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column17",
                name: "Estado",
                fieldName: "estadoActual",
                minWidth: 150,
                maxWidth: 160,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column15",
                name: "Clasificación",
                fieldName: "clasificacion",
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
            {
                key: "column15",
                name: "Es Adicional",
                fieldName: "esAdicional",
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
            {
                key: "column12",
                name: "Registrado por",
                fieldName: "nombreUsuarioRegistra",
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
            {
                key: "column13",
                name: "Validado por",
                fieldName: "nombreUsuarioValida",
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
            {
                key: "column10",
                name: "Empresa Transportista",
                fieldName: "transporte",
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
        ];

        setColumnPersonaAsignado(col);
    };

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => {
                    navigate(`${ConfigHelper.urlBase()}/area.transporte`);
                },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: setup,
            },
        ];

        return _items;
    };

    const _rightBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: openReport,
            },
            {
                ...IconHelper.ICON_INFO,
                onClick: () => {
                    alert("info");
                },
            },
        ];

        return _items;
    };

    /* Event */
    const clearComboPersonaTransporte = (type: string) => {
        switch (type) {
            case 'bus':
                setFilterPersonaTransporte({
                    ...filterPersonaTransporte,
                    busId: null
                });
                break;
            case 'destino':
                setFilterPersonaTransporte({
                    ...filterPersonaTransporte,
                    destinoId: null
                });
                break;
            default:
                break;
        }
    }

    const onChageComboPersonaTransporte = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                let isNone = option.key == IndexNone;

                if (isNone) {
                    clearComboPersonaTransporte(type);
                }

                switch (type) {
                    case 'bus':
                        if (!isNone) {
                            setFilterPersonaTransporte({
                                ...filterPersonaTransporte,
                                busId: option.key.toString(),
                            })
                        }
                        break;
                    case 'destino':
                        if (!isNone) {
                            setFilterPersonaTransporte({
                                ...filterPersonaTransporte,
                                destinoId: option.key.toString(),
                            })
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return (
        <>
            <HeadingBar title={personaAsignado.title} subTitle={personaAsignado.subTitle} searchShow={false}></HeadingBar>

            <div style={{ padding: '20px', paddingTop: '10px', paddingBottom: '0px' }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div style={ThemeBase.notPaddingSide}>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                            <ComboBox
                                                label='Bus'
                                                placeholder='Bus'
                                                autoComplete="on"
                                                useComboBoxAsMenuWidth
                                                calloutProps={{ directionalHintFixed: true }}
                                                options={bus}
                                                selectedKey={filterPersonaTransporte.busId}
                                                onChange={(e, o) => onChageComboPersonaTransporte('bus', e, o)} />
                                        </div>

                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                            <ComboBox
                                                label='Destino'
                                                placeholder='Destino'
                                                autoComplete='off'
                                                useComboBoxAsMenuWidth
                                                calloutProps={{ directionalHintFixed: true }}
                                                options={campamento}
                                                selectedKey={filterPersonaTransporte.destinoId}
                                                onChange={(e, o) => onChageComboPersonaTransporte('destino', e, o)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TableBase leftBar={_leftBar()} rigthBar={_rightBar()} column={columnPersonaAsignado} data={personaAsignado.items} isLoading={hidden}></TableBase>

            {/* Report personas programadas en su totalidad - similar admin */}
            <RepoteBase
                headerName={``}
                NameReport={personaAsignado?.tipoProgramacion == '01' ? 'MOVAccesoPersonaAreaTransporteMMG.trdx' : (personaAsignado?.tipoProgramacion == '02' ? 'MOVAccesoPersonaAreaTransporteSSEE.trdx' : '')}
                Parameters={{ ProgramacionId: id }}
                openTelerik={isOpenReport}
                dismissTelerik={dismissReport}
            ></RepoteBase>
        </>
    )
}