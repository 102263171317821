import { Panel, PanelType, ProgressIndicator, Stack, ComboBox, DefaultButton, IComboBox, IComboBoxOption, PrimaryButton, SelectableOptionMenuItemType } from "@fluentui/react"
import React, { useState, useEffect } from "react"
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar"
import { ICheckItemComboBaseDTO, ISearch, IException, IItemComboBaseDTO } from "../../../../interfaces/base/IBase"
import { IAsignarMultipleOrganigrama, IUsuarioSupervisor } from "../../../../interfaces/ISupervisorOrganigrama"
import ConfiguracionService from "../../../../services/configuracionService"
import OrganigramaService from "../../../../services/organigramaService"
import { ItemSelectAll, KeyAllSelect, splitExceptionDetail, stackTokens, _initialFilter } from "../../../../utils/Helper/helper"
import ThemeBase from "../../../../utils/Theme/themeBase"
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import ProgramacionService from '../../../../services/ProgramacionService';
import { Ifilter } from "../../../../interfaces/IFilter"
import { IDataCollection } from "../../../../interfaces/base/ITable"
import { _initialDataCollection } from "../../../../utils/Helper/tableHelper"

const initialTipo = [
    { key: '1', text: 'KeyUser' },
    { key: '2', text: 'Gerente Solicitante' },
];

export const AsignarSupervisorOrganigrama = ({ isOpen = false, dissmiss = () => { }, reloadInitial = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Form */
    const [user, setUser] = useState<IItemComboBaseDTO[]>([]);
    const [gerencia, setGerencia] = useState<ICheckItemComboBaseDTO[]>([]);
    const [form, setForm] = useState<IAsignarMultipleOrganigrama>(null!);

    /* Filter */
    const [filterGerencia, setFilterGerencia] = useState<ISearch>(_initialFilter);
    const [searchSupervisor, setSearchSupervisor] = useState("");

    /* Event Multiselect */
    const [selectedKeyGerencia, setSelectedKeyGerencia] = React.useState<string[]>([]);

    const selectableOptionGerencia = gerencia.filter(
        option =>
            (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
    );

    const refComboSupervisor = React.useRef<IComboBox>(null);
    const _openComboSupervisor = React.useCallback(
        () => refComboSupervisor.current?.focus(true),
        []
    );

    useEffect(() => {
        if (!isOpen) {
            setForm(null!)
            setGerencia([]);
        }
    }, [isOpen])

    useEffect(() => {
        dataKeyUser()
    }, [searchSupervisor])

    /* Api */
    const dataKeyUser = () => {
        setUser([]);
        ConfiguracionService.allKeyUser(searchSupervisor).then(res => {
            if (res.status == 200) {
                setUser(res.data);
            }
            // setHidden(true);
        }).catch((e: IException) => {
            // setHidden(true);
            alert(splitExceptionDetail(e));
        })
    }

    const _cerrar = () => {
        setSearchSupervisor("")
        dissmiss()
    }


    const dataGerenteArea = () => {
        setUser([]);
        ConfiguracionService.allGerenteSolicitante().then(res => {
            if (res.status == 200) {
                setUser(res.data);
            }
            // setHidden(true);
        }).catch((e: IException) => {
            // setHidden(true);
            alert(splitExceptionDetail(e));
        })
    }

    const dataGerencia = (supervisorId: string, tipo: number) => {
        setGerencia([]);

        OrganigramaService.allOrganigramaAsignado(supervisorId, tipo)
            .then((res) => {
                if (res.status == 200) {
                    let t: ICheckItemComboBaseDTO[] = res.data;
                    t.unshift({
                        ...ItemSelectAll(),
                        isChecked: false,
                    });

                    setGerencia(t);
                    setSelectedKeyGerencia(t.filter(x => x.isChecked).map(x => x.key));
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const asignarGerencia = () => {
        if (form.organigramaLst?.length == 0) {
            return alert('Debe seleccionar al menos una gerencia');
        }

        setIsSend(true);

        let f: IAsignarMultipleOrganigrama = {
            ...form,
            organigramaLst: selectedKeyGerencia.filter(x => x != ItemSelectAll().key),
        }

        /* console.log("----- asignar gerencia -------------")
        console.log(f) */

        OrganigramaService.asignarMultipleGerencia(f).then(res => {
            if (res.status == 200) {
                setIsSend(false)
                refForm()
                setChoice('send');
                reloadInitial();
            }
        }).catch((e: IException) => {
            setIsSend(false);
            setChoice('error')
            settextError(splitExceptionDetail(e))
        })
        setSearchSupervisor("")
    } 

    /* Event */
    const onChangeGerencia = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeyGerencia.filter(key => key !== KeyAllSelect);
        const selectAllState = currentSelectedOptionKeys.length === selectableOptionGerencia.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setSelectedKeyGerencia([])
                    : setSelectedKeyGerencia(['selectAll', ...selectableOptionGerencia.map(o => o.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === selectableOptionGerencia.length) {
                    updatedKeys.push(KeyAllSelect);
                }
                setSelectedKeyGerencia(updatedKeys);
            }
        }
    }

    /* Event */
    const _onChange = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            console.log(option);
            if (option != undefined) {
                switch (type) {
                    case 'tipo':
                        setForm({
                            ...form,
                            supervisorId: '',
                        })

                        setGerencia([]);

                        switch (option.key as string) {
                            case '1':
                                dataKeyUser();
                                break;
                            case '2':
                                dataGerenteArea();
                                break;
                            default:
                                break;
                        }

                        setForm({
                            ...form,
                            tipo: parseInt(option.key as string),
                        })
                        break;
                    case 'supervisor':
                        setForm({
                            ...form,
                            supervisorId: String(option.key),
                        })

                        dataGerencia(option.key as string, form.tipo)
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Callback reload */
    const refForm = React.useCallback(() => {
        resetChoice();
    }, [dissmiss])

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={asignarGerencia} styles={ThemeBase.panelButtonStyle} disabled={isSend}>
                    Asignar
                </PrimaryButton>
                <DefaultButton onClick={_cerrar}>Cancelar</DefaultButton>
            </div>
        ),
        [isSend, isOpen, dissmiss, form, selectedKeyGerencia]
    );

    //------------------- tipo de programacion -----------------

    const [tipoProgramacion, setTipoProgramacion] = useState<IChoiceGroupOption[]>([]);

    useEffect(() => {
        dataTipoProgramacion();
    }, []);

    const dataTipoProgramacion = () => {
        ProgramacionService.allTipoProgramacion()
            .then((res: any) => {
                if (res.status == 200) {
                    setTipoProgramacion(res.data);
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const onChangeChoiceTipoProgramacion = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined): void => {

        if (ev != undefined) {
            if (option != undefined) {

                console.log("option.key: " + option.key.toString())
                console.log("option.text: " + option.text)

            }
        }
    }

    const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
        let { value } = ev?.target as HTMLInputElement;
    
        switch (type) {
          case "supervisor":
            _openComboSupervisor();
    
            /* if (filter.empresaId != null) {
              setFilter({
                ...filter,
                empresaId: null,
              });
            } */
    
            setSearchSupervisor(value);
            break;
          
          default:
            break;
        }
      };

        const _onBlurCombo = (type: string) => {
        switch (type) {
          case "supervisor":
            
            if (form.supervisorId == "" || form.supervisorId == null)
            {
                setSearchSupervisor("")
            }
            break;
            default:
            break;
        }
      };

    const options: IChoiceGroupOption[] = [
        { key: 'day', text: 'MMGG', /* iconProps: { iconName: 'CalendarDay' }  */ },
        { key: 'week', text: 'SSEE', /* iconProps: { iconName: 'CalendarWeek' }  */ },
        { key: 'month', text: 'Ambos', /* iconProps: { iconName: 'Calendar' }, disabled: true  */ },
    ];

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={_cerrar}
                type={PanelType.medium}
                headerText='Asignar Gerencia'
                closeButtonAriaLabel='Close'
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
{/*                 <pre>{JSON.stringify(searchSupervisor, null, 2)}</pre>
 */}                <div style={{
                    paddingTop: '5%',
                }}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description="Enviando espere un momento" barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    refForm()
                                    resetChoice()
                                }}></CustomMessageBar>}
                            </div>
                        </div>

                        <form ref={refForm} hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                            <Stack tokens={stackTokens}>
                                <div className="ms-Grid" dir="ltr">
                                    <Stack tokens={stackTokens}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                <ComboBox
                                                    label='Tipo'
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={initialTipo}
                                                    selectedKey={form ? form.tipo.toString() : ''}
                                                    onChange={(e, o) => _onChange('tipo', e, o)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComboBox
                                                    componentRef={refComboSupervisor}
                                                    label='Usuario Supervisor'
                                                    allowFreeform
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={user}
                                                    selectedKey={form ? form.supervisorId : ''}
                                                    onChange={(e, o) => _onChange('supervisor', e, o)}
                                                    onKeyUp={(e) => _onUpCombo("supervisor", e)}
                                                    onBlur={() => _onBlurCombo("supervisor")}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComboBox
                                                    label="Gerencia"
                                                    multiSelect
                                                    options={gerencia}
                                                    selectedKey={selectedKeyGerencia}
                                                    onChange={onChangeGerencia}
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ChoiceGroup
                                                    label="Tipo Programación"
                                                    // defaultSelectedKey="day"
                                                    options={tipoProgramacion}
                                                    onChange={onChangeChoiceTipoProgramacion}
                                                />
                                            </div>
                                        </div> */}
                                    </Stack>
                                </div>
                            </Stack>
                        </form>
                    </div>
                </div>
            </Panel>
        </>
    )
}