import { IAsignarRol, IEliminarRolUser, ISearch } from "../interfaces/base/IBase";
import { Ifilter } from "../interfaces/IFilter";
import { IAsignarKeyUser } from "../interfaces/IPersona";
import { IActualizarContrasenia, IActualizarContraseniaRol, IAddRolIdentity, IDisabledUser } from "../interfaces/IUsuario";
import { fetchAdministracion, fetchIdentidad, fetchMovilizacion } from "./http-common";
import TokenService from "./TokenService";

const urlPersona = 'Persona';
const urlArea = 'Area';
const urlEmpresa = 'Empresa';
const urlUsuario = 'Usuario';
const urlUsuarioCuenta = 'UsuarioCuenta';
const urlNivel = 'Nivel';
const urlLevel = 'Level';
const urlMarca = 'Marca';

const allKeyUser = (Search : string) => {
    return fetchAdministracion.get(`${urlPersona}/KeyUser?search=${Search}`);
}

const allGerenteSolicitante = () => {
    return fetchAdministracion.get(`${urlPersona}/GerenteSolicitante`);
}

const asignarKeyUser = (data: IAsignarKeyUser) => {
    return fetchAdministracion.post(`${urlPersona}/Asignar`, data);
}

/* Area */
const allArea = () => {
    return fetchAdministracion.get(`${urlArea}`);
}

/* Empresa */
const allEmpresa = (filter: ISearch) => {
    return fetchAdministracion.get(`${urlEmpresa}`, { params: filter });
}

/* Marca*/

const allMarca = () => {
    return fetchMovilizacion.get(`${urlMarca}`);
}


/* Rol */
const rolByUser = () => {
    return fetchAdministracion.get(`${urlUsuario}/RolByUsuario`);
}

const allRol = () => {
    return fetchIdentidad.get(`allRole`);
}

const eliminarRolUser = (data: IEliminarRolUser) => {
    return fetchIdentidad.post(`User/RemoveRole`, data);
}

/* Rol & Persona */
const rolByPersonaId = (id: string) => {
    return fetchAdministracion.get(`${urlPersona}/Rol/${id}`);
}

const asignarRolByPersona = (data: IAsignarRol) => {
    return fetchAdministracion.post(`${urlPersona}/Rol`, data, { headers: { "Authorization": `Bearer ${TokenService.getToken()}` } });
}

/* Cuenta & Usuario */
const cuentaByPersonaId = (personaId: string) => {
    return fetchAdministracion.get(`${urlUsuarioCuenta}/${personaId}`);
}

/* Generar Usuario */
const crearUsuarioPorPersona = (personaId: string) => {
    return fetchAdministracion.post(`${urlPersona}/Usuario/Generar/${personaId}`, {});
}

/* Listar Usuarios */
const allUsuario = (filter: Ifilter) => {
    return fetchIdentidad.get(`User`, { headers: { "Authorization": `Bearer ${TokenService.getToken()}` }, params: filter });
}

const actualizarContraseniaUsario = (data: IActualizarContraseniaRol) => {
    return fetchIdentidad.put(`User/CambiarContrasenia`, data);
}

const asignarRolByIdentity = (data: IAddRolIdentity) => {
    return fetchIdentidad.post(`User/AssignRole`, data, { headers: { "Authorization": `Bearer ${TokenService.getToken()}` } });
}

const actualizarRolUser = (data: IAddRolIdentity) => {
    return fetchIdentidad.put(`User/UpdateRole`, data);
}

const darBaja = (data: IDisabledUser) => {
    return fetchIdentidad.put(`User/Disabled`, data);
}

const habilitar = (data: IDisabledUser) => {
    return fetchIdentidad.put(`User/Enabled`, data);
}

/* Nivel & Level */
const allNivel = (filter: ISearch) => {
    return fetchAdministracion.get(`${urlNivel}`, { params: filter });
}

const allLevel = (filter: ISearch) => {
    return fetchAdministracion.get(`${urlLevel}`, { params: filter });
}


const ConfiguracionService = {
    allKeyUser,
    allGerenteSolicitante,
    asignarKeyUser,

    /* Area */
    allArea,

    /* Empresa */
    allEmpresa,

    /* Marca */
    allMarca,

    /* Usuario & Rol */
    rolByUser,
    allRol,
    asignarRolByPersona,
    eliminarRolUser,

    /* Rol por Persona */
    rolByPersonaId,

    /* Cuentas por Usuario */
    cuentaByPersonaId,

    /* Crear Usuario */
    crearUsuarioPorPersona,

    /* Listar Usuario */
    allUsuario,
    actualizarContraseniaUsario,

    /* Rol & Identity */
    asignarRolByIdentity,
    actualizarRolUser,
    darBaja,
    habilitar,

    /* Nivel & Level */
    allNivel,
    allLevel,
}

export default ConfiguracionService;