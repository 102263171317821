/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { ComboBox, IComboBox, IComboBoxOption, IPanelProps, Panel, PrimaryButton, ProgressIndicator, Stack, TextField } from "@fluentui/react";
import ThemeBase from "../../../../utils/Theme/themeBase";
import { splitExceptionDetail, stackTokens } from "../../../../utils/Helper/helper";
import ProgramacionService from "../../../../services/ProgramacionService";
import { IException, IItemComboBaseDTO } from "../../../../interfaces/base/IBase";
import UbicacionService from "../../../../services/ubicacionesServices";
import { UbicacionHelper } from "../../../../utils/Helper/ubicacionHelper";
import { useParams } from "react-router-dom";
import ProgramacionBusService from "../../../../services/programacionBusService";
import { MessageHelper } from "../../../../utils/Helper/messageHelper";
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar";

interface ActulizarComunicadoProps extends IPanelProps {
    reloadParent: () => void
    programacionesPersonaIds?: string[]
    onDismiss: () => void
}

export interface IBodyPUT {
    programacionId: String | null
    lugarMovilizacionId: String | null
    ubicacionOrigenId: String | null
    comunicado: String | null
    programacionPersonaIds: String[] | undefined
}

const INITIAL_BODY: IBodyPUT = {
    programacionId: null,
    lugarMovilizacionId: null,
    ubicacionOrigenId: null,
    comunicado: null,
    programacionPersonaIds: []
}
//{ isOpen = false, onDismiss = () => { }, reloadParent = () => { } }
const ActulizarComunicado: FunctionComponent<ActulizarComunicadoProps> = (prop) => {
    const { id } = useParams()
    const [lugarMovilizaciones, setLugarMovilizaciones] = useState<IItemComboBaseDTO[]>([]);
    const [origen, setOrigen] = useState<IItemComboBaseDTO[]>([]);
    const [bodyPUT, setbodyPUT] = useState<IBodyPUT>(INITIAL_BODY);
    const [isSend, setIsSend] = useState(false);

    const [choice, setChoice] = useState<string | undefined>(undefined);
    const [textError, settextError] = useState('Error');
    const resetChoice = useCallback(() => setChoice(undefined), []);

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion().then(res => {
            setLugarMovilizaciones(res.data);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        });;
    }

    const dataOrigen = () => {
        UbicacionService.ubicacionPorFiltro({ tipo: UbicacionHelper.TIPO_ORIGEN })
            .then((res) => {
                setOrigen(res.data);
            })
            .catch((e: IException) => {
                console.log(splitExceptionDetail(e));
            });
    }


    useEffect(() => {
        setbodyPUT({ ...INITIAL_BODY, programacionId: id!, programacionPersonaIds: prop.programacionesPersonaIds })
        if (prop.isOpen) {
            dataLugarMovilizacion()
            dataOrigen()
        }
    }, [prop.isOpen]);

    const _onchangeComunicado = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        if (event !== undefined) {
            if (newValue !== undefined)
                setbodyPUT({ ...bodyPUT, comunicado: newValue })
        }
    }
    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event !== undefined) {
            if (option !== undefined) {
                switch (type) {
                    case "LugarMovilizacion":
                        setbodyPUT({ ...bodyPUT, lugarMovilizacionId: option.key.toLocaleString() })
                        break;
                    case "Origen":
                        setbodyPUT({ ...bodyPUT, ubicacionOrigenId: option.key.toLocaleString() })
                        break;
                    default:
                        break;
                }
            }
        };
    }

    const _onClickActulizar = () => {
        console.log(bodyPUT);
        setIsSend(true);
        setChoice(undefined);
        ProgramacionBusService.comunicado(bodyPUT).then((res) => {
            setIsSend(false);
            if (res.status === 200) {
                prop.onDismiss();
                prop.reloadParent();
            }
        }).catch((e: IException) => {
            settextError(splitExceptionDetail(e))
            setChoice('error');
            setIsSend(false);
        }).finally(() => {

        })
    }

    const onRenderFooter = useCallback(
        () => (
            <div style={{ padding: 20 }}>
                <Stack tokens={stackTokens}>
                    <PrimaryButton onClick={_onClickActulizar} styles={ThemeBase.panelButtonStyle}>
                        Actualizar
                    </PrimaryButton>
                </Stack>
            </div>
        ),
        [bodyPUT, prop.isOpen, prop.onDismiss]
    );
    const onRenderHeader = useCallback(
        () => (
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <Stack horizontalAlign="start" tokens={stackTokens}>
                        <h5>Comunicado de movilización</h5>
                    </Stack>
                </div>
            </div>
        ),
        [prop.isOpen, prop.onDismiss]
    );

    return (
        <>
            <Panel
                isOpen={prop.isOpen}
                onDismiss={prop.onDismiss}
                closeButtonAriaLabel="Close"
                onRenderFooter={onRenderFooter}
                // onRenderHeader={onRenderHeader}
                isFooterAtBottom={true}
            >
                <Stack horizontalAlign="center">
                    <h5>Comunicado de movilización</h5>
                </Stack>
                <br />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                            <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                            {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                            {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                prop.reloadParent();
                            }}></CustomMessageBar>}
                        </div>
                    </div>
                </div>

                <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                    <TextField
                        label="Ingrese comunicado"
                        multiline rows={20}
                        onChange={_onchangeComunicado}
                    />
                    <br />
                    {/* <h5>Aplicar para:</h5>
                    <ComboBox
                        // componentRef={comboBoxRef}
                        label="Lugar Movilizacion"
                        options={lugarMovilizaciones}
                        useComboBoxAsMenuWidth
                        onChange={(e, o) => _onChangeCombo('LugarMovilizacion', e, o)}
                    />
                    <ComboBox
                        // componentRef={comboBoxRef}
                        label="Origen (Embarque)"
                        options={origen}
                        useComboBoxAsMenuWidth
                        onChange={(e, o) => _onChangeCombo('Origen', e, o)}
                    /> */}
                </form>
            </Panel>
        </>
    );
}

export default ActulizarComunicado;