import { IActivityItemProps, IColumn, ICommandBarItemProps, Icon, Link, Persona, PersonaSize, Stack, TooltipHost } from "@fluentui/react";
import { Selection } from "@fluentui/react/lib/DetailsList";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { TableBase } from "../../../components/Table/TableBase";
import { IException } from "../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { IFilterProgramacion, IProgramacionPersona } from "../../../interfaces/IProgramacion";
import ProgramacionPersonaService from "../../../services/programacionPersonaService";
import { baseImageUrl, splitExceptionDetail, stackTokensHorizontal, _initialFilter, _initialPagination } from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { LogActividad } from "../logActividad/logActividad";
import { useBoolean } from "@fluentui/react-hooks";
import { RetiroAdicional } from "./retiroAdicional";
import { ProgramacionAdicionalProps } from "../../../types/ITypes";

export const AccesoPersonaAdicional = React.forwardRef((props: ProgramacionAdicionalProps, forwardedRef) => {
  const [hidden, setHidden] = useState(false);

  const [filter, setFilter] = useState<IFilterProgramacion>({
    ..._initialFilter(),
    search: props.search,
    programacionId: props.programacionId,
  });
  const [column, setColumn] = useState<IColumn[]>([]);
  const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);

  /* Panel Log */
  const [isOpenLog, { setTrue: openLog, setFalse: dismissLog },] = useBoolean(false);
  const [selId, setSelId] = useState<string | undefined>(undefined);

  /* Panel retiro masivo */
  const [isOpenRetiro, { setTrue: openRetiro, setFalse: dismissRetiro },] = useBoolean(false);

  /* Selection */
  const [sel, setSel] = useState<any[]>([]);

  const selection = new Selection({
    onSelectionChanged: () => {
      let d: any[] = selection.getSelection();
      let t: IActivityItemProps[] = d
        .filter((x) =>x.motivoRetiro === "" ) //x.isRetired
        .map((x) => {
          return {
            key: `1_${x.programacionPersonaId}`,
            activityDescription: [
              <Link
                key={`d_${x.programacionPersonaId}`}
                className={ThemeBase.activityClass.nameText}
              >
                {x.nombres}
              </Link>,
              <span key={`2_${x.programacionPersonaId}`}></span>,
              <span
                key={`3_${x.programacionPersonaId}`}
                className={ThemeBase.activityClass.nameText}
              ></span>,
            ],
            activityPersonas: [{ imageUrl: baseImageUrl }],
            timeStamp: `DNI: ${x.nroDOI}`,
            programacionPersonaId: `${x.programacionPersonaId}`,
          };
        });
      setSel(t);
    },
  });
  // const _selection = new Selection({ onSelectionChanged: () => { setItemSeleccionado(_selection.getSelection()[0]) } })

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  /* Hook */
  useEffect(() => {
    reloadInitial();
  }, [])

  useEffect(() => {
    setSel([]);
  }, [data]);

  /* Imperative Hook */
  useImperativeHandle(forwardedRef, () => {
    return {
      filterFromParent: (search = '') => {

        let p = _initialPagination().currentPage;

        let f: IFilterProgramacion = {
          ..._initialFilter(),
          programacionId: props.programacionId,
          skip: (p - 1) * pageSize,
          take: pageSize,
          search: search.trim(),
        }

        setFilter(f);
        setCurrentPage(p);
        reloadInitial(f);
      }
    }
  });

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  /* Api */
  const dataAdicional = (param = filter) => {
    ProgramacionPersonaService.allTodoAdicional(param)
      .then((res) => {
        setData(res.data);
        setTotal(res.data.total);
        setHidden(true);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        setHidden(true);
      });
  };

  /* Function */
  const reloadInitial = (param = filter) => {
    setHidden(false);
    setColumn(setTitleColumnAdicional());
    dataAdicional(param);
  }

  const setTitleColumnAdicional = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Foto",
        ariaLabel:
          "Column operations for File type, Press to sort on File type",
        isIconOnly: true,
        fieldName: "foto",
        minWidth: 45,
        maxWidth: 45,
        onRender: (item: IProgramacionPersona) => (
          <TooltipHost content={`Foto`}>
            <Persona
              text=""
              size={PersonaSize.size24}
              imageUrl={baseImageUrl}
            />
          </TooltipHost>
        ),
      },
      {
        key: "column2",
        name: "Nombre",
        fieldName: "nombres",
        minWidth: 160,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{
                fontSize: "90%",
                textDecorationLine:
                  item.motivoRetiro == "" ? "none" : "line-through",
              }}
            >
              {item.nombres}
            </span>
          );
        },
      },
      {
        key: "column3",
        name: "Identificación",
        fieldName: "nroDOI",
        minWidth: 80,
        maxWidth: 90,
        isResizable: true,
        data: "number",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%" }}
            >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
          );
        },
      },
      {
        key: "column40",
        name: "Gerencia",
        fieldName: "gerencia",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column6",
        name: "Origen",
        fieldName: "origen",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: 'column7',
        name: 'Destino',
        fieldName: 'destino',
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: "column10",
        name: "Empresa Transportista",
        fieldName: "transporte",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column11",
        name: "Turno",
        fieldName: "turno",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column14.1",
        name: "Adicional",
        fieldName: "esAdicional",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column14.2",
        name: "Comunidad",
        fieldName: "esComunidad",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%", ...ThemeBase.justifiedStyle }}>{item.esComunidad ? 'Si' : 'No'}</span>
          );
        },
      },
      {
        key: "column14.3",
        name: "Visita",
        fieldName: "esVisita",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%", ...ThemeBase.justifiedStyle }}>{item.esVisita ? 'Si' : 'No'}</span>
          );
        },
      },
      {
        key: "column15",
        name: "Clasificación",
        fieldName: "clasificacion",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column16",
        name: "Nivel",
        fieldName: "nivelActual",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column18",
        name: "Lugar Movilizacion",
        fieldName: "lugarMovilizacion",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column19",
        name: "Centro Costo",
        fieldName: "centroCosto",
        minWidth: 150,
        maxWidth: 160,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column12",
        name: "Registrado por",
        fieldName: "nombreUsuarioRegistra",
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column20",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IProgramacionPersona) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              <TooltipHost content={`Actividades`}>
                <Icon
                  iconName={IconHelper.ICON_INFO.iconProps?.iconName}
                  style={{ cursor: "pointer" }}
                  className={`${ThemeBase.classes.iconFont}`}
                  onClick={() => {
                    setSelId(item.programacionPersonaId);
                    openLog();
                  }}
                />
              </TooltipHost>
            </Stack>
          </>
        ),
      },
    ];

    return col;
  };

  const _leftBarAdicional = () => {
    const _items: ICommandBarItemProps[] = [];

    if (props.isAddAdicional) {
      _items.push(
        {
          ...IconHelper.ICON_ADD,
          onClick: () => props.openForm(),
        },
      )
    }
    _items.push(
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => reloadInitial(),
      },
      {
        ...IconHelper.ICON_CANCEL,
        text: "Retirar",
        onClick: () => {
          if (sel.length == 0) {
            return alert("Debe seleccionar al menos un item");
          }

          openRetiro();
        },
      },
    )

    return _items;
  };

  const _rightBarAdicional = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_EXCEL,
        onClick: () => props.openReporte(),
      },
    ];

    return _items;
  };

  /* Event Tab */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: IFilterProgramacion = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      }

      setFilter(f);
      setCurrentPage(page);

      reloadInitial(f);
    }
  }

  return (
    <>
      <TableBase leftBar={_leftBarAdicional()} rigthBar={_rightBarAdicional()} column={column} data={data.items} isLoading={!hidden} selection={selection} style={ThemeBase.notPaddingTableBase} pagination={pager}
      ></TableBase>

      {/* Panel Retirar Adicional */}
      <RetiroAdicional programacionId={props.programacionId} isOpen={isOpenRetiro} dismiss={dismissRetiro} sel={sel} reloadParent={reloadInitial}></RetiroAdicional>

      {/* Panel Log */}
      <LogActividad programacionPersonaId={selId} isOpen={isOpenLog} dismiss={dismissLog}></LogActividad>
    </>
  )
})