import { ComboBox, DefaultButton, getTheme, IComboBox, IComboBoxOption, ICommandBarItemProps, Label, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import '../Hoteles/hotelcss.css';
import React, { useEffect, useState } from "react";
import PersonaService from "../../services/PersonaService";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import TitleSeparator from "../../components/Generic/TitleSeparator";
import { splitExceptionDetail, stackTokens, warningButtonTheme } from "../../utils/Helper/helper";
import { IBase, IException, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import TransporteService from "../../services/transporteService";
import { ICreateTransporte, IItemCatalogoAdjunto } from "../../interfaces/ITransporte";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { ConfigHelper } from "../../utils/Helper/configHelper";

const theme = getTheme();

export const AgregarVehiculo = () => {
    let navigate = useNavigate();
    const [model, setModel] = useState<ICreateTransporte>(null!);
    const [marca, setMarca] = useState<IItemComboBaseDTO[]>([]);
    const [modelo, setModelo] = useState<IItemComboBaseDTO[]>([]);
    const [tipo, setTipo] = useState<IItemComboBaseDTO[]>([]);
    const [categoria, setCategoria] = useState<IItemComboBaseDTO[]>([]);
    /* const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]); */
    const [catalogo, setCatalogo] = useState<IItemCatalogoAdjunto[]>([]);

    const [isComplete, setIsComplete] = useState(false);

    /* Effect */
    useEffect(() => {
        dataMarca();
        dataTipo();
        dataCategoria();
        /* dataEmpresa(); */
        /* dataCatalogoAdjunto(); */
    }, []);

    /* Validar campos requeridos */
    useEffect(() => {
        setIsComplete(_validateForm);
    }, [model]);

    useEffect(() => {
        setIsComplete(_validateForm);
    }, [catalogo]);

    /* Api */
    const dataMarca = () => {
        TransporteService.allMarca().then(res => {
            setMarca(res.data);
        }).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataModeloByMarcaId = (marcaId: string) => {
        TransporteService.allModeloByMarcaId(marcaId).then(res => {
            setModelo(res.data);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataTipo = () => {
        TransporteService.allTipo().then(res => {
            setTipo(res.data);
        }).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataCategoria = () => {
        TransporteService.allCategoria().then(res => {
            setCategoria(res.data);
        }).catch((e: Error) => {
            alert(e.message);
        })
    }

    /* const dataEmpresa = () => {
        PersonaService.transporteHabilitado().then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                });
            });

            setEmpresa(t);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    } */

    const dataCatalogoAdjunto = () => {
        TransporteService.catalogoAdjunto().then((res) => {
            if (res.status == 200) {
                setCatalogo(res.data);
            }
        }).catch((e) => {
            alert(e.message);
        })
    }

    const addTransporte = (state: boolean) => {
        if (!model) {
            return alert('Datos incompletos');
        }

        if (state) {
            if (!model.placa || model.placa == '') {
                return alert('Debe de seleccionar una placa');
            }

            if (!model.modeloId || model.modeloId == '') {
                return alert('Debe de seleccionar un modelo');
            }

            if (!model.tipoTransporteId || model.tipoTransporteId == '') {
                return alert('Debe de seleccionar un tipo transporte');
            }

            if (!model.categoriaId || model.categoriaId == '') {
                return alert('Debe de seleccionar una categoría');
            }

            /* if (!model.empresaId || model.empresaId == '') {
                return alert('Debe de seleccionar una empresa');
            } */
        }

        model.isBorrador = state;
        model.adjunto = catalogo;
        // state && _sendEmail() //Quitar y solo dejar el de editar
        PersonaService.postTransporte(model).then((res) => {
            if (res.status == 200) {
                alert('Se registró correctamente');
                goBack();
            }
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        })
        
    }
    /*Email pendientes aprobacion*/
    const _sendEmail=() => {
        if(model.empresaId != '')
         TransporteService.emailPendienteAprobacion("5ad0793d-f40f-4340-bceb-39da2a4cfbb0").then((res) =>{
             if(res.status==200){
                console.log("email enviado")
             }
         })
    }

    /* Function */
    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    /* Event */
    const _onChangeMarca = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                dataModeloByMarcaId(option.key.toString());
            }
        }
    }

    const _onChangeModelo = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    modeloId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeTipo = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    tipoTransporteId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeCategoria = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    categoriaId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeEmpresa = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    empresaId: option.key.toString(),
                })
            }
        }
    }

    const _onChangePlaca = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                placa: newValue,
            });
        }
    }

    const _onChangeCodigoInterno = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                codigoInterno: newValue,
            });
        }
    }

    const _getErrorMessagePeriodo = (value: string): string => {
        let msg = '';

        if (value == '') {
            /* msg = `El valor no puede ser vacío`; */
        } else if (isNaN(value as any)) {
            msg = `Debe de seleccionar un año válido`;
        } else if (value.length != 4) {
            msg = `Debe de seleccionar un año válido`;
        } else {
            msg = ``;
        }

        return msg;
    };

    const _onChangeAnio = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let val = event?.target as HTMLInputElement;

        let an: number | null = null;

        if (val) {
            if (parseInt(val.value)) {
                an = parseInt(val.value);
            }
        }

        setModel({
            ...model,
            anioFabricacion: an,
        });
    }

    const _onChangePasajero = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                pasajeros: parseInt(newValue),
            });
        }
    }

    const _onChangeColor = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                color: newValue,
            });
        }
    }

    const _onChangeTarjeta = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                tarjetaPropiedad: newValue,
            });
        }
    }

    const _uploadFile = (ev: React.ChangeEvent, id: string) => {
        let { files } = ev.target as HTMLInputElement;

        if (files && files[0]) {
            let f = files[0];
            getBase64(f).then(
                data => {
                    let ot = catalogo.map(x => {
                        if (x.id == id) {
                            return ({
                                ...x,
                                archivo: f.name,
                                file: data as string,
                                extension: `.${f.name.split('.').pop()}` as string,
                            });
                        } else {
                            return x;
                        }
                    })

                    setCatalogo(ot)
                }
            );
        } else {
            let ot = catalogo.map(x => {
                if (x.id == id) {
                    return ({
                        ...x,
                        archivo: '',
                        file: '',
                        extension: '',
                    });
                } else {
                    return x;
                }
            })

            setCatalogo(ot)
        }
    }

    const _validateForm = () => {
        if (!model) {
            return false;
        }
        
        let st = true;

        if (catalogo.length > 0) {
            let reqLst = catalogo.filter(x => x.required);
            if (reqLst.length > 0 && reqLst.filter(x => (x.file == '' || !x.file)).length > 0) {
                st = false;
            }
        }

        if (model) {
            if (!model.modeloId || model.modeloId == '') {
                st = false;
            }

            if (!model.tipoTransporteId || model.tipoTransporteId == '') {
                st = false;
            }

            if (!model.categoriaId || model.categoriaId == '') {
                st = false;
            }

            /* if (!model.empresaId || model.empresaId == '') {
                st = false;
            } */

            if (!model.placa || model.placa == '') {
                st = false;
            }

            if (!model.pasajeros || model.pasajeros <= 0) {
                st = false;
            }
        }

        return st;
    }

    const goBack = () => {
        navigate(`${ConfigHelper.urlBase()}/vehiculos`)
    }

    const _generateItemBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                key: 'Back',
                text: 'Atrás',
                iconProps: { iconName: 'Back' },
                onClick: goBack,
            },
        ];

        return _items;
    }

    return (
        <>
            <HeadingBar searchShow={false} itemBar={_generateItemBar()}></HeadingBar>

            {/* <pre>{JSON.stringify(model, null, 2)}</pre>
            <pre>{JSON.stringify(catalogo, null, 2)}</pre>
            <pre>{JSON.stringify(String(isComplete), null, 2)}</pre> */}

            <form>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg2"></div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8" style={{
                            padding: '5%',
                        }}>
                            <div className="ms-depth-8" style={{
                                padding: '15px',
                            }}>
                                <TitleSeparator title='Registrar Vehiculo' icon='BusSolid'></TitleSeparator>

                                <Stack tokens={stackTokens}>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="ms-fontSize-16" style={{ color: '#4676DD' }}>Datos del vehículo</div>
                                    </div>

                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label="Marca"
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={marca}
                                                    onChange={_onChangeMarca}
                               
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label="Modelo"
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={modelo}
                                                    onChange={_onChangeModelo}
                                                    selectedKey=""
                                                    required
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label="Tipo Vehículo"
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={tipo}
                                                    onChange={_onChangeTipo}
                                                    required
                                                />
                                            </div>

                                            <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                                <ComboBox
                                                    label="Categoría Vehículo"
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={categoria}
                                                    onChange={_onChangeCategoria}
                                                    required
                                                />
                                            </div>

                                            {/* <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label="Empresa"
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={empresa}
                                                    onChange={_onChangeEmpresa}
                                                    required
                                                />
                                            </div> */}

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <TextField
                                                    label='Placa'
                                                    placeholder='Placa'
                                                    onChange={_onChangePlaca}
                                                    autoComplete='off' 
                                                    required/>
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <TextField
                                                    label="Año Fabricación"
                                                    onGetErrorMessage={_getErrorMessagePeriodo}
                                                    onChange={_onChangeAnio}
                                                    autoComplete='off'
                                                    value={model ? model.anioFabricacion?.toString() : ''} />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <TextField
                                                    label='Pasajeros'
                                                    placeholder='Pasajeros'
                                                    onChange={_onChangePasajero}
                                                    autoComplete='off'
                                                    required />
                                            </div>

                                            {/* <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <TextField
                                                    label='Color'
                                                    placeholder='Color'
                                                    onChange={_onChangeColor}
                                                    autoComplete='off' />
                                            </div> */}

                                            {/* <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <TextField
                                                    label='Tarjeta Propiedad'
                                                    placeholder='Tarjeta'
                                                    onChange={_onChangeTarjeta}
                                                    autoComplete='off' />
                                            </div> */}

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <TextField
                                                    label='Codigo Interno'
                                                    placeholder='Codigo'
                                                    onChange={_onChangeCodigoInterno}
                                                    autoComplete='off' />
                                            </div>
                                            
                                        </div>
                                        <br />
                                        {/* <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className="ms-fontSize-16" style={{ color: '#4676DD' }}>Documentación</div>
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <Label></Label>
                                                <Stack tokens={stackTokens}>
                                                    {catalogo?.map(function (i: IItemCatalogoAdjunto) {
                                                        return <React.Fragment key={`c_${i.id}`}>
                                                            <div key={`d_${i.id}`} className="ms-fontSize-8" style={{ color: theme.palette.neutralSecondary }}>
                                                                <Stack tokens={stackTokens}>
                                                                    <li key={`l_${i.id}`}>{i.required ? `${i.nombre} *` : i.nombre}</li>
                                                                    <input type='file' accept='.pdf' required={i.required} onChange={(e) => { _uploadFile(e, i.id) }} />
                                                                </Stack>
                                                            </div>
                                                        </React.Fragment>
                                                    })}
                                                </Stack>
                                            </div>
                                        </div> */}
                                    </div>

                                    {/* Third option */}
                                    <br />
                                    <ButtonForm back={goBack} register={() => addTransporte(false)} disabledRegister={!isComplete}></ButtonForm>
                                    {/* <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className="ms-Grid" dir="ltr">
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                            <DefaultButton text='Borrador' onClick={() => addTransporte(true)} allowDisabledFocus theme={warningButtonTheme} />
                                                        </div>

                                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}