import { FontWeights, getTheme, IButtonStyles, IIconProps, mergeStyleSets } from "@fluentui/react";

const theme = getTheme();

/* Modal Style */
const modalStyle = mergeStyleSets({
  container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
  },
  header: [
      theme.fonts.medium,
      {
          flex: '1 1 auto',
          borderTop: `4px solid ${theme.palette.themePrimary}`,
          color: theme.palette.neutralPrimary,
          display: 'flex',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '12px 12px 14px 24px',
      },
  ],
  body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
          p: { margin: '14px 0' },
          'p:first-child': { marginTop: 0 },
          'p:last-child': { marginBottom: 0 },
      },
  },
});

const modalIconButtonStyles: Partial<IButtonStyles> = {
  root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
  },
  rootHovered: {
      color: theme.palette.neutralDark,
  },
};

const modalCancelIcon: IIconProps = { iconName: 'Cancel' };

export const ModalHelper = {
  /* Modal */
  modalStyle,
  modalIconButtonStyles,
  modalCancelIcon,
};