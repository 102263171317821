import {
    Checkbox, DefaultButton,
    getTheme, IconButton, Label,
    mergeStyleSets,
    Modal, PrimaryButton, TextField
} from "@fluentui/react";
import { GenButtonAdd } from "../../components/Generic/botonesCrear";
import { useBoolean } from '@fluentui/react-hooks';
import { useEffect, useState } from "react";
import PersonaService from "../../services/PersonaService";
import { useParams } from 'react-router';
import { baseImageUrl } from "../../utils/Helper/helper";
const theme = getTheme();

const ESTILOS = {
    boxShadow: {
        minWidth: '900px',
        maxWidth: '900px',
        boxShadow: theme.effects.elevation8,
        margin: '20px',
        padding: '20px',
        borderRadius: '5px',
        // backgroundColor: theme.palette.blue,
    },
    boxShadowHead: {
        minWidth: '900px',
        maxWidth: '900px',
        boxShadow: theme.effects.elevation8,
        margin: '20px',
        padding: '20px',
        borderRadius: '5px',
        backgroundColor: theme.palette.blue,
        color: theme.palette.white,
    },
    fotoPer: {
        minWidth: '180px',
        maxWidth: '200px',
        height: '250px',
        boxShadow: theme.effects.elevation8,
        // backgroundColor: theme.palette.blue,
        display: 'Flex',
        justifyContent: 'center'
    },
}
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        //   fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

interface INF_CONFIRMACION {
    nombres?: string,
    identificacion?: string,
    empresa?: string,
    puesto?: string,
    contacto?: string,
    celularContacto?: string,
    tituloProgramacion?: string,
    campamento?: string,
    paradero?: string,
    transporte?: string,
    hotel?: string,
}
interface MODEL_CONFIRM {
    programadoPersonaId?: string
}

function fechaActual(): string {
    let hoy = new Date()
    return hoy.toLocaleDateString()// hoy.getDay() + '/' + (hoy.getMonth() + 1)+ '/' + hoy.getFullYear()
}

export const ProgamConfirmacion = (props: any) => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(true);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);

    const [DtConfrimacion, setDtConfrimacion] = useState<INF_CONFIRMACION>({});
    const [modelConfirm, setmodelConfirm] = useState<MODEL_CONFIRM>({});

    let { id } = useParams();

    let fecha = fechaActual()
    useEffect(() => {
        console.log(id)
        PersonaService.getAccesProgConfirm(`${id}`).then(res => {
            //eb7b3583-3028-4a84-e5a7-08d9df84d6d2
            console.log(res.data.tituloProgramacion)
            setDtConfrimacion(res.data)
            setmodelConfirm({ ...modelConfirm, programadoPersonaId: id })
        })
    }, []);

    function _loadConfirmacion() {
        PersonaService.putConfirmacionProg(modelConfirm).then(res => {
            console.log('update')
            console.log(res.data)
            alert("Confirmado con éxito");
        }).catch((e) => { console.log(e) })
    }

    return (
        <>
            <div style={{ backgroundColor: 'white', width: '100%' }}>
                <Modal
                    // titleAriaId={titleId}
                    isOpen={isModalOpen}
                    onDismiss={hideModal}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                    styles={{ root: { maxWidth: '100', backgroundColor: 'white' } }}
                // dragOptions={isDraggable ? dragOptions : undefined}
                >
                    <div>
                        <div>
                            <div style={ESTILOS.boxShadowHead} >
                                <h3>Confirmación de programacion</h3>
                            </div >
                            <div style={ESTILOS.boxShadow}>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{}}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>

                                                <Label>Foto</Label>
                                            </div>
                                            <img style={ESTILOS.fotoPer} src={baseImageUrl} alt="" />
                                        </div>

                                        <div style={{ margin: '0 0 0 20px', padding: "0 0 0 20px", borderLeft: 'solid 1px gray' }}>
                                            <h4>{DtConfrimacion.tituloProgramacion}</h4>
                                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '50px' }}>
                                                <div>
                                                    <Label>Apellidos y Nombres</Label>
                                                    <Label>Identificacion</Label>
                                                    <Label>Unidad</Label>
                                                    <Label>Empresa</Label>
                                                    <Label>Puesto</Label>
                                                    <Label>Contacto</Label>
                                                    <Label>Cel. Contacto</Label>
                                                    <Label>Campamento</Label>
                                                    <Label>Paradero</Label>
                                                    <Label>Transporte</Label>
                                                    <Label>Hotel</Label>
                                                </div>
                                                <div>
                                                    <Label>{DtConfrimacion.nombres}</Label>
                                                    <Label>{DtConfrimacion.identificacion}</Label>
                                                    <Label>-</Label>
                                                    <Label>{DtConfrimacion.empresa}</Label>
                                                    <Label>{DtConfrimacion.puesto}</Label>
                                                    <Label>{DtConfrimacion.contacto ?? '-'}</Label>
                                                    <Label>{DtConfrimacion.celularContacto}</Label>
                                                    <Label>{DtConfrimacion.campamento}</Label>
                                                    <Label>{DtConfrimacion.paradero ?? '-'}</Label>
                                                    <Label>{DtConfrimacion.transporte ?? '-'}</Label>
                                                    <Label>{DtConfrimacion.hotel ?? '-'}</Label>
                                                </div>
                                            </div>
                                            <div style={{ margin: '20px 0 20px 0' }}>
                                                {/* <Label style={{ fontSize: '9pt', color: theme.palette.blueLight }}>
                                                    Agregre un comentario
                                                </Label>
                                                <TextField
                                                    name="titulo"
                                                    placeholder='Comentario'
                                                    // onChange={_onChangeTextFild}
                                                    underlined
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ margin: '20px 0 20px 0' }}>
                                        {/* <Checkbox label="Acepto los terminos y condiciones asi mismo confirmo mi subida" onChange={_onChange} /> */}
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {/* <DefaultButton
                                            text="Cancelar"
                                            style={{ borderColor: 'red', color: 'red' }}
                                            onClick={hideModal}
                                            href='/'
                                        /> */}
                                        {/* <PrimaryButton
                                            type="submit"
                                            text='Confirmar'
                                            style={{ marginLeft: '20px' }}
                                            onClick={_loadConfirmacion}
                                            onClickCapture={hideModal}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

        </>
    );
}

function _onChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
    console.log(`The option has been changed to ${isChecked}.`);
}
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};