import {
  Checkbox,
  ComboBox,
  DatePicker,
  DefaultButton,
  DetailsListLayoutMode,
  DialogType,
  IColumn,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  Icon,
  Panel,
  PanelType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { TableBase } from "../../components/Table/TableBase";
import { IDataCollection, IPagination } from "../../interfaces/base/ITable";
import { Ifilter } from "../../interfaces/IFilter";
import {
  ICreateTemporada,
  IDetalleNivelLevel,
  IItemDetalleLevel,
  ITemporada,
} from "../../interfaces/ITemporada";
import {
  splitExceptionDetail,
  stackTokens,
  stackTokensHorizontal,
  _initialFilter,
  _initialPagination,
} from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { useBoolean } from "@fluentui/react-hooks";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import TemporadaService from "../../services/temporadaService";
import ThemeBase from "../../utils/Theme/themeBase";
import React from "react";
import {
  _esBisiesto,
  _formatDate,
  _formatDatePrefix,
} from "../../utils/Helper/datetimeHelper";
import { AccesoGeneric } from "../accesoPersona/generic/accesoGeneric";
import { DialogBase } from "../../components/Dialog/DialogBase";
import { ActualizarTemporada } from "./actualizarTemporada";
import {
  CustomMessageBar,
  typeMessage,
} from "../../components/MessageBar/customMessageBar";
import { IComboOptions } from "../../components/Generic/combo";
import { Input } from "@fluentui/react-components";

export const ListarTemporada = () => {
  const [hidden, setHidden] = useState(false);
  const [isOpenForm, { setTrue: openForm, setFalse: dismissForm }] =
    useBoolean(false);
  const [isAdd, setIsAdd] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState<ICreateTemporada>(null!);
  const [inicio, setInicio] = useState<Date | undefined>(undefined);
  const [fin, setFin] = useState<Date | undefined>(undefined);

  const [column, setColumn] = useState<IColumn[]>([]);
  const [data, setData] = useState<IDataCollection<ITemporada>>(
    _initialDataCollection
  );
  const [filter, setFilter] = useState<Ifilter>(_initialFilter);

  /* Detalle */
  const [detalle, setDetalle] = useState<IDetalleNivelLevel[]>([]);
  const [selId, setSelId] = useState<string | undefined>(undefined);
  const [disableForm, setDisableForm] = useState(true);
  /* Dialog */
  const [hideEliminar, { toggle: toggleEliminar }] = useBoolean(true);
  const [hideActualizar, { toggle: toggleActualizar }] = useBoolean(true);

  /* Loading */
  const [
    isLoadingELiminar,
    { setTrue: loadingEliminar, setFalse: dismissLoadingEliminar },
  ] = useBoolean(false);

  /* Message Error & Choice */
  const [isSend, setIsSend] = useState(false);
  const [choice, setChoice] = React.useState<string | undefined>(undefined);
  const resetChoice = React.useCallback(() => setChoice(undefined), []);
  const [textError, settextError] = useState("Error");

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);
  const [meses, setMeses] = useState<IComboOptions[]>([]);
  const [search, setSearch] = useState("");
  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => setCurrentPage(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });
  const [maxDiaInicio, setMaxDiaInicio] = useState(31);
  const [maxDiaFin, setMaxDiaFin] = useState(31);
  const [keyToCodigoMap, setKeyToCodigoMap] = useState({});
  const [errorDiaInicio, setErrorDiaInicio] = useState("");
  const [errorDiaFin, setErrorDiaFin] = useState("");
  /* Hook */
  useEffect(() => {
    reloadInitial();
    dataComboMeses();
  }, []);

  useEffect(() => {
    if (form && form.mesInicioId) {
      updateMaxDia(form?.mesInicioId, true);
    }
    if (form && form.mesFinId) {
      updateMaxDia(form?.mesFinId, false);
    }
  }, [form]);

  const updateMaxDia = (mesKey, esInicio: boolean) => {
    const diasPorMes = {
      "01": 31,
      "02": _esBisiesto() ? 29 : 28,
      "03": 31,
      "04": 30,
      "05": 31,
      "06": 30,
      "07": 31,
      "08": 31,
      "09": 30,
      "10": 31,
      "11": 30,
      "12": 31,
    };
    const mesCodigo = keyToCodigoMap[mesKey];
    if (esInicio) {
      setMaxDiaInicio(diasPorMes[mesCodigo] || 31);
    } else {
      setMaxDiaFin(diasPorMes[mesCodigo] || 31);
    }
  };

  useEffect(() => {
    if (isOpenForm) {
      detalleNivelLevel();
    }
  }, [isOpenForm]);

  useEffect(() => {
    if (inicio != undefined) {
      setForm({
        ...form,
        inicio: _formatDate(inicio, ","),
      });
    }
  }, [inicio]);

  useEffect(() => {
    if (fin != undefined) {
      setForm({
        ...form,
        fin: _formatDate(fin, ","),
      });
    }
  }, [fin]);

  useEffect(() => {
    let current: IItemDetalleLevel[] = [];

    detalle.map((n) => {
      n.level.map((l) => {
        current = [...current, l];
      });
    });

    setForm({
      ...form,
      level: current.filter((x) => x.isChecked).map((x) => x.detalleId),
    });
  }, [detalle]);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      total: total,
    });
  }, [total]);

  const dataComboMeses = () => {
    TemporadaService.comboMeses(search)
      .then((response: any) => {
        if (response.status == 200) {
          setMeses(response.data);
          const keyToCodigo = {};
          const options = response.data.map((m) => {
            keyToCodigo[m.key] = m.codigo;
            return { key: m.key, text: m.text };
          });
          setKeyToCodigoMap(keyToCodigo);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  /* Callback reload */
  const refForm = React.useCallback(() => {
    setForm(null!);
    detalleNivelLevel();
    setInicio(undefined);
    setFin(undefined);
    resetChoice();
  }, [dismissForm]);

  /* Api */
  const dataTemporada = () => {
    setData(_initialDataCollection);

    TemporadaService.all(filter)
      .then((res) => {
        if (res.status == 200) {
          setData(res.data);
          setTotal(res.data.total);
          setHidden(true);
        }
      })
      .catch((e) => {
        alert(splitExceptionDetail(e));
        setHidden(true);
      });
  };

  const detalleNivelLevel = () => {
    TemporadaService.detalleNivelLevel()
      .then((res) => {
        if (res.status == 200) {
          setDetalle(res.data);
        }
      })
      .catch((e) => {
        alert(splitExceptionDetail(e));
        setHidden(true);
      });
  };

  const crearTemporada = () => {
    setIsSend(true);
    setDisableForm(true);

    TemporadaService.crearTemporada(form)
      .then((res) => {
        if (res.status == 200) {
          setIsSend(false);
          refForm();
          setChoice("send");
          reloadInitial();
        }
      })
      .catch((e) => {
        setIsSend(false);
        setChoice("error");
        settextError(splitExceptionDetail(e));
      });
  };

  const eliminarTemporada = () => {
    if (selId != undefined) {
      loadingEliminar();

      TemporadaService.eliminarTemporada(selId)
        .then((res) => {
          if (res.status == 200) {
            reloadInitial();
            toggleEliminar();
            dismissLoadingEliminar();
          }
        })
        .catch((e) => {
          alert(splitExceptionDetail(e));
          dismissLoadingEliminar();
        });
    }
  };

  /* Function */
  const reloadInitial = () => {
    setHidden(false);
    setTitleColumn();
    dataTemporada();
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_ADD,
        onClick: () => {
          setIsAdd(true);
          // setIsEdit(false);
          openForm();
        },
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: reloadInitial,
      },
    ];

    return _items;
  };

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "render",
        name: "Inicio",
        fieldName: "inicio",
        minWidth: 230,
        maxWidth: 230,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: ITemporada) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              <>
                <div>
                  {item.diaInicio} {item.mesInicio}
                </div>
              </>
            </Stack>
          </>
        ),
      },
      {
        key: "render",
        name: "Fin",
        fieldName: "fin",
        minWidth: 230,
        maxWidth: 230,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: ITemporada) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              <>
                <div>
                  {item.diaFin} {item.mesFin}
                </div>
              </>
            </Stack>
          </>
        ),
      },
      {
        key: "column3",
        name: "Tipo",
        fieldName: "tipo",
        minWidth: 180,
        maxWidth: 180,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      // {
      //   key: "column3",
      //   name: "Level",
      //   fieldName: "level",
      //   minWidth: 150,
      //   maxWidth: 150,
      //   isResizable: true,
      //   data: "string",
      //   isPadded: true,
      // },
      {
        key: "column3",
        name: "Opciones",
        fieldName: "",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item: ITemporada) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              <>
                <TooltipHost content={"Editar"}>
                  <Icon
                    iconName="Edit"
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      setSelId(item.temporadaId);
                      // setIsAdd(false);
                      // setIsEdit(true);
                      toggleActualizar();
                    }}
                  />
                </TooltipHost>

                <TooltipHost content={`Eliminar`}>
                  <Icon
                    iconName="Delete"
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      setSelId(item.temporadaId);
                      toggleEliminar();
                    }}
                  />
                </TooltipHost>
              </>
            </Stack>
          </>
        ),
      },
    ];

    setColumn(col);
  };

  /* Event */
  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "tipo":
            setForm({
              ...form,
              tipo: String(option.key),
            });
            break;
          case "mesInicio":
            setForm({
              ...form,
              mesInicioId: String(option.key),
            });
            break;
          case "mesFin":
            setForm({
              ...form,
              mesFinId: String(option.key),
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const _onChangeLevel = (
    nivelId: string,
    levelId: string,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    if (isChecked != undefined) {
      let d = detalle;

      let t = d.map((n) => {
        let nivelTemp = n;

        if (n.nivelId == nivelId) {
          let exis = n.level.find((z) => z.detalleId == levelId);

          if (exis) {
            let levelTemp = n.level.map((z) => {
              if (z.detalleId == levelId) {
                return {
                  ...z,
                  isChecked: isChecked,
                };
              } else {
                return z;
              }
            });

            nivelTemp = {
              ...n,
              level: levelTemp,
            };
          }
        }

        return nivelTemp;
      });

      setDetalle(t);
    }
  };

  useEffect(() => {
    validarForm();
  }, [form, errorDiaFin, errorDiaInicio]);

  const validarForm = () => {
    //!dataGuidancees.some((item) => item.value === "")
    if (form == null) setDisableForm(true);
    if (form !== null) {
      if (
        form.tipo &&
        form.mesFinId &&
        form.mesInicioId &&
        form.diaFin &&
        form.diaInicio &&
        form.level &&
        form.level.length > 0 &&
        errorDiaFin == "" &&
        errorDiaInicio == ""
      ) {
        setDisableForm(false);
      } else {
        setDisableForm(true);
      }
    }
  };

  const _onChangeAllNivel = (
    nivelId: string,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    if (isChecked != undefined) {
      let d = detalle;

      let t = d.map((n) => {
        let nivelTemp = n;

        if (n.nivelId == nivelId) {
          let levelTemp = n.level.map((z) => {
            return {
              ...z,
              isChecked: isChecked,
            };
          });

          nivelTemp = {
            ...n,
            level: levelTemp,
            isAll: isChecked,
          };
        }

        return nivelTemp;
      });

      setDetalle(t);
    }
  };

  const _onChangeText = (
    type: string,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        switch (type) {
          case "diaInicio":
            setForm({
              ...form,
              diaInicio: newValue,
            });
            break;
          case "diaFin":
            setForm({
              ...form,
              diaFin: newValue,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const handleDiaInicioChange = (e, newValue) => {
    const dia = parseInt(newValue, 10);
    _onChangeText("diaInicio", e, newValue);
    if (dia <= 0) {
      setErrorDiaInicio(`El día no puede ser menor que 0`);
    } else if (dia > maxDiaInicio) {
      setErrorDiaInicio(`El día no puede ser mayor que ${maxDiaInicio}`);
    } else {
      setErrorDiaInicio("");
    }
  };

  const handleDiaFinChange = (e, newValue) => {
    const dia = parseInt(newValue, 10);
    _onChangeText("diaFin", e, newValue);
    if (dia <= 0) {
      setErrorDiaFin(`El día no puede ser menor que 0`);
    } else if (dia > maxDiaFin) {
      setErrorDiaFin(`El día no puede ser mayor que ${maxDiaFin}`);
    } else {
      setErrorDiaFin("");
    }
  };

  /* Footer Panel */
  const onRenderFooter = React.useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={crearTemporada}
          styles={ThemeBase.panelButtonStyle}
          disabled={disableForm}
        >
          Confirmar
        </PrimaryButton>
        <DefaultButton onClick={dismissForm}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissForm, detalle, form, isSend, disableForm]
  );

  return (
    <>
      <HeadingBar
        title="Temporadas"
        subTitle="Gestión de Temporadas"
        searchPlaceholder="Buscar"
        searchShow={false}
      ></HeadingBar>

      <TableBase
        leftBar={_leftBar()}
        column={column}
        data={data.items}
        isLoading={!hidden}
        pagination={pager}
        layout={DetailsListLayoutMode.justified}
      ></TableBase>

      {/* Panel Crear */}
      <Panel
        isOpen={isOpenForm}
        onDismiss={dismissForm}
        type={PanelType.medium}
        headerText={`${isAdd ? "Registrar" : "Actualizar"} Temporada`}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooter}
        isFooterAtBottom={true}
      >
        <div style={{ paddingTop: "5%" }}>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                <ProgressIndicator
                  description="Enviando espere un momento"
                  barHeight={3}
                />
              </div>

              <div
                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                style={ThemeBase.notPaddingSide}
              >
                {choice === "error" && (
                  <CustomMessageBar
                    type={typeMessage.TYPE_ERROR}
                    message={textError}
                    dissmiss={resetChoice}
                  ></CustomMessageBar>
                )}
                {choice === "send" && (
                  <CustomMessageBar
                    dissmiss={() => {
                      refForm();
                      resetChoice();
                    }}
                  ></CustomMessageBar>
                )}
              </div>
            </div>
          </div>

          <form
            ref={refForm}
            hidden={
              choice === "error" || choice === "send" || isSend ? true : false
            }
          >
            <Stack tokens={stackTokens}>
              <div className="ms-Grid" dir="ltr">
                <Stack tokens={stackTokens}>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <ComboBox
                        label="Mes Inicio"
                        autoComplete="off"
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={meses}
                        selectedKey={form?.mesInicioId}
                        onChange={(e, o) => _onChangeCombo("mesInicio", e, o)}
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <TextField
                        label="Día Inicio"
                        name="diaInicio"
                        placeholder="Día de Inicio"
                        min={1}
                        max={maxDiaInicio}
                        onChange={handleDiaInicioChange}
                        type="number"
                        errorMessage={errorDiaInicio}
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <ComboBox
                        label="Mes Final"
                        autoComplete="off"
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={meses}
                        selectedKey={form?.mesFinId}
                        onChange={(e, o) => _onChangeCombo("mesFin", e, o)}
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <TextField
                        label="Día Fin"
                        name="diaFin"
                        placeholder="Día de Fin"
                        min={1}
                        max={maxDiaFin}
                        onChange={handleDiaFinChange}
                        type="number"
                        errorMessage={errorDiaFin}
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <ComboBox
                        label="Tipo Temporada"
                        autoComplete="on"
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={AccesoGeneric.initialTipoTemporada}
                        selectedKey={form ? form.tipo : ""}
                        onChange={(e, o) => _onChangeCombo("tipo", e, o)}
                        required
                      />
                    </div>
                  </div>

                  <br />
                  <div className="ms-Grid-row">
                    {detalle.map(function (i: IDetalleNivelLevel) {
                      return (
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                          <Checkbox
                            label={i.nivel}
                            boxSide="start"
                            onChange={(e, o) =>
                              _onChangeAllNivel(i.nivelId, e, o)
                            }
                            checked={i.isAll}
                            styles={ThemeBase.checkBoxStyles}
                          />
                          <br />

                          <Stack tokens={stackTokens}>
                            {i.level.map(function (l: IItemDetalleLevel) {
                              return (
                                <Checkbox
                                  id={l.detalleId}
                                  key={l.detalleId}
                                  label={l.level}
                                  onChange={(e, o) =>
                                    _onChangeLevel(i.nivelId, l.detalleId, e, o)
                                  }
                                  checked={l.isChecked}
                                />
                              );
                            })}
                          </Stack>
                        </div>
                      );
                    })}
                  </div>
                </Stack>
              </div>
            </Stack>
          </form>
        </div>
      </Panel>

      {/* Panel Actualizar */}
      <ActualizarTemporada
        id={selId}
        isOpen={!hideActualizar}
        dissmiss={toggleActualizar}
        reloadInitial={reloadInitial}
      ></ActualizarTemporada>

      {/* Dialog Eliminar */}
      <DialogBase
        isHidden={hideEliminar}
        subTitle={"La temporada se eliminará correctamente"}
        tipo={DialogType.normal}
        toggleHidden={() => {
          dismissLoadingEliminar();
          toggleEliminar();
        }}
        dependency={[]}
        confirm={eliminarTemporada}
        isDisableConfirm={isLoadingELiminar}
      ></DialogBase>
    </>
  );
};
