import { ComboBox, IComboBox, IComboBoxOption, ICommandBarItemProps, Label, Stack, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { HeadingBar } from "../../components/Generic/HeadingBar"
import SpinnerCenter from "../../components/Generic/SpinnerCenter";
import TitleSeparator from "../../components/Generic/TitleSeparator";
import { IItemComboBaseDTO } from "../../interfaces/base/IBase";
import { ITransporte, IValidarTransporte } from "../../interfaces/ITransporte";
import TransporteService from "../../services/transporteService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { stackTokens } from "../../utils/Helper/helper";
import ThemeBase from "../../utils/Theme/themeBase";

export const ValidarVehiculo = ({ dismiss = () => { }, register = () => { }, id = '' }) => {
    // const { id } = useParams();
    let navigate = useNavigate();

    const [hidden, setHidden] = useState(false);
    const [detalle, setDetalle] = useState<ITransporte>(null!);
    const [estado, setEstado] = useState<IItemComboBaseDTO[]>([]);
    const [model, setModel] = useState<IValidarTransporte>(null!);
    const [isComplete, setIsComplete] = useState(false);

    /* Effect */
    useEffect(() => {
        if (id != undefined) {
            infoVehiculo();
            dataEstado();

            setModel({
                ...model,
                transporteId: id,
            })
        }
    }, [id]);

    useEffect(() => {
        if (model) {
            if (model.estadoId) {
                setIsComplete(true);
            }
        }
    }, [model]);

    /* Api */
    const infoVehiculo = () => {
        if (id != undefined) {
            TransporteService.detalleTransporteById(id).then(res => {
                setDetalle(res.data);
                setHidden(true);
            }
            ).catch((e: Error) => {
                alert(e.message);
            })
        }
    }

    const dataEstado = () => {
        TransporteService.estado().then(res => {
            setEstado(res.data);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const validar = () => {
        TransporteService.validarTransporte(model).then(res => {
            if (res.status == 200) {
                // goBack();
                alert('Se validó correctamente');
                dismiss();
                register();
            }
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    /* Function */
    const goBack = () => {
        navigate(`${ConfigHelper.urlBase()}/vehiculos/lista.validar`);
    }

    const _generateItemBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                key: 'Back',
                text: 'Atrás',
                iconProps: { iconName: 'Back' },
                onClick: goBack,
            },
        ];

        return _items;
    }

    /* Event */
    const _onChangeEstado = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    estadoId: String(option.key),
                });
            }
        }
    }

    const _onChangeText = (type: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                switch (type) {
                    case 'observacion':
                        setModel({
                            ...model,
                            observacion: newValue,
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return (

        <>
            {/* <HeadingBar
                searchShow={false}
                itemBar={_generateItemBar()}
            ></HeadingBar> */}

            <SpinnerCenter isLoad={!hidden} />

            {hidden &&
                <>
                    <br />
                    <br />
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row" style={{padding:'1em'}}>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div>
                                    {/* <TitleSeparator title='Información Vehiculo' icon='BusSolid'></TitleSeparator> */}

                                    <Stack tokens={stackTokens}>
                                        <div className="ms-Grid" dir="ltr">
                                            <Stack tokens={stackTokens}>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Placa</Label>
                                                        {detalle?.placa}
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Tipo Vehículo</Label>
                                                        {detalle?.tipoTransporte}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Categoría</Label>
                                                        {detalle?.categoria}
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Marca</Label>
                                                        {detalle?.marca}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Modelo</Label>
                                                        {detalle?.modelo}
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Pasajeros</Label>
                                                        {detalle?.pasajeros}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Código Interno</Label>
                                                        {detalle?.codigoInterno}
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Año Fabricación</Label>
                                                        {detalle?.anioFabricacion}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Estado</Label>
                                                        {detalle?.estado}
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                        <Label>Empresa</Label>
                                                        {detalle?.empresa}
                                                    </div>
                                                </div>
                                            </Stack>
                                        </div>
                                        <br />
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <ComboBox
                                                        label="Estado"
                                                        autoComplete='off'
                                                        useComboBoxAsMenuWidth
                                                        calloutProps={{ directionalHintFixed: true }}
                                                        options={estado}
                                                        onChange={_onChangeEstado}
                                                        styles={ThemeBase.comboBoxStyles}
                                                    />
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <TextField
                                                        label='Observación'
                                                        placeholder='Observación'
                                                        value={model ? model.observacion ?? '' : ''}
                                                        onChange={(e, o) => _onChangeText('observacion', e, o)}
                                                        styles={ThemeBase.textFileStyles}
                                                        autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <br />
                                        <ButtonForm back={dismiss} register={validar} disabledRegister={!isComplete}></ButtonForm>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}