import * as React from "react";
import {
  Nav,
  INavStyles,
  INavLinkGroup,
  INavLink,
} from "@fluentui/react/lib/Nav";
import { getTheme } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../auth/context/auth-context";

const theme = getTheme();

// const navStyles: Partial<INavStyles> = {
//     root: {
//         width: '100%',
//         height: 'calc(100vh - 3.9em)',
//         backgroundColor: theme.palette.neutralLighterAlt,
//         color: theme.palette.themeSecondary,
//         boxSizing: 'border-box',
//         border: '1px solid #eee',
//         overflowY: 'auto',
//     },
//     groupContent: {
//         marginBottom: '15px'
//     },
//     linkText: {
//         color: theme.palette.neutralSecondary,
//         fontSize: '19',
//     },
//     link: {
//         paddingLeft: '25px'
//     }
// };
//

const navStyles: Partial<INavStyles> = {
  root: {
    width: "230px",
    height: "calc(100vh - 3rem)",
    maxwidth: "300px",
    boxSizing: "border-box",
    border: "1px solid #eee",
    overflowY: "auto",
    cursor: "pointer",
    backgroundColor: theme.palette.neutralLighter,
  },
  groupContent: {
    marginBottom: "15px",
    padding: "0 10px 0 0",
  },
  link: {
    paddingLeft: "25px",
    height: "25px",
    borderRadius: "10xp",
    font: "inherit",
    margin: 5,
  },
  chevronButton: {
    fontSize: "14px",
    height: "35px",
    borderRadius: "10xp",
  },
};

// const navLinkGroups: INavLinkGroup[] = [
//     {
//         name: "Gestión de Personas",
//         links: [
//             {
//                 key: "Puestos",
//                 name: "Puestos",
//                 url: "Puestos",
//                 icon: "BulletedList",
//                 iconProps: {
//                     iconName: 'BulletedList',
//                     styles: {
//                         root: { color: theme.palette.neutralSecondary },
//                     }
//                 },
//             },
//             {
//                 key: "Fichas",
//                 name: "Ficha de registro",
//                 url: "fichas",
//                 icon: "People"
//             },
//             {
//                 key: "Personas",
//                 name: "Personas",
//                 url: "personas",
//                 icon: "People"
//             },
//         ],
//     },
//     {
//         name: "Programaciones",
//         links: [
//             {
//                 key: "Programaciones",
//                 name: "Programaciones",
//                 url: "programaciones",
//                 icon: "Calendar",
//             },
//         ],
//     },
//     {
//         name: "Unidad Médica",
//         links: [
//             {
//                 key: "ProgExamenes",
//                 name: "Prog. Examenes",
//                 url: "prueba.covid",
//                 icon: "Calendar",
//             },
//         ],
//     },
//     {
//         name: "Hotelería",
//         links: [
//             {
//                 key: "Hoteles",
//                 name: "Hoteles",
//                 url: "hoteles",
//                 icon: "Hotel",
//             },
//             /* {
//                 key: "Habitaciones",
//                 name: "Habitaciones",
//                 url: "habitaciones",
//                 icon: "Room",
//             },
//             {
//                 key: "Programaciones",
//                 name: "Programaciones",
//                 url: "programaciones.hotel",
//                 icon: "Calendar",
//             }, */
//         ],
//     },
//     {
//         name: "Transporte",
//         links: [
//             {
//                 key: "Empresas",
//                 name: "Empresas",
//                 url: "empresas",
//                 icon: "EntitlementRedemption",
//             },
//             /* {
//                 key: "Prog. Transporte",
//                 name: "Prog. Transporte",
//                 url: "transprogramacion",
//                 icon: "Calendar",
//             }, */
//             {
//                 key: "Vehículos",
//                 name: "Vehículos",
//                 url: "vehiculos",
//                 icon: "Bus",
//             },
//             {
//                 key: "Asignacion Transporte",
//                 name: "Asignacion Transporte",
//                 url: "empresa.transporte",
//                 icon: "Car"
//             },
//             {
//                 key: "Validacion",
//                 name: "Validación",
//                 url: "vehiculos/lista.validar",
//                 icon: "CheckMark"
//             },
//         ],
//     },
// ];

export const Sidebar: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const { access } = useContext(AuthContext);

  const _clickNavigate = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    if (ev) {
      ev.preventDefault();
      if (item) {
        navigate(item.url);
      }
    }
  };

  return (
    <Nav
      styles={navStyles}
      groups={access && access.sidebar ? access.sidebar : []}
      /* groups={navLinkGroups}  */
      onLinkClick={_clickNavigate}
      ariaLabel="Nav example similar to one found in this demo page"
    />
  );
};
