import { IColumn, ICommandBarItemProps, Icon, mergeStyleSets, NeutralColors, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadingBar } from "../../components/Generic/HeadingBar"
import { TableBase } from "../../components/Table/TableBase";
import { IHabilitarServicioEmpresa, IServicioEmpresa } from "../../interfaces/IServicioEmpresa";
import ServicioEmpresaService from "../../services/servicioEmpresaService";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import { RegistrarEmpresa } from "./registrarEmpresa";
import { ConfigHelper } from "../../utils/Helper/configHelper";


/* const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    iconFont: {
        padding: 0,
        fontSize: "16px",
        marginRight: 5,
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
    contenedor: {
        width: '100%',
    },

}); */

export const ListarEmpresa = () => {
    const navigate = useNavigate();

    const [hidden, setHidden] = useState(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [empresa, setEmpresa] = useState<IServicioEmpresa[]>([]);

    /* Panel Nuevo Vehiculo */
    const [isOpenNuevo, { setTrue: openNuevo, setFalse: dismissNuevo }] = useBoolean(false);

    /* useEffect */
    useEffect(() => {
        setTitleColumn();
        dataEmpresa();
    }, []);

    function setTitleColumn() {
        let col = [
            {
                key: 'column1',
                name: 'Empresa',
                fieldName: 'empresa',
                minWidth: 200,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Ruc',
                fieldName: 'ruc',
                minWidth: 200,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Tipo',
                fieldName: 'tipo',
                minWidth: 300,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Opciones',
                fieldName: 'fipo',
                minWidth: 200,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IServicioEmpresa) => (
                    <div>
                        <TooltipHost content={item.isEnabled ? 'Habilitado' : 'Bloqueado'}>
                            <Icon iconName={item.isEnabled ? 'UnlockSolid' : 'LockSolid'}
                                className={`${ThemeBase.classes.iconFont}`}
                                style={{ margin: '0 5px 0 5px', fontSize: 17, color: (item.isEnabled) ? 'green' : 'red' }}
                                onClick={() => _changeEstado(item.id, item.isEnabled)} />
                        </TooltipHost>
                        <TooltipHost content='Eliminar'>
                            <Icon iconName='RemoveFromTrash'
                                className={`${ThemeBase.classes.iconFont}`}
                                style={{ margin: '0 5px 0 5px', fontSize: 17, color: 'red' }}
                                onClick={() => _deleteServicio(item.id)} />
                        </TooltipHost>
                        <TooltipHost content='Detalle'>
                            <Icon iconName='NumberedListText'
                                className={`${ThemeBase.classes.iconFont}`}
                                style={{ margin: '0 5px 0 5px', fontSize: 17, color: NeutralColors.gray130}}
                                onClick={() => navigate(`${ConfigHelper.urlBase()}/progr`)} />
                        </TooltipHost>
                    </div>
                ),
            }
        ];

        setColumn(col);
    }

    /* api */
    const dataEmpresa = () => {
        setEmpresa([]);
        return ServicioEmpresaService.allEmpresaByUsuario()
            .then((response: any) => {
                if (response.status == 200) {
                    setEmpresa(response.data);
                    setHidden(true);
                }
            })
            .catch((e: Error) => {
                alert(e);
                setHidden(true);
            });
    }

    const _setup = () => {
        setHidden(false);
        dataEmpresa();
    }

    const _addEmpresa = () => {
        navigate(`${ConfigHelper.urlBase()}/empresas/registrar`);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_ADD,
                // onClick: _addEmpresa,
                onClick: openNuevo,
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: _setup,
            },
        ];

        return _items;
    }

    const _changeEstado = (id: string, estado: boolean) => {
        let obj: IHabilitarServicioEmpresa = {
            servicioEmpresaId: id,
            estado: !estado,
        };

        return ServicioEmpresaService.habilitarServicioEmpresa(obj)
            .then((response: any) => {
                if (response.status == 200) {
                    alert('Se Actualizó correctamente');
                    _setup();
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const _deleteServicio = (id: string) => {
        return ServicioEmpresaService.eliminarServicioEmpresa(id)
            .then((response: any) => {
                if (response.status == 200) {
                    alert('Se eliminó correctamente');
                    _setup();
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    return (
        <>
            <HeadingBar title='Selección de Empresas Transportistas' subTitle='Gestión de Empresas' searchShow={false}></HeadingBar>
            <TableBase leftBar={_leftBar()} column={column} data={empresa} isLoading={!hidden}></TableBase>
            <PanelGenerico
                isOpen={isOpenNuevo}
                onDismiss={dismissNuevo} 
                isOnRenderHeader
                disabledFooter
                iconHeaderName="train"
                title='Empresa Transportista'
                subTitle='Selección de Empresas Transportistas'
            >
                <RegistrarEmpresa dismiss={dismissNuevo} registar={_setup}></RegistrarEmpresa>
            </PanelGenerico>
        </>
    )
}  