import { useState } from "react"

export const UseForm = <T extends Object>(initState: T) => {
    const [form, setForm] = useState(initState);

    const _handleChange = ({ target }: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const { name, value } = target as HTMLInputElement;

        setForm({
            ...form,
            [name] : value
        });
    }

    return {
        form,
        _handleChange,
        setForm,
        ...form
    }
}