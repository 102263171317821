import { IFilterUbicacion } from "../interfaces/filter/IFilterUbicacion";
import { IUbicacionFilter } from "../interfaces/IProgramacionBus";
import { IUbicacionEditar, IUbicacionNuevo } from "../interfaces/ubicacion/IUbicacion";
import { fetchAdministracion } from "./http-common";

const urlUbicacion = 'Ubicacion';

const comboUbicaciones = (codigo: number) => {
    return fetchAdministracion.get(`${urlUbicacion}/Combos?codigo=${codigo}`);
}

// TIPO
// 1. ORIGEN
// 2. DESTINO
// 3 ORIGEN + DESTINO

const listaUbicaciones = (filter:{}) => {
    return fetchAdministracion.get(`${urlUbicacion}/ListaUbicaciones`, {params: filter});
}
const ubicacionPorFiltro = (filter: IFilterUbicacion) => {
    return fetchAdministracion.get(`${urlUbicacion}/ListarPorFiltro`, { params: filter });
}

const ubicacionComboAereo = (filter: IFilterUbicacion) => {
    return fetchAdministracion.get(`${urlUbicacion}/Combo/Aereo`, { params: filter });
}
const ubicacionCrear = (data: IUbicacionNuevo) => {
    return fetchAdministracion.post(`${urlUbicacion}/CrearUbicacion`, data);
}
const ubicacionEliminar = (id: string) => {
    return fetchAdministracion.delete(`${urlUbicacion}/${id}`);
}
const ubicacionEditar = (data: IUbicacionEditar) => {
    return fetchAdministracion.put(`${urlUbicacion}/EditarUbicacion`, data);
}

const UbicacionService = {
    comboUbicaciones,
    ubicacionPorFiltro,
    ubicacionComboAereo,
    listaUbicaciones,
    ubicacionCrear,
    ubicacionEliminar,
    ubicacionEditar
}

export default UbicacionService;