import { ComboBox, IComboBox, IComboBoxOption, Panel, PanelType, ProgressIndicator, SelectableOptionMenuItemType, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import { CustomMessageBar, typeMessage } from "../../../components/MessageBar/customMessageBar";
import { IItemComboBaseDTO } from "../../../interfaces/base/IBase";
import { IAsignarRutaProgramacionBus, IUbicacionFilter } from "../../../interfaces/IProgramacionBus";
import ProgramacionBusService from "../../../services/programacionBusService";
import UbicacionService from "../../../services/ubicacionesServices";
import { ItemSelectAll, KeyAllSelect, splitExceptionDetail, stackTokens, _initialFilter } from "../../../utils/Helper/helper";
import { MessageHelper } from "../../../utils/Helper/messageHelper";
import ThemeBase from "../../../utils/Theme/themeBase";

export const CrearRutaProgramacionBus = ({ programacionBusId = '', transporte = '', isOpen = false, dismiss = () => { }, reloadParent = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Form */
    const [form, setForm] = useState<IAsignarRutaProgramacionBus>(null!);
    const [ubicacion, setUbicacion] = useState<IItemComboBaseDTO[]>([]);
    const [filterUbicacion, setFilterUbicacion] = useState<IUbicacionFilter>({
        ..._initialFilter(),
        tipo: 2
    })

    /* Event Multiselect */
    const [selectedKeyRuta, setSelectedKeyRuta] = React.useState<string[]>([]);
    const selectableOptionRuta = ubicacion.filter(
        option =>
            (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
    );

    /* Hook */
    useEffect(() => {
        if (isOpen) {
            if (programacionBusId != '') {
                setChoice('');
                dataRuta();
            }
        }
    }, [isOpen]);

    /* Api */
    const dataRuta = () => {
        ProgramacionBusService.rutaPorProgramacionBus(programacionBusId)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemSelectAll());
                    setUbicacion(t);

                    let s = res.data.filter(x => x.isChecked).map(x => x.key);
                    console.log(s);
                    setSelectedKeyRuta(s);
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
            });
    }

    const asignarRuta = () => {
        if (programacionBusId != '') {
            setIsSend(true);
            setChoice(undefined);

            let t: IAsignarRutaProgramacionBus = {
                programacionBusId: programacionBusId,
                listaRutaId: selectedKeyRuta.filter(x => x != KeyAllSelect),
            };

            ProgramacionBusService.asignarRuta(t)
                .then((res) => {
                    setIsSend(false)
                    if (res.status == 200) {
                        reloadParent();
                        setChoice('send');
                        reloadParent();
                    }
                })
                .catch((e) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    };

    const onChangeUbicacion = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeyRuta.filter(key => key !== KeyAllSelect);
        const selectAllState = currentSelectedOptionKeys.length === selectableOptionRuta.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setSelectedKeyRuta([])
                    : setSelectedKeyRuta([KeyAllSelect, ...selectableOptionRuta.map(o => o.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === selectableOptionRuta.length) {
                    updatedKeys.push(KeyAllSelect);
                }
                setSelectedKeyRuta(updatedKeys);
            }
        }
    };

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <>
                <ButtonForm register={asignarRuta} disabledRegister={isSend} textRegister='Asignar' back={() => dismiss()}></ButtonForm>
            </>
        ),
        [programacionBusId, dismiss, selectedKeyRuta, isSend]
    );

    return (
        <>
            <Panel
                type={PanelType.medium}
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText={`Asignar Rutas - Transporte ${transporte}`}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    resetChoice();
                                }}></CustomMessageBar>}
                            </div>
                        </div>
                    </div>

                    <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                        <div className="ms-Grid" dir="ltr" style={{ paddingTop: '20%' }}>
                            <Stack tokens={stackTokens}>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Rutas"
                                            multiSelect
                                            options={ubicacion}
                                            selectedKey={selectedKeyRuta}
                                            onChange={onChangeUbicacion}
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                        />
                                    </div>
                                </div>
                            </Stack>
                        </div>
                    </form>

                </>
            </Panel>
        </>
    )
}