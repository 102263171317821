import { IColumn, Icon, Stack, TooltipHost } from "@fluentui/react";
import { State } from "../../../components/Generic/state";
import { IProgramacion } from "../../../interfaces/IProgramacion";
import { ColorHelper } from "../../../utils/Helper/colorHelper";
import { stackTokensHorizontal } from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import ThemeBase from "../../../utils/Theme/themeBase";

export const ColumnAdicional = (url = '', navigate) => {
    let col: IColumn[] = [
        {
            key: 'column1',
            name: 'Programacion',
            fieldName: 'titulo',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column1.2',
            name: 'Tipo',
            fieldName: 'tipoPersonal',
            minWidth: 30,
            maxWidth: 30,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column2',
            name: 'Fecha Cierre',
            fieldName: 'fechaCierre',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
            onRender: (item: IProgramacion) => {
                return <span style={{ color: ColorHelper.DANGER }}>{item.fechaCierre}</span>;
            },
        },
        {
            key: 'column3',
            name: 'Fecha Mov.',
            fieldName: 'fechaIngreso',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column4',
            name: 'Fecha Bajada',
            fieldName: 'fechaSalida',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
        },
        // {
        //     key: 'column5',
        //     name: 'Guardia',
        //     fieldName: 'guardia',
        //     minWidth: 200,
        //     maxWidth: 200,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        //     isPadded: true,
        // },
        {
            key: 'column7',
            name: 'Adicionales/Cupos',
            fieldName: 'inscritos',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: "column7.1",
            name: "Estado",
            fieldName: "estado",
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isMultiline: true,
            data: "string",
            isPadded: true,
            onRender: (item: IProgramacion) => (
                <>
                    <State estado={item.estado} colorEstado={item.colorEstado}></State>
                </>
            )
        },
        {
            key: "column9",
            name: "Opciones",
            fieldName: "opciones",
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: "number",
            onRender: (item: IProgramacion) => (
                <>
                    <Stack horizontal tokens={stackTokensHorizontal}>
                        <TooltipHost content={`Detalle`}>
                            <Icon iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                                style={{ cursor: 'pointer' }}
                                className={ThemeBase.classes.iconFont}
                                onClick={() => navigate(`${url}/${item.programacionId}`)}
                            />
                        </TooltipHost>
                    </Stack>
                </>
            ),
        },
    ];

    return col;
};