export const IS_PROD = true;
const PATH_LOGIN = '/login';

export const urlBase = (login = false) => {
    return IS_PROD ? (login ? '/' : '') : '/movilizaciones';
}

export const urlLogin = () => {
    return IS_PROD ? `${PATH_LOGIN}` : `${urlBase()}${PATH_LOGIN}`;
}

export const ConfigHelper = {
    urlBase,
    urlLogin,
    PATH_LOGIN,
}