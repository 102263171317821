import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  Shimmer,
  Stack,
  TextField,
  ThemeProvider,
  Toggle,
} from "@fluentui/react";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../auth/context/auth-context";
import { ButtonForm } from "../../../../components/Generic/buttonForm";
import { PanelGenerico } from "../../../../components/Generic/PanelGenerico";
import {
  IBase,
  IDetailUbigeo,
  IException,
  IItemComboBaseDTO,
  ISearch,
} from "../../../../interfaces/base/IBase";
import { IFilterRegla } from "../../../../interfaces/filter/IFilterRegla";
import { IFilterUbicacion } from "../../../../interfaces/filter/IFilterUbicacion";
import { ICreateAccesoPersona } from "../../../../interfaces/IAccesoPersona";
import { Ifilter } from "../../../../interfaces/IFilter";
import { IHotel } from "../../../../interfaces/IHotel";
import { IListarPersonaById, IPersona } from "../../../../interfaces/IPersona";
import { IProgramacionPersona } from "../../../../interfaces/IProgramacion";
import ContratoService from "../../../../services/contratoService";
import EmpresaService from "../../../../services/empresaService";
import HoteleriaService from "../../../../services/HoteleriaService";
import PersonaService from "../../../../services/PersonaService";
import ProgramacionService from "../../../../services/ProgramacionService";
import { ReglaService } from "../../../../services/reglaService";
import TemporadaService from "../../../../services/temporadaService";
import UbicacionService from "../../../../services/ubicacionesServices";
import UbigeoService from "../../../../services/ubigeoService";
import {
  splitExceptionDetail,
  stackTokens5,
  _initialFilter,
  keyAccess,
} from "../../../../utils/Helper/helper";
import {
  isRoleAccessAll,
  ROLE_CONTRATISTA,
  ROLE_KEY_USER_EMPLEADO,
  ROLE_KEY_USER_SOCIO,
  roleAdmin,
} from "../../../../utils/Helper/roleHelper";
import { UbicacionHelper } from "../../../../utils/Helper/ubicacionHelper";
import { AccesoGeneric } from "../../generic/accesoGeneric";
import { CrearAccesoShimmer } from "../../shimmer/accesoShimmer";
import { IUserAuthenticate } from "../../../../auth/interface/IAuth";

export const CrearAccesoVuelo = ({
  programacionId = "",
  onDismiss = () => {},
  setup = () => {},
  isOpen = false,
  subTitle = "",
  isSubida = false,
}) => {
  let { id } = useParams();

  const [programacion, setProgramacion] = useState<IProgramacionPersona>();
  const [isSend, setIsSend] = useState(false);
  const [model, setModel] = useState<ICreateAccesoPersona>({
    ...AccesoGeneric.initialForm,
    programacionId: id,
  });
  const [persona, setPersona] = useState<IItemComboBaseDTO[]>([]);
  const [personaIsLoad, setpersonaIsLoad] = useState(false);
  const [embarque, setEmbarque] = useState<IItemComboBaseDTO[]>([]);
  const [destino, setDestino] = useState<IItemComboBaseDTO[]>([]);

  /* Ubigeo */
  const [Ubigueo, setUbigueo] = useState<IItemComboBaseDTO[]>([]);
  const [Provincias, setProvincias] = useState<IItemComboBaseDTO[]>([]);
  const [Distritos, setDistritos] = useState<IItemComboBaseDTO[]>([]);
  const [detailUbigeo, setDetailUbigeo] = useState<IDetailUbigeo>(null!);
  const [hotel, setHotel] = useState<IItemComboBaseDTO[]>([]);
  const [transporte, setTransporte] = useState<IItemComboBaseDTO[]>([]);
  const [turno, setTurno] = useState<IItemComboBaseDTO[]>(
    AccesoGeneric.initialTurno
  );

  const [lugarMovilizacion, setLugarMovilizacion] = useState<
    IItemComboBaseDTO[]
  >([]);

  const [ubicacionCampamentoId, setubicacionCampamentoId] = useState("");
  const [paraderoId, setParaderoId] = useState("");

  const [personaSelect, setPersonaSelect] = useState(false);
  const [personaOptionSelect, setpersonaOptionSelect] =
    useState<IComboBoxOption>();
  const [centroCosto, setCentroCosto] = useState<IItemComboBaseDTO[]>([]);
  const [filterCentroCosto, setFilterCentroCosto] = useState<Ifilter>({
    ..._initialFilter(),
  });
  const centroCostoRef = React.useRef<IComboBox>(null);
  const _openOptionCentroCosto = React.useCallback(
    () => centroCostoRef.current?.focus(true),
    []
  );
  const accessN = localStorage.getItem(keyAccess);
  let iRole: string[] = [];
  if (accessN) {
    let d: IUserAuthenticate = JSON.parse(accessN);
    if (d.role) {
      iRole = d.role;
    }
  }

  const [roleN, setRoleN] = useState<string[]>(iRole);
  let isKeyUser = isRoleAccessAll(roleN, [
    ROLE_KEY_USER_EMPLEADO,
    ROLE_KEY_USER_SOCIO,
  ]);
  let isSSEE = isRoleAccessAll(roleN, [ROLE_CONTRATISTA]);
  let isOnlyKeyUserEmpleado = isRoleAccessAll(roleN, [ROLE_KEY_USER_EMPLEADO]);
  /* Ref combo */
  const comboBoxRef = React.useRef<IComboBox>(null);
  const departamentoRef = React.useRef<IComboBox>(null);
  const provinciaRef = React.useRef<IComboBox>(null);
  const embarqueRef = React.useRef<IComboBox>(null);
  const destinoRef = React.useRef<IComboBox>(null);
  const LugarMovilizacoinRef = React.useRef<IComboBox>(null);

  /* Event open combo box */
  const _openOptionPersona = React.useCallback(
    () => comboBoxRef.current?.focus(true),
    []
  );
  const _openOptionEmbarque = React.useCallback(
    () => embarqueRef.current?.focus(true),
    []
  );
  const _openOptionDestino = React.useCallback(
    () => destinoRef.current?.focus(true),
    []
  );
  const _openOptionMovilizacion = React.useCallback(
    () => LugarMovilizacoinRef.current?.focus(true),
    []
  );

  /* Filter */
  const [filterEmpresa, setFilterEmpresa] = useState<Ifilter>(_initialFilter);
  const [filterPersona, setFilterPersona] = useState<Ifilter>(_initialFilter);
  const [filterEmbarque, setfilterEmbarque] = useState<IFilterUbicacion>({
    tipo: UbicacionHelper.TIPO_ORIGEN,
  });
  const [filterDestino, setfilterDestino] = useState<IFilterUbicacion>({
    tipo: UbicacionHelper.TIPO_DESTINO,
  });
  const [filterMovilizacion, setfilterMovilizacion] =
    useState<ISearch>(_initialFilter);

  /* Campos default Panel */
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [celular, setCelular] = useState<string | undefined>(undefined);
  const [direccion, setDireccion] = useState<string | undefined>(undefined);
  const [ccostoId, setCCostoId] = useState<string | undefined>(undefined);
  const [lugarMovId, setLugarMovId] = useState<string | undefined>(undefined);
  const [ubigeoId, setUbigeoId] = useState<string | undefined>(undefined);
  const [empresa, setEmpresa] = useState<string>("");
  const [psGroup, setPsGroup] = useState<string>("");
  const [isTitular, setIsTitular] = useState(false);

  /* Message Error & Choice */
  const [choice, setChoice] = React.useState<string | undefined>(undefined);
  const resetChoice = React.useCallback(() => setChoice(undefined), []);
  const [textError, settextError] = useState("Error");
  const [isMMG, setIsMMG] = useState<boolean | undefined>(undefined);
  /* Access & Role */
  const { access } = useContext(AuthContext);
  let role = access?.role ?? [];
  let isAdmin = isRoleAccessAll(role, roleAdmin());

  /* Hook */
  // Carga inicial

  useEffect(() => {
    setPsGroup("");
    setModel({ ...model, programacionId: id });
    dataPersona();
    // dataEmbarque();
    // dataDestino();
    dataLugarMovilizacion();
    dataDepartamento();
    dataEmpresaVuelo();
    dataHotel();
  }, []);

  useEffect(() => {
    if (id != undefined) {
      dataInfoProgramacion();
    }
  }, [id]);

  useEffect(() => {
    if (filterPersona.search != null) {
      dataPersona();
    }
  }, [filterPersona]);

  // useEffect(() => {
  //     if (filterEmbarque.search != null) {
  //         dataEmbarque();
  //     }
  // }, [filterEmbarque]);

  // useEffect(() => {
  //     if (filterDestino.search != null) {
  //         dataDestino();
  //     }
  // }, [filterDestino]);

  useEffect(() => {
    if (filterMovilizacion.search != null) {
      dataLugarMovilizacion();
    }
  }, [filterMovilizacion]);

  /* Hook seleccionas persona */
  useEffect(() => {
    if (email != undefined) {
      setModel({
        ...model,
        email: email,
      });
    }
  }, [email]);

  useEffect(() => {
    if (celular != undefined) {
      setModel({
        ...model,
        celular: celular,
      });
    }
  }, [celular]);

  useEffect(() => {
    if (direccion != undefined) {
      setModel({
        ...model,
        direccion: direccion,
      });
    }
  }, [direccion]);

  useEffect(() => {
    if (ccostoId != undefined) {
      setModel({
        ...model,
        centroCostoId: ccostoId,
      });
    }
  }, [ccostoId]);

  useEffect(() => {
    if (lugarMovId != undefined) {
      setModel({
        ...model,
        lugarMovilizacionId: lugarMovId,
      });
    }
  }, [lugarMovId]);

  useEffect(() => {
    if (ubigeoId != undefined) {
      detalleUbigeo(ubigeoId);
    }
  }, [ubigeoId]);

  useEffect(() => {
    if (detailUbigeo != undefined) {
      if (detailUbigeo.distritoId != undefined) {
        setModel({
          ...model,
          ubigeoId: detailUbigeo.distritoId,
        });
      }
    }
  }, [detailUbigeo]);

  useEffect(() => {
    setModel({
      ...model,
      esTitular: isTitular,
    });
  }, [isTitular]);

  useEffect(() => {
    dataCentroCosto();
  }, [filterCentroCosto]);

  /* Api */
  const dataInfoProgramacion = () => {
    PersonaService.getInfoProgramacion(id)
      .then((res) => {
        let d: IProgramacionPersona = res.data;
        setProgramacion(d);

        if (!d.isAllTurno) {
          setTurno(turno.filter((x) => x.key != "D"));
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataPersona = () => {
    if (isAdmin) {
      PersonaService.listarPersonaMMG(filterPersona)
        .then((res) => {
          /* v2 persona */
          let d: IPersona[] = res.data;
          let t: IItemComboBaseDTO[] = d.map((x) => {
            return {
              key: x.id,
              text: `${x.nroDOI}  | ${x.persona} | ${x.tipo}`,
            };
          });

          setPersona(t);
          setpersonaIsLoad(true);
        })
        .catch((e: Error) => {
          alert(e.message);
        });
    } else {
      TemporadaService.allPersonaByTemporadaLevel(programacionId, filterPersona)
        .then((res) => {
          /* v2 persona */
          let d: IPersona[] = res.data;
          let t: IItemComboBaseDTO[] = d.map((x) => {
            return {
              key: x.personaId,
              text: `${x.nroDOI}  | ${x.persona} | ${x.tipo}`,
            };
          });

          setPersona(t);
          setpersonaIsLoad(true);
        })
        .catch((e: Error) => {
          alert(e.message);
        });
    }
  };

  // const dataEmbarque = () => {
  //     UbicacionService.ubicacionComboAereo(filterEmbarque)
  //         .then((res) => {
  //             setEmbarque(res.data);
  //         })
  //         .catch((e: Error) => {
  //             console.log(e);
  //         });
  // };

  // const dataDestino = () => {
  //     UbicacionService.ubicacionComboAereo(filterDestino)
  //         .then((res) => {
  //             setDestino(res.data);
  //         })
  //         .catch((e: Error) => {
  //             console.log(e);
  //         });
  // };

  const dataLugarMovilizacion = () => {
    ProgramacionService.allLugarMovilizacion()
      .then((res) => {
        setLugarMovilizacion(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataCentroCosto = () => {
    ContratoService.allCentroCosto1(filterCentroCosto)
      .then((res) => {
        setCentroCosto(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataDepartamento = () => {
    PersonaService.getComboUbigueo()
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setUbigueo(t);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataHotel = () => {
    HoteleriaService.getHoteles(50)
      .then((res) => {
        let d: IHotel[] = res.data.items;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.hotelID != null ? x.hotelID : "",
            text: `${x.nombre} | ${x.direccion}`,
          };
        });

        setHotel(t);
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  const detalleUbigeo = (distritoId: string) => {
    UbigeoService.detalleById(distritoId)
      .then((res) => {
        let d: IDetailUbigeo = res.data;

        setDetailUbigeo({
          ...programacion,
          departamentoId: d.departamentoId,
          provinciaId: d.provinciaId,
          distritoId: d.distritoId,
        });

        if (d.departamentoId) {
          dataByUbigeoId(2, d.departamentoId);
        }

        if (d.provinciaId) {
          dataByUbigeoId(3, d.provinciaId);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataByUbigeoId = (tipo: number, ubigeoId?: string) => {
    PersonaService.getComboUbigueo(ubigeoId)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        switch (tipo) {
          case 1:
            setUbigueo(t);
            break;
          case 2:
            setProvincias(t);
            break;
          case 3:
            setDistritos(t);
            break;
          default:
            break;
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  /* Callback reload */
  const refForm = React.useCallback(() => {
    setFilterPersona({
      ...filterPersona,
      search: "",
    });
    setModel({
      ...AccesoGeneric.initialForm,
      programacionId: id,
    });

    setDistritos([]);
    setProvincias([]);
    setDetailUbigeo(null!);
    resetChoice();
    setEmpresa("");
    setPsGroup("");
  }, [onDismiss]);

  /* Api */
  const dataEmpresaVuelo = () => {
    EmpresaService.allVuelo(filterEmpresa)
      .then((res) => {
        setTransporte(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const _onInfoPersona = (id: string) => {
    let obj: IListarPersonaById = {
      listaPersonas: [id],
    };

    setubicacionCampamentoId("");
    setParaderoId("");

    PersonaService.getInfoPersonaById(obj)
      .then((res) => {
        if (res.data) {
          let d = res.data[0];
          if (d) {
            setEmail(d.email);
            setCelular(d.celular);
            setDireccion(d.direccion);
            setCCostoId(d.centroCostoId);
            setLugarMovId(d.lugarMovilizacionId);
            setUbigeoId(d.ubigeoId);
            setEmpresa(d.empresa);
            setPsGroup(`${d.nivel} - ${d.level}`);
            setIsTitular(d.esTitular);
            let isMMG = d.tipo == "01";
            // Regla Origen & Destino
            let lugMovId = d.lugarMovilizacionId;
            dataOrigen(lugMovId, isMMG);
            dataDestino(lugMovId, isMMG);
          }
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const dataOrigen = (lugarMovilizacionId: string, isMMG: boolean) => {
    let f: IFilterRegla = {
      programacionId: programacionId,
      lugarMovilizacionId: lugarMovilizacionId,
      tipoUbicacion: UbicacionHelper.TIPO_ORIGEN,
      esVuelo: true,
      requiereTransporte: true,
      skip: 0,
      take: 50,
    };

    ReglaService.listarPorFiltro(f)
      .then((res) => {
        setEmbarque(res.data);

        if (
          isMMG != null &&
          isMMG != undefined &&
          isMMG == true /*&& isKeyUser*/
        ) {
          if (
            res.data != null &&
            res.data[0] != null &&
            res.data[0].tieneRegla
          ) {
            setParaderoId(res.data[0].key!);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataDestino = (lugarMovilizacionId: string, isMMG: boolean) => {
    let f: IFilterRegla = {
      programacionId: programacionId,
      lugarMovilizacionId: lugarMovilizacionId,
      tipoUbicacion: UbicacionHelper.TIPO_DESTINO,
      esVuelo: true,
      requiereTransporte: true,
      skip: 0,
      take: 50,
    };

    ReglaService.listarPorFiltro(f)
      .then((res) => {
        setDestino(res.data);
        if (
          isMMG != null &&
          isMMG != undefined &&
          isMMG == true /*&& isKeyUser*/
        ) {
          if (
            res.data != null &&
            res.data[0] != null &&
            res.data[0].tieneRegla
          ) {
            // setmodel({ ...model, ubicacionCampamentoId: res.data[0].key });
            setubicacionCampamentoId(res.data[0].key!);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const _LoadProvincia = (key: any) => {
    PersonaService.getComboUbigueo(key)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setProvincias(t);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const _LoadDistritos = (key: any) => {
    PersonaService.getComboUbigueo(key)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setDistritos(t);
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  /* Function */
  const _enviarAccesoPersona = () => {
    setIsSend(true);

    PersonaService.inscribirVuelo(model)
      .then((res) => {
        if (res.status == 200) {
          setIsSend(false);
          refForm();
          setChoice("send");
          setEmpresa("");
          setPsGroup("");
          setup();
        }
      })
      .catch((e: any) => {
        setIsSend(false);
        setChoice("error");
        settextError(splitExceptionDetail(e));
      });
  };

  /* Event */
  const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
    let { value } = ev?.target as HTMLInputElement;

    switch (type) {
      case "persona":
        _openOptionPersona();

        setModel({
          ...model,
          personaId: "",
        });

        setEmpresa("");
        if (value.length > 2 || value.length == 0) {
          setFilterPersona({
            ...filterPersona,
            search: value,
          });
        }
        break;
      case "embarque":
        _openOptionEmbarque();

        setModel({
          ...model,
          paraderoId: "",
        });

        setfilterEmbarque({
          ...filterEmbarque,
          search: value,
        });
        break;
      case "destino":
        _openOptionDestino();

        setModel({
          ...model,
          ubicacionCampamentoId: "",
        });

        setfilterDestino({
          ...filterDestino,
          search: value,
        });
        break;
      case "lugarMovilizacion":
        _openOptionMovilizacion();

        setModel({
          ...model,
          lugarMovilizacionId: "",
        });
        setfilterMovilizacion({
          ...filterMovilizacion,
          search: value,
        });
        break;
      default:
        break;
    }
  };

  const _onKeyUpComboCentroCosto = (e: React.FormEvent<IComboBox>) => {
    let { id } = e?.target as HTMLInputElement;
    let { value } = e?.target as HTMLInputElement;
    _openOptionCentroCosto();

    setFilterCentroCosto({
      ...filterCentroCosto,
      search: value,
    });
  };

  const _onInputPersona = (e: React.KeyboardEvent<IComboBox>) => {
    _openOptionPersona();

    let { value } = e?.target as HTMLInputElement;

    setModel({
      ...model,
      personaId: "",
    });

    setEmpresa("");
    if (value.length > 2) {
      setFilterPersona({
        ...filterPersona,
        search: value,
      });
    }

    let embarqueAux = model.paraderoId;
    let turnoAux = model.turno;
    let campamentoAux = model.ubicacionCampamentoId;
    let empresaAux = model.transporteId;
    setModel({
      ...AccesoGeneric.initialForm,
      programacionId: id,
      paraderoId: embarqueAux,
      turno: turnoAux,
      ubicacionCampamentoId: campamentoAux,
      transporteId: empresaAux,
    });
    setDetailUbigeo(AccesoGeneric.initialUbigeo);
    setProvincias([]);
    setDistritos([]);
  };

  const _onChangePersona = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setEmbarque([]);
        setDestino([]);
        setpersonaOptionSelect(option);
        _onInfoPersona(option.key.toString());
      }
    }
  };

  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "persona":
            _onInfoPersona(option.key.toString());
            setpersonaOptionSelect(option);
            setPersonaSelect(true);
            setModel({
              ...model,
              personaId: option.key.toString(),
            });
            break;
          case "embarque":
            setModel({
              ...model,
              paraderoId: option.key.toString(),
            });
            break;
          case "destino":
            setModel({
              ...model,
              ubicacionCampamentoId: option.key.toString(),
            });
            break;
          case "turno":
            setModel({
              ...model,
              turno: option.key.toString(),
            });
            break;
          case "lugarMovilizacion":
            setModel({
              ...model,
              lugarMovilizacionId: option.key.toString(),
            });
            break;
          case "centroCosto":
            setModel({
              ...model,
              centroCostoId: option.key.toString(),
            });
            break;
          case "departamento":
            setDetailUbigeo({
              ...detailUbigeo,
              departamentoId: String(option.key) as string,
            });
            _LoadProvincia(option.key);
            break;
          case "provincia":
            setDetailUbigeo({
              ...detailUbigeo,
              provinciaId: String(option.key),
            });

            _LoadDistritos(option.key);
            break;
          case "distrito":
            setModel({
              ...model,
              ubigeoId: option.key.toString(),
            });
            break;
          case "empresa":
            setModel({
              ...model,
              transporteId: option.key.toString(),
            });
            break;
          case "hotel":
            setModel({
              ...model,
              hotelId: option.key.toString(),
            });
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case "departamento":
            setModel({
              ...model,
              ubigeoId: null,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    if (personaOptionSelect) {
      setModel({
        ...model,
        personaId: personaOptionSelect.key.toString(),
        lugarMovilizacionId: lugarMovId,
        ubigeoId: detailUbigeo ? detailUbigeo.distritoId : "",
        celular: celular,
        email: email,
        direccion: direccion,
        centroCostoId: ccostoId,
        ubicacionCampamentoId: ubicacionCampamentoId,
        paraderoId: paraderoId,
      });
    }
  }, [personaOptionSelect, ubicacionCampamentoId, paraderoId]);

  const _onBlurCombo = (type: string) => {
    switch (type) {
      case "persona":
        if (model.personaId == "" || model.personaId == undefined) {
          setFilterPersona({
            ...filterPersona,
            search: "",
          });
        }
        break;
      case "embarque":
        if (model.paraderoId == "" || model.paraderoId == undefined) {
          setfilterEmbarque({
            ...filterEmbarque,
            search: "",
          });
        }
        break;
      case "destino":
        if (
          model.ubicacionCampamentoId == "" ||
          model.ubicacionCampamentoId == undefined
        ) {
          setfilterDestino({
            ...filterDestino,
            search: "",
          });
        }
        break;
      default:
        break;
    }
  };

  const _onChangeClasificacion = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != undefined) {
      setModel({
        ...model,
        esTitular: checked,
      });
    }
  };

  const _onChangeText = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      let { name } = e?.target as HTMLInputElement;

      switch (name) {
        case "direccion":
          setModel({
            ...model,
            direccion: newValue,
          });
          break;
        case "email":
          setModel({
            ...model,
            email: newValue,
          });
          break;
        case "celular":
          setModel({
            ...model,
            celular: newValue,
          });
          break;
        default:
          break;
      }
    }
  };

  /* Footer Panel */
  const onRenderFooter = React.useCallback(() => {
    const filter =
      !model.personaId ||
      !model.paraderoId ||
      !model.ubicacionCampamentoId ||
      !model.turno;
    const disabledRegister = isSend || filter;
    return (
      <>
        <ButtonForm
          back={onDismiss}
          register={_enviarAccesoPersona}
          disabledRegister={isSend}
        ></ButtonForm>
      </>
    );
  }, [
    model,
    persona,
    onDismiss,
    personaIsLoad,
    Ubigueo,
    Provincias,
    Distritos,
    centroCosto,
    isSend,
    isSubida,
    isAdmin,
    isMMG,
  ]);

  /* Message Bar */
  const ErrorExample = (p: any) => {
    return (
      <MessageBar
        messageBarType={MessageBarType.blocked}
        isMultiline={false}
        onDismiss={p.resetChoice}
        dismissButtonAriaLabel="Close"
        truncated={true}
        overflowButtonAriaLabel="See more"
      >
        {textError}
      </MessageBar>
    );
  };
  const SuccessExample = () => (
    <MessageBar
      messageBarType={MessageBarType.success}
      isMultiline={false}
      onDismiss={() => {
        refForm();
        resetChoice();
      }}
    >
      Success
    </MessageBar>
  );

  return (
    <>
      <PanelGenerico
        isOpen={isOpen}
        onDismiss={onDismiss}
        isOnRenderHeader
        disabledFooter
        iconHeaderName="TemporaryUser"
        title="Inscripcion Helo"
        subTitle2="Complete el formulario para registar colaborador"
        subTitle={`Personal ${programacion?.tipoPersonal} | ${programacion?.guardia}`}
        onRendeFooter={onRenderFooter}
        disabledIconHeader={false}
      >
        <>
          <div hidden={!isSend}>
            <ProgressIndicator
              description="Enviando espere un momento"
              barHeight={3}
            />
          </div>
          <br />
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            {choice === "error" && <ErrorExample resetChoice={resetChoice} />}
            {choice === "send" && <SuccessExample />}
          </div>
          <br />
          {/* ANIMACION DE CARGA FORMULARIO */}
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
            hidden={personaIsLoad}
          >
            <ThemeProvider>
              <Shimmer
                shimmerElements={CrearAccesoShimmer.shimmerWithElementFirstRow}
              />{" "}
              <br />
              <br />
              <Shimmer
                shimmerElements={
                  CrearAccesoShimmer.shimmerWithElementSecondRow2
                }
              />{" "}
              <br />
              <br />
              <Shimmer
                shimmerElements={
                  CrearAccesoShimmer.shimmerWithElementSecondRow2
                }
              />{" "}
              <br />
              <br />
              <Shimmer
                width="48%"
                shimmerElements={CrearAccesoShimmer.shimmerWithElementFirstRow}
              />{" "}
              <br />
              <br />
              <Shimmer
                shimmerElements={
                  CrearAccesoShimmer.shimmerWithElementSecondRow3
                }
              />{" "}
              <br />
              <br />
              <Shimmer
                shimmerElements={CrearAccesoShimmer.shimmerWithElementFirstRow}
              />{" "}
              <br />
              <br />
              <Shimmer
                shimmerElements={CrearAccesoShimmer.shimmerWithElementFirstRow}
              />{" "}
              <br />
              <br />
              <Shimmer
                shimmerElements={
                  CrearAccesoShimmer.shimmerWithElementSecondRow2
                }
              />{" "}
              <br />
              <br />
            </ThemeProvider>
          </div>
          <form
            ref={refForm}
            hidden={
              choice === "error" || choice === "send" || !personaIsLoad
                ? true
                : false
            }
          >
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                  <div>
                    {!isSend && (
                      <>
                        <Stack tokens={stackTokens5}>
                          {/* <pre>{JSON.stringify(filterEmbarque, null, 2)}</pre>
                                                    <pre>{JSON.stringify(filterDestino, null, 2)}</pre>
                                                    <pre>{JSON.stringify(filterPersona, null, 2)}</pre> */}

                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                  componentRef={comboBoxRef}
                                  label="Seleccionar Persona"
                                  allowFreeform
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={persona}
                                  errorMessage={empresa}
                                  onKeyUp={(e) => _onUpCombo("persona", e)}
                                  onInput={_onInputPersona}
                                  selectedKey={model.personaId}
                                  onChange={_onChangePersona}
                                  onBlur={() => _onBlurCombo("persona")}
                                  required
                                />

                                <span
                                  style={{
                                    color: "rgb(164, 38, 44)",
                                    fontSize: 12,
                                  }}
                                >
                                  {psGroup}
                                </span>
                              </div>

                              <div>{isSubida}</div>

                              {!isSubida ? (
                                <>
                                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                      componentRef={embarqueRef}
                                      label="Origen (Embarque)"
                                      allowFreeform
                                      autoComplete="off"
                                      useComboBoxAsMenuWidth
                                      calloutProps={{
                                        directionalHintFixed: true,
                                      }}
                                      disabled={
                                        paraderoId.length > 0 &&
                                        isOnlyKeyUserEmpleado
                                      }
                                      options={embarque}
                                      onKeyUp={(e) => _onUpCombo("embarque", e)}
                                      selectedKey={
                                        model ? model.paraderoId : ""
                                      }
                                      onChange={(e, o) =>
                                        _onChangeCombo("embarque", e, o)
                                      }
                                      onBlur={() => _onBlurCombo("embarque")}
                                      required
                                    />
                                  </div>

                                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                      componentRef={destinoRef}
                                      label="Destino (Desembarque)"
                                      allowFreeform
                                      autoComplete="off"
                                      useComboBoxAsMenuWidth
                                      disabled={
                                        ubicacionCampamentoId.length > 0 &&
                                        isOnlyKeyUserEmpleado
                                      }
                                      calloutProps={{
                                        directionalHintFixed: true,
                                      }}
                                      onKeyUp={(e) => _onUpCombo("destino", e)}
                                      options={destino}
                                      selectedKey={
                                        model ? model.ubicacionCampamentoId : ""
                                      }
                                      onChange={(e, o) =>
                                        _onChangeCombo("destino", e, o)
                                      }
                                      onBlur={() => _onBlurCombo("destino")}
                                      required
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                      componentRef={embarqueRef}
                                      label=" Origen (Embarque)"
                                      allowFreeform
                                      autoComplete="off"
                                      useComboBoxAsMenuWidth
                                      calloutProps={{
                                        directionalHintFixed: true,
                                      }}
                                      onKeyUp={(e) => _onUpCombo("embarque", e)}
                                      options={embarque}
                                      selectedKey={
                                        model ? model.paraderoId : ""
                                      }
                                      onChange={(e, o) =>
                                        _onChangeCombo("embarque", e, o)
                                      }
                                      onBlur={() => _onBlurCombo("embarque")}
                                      disabled={
                                        paraderoId.length > 0 &&
                                        isOnlyKeyUserEmpleado
                                      }
                                      required
                                    />
                                  </div>

                                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                      componentRef={destinoRef}
                                      label="Destino (Desembarque)"
                                      allowFreeform
                                      autoComplete="off"
                                      useComboBoxAsMenuWidth
                                      calloutProps={{
                                        directionalHintFixed: true,
                                      }}
                                      options={destino}
                                      onKeyUp={(e) => _onUpCombo("destino", e)}
                                      selectedKey={
                                        model ? model.ubicacionCampamentoId : ""
                                      }
                                      onChange={(e, o) =>
                                        _onChangeCombo("destino", e, o)
                                      }
                                      disabled={
                                        ubicacionCampamentoId.length > 0 &&
                                        isOnlyKeyUserEmpleado
                                      }
                                      onBlur={() => _onBlurCombo("destino")}
                                      required
                                    />
                                  </div>
                                </>
                              )}

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                  label="Seleccionar Turno"
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={turno}
                                  openOnKeyboardFocus
                                  selectedKey={model.turno}
                                  onChange={(e, o) =>
                                    _onChangeCombo("turno", e, o)
                                  }
                                  required
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                  componentRef={LugarMovilizacoinRef}
                                  label="Lugar Movilización"
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  calloutProps={{ directionalHintFixed: true }}
                                  onKeyUp={(e) =>
                                    _onUpCombo("lugarMovilizacion", e)
                                  }
                                  options={lugarMovilizacion}
                                  selectedKey={
                                    model ? model.lugarMovilizacionId : ""
                                  }
                                  onChange={(e, o) =>
                                    _onChangeCombo("lugarMovilizacion", e, o)
                                  }
                                  disabled={true}
                                  required
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                  label="Centro Costos"
                                  autoComplete="off"
                                  useComboBoxAsMenuWidth
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={centroCosto}
                                  selectedKey={model ? model.centroCostoId : ""}
                                  onChange={(e, o) =>
                                    _onChangeCombo("centroCosto", e, o)
                                  }
                                  required
                                  componentRef={centroCostoRef}
                                  onKeyUp={_onKeyUpComboCentroCosto}
                                  allowFreeform
                                  disabled={!isAdmin}
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                {/* <Toggle
                                                                    label={`Clasificación Colaborador`}
                                                                    onText="Titular"
                                                                    offText="Suplente"
                                                                    onChange={_onChangeClasificacion}
                                                                    checked={model ? model.esTitular : false}
                                                                /> */}
                                <Toggle
                                  label="Requiere Transporte"
                                  placeholder="placeholder"
                                  onText="Si"
                                  offText="No"
                                  // checked={model ? model.requiereTransporte : false}
                                  // onChange={_onToggleComunidad}
                                />
                              </div>
                            </div>
                          </div>
                          {/* Ubigeo */}
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <div
                                  className="ms-fontSize-16"
                                  style={{ color: "#4676DD" }}
                                >
                                  Ubigeo (Ubicación actual)
                                </div>
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <ComboBox
                                  componentRef={departamentoRef}
                                  label="Departamento"
                                  allowFreeform
                                  autoComplete="on"
                                  useComboBoxAsMenuWidth
                                  openOnKeyboardFocus
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={Ubigueo}
                                  selectedKey={
                                    detailUbigeo
                                      ? detailUbigeo.departamentoId
                                      : ""
                                  }
                                  onChange={(e, o) =>
                                    _onChangeCombo("departamento", e, o)
                                  }
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <ComboBox
                                  label="Provincia"
                                  componentRef={provinciaRef}
                                  allowFreeform
                                  autoComplete="on"
                                  useComboBoxAsMenuWidth
                                  openOnKeyboardFocus
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={Provincias}
                                  selectedKey={
                                    detailUbigeo ? detailUbigeo.provinciaId : ""
                                  }
                                  onChange={(e, o) =>
                                    _onChangeCombo("provincia", e, o)
                                  }
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                <ComboBox
                                  label="Distrito"
                                  allowFreeform
                                  autoComplete="on"
                                  useComboBoxAsMenuWidth
                                  openOnKeyboardFocus
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={Distritos}
                                  selectedKey={model.ubigeoId}
                                  onChange={(e, o) =>
                                    _onChangeCombo("distrito", e, o)
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </div>

                          {/* Transporte / hotel */}
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                  label="Empresa Helo"
                                  allowFreeform
                                  autoComplete="on"
                                  useComboBoxAsMenuWidth
                                  openOnKeyboardFocus
                                  calloutProps={{ directionalHintFixed: true }}
                                  options={transporte}
                                  selectedKey={model.transporteId}
                                  onChange={(e, o) =>
                                    _onChangeCombo("empresa", e, o)
                                  }
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                {programacion?.tieneCuarentena && (
                                  <ComboBox
                                    label="Hotel"
                                    allowFreeform
                                    autoComplete="on"
                                    useComboBoxAsMenuWidth
                                    openOnKeyboardFocus
                                    calloutProps={{
                                      directionalHintFixed: true,
                                    }}
                                    options={hotel}
                                    selectedKey={model.hotelId}
                                    onChange={(e, o) =>
                                      _onChangeCombo("hotel", e, o)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>

                          {/* Info contacto */}
                          <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <TextField
                                  label="Dirección"
                                  name="direccion"
                                  placeholder="Dirección"
                                  value={model.direccion}
                                  onChange={_onChangeText}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                  label="Email"
                                  name="email"
                                  placeholder="Email"
                                  value={model.email}
                                  onChange={_onChangeText}
                                  autoComplete="off"
                                />
                              </div>

                              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <TextField
                                  label="Teléfono"
                                  name="telefono"
                                  placeholder="Teléfono"
                                  value={model.celular}
                                  onChange={_onChangeText}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                        </Stack>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      </PanelGenerico>
    </>
  );
};
