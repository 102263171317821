import { ComboBox, IComboBox, IComboBoxOption, ICommandBarItemProps, Stack, TextField, Toggle } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { HeadingBar } from "../../components/Generic/HeadingBar"
import TitleSeparator from "../../components/Generic/TitleSeparator";
import { IBase, IDetailUbigeo, IException, IItemComboBaseDTO, ISearch } from "../../interfaces/base/IBase";
import { IHotel } from "../../interfaces/IHotel";
import { IOptionPersona, IPersona } from "../../interfaces/IPersona";
import { IEditProgramacionPersona } from "../../interfaces/IProgramacion";
import ContratoService from "../../services/contratoService";
import HoteleriaService from "../../services/HoteleriaService";
import PersonaService from "../../services/PersonaService";
import ProgramacionPersonaService from "../../services/programacionPersonaService";
import ProgramacionService from "../../services/ProgramacionService";
import TransporteService from "../../services/transporteService";
import UbigeoService from "../../services/ubigeoService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { splitExceptionDetail, stackTokens } from "../../utils/Helper/helper";

export const EditarProgPersona = () => {
    const { id } = useParams();
    let navigate = useNavigate();

    const [paradero, setparadero] = useState<IItemComboBaseDTO[]>([]);
    const [comboCampamento, setComboCampamento] = useState<IItemComboBaseDTO[]>([]);
    const [Ubigueo, setUbigueo] = useState<IItemComboBaseDTO[]>([]);
    const [Provincias, setProvincias] = useState<IItemComboBaseDTO[]>([]);
    const [Distritos, setDistritos] = useState<IItemComboBaseDTO[]>([]);
    const [hotel, setHotel] = useState<IItemComboBaseDTO[]>([]);
    const [transporte, setTransporte] = useState<IItemComboBaseDTO[]>([]);
    const [centroCosto, setCentroCosto] = useState<IItemComboBaseDTO[]>([]);
    const [lugarMovilizacion, setLugarMovilizacion] = useState<IItemComboBaseDTO[]>([]);

    /* Reest */
    const [programacion, setProgramacion] = useState<IEditProgramacionPersona>(null!);
    const [persona, setPersona] = useState<IOptionPersona[]>([]);
    const [ubigeo, setUbigeo] = useState<IDetailUbigeo>(null!);
    const [filterPersona, setFilterPersona] = useState<ISearch>({ search: null });

    const goBack = () => {
        navigate(`${ConfigHelper.urlBase()}/programaciones/${programacion.programacionId}`);
    }

    /* Efect */
    useEffect(() => {
        if (!id) {
            return
        }

        dataProgramacionPersona();
        dataParadero();
        dataCampamento();
        dataLugarMovilizacion();
        dataCentroCosto();
        dataTransporte();
        dataHotel();
        dataByUbigeoId(1, undefined);
    }, [id]);

    useEffect(() => {
        if (filterPersona.search != null) {
            dataPersona();
        }
    }, [filterPersona]);

    /* Api */
    const dataProgramacionPersona = () => {
        if (id != undefined) {
            PersonaService.getAccesProgConfirm(id)
                .then((res) => {
                    if (res.status == 200) {
                        let d: IEditProgramacionPersona = res.data;

                        setProgramacion({
                            programacionId: d.programacionId,
                            programacionPersonaId: d.programacionPersonaId,
                            identificacion: d.identificacion,
                            paraderoId: d.paraderoId,
                            ubicacionCampamentoId: d.ubicacionCampamentoId,
                            turnoId: d.turnoId,
                            ubigeoId: d.ubigeoId,
                            transporteId: d.transporteId,
                            hotelId: d.hotelId,
                            direccion: d.direccion,
                            email: d.email,
                            celular: d.celular,
                            isCuarentena: d.isCuarentena,
                            isTitular: d.isTitular,
                            esTitular: d.esTitular,
                            lugarMovilizacionId: d.lugarMovilizacionId,
                            centroCostoId: d.centroCostoId,
                        });

                        /* Buscar la persona por dni */
                        setFilterPersona({
                            ...filterPersona,
                            search: d.identificacion,
                        });

                        /* Ubigeo */
                        if (d.ubigeoId != undefined) {
                            detalleUbigeo(d.ubigeoId);
                        }
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }

    const dataPersona = () => {
        PersonaService.personaByCustomer(filterPersona)
            .then(res => {
                /* v2 persona */
                let d: IPersona[] = res.data.data;
                let t: IOptionPersona[] = d.map(x => {
                    return ({
                        key: x.id,
                        text: `${x.nroDOI} | ${x.persona}`,
                        foto: x.foto,
                        doi: x.nroDOI,
                        puesto: x.puesto,
                    });
                });

                setPersona(t);
            })
            .catch((e: Error) => {
                alert(e.message);
            });;
    }

    const dataParadero = () => {
        PersonaService.getComboParadero().then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setparadero(t)
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataCampamento = () => {
        PersonaService.getComboCampamento().then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setComboCampamento(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion().then(res => {
            setLugarMovilizacion(res.data);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        });;
    }

    const dataCentroCosto = () => {
        ContratoService.allCentroCosto().then(res => {
            setCentroCosto(res.data);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        });;
    }

    const dataTransporte = () => {
        TransporteService.allSocioTransportista().then(res => {
            let d: IBase[] = res.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setTransporte(t);
        }).catch((e) => {
            return alert(e.message);
        })
    }

    const dataHotel = () => {
        HoteleriaService.getHoteles(50).then(res => {
            let d: IHotel[] = res.data.items;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.hotelID != null ? x.hotelID : '',
                    text: `${x.nombre} | ${x.direccion}`,
                })
            });

            setHotel(t);
        }).catch((e) => {
            return alert(e.message);
        });
    }

    const detalleUbigeo = (distritoId: string) => {
        UbigeoService.detalleById(distritoId).then(res => {
            let d: IDetailUbigeo = res.data;

            setUbigeo({
                ...programacion,
                departamentoId: d.departamentoId,
                provinciaId: d.provinciaId,
                distritoId: d.distritoId,
            })

            if (d.departamentoId) {
                dataByUbigeoId(2, d.departamentoId);
            }

            if (d.provinciaId) {
                dataByUbigeoId(3, d.provinciaId);
            }
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataByUbigeoId = (tipo: number, ubigeoId?: string) => {
        PersonaService.getComboUbigueo(ubigeoId).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            switch (tipo) {
                case 1:
                    setUbigueo(t);
                    break;
                case 2:
                    setProvincias(t);
                    break;
                case 3:
                    setDistritos(t);
                    break;
                default:
                    break;
            }
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const actualizar = () => {
        ProgramacionPersonaService.actualizar(programacion)
            .then(res => {
                alert('Se registró correctamente');
                /* dataProgramacionPersona(); */
                goBack();
            })
            .catch((e: Error) => {
                alert(e.message);
            });;
    }

    /* Event */
    const _onChangeParadero = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setProgramacion({
                    ...programacion,
                    paraderoId: option.key.toString(),
                });
            }
        }
    }

    const _onChangeCampamento = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                console.log(option);

                setProgramacion({
                    ...programacion,
                    ubicacionCampamentoId: option.key.toString(),
                });
            }
        }
    }

    const _onChangeClasificacion = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked != undefined) {
            setProgramacion({
                ...programacion,
                esTitular: checked,
            })
        }
    }

    const _onChangeDepartamento = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setUbigeo({
                    ...ubigeo,
                    departamentoId: option.key.toString(),
                    provinciaId: null,
                    distritoId: null
                });

                dataByUbigeoId(2, option.key.toString());

                setProgramacion({
                    ...programacion,
                    ubigeoId: null,
                });
            }
        }
    }

    const _onChangeProvincia = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setUbigeo({
                    ...ubigeo,
                    provinciaId: option.key.toString(),
                    distritoId: null,
                });

                dataByUbigeoId(3, option.key.toString());

                setProgramacion({
                    ...programacion,
                    ubigeoId: null,
                });
            }
        }
    }

    const _onChangeDistrito = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setUbigeo({
                    ...ubigeo,
                    distritoId: option.key.toString(),
                });

                setProgramacion({
                    ...programacion,
                    ubigeoId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeTransporte = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setProgramacion({
                    ...programacion,
                    transporteId: option.key.toString(),
                });
            }
        }
    }

    const _onChangeHotel = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setProgramacion({
                    ...programacion,
                    hotelId: option.key.toString(),
                });
            }
        }
    }

    const _onChangeDireccion = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setProgramacion({
                ...programacion,
                direccion: newValue,
            });
        }
    }

    const _onChangeEmail = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setProgramacion({
                ...programacion,
                email: newValue,
            });
        }
    }

    const _onChangeTelefono = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setProgramacion({
                ...programacion,
                celular: newValue,
            });
        }
    }

    /* Function */
    const _generateItemBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                key: 'Back',
                text: 'Atrás',
                iconProps: { iconName: 'Back' },
                onClick: goBack,
            },
        ];

        return _items;
    }

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'lugarMovilizacion':
                        setProgramacion({
                            ...programacion,
                            lugarMovilizacionId: String(option.key),
                        })
                        break;
                    case 'centroCosto':
                        setProgramacion({
                            ...programacion,
                            centroCostoId: String(option.key),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return (
        <>
            <HeadingBar searchShow={false} itemBar={_generateItemBar()}></HeadingBar>

            <form>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg2"></div>

                        <div className="ms-Grid-col ms-sm12 ms-md10 ms-lg8" style={{
                            padding: '5%',
                        }}>
                            <div className="ms-depth-8" style={{
                                padding: '15px',
                            }}>
                                <TitleSeparator title='Editar Inscripción Colaborador' icon='VisualsFolder'></TitleSeparator>

                                <Stack tokens={stackTokens}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComboBox
                                                    label="Persona"
                                                    allowFreeform
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={persona}
                                                    selectedKey={persona.length > 0 ? persona[0].key : ''}
                                                    disabled
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label="Embarque"
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={paradero}
                                                    selectedKey={programacion ? programacion.paraderoId : ''}
                                                    onChange={_onChangeParadero}
                                                    required
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label="Ubicación Campamento"
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={comboCampamento}
                                                    selectedKey={programacion ? programacion.ubicacionCampamentoId : ''}
                                                    onChange={_onChangeCampamento}
                                                    required
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label="Turno"
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={[
                                                        { key: 'D', text: 'Turno Día' },
                                                        { key: 'N', text: 'Turno Noche' }
                                                    ]}
                                                    selectedKey={programacion ? programacion.turnoId : ''}
                                                    disabled
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label='Lugar Movilización'
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={lugarMovilizacion}
                                                    selectedKey={programacion ? programacion.lugarMovilizacionId : ''}
                                                    onChange={(e, o) => _onChangeCombo('lugarMovilizacion', e, o)}
                                                    required
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <ComboBox
                                                    label='Centro Costos'
                                                    autoComplete='off'
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={centroCosto}
                                                    selectedKey={programacion ? programacion.centroCostoId : ''}
                                                    onChange={(e, o) => _onChangeCombo('centroCosto', e, o)}
                                                    required
                                                />
                                            </div>

                                            <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                                <Toggle label='Clasificación Colaborador' onText='Titular' offText='Suplente' checked={programacion ? programacion.esTitular : true} onChange={_onChangeClasificacion} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Ubigeo */}
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <div className="ms-fontSize-16" style={{ color: '#4676DD' }}>Ubigeo (Ubicación actual)</div>
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                                <ComboBox
                                                    label='Departamento'
                                                    allowFreeform
                                                    autoComplete='on'
                                                    useComboBoxAsMenuWidth
                                                    openOnKeyboardFocus
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={Ubigueo}
                                                    selectedKey={ubigeo ? ubigeo.departamentoId : ''}
                                                    onChange={_onChangeDepartamento}
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                                <ComboBox
                                                    label='Provincia'
                                                    allowFreeform
                                                    autoComplete='on'
                                                    useComboBoxAsMenuWidth
                                                    openOnKeyboardFocus
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={Provincias}
                                                    selectedKey={ubigeo ? ubigeo.provinciaId : ''}
                                                    onChange={_onChangeProvincia}
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                                <ComboBox
                                                    label='Distrito'
                                                    allowFreeform
                                                    autoComplete='on'
                                                    useComboBoxAsMenuWidth
                                                    openOnKeyboardFocus
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={Distritos}
                                                    selectedKey={ubigeo ? ubigeo.distritoId : ''}
                                                    onChange={_onChangeDistrito}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Transporte / hotel */}
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComboBox
                                                    label='Empresa Transportista'
                                                    allowFreeform
                                                    autoComplete='on'
                                                    disabled={programacion ? programacion.isTitular : false}
                                                    useComboBoxAsMenuWidth
                                                    openOnKeyboardFocus
                                                    calloutProps={{ directionalHintFixed: true }}
                                                    options={transporte}
                                                    selectedKey={programacion ? programacion.transporteId : ''}
                                                    onChange={_onChangeTransporte}
                                                />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                {programacion?.isCuarentena &&
                                                    <ComboBox
                                                        label='Hotel'
                                                        allowFreeform
                                                        autoComplete='on'
                                                        useComboBoxAsMenuWidth
                                                        openOnKeyboardFocus
                                                        calloutProps={{ directionalHintFixed: true }}
                                                        options={hotel}
                                                        selectedKey={programacion ? programacion.hotelId : ''}
                                                        onChange={_onChangeHotel}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* Info contacto */}
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <TextField
                                                    label="Dirección"
                                                    placeholder="Dirección"
                                                    value={programacion?.direccion}
                                                    onChange={_onChangeDireccion}
                                                    autoComplete="off" />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <TextField
                                                    label="Email"
                                                    placeholder="Email"
                                                    value={programacion?.email}
                                                    onChange={_onChangeEmail}
                                                    autoComplete="off" />
                                            </div>

                                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                <TextField
                                                    label="Teléfono"
                                                    placeholder="Teléfono"
                                                    value={programacion?.celular}
                                                    onChange={_onChangeTelefono}
                                                    autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>

                                    <ButtonForm back={goBack} register={actualizar}></ButtonForm>
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}