import React from "react";
import { Dialog, Icon, DialogFooter, PrimaryButton, DefaultButton, getTheme, IDialogContentProps, ActivityItem, FontSizes, FontIcon } from '@fluentui/react';
import { ITheme } from '@fluentui/react/lib/Styling';
import { NeutralColors } from '@fluentui/theme';

const theme = getTheme();



const _hideDialog: boolean = true;
const _toggleHideDialog = () => { };
const _title: string = "";
const _subText: string = "";
const _iconName: string | undefined = "";
const _onclickButtonPrimary = (param) => { };
const _nameButtonPrimary: string = "";
const _onclickButtonDefault = () => { };
const _nameButtonDefault: string = "";


const DialogBaseError = ({
    hideDialog = _hideDialog,
    toggleHideDialog = _toggleHideDialog,
    title = _title,
    subText = _subText,
    // iconName = _iconName,
    // onclickButtonPrimary = (param: any) => _onclickButtonPrimary(parametro)
    onclickButtonPrimary = (parametro) => _onclickButtonPrimary(parametro)
}) => {

    let param: string = "";
    const myElement = <div>
        <ActivityItem
            style={{
                marginTop: 3
            }}
            activityDescription={[
                <div>
                    <div className="LayoutPage-demoBlock">
                    <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size14 }}> <strong>¡Error!</strong></div>
                        <br />
                        <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>{subText}</div>
                    </div>
                </div>

            ]}
            activityIcon={
                <FontIcon
                    // iconName="Accept"
                    iconName="ErrorBadge"
                    style={{
                        color: theme.palette.red,
                        fontSize: 30,
                        height: 30,
                        width: 30,
                        marginTop: 40
                        // margin: "0 5px",

                    }}
                />
            }
        // comments={[
        //     <div className="LayoutPage-demoBlock">
        //         <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>Edite datos de la persona: {info?.persona}</div>
        //         <br />
        //         <br />
        //     </div>
        // ]}
        // timeStamp={}
        // className={ThemeBase.activityClass.exampleRoot}
        />
    </div>

    return (

        <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={{
                title: myElement,
                // title: "Error en Solicitud",
                // subText: subText,
            }}
            styles={{
                main: {
                    // background: "#F2DFE2",
                    background: "#fff",
                    //borderTop: `4px solid ${theme.palette.themePrimary}`,
                }
            }}
        >
            {/* <div style={{ width: "100%" }}>
                <div
                    style={{
                        width: "42px",
                        height: "42px",
                        margin: "0 auto",
                        marginBottom:"30px"
                    }}
                >
                    <Icon
                        // iconName={iconName}
                        iconName="ErrorBadge"
                        style={{
                            color: theme.palette.themePrimary,
                        }}
                        className="ms-fontSize-42"
                    ></Icon>
                </div>
            </div> */}
            <DialogFooter styles={{ actions: { marginTop: "40px" } }}>
                <PrimaryButton
                    onClick={() => {
                        toggleHideDialog();
                    }}
                    text="Aceptar"
                    style={{ height: 25 }}
                />
            </DialogFooter>
        </Dialog>
    );


}

export {DialogBaseError};