import { getTheme, Icon } from "@fluentui/react";

const theme = getTheme();

interface IHeaderBase {
  title: String | undefined;
  subTitle?: String | undefined;
  subTitle2?: String | undefined;
  iconHeaderPanel?: boolean;
  iconHeaderName?: String;
}
const _PROP: IHeaderBase = {
  title: "",
  subTitle: "",
  subTitle2: "",
  iconHeaderPanel: true,
  iconHeaderName: "Help",
};

const HeaderGeneric = (
  {
    title= "",
    subTitle= "",
    subTitle2= "",
    iconHeader= true,
    iconName= "Help",
  }
) => {
  return (
    <>
      <div
        hidden={iconHeader}
        className="ms-Grid-col ms-sm12 ms-md4 ms-lg1"
      >
        <Icon
          style={{
            fontSize: 40,
            height: 40,
            width: 40,
            margin: "0 0.5em",
          }}
          iconName={iconName}
        ></Icon>
      </div>
      <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg11">
        <div
          className="ms-fontSize-18"
          style={{
            color: theme.palette.neutralSecondary,
            paddingLeft: "20px",
          }}
        >
          {title}
        </div>
        <div
          className="ms-fontSize-14"
          style={{
            color: theme.palette.neutralSecondaryAlt,
            paddingLeft: "20px",
          }}
        >
          {subTitle}
        </div>
        <div
          className="ms-fontSize-12"
          style={{
            color: theme.palette.neutralSecondaryAlt,
            paddingLeft: "20px",
          }}
        >
          {subTitle2}
        </div>
      </div>
    </>
  );
};

export default HeaderGeneric;
