import { DefaultButton, ICommandBarItemProps, IIconProps, TooltipHost } from "@fluentui/react"
import { useId } from '@fluentui/react-hooks';
import React from "react"

/* 1. Icon & Option Command Bar */
const ICON_ADD: ICommandBarItemProps = {
    key: 'AddTo',
    text: 'Nuevo',
    iconProps: { iconName: 'AddTo' }
}

const ICON_BACK: ICommandBarItemProps = {
    key: 'ReplyAll',
    text: 'Atrás',
    iconProps: { iconName: 'ReplyAll' }
}

const ICON_RELOAD: ICommandBarItemProps = {
    key: 'SyncStatus',
    text: 'Actualizar',
    iconProps: { iconName: 'SyncStatus' }
}

const ICON_EXCEL: ICommandBarItemProps = {
    key: 'ExcelDocument',
    text: 'Descargar',
    iconProps: { iconName: 'ExcelDocument', style: { color: 'green' } }
}

const ICON_UPLOAD: ICommandBarItemProps = {
    key: 'upload',
    text: 'Subir',
    iconProps: { iconName: 'upload' }
}

const ICON_FILE_BULK_UPLOAD: ICommandBarItemProps = {
    key: 'BulkUpload',
    text: 'Subir',
    iconProps: { iconName: 'BulkUpload' }
}

const ICON_DOWNLOAD: ICommandBarItemProps = {
    key: 'download',
    text: 'Descargar',
    iconProps: { iconName: 'download' }
}

const ICON_CHECK: ICommandBarItemProps = {
    key: 'CheckMark',
    text: 'Validar',
    iconProps: { iconName: 'CheckMark' }
}

const ICON_CONTACTS: ICommandBarItemProps = {
    key: 'ConnectContacts',
    text: 'Validar todo',
    iconProps: { iconName: 'ConnectContacts' }
}

const ICON_CANCEL: ICommandBarItemProps = {
    key: 'Cancel',
    text: 'Cancelar',
    iconProps: { iconName: 'Cancel' }
}

const ICON_INFO: ICommandBarItemProps = {
    key: 'Info',
    text: 'Información',
    iconProps: { iconName: 'Info' }
}

const ICON_LIST: ICommandBarItemProps = {
    key: 'BulletedList',
    text: 'Detalle',
    iconProps: { iconName: 'BulletedList' }
}

const ICON_REPORT_DOCUMENT: ICommandBarItemProps = {
    key: 'ReportDocument',
    text: 'Reporte',
    iconProps: { iconName: 'ReportDocument' }
}

const ICON_MAIL_CHECK: ICommandBarItemProps = {
    key: 'MailCheck',
    text: 'Email',
    iconProps: { iconName: 'MailCheck' }
}

const ICON_REPLY_MIRRORED: ICommandBarItemProps = {
    key: 'ReplyAllMirrored',
    text: 'Mover',
    iconProps: { iconName: 'ReplyAllMirrored' }
}

const ICON_SEARCH: ICommandBarItemProps = {
    key: 'Search',
    text: 'Buscar',
    iconProps: { iconName: 'Search' }
}

const ICON_SMS: ICommandBarItemProps = {
    key: 'Send',
    text: 'Enviar SMS',
    iconProps: { iconName: 'Send' }
}

const ICON_EDIT: ICommandBarItemProps = {
    key: 'Edit',
    text: 'Editar',
    iconProps: { iconName: 'Edit' }
}

const ICON_DETAIL: ICommandBarItemProps = {
    key: 'NumberedListText',
    text: 'Detalle',
    iconProps: { iconName: 'NumberedListText' }
}

const ICON_DELETE: ICommandBarItemProps = {
    key: 'Delete',
    text: 'Eliminar',
    iconProps: { iconName: 'Delete' }
}

/* Carga de Archivo*/
const ICON_FOLDER: ICommandBarItemProps = {
    key: 'folder',
    text: '',
    iconProps: { iconName: 'FabricFolder' }
}

/* 2. Cuadro Mando - Reporte */
const ICON_CHART: ICommandBarItemProps = {
    key: 'Chart',
    text: 'Reporte',
    iconProps: { iconName: 'Chart' }
}

/* 3. Prueba Covid - solo se usa una vez */
const ICON_SR: ICommandBarItemProps = {
    key: 'sr',
    text: 'SR',
    iconProps: { iconName: 'Important' }
}

const ICON_NSP: ICommandBarItemProps = {
    key: 'nsp',
    text: 'NSP',
    iconProps: { iconName: 'SkypeMinus' }
}

/**Filtros y busquedas*/
const ICON_FILTER: ICommandBarItemProps = {
    key: 'filter',
    text: 'Filtrar',
    iconProps: { iconName: 'Filter' }
}
const ICON_CLFILTER: ICommandBarItemProps = {
    key: 'CLF',
    text: 'Borrar Filtro',
    iconProps: { iconName: 'ClearFilter' }
    // iconProps: { iconName: 'FilterSolid' }
}
const ICON_CUADROMANDO: ICommandBarItemProps = {
    key: 'cuadroMando',
    text: 'Reporte',
    iconProps: { iconName: 'BarChart4' }
    // iconProps: { iconName: 'FilterSolid' }
}

const ICON_ADD_GRUPO: ICommandBarItemProps = {
    key: 'AddGroup',
    text: 'Grupo',
    iconProps: { iconName: 'AddGroup' }
}

const ICON_FILTERS = {
    filterPanel: 'Filter',
    filterSearch: 'Breadcrumb',
    clearFilter: 'ClearFilter'
}

const ICON_REDIRECT: ICommandBarItemProps = {
    key: 'Redirect',
    text: 'Ir a otra página',
    iconProps: { iconName: 'NavigateBackMirrored' }
}

const ICON_UPLOAD_COMUNICADO: ICommandBarItemProps = {
    key: 'upload',
    text: 'Comunicados',
    iconProps: { iconName: 'upload' }
}

export const IconHelper = {
    /* 1. Command Bar */
    ICON_ADD,
    ICON_BACK,
    ICON_RELOAD,
    ICON_DETAIL,
    ICON_EXCEL,
    ICON_UPLOAD,
    ICON_FILE_BULK_UPLOAD,
    ICON_DOWNLOAD,
    ICON_CHECK,
    ICON_CONTACTS,
    ICON_CANCEL,
    ICON_INFO,
    ICON_LIST,
    ICON_REPORT_DOCUMENT,
    ICON_MAIL_CHECK,
    ICON_REPLY_MIRRORED,
    ICON_SEARCH,
    ICON_SMS,
    ICON_CUADROMANDO,

    /* Opciones */
    ICON_EDIT,
    ICON_DELETE,

    /* 2. Cuadro Mando - Reporte */
    ICON_CHART,

    /* 3. Prueba Covid - solo se usa una vez */
    ICON_SR,
    ICON_NSP,

    /**Filtros y busquedas*/
    ICON_FILTER,
    ICON_FILTERS,
    ICON_CLFILTER,

    /*Carga Archivos */
    ICON_Folder: ICON_FOLDER,

    ICON_ADD_GRUPO,
    ICON_REDIRECT,
    ICON_UPLOAD_COMUNICADO
}