import React from "react";
import { useState } from 'react';
import { ICreateMarca, ITablaMarca, IUpdateMarca } from "../../../interfaces/ITransporte";
import { DetailsListLayoutMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { TextField, TooltipHost, Icon, PanelType, ICommandBarItemProps, MessageBar, MessageBarType, MessageBarButton, ProgressIndicator, SharedColors, mergeStyleSets } from '@fluentui/react';
import { TableBase } from "../../../components/Table/TableBase";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { PanelPlantillaFiltro } from "../../../components/PanelUpload/PanelPlantillaFiltro";
import { useBoolean } from '@fluentui/react-hooks';
import TransporteService from '../../../services/transporteService';
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { useEffect } from 'react';
import { DialogBaseAlert } from "../../../components/Dialog/DialogBaseAlert";
import { DialogBaseWait } from "../../../components/Dialog/DialogBaseWait";
import { DialogBaseSuccess } from "../../../components/Dialog/DialogBaseSuccess";
import { DialogBaseError } from "../../../components/Dialog/DialogBaseError";
import { _initialPagination } from "../../../utils/Helper/helper";
import { IPagination } from "../../../interfaces/base/ITable";


const classNames = mergeStyleSets({

    iconFont: {
        padding: 0,
        fontSize: "14px",
        marginRight: 5,
        marginLeft: 2,
    },
});


function Marca() {

    const [hidden, setHidden] = useState(false);

    const data: ITablaMarca[] = [];
    const [items, setItems] = useState(data);
    const [modelMarca, setModelMarca] = useState<ICreateMarca>(null!);
    const [modelItem, setModelItem] = useState<IUpdateMarca>(null!)

    const [search, setSearch] = useState("");

    //panel
    const [isOpenMarca, { setTrue: openMarca, setFalse: dismissMarca },] = useBoolean(false);
    const [isOpenMarcaEditar, { setTrue: openMarcaEditar, setFalse: dismissMarcaEditar },] = useBoolean(false);

    //mensajes de estado
    const [verLoading, setVerLoading] = useState(false);
    const [mensajeTransaccion, setMensajeTransaccion] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState(0);
    const [verLoadingBoton, setVerLoadingBoton] = useState(false);

    //dialog
    const [hideAlertEliminar, { toggle: toggleHideAlertEliminar }] = useBoolean(true);
    const [hideWaitEliminar, { toggle: toggleHideWaitEliminar }] = useBoolean(true);
    const [hideSuccessEliminar, { toggle: toggleHideSuccessEliminar }] = useBoolean(true);
    const [hideErrorEliminar, { toggle: toggleHideErrorEliminar }] = useBoolean(true);

    //paginacion----------------------------------------
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);
    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    const dataByPager = (page) => {
        if (true) {
            setTotal(0);
            setPager({
                ...pager,
                currentPage: page,
            });
        }
    }
    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);
    //-------------------------------------------------------

    useEffect(() => {
        if (search.length == 0) {
            dataTablaMarca(search);
        }

    }, [search]);

    const dataTablaMarca = (search) => {

        setHidden(false);
        TransporteService.tablaMarca(search).then(res => {
            setItems(res.data.items);
            setTotal(res.data.total);
            setHidden(true);
        }).catch((err) => {
            alert(err.message);
            // setHidden(true);
        })

    }

    const CrearMarca = () => {

        setVerLoading(true);
        setVerLoadingBoton(true);
        TransporteService.postMarca(modelMarca)
            .then((res) => {
                if (res.status == 200) {
                    // alert("Se registró correctamente!!");
                    setMensajeTransaccion("Se ha creado correctamente");
                    setTipoMensaje(2);
                    setVerLoading(false);
                    setVerLoadingBoton(true);
                    // 
                    // setItems(res.data.detalle);
                }

            }).catch(function (error) {
                if (error.response) {
                    // alert(e.message);
                    setMensajeTransaccion(error.response.data.detail)
                    setTipoMensaje(1);
                    setVerLoading(false);
                }
            });
    }

    const ActualizarMarca = () => {

        console.log("************ marca ******************")
        console.log(modelItem);

        setVerLoading(true);
        setVerLoadingBoton(true);
        TransporteService.putMarca(modelItem)
            .then((res) => {
                if (res.status == 200) {
                    // alert("Se registró correctamente!!");
                    setMensajeTransaccion("Se ha actualizado correctamente");
                    setTipoMensaje(2);
                    setVerLoading(false);
                    setVerLoadingBoton(true);
                }

            }).catch(function (error) {
                if (error.response) {
                    // alert(e.message);
                    setMensajeTransaccion(error.response.data.detail)
                    setTipoMensaje(1);
                    setVerLoading(false);
                }
            });
    }

    const EliminarMarca = (marcaId: string) => {

        toggleHideWaitEliminar()
        TransporteService.deleteMarca(marcaId)
            .then((res) => {
                if (res.status == 200) {
                    // alert("Se registró correctamente!!");
                    toggleHideWaitEliminar()
                    toggleHideSuccessEliminar()
                    dataTablaMarca(search);
                }

            }).catch(function (error) {
                if (error.response) {
                    toggleHideWaitEliminar()
                    setMensajeTransaccion(error.response.data.detail)
                    toggleHideErrorEliminar()
                }
            });
    }

    const _onChangeText = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let nameInput = event.currentTarget.getAttribute("name");

                switch (nameInput) {
                    case "marca":

                        setModelMarca({
                            ...modelMarca,
                            nombre: newValue.toUpperCase(),
                        });

                        break;
                    case "codigoMarca":
                        setModelMarca({
                            ...modelMarca,
                            codigo: newValue,
                        });

                        break;

                    default:
                        break;
                }
            }
        }
    };

    const _onChangeTextEditar = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let nameInput = event.currentTarget.getAttribute("name");

                switch (nameInput) {
                    case "marca":

                        setModelItem({
                            ...modelItem,
                            nombre: newValue.toUpperCase(),
                        });

                        break;
                    case "codigoMarca":
                        setModelItem({
                            ...modelItem,
                            codigo: newValue,
                        });

                        break;

                    default:
                        break;
                }
            }
        }
    };

    const columns: IColumn[] = [
        {
            key: "column1",
            name: "Marca Vehículo",
            fieldName: "nombre",
            minWidth: 300,
            maxWidth: 300,

        },
        {
            key: "column2",
            name: "Código",
            fieldName: "codigo",
            minWidth: 300,
            maxWidth: 300,
        },
        {
            key: "column3",
            name: "Opciones",
            fieldName: "opciones",
            minWidth: 100,
            // maxWidth: 100,
            isResizable: true,
            isCollapsible: true,
            data: "number",

            onRender: (item: ITablaMarca) => (
                <>
                    <TooltipHost content="Editar">
                        <Icon
                            style={{ cursor: "pointer" }}
                            iconName="Edit"
                            className={classNames.iconFont}
                            onClick={() => {
                                setModelItem({
                                    ...modelItem,
                                    marcaId: item.id,
                                    nombre: item.nombre,
                                    codigo: item.codigo
                                })
                                openMarcaEditar();

                            }}
                        />
                    </TooltipHost>
                    <TooltipHost content="Eliminar">
                        <Icon
                            style={{ color: SharedColors.red10, cursor: "pointer" }}
                            iconName="Delete"
                            className={classNames.iconFont}
                            onClick={() => {
                                setModelItem({
                                    ...modelItem,
                                    marcaId: item.id,
                                    nombre: item.nombre,
                                    codigo: item.codigo
                                })
                                toggleHideAlertEliminar();

                            }}
                        />

                    </TooltipHost>

                </>
            ),
        }


    ]

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_ADD,
                onClick: () => openMarca(),
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => dataTablaMarca(search),
            },
        ];

        return _items;
    };

    const _onChangeSearch = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {

            setSearch(newValue);
        }
    };

    const _onPressSearch = (newValue: string) => {
        dataTablaMarca(newValue);

    };

    const _onClearSearch = () => {
        setSearch("");
    };

    const _goBack = () => {
        dismissMarca();
        dataTablaMarca(search);
        setVerLoadingBoton(false);
        setTipoMensaje(3);
    }

    const _goBackEditar = () => {
        dismissMarcaEditar();
        dataTablaMarca(search);
        setVerLoadingBoton(false);
        setTipoMensaje(3);
    }

    return (

        <>
            <div className="ms-Grid" dir="ltr">

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <HeadingBar
                            title={"Marca de vehículos"}
                            subTitle={"Detalle de marca de vehículos"}
                            searchPlaceholder="Buscar por marca de vehículo"
                            searchChange={_onChangeSearch}
                            searchHandler={_onPressSearch}
                            searchValue={search}
                            onClearHandler={_onClearSearch}
                        ></HeadingBar>
                        <TableBase leftBar={_leftBar()} data={items} column={columns} isLoading={!hidden} pagination={pager} layout={DetailsListLayoutMode.justified} />

                    </div>
                </div>
            </div>
            <div id='panelNuevo'>
                <>
                    <PanelPlantillaFiltro
                        textHeader='Marca de vehículo'
                        subTextHeader='Crear una nueva nueva marca de vehículo'
                        textBoton='Guardar'
                        textBotonDefault='Cancelar'
                        onclickBoton={CrearMarca}
                        onclickBotonDefault={_goBack}
                        openPanel={isOpenMarca}
                        closePanel={dismissMarca}
                        size={PanelType.smallFixedFar}
                        estadoBarraEnvio={verLoadingBoton}
                        nameIconHeader={"Car"}
                        nameIconButton={"Save"}
                    >
                        <>
                            {tipoMensaje == 1 && (
                                <>
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        //onDismiss={p.resetChoice}
                                        dismissButtonAriaLabel="Close"
                                    //truncated={true}
                                    //overflowButtonAriaLabel="See more"
                                    >
                                        <b>{mensajeTransaccion}</b>{" "}
                                    </MessageBar>
                                    <br></br>
                                </>
                            )}
                            {tipoMensaje == 2 && (
                                <>
                                    <MessageBar
                                        actions={
                                            <div>
                                                <MessageBarButton onClick={() => _goBack()}>
                                                    Conforme
                                                </MessageBarButton>
                                            </div>
                                        }
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                    >
                                        {mensajeTransaccion}
                                    </MessageBar>
                                    <br></br>
                                </>
                            )}
                            {!verLoading && (
                                <form action="">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <TextField
                                                    label="Marca:"
                                                    placeholder="Marca de vehículo"
                                                    name="marca"
                                                    value={modelMarca?.nombre}
                                                    onChange={(e, o) => _onChangeText(e, o)}

                                                />
                                                <br />
                                                <TextField
                                                    label="Código: "
                                                    name="codigoMarca"
                                                    value={modelMarca?.codigo}
                                                    onChange={(e, o) => _onChangeText(e, o)}

                                                />

                                            </div>

                                        </div>
                                    </div>
                                </form>
                            )}
                            {verLoading && (
                                <>
                                    <br></br>
                                    <ProgressIndicator
                                        barHeight={5}
                                        label="Enviando información"
                                        description="Favor Espere un momento"
                                    />
                                </>
                            )}
                        </>
                    </PanelPlantillaFiltro>
                </>
            </div>
            <div id='panelEditar'>
                <>
                    <PanelPlantillaFiltro
                        textHeader='Editar marca'
                        subTextHeader={`Edite la marca de vehículo: ${modelItem?.nombre}`}
                        textBoton='Actualizar'
                        textBotonDefault='Cancelar'
                        onclickBoton={ActualizarMarca}
                        onclickBotonDefault={dismissMarcaEditar}
                        openPanel={isOpenMarcaEditar}
                        closePanel={dismissMarcaEditar}
                        size={PanelType.smallFixedFar}
                        estadoBarraEnvio={verLoadingBoton}
                        nameIconHeader={"Car"}

                    >
                        <>
                            {tipoMensaje == 1 && (
                                <>
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        //onDismiss={p.resetChoice}
                                        dismissButtonAriaLabel="Close"
                                    //truncated={true}
                                    //overflowButtonAriaLabel="See more"
                                    >
                                        <b>{mensajeTransaccion}</b>{" "}
                                    </MessageBar>
                                    <br></br>
                                </>
                            )}
                            {tipoMensaje == 2 && (
                                <>
                                    <MessageBar
                                        actions={
                                            <div>
                                                <MessageBarButton onClick={() => _goBackEditar()}>
                                                    Conforme
                                                </MessageBarButton>
                                            </div>
                                        }
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                    >
                                        {mensajeTransaccion}
                                    </MessageBar>
                                    <br></br>
                                </>
                            )}
                            {!verLoading && (
                                <form action="">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <TextField
                                                    label="Marca: "
                                                    placeholder="Marca de vehículo"
                                                    name='marca'
                                                    value={modelItem?.nombre}
                                                    onChange={(e, o) => _onChangeTextEditar(e, o)}
                                                />
                                                <br />
                                                <TextField
                                                    label="Código: "
                                                    placeholder="08"
                                                    name="codigoMarca"
                                                    value={modelItem?.codigo}
                                                    onChange={(e, o) => _onChangeTextEditar(e, o)}

                                                />
                                                <br />
                                                {/* <TextField
                                                    label="Orden:"
                                                    name="ordenCat"
                                                    type="number"
                                                    min={"0"}
                                                    // value={modelMarca?.orden}
                                                    onChange={(e, o) => _onChangeText(e, o)}

                                                /> */}

                                            </div>

                                        </div>
                                    </div>
                                </form>
                            )}
                            {verLoading && (
                                <>
                                    <br></br>
                                    <ProgressIndicator
                                        barHeight={5}
                                        label="Enviando información"
                                        description="Favor Espere un momento"
                                    />
                                </>
                            )}
                        </>
                    </PanelPlantillaFiltro>
                </>
            </div>
            <div id="dialogAlertElimnar">
                <DialogBaseAlert
                    hideDialog={hideAlertEliminar}
                    toggleHideDialog={toggleHideAlertEliminar}
                    title="¡Atención!"
                    subText={`¿Está seguro que desea eliminar a ${modelItem?.nombre}?`}
                    onclickButtonPrimary={() => EliminarMarca(modelItem?.marcaId)}
                />
            </div>
            <div id="dialogWaitElimnar">
                <DialogBaseWait
                    hideDialog={hideWaitEliminar}
                    toggleHideDialog={toggleHideWaitEliminar}
                />
            </div>
            <div id="dialogSuccessElimnar">
                <DialogBaseSuccess
                    hideDialog={hideSuccessEliminar}
                    toggleHideDialog={toggleHideSuccessEliminar}
                    subText={`Se ha eliminado la marca de vehículo ${modelItem?.nombre}?`}
                />
            </div>
            <div id="dialogErrorEliminar">
                <DialogBaseError
                    hideDialog={hideErrorEliminar}
                    toggleHideDialog={toggleHideErrorEliminar}
                    subText={mensajeTransaccion}
                />
            </div>

        </>
    );


}

export { Marca }