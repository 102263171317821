import { Pivot, PivotItem } from "@fluentui/react"
import { useRef, useState } from "react"
import { HeadingBar } from "../../../components/Generic/HeadingBar"
import { HistorialAdicionalLogistica } from "./tab/historialAdicionalLogistica"
import { PendienteAdicionalLogistica } from "./tab/pendienteAdicionalLogistica"

export const TabAdicionalLogistica = () => {
    
    /* Tabs */
    const [tabIndex, setTabIndex] = useState('1');
    
    const childRef: any = useRef(null);
    const [search, setSearch] = useState("")

    /* Event Filter */
    const _onChangeText = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {

            setSearch(newValue)

            switch (tabIndex) {
                case '1':
                case '2':
                     if (newValue === '') {
                         if (childRef) {
                           childRef.current.filterFromParent(newValue);
                         } 
                     }

                    break;
                default:
                    break;
            }
        }
    };

    const _onPress = (newValue: string) => {
        //loadBase(newValue as string);

        switch (tabIndex) {
            case '1':
            case '2':
                console.log(childRef);
                if (childRef) {
                    childRef.current.filterFromParent(newValue);
                }
                break;
            default:
                break;
        }
    };

    const _onClearSearch = () => {

        setSearch("")
        
        switch (tabIndex) {
            case '1':
            case '2':
                 if (childRef) {
                   childRef.current.filterFromParent('');
                 }
                break;
            default:
                break;
        }
    };

    const _onChangeTab = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (item != undefined) {
            let { itemKey } = item.props;
            if (itemKey != undefined) {
                setTabIndex(itemKey);
                
                console.log(itemKey)
            }
        }
    };

    return (
        <>
            <HeadingBar
                title={`Gerencia de Logística`}
                subTitle={`Personal`}
                searchPlaceholder="Buscar por Nombres o DNI"
                searchChange={_onChangeText}
                searchHandler={_onPress}
                searchValue={search ?? ''}
                onClearHandler={_onClearSearch}
            ></HeadingBar>

            <div style={{ padding: '20px', paddingTop: '10px' }}>
                <Pivot
                    aria-label="Pivot Overflow Menu Example"
                    overflowBehavior={'none'}
                    overflowAriaLabel="more items"
                    style={{ paddingTop: '10px' }}
                    onLinkClick={_onChangeTab}
                >
                    <PivotItem itemKey="1" headerText='Pendientes'>
                        <PendienteAdicionalLogistica ref={childRef}></PendienteAdicionalLogistica>
                    </PivotItem>
                    <PivotItem itemKey="2" headerText='Historial'>
                        <HistorialAdicionalLogistica ref={childRef}></HistorialAdicionalLogistica>
                    </PivotItem>
                </Pivot>
            </div>
        </>
    )
}