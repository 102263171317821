import { keyJwt } from "../utils/Helper/helper";

class TokenService {
  getToken() {
    let c = localStorage.getItem(keyJwt);

    if (c && c != '') {
      return c.substring(1, c.length - 1);
    }

    return null;
  }
}

export default new TokenService();
