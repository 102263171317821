import { Pivot, PivotItem } from "@fluentui/react"
import { AccesoPersonaVueloAprobado } from "./agenciaViaje/accesoPersonaVueloAprobado";
import { AccesoPersonaVueloPendiente } from "./transporte/accesoPersonaVueloPendiente";
import { _initialPagination } from "../../../utils/Helper/helper";
import React from 'react';
import { ProgramacionProps } from "../../../types/ITypes";

export const TabAccesoPersonaVuelo = React.forwardRef((props: ProgramacionProps, forwardedRef) => {
    return (
        <>
            <Pivot
                aria-label="Pivot Overflow Menu Example"
                linkFormat={'tabs'}
                overflowBehavior={'none'}
                overflowAriaLabel="more items"
                style={{ paddingTop: '10px' }}
            >
                {/* Rol Empresa Transporte */}
                {props.isSubida &&
                    <PivotItem headerText='Pendientes'>
                        <AccesoPersonaVueloPendiente ref={forwardedRef} programacionId={props.programacionId} isSubida={props.isSubida}></AccesoPersonaVueloPendiente>
                    </PivotItem>
                }
                {/* Rol Agencia Viaje */}
                <PivotItem headerText='Datos Vuelo'>
                    <AccesoPersonaVueloAprobado ref={forwardedRef} programacionId={props.programacionId}></AccesoPersonaVueloAprobado>
                </PivotItem>
            </Pivot>
        </>
    )
})