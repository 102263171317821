import { IColumn } from "@fluentui/react/lib/DetailsList";
import { ICommandBarItemProps, Icon, Stack, TooltipHost } from '@fluentui/react';
import HoteleriaService from '../../services/HoteleriaService';
import { HeadingBar } from '../../components/Generic/HeadingBar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { stackTokensHorizontal, _onRenderHeader, _onRenderRow } from '../../utils/Helper/helper';
import { saveAs } from 'file-saver';
import { IconHelper } from '../../utils/Helper/iconHelper';
import { TableBase } from '../../components/Table/TableBase';
import RepoteBase from "../../components/Generic/ReporteBase";
import { useBoolean } from "@fluentui/react-hooks";
import ThemeBase from "../../utils/Theme/themeBase";
import { PanelCrearHotel } from "./formulario/panelCrearHotel";
import { IHotel } from "../../interfaces/IHotel";
import { ConfigHelper } from "../../utils/Helper/configHelper";

export const TablaHoteles = () => {
    let navigate = useNavigate();

    const [hidden, setHidden] = useState(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [data, setData] = useState<IHotel[]>([]);

    /* v2. Panel crear hotel */
    const [isOpenCrearHotel, { setTrue: openCrearHotel, setFalse: dismissCrearHotel }] = useBoolean(false);

    /* Panel Telerik */
    const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);

    /* Hook */
    useEffect(() => {
        setTitleColumn();
        setup();
    }, []);

    /* Api */
    const dataHotel = () => {
        HoteleriaService.getHoteles(50).then((response) => {
            var d: IHotel[] = response.data.items
            setData(d);
            setHidden(true);
        }).catch((err) => {
            alert(err.message);
            setHidden(true);
        })
    }

    /* Function */
    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Nombre',
                fieldName: 'nombre',
                minWidth: 150,
                maxWidth: 170,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Direccion',
                fieldName: 'direccion',
                minWidth: 180,
                maxWidth: 190,
                isResizable: true,
                data: 'number',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Ubicacion',
                fieldName: 'codigo',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Latitud',
                fieldName: 'latitud',
                minWidth: 90,
                maxWidth: 110,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column5',
                name: 'Longitud',
                fieldName: 'longitud',
                minWidth: 90,
                maxWidth: 110,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column6',
                name: 'Contacto',
                fieldName: 'contacto',
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: "column7",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IHotel) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <>
                                <TooltipHost content={`Editar`}>
                                    <Icon iconName='Edit'
                                        style={{ cursor: 'pointer' }}
                                        className={`${ThemeBase.classes.iconFont}`}
                                        onClick={() => { alert('edit') }} />
                                </TooltipHost>


                                <TooltipHost content={`Eliminar`}>
                                    <Icon iconName='Delete'
                                        style={{ cursor: 'pointer' }}
                                        className={`${ThemeBase.classes.iconFont}`}
                                        onClick={() => { alert('delete') }} />
                                </TooltipHost>

                                <TooltipHost content={`Nada`}>
                                    <Icon iconName='GlobalNavButton'
                                        style={{ cursor: 'pointer' }}
                                        className={`${ThemeBase.classes.iconFont}`}
                                        onClick={() => { alert('nada') }} />
                                </TooltipHost>
                            </>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    }

    const setup = () => {
        setData([]);
        setHidden(false);
        dataHotel();
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_ADD,
                onClick: () => { navigate(`${ConfigHelper.urlBase()}/hoteles/crear`) },
            },
            // {
            //     ...IconHelper.ICON_ADD,
            //     text: 'Add Version 2',
            //     onClick: openCrearHotel,
            // },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: setup,
            },
        ];

        return _items;
    }

    const _rightBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: openTelerik,
            },
        ];

        return _items;
    }

    return (
        <>
            <HeadingBar
                title='Hoteles'
                subTitle='Gestión de Hoteles'
                searchShow={false}
            ></HeadingBar>

            <TableBase leftBar={_leftBar()} rigthBar={_rightBar()} column={column} data={data} isLoading={!hidden}></TableBase>

            {/* v2. Panel Crear Hotel */}
            <PanelCrearHotel isOpen={isOpenCrearHotel} dissmiss={dismissCrearHotel}></PanelCrearHotel>

            {/* Panel - Reporte - Telerik */ }
            <RepoteBase
                headerName="Hoteles"
                NameReport="MOVReporteHoteles.trdp"
                openTelerik={isOpenTelerik}
                dismissTelerik={dismissTelerik}
            ></RepoteBase>

        </>
    )
}