import { Panel, PanelType, PrimaryButton, ProgressIndicator, Stack, TextField } from "@fluentui/react"
import React, { useEffect, useState } from "react";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import { CustomMessageBar, typeMessage } from "../../../components/MessageBar/customMessageBar";
import { IEmpresa, IUpdateEmpresa } from "../../../interfaces/IEmpresa";
import EmpresaService from "../../../services/empresaService";
import { splitExceptionDetail, stackTokens } from "../../../utils/Helper/helper";
import { MessageHelper } from "../../../utils/Helper/messageHelper";
import ThemeBase from "../../../utils/Theme/themeBase";

const _empresa: IEmpresa = null!;

export const ActualizarEmpresa = ({ empresa = _empresa, isOpen = false, dismiss = () => { }, reloadParent = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Form */
    const [form, setForm] = useState<IUpdateEmpresa>(null!);

    /* Hook */
    useEffect(() => {
        if (empresa) {
            setForm({
                empresaId: empresa.empresaId,
                nombre: empresa.nombre as string,
                nombreCorto: empresa.nombreCorto as string,
            })
        }
    }, [isOpen])

    /* Api */
    const actualizar = () => {
        setIsSend(true);
        setChoice(undefined);

        EmpresaService.actualizaEmpresa(form)
            .then((res) => {
                setIsSend(false)
                if (res.status == 200) {
                    reloadParent();
                    setChoice('send');
                    reloadParent();
                }
            })
            .catch((e) => {
                setIsSend(false)
                setChoice('error')
                settextError(splitExceptionDetail(e))
            });
    }

    /* Event */
    const _onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let { name } = event?.target as HTMLInputElement;

                switch (name) {
                    case 'nombre':
                        setForm({
                            ...form,
                            nombre: newValue,
                        })
                        break;
                    case 'nombreCorto':
                        setForm({
                            ...form,
                            nombreCorto: newValue,
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Render Footer */
    const onRenderFooter = React.useCallback(
        () => (
            <>
                <ButtonForm register={actualizar} textRegister='Actualizar' disabledRegister={isSend} back={dismiss}></ButtonForm>
            </>
        ),
        [empresa, form, dismiss, isSend]
    );

    return (
        <>
            <Panel
                type={PanelType.medium}
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText='Actualizar Empresa'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                            <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                            {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                            {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                resetChoice();
                            }}></CustomMessageBar>}
                        </div>
                    </div>
                </div>

                <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                    <div className="ms-Grid" dir="ltr" style={{ paddingTop: '10%' }}>
                        <Stack tokens={stackTokens}>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        name='nombre'
                                        label='Nombre'
                                        multiline
                                        rows={3}
                                        value={form ? form.nombre : ''}
                                        autoComplete='off'
                                        onChange={_onChangeText}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        name='nombreCorto'
                                        value={form ? form.nombreCorto : ''}
                                        label='Nombre Corto'
                                        autoComplete='off'
                                        onChange={_onChangeText}
                                    />
                                </div>
                            </div>
                        </Stack>
                    </div>

                </form>

            </Panel>
        </>
    )
}