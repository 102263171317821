import {
  Button,
  ButtonProps,
  Input,
  Label,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  makeStyles,
  shorthands,
  useId,
} from "@fluentui/react-components";
import {
  IPoliticas,
  ICambiarContrasenia,
} from "../../../../asistencia/src/Interfaces/base/IBase";
import {
  CONTIENE_MAYUSCULA,
  CONTIENE_MINUSCULA,
  CONTIENE_NO_ALFANUMERICO,
  CONTIENE_NUMERO,
  EXPRESION_EMAIL,
} from "../../utils/Helper/helper";
import { useEffect, useState } from "react";
import { Eye24Filled, Eye24Regular } from "@fluentui/react-icons";
import { PanelType, ProgressIndicator, Stack } from "@fluentui/react";
import usuarioServicio from "../../services/UsuarioService";
import { PanelPlantilla } from "../PanelUpload/PanelPlantilla";
import { PanelPlantillaV2 } from "../PanelUpload/PanelPlantillaV2";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
    // Stack the label above the field (with 2px gap per the design system)
    "> div": {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("2px"),
    },
  },
  wrapper: {
    columnGap: "15px",
    display: "flex",
  },
});

export const CambiarContrasenia = (props: any) => {
  //let { urlLogin } = useParams();

  //const paternUrl = EXPRESION_URL;
  const emailId = useId("input-email");
  const urlId = useId("input-url");
  const passwordId = useId("input-password");
  const confPasswordId = useId("input-confPassword");
  const styles = useStyles();

  const [email, setEmail] = useState(false);
  const [isContenedor, setIsContenedor] = useState(false);
  const [mostarComponent, setMostrarComponet] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isEnviado, setIsEnviado] = useState(false);
  const [isError, setIsError] = useState(false);
  const [textError, settextError] = useState("-");

  const [contraseniaActual, setContraseniaActual] = useState("");
  const [mostrarContraseniaActual, setMostrarContraseniaActual] =
    useState(false);
  const mostrarContraseniaActualTemporal = () => {
    setMostrarContraseniaActual(true);
  };
  const ocultarContraseniaActualTemporal = () => {
    setMostrarContraseniaActual(false);
  };

  const [contrasenia, setContrasenia] = useState("");
  const [mostrarContrasenia, setMostrarContrasenia] = useState(false);
  const mostrarContraseniaTemporal = () => {
    setMostrarContrasenia(true);
  };
  const ocultarContraseniaTemporal = () => {
    setMostrarContrasenia(false);
  };
  const [confContrasenia, setConfContrasenia] = useState("");
  const [mostrarConfContrasenia, setMostrarConfContrasenia] = useState(false);
  const mostrarConfContraseniaTemporal = () => {
    setMostrarConfContrasenia(true);
  };
  const ocultarConfContraseniaTemporal = () => {
    setMostrarConfContrasenia(false);
  };

  const [coincideContraseniaActual, setCoincideContraseniaActual] =
    useState(false);
  const [reqDigito, setReqDigito] = useState(true);
  const [reqMinuscula, setReqMinuscula] = useState(true);
  const [reqNoAlfanumerico, setReqNoAlfanumerico] = useState(true);
  const [reqMayuscula, setReqMayuscula] = useState(true);
  const [cumpleLongitud, setCumpleLongitud] = useState(false);
  const [cumplePoliticas, setCumplePoliticas] = useState(false);

  const [coincideContrasenia, setCoincideContrasenia] = useState(true);
  const [disableForm, setDisableForm] = useState(true);
  const [emailEnviar, setEmailEnviar] = useState("");

  const [politicas, setPoliticas] = useState<IPoliticas>(null!);
  //   const [politicas, setPoliticas] = useState<IPoliticas>({
  //     longitud: 12,
  //     requiereDigito: true,
  //     requiereMinuscula: true,
  //     requiereNoAlfanumerico: true,
  //     requiereMayuscula: true,
  //   });

  const TextButton: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        {...props}
        appearance="transparent"
        icon={mostrarContraseniaActual ? <Eye24Regular /> : <Eye24Filled />}
        size="small"
        //onClick={toggleMostrarContrasenia}
        onMouseDown={mostrarContraseniaActualTemporal}
        onMouseUp={ocultarContraseniaActualTemporal}
        onMouseLeave={ocultarContraseniaActualTemporal}
      />
    );
  };

  const TextButton1: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        {...props}
        appearance="transparent"
        icon={mostrarContrasenia ? <Eye24Regular /> : <Eye24Filled />}
        size="small"
        //onClick={toggleMostrarContrasenia}
        onMouseDown={mostrarContraseniaTemporal}
        onMouseUp={ocultarContraseniaTemporal}
        onMouseLeave={ocultarContraseniaTemporal}
      />
    );
  };

  const TextButton2: React.FC<ButtonProps> = (props) => {
    return (
      <Button
        {...props}
        appearance="transparent"
        icon={mostrarConfContrasenia ? <Eye24Regular /> : <Eye24Filled />}
        size="small"
        //onClick={toggleMostrarContrasenia}
        onMouseDown={mostrarConfContraseniaTemporal}
        onMouseUp={ocultarConfContraseniaTemporal}
        onMouseLeave={ocultarConfContraseniaTemporal}
      />
    );
  };

  const handleChangeContraseniaActual = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (value != undefined) {
    }
    setContraseniaActual(value);
  };

  const handleChangeContrasenia = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (value != undefined) {
    }
    setContrasenia(value);
  };

  const handleChangeConfirmarContrasenia = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    if (value != undefined) {
    }
    setConfContrasenia(value);
  };

  const validarInputs = () => {
    if (politicas != null) {
      if (confContrasenia.length >= 1) {
        setConfContrasenia("");
      }
      if (contrasenia != "" && contrasenia == contraseniaActual) {
        setCoincideContraseniaActual(true);
      } else setCoincideContraseniaActual(false);

      if (contrasenia.length >= politicas.longitud) {
        setCumpleLongitud(true);
      } else setCumpleLongitud(false);

      if (politicas.requiereDigito) {
        if (CONTIENE_NUMERO.test(contrasenia)) {
          setReqDigito(true);
        } else setReqDigito(false);
      } else setReqDigito(true);

      if (politicas?.requiereMinuscula) {
        if (CONTIENE_MINUSCULA.test(contrasenia)) {
          setReqMinuscula(true);
        } else setReqMinuscula(false);
      } else setReqMinuscula(true);

      if (politicas?.requiereMayuscula) {
        if (CONTIENE_MAYUSCULA.test(contrasenia)) {
          setReqMayuscula(true);
        } else setReqMayuscula(false);
      } else setReqMayuscula(true);

      if (politicas?.requiereNoAlfanumerico) {
        if (CONTIENE_NO_ALFANUMERICO.test(contrasenia)) {
          setReqNoAlfanumerico(true);
        } else setReqNoAlfanumerico(false);
      } else setReqNoAlfanumerico(true);
    }
  };

  const validarPoliticas = () => {
    if (
      reqDigito &&
      reqMinuscula &&
      reqNoAlfanumerico &&
      reqMayuscula &&
      cumpleLongitud
    ) {
      setCumplePoliticas(true);
    } else setCumplePoliticas(false);
  };

  const validarForm = () => {
    if (confContrasenia != contrasenia) {
      setCoincideContrasenia(false);
    } else setCoincideContrasenia(true);

    if (
      contraseniaActual != "" &&
      contrasenia != "" &&
      confContrasenia != "" &&
      confContrasenia == contrasenia &&
      cumplePoliticas == true &&
      coincideContraseniaActual == false
    ) {
      setDisableForm(false);
    } else setDisableForm(true);
  };

  //Setear datos PoliticasContrasenia
  const dataApiPoliticas = () => {
    usuarioServicio.politicasContrasenia().then((res) => {
      if (res.status == 200) {
        // let arrays: IItemComboBaseDTO[] = res.data;
        // setComboGuardia(arrays);
        setPoliticas(res.data);
      }
    });
  };

  function actualizarContrasenia() {
    const newCambio: ICambiarContrasenia = {
      email: emailEnviar,
      contrasenia: contraseniaActual,
      contraseniaNueva: contrasenia,
      //token: token,c
    };
    setIsError(false);
    setIsSend(true);
    setIsContenedor(true);
    setDisableForm(true);

    usuarioServicio
      .cambiarContrasenia(newCambio)
      .then((res) => {
        if (res.status == 200) {
          setIsSend(false);
          //alert("Contraseña cambiada...");
          setContraseniaActual("");
          setContrasenia("");
          setConfContrasenia("");
          setDisableForm(true);
          setIsEnviado(true);
          //var newurl = cambiarUrl(urlLogin!, true);
          let urlLogin = `/login`; //console.log("Redirigir a :", newurl);
          if (urlLogin != undefined) {
            window.location.assign(urlLogin);
          } else {
            alert(
              "Dirijase al inicio de sesion e ingrese con sus credenciales que actualizó..."
            );
          }
        }
      })
      .catch((e) => {
        setIsEnviado(false);
        setIsError(true);
        if (e.response.status == 500) {
          //alert(e.response.data.detail);
          settextError(e.response.data.detail);
        }
        if (e.response.status == 401) {
          // alert(
          //   "No cuenta con autorización, Dirijase a la pagina de inicio de sesion "
          // );
          settextError("No cuenta con autorización");
        }
        //settextError(splitExceptionDetailMensaje(e));
        setContraseniaActual("");
        setContrasenia("");
        setConfContrasenia("");
        setIsSend(false);
        setTimeout(() => {
          //props.onDismissPanelCrear();
          //cleanInputs();
          setIsContenedor(false);
        }, 3000);
      });
  }

  useEffect(() => {
    if (props.isOpen) {
      dataApiPoliticas();
    }
  }, [props.isOpen]);

  useEffect(() => {
    validarInputs();
  }, [contrasenia, contraseniaActual]);

  useEffect(() => {
    validarPoliticas();
  }, [
    reqDigito,
    reqMinuscula,
    reqNoAlfanumerico,
    reqMayuscula,
    cumpleLongitud,
  ]);

  useEffect(() => {
    validarForm();
  }, [confContrasenia]);

  //   useEffect(() => {
  //     if (urlLogin !== "" || urlLogin !== undefined) {
  //       //let url = cambiarUrl(urlLogin!, true);
  //       let url = "link login";
  //       console.log("url login", url);
  //       //   if (!paternUrl.test(url!)) {
  //       //     alert("Ingrese URL Correcta.");
  //       //     setMostrarComponet(true);
  //       //     return;
  //       //   }
  //     }
  //     //}, [urlLogin, paternUrl]);
  //   }, [urlLogin]);

  useEffect(() => {
    if (props.email != undefined) {
      if (EXPRESION_EMAIL.test(props.email)) {
        setEmailEnviar(props.email);
      }
      //else alert("Email no válido");
    }
    //else alert("Email no válido");
  }, [props.email]);

  const _goBack = () => {
    setIsError(false);
    props.onDismiss();
  };

  return (
    <>
      <PanelPlantillaV2
        textHeader="Cambiar contraseña"
        subTextHeader=""
        textBoton="Actualizar"
        textBotonDefault="Cancelar"
        onclickBoton={actualizarContrasenia}
        onclickBotonDefault={_goBack}
        // openPanel={props.onClickBoton}
        openPanel={props.isOpen}
        closePanel={_goBack}
        size={PanelType.medium}
        disabledBoton={disableForm} //defecto false
        // estadoBarraEnvio={verLoadingBoton} //defecto false
        estadoBarraEnvio={false} //defecto false
        numeroDeBotones={2}>
        <div
          style={{
            justifyContent: "center",
            // height: "100vh",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10%",
            }}>
            <div>
              {/* <pre>{JSON.stringify("--------DATA--", null, 2)}</pre>
              <pre>{JSON.stringify(contraseniaActual, null, 2)}</pre>
              <pre>{JSON.stringify(contrasenia, null, 2)}</pre>
              <pre>{JSON.stringify(emailEnviar, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(textError, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(token, null, 2)}</pre> */}
              <Stack
                style={{
                  display: "grid",
                  //alignItems: "center",
                  width: "300px",
                  justifyContent: "space-between",
                }}>
                <div
                  style={{
                    //display: "grid",
                    justifyContent: "center",
                    //flexDirection: "column",
                    rowGap: "20px",
                    width: "320px",
                  }}>
                  <div
                    // hidden={false}
                    hidden={!isSend}
                    // style={themeBase.notPaddingSide}
                  >
                    <ProgressIndicator
                      // description={MessageHelper.MESSAGE_LOADING}
                      description={"Cargando"}
                      barHeight={3}
                    />
                  </div>
                  <div hidden={!isEnviado}>
                    <MessageBar intent="success">
                      <MessageBarBody>
                        <MessageBarTitle>
                          Actualización exitosa:
                        </MessageBarTitle>
                        Se actualizó correctamenta la contraseña.
                      </MessageBarBody>
                      {/* <MessageBarActions
                      containerAction={
                        <Button aria-label="dismiss" appearance="transparent" />
                      }>
                      <Button>Action</Button>
                    </MessageBarActions> */}
                    </MessageBar>
                  </div>
                  <div hidden={!isError}>
                    {/* <div hidden={false}> */}
                    {/* ["info", "warning", "error", "success"]; */}
                    <MessageBar intent="error">
                      <MessageBarBody>
                        <MessageBarTitle>Error:</MessageBarTitle>
                        {textError}
                      </MessageBarBody>
                      {/* <MessageBarActions
                      containerAction={
                        <Button aria-label="dismiss" appearance="transparent" />
                      }>
                      <Button>Action</Button>
                    </MessageBarActions> */}
                    </MessageBar>
                  </div>
                </div>
              </Stack>
              <br />
              <Stack
                style={{
                  display: "grid",
                  //alignItems: "center",
                  width: "320px",
                  //justifyContent: "space-between",
                }}>
                {/* <div style={{ width: "100%", textAlign: "center" }}>
                  <Label size="large">Cambiar Contraseña</Label>
                </div> */}
                <br />
                <Stack.Item
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <Label size="medium" htmlFor={passwordId}>
                    Contraseña actual
                  </Label>

                  <Input
                    contentAfter={<TextButton />}
                    //type="password"
                    type={mostrarContraseniaActual ? "text" : "password"}
                    // defaultValue="password"
                    required
                    id={passwordId}
                    value={contraseniaActual}
                    onChange={handleChangeContraseniaActual}
                  />
                </Stack.Item>
                <br />
                <Stack.Item
                  style={{
                    width: "100%",
                    display: "grid",
                    //alignItems: "center",
                    //justifyContent: "space-between",
                    //justifyContent: "center",
                  }}>
                  <Label size="medium" htmlFor={passwordId}>
                    Nueva Contraseña
                  </Label>
                  <Input
                    contentAfter={<TextButton1 />}
                    //type="password"
                    type={mostrarContrasenia ? "text" : "password"}
                    // defaultValue="password"
                    required
                    id={passwordId}
                    value={contrasenia}
                    onChange={handleChangeContrasenia}
                  />
                </Stack.Item>
                <Stack.Item
                  style={{
                    width: "100%",
                    display: "grid",
                    //alignItems: "center",
                    //justifyContent: "space-between",
                    //justifyContent: "center",
                  }}>
                  <div>
                    <div hidden={!coincideContraseniaActual}>
                      <Label
                        required
                        style={{ color: "red", fontSize: "smaller" }}>
                        - La nueva contraseña no puede ser igual a la actual
                      </Label>
                    </div>
                    <div hidden={cumpleLongitud}>
                      <Label
                        required
                        style={{ color: "red", fontSize: "smaller" }}>
                        - Longitud mínima de {politicas?.longitud} caracteres
                      </Label>
                    </div>
                    <div hidden={reqNoAlfanumerico}>
                      <Label
                        required
                        style={{ color: "red", fontSize: "smaller" }}>
                        - Se requiere al menos un carácter no alfanumérico
                      </Label>
                    </div>
                    <div hidden={reqMinuscula}>
                      <Label
                        required
                        style={{ color: "red", fontSize: "smaller" }}>
                        - Se requiere al menos una letra minúscula
                      </Label>
                    </div>
                    <div hidden={reqMayuscula}>
                      <Label
                        required
                        style={{ color: "red", fontSize: "smaller" }}>
                        - Se requiere al menos una letra mayúscula
                      </Label>
                    </div>
                    <div hidden={reqDigito}>
                      <Label
                        required
                        style={{ color: "red", fontSize: "smaller" }}>
                        - Se requiere al menos un dígito numérico
                      </Label>
                    </div>
                  </div>
                </Stack.Item>

                <br />

                <Stack.Item
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}>
                    <Label size="medium" htmlFor={passwordId}>
                      Confirmar Contraseña
                    </Label>
                    <Input
                      // disabled={true}
                      disabled={!cumplePoliticas}
                      contentAfter={<TextButton2 />}
                      //type="password"
                      type={mostrarConfContrasenia ? "text" : "password"}
                      required
                      //defaultValue="password"
                      id={confPasswordId}
                      value={confContrasenia}
                      onChange={handleChangeConfirmarContrasenia}
                    />
                  </div>
                  <div hidden={coincideContrasenia}>
                    <Label
                      required
                      style={{ color: "red", fontSize: "smaller" }}>
                      - La contraseña no coincide
                    </Label>
                  </div>
                </Stack.Item>
                <br />
                {/* <Stack.Item
                  style={{
                    width: "100%",
                    //display: "block",
                    //flexDirection: "column",
                    //justifyContent: "space-between",
                  }}>
                  <Button
                    style={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                      alignContent: "center",
                      alignItems: "center",
                      //flexDirection: "column",
                      //justifyContent: "space-between",
                    }}
                    appearance="primary"
                    type="submit"
                    disabled={disableForm}
                    // icon={<CalendarMonthRegular />}
                    onClick={actualizarContrasenia}>
                    Guardar
                  </Button>
                </Stack.Item> */}
              </Stack>
            </div>
          </div>
        </div>
      </PanelPlantillaV2>
    </>
  );
};
