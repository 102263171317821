import { Ifilter } from "../interfaces/IFilter";
import { ICreateRoster } from "../interfaces/IRoster";
import { fetchMovilizacion } from "./http-common";

const urlRoster = 'Roster';

const all = (filter: Ifilter) => {
    return fetchMovilizacion.get(`${urlRoster}`, { params: filter });
}

/* Crea / actualiza */
const crearRoster = (data: ICreateRoster) => {
    return fetchMovilizacion.post(`${urlRoster}/`, data);
}

const elimminarRoster = (id: string) => {
    return fetchMovilizacion.delete(`${urlRoster}/${id}`);
}


const RosterService = {
    all,
    crearRoster,
    elimminarRoster,
}

export default RosterService;