import { DatePicker, ICommandBarItemProps } from "@fluentui/react";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { useEffect, useRef, useState } from "react";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import { urlTelerik } from "../../services/http-common";
import { _formatDatePrefix } from "../../utils/Helper/datetimeHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { IReportTelerik } from "../../interfaces/base/IBase";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { dayBeforeReport } from "../../utils/Helper/helper";
import TokenService from "../../services/TokenService";

const NameReport = "MOVCuadroMandoProgramaciones.trdx";

const CuadroMandoProgramacion = () => {
  let t = TokenService.getToken();
  let viewer;

  let end = new Date();
  let start = new Date(new Date().setDate(end.getDate() - dayBeforeReport));

  const [inicio, setInicio] = useState<Date | undefined>(start);
  const [fin, setFin] = useState<Date | undefined>(end);

  const [reportTelerik, setReportTelerik] = useState<IReportTelerik>({
    reportName: NameReport,
    param: {
      fechInicio: start,
      fechFin: end,
    },
  });

  /* Hook */
  useEffect(() => {
    let d = reportTelerik;
    let p = {
      ...d.param,
      fechInicio: inicio,
    };

    setReportTelerik({
      ...reportTelerik,
      param: p,
    });
  }, [inicio]);

  useEffect(() => {
    let d = reportTelerik;
    let p = {
      ...d.param,
      fechFin: fin,
    };

    setReportTelerik({
      ...reportTelerik,
      param: p,
    });
  }, [fin]);

  /* Function */
  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        key: "FechaInicio",
        onRender: () => (
          <>
            <DatePicker
              placeholder="Fecha Inicio *"
              allowTextInput
              ariaLabel="Select a date. Input format is day slash month slash year."
              value={inicio}
              onSelectDate={setInicio as (date?: any) => void}
              formatDate={(d) => _formatDatePrefix("F. Inicio", d)}
              style={{
                paddingTop: "6px",
                width: "300px",
              }}
            />
          </>
        ),
      },
      {
        key: "FechaFin",
        onRender: () => (
          <>
            <DatePicker
              placeholder="Fecha Fin *"
              allowTextInput
              ariaLabel="Select a date. Input format is day slash month slash year."
              value={fin}
              onSelectDate={setFin as (date?: any) => void}
              formatDate={(d) => _formatDatePrefix("F. Fin", d)}
              style={{
                paddingLeft: "5px",
                paddingTop: "6px",
                width: "300px",
              }}
            />
          </>
        ),
      },
    ];
    return _items;
  };

  const _rigthBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_SEARCH,
        onClick: () => {
          //console.log("------reportTelerik------", reportTelerik);
          //console.log("------viewer------", viewer);

          let rs = {
            report: reportTelerik.reportName,
            parameters: reportTelerik.param,
          };

          viewer.setReportSource(rs);
          viewer.refreshReport();
        },
      },
    ];

    return _items;
  };

  const EnviarToken = () => {
    if (viewer.current) {
      const viewerObject = viewer.current.viewerObject;
      viewerObject.authenticationToken(t);
    }
  };

  useEffect(() => {
    EnviarToken();
  }, [t]);

  return (
    <>
      <div>
        <div className="ms-Grid" dir="ltr">
          <br />
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <div
                className="ms-fontSize-18"
                style={{ color: ThemeBase.theme.palette.neutralSecondary }}>
                Cuadro Mando Programaciones
              </div>
            </div>
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">{/* Nada */}</div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <CustomCommandBar
                item={_leftBar()}
                farItem={_rigthBar()}
                style={{
                  ...ThemeBase.notPaddingSide,
                  paddingTop: "10px",
                }}></CustomCommandBar>
            </div>
          </div>
          <br />
        </div>

        <div className="ms-Grid" dir="ltr">
          <TelerikReportViewer
            ref={(el) => (viewer = el)}
            serviceUrl={urlTelerik}
            reportSource={{
              parameters: reportTelerik.param,
              report: reportTelerik.reportName,
            }}
            viewerContainerStyle={ThemeBase.telerikStyle}
            viewMode="INTERACTIVE"
            scaleMode="FIT_PAGE_WIDTH"
            scale={1.0}
            enableAccessibility={true}
            authenticationToken={t}
          />
        </div>
      </div>
    </>
  );
};

export default CuadroMandoProgramacion;
