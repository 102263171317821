import {
  ActivityItem,
  ComboBox,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IActivityItemProps,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  Icon,
  Link,
  MarqueeSelection,
  Panel,
  Persona,
  PersonaSize,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Spinner,
  TooltipHost,
} from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import {
  IBase,
  IException,
  IItemComboBaseDTO,
  IReportTelerik,
  ISearch,
} from "../../interfaces/base/IBase";
import {
  IFilterAsignacion,
  IFilterPersonaTransporte,
  IProgramacionPersona,
} from "../../interfaces/IProgramacion";
import PersonaService from "../../services/PersonaService";
import {
  baseImageUrl,
  IndexNone,
  ItemNoneSelect,
  splitExceptionDetail,
  _initialFilter,
  _initialPagination,
} from "../../utils/Helper/helper";
import ThemeBase from "../../utils/Theme/themeBase";
import { IColumn, Selection } from "@fluentui/react/lib/DetailsList";
import React from "react";
import ConfiguracionService from "../../services/configuracionService";
import OrganigramaService from "../../services/organigramaService";
import ProgramacionBusService from "../../services/programacionBusService";
import { IDataCollection, IPagination } from "../../interfaces/base/ITable";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { useBoolean } from "@fluentui/react-hooks";
import { IAsignarBus, IBusVigente } from "../../interfaces/IProgramacionBus";
import { Ifilter } from "../../interfaces/IFilter";
import { PaginationFooter } from "../../components/PaginationFooter/PaginationFooter";
import {
  CustomMessageBar,
  typeMessage,
} from "../../components/MessageBar/customMessageBar";
import { MessageHelper } from "../../utils/Helper/messageHelper";
import RepoteBase from "../../components/Generic/ReporteBase";
import { AuthContext } from "../../auth/context/auth-context";
import { IUserAuthenticate } from "../../auth/interface/IAuth";
import {
  CLAIM_EMPRESA_USUARIO_ID,
  isRoleAccessAll,
  roleAdmin,
  ROLE_TRANSPORTE,
} from "../../utils/Helper/roleHelper";
import AuthService from "../../services/AuthService";
import UbicacionService from "../../services/ubicacionesServices";
import { IFilterUbicacion } from "../../interfaces/filter/IFilterUbicacion";
import { UbicacionHelper } from "../../utils/Helper/ubicacionHelper";
import ProgramacionService from "../../services/ProgramacionService";
import { Label } from "@fluentui/react-components";

const _filter: IFilterAsignacion = {
  ..._initialFilter(),
};

// A. ADMIN
// B. SSEE
const reportByRole = (access: IUserAuthenticate | null, id: string) => {
  let role = access?.role ?? [];

  let reportName = "";
  let param = {};

  let isRolAdmin = isRoleAccessAll(role, roleAdmin());

  if (isRolAdmin) {
    reportName = "MOVPersonaReservaTransporteADMIN.trdx";
  } else {
    let isRolTransporte = isRoleAccessAll(role, [ROLE_TRANSPORTE]);

    if (isRolTransporte) {
      reportName = "MOVPersonaReservaTransporte.trdx";
      param = {
        EmpresaId: AuthService.getInfoToken(CLAIM_EMPRESA_USUARIO_ID),
      };
    }
  }

  let report: IReportTelerik = {
    reportName: reportName,
    param: {
      programacionId: id,
      ...param,
    },
  };

  return report;
};

export const ListarPendienteAsignarBus = ({
  filter = _filter,
  reload = false,
}) => {
  /* Combo */
  const [bus, setBus] = useState<any[]>([]);
  const [campamento, setCampamento] = useState<IItemComboBaseDTO[]>([]);
  const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
  const [gerencia, setGerencia] = useState<IItemComboBaseDTO[]>([]);
  const [lugarMovilizacion, setLugarMovilizacion] = useState<
    IItemComboBaseDTO[]
  >([]);

  /* Ref */
  const refBus = React.useRef<IComboBox>(null);
  const refCampamento = React.useRef<IComboBox>(null);
  const refEmpresa = React.useRef<IComboBox>(null);
  const refGerencia = React.useRef<IComboBox>(null);
  const refLugarMovilizacion = React.useRef<IComboBox>(null);
  const [cambio, setCambio] = useState(false);

  const _openBus = React.useCallback(() => refBus.current?.focus(true), []);
  const _openCampamento = React.useCallback(
    () => refCampamento.current?.focus(true),
    []
  );
  const _openEmpresa = React.useCallback(
    () => refEmpresa.current?.focus(true),
    []
  );
  const _openGerencia = React.useCallback(
    () => refGerencia.current?.focus(true),
    []
  );
  const _openLugarMovilizacion = React.useCallback(
    () => refLugarMovilizacion.current?.focus(true),
    []
  );

  /* Data */
  const [pendiente, setPendiente] = useState<
    IDataCollection<IProgramacionPersona>
  >(_initialDataCollection);
  const [column, setColumn] = useState<IColumn[]>([]);

  /* Filter */
  const [filterBus, setFilterBus] = useState<IFilterAsignacion>(filter);
  const [filterCampamento, setFilterCampamento] =
    useState<ISearch>(_initialFilter);
  const [filterEmpresa, setFilterEmpresa] = useState<ISearch>(_initialFilter);
  const [filterGerencia, setFilterGerencia] = useState<ISearch>(_initialFilter);
  const [filterLugarMovilizacion, setFilterLugarMovilizacion] =
    useState<ISearch>(_initialFilter);
  const [filterBusVigente, setFilterBusVigente] =
    useState<Ifilter>(_initialFilter); /* Combo */

  /* Panel */
  const [selBusForm, setSelBusForm] = useState<IComboBoxOption>(null!);
  const [isOpenAsignar, { setTrue: openAsignar, setFalse: dismissAsignar }] =
    useBoolean(false);
  const [isOpenFiltro, { setTrue: openFiltro, setFalse: dismissFiltro }] =
    useBoolean(false);

  const { access } = useContext(AuthContext);
  const role = access?.role ?? [];
  const isAdmin = isRoleAccessAll(role, roleAdmin());
  const isTransporte = isRoleAccessAll(role, [ROLE_TRANSPORTE]);

  /* Report */
  let reportTelerik = reportByRole(
    access as IUserAuthenticate,
    filter.programacionId as string
  );

  /* Panel Telerik */
  const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] =
    useBoolean(false);

  /* Message Error & Choice */
  const [isSend, setIsSend] = useState(false);
  const [choice, setChoice] = React.useState<string | undefined>(undefined);
  const resetChoice = React.useCallback(() => setChoice(undefined), []);
  const [textError, settextError] = useState("Error");
  const [filtroTabla, setFiltroTabla] = useState(false);
  /* Selection */
  const [sel, setSel] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const selection = new Selection({
    onSelectionChanged: () => {
      let d: any[] = selection.getSelection();
      let t: IActivityItemProps[] = d.map((x) => {
        return {
          key: `id_${x.programacionPersonaId}`,
          activityDescription: [
            <Link
              key={x.programacionPersonaId}
              className={ThemeBase.activityClass.nameText}
            >
              {x.nombres}
            </Link>,
            <span key={`2_${x.programacionPersonaId}`}> </span>,
            <span
              key={`3_${x.programacionPersonaId}`}
              className={ThemeBase.activityClass.nameText}
            ></span>,
          ],
          activityIcon: <Icon iconName={"ReminderPerson"} />,
          timeStamp: `DNI: ${x.nroDOI}`,
          programacionPersonaBusId: `${x.programacionPersonaBusId}`,
        };
      });

      setSel(t);
    },
    getKey: (item: any) => item.programacionPersonaBusId,
  });

  const [customSelection, setCustomSelection] = useState<any>(selection);
  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  /* Hook */
  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    setup();
  }, [reload]);

  useEffect(() => {
    if (filtroTabla) {
      dataPendiente();
      setFiltroTabla(false);
    }
  }, [filtroTabla]);

  useEffect(() => {
    if (filterCampamento.search != null) {
      dataCampamento();
    }
  }, [filterCampamento]);

  useEffect(() => {
    if (filterEmpresa.search != null) {
      dataEmpresa();
    }
  }, [filterEmpresa]);

  useEffect(() => {
    if (filterGerencia.search != null) {
      dataGerencia();
    }
  }, [filterGerencia]);

  useEffect(() => {
    if (filterLugarMovilizacion.search != null) {
      dataLugarMovilizacion();
    }
  }, [filterLugarMovilizacion]);

  //   useEffect(() => {
  //     if (filterBus.programacionId != undefined) {
  //       console.log(filterBus);
  //       dataPendiente();
  //     }
  //   }, [filterBus]);

  useEffect(() => {
    if (filterBusVigente.search != null) {
      dataBus();
    }
  }, [filterBusVigente]);

  /* Hook selection mark */
  useEffect(() => {
    if (selBusForm) {
      let e = bus.find((x) => x.key == selBusForm.key);
      if (e) {
        markItem(e.disponible);
      }
    }
  }, [pendiente]);

  /* Hook Pagination */
  useEffect(() => {
    setPager((prevPager) => ({
      ...prevPager,
      currentPage: currentPage,
    }));

    if (filterBus) {
      setFilterBus((prevFilter) => ({
        ...prevFilter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      }));
      setCambio(true);
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      // onPageChange: (page) => dataByPager(page),
      // onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  useEffect(() => {
    if (cambio) {
      dataPendiente();
      setCambio(false);
    }
  }, [cambio]);

  const dataByPager = (page) => {
    setTotal(0);

    setPager((prevPager) => ({
      ...prevPager,
      currentPage: page,
    }));

    let updatedFilter: IFilterPersonaTransporte = {
      ...filter,
      skip: (page - 1) * pageSize,
      take: pageSize,
    };

    setFilterBus(updatedFilter);
    setCurrentPage(page);
  };

  /* Api */
  const dataCampamento = () => {
    setCampamento([]);

    const filterDestino: IFilterUbicacion = {
      tipo: UbicacionHelper.TIPO_DESTINO,
      search: filterCampamento.search,
    };

    UbicacionService.ubicacionPorFiltro(filterDestino)
      .then((res) => {
        let t: IItemComboBaseDTO[] = res.data;
        t.unshift(ItemNoneSelect());
        setCampamento(t);
        // let d: IBase[] = res.data.data;

        // let t: IItemComboBaseDTO[] = d.map((x) => {
        //   return {
        //     key: x.id,
        //     text: x.nombre,
        //   };
        // });

        // setDestino(t);
      })
      .catch((e: Error) => {
        console.log(e);
      });

    // PersonaService.getComboCampamento1(filterCampamento).then(res => {
    //     let d: IBase[] = res.data.data;

    //     let t: IItemComboBaseDTO[] = d.map(x => {
    //         return ({
    //             key: x.id,
    //             text: x.nombre,
    //         })
    //     });

    //     t.unshift(ItemNoneSelect());

    //     setCampamento(t);
    // })
    //     .catch((e: Error) => {
    //         console.log(e);
    //     });
  };

  const dataEmpresa = () => {
    setEmpresa([]);
    ConfiguracionService.allEmpresa(filterEmpresa)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          t.unshift(ItemNoneSelect());

          setEmpresa(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataGerencia = () => {
    setGerencia([]);
    OrganigramaService.organigramaByTipo("01", filterGerencia)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          t.unshift(ItemNoneSelect());

          setGerencia(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataLugarMovilizacion = () => {
    ProgramacionService.allLugarMovilizacion1(filterLugarMovilizacion)
      .then((res) => {
        setLugarMovilizacion(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };
  const dataPendiente = () => {
    setIsLoading(true);
    setPendiente(_initialDataCollection);
    ProgramacionBusService.allPendienteAsignacionBus(filterBus)
      .then((res) => {
        if (res.status == 200) {
          setIsLoading(false);
          setPendiente(res.data);
          setTotal(res.data.total);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataBus = () => {
    if (filter.programacionId) {
      ProgramacionBusService.busvigenteV2(
        filter.programacionId,
        filterBusVigente
      )
        .then((res) => {
          if (res.status == 200) {
            let d: IBusVigente[] = res.data;
            let t: any[] = [];

            t = d.map((x) => {
              return {
                key: x.transporteId,
                text: `${x.placa} | ${x.capacidad} | ${x.marca} | ${x.modelo}`,
                disponible: x.disponible,
              };
            });

            t.unshift(ItemNoneSelect());

            setBus(t);
          }
        })
        .catch((e) => {
          alert(e.message);
        });
    }
  };

  const asignarBus = () => {
    setIsSend(true);
    setChoice(undefined);

    let t: IAsignarBus = {
      programacionId: filterBus.programacionId as string,
      busId: selBusForm.key as string,
      programacionPersonaBusLista: sel.map((x) => x.programacionPersonaBusId),
    };

    ProgramacionBusService.asignarBusV2(t)
      .then((res) => {
        setIsSend(false);
        if (res.status == 200) {
          dismissAsignar();
          setup();
          resetChoice();
        }
      })
      .catch((e: IException) => {
        setIsSend(false);
        setChoice("error");
        settextError(splitExceptionDetail(e));
      });
  };

  /* Function */
  const setup = () => {
    setTitleColumn();
    dataBus();
    setIsLoading(false);
    dataCampamento();
    dataEmpresa();
    dataGerencia();
    dataLugarMovilizacion();

    dataPendiente();
  };

  /* Bar Reporte */
  const _rightBarReporte = () => {
    const _items: ICommandBarItemProps[] = [];

    if (!isAdmin) {
      if (isTransporte) {
        _items.push({
          ...IconHelper.ICON_EXCEL,
          onClick: openTelerik,
        });
      }
    }

    return _items;
  };

  const _leftBarReporte = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => setup(),
      },
    ];
    return _items;
  };

  const _leftBarBus = () => {
    const _items: ICommandBarItemProps[] = [
      {
        key: "Bus",
        onRender: () => (
          <>
            <ComboBox
              componentRef={refBus}
              placeholder="Bus"
              style={{
                ...ThemeBase.notPaddingTopCombo,
                width: "450px",
              }}
              allowFreeform
              autoComplete="off"
              useComboBoxAsMenuWidth
              calloutProps={{ directionalHintFixed: true }}
              options={bus}
              onInput={_onInputBus}
              selectedKey={selBusForm ? selBusForm.key : ""}
              onChange={(e, o) => _onChangeCombo("bus", e, o)}
              onBlur={() => _onBlurCombo("bus")}
              required
            />
          </>
        ),
      },
      {
        ...IconHelper.ICON_CHECK,
        text: "Asignar",
        onClick: () => {
          if (sel.length == 0) {
            return alert("Debe seleccionar al menos un item");
          }

          openAsignar();
        },
      },
    ];

    return _items;
  };

  const _filterBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_FILTER,
        text: "Filtrar",
        onClick: () => {
          openFiltro();
        },
      },
    ];

    return _items;
  };

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "x",
        name: "Item",
        fieldName: "nro",
        minWidth: 15,
        maxWidth: 15,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item, index) => {
          // return <span>{(parseInt(index as any) + 1) + ((currentPage - 1) * pageSize)}</span>
          return (
            <span>
              {
                parseInt(index as any) +
                  1 /* + ((currentPage - 1) * pageSize) */
              }
            </span>
          );
        },
      },
      {
        key: "column1",
        name: "Foto",
        ariaLabel:
          "Column operations for File type, Press to sort on File type",
        iconName: "-",
        isIconOnly: true,
        fieldName: "foto",
        minWidth: 30,
        maxWidth: 30,
        onRender: (item: IProgramacionPersona) => (
          <TooltipHost content={`Foto`}>
            <Persona
              text=""
              size={PersonaSize.size24}
              imageUrl={baseImageUrl}
            />
          </TooltipHost>
        ),
      },
      {
        key: "column2",
        name: "Nombre",
        fieldName: "nombres",
        minWidth: 170,
        maxWidth: 170,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return <span style={{ fontSize: "90%" }}>{item.nombres}</span>;
        },
      },
      {
        key: "column3",
        name: "Identificación",
        fieldName: "nroDOI",
        minWidth: 80,
        maxWidth: 90,
        isResizable: true,
        data: "number",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%" }}
            >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
          );
        },
      },
      {
        key: "column4",
        name: "Email",
        fieldName: "email",
        minWidth: 180,
        maxWidth: 180,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: "Celular",
        fieldName: "celular",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column6",
        name: "Gerencia",
        fieldName: "gerencia",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column6.0",
        name: "Lugar Mov.",
        fieldName: "lugarMovilizacion",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column6.1",
        name: "Origen",
        fieldName: "origen",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column6.2",
        name: "Destino",
        fieldName: "destino",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column7",
        name: "Empresa Transportista",
        fieldName: "empresaTransporte",
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column9",
        name: "Empresa Persona",
        fieldName: "empresaPersona",
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column10",
        name: "Proceso - Estado",
        fieldName: "nivelActual",
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      // {
      //     key: "column11",
      //     name: "Estado",
      //     fieldName: "estadoActual",
      //     minWidth: 200,
      //     maxWidth: 200,
      //     isResizable: true,
      //     isCollapsible: true,
      //     data: "number",
      // },
    ];

    setColumn(col);
  };

  const _clearSearch = (type: string) => {
    switch (type) {
      case "bus":
        setSelBusForm(null!);

        setFilterBusVigente({
          ...filterBusVigente,
          search: "",
        });

        markItem(0);
        break;
      case "campamento":
        setFilterCampamento({
          ...filterCampamento,
          search: "",
        });
        break;
      case "empresa":
        setFilterEmpresa({
          ...filterEmpresa,
          search: "",
        });
        break;
      case "gerencia":
        setFilterGerencia({
          ...filterGerencia,
          search: "",
        });
        break;
      case "LugarMovilizacion":
        setFilterLugarMovilizacion({
          ...filterLugarMovilizacion,
          search: "",
        });
        break;
      default:
        break;
    }
  };

  const markItem = (cantidad: number) => {
    const newSelection = customSelection;
    newSelection.setItems(pendiente.items);

    let d = pendiente.items.slice(0, cantidad);

    d.forEach((item) => {
      newSelection.setKeySelected(item.key, true, false);
    });

    setCustomSelection(newSelection);
  };

  /* Event */
  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        let k: string | null = null;

        let isNone = option.key == IndexNone;

        if (isNone) {
          _clearSearch(type);
        }

        switch (type) {
          case "bus":
            if (!isNone) {
              setSelBusForm(option);

              /* ¿como saco el disponible? */
              let e = bus.find((x) => x.key == option.key);

              if (e) {
                markItem(e.disponible);
              }
            }

            break;
          case "campamento":
            if (!isNone) {
              k = String(option.key);
            }

            setFilterBus({
              ...filterBus,
              campamentoId: k,
            });
            break;
          case "empresa":
            if (!isNone) {
              k = String(option.key);
            }

            setFilterBus({
              ...filterBus,
              empresaId: k,
            });
            break;
          case "gerencia":
            if (!isNone) {
              k = String(option.key);
            }
            setFilterBus({
              ...filterBus,
              gerenciaId: k,
            });
            break;
          case "LugarMovilizacion":
            if (!isNone) {
              k = String(option.key);
            }
            //console.log("movilizacion"+k)
            setFilterBus({
              ...filterBus,
              lugarMovilizacionId: k,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const _onBlurCombo = (type: string) => {
    switch (type) {
      case "bus":
        if (selBusForm == undefined && selBusForm != null) {
          markItem(0);
        }
        break;
      case "campamento":
        if (
          (filterBus.campamentoId == "" ||
            filterBus.campamentoId == undefined) &&
          filter.campamentoId != null
        ) {
          setFilterBus({
            ...filterBus,
            campamentoId: null,
          });
        }
        break;
      case "empresa":
        if (
          (filterBus.empresaId == "" || filterBus.empresaId == undefined) &&
          filter.empresaId != null
        ) {
          setFilterBus({
            ...filterBus,
            empresaId: null,
          });
        }
        break;
      case "gerencia":
        if (
          (filterBus.gerenciaId == "" || filterBus.gerenciaId == undefined) &&
          filter.gerenciaId != null
        ) {
          setFilterBus({
            ...filterBus,
            gerenciaId: null,
          });
        }
        break;
      case "LugarMovilizacion":
        if (
          (filterBus.lugarMovilizacionId == "" ||
            filterBus.lugarMovilizacionId == undefined) &&
          filter.lugarMovilizacionId != null
        ) {
          setFilterBus({
            ...filterBus,
            lugarMovilizacionId: null,
          });
        }
        break;
      default:
        break;
    }
  };

  const _onInputEmpresa = (e: React.KeyboardEvent<IComboBox>) => {
    _openEmpresa();
    let { value } = e?.target as HTMLInputElement;

    if (filterBus.empresaId != null) {
      setFilterBus({
        ...filterBus,
        empresaId: null,
      });
    }

    setFilterEmpresa({
      ...filterEmpresa,
      search: value,
    });
  };

  const _onInputLugarMovilizacion = (e: React.KeyboardEvent<IComboBox>) => {
    _openLugarMovilizacion();
    let { value } = e?.target as HTMLInputElement;

    if (filterBus.lugarMovilizacionId != null) {
      setFilterBus({
        ...filterBus,
        lugarMovilizacionId: null,
      });
    }

    setFilterLugarMovilizacion({
      ...filterLugarMovilizacion,
      search: value,
    });
  };

  const _onInputGerencia = (e: React.KeyboardEvent<IComboBox>) => {
    _openGerencia();
    let { value } = e?.target as HTMLInputElement;

    if (filterBus.gerenciaId != null) {
      setFilterBus({
        ...filterBus,
        gerenciaId: null,
      });
    }

    setFilterGerencia({
      ...filterGerencia,
      search: value,
    });
  };

  const _onInputDestino = (e: React.KeyboardEvent<IComboBox>) => {
    _openCampamento();
    let { value } = e?.target as HTMLInputElement;

    if (filterBus.campamentoId != null) {
      setFilterBus({
        ...filterBus,
        campamentoId: null,
      });
    }

    setFilterCampamento({
      ...filterCampamento,
      search: value,
    });
  };

  const _onInputBus = (e: React.KeyboardEvent<IComboBox>) => {
    _openBus();
    let { value } = e?.target as HTMLInputElement;

    setFilterBusVigente({
      ...filterBusVigente,
      search: value,
    });
  };

  const handleFiltrar = () => {
    setFiltroTabla(true);
  };

  /* Footer Panel */
  const _onRenderFooter = React.useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={asignarBus}
          styles={ThemeBase.panelButtonStyle}
          disabled={!selBusForm || isSend}
        >
          Asignar
        </PrimaryButton>
        <DefaultButton onClick={dismissAsignar}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissAsignar, sel, selBusForm, isSend]
  );

  /* Footer Panel Filtro */
  const _onRenderFooterFiltro = React.useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={handleFiltrar}
          styles={ThemeBase.panelButtonStyle}
        >
          Filtrar
        </PrimaryButton>
        <DefaultButton onClick={dismissFiltro}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissFiltro]
  );

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <CustomCommandBar
                  farItem={_rightBarReporte()}
                  item={_leftBarReporte()}
                  style={ThemeBase.notPaddingSide}
                ></CustomCommandBar>
                <CustomCommandBar
                  item={_leftBarBus()}
                  farItem={_filterBar()}
                  style={ThemeBase.notPaddingSide}
                ></CustomCommandBar>

                <div
                  className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                  style={ThemeBase.notPaddingSide}
                >
                  {isLoading && (
                    <div style={{ marginTop: "150px" }}>
                      <Spinner label="Obteniendo Datos..." />
                    </div>
                  )}
                  <div hidden={isLoading}>
                    <MarqueeSelection selection={customSelection}>
                      <DetailsList
                        items={pendiente.items}
                        compact={true}
                        columns={column}
                        selectionMode={SelectionMode.multiple}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                        isHeaderVisible={true}
                        selection={selection}
                        selectionPreservedOnEmptyClick={true}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                      />
                    </MarqueeSelection>

                    <PaginationFooter
                      data={pendiente.items}
                      pagination={pager}
                    ></PaginationFooter>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Panel Asignacion */}
      <Panel
        isOpen={isOpenAsignar}
        onDismiss={dismissAsignar}
        headerText={`Asignar al Bus ${selBusForm ? selBusForm.text : ""}`}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={_onRenderFooter}
        isFooterAtBottom={true}
      >
        <>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                <ProgressIndicator
                  description={MessageHelper.MESSAGE_LOADING}
                  barHeight={3}
                />
              </div>

              <div
                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                style={ThemeBase.notPaddingSide}
              >
                {choice === "error" && (
                  <CustomMessageBar
                    type={typeMessage.TYPE_ERROR}
                    message={textError}
                    dissmiss={resetChoice}
                  ></CustomMessageBar>
                )}
                {choice === "send" && (
                  <CustomMessageBar
                    dissmiss={() => {
                      resetChoice();
                    }}
                  ></CustomMessageBar>
                )}
              </div>
            </div>
          </div>

          <form
            hidden={
              choice === "error" || choice === "send" || isSend ? true : false
            }
          >
            {sel.map((item: { key: string | number }) => (
              <ActivityItem
                {...item}
                key={item.key}
                className={ThemeBase.activityClass.exampleRoot}
              />
            ))}
          </form>
        </>
      </Panel>

      {/* Panel Filtro */}
      <Panel
        isOpen={isOpenFiltro}
        onDismiss={dismissFiltro}
        headerText={`Filtrado`}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={_onRenderFooterFiltro}
        isFooterAtBottom={true}
      >
        <>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <br />
              <div
                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                style={ThemeBase.notPaddingSide}
              >
                <ComboBox
                  label="Destino:"
                  componentRef={refCampamento}
                  placeholder="Ingrese Destino"
                  allowFreeform
                  autoComplete="off"
                  useComboBoxAsMenuWidth
                  calloutProps={{ directionalHintFixed: true }}
                  options={campamento}
                  onInput={_onInputDestino}
                  selectedKey={filterBus.campamentoId}
                  onChange={(e, o) => _onChangeCombo("campamento", e, o)}
                  onBlur={() => _onBlurCombo("campamento")}
                />
                <br />
                <ComboBox
                  label="Empresa:"
                  componentRef={refEmpresa}
                  placeholder="Ingrese Empresa"
                  allowFreeform
                  autoComplete="off"
                  useComboBoxAsMenuWidth
                  calloutProps={{ directionalHintFixed: true }}
                  options={empresa}
                  onInput={_onInputEmpresa}
                  selectedKey={filterBus.empresaId}
                  onChange={(e, o) => _onChangeCombo("empresa", e, o)}
                  onBlur={() => _onBlurCombo("empresa")}
                />
                <br />
                <ComboBox
                  label="Gerencia:"
                  componentRef={refGerencia}
                  placeholder="Ingrese Gerencia"
                  allowFreeform
                  autoComplete="off"
                  useComboBoxAsMenuWidth
                  calloutProps={{ directionalHintFixed: true }}
                  options={gerencia}
                  onInput={_onInputGerencia}
                  selectedKey={filterBus.gerenciaId}
                  onChange={(e, o) => _onChangeCombo("gerencia", e, o)}
                  onBlur={() => _onBlurCombo("gerencia")}
                />
                <br />
                <ComboBox
                  label="Lugar Movilización:"
                  componentRef={refLugarMovilizacion}
                  placeholder="Ingrese Lugar Movilización"
                  allowFreeform
                  autoComplete="off"
                  useComboBoxAsMenuWidth
                  calloutProps={{ directionalHintFixed: true }}
                  options={lugarMovilizacion}
                  onInput={_onInputLugarMovilizacion}
                  selectedKey={filterBus.lugarMovilizacionId}
                  onChange={(e, o) => _onChangeCombo("LugarMovilizacion", e, o)}
                  onBlur={() => _onBlurCombo("lugarMovilizacion")}
                />
              </div>
            </div>
          </div>
        </>
      </Panel>

      {/* Panel - Reporte - Telerik */}
      <RepoteBase
        NameReport={reportTelerik.reportName}
        Parameters={reportTelerik.param}
        openTelerik={isOpenTelerik}
        dismissTelerik={dismissTelerik}
      ></RepoteBase>
    </>
  );
};
