import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import "office-ui-fabric-core/dist/css/fabric.css";
import "../src/index.css";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

ReactDOM.render(
  // <FluentProvider theme={teamsLightTheme}>
  <FluentProvider theme={webLightTheme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </FluentProvider>,
  document.getElementById("root")
);

// ReactDOM.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.getElementById('root')
// );

// reportWebVitals();
