import { IDataCollection } from "../../interfaces/base/ITable"

const _initialDataCollection = <T extends Object>() => {
    let d: IDataCollection<T> = {
        hasItems: false,
        items: [],
        total: 0,
        page: 0,
        pages: 0,
        title: '',
        subTitle: '',
        subTitleTwo: '',
    };

    return d;
}

export {
    _initialDataCollection,
}