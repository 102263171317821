import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    ICommandBarItemProps,
    Stack, TextField, Toggle
} from "@fluentui/react";
import PersonaService from "../../services/PersonaService";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HoteleriaService from "../../services/HoteleriaService";
import { IListarPersonaById, IPersona } from "../../interfaces/IPersona";
import { EMPRESA_TITULAR_DEFAULT, keyAccess, splitExceptionDetail, _initialFilter } from "../../utils/Helper/helper";
import { IBase, IDetailUbigeo, IException, IItemComboBaseDTO, ISearch } from "../../interfaces/base/IBase";
import { IProgramacionPersona } from "../../interfaces/IProgramacion";
import TitleSeparator from "../../components/Generic/TitleSeparator";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import SpinnerCenter from "../../components/Generic/SpinnerCenter";
import TransporteService from "../../services/transporteService";
import { IUserAuthenticate } from "../../auth/interface/IAuth";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ProgramacionService from "../../services/ProgramacionService";
import ContratoService from "../../services/contratoService";
import UbigeoService from "../../services/ubigeoService";
import { Console } from "console";
import { IHotel } from "../../interfaces/IHotel";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { ROLE_KEY_USER_EMPLEADO, ROLE_KEY_USER_SOCIO } from "../../utils/Helper/roleHelper";

const stackTokens = { childrenGap: 20 };
interface formModel {
    programacionId?: string,
    personaId: string,
    prioridad?: number,
    requiereBus?: boolean,
    ubigeoId?: string | null,
    email?: string,
    celular?: string,
    direccion?: string,
    paraderoId: string,
    ubicacionCuarentenaId?: string | null,
    ubicacionCampamentoId: string,
    turno: string,
    transporteId?: string | null,
    hotelId?: string | null,
    usuarioRegistraId?: string | null,
    esTitular: boolean,
    lugarMovilizacionId?: string | null,
    centroCostoId?: string | null,
}
const INITIAL_MODULE: formModel = {
    programacionId: '',
    personaId: '',
    prioridad: 0,
    requiereBus: true,
    ubigeoId: null,
    email: '',
    celular: '',
    direccion: '',
    paraderoId: '',
    ubicacionCuarentenaId: null,
    ubicacionCampamentoId: '',
    turno: '',
    transporteId: '',
    hotelId: null,
    usuarioRegistraId: null,
    esTitular: true,
    lugarMovilizacionId: null,
    centroCostoId: null,
}

const initialTurno = [
    { key: 'D', text: 'Turno Día' },
    { key: 'N', text: 'Turno Noche' },
    { key: 'D/N', text: 'Turno Día / Noche' },
];
const  INITIAL_UBIGUEO =
{
    departamentoId: '',
    provinciaId:  null,
    distritoId: null,
}

const linkBack = `${ConfigHelper.urlBase()}/programaciones/`;

export const CearAccesoPersona = () => {
    let { id } = useParams();
    let navigate = useNavigate();

    const [paradero, setparadero] = useState<IItemComboBaseDTO[]>([])//-----------lista------------
    const [comboCampamento, setComboCampamento] = useState<IItemComboBaseDTO[]>([]);//-----------lista------------
    const [Ubigueo, setUbigueo] = useState<IItemComboBaseDTO[]>([])//-----------lista------------
    const [Provincias, setProvincias] = useState<IItemComboBaseDTO[]>([]);
    const [Distritos, setDistritos] = useState<IItemComboBaseDTO[]>([]);
    const [detailUbigeo, setDetailUbigeo] = useState<IDetailUbigeo>(null!);
    const [hotel, setHotel] = useState<IItemComboBaseDTO[]>([]);
    const [transporte, setTransporte] = useState<IItemComboBaseDTO[]>([]);
    const [isTitular, setIsTitular] = useState<boolean | undefined>(undefined);
    const [turno, setTurno] = useState<IItemComboBaseDTO[]>(initialTurno);
    const [centroCosto, setCentroCosto] = useState<IItemComboBaseDTO[]>([]);
    const [lugarMovilizacion, setLugarMovilizacion] = useState<IItemComboBaseDTO[]>([]);

    const [model, setmodel] = useState<formModel>({...INITIAL_MODULE,programacionId:id});
    const [isSend, setIsSend] = useState(false);

    /* Reest */
    const [programacion, setProgramacion] = useState<IProgramacionPersona>();
    const [persona, setPersona] = useState<IItemComboBaseDTO[]>([]);

    /*filtros */
    const [filterPersona, setFilterPersona] = useState<ISearch>(_initialFilter);
    const [filterEmbarque, setfilterEmbarque] = useState<ISearch>(_initialFilter);
    const [filterCampamento, setfilterCampamento] = useState<ISearch>(_initialFilter);
    const [filterMovilizacion, setfilterMovilizacion] = useState<ISearch>(_initialFilter);

    const comboBoxRef = React.useRef<IComboBox>(null);
    const departamentoRef = React.useRef<IComboBox>(null);
    const provinciaRef = React.useRef<IComboBox>(null);
    const embarqueRef = React.useRef<IComboBox>(null);
    const campamentoRef = React.useRef<IComboBox>(null);
    const LugarMovilizacoinRef = React.useRef<IComboBox>(null);

    const _openOptionPersona = React.useCallback(() => comboBoxRef.current?.focus(true), []);
    const _openOptionEmbarque = React.useCallback(() => embarqueRef.current?.focus(true), []);
    const _openOptionCampamento = React.useCallback(() => campamentoRef.current?.focus(true), []);
    const _openOptionMovilizacion = React.useCallback(() => LugarMovilizacoinRef.current?.focus(true), []);

    const [email, setEmail] = useState<string | undefined>(undefined);
    const [celular, setCelular] = useState<string | undefined>(undefined);
    const [direccion, setDireccion] = useState<string | undefined>(undefined);
    const [ccostoId, setCCostoId] = useState<string | undefined>(undefined);
    const [lugarMovId, setLugarMovId] = useState<string | undefined>(undefined);
    const [ubigeoId, setUbigeoId] = useState<string | undefined>(undefined);
    const [empresa, setEmpresa] = useState<string>('');
    /* Reest */

    /* Role */
    const access = localStorage.getItem(keyAccess);
    let iRole: string[] = [];
    if (access) {
        let d: IUserAuthenticate = JSON.parse(access);
        if (d.role) {
            iRole = d.role;
        }
    }
    /* Role */

    const [role, setRole] = useState<string[]>(iRole);

    /* Effect */
    useEffect(() => {
        setmodel({ ...model, programacionId: id })

        dataPersona();
        dataParadero();
        dataCampamento();
        dataLugarMovilizacion();
        dataCentroCosto();
        dataDepartamento();
        dataTransporte();
        dataHotel();
    }, [])

    useEffect(() => {
        if (filterPersona.search != null) {
            dataPersona();
        }
    }, [filterPersona]);

    useEffect(() => {
        if (filterEmbarque.search != null) {
            dataParadero();
        }
    }, [filterEmbarque]);

    useEffect(() => {
        if (filterCampamento.search != null) {
            dataCampamento();
        }
    }, [filterCampamento]);


    useEffect(() => {
        if (filterMovilizacion.search != null) {
            dataLugarMovilizacion();
        }
    }, [filterMovilizacion]);

    useEffect(() => {
        if (id != undefined) {
            dataInfoProgramacion();
        }
    }, [id]);

    useEffect(() => {
        if (isTitular != undefined) {
            let v = isTitular ? EMPRESA_TITULAR_DEFAULT.toLowerCase() : null;

            setmodel({
                ...model,
                transporteId: v,
            })
        }
    }, [isTitular, transporte]);

    useEffect(() => {
        if (email != undefined) {
            setmodel({
                ...model,
                email: email
            })
        }
    }, [email]);

    useEffect(() => {
        if (celular != undefined) {
            setmodel({
                ...model,
                celular: celular
            })
        }
    }, [celular]);

    useEffect(() => {
        if (direccion != undefined) {
            setmodel({
                ...model,
                direccion: direccion
            })
        }
    }, [direccion]);

    useEffect(() => {
        if (ccostoId != undefined) {
            setmodel({
                ...model,
                centroCostoId: ccostoId
            })
        }
    }, [ccostoId]);

    useEffect(() => {
        if (lugarMovId != undefined) {
            setmodel({
                ...model,
                lugarMovilizacionId: lugarMovId
            })
        }
    }, [lugarMovId]);

    useEffect(() => {
        if (ubigeoId != undefined) {
            detalleUbigeo(ubigeoId);
        }
    }, [ubigeoId]);

    useEffect(() => {
        if (detailUbigeo != undefined) {
            if (detailUbigeo.distritoId != undefined) {
                setmodel({
                    ...model,
                    ubigeoId: detailUbigeo.distritoId
                })
            }
        }
    }, [detailUbigeo]);

    /* Api */
    const dataPersona = () => {
        PersonaService.listarPersonaMMG(filterPersona)
            .then(res => {
                /* v2 persona */
                let d: IPersona[] = res.data;
                let t: IItemComboBaseDTO[] = d.map(x => {
                    return ({
                        key: x.id,
                        text: `${x.nroDOI}  | ${x.persona} | ${x.tipo}`,
                    });
                });

                setPersona(t);
            })
            .catch((e: Error) => {
                alert(e.message);
            });;
    }

    const dataInfoProgramacion = () => {
        PersonaService.getInfoProgramacion(id).then(res => {
            let d: IProgramacionPersona = res.data;
            setProgramacion(d);

            if (!d.isAllTurno) {
                setTurno(turno.filter(x => x.key != 'D'));
            }
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataParadero = () => {
        PersonaService.getComboParadero1(filterEmbarque).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setparadero(t)
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataCampamento = () => {
        PersonaService.getComboCampamento1(filterCampamento).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setComboCampamento(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion().then(res => {
            setLugarMovilizacion(res.data);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        });;
    }

    const dataCentroCosto = () => {
        ContratoService.allCentroCosto().then(res => {
            setCentroCosto(res.data);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        });;
    }

    const dataDepartamento = () => {
        PersonaService.getComboUbigueo().then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setUbigueo(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const _LoadProvincia = (key: any) => {
        PersonaService.getComboUbigueo(key).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setProvincias(t);
        }).catch((e) => {
            alert(e.message);
        })
    }

    const _LoadDistritos = (key: any) => {
        PersonaService.getComboUbigueo(key).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            setDistritos(t)
        }).catch((e) => {
            return alert(e.message);
        })
    }

    const dataTransporte = () => {
        /* Caso sea KeyUser - las empresas se filtraran de acuerdo a la persona seleccionada
        Caso contrario - empresas segun el contrato del usuario */
        if (role.filter(x => x == ROLE_KEY_USER_EMPLEADO ||x == ROLE_KEY_USER_SOCIO ).length == 0) {
            TransporteService.allSocioTransportista().then(res => {
                let d: IBase[] = res.data;

                let t: IItemComboBaseDTO[] = d.map(x => {
                    return ({
                        key: x.id,
                        text: x.nombre,
                    })
                });

                setTransporte(t);
            }).catch((e: IException) => {
                alert(splitExceptionDetail(e));
            })
        }
    }

    const dataHotel = () => {
        HoteleriaService.getHoteles(50).then(res => {
            let d: IHotel[] = res.data.items;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.hotelID != null ? x.hotelID : '',
                    text: `${x.nombre} | ${x.direccion}`,
                })
            });

            setHotel(t);
        }).catch((e) => {
            return alert(e.message);
        });
    }

    function _enviarAccesoPersona() {
        setIsSend(true);

        PersonaService.postAccesoPersona(model).then(res => {
            if (res.status == 200) {
                alert('enviado')
                goBack()
            }
        }).catch((e: any) => {
            setIsSend(false);
            alert(splitExceptionDetail(e));
        })
    }

    const _onInfoPersona = (id: string) => {
        let obj: IListarPersonaById = {
            listaPersonas: [
                id
            ]
        };

        PersonaService.getInfoPersonaById(obj).then(res => {
            if (res.data) {
                let d = res.data[0];
                if (d) {
                    setIsTitular(d.tipo == '01');

                    /* Rol KeyUser - busca la empresa trasportistas segun la empresa de la persona */
                    if (role.filter(x => x == ROLE_KEY_USER_EMPLEADO ||x == ROLE_KEY_USER_SOCIO).length > 0) {
                        dataSocioTrPorEmpresaContratista(d.empresaId);
                    }

                    setEmail(d.email);
                    setCelular(d.celular);
                    setDireccion(d.direccion);
                    setCCostoId(d.centroCostoId);
                    setLugarMovId(d.lugarMovilizacionId);
                    setUbigeoId(d.ubigeoId);
                    setEmpresa(d.empresa);
                }
            }
        }).catch((e) => {
            alert(e);
        })
    }

    const dataSocioTrPorEmpresaContratista = (empresaContratistaId: string) => {
        setTransporte([]);
        TransporteService.allSocioTrByEmpresaContratista(empresaContratistaId).then(res => {
            if (res.status == 200) {
                let d: IBase[] = res.data;

                let t: IItemComboBaseDTO[] = d.map(x => {
                    return ({
                        key: x.id,
                        text: x.nombre,
                    })
                });

                setTransporte(t);
            }
        }).catch((e) => {
            return alert(e.message);
        });
    }

    /* Api Ubigeo */
    const detalleUbigeo = (distritoId: string) => {
        UbigeoService.detalleById(distritoId).then(res => {
            let d: IDetailUbigeo = res.data;

            setDetailUbigeo({
                ...programacion,
                departamentoId: d.departamentoId,
                provinciaId: d.provinciaId,
                distritoId: d.distritoId,
            })

            if (d.departamentoId) {
                dataByUbigeoId(2, d.departamentoId);
            }

            if (d.provinciaId) {
                dataByUbigeoId(3, d.provinciaId);
            }
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataByUbigeoId = (tipo: number, ubigeoId?: string) => {
        PersonaService.getComboUbigueo(ubigeoId).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            switch (tipo) {
                case 1:
                    setUbigueo(t);
                    break;
                case 2:
                    setProvincias(t);
                    break;
                case 3:
                    setDistritos(t);
                    break;
                default:
                    break;
            }
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    /* Event */
    const _onUpPersona = (ev?: React.KeyboardEvent<IComboBox>) => {
        _openOptionPersona();

        let { value } = ev?.target as HTMLInputElement;
        setmodel({
            ...model,
            personaId: ''
        })

        setEmpresa('');

        setFilterPersona({
            ...filterPersona,
            search: value,
        });
    }



    const _onBlurPersona = () => {
        if (model.personaId == '' || model.personaId == undefined) {
            setFilterPersona({
                ...filterPersona,
                search: '',
            })
        }
    }

    const _onInputPersona=()=>{
        let embarqueAux= model.paraderoId;
        let turnoAux = model.turno;
        let campamentoAux = model.ubicacionCampamentoId;
        let empresaAux = model.transporteId;
        setmodel({...INITIAL_MODULE,
            programacionId:id,
            paraderoId:embarqueAux,
            turno:turnoAux,
            ubicacionCampamentoId:campamentoAux,
            transporteId:empresaAux,
        })
        setDetailUbigeo(INITIAL_UBIGUEO)
        setProvincias([])
        setDistritos([])
    }
    
  /* Event Combo Embarque */
  const _onUpEmbarque = (e? :React.KeyboardEvent<IComboBox>)=>{
    _openOptionEmbarque();
    let { value } = e?.target as HTMLInputElement;
    setmodel({
        ...model,
        paraderoId: ''
    })
    setfilterEmbarque({
        ...filterEmbarque,
        search: value,
    })
}

    const _onBlurEmbarque = () => {
        if (model.paraderoId == '' || model.paraderoId == undefined) {
            setfilterEmbarque({
                ...filterEmbarque,
                search: '',
            })
        }
    }

    /* Event Combo Campamento */
    const _onUpCampamento = (e?: React.KeyboardEvent<IComboBox>) => {
        _openOptionCampamento();
        let { value } = e?.target as HTMLInputElement;
        setmodel({
            ...model,
            ubicacionCampamentoId: ''
        })
        setfilterCampamento({
            ...filterCampamento,
            search: value,
        })
    }

    const _onBlurCampamento = () => {
        if (model.ubicacionCampamentoId == '' || model.ubicacionCampamentoId == undefined) {
            setfilterEmbarque({
                ...filterCampamento,
                search: '',
            })
        }
    }

    /* Event Combo Campamento */
    const _onUpMOvilizacion = (e?: React.KeyboardEvent<IComboBox>) => {
        _openOptionMovilizacion();
        let { value } = e?.target as HTMLInputElement;
        setmodel({
            ...model,
            lugarMovilizacionId: ''
        })
        setfilterMovilizacion({
            ...filterMovilizacion,
            search: value,
        })
    }
    const _onChangePersona = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                _onInfoPersona(option.key.toString());

                setmodel({
                    ...model,
                    personaId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeParadero = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setmodel({
                    ...model,
                    paraderoId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeCampamento = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setmodel({
                    ...model,
                    ubicacionCampamentoId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeTurno = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setmodel({
                    ...model,
                    turno: option.key.toString(),
                })
            }
        }
    }

    const _onChangeClasificacion = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked != undefined) {
            setmodel({
                ...model,
                esTitular: checked,
            })
        }
    }

    const _onChangeDepartamento = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setDetailUbigeo({
                    ...detailUbigeo,
                    departamentoId: String(option.key) as string
                })
                _LoadProvincia(option.key);
            }
        } else {
            setmodel({
                ...model,
                ubigeoId: null
            })
        }
    }

    const _onChangeProvincia = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setDetailUbigeo({
                    ...detailUbigeo,
                    provinciaId: String(option.key),
                })
                _LoadDistritos(option.key);
            }
        }
    }

    const _onChangeDistrito = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setmodel({
                    ...model,
                    ubigeoId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeDireccion = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setmodel({
                ...model,
                direccion: newValue,
            });
        }
    }

    const _onChangeEmail = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setmodel({
                ...model,
                email: newValue,
            });
        }
    }

    const _onChangeTelefono = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setmodel({
                ...model,
                celular: newValue,
            });
        }
    }

    const _onChangeTransporte = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setmodel({
                    ...model,
                    transporteId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeHotel = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setmodel({
                    ...model,
                    hotelId: option.key.toString(),
                })
            }
        }
    }

    const goBack = () => {
        navigate(`${linkBack + id}`)
    }

    const _generateItemBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: goBack,
            },
        ];

        return _items;
    }

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'lugarMovilizacion':
                        setmodel({
                            ...model,
                            lugarMovilizacionId: String(option.key),
                        })
                        break;
                    case 'centroCosto':
                        setmodel({
                            ...model,
                            centroCostoId: String(option.key),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    return (
        <>
            <HeadingBar title={`${programacion?.titulo}`}
                subTitle={`Personal ${programacion?.tipoPersonal} | ${programacion?.guardia}`}
                searchShow={false} itemBar={_generateItemBar()}
                subTitle2={`Fecha Cierre: ${programacion?.fCierre} | Días Cierre: ${programacion?.diasCierre}`}></HeadingBar>

            <form>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg2"></div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8" style={{
                            padding: '5%',
                        }}>
                            <div className="ms-depth-8" style={{
                                padding: '15px',
                            }}>
                                <SpinnerCenter isLoad={isSend} message={'Enviando'}></SpinnerCenter>

                                {!isSend &&
                                    <>
                                        <TitleSeparator title='Inscribir Colaborador' icon='TemporaryUser'></TitleSeparator>

                                        <Stack tokens={stackTokens}>
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <ComboBox
                                                            componentRef={comboBoxRef}
                                                            label="Persona"
                                                            allowFreeform
                                                            autoComplete='off'
                                                            useComboBoxAsMenuWidth
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            options={persona}
                                                            errorMessage={empresa}
                                                            onKeyUp={_onUpPersona}
                                                            onInput={ _onInputPersona}
                                                            selectedKey={model.personaId}
                                                            onChange={_onChangePersona}
                                                            onBlur={_onBlurPersona}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                        <ComboBox
                                                            componentRef={embarqueRef}
                                                            label="Embarque"
                                                            allowFreeform
                                                            autoComplete='off'
                                                            useComboBoxAsMenuWidth
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            options={paradero}
                                                            onKeyUp={_onUpEmbarque}
                                                            selectedKey={model.paraderoId}
                                                            onChange={_onChangeParadero}
                                                            onBlur={_onBlurEmbarque}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                        <ComboBox
                                                            componentRef={campamentoRef}
                                                            label="Ubicación Campamento"
                                                            allowFreeform
                                                            autoComplete='off'
                                                            useComboBoxAsMenuWidth
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            onKeyUp={_onUpCampamento}
                                                            options={comboCampamento}
                                                            selectedKey={model.ubicacionCampamentoId}
                                                            onChange={_onChangeCampamento}
                                                            onBlur={_onBlurCampamento}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                        <ComboBox
                                                            label="Turno"
                                                            autoComplete='off'
                                                            useComboBoxAsMenuWidth
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            options={turno}
                                                            openOnKeyboardFocus
                                                            selectedKey={model.turno}
                                                            onChange={_onChangeTurno}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                        <ComboBox
                                                            componentRef={LugarMovilizacoinRef}
                                                            label='Lugar Movilización'
                                                            autoComplete='off'
                                                            useComboBoxAsMenuWidth
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            onKeyUp={_onUpMOvilizacion}
                                                            options={lugarMovilizacion}
                                                            selectedKey={model ? model.lugarMovilizacionId : ''}
                                                            onChange={(e, o) => _onChangeCombo('lugarMovilizacion', e, o)}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                        <ComboBox
                                                            label='Centro Costos'
                                                            autoComplete='off'
                                                            useComboBoxAsMenuWidth
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            options={centroCosto}
                                                            selectedKey={model ? model.centroCostoId : ''}
                                                            onChange={(e, o) => _onChangeCombo('centroCosto', e, o)}
                                                            required
                                                        />
                                                    </div>

                                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                                        <Toggle label='Clasificación Colaborador' defaultChecked onText='Titular' offText='Suplente' onChange={_onChangeClasificacion} />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Ubigeo */}
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <div className="ms-fontSize-16" style={{ color: '#4676DD' }}>Ubigeo (Ubicación actual)</div>
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                                        <ComboBox
                                                            componentRef={departamentoRef}
                                                            label='Departamento'
                                                            allowFreeform
                                                            autoComplete='on'
                                                            useComboBoxAsMenuWidth
                                                            openOnKeyboardFocus
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            options={Ubigueo}
                                                            selectedKey={detailUbigeo ? detailUbigeo.departamentoId : ''}
                                                            onChange={_onChangeDepartamento}
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                                        <ComboBox
                                                            label='Provincia'
                                                            componentRef={provinciaRef}
                                                            allowFreeform
                                                            autoComplete='on'
                                                            useComboBoxAsMenuWidth
                                                            openOnKeyboardFocus
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            options={Provincias}
                                                            selectedKey={detailUbigeo ? detailUbigeo.provinciaId : ''}
                                                            onChange={_onChangeProvincia}
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                                                        <ComboBox
                                                            label='Distrito'
                                                            allowFreeform
                                                            autoComplete='on'
                                                            useComboBoxAsMenuWidth
                                                            openOnKeyboardFocus
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            options={Distritos}
                                                            selectedKey={model.ubigeoId}
                                                            onChange={_onChangeDistrito}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Transporte / hotel */}
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <ComboBox
                                                            label='Empresa Transportista'
                                                            allowFreeform
                                                            autoComplete='on'
                                                            disabled={isTitular}
                                                            useComboBoxAsMenuWidth
                                                            openOnKeyboardFocus
                                                            calloutProps={{ directionalHintFixed: true }}
                                                            options={transporte}
                                                            selectedKey={model.transporteId}
                                                            onChange={_onChangeTransporte}
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        {programacion?.tieneCuarentena &&
                                                            <ComboBox
                                                                label='Hotel'
                                                                allowFreeform
                                                                autoComplete='on'
                                                                useComboBoxAsMenuWidth
                                                                openOnKeyboardFocus
                                                                calloutProps={{ directionalHintFixed: true }}
                                                                options={hotel}
                                                                selectedKey={model.hotelId}
                                                                onChange={_onChangeHotel}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Info contacto */}
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <TextField
                                                            label="Dirección"
                                                            placeholder="Dirección"
                                                            value={model.direccion}
                                                            onChange={_onChangeDireccion}
                                                            autoComplete="off" />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                        <TextField
                                                            label='Email'
                                                            placeholder='Email'
                                                            value={model.email}
                                                            onChange={_onChangeEmail}
                                                            autoComplete="off" />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                        <TextField
                                                            label='Teléfono'
                                                            placeholder='Teléfono'
                                                            value={model.celular}
                                                            onChange={_onChangeTelefono}
                                                            autoComplete="off" />
                                                    </div>
                                                </div>
                                            </div>

                                            <ButtonForm back={goBack} register={_enviarAccesoPersona} disabledRegister={isSend}></ButtonForm>
                                        </Stack>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </>
    );
}