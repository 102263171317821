import { fetchAdministracion, fetchMovilizacion } from "./http-common";

import { IItemComboBaseDTO, ISearch } from "../interfaces/base/IBase";
import { IPersona, IUpdateSEE } from "../interfaces/IPersona";
import {
  IFilterAsignacion,
  IFilterProgramacion,
  IValidarKeyUser,
} from "../interfaces/IProgramacion";
import { Ifilter } from "../interfaces/IFilter";
import { IFilterPersona } from "../interfaces/filter/IFilterPersona";
import { ICreateAccesoPersona } from "../interfaces/IAccesoPersona";

const urlProgramacion = "AccesoProgramadoProgramacion";
const urlProgramacionPersona = "AccesoProgramadoPersona";
const urlGuardia = "Guardia";
const urlPersona = "Persona";
const urlTemporada = "Temporada";
const urlAccesoProgramadoProgramacionBus = "AccesoProgramadoProgramacionBus";

const getPersonas = (filter: {}) => {
  return fetchAdministracion.get<IPersona>(`${urlPersona}/Listar`, {
    params: filter,
  });
};
const getListaPersonas = (filter: IFilterPersona) => {
  return fetchAdministracion.get(`Persona/Listar`, { params: filter });
};

const getInfoPersonaById = (lst: object) => {
  return fetchAdministracion.post(`Persona/ListarPersonasPorId`, lst);
};

const getComboEmpresas = () => {
  return fetchAdministracion.get(`Empresa/Combo/Empresas`);
};

// Empresas transporte habilitadas
const transporteHabilitado = () => {
  return fetchMovilizacion.get(`Empresa/Transportista`);
};

const getComboTransportes = () => {
  return fetchMovilizacion.get(`Transporte/Combo`);
};

const postPersona = (data: any) => {
  return fetchAdministracion.post(`Persona/Agregar/Persona`, data);
};

const putPersonaEmpTransp = (data: any) => {
  return fetchAdministracion.put(`${urlPersona}/EmpTranportista`, data);
};
const putPersonaKeyUser = (data: any) => {
  return fetchAdministracion.put(`${urlPersona}/Actualizar`, data);
};

const putPersonaSEE = (data: IUpdateSEE) => {
  console.log(data);
  return fetchAdministracion.put(`${urlPersona}/SEE`, data);
};

const postRutaParaderos = (data: any) => {
  return fetchMovilizacion.post(
    `TransporteRutaParadero/Agregar/Paraderos`,
    data
  );
};

const getComboCuarentena = () => {
  return fetchAdministracion.get(`Ubicacion/Combo/Cuarentena`);
};

const getComboCampamento = () => {
  return fetchAdministracion.get(`Ubicacion/Combo/Campamento`);
};
const getComboCampamento1 = (filter: ISearch) => {
  return fetchAdministracion.get(`Ubicacion/Combo/Campamento`, {
    params: filter,
  });
};

const personaByCustomer = (filter: ISearch) => {
  return fetchAdministracion.get(`Persona/Combo/ByCustomer`, {
    params: filter,
  });
};

const listarPersonaMMG = (filter: ISearch) => {
  return fetchAdministracion.get(`Persona/ListarMMG`, { params: filter });
};

const getComboUbigueo = (id?: string) => {
  return fetchAdministracion.get(`Ubigeo/Combos?Id=${id}`);
};

const getTiposdocumentos = () => {
  return fetchAdministracion.get(
    `TipoDocumentoIdentidad/TipoDocumentoIdentidad`
  );
};
//METODOS MOVILIZACION API
//POSTs
const postAccesoPersona = (data: any) => {
  return fetchMovilizacion.post(`AccesoProgramadoPersona`, data);
};

const postPrograma = (data: any) => {
  return fetchMovilizacion.post(`AccesoProgramadoProgramacion`, data);
};

const postAsigancionMasiva = (file: any) => {
  return fetchMovilizacion.post(
    `AccesoProgramadoTransporte/Asignacion/Masiva`,
    file
  );
};

const postAgregarAsientoMasivo = (lista: any) => {
  return fetchMovilizacion.post(
    `AccesoProgramadoProgramacionBus/Agregar/Asiento`,
    lista
  );
};

const postTransporte = (data: any) => {
  return fetchMovilizacion.post(`Transporte`, data);
};

const postRegistroMasivo = (file: any, programacionId: string) => {
  /* let formData = new FormData();
  formData.append("file", file); */

  return fetchMovilizacion.post(
    `AccesoProgramadoPersona/Registro/Masivo/${programacionId}`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const postRegistroRetiroMasivoExcel = (file: any, programacionId: string) => {
  let formData = new FormData();
  formData.append("file", file);

  return fetchMovilizacion.post(
    `AccesoProgramadoPersona/Retiro/Masivo/${programacionId}`,
    file,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

//GETs
const reporteAccesoProgramado = (programacionId: any) => {
  return fetchMovilizacion.get(
    `AccesoProgramadoPersona/Reporte?ProgramacionId=${programacionId}`,
    {
      responseType: "blob",
    }
  );
};
const reporteCovid = (programacionId: any) => {
  return fetchMovilizacion.get(
    `AccesoProgramadoPersona/ReporteCovid?ProgramacionId=${programacionId}`,
    {
      responseType: "blob",
    }
  );
};

const getComboParadero = () => {
  return fetchMovilizacion.get(`TransporteCatalogoParadero/Combo`);
};
const getComboParadero1 = (filter: ISearch) => {
  return fetchMovilizacion.get(`TransporteCatalogoParadero/Combo`, {
    params: filter,
  });
};

const getComboGuardias = (search: any) => {
  return fetchMovilizacion.get<IItemComboBaseDTO>(
    `GuardiaPersonal/Combo`,
    search
  );
};

const comboGuardiaProgramacion = (filter: Ifilter) => {
  return fetchMovilizacion.get<IItemComboBaseDTO>(`${urlGuardia}/Combo`, {
    params: filter,
  });
};

const getPendientesBus = (model: IFilterAsignacion) => {
  return fetchMovilizacion.get(`AccesoProgramadoPersona/PendientesBus`, {
    params: model,
  });
};

const getTransporte = (filter: Ifilter) => {
  return fetchMovilizacion.get(`Transporte/`, { params: filter });
};
//Programaciones de ingreso
const getListaPrograma = (filter: Ifilter) => {
  return fetchMovilizacion.get(`${urlProgramacion}`, { params: filter });
};

//-------------
const getProgramFilter = (filter: {}) => {
  return fetchMovilizacion.get<IPersona>(`AccesoProgramadoProgramacion`, {
    params: filter,
  });
};

//Programaciones de salida
const getListaProgramaSalidas = (filter: Ifilter) => {
  return fetchMovilizacion.get(`${urlProgramacion}/Salidas`, {
    params: filter,
  });
};

//Programaciones Transportes
const getListaProgramacionesTransportes = (
  actividad: number,
  filter: Ifilter
) => {
  // return fetchMovilizacion.get(`AccesoProgramadoProgramacion/Transportes?Skip=0&Take=${num}`);
  return fetchMovilizacion.get(`${urlProgramacion}/Transportes/${actividad}`, {
    params: filter,
  });
};

const getInfoProgramacion = (programacionId: any) => {
  return fetchMovilizacion.get(
    `AccesoProgramadoProgramacion/Info/${programacionId}`
  );
};

const getAccesoPersona = (id: string, filter: IFilterProgramacion) => {
  return fetchMovilizacion.get(`AccesoProgramadoPersona/${id}`, {
    params: filter,
  });
};

const getAccesProgConfirm = (programaId: string) => {
  return fetchMovilizacion.get(`AccesoProgramadoPersona/Info/${programaId}`);
};

const postCupoEmpresa = (data: any) => {
  return fetchMovilizacion.post(
    `AccesoProgramadoProgramacion/CupoEmpresa`,
    data
  );
};

const getCupoEmpresa = (programacionId: string) => {
  return fetchMovilizacion.get(
    `AccesoProgramadoProgramacion/CupoEmpresa/Listar/?ProgramacionId=${programacionId}`
  );
};
//PUTs
const putConfirmacionProg = (data: any) => {
  return fetchMovilizacion.put(`AccesoProgramadoPersona/Confirmar`, data);
};

const putCargarPruebasCovid = (params: any, id: any) => {
  console.log(params);
  return fetchMovilizacion.put(
    `AccesoProgramadoPersona/Registro/Pruebas/${id}`,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    }
  );
};

const putProgramacion = (data: any) => {
  return fetchMovilizacion.put(`AccesoProgramadoProgramacion`, data);
};

const getPersonasConfirmadas = (num: number, programaId: string) => {
  return fetchMovilizacion.get(
    `AccesoProgramadoPersona/Confirmados?Skip=0&Take=${num}&ProgramacionId=${programaId}`
  );
};

const getTransporteRutaParadero = (num: number, programacionId: string) => {
  return fetchMovilizacion.get(
    `TransporteRutaParadero/Listar/Paraderos?Skip=0&Take=${num}&ProgramacionId=${programacionId}`
  );
};

const getComboTransporteRutaParadero = () => {
  return fetchMovilizacion.get(`TransporteRutaParadero/combo/Paraderos`);
};
const getPersonasAsignadas = (num: number, programaId: string) => {
  return fetchMovilizacion.get(
    `AccesoProgramadoPersona/EmpresaAsignada?Skip=0&Take=${num}&ProgramacionId=${programaId}`
  );
};

const postTransporteParadero = (data: any) => {
  return fetchMovilizacion.post(
    `AccesoProgramadoProgramacionBus/Asignar/Transporte`,
    data
  );
};

const getTableTransporteRuta = (filter: IFilterAsignacion) => {
  return fetchMovilizacion.get(`${urlAccesoProgramadoProgramacionBus}`, {
    params: filter,
  });
};

const getComboTransporteRuta = (filter: any) => {
  return fetchMovilizacion.get(`TransporteRuta/Combo?Search=${filter}`);
};

const validarPersona = (data: IValidarKeyUser) => {
  return fetchMovilizacion.post(`${urlProgramacionPersona}/Validar`, data);
};

const validarProgramacion = (id: string) => {
  return fetchMovilizacion.post(`${urlProgramacion}/Validar/${id}`);
};

/* Programaciones Adicionales */
/* Nivel 1 - Gerente */
const adicionalGerencia = (filter: Ifilter) => {
  return fetchMovilizacion.get(`${urlProgramacion}/Adicionales/Gerencia`, {
    params: filter,
  });
};

/* Nivel 2 - Logistica */
const adicionalLogistica = (filter: Ifilter) => {
  return fetchMovilizacion.get(`${urlProgramacion}/Adicionales/Logistica`, {
    params: filter,
  });
};

/* Actualizacion personas masiva - roles */
const actualizarMasivoKeyUser = (file: FormData) => {
  return fetchAdministracion.post(`${urlPersona}/MMG/Masivo/KeyUser`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const actualizarMasivoTransporte = (file: FormData) => {
  return fetchAdministracion.post(`${urlPersona}/MMG/Masivo/Transporte`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const actualizarMasivoSSEE = (file: FormData) => {
  return fetchAdministracion.post(`${urlPersona}/MMG/Masivo/SSEE`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getPersonaSegunTemporadaLevel = (filter: Ifilter) => {
  return fetchMovilizacion.get(`${urlTemporada}/Persona/${filter}`);
};

/* Vuelo */
const inscribirVuelo = (data: ICreateAccesoPersona) => {
  return fetchMovilizacion.post(`${urlProgramacionPersona}/Vuelo`, data);
};

const getPersonaTipo = (search: string) => {
  return fetchAdministracion.get(`${urlPersona}/comboTipo?search =${search}`);
};

const getPersonaEstado = (search: string) => {
  return fetchAdministracion.get(`${urlPersona}/comboEstado?search =${search}`);
};

const putPersonalMMG = (data: any) => {
  return fetchAdministracion.put(`Integracion/MMG/Actualizar/Personal`, data);
};

const addPersonaMMG = (data: any) => {
  return fetchAdministracion.post(`Persona/Agregar/PersonaBasico`, data);
};

const putProgramacionPersonaTransporte = (progrmacionPersonaId: string) => {
  return fetchMovilizacion.put(
    `AccesoProgramadoPersona/ActualizarTransporte/${progrmacionPersonaId}`
  );
};

const getPuestoCombo = (search: string) => {
  return fetchAdministracion.get(`Puesto/ComboByCustomer?Filter=${search}`);
};

const PersonaService = {
  postPrograma,
  postPersona,

  /**Actulizacion Persona */
  putPersonaEmpTransp,
  putPersonaKeyUser,
  putPersonaSEE,
  addPersonaMMG,

  postTransporte,
  postRegistroMasivo,
  postAccesoPersona,
  postAsigancionMasiva,
  getPersonas,
  getInfoPersonaById,
  getListaPersonas,
  getListaPrograma,
  getComboEmpresas,
  transporteHabilitado,
  getComboCampamento,
  getComboCampamento1,
  getAccesoPersona,
  getComboCuarentena,
  getComboParadero,
  getComboParadero1,
  getComboUbigueo,
  personaByCustomer,
  listarPersonaMMG,
  getAccesProgConfirm,
  getTransporte,
  getPersonasConfirmadas,
  putConfirmacionProg,
  putCargarPruebasCovid,
  getComboGuardias,
  getListaProgramaSalidas,
  getListaProgramacionesTransportes,
  getInfoProgramacion,
  getPersonasAsignadas,
  getComboTransporteRuta,
  getProgramFilter,
  getTransporteRutaParadero,
  postRutaParaderos,
  getComboTransportes,
  getComboTransporteRutaParadero,
  postTransporteParadero,
  getTableTransporteRuta,
  getPendientesBus,
  postAgregarAsientoMasivo,
  reporteAccesoProgramado,
  validarPersona,
  validarProgramacion,
  reporteCovid,
  putProgramacion,

  /* v2. Guardia */
  comboGuardiaProgramacion,

  /* Adicionales */
  adicionalGerencia,
  adicionalLogistica,

  /* Actualizacion personas masivo */
  actualizarMasivoKeyUser,
  actualizarMasivoTransporte,
  actualizarMasivoSSEE,

  /* Vuelo Persona segun temporada level */
  getPersonaSegunTemporadaLevel,
  /* Crear acceso vuelo */
  inscribirVuelo,
  getPersonaEstado,
  getPersonaTipo,
  putPersonalMMG,
  putProgramacionPersonaTransporte,
  getPuestoCombo,
  postRegistroRetiroMasivoExcel,

  /*AccesoProgramado Programacion Transporte*/
  postCupoEmpresa,
  getCupoEmpresa,
  getTiposdocumentos,
};

export default PersonaService;
