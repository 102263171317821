import { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "../auth/context/auth-context";
import { LoginPrincipal } from "../components/Login/Login";
import Navbar from "../components/Navbar";
import { Sidebar } from "../components/Sidebar/sidebar";
import { CrearPersona } from "../pages/persona/menuLista";
import { TablaHoteles } from "../pages/Hoteles/tablaHoteles";
import AgregarHotel from "../pages/Hoteles/crearHotel";
import { TablaVehiculos } from "../pages/transporte/tablaVehiculos";
import { AgregarVehiculo } from "../pages/transporte/crearVehiculo";
import { TablaProgramaciones } from "../pages/programaciones/tablaProgramaciones";
import { CrearProgramacion } from "../pages/programaciones/crearProgramacion";
import { CearAccesoPersona } from "../pages/persona/crearAccesoPersona";
import { ListarPersonaPorProgramacion } from "../pages/accesoPersona/listarAccesoPersona";
import { ProgamConfirmacion } from "../pages/programaciones/confirmacion";
import { PuestosVista } from "../pages/puestos/puestos";
import { EditarProgramacion } from "../pages/programaciones/editarProgramacion";
import { ListarEmpresa } from "../pages/transporte/empresa";
import { RegistrarEmpresa } from "../pages/transporte/registrarEmpresa";
import { EditarProgPersona } from "../pages/persona/editarProgPersona";
import { Asignacion } from "../pages/transporte/asignacion";
import { ListaValidarVehiculo } from "../pages/transporte/listaValidarVehiculo";
import { EditarVehiculo } from "../pages/transporte/editarVehiculo";
import { ValidarVehiculo } from "../pages/transporte/validarVehiculo";
import { AsignacionGerencia } from "../pages/configuraciones/keyUser/asignacionGerencia";
import { TablaPersona } from "../pages/persona/persona";
import { Perfil } from "../pages/persona/perfil";
import { ProgramacionesEmpresaTransporte } from "../pages/transporte/programacionesEmpresaTransporte";
import { ListarProgramacionPruebaCovid } from "../pages/programaciones/ProgExamenes";
import { DetalleProgramacionPruebaCovid } from "../pages/programaciones/LpruebasCovid";
import { ListarContrato } from "../pages/contrato/listarContrato";
import { DetalleContrato } from "../pages/contrato/detalleContrato";
import { RegistrarContrato } from "../pages/contrato/registrarContrato";
import { ListarProgramacionGuardia } from "../pages/configuraciones/guardia/listarProgramacionGuardia";
import { ListarUsuario } from "../pages/configuraciones/usuario/listarUsuario";
import { ListarRoster } from "../pages/configuraciones/roster/listarRoster";
import { Account } from "../pages/account/account";
import { ListarConfiguracionEmpresa } from "../pages/configuraciones/empresa/listarEmpresa";
import { ListarEmpresaUsuario } from "../pages/configuraciones/empresa/usuario/listarEmpresaUsuario";
import { ListarProgAdicionalGerencia } from "../pages/adicionales/1.solicitante/tabProgAdicionalGerencia";
import { ListarAdicionalGerencia } from "../pages/adicionales/1.solicitante/tabAdicionalGerencia";
import { ListarProgAreaTransporte } from "../pages/areaTransporte/listarProgAreaTransporte";
import { ListarAccesoPersonaAreaTransporte } from "../pages/areaTransporte/listarAccesoPersonaAreaTransporte";
import CuadroPersona from "../pages/cuadroMando/mandoPersona";
import CuadroMandoProgramacion from "../pages/cuadroMando/mandoProgramacion";
import CuadroMandoTransporte from "../pages/cuadroMando/mandoTransporte";
import { ListarTemporada } from "../pages/temporadas/listarTemporada";
import { ListarPersonaTransporteAsignado } from "../pages/programacionBus/ListarPersonaTransporteAsignado";
import { ConfigHelper, IS_PROD } from "../utils/Helper/configHelper";
import { ListarUsuarioSupervisor } from "../pages/configuraciones/v2.asignacionGerencia/listarUsuarioSupervisor";
import { ListarGerenciaAsignada } from "../pages/configuraciones/v2.asignacionGerencia/listarGerenciaAsignada";
import AuthService from "../services/AuthService";
import { MessageHelper } from "../utils/Helper/messageHelper";
import { TabProgAdicionalLogistica } from "../pages/adicionales/2.aprobador/tabProgAdicionalLogistica";
import { TabAdicionalLogistica } from "../pages/adicionales/2.aprobador/tabAdicionalLogistica";
import { MenuTransporte } from "../pages/transporte/Mantenimiento/MenuTransporte";
import { ListarRegla } from "../pages/regla/listarRegla";
import { ListarHistorico } from "../pages/historico/listarHistorico";
import { ReporteGerencial } from "../pages/historico/reporteGerencial";
import VistaUbicaciones from "../pages/configuraciones/ubicaciones/Mantenimiento/ListaUbicacion";
import { LoginRedireccionamiento } from "../components/Login/LoginRedireccionamiento";
import { EscanerQr } from "../components/EscannerDispositivo/EscanerQr";
import Helos5x2 from "../pages/configuraciones/guardia/Helos5x2";
import TablaGerencias from "../pages/configuraciones/v2.asignacionGerencia/TablaGerencias";

const Verified = () => {
  const { token, signout } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  /* Verificar exp */
  let isExp = AuthService.verifiedExp();
  if (isExp)
    signout(() => {
      //alert(MessageHelper.MESSAGE_EXPIRED);
      sessionStorage.clear();
    });
  function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
  // useEffect(() => {
  //   if (!token) {
  //     //navigate(`/login`);
  //     sessionStorage.clear();
  //     deleteAllCookies();
  //   }
  //   if (isExp)
  //     signout(() => {
  //       //alert(MessageHelper.MESSAGE_EXPIRED);
  //       sessionStorage.clear();
  //       deleteAllCookies();
  //     });
  // }, [token, isExp]);

  if (!token || isExp) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }

  return (
    <>
      <div
        className="ms-Grid"
        dir="ltr"
        style={{
          margin: "0px",
        }}>
        <div className="ms-Grid-row">
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
            style={{
              position: "fixed",
              zIndex: "1000",
              paddingRight: "2px",
              paddingLeft: "2px",
            }}>
            <Navbar />
          </div>
        </div>
      </div>

      <div
        style={{
          paddingTop: "3.7em",
          height: "calc(100vh - 3.7em)",
          display: "flex",
          maxHeight: "calc(100vh - 3.7em)",
          paddingRight: "2px",
          paddingLeft: "2px",
        }}>
        <div>
          <Sidebar />
        </div>

        <div
          style={{
            width: "83%",
            height: "100%",
            overflow: "auto",
          }}>
          <Outlet />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export const ContentRouter = () => {
  return (
    <>
      <Routes>
        <Route path={ConfigHelper.urlLogin()} element={<LoginPrincipal />} />
        {/* <Route path={`/movilizaciones`} element={<Verified />}> */}
        <Route path={`/`} element={<Verified />}>
          {/* Cuadro Mandos */}
          <Route path="cuadro.mando">
            <Route path="" element={<TablaProgramaciones />} />
            <Route path="personas" element={<CuadroPersona />} />
            <Route
              path="programaciones"
              element={<CuadroMandoProgramacion />}
            />
            <Route path="Transporte" element={<CuadroMandoTransporte />} />
          </Route>

          {/* 1. Gestion Personas */}
          <Route path="personas">
            <Route path="" element={<TablaPersona />} />
            <Route path=":id" element={<Perfil />} />
            <Route path="crear" element={<CrearPersona />} />
          </Route>

          {/* <Route path='puestos' element={<PuestosVista />} />
          <Route path='fichas'>
            <Route path='' element={<ListarFicha />} />
            <Route path='crear' element={<FichaRegistro />} /> 
          </Route>*/}

          {/* 2. Gestion de Programaciones */}
          <Route path="programaciones">
            <Route path="" element={<TablaProgramaciones />} />
            <Route path=":id" element={<ListarPersonaPorProgramacion />} />
            {/* <Route path=':id' element={<ListarAccesoPersonaV1 />} /> */}
            <Route path="crear" element={<CrearProgramacion />} />
            <Route path="editar/:id" element={<EditarProgramacion />} />
          </Route>
          <Route path="accesopersona">
            <Route path=":id" element={<CearAccesoPersona />} />
            <Route path="editar/:id" element={<EditarProgPersona />} />
          </Route>

          {/* 3. Unidad Medica */}
          <Route path="prueba.covid">
            <Route path="" element={<ListarProgramacionPruebaCovid />} />
            <Route path=":id" element={<DetalleProgramacionPruebaCovid />} />
          </Route>

          {/* 4. Hoteleria */}
          {/* <Route path='habitaciones'>
            <Route  path='' element={<ListaHabitacionesH />}/>
            <Route path='idHotel=:IdHotel' element={<ListaHabitacionesH />} />
            <Route path='crear=:IdHotel' element={<AgregarHabitacion />} />
          </Route> */}
          <Route path="hoteles">
            <Route path="" element={<TablaHoteles />} />
            <Route path="crear" element={<AgregarHotel />} />
          </Route>

          {/* 5. Transporte */}
          <Route path="empresas">
            <Route path="" element={<ListarEmpresa />} />
            <Route path="registrar" element={<RegistrarEmpresa />} />
          </Route>
          <Route path="vehiculos">
            <Route path="" element={<TablaVehiculos />}></Route>
            <Route path="crear" element={<AgregarVehiculo />}></Route>
            <Route path=":id" element={<EditarVehiculo />}></Route>
            <Route path="lista.validar">
              <Route path="" element={<ListaValidarVehiculo />} />
              <Route path=":id" element={<ValidarVehiculo />} />
            </Route>
          </Route>
          <Route path="empresa.transporte">
            <Route path="" element={<ProgramacionesEmpresaTransporte />} />
            {/* Obsoleto v1 */}
            {/* <Route path='detalle/id=:id' element={<EmpresaTransporteDetalleAsignacion />}/> */}
            {/* Tab Transportes
            Tab Buses
            Tab Helos */}
            <Route path=":id" element={<Asignacion />} /> {/* v2 */}
            <Route
              path="asignado/:id"
              element={<ListarPersonaTransporteAsignado />}
            />
          </Route>
          <Route path="configuracion.transporte">
            <Route path="" element={<MenuTransporte />} />
          </Route>

          {/* 6. Configuraciones */}
          <Route path="configuraciones">
            {/* v1. Asignacion de personas por KeyUser */}
            {/* <Route path='personas' element={<ListarPersona />} /> */}
            <Route path="keyuser" element={<AsignacionGerencia />}>
              {/* <Route path='' element={<KeyUserConfiguracion />} />
              <Route path='asignacion' element={<AsignacionGerencia />} /> */}
            </Route>
            {/* v2. Asignacion Gerecia KeyUser & Gerente Area */}
            <Route path="asignacion.gerencia">
              <Route path="" element={<TablaGerencias />} />
              <Route path="usuarios" element={<ListarUsuarioSupervisor />} />
              <Route path="usuarios/:id" element={<ListarGerenciaAsignada />} />
              {/* <Route path=":id" element={<ListarGerenciaAsignada />} /> */}
            </Route>
            <Route path="usuarios" element={<ListarUsuario />} />
            <Route path="guardias">
              <Route path="" element={<ListarProgramacionGuardia />}></Route>
            </Route>
            <Route path="rosters">
              <Route path="" element={<ListarRoster />} />
            </Route>
            <Route path="ubicaciones">
              <Route path="" element={<VistaUbicaciones />} />
            </Route>
            <Route path="empresas">
              <Route path="" element={<ListarConfiguracionEmpresa />} />
              <Route path="usuarios/:id" element={<ListarEmpresaUsuario />} />
            </Route>
            <Route path="temporadas">
              <Route path="" element={<ListarTemporada />} />
            </Route>
            <Route path="reglas">
              <Route path="" element={<ListarRegla />} />
            </Route>
            <Route path="HeloPax">
              <Route path="" element={<Helos5x2 />} />
            </Route>
          </Route>

          {/* 7. Contratos */}
          <Route path="contrato">
            <Route path="" element={<ListarContrato />} />
            <Route path=":id" element={<DetalleContrato />} />
            <Route path="crear" element={<RegistrarContrato />} />
          </Route>

          {/* 8. Proceso Adicionales */}
          <Route path="adicionales">
            {/* Nivel 1 */}
            <Route path="solicitante">
              <Route path="" element={<ListarProgAdicionalGerencia />} />
              <Route path=":id" element={<ListarAdicionalGerencia />} />
            </Route>

            {/* Nivel 2 */}
            <Route path="aprobador">
              <Route path="" element={<TabProgAdicionalLogistica />} />
              <Route path=":id" element={<TabAdicionalLogistica />} />
            </Route>
          </Route>

          {/* 9. Area Transportes */}
          <Route path="area.transporte">
            <Route path="" element={<ListarProgAreaTransporte />} />
            <Route path=":id" element={<ListarAccesoPersonaAreaTransporte />} />
          </Route>

          {/* 10. Historico & Reporte Gerencial */}
          <Route path="historico" element={<ListarHistorico />} />
          <Route path="reporte.gerencial" element={<ReporteGerencial />} />

          {/* 11. Dispositivos*/}

          {/* Otros */}
          {/* <Route path='transprogramacion' element={<TransProgramaciones />} />
          <Route path='transprogramacion/id=:id' element={<AccesoProgrTransporte />} />
          <Route path='programaciones.hotel' element={<TablaProgramacionesHotel />} />
          <Route path='programaciones.hotel/id=:id' element={<RegistroPersonasHotel />} /> */}

          {/* <Route path="account" element={<Account />} /> */}
          <Route
            path="*"
            element={<Navigate replace to={`${ConfigHelper.urlLogin()}`} />}
          />
        </Route>

        {
          <Route
            path={`${ConfigHelper.urlBase()}/confirmacion/:id`}
            element={<ProgamConfirmacion />}
          />
        }
        <Route
          path="/movilizaciones/personas/escannerQr/:infoWeb"
          element={<EscanerQr />}
        />
      </Routes>
    </>
  );
};
