import {
  CommandBar,
  getTheme,
  ICommandBarItemProps,
  IconButton,
  SearchBox,
} from "@fluentui/react";
import { IconHelper } from "../../utils/Helper/iconHelper";
const _farItems: ICommandBarItemProps[] = [];
const _onSearchText = (
  event?: React.ChangeEvent<HTMLInputElement>,
  newValue?: string
) => { };
const onSearch = (newValue: string) => { };
const onClear = (ev?: any) => { };
const _onIconClik = (ev?: any) => { };

const theme = getTheme();

export function HeadingBar({
  title = "",
  subTitle = "",
  subTitle2 = "",
  itemBar = _farItems,
  searchPlaceholder = "Buscar...",
  searchChange = _onSearchText,
  searchHandler = onSearch,
  searchValue = "",
  searchShow = true,
  onClearHandler = onClear,
}) {
  return (
    <>
      <div>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div
              className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"
              style={{
                paddingTop: "0.5em",
              }}
            >
              <div
                className="ms-fontSize-16"
                style={{
                  color: theme.palette.neutralSecondary,
                  paddingLeft: "20px",
                }}
              >
                {title}
              </div>
              <div
                className="ms-fontSize-12"
                style={{
                  color: theme.palette.neutralSecondaryAlt,
                  paddingLeft: "20px",
                }}
              >
                {subTitle}
              </div>
              <div
                className="ms-fontSize-12"
                style={{
                  color: theme.palette.neutralSecondaryAlt,
                  paddingLeft: "20px",
                }}
              >
                {subTitle2}
              </div>
            </div>

            <div
              className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"
              style={{
                paddingTop: "0.5em",
              }}
            >
              {searchShow && (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col  ms-lg11">
                    <SearchBox
                      // underlined
                      placeholder={searchPlaceholder}
                      onSearch={searchHandler}
                      onChange={searchChange}
                      value={searchValue}
                      autoComplete="off"
                      onClear={onClearHandler}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"
              style={{
                paddingRight: "0px",
              }}
            >
              {_farItems.length > 0 ||
                (itemBar.length > 0 && (
                  <CommandBar
                    items={_farItems}
                    farItems={itemBar}
                    ariaLabel="Inbox actions"
                    primaryGroupAriaLabel="Email actions"
                    farItemsGroupAriaLabel="More actions"
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
