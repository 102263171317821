import { fetchAdministracion } from "./http-common";

const urlUbigeo = 'Ubigeo';


const detalleById = (id: string) => {
    return fetchAdministracion.get(`${urlUbigeo}/Detalle/${id}`);
}

const UbigeoService = {
    detalleById,
}

export default UbigeoService;