import { ShimmerElementType } from "@fluentui/react";

const shimmerWithElementFirstRow = [
    { type: ShimmerElementType.line, height: 32 },
];

const shimmerWithElementSecondRow2 = [
    { type: ShimmerElementType.line, height: 32, width: '50%' },
    { type: ShimmerElementType.gap, width: '5%' },
    { type: ShimmerElementType.line, height: 32, width: '50%' },
];

const shimmerWithElementSecondRow3 = [
    { type: ShimmerElementType.line, height: 32, width: '20%' },
    { type: ShimmerElementType.line, height: 32 },
    { type: ShimmerElementType.gap, width: '15%' },
    { type: ShimmerElementType.line, height: 32, width: '20%' },
    { type: ShimmerElementType.line, height: 32 },
    { type: ShimmerElementType.gap, width: '15%' },
    { type: ShimmerElementType.line, height: 32, width: '20%' },
    { type: ShimmerElementType.line, height: 32 },
];

export const CrearAccesoShimmer = {
    shimmerWithElementFirstRow,
    shimmerWithElementSecondRow2,
    shimmerWithElementSecondRow3,
}