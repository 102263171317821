import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, Panel, PanelType, PrimaryButton, ProgressIndicator, SelectableOptionMenuItemType, Stack } from "@fluentui/react"
import React, { useEffect, useState } from "react";
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar";
import { ICheckItemComboBaseDTO, IException, IItemComboBaseDTO, ISearch } from "../../../../interfaces/base/IBase";
import { IAsignarMultipleOrganigrama } from "../../../../interfaces/ISupervisorOrganigrama";
import OrganigramaService from "../../../../services/organigramaService";
import { ItemNoneSelect, ItemSelectAll, KeyAllSelect, splitExceptionDetail, stackTokens, _initialFilter } from "../../../../utils/Helper/helper";
import ThemeBase from "../../../../utils/Theme/themeBase";
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

export const AsignarOrganigrama = ({ supervisorId = '', tipo = 0, isOpen = false, dissmiss = () => { }, reloadInitial = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Form */
    const [gerencia, setGerencia] = useState<ICheckItemComboBaseDTO[]>([]);
    const [form, setForm] = useState<IAsignarMultipleOrganigrama>({
        supervisorId: supervisorId,
        tipo: tipo
    });

    /* Filter */
    const [filterGerencia, setFilterGerencia] = useState<ISearch>(_initialFilter);

    /* Event Multiselect */
    const [selectedKeyGerencia, setSelectedKeyGerencia] = React.useState<string[]>([]);

    const selectableOptionGerencia = gerencia.filter(
        option =>
            (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
    );

    /* Hook */
    useEffect(() => {
        if (isOpen) {
            dataGerencia();
        }
    }, [isOpen]);

    /* Api */
    const dataGerencia = () => {
        setGerencia([]);
        OrganigramaService.allOrganigramaAsignado(supervisorId, tipo)
            .then((res) => {
                if (res.status == 200) {
                    let t: ICheckItemComboBaseDTO[] = res.data;
                    t.unshift({
                        ...ItemSelectAll(),
                        isChecked: false,
                    });

                    setGerencia(t);
                    setSelectedKeyGerencia(t.filter(x => x.isChecked).map(x => x.key));
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const asignarGerencia = () => {
        if (form.organigramaLst?.length == 0) {
            return alert('Debe seleccionar al menos una gerencia');
        }

        setIsSend(true);

        let f: IAsignarMultipleOrganigrama = {
            ...form,
            organigramaLst: selectedKeyGerencia.filter(x => x != ItemSelectAll().key),
        }

        OrganigramaService.asignarMultipleGerencia(f).then(res => {
            if (res.status == 200) {
                setIsSend(false)
                refForm()
                setChoice('send');
                reloadInitial();
            }
        }).catch((e: IException) => {
            setIsSend(false);
            setChoice('error')
            settextError(splitExceptionDetail(e))
        })
    }

    /* Event */
    const onChangeGerencia = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeyGerencia.filter(key => key !== KeyAllSelect);
        const selectAllState = currentSelectedOptionKeys.length === selectableOptionGerencia.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setSelectedKeyGerencia([])
                    : setSelectedKeyGerencia(['selectAll', ...selectableOptionGerencia.map(o => o.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === selectableOptionGerencia.length) {
                    updatedKeys.push(KeyAllSelect);
                }
                setSelectedKeyGerencia(updatedKeys);
            }
        }
    }

    /* Callback reload */
    const refForm = React.useCallback(() => {
        resetChoice();
    }, [dissmiss])

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={asignarGerencia} styles={ThemeBase.panelButtonStyle} disabled={isSend}>
                    Asignar
                </PrimaryButton>
                <DefaultButton onClick={dissmiss}>Cancelar</DefaultButton>
            </div>
        ),
        [isSend, supervisorId, isOpen, dissmiss, form, selectedKeyGerencia]
    );

    const options: IChoiceGroupOption[] = [
        { key: 'day', text: 'MMGG', /* iconProps: { iconName: 'CalendarDay' }  */},
        { key: 'week', text: 'SSEE', /* iconProps: { iconName: 'CalendarWeek' }  */},
        { key: 'month', text: 'Ambos', /* iconProps: { iconName: 'Calendar' }, disabled: true  */},
    ];

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dissmiss}
                type={PanelType.medium}
                headerText='Asignar Gerencia'
                closeButtonAriaLabel='Close'
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <div style={{
                    paddingTop: '5%',
                }}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description="Enviando espere un momento" barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    refForm()
                                    resetChoice()
                                }}></CustomMessageBar>}
                            </div>
                        </div>

                        <form ref={refForm} hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                            <Stack tokens={stackTokens}>
                                <div className="ms-Grid" dir="ltr">
                                    <Stack tokens={stackTokens}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComboBox
                                                    label="Gerencia"
                                                    multiSelect
                                                    options={gerencia}
                                                    selectedKey={selectedKeyGerencia}
                                                    onChange={onChangeGerencia}
                                                    useComboBoxAsMenuWidth
                                                    calloutProps={{ directionalHintFixed: true }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ChoiceGroup label="Tipo" defaultSelectedKey="day" options={options} />
                                            </div>
                                        </div> */}
                                    </Stack>
                                </div>
                            </Stack>
                        </form>
                    </div>
                </div>
            </Panel>
        </>
    )
}