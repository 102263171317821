import {
  FocusTrapZone,
  ICommandBarItemProps,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import HeaderGeneric from "../../components/Generic/headerPanel";
import { urlTelerik } from "../../services/http-common";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import TokenService from "../../services/TokenService";
import { useEffect, useRef } from "react";

const NameReport = "MOVCuadroMandoTrasporte.trdx";

const CuadroMandoTransporte = () => {
  let t = TokenService.getToken();
  const viewerRef = useRef(); //equivalnete id de componente
  const _rigthBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_INFO,
        // onClick: ,
      },
    ];
    return _items;
  };

  const EnviarToken = () => {
    var { viewerObject } =
      viewerRef.current != null ? viewerRef.current : viewerObject;
    viewerObject.authenticationToken(t);
  };

  useEffect(() => {
    EnviarToken();
  }, [t]);

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <br />
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
            <HeaderGeneric
              title="Cuadro Mando Transporte"
              subTitle="Gestión transporte"
            />
          </div>
          <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
            {/* <CustomCommandBar
                                farItem={_rigthBar()}
                                style={{ paddingRight: "0px", paddingLeft: "0px" }}
                            ></CustomCommandBar> */}
          </div>
        </div>
        <br />
      </div>

      <FocusTrapZone
        disabled={false}
        isClickableOutsideFocusTrap
        forceFocusInsideTrap={true}>
        <TelerikReportViewer
          ref={viewerRef}
          serviceUrl={urlTelerik}
          reportSource={{
            parameters: { editors: { multiSelect: {} } },
            report: NameReport,
          }}
          viewerContainerStyle={ThemeBase.telerikStyle}
          viewMode="INTERACTIVE"
          scaleMode="FIT_PAGE_WIDTH"
          scale={1.0}
          enableAccessibility={false}
        />
      </FocusTrapZone>
    </>
  );
};

export default CuadroMandoTransporte;
