import {
  ComboBox,
  DatePicker,
  IComboBox,
  IComboBoxOption,
  IPersonaSharedProps,
  Panel,
  PanelType,
  Persona,
  PersonaPresence,
  PersonaSize,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { ButtonForm } from "../../components/Generic/buttonForm";
import {
  IBase,
  IException,
  IItemComboBaseDTO,
  ISearch,
} from "../../interfaces/base/IBase";
import {
  IDataUpdateEmpTrans,
  IListarPersonaById,
  IPersona,
} from "../../interfaces/IPersona";
import PersonaService from "../../services/PersonaService";
import ProgramacionService from "../../services/ProgramacionService";
import UbicacionService from "../../services/ubicacionesServices";
import { _formatDatePrefix } from "../../utils/Helper/datetimeHelper";
import {
  splitExceptionDetail,
  stackTokens,
  _initialFilter,
} from "../../utils/Helper/helper";

let _openPanel: boolean;
let _headerPanelName: string;
const _dismissPanel = () => {};
let _dataDetailId: IPersona;
const _setup = () => {};

const INITIAL_MODEL = {
  personaId: "string",
  viajeAutorizadoId: "19f948eb-170d-419c-9ee7-4ed251922627",
  lugarMovilizacionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  esCritico: true,
  esTitular: true,
};

function EditarPersonaEmpTrans({
  openPanel = _openPanel,
  dismissPanel = _dismissPanel,
  dataDetail = _dataDetailId,
  setup = _setup,
}) {
  const [inputFrom, setInputForm] =
    useState<IDataUpdateEmpTrans>(INITIAL_MODEL);
  const [lugarMovilizacion, setLugarMovilizacion] = useState<
    IItemComboBaseDTO[]
  >([]);
  const [viajeAutorizado, setviajeAutorizado] = useState<IItemComboBaseDTO[]>(
    []
  );

  const movilizacionRef = React.useRef<IComboBox>(null);
  const _openOptionMovilizacion = React.useCallback(
    () => movilizacionRef.current?.focus(true),
    []
  );

  const viajeAutorizadoRef = React.useRef<IComboBox>(null);
  const _openOptionViajeAutorizado = React.useCallback(
    () => viajeAutorizadoRef.current?.focus(true),
    []
  );

  /* Filters */
  const [filterMovilizacion, setfilterMovilizacion] =
    useState<ISearch>(_initialFilter);

  const [filterViajeAutorizado, setfilterViajeAutorizado] =
    useState<ISearch>(_initialFilter);

  useEffect(() => {
    setInputForm({
      ...inputFrom,
      persona: dataDetail?.persona,
      personaId: dataDetail?.personaId,
      viajeAutorizadoId: dataDetail?.viajeAutorizadoId,
      lugarMovilizacionId: dataDetail?.lugarMovilizacionId,
      esCritico: dataDetail?.esCritico,
      esTitular: dataDetail?.esTitular,
    });
    dataMovilizacion();
    dataViajeAutorizado();
  }, [dataDetail]);

  /**Evet combo lugar de movilizacion */
  const dataMovilizacion = () => {
    ProgramacionService.allLugarMovilizacion()
      .then((res) => {
        setLugarMovilizacion(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const _onInputMovilizacion = (e?: any) => {
    _openOptionMovilizacion();
    let { value } = e?.target as HTMLInputElement;
    setInputForm({
      ...inputFrom,
      lugarMovilizacionId: "",
    });
    setfilterMovilizacion({
      ...filterMovilizacion,
      search: value,
    });
  };

  const _onChangeMovilizacion = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != null) {
      if (option != null) {
        setInputForm({
          ...inputFrom,
          lugarMovilizacionId: option.key.toString(),
        });
      }
    }
  };

  const _onBlurMovilizacion = () => {
    if (
      inputFrom.lugarMovilizacionId == "" ||
      inputFrom.lugarMovilizacionId == undefined
    ) {
      setfilterMovilizacion({
        ...filterMovilizacion,
        search: "",
      });
    }
  };

  /**Evet Combo Viaje Autorizado */
  const _onChangeAutorizado = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != null) {
      if (option != null) {
        console.log(option);
        setInputForm({
          ...inputFrom,
          viajeAutorizadoId: option.key.toString(),
        });
      }
    }
  };
  const dataViajeAutorizado = () => {
    UbicacionService.comboUbicaciones(4)
      .then((res) => {
        let d: IBase[] = res.data.data;
        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });
        setviajeAutorizado(t);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const _onInputViajeAutorizado = (e?: any) => {
    _openOptionViajeAutorizado();
    let { value } = e?.target as HTMLInputElement;
    setInputForm({
      ...inputFrom,
      viajeAutorizadoId: "",
    });
    setfilterViajeAutorizado({
      ...filterViajeAutorizado,
      search: value,
    });
  };
  const _onBlurViajeAutorizado = () => {
    if (
      inputFrom.viajeAutorizadoId == "" ||
      inputFrom.viajeAutorizadoId == undefined
    ) {
      setfilterViajeAutorizado({
        ...filterViajeAutorizado,
        search: "",
      });
    }
  };

  const _updateDataPersona = () => {
    PersonaService.putPersonaEmpTransp(inputFrom)
      .then((res) => {
        if (res.status == 200) {
          alert("Datos Actulizados");
          dismissPanel();
          setup();
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        dismissPanel();
        setup();
      });
  };

  const onRenderFooter = () => (
    <div>
      <ButtonForm
        back={dismissPanel}
        register={_updateDataPersona}
      ></ButtonForm>
    </div>
  );

  const _onChangeEsTitular = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setInputForm({
      ...inputFrom,
      esTitular: checked ? true : false,
    });
  };

  const _onChangeEsCritico = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setInputForm({
      ...inputFrom,
      esCritico: checked ? true : false,
    });
  };

  /**Detalle corto de Persona */
  const detailPersona: IPersonaSharedProps = {
    imageUrl: dataDetail?.foto,
    imageInitials: dataDetail?.primerNombre.charAt(0).toUpperCase(),
    text: dataDetail?.persona,
    secondaryText: dataDetail?.gerencia,
  };

  return (
    <>
      <Panel
        isOpen={openPanel}
        onDismiss={dismissPanel}
        headerText="Actualizacion Persona - Rol Transportista"
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooter}
        isFooterAtBottom={true}
      >
        <form>
          <div style={{ paddingTop: "20%" }}>
            <Stack tokens={stackTokens}>
              <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-lg12">
                    <Persona
                      {...detailPersona}
                      size={PersonaSize.size56}
                      presence={PersonaPresence.none}
                      imageAlt={dataDetail?.primerNombre}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <ComboBox
                      componentRef={movilizacionRef}
                      label="Lugar de Movilización"
                      allowFreeform
                      options={lugarMovilizacion}
                      calloutProps={{ directionalHintFixed: true }}
                      selectedKey={
                        inputFrom ? inputFrom.lugarMovilizacionId : ""
                      }
                      useComboBoxAsMenuWidth
                      openOnKeyboardFocus
                      autoComplete="off"
                      onInput={_onInputMovilizacion}
                      onChange={_onChangeMovilizacion}
                      onBlur={_onBlurMovilizacion}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <ComboBox
                      componentRef={viajeAutorizadoRef}
                      label="Viaje Autorizado"
                      allowFreeform
                      options={viajeAutorizado}
                      calloutProps={{ directionalHintFixed: true }}
                      selectedKey={inputFrom ? inputFrom.viajeAutorizadoId : ""}
                      useComboBoxAsMenuWidth
                      onChange={_onChangeAutorizado}
                      onInput={_onInputViajeAutorizado}
                      onBlur={_onBlurViajeAutorizado}
                      required
                    />
                  </div>
                </div>
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <Toggle
                      label="Es Critico"
                      placeholder="Es Critico"
                      onText="Si"
                      offText="No"
                      defaultChecked={inputFrom?.esCritico}
                      onChange={_onChangeEsCritico}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <Toggle
                      label="Es Titular"
                      placeholder="Es Titular"
                      defaultChecked={inputFrom?.esTitular}
                      onText="Si"
                      offText="No"
                      onChange={_onChangeEsTitular}
                    />
                  </div>
                </div>
              </div>
            </Stack>
          </div>
          {/* {JSON.stringify(persona.nroDOI, null, 2)}
          {JSON.stringify(persona.viajeAutorizadoId, null, 2)}
          {JSON.stringify(persona.lugarMovilizacionId, null, 2)} */}
        </form>
      </Panel>
    </>
  );
}

export default EditarPersonaEmpTrans;
