import { DefaultButton, PrimaryButton } from "@fluentui/react"
// import { useNavigate } from "react-router-dom";



export const GenButtonAdd = (props: any) => {
    /* let navigate = useNavigate();
    function goBack() {
        navigate(props.goback)
    } */
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end',alignItems:'flex-end' }}>
            <DefaultButton
                text="Cancelar"
                style={{ borderColor: 'red', color: 'red' }}
                onClick={props.cancelar}
                href={props.href1}
            />
            <PrimaryButton
                type="submit"
                text={props.text}
                style={{ marginLeft: '20px' }}
                onClick={props.guardar}
                href={props.href2}
            />
        </div>
    )
}