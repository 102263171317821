import {
  Panel,
  PanelType,
  Stack,
} from "@fluentui/react";
import React from "react";
import { IpanelGeneric } from "../../interfaces/base/IPanel";
import { ButtonForm } from "./buttonForm";
import HeaderGeneric from "./headerPanel";
import { useBoolean } from "@fluentui/react-hooks";


export const PanelGenerico = (props: IpanelGeneric) => {
  const onRenderHeader = React.useCallback(
    () =>
      props.isOnRenderHeader ? (
        <HeaderGeneric
          iconHeader={props.disabledIconHeader}
          iconName={props.iconHeaderName}
          title={props.title}
          subTitle={props.subTitle}
          subTitle2={props.subTitle2}
        ></HeaderGeneric>
      ) : (
        <></>
      ),
    [props.isOpen]
  );
  const onRendeFooter = React.useCallback(
    () =>
      !props.disabledFooter ? (
        <div style={{ padding: "1em"}}>
          <ButtonForm
            isBack={props.isBack}
            back={props.cancelar}
            register={props.registrar}
            textRegister={props.textRegister}
            disabledRegister={props.disabledRegister}
          ></ButtonForm>
        </div>
      ) : (
        <></>
      ),
    [props.isOpen,props.onDismiss]
  );
  return (
    <Panel
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      type={PanelType.medium}
      closeButtonAriaLabel="Close"
      isLightDismiss={true}
      onRenderHeader={onRenderHeader}
      onRenderFooter={props.disabledFooter? props.onRendeFooter : onRendeFooter}
      onRenderBody={props.onRenderBody}
      isFooterAtBottom={true}
    >
      {props.children}
    </Panel>
  );
};