import { IColumn, ICommandBarItemProps, Persona, PersonaSize, TooltipHost } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { AuthContext } from "../../../auth/context/auth-context";
import { IUserAuthenticate } from "../../../auth/interface/IAuth";
import RepoteBase from "../../../components/Generic/ReporteBase"
import { TableBase } from "../../../components/Table/TableBase"
import { IException, IReportTelerik } from "../../../interfaces/base/IBase";
import AuthService from "../../../services/AuthService";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { CLAIM_EMPRESA_USUARIO_ID, isRoleAccessAll, roleAdmin, ROLE_TRANSPORTE } from "../../../utils/Helper/roleHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { IFilterProgramacion, IProgramacionPersona } from "../../../interfaces/IProgramacion";
import { baseImageUrl, splitExceptionDetail, _initialFilter, _initialPagination } from "../../../utils/Helper/helper";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ProgramacionPersonaService from "../../../services/programacionPersonaService";

const reportByRole = (access: IUserAuthenticate | null, id: string) => {
    let role = access?.role ?? [];

    let transporteId = null;

    let isRolAdmin = isRoleAccessAll(role, roleAdmin());
    
    if (!isRolAdmin) {
        let isRolTransporte = isRoleAccessAll(role, [ROLE_TRANSPORTE]);

        if (isRolTransporte) {
            transporteId = AuthService.getInfoToken(CLAIM_EMPRESA_USUARIO_ID);
        }
    }

    let report: IReportTelerik = {
        reportName: 'MOVProgramadoPersonaTransporte.trdx',
        param: {
            programacionId: id,
            EmpresaId: transporteId
        }
    }

    return report;
}

export const ListarInscritoBus = ({ programacionId = '' }) => {
    const navigate = useNavigate();
    const [hidden, setHidden] = useState(false);

    /* Access & Role */
    const { access } = useContext(AuthContext);
    let role = access?.role ?? [];

    /* Data & Colum */
    const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Filter */
    const [filter, setFilter] = useState<IFilterProgramacion>({
        ..._initialFilter(),
        programacionId: programacionId,
      });

    /* Report */
    let reportTelerik = reportByRole(access as IUserAuthenticate, programacionId as string);
    const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    /* Hook */
    useEffect(() => {
        if (programacionId != null) {
            reloadInitial();
        }
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    /* Api */
    const dataInscrito = (param = filter) => {
        if (programacionId != '') {
            setData(_initialDataCollection);
            ProgramacionPersonaService.inscritoTransporte(param)
                .then((res) => {
                    if (res.status == 200) {
                        setData(res.data);
                        setHidden(true);
                        setTotal(res.data.total);
                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));
                    setHidden(true);
                });
        }
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setTitleColumn();
        dataInscrito(param);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => { navigate(`${ConfigHelper.urlBase()}/empresa.transporte`) },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },
        ];

        return _items;
    };

    const _rightBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: openTelerik,
            },
        ];

        return _items;
    };

    const setTitleColumn = () => {
        let col: IColumn[] = [
          {
            key: "column1",
            name: "Foto",
            ariaLabel:
              "Column operations for File type, Press to sort on File type",
            isIconOnly: true,
            fieldName: "foto",
            minWidth: 45,
            maxWidth: 45,
            onRender: (item: IProgramacionPersona) => (
              <TooltipHost content={`Foto`}>
                <Persona
                  text=""
                  size={PersonaSize.size24}
                  imageUrl={baseImageUrl}
                />
              </TooltipHost>
            ),
          },
          {
            key: "column2",
            name: "Nombre",
            fieldName: "nombres",
            minWidth: 160,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            data: "string",
            isPadded: true,
            onRender: (item: IProgramacionPersona) => {
              return (
                <span
                  style={{
                    fontSize: "90%",
                    textDecorationLine:
                      item.motivoRetiro == "" ? "none" : "line-through",
                  }}
                >
                  {item.nombres}
                </span>
              );
            },
          },
          {
            key: "column3",
            name: "Identificación",
            fieldName: "nroDOI",
            minWidth: 80,
            maxWidth: 90,
            isResizable: true,
            data: "number",
            isPadded: true,
            onRender: (item: IProgramacionPersona) => {
              return (
                <span
                  style={{ fontSize: "95%" }}
                >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
              );
            },
          },
          {
            key: "column40",
            name: "Gerencia",
            fieldName: "gerencia",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: true,
            data: "number",
          },
          {
            key: "column41",
            name: "Email",
            fieldName: "email",
            minWidth: 170,
            maxWidth: 180,
            isResizable: true,
            isCollapsible: true,
            data: "number",
          },
          {
            key: "column5",
            name: "Celular",
            fieldName: "celular",
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isCollapsible: true,
            data: "string",
            isPadded: true,
          },
          {
            key: "column9.1",
            name: "Empresa Persona",
            fieldName: "empresa",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: true,
            data: "string",
          },
          {
            key: "column10",
            name: "Empresa Transportista",
            fieldName: "transporte",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: true,
            data: "string",
          },
          {
            key: "column11",
            name: "Turno",
            fieldName: "turno",
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: "string",
          },
          {
            key: "column12",
            name: "Usuario Registra",
            fieldName: "nombreUsuarioRegistra",
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: "string",
          },
          {
            key: "column14",
            name: "En Espera",
            fieldName: "enEspera",
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: "string",
          },
          {
            key: "column14.1",
            name: "Es Adicional",
            fieldName: "esAdicional",
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: "string",
          },
          {
            key: "column14.2",
            name: "Es Comunidad",
            fieldName: "esComunidad",
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: "string",
          },
          {
            key: "column16",
            name: "Proceso - Estado",
            fieldName: "nivelActual",
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
            isCollapsible: true,
            data: "number",
          },
          {
            key: "column18",
            name: "Lugar Movilización",
            fieldName: "lugarMovilizacion",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: true,
            data: "number",
          },
          {
            key: "column6",
            name: "Origen",
            fieldName: "origen",
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: "string",
            isPadded: true,
          },
          {
            key: "column6",
            name: "Destino",
            fieldName: "destino",
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: "string",
            isPadded: true,
          },
          {
            key: "column19",
            name: "Centro Costo",
            fieldName: "centroCosto",
            minWidth: 150,
            maxWidth: 160,
            isResizable: true,
            isCollapsible: true,
            data: "number",
          },
        ];
    
        setColumn(col);
      };

    /* Event Tab */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterProgramacion = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    return (
        <>
            <TableBase leftBar={_leftBar()} rigthBar={_rightBar()} column={column} data={data.items} pagination={pager} style={ThemeBase.notPaddingTableBase} isLoading={!hidden}></TableBase>

            {/* Panel - Reporte - Telerik */}
            <RepoteBase
                NameReport={reportTelerik.reportName}
                Parameters={reportTelerik.param}
                openTelerik={isOpenTelerik}
                dismissTelerik={dismissTelerik}
            ></RepoteBase>
        </>
    )
}