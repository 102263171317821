import { ComboBox, IComboBox, IComboBoxOption, ICommandBarItemProps, Label, Stack, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { HeadingBar } from "../../components/Generic/HeadingBar"
import SpinnerCenter from "../../components/Generic/SpinnerCenter";
import TitleSeparator from "../../components/Generic/TitleSeparator";
import { IBase, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import { IEditTransporte } from "../../interfaces/ITransporte";
import PersonaService from "../../services/PersonaService";
import TransporteService from "../../services/transporteService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { getBase64, stackTokens } from "../../utils/Helper/helper";

export const EditarVehiculo = () => {
    const { id } = useParams();
    let navigate = useNavigate();

    const [marca, setMarca] = useState<IItemComboBaseDTO[]>([]);
    const [modelo, setModelo] = useState<IItemComboBaseDTO[]>([]);
    const [tipo, setTipo] = useState<IItemComboBaseDTO[]>([]);
    const [categoria, setCategoria] = useState<IItemComboBaseDTO[]>([]);
    const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
    const [hidden, setHidden] = useState(false);
    const [model, setModel] = useState<IEditTransporte>(null!);
    const [isComplete, setIsComplete] = useState(false);

    /* Effect */
    useEffect(() => {
        if (id != undefined) {
            dataMarca();
            dataTipo();
            dataCategoria();
            dataEmpresa();
            infoVehiculo();
        }
    }, [id]);

    /* Validar campos requeridos */
    useEffect(() => {
        setIsComplete(_validateForm);
    }, [model]);

    /* Api */
    const infoVehiculo = () => {
        if (id != undefined) {
            TransporteService.detalleTransporteById(id).then(res => {
                let d: IEditTransporte = res.data;

                if (d.marcaId) {
                    dataModeloByMarcaId(d.marcaId);
                }

                setModel(d);
                setHidden(true);
            }
            ).catch((e: Error) => {
                alert(e.message);
            })
        }
    }

    const dataMarca = () => {
        TransporteService.allMarca().then(res => {
            setMarca(res.data);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataModeloByMarcaId = (marcaId: string) => {
        TransporteService.allModeloByMarcaId(marcaId).then(res => {
            setModelo(res.data);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataTipo = () => {
        TransporteService.allTipo().then(res => {
            setTipo(res.data);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataCategoria = () => {
        TransporteService.allCategoria().then(res => {
            setCategoria(res.data);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataEmpresa = () => {
        PersonaService.transporteHabilitado().then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                });
            });

            setEmpresa(t);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const editTransporte = (state: boolean) => {
        if (!model) {
            return alert('Datos incompletos');
        }

        if (state) {
            if (!model.placa || model.placa == '') {
                return alert('Debe de seleccionar una placa');
            }

            if (!model.modeloId || model.modeloId == '') {
                return alert('Debe de seleccionar un modelo');
            }

            if (!model.tipoTransporteId || model.tipoTransporteId == '') {
                return alert('Debe de seleccionar un tipo transporte');
            }

            if (!model.categoriaId || model.categoriaId == '') {
                return alert('Debe de seleccionar una categoría');
            }

            if (!model.empresaId || model.empresaId == '') {
                return alert('Debe de seleccionar una empresa');
            }

            if (!model.anioFabricacion) {
                return alert('Debe de seleccionar un año válido');
            }
        }

        model.isBorrador = state;
        _sendEmail()
        TransporteService.actualizarTransporte(model).then((res) => {
            if (res.status == 200) {
                alert('Se actualizó correctamente');
                goBack();
            }
        }).catch((e) => {
            alert(e.message);
        })
    }

    /*Email pendientes aprobacion*/
    const _sendEmail = () => {
        if (model.empresaId != '')
            TransporteService.emailPendienteAprobacion("5ad0793d-f40f-4340-bceb-39da2a4cfbb0").then((res) => {
                if (res.status == 200) {
                    console.log("email enviado")
                }
            })
    }

    /* Function */
    const goBack = () => {
        navigate(`${ConfigHelper.urlBase()}/vehiculos`);
    }

    const _generateItemBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                key: 'Back',
                text: 'Atrás',
                iconProps: { iconName: 'Back' },
                onClick: goBack,
            },
        ];

        return _items;
    }

    const _validateForm = () => {
        let st = true;

        if (!model) {
            return false;
        }

        if (model.adjunto.length > 0) {
            let reqLst = model.adjunto.filter(x => x.required);
            if (reqLst.length > 0 && reqLst.filter(x => (x.file == '' || !x.file)).length > 0) {
                /* Validar en la info obtenida */
                let current = reqLst.filter(x => x.required && x.url == '');

                if (current.length > 0) {
                    st = false;
                }
            }
        }

        if (model) {
            if (!model.modeloId || model.modeloId == '') {
                st = false;
            }

            if (!model.tipoTransporteId || model.tipoTransporteId == '') {
                st = false;
            }

            if (!model.categoriaId || model.categoriaId == '') {
                st = false;
            }

            if (!model.empresaId || model.empresaId == '') {
                st = false;
            }

            if (!model.placa || model.placa == '') {
                st = false;
            }

            if (!model.pasajeros || model.pasajeros <= 0) {
                st = false;
            }
        }

        return st;
    }

    const _uploadFile = (ev: React.ChangeEvent, id: string) => {
        let { files } = ev.target as HTMLInputElement;

        if (files && files[0]) {
            let f = files[0];
            getBase64(f).then(
                data => {
                    let ot = model.adjunto.map(x => {
                        if (x.id == id) {
                            return ({
                                ...x,
                                archivo: f.name,
                                file: data as string,
                                extension: `.${f.name.split('.').pop()}` as string,
                            });
                        } else {
                            return x;
                        }
                    })

                    setModel({
                        ...model,
                        adjunto: ot,
                    });
                }
            );
        } else {
            let ot = model.adjunto.map(x => {
                if (x.id == id) {
                    return ({
                        ...x,
                        archivo: '',
                        file: '',
                        extension: '',
                    });
                } else {
                    return x;
                }
            })

            setModel({
                ...model,
                adjunto: ot,
            });
        }
    }

    /* Event */
    const _onChangeMarca = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                dataModeloByMarcaId(option.key.toString());
            }
        }
    }

    const _onChangeModelo = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    modeloId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeTipo = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    tipoTransporteId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeCategoria = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    categoriaId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeEmpresa = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    empresaId: option.key.toString(),
                })
            }
        }
    }

    const _onChangePlaca = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                placa: newValue,
            });
        }
    }

    const _onChangeCodigoInterno = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                codigoInterno: newValue.toString(),
            });
        }
    }

    const _getErrorMessagePeriodo = (value: string): string => {
        let msg = '';

        if (value == '') {
            /* msg = `El valor no puede ser vacío`; */
        } else if (isNaN(value as any)) {
            msg = `Debe de seleccionar un año válido`;
        } else if (value.length != 4) {
            msg = `Debe de seleccionar un año válido`;
        } else {
            msg = ``;
        }

        return msg;
    };

    const _onChangeAnio = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let val = event?.target as HTMLInputElement;

        let an: number | null = null;

        if (val) {
            if (parseInt(val.value)) {
                an = parseInt(val.value);
            }
        }

        setModel({
            ...model,
            anioFabricacion: an,
        });
    }

    const _onChangePasajero = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                pasajeros: parseInt(newValue),
            });
        }
    }

    const _onChangeColor = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                color: newValue,
            });
        }
    }

    const _onChangeTarjeta = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                tarjetaPropiedad: newValue,
            });
        }
    }

    return (
        <>
            <HeadingBar
                searchShow={false}
                itemBar={_generateItemBar()}
            ></HeadingBar>

            <SpinnerCenter isLoad={!hidden} />

            {hidden &&
                <form>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg2"></div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8" style={{
                                padding: '5%',
                            }}>
                                <div className="ms-depth-8" style={{
                                    padding: '15px',
                                }}>
                                    <TitleSeparator title='Validar información de Vehiculo' icon='BusSolid'></TitleSeparator>

                                    <Stack tokens={stackTokens}>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-fontSize-16" style={{ color: '#4676DD' }}>Datos del vehículo</div>
                                        </div>

                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <ComboBox
                                                        label="Marca"
                                                        autoComplete='off'
                                                        useComboBoxAsMenuWidth
                                                        calloutProps={{ directionalHintFixed: true }}
                                                        options={marca}
                                                        selectedKey={model ? model.marcaId : ''}
                                                        onChange={_onChangeMarca}
                                                    />
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <ComboBox
                                                        label="Modelo"
                                                        autoComplete='off'
                                                        useComboBoxAsMenuWidth
                                                        calloutProps={{ directionalHintFixed: true }}
                                                        options={modelo}
                                                        onChange={_onChangeModelo}
                                                        selectedKey={model ? model.modeloId : ''}
                                                        required
                                                    />
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <ComboBox
                                                        label="Tipo Vehículo"
                                                        autoComplete='off'
                                                        useComboBoxAsMenuWidth
                                                        calloutProps={{ directionalHintFixed: true }}
                                                        options={tipo}
                                                        onChange={_onChangeTipo}
                                                        selectedKey={model ? model.tipoTransporteId : ''}
                                                        required
                                                    />
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <ComboBox
                                                        label="Categoría Vehículo"
                                                        autoComplete='off'
                                                        useComboBoxAsMenuWidth
                                                        calloutProps={{ directionalHintFixed: true }}
                                                        options={categoria}
                                                        onChange={_onChangeCategoria}
                                                        selectedKey={model ? model.categoriaId : ''}
                                                        required
                                                    />
                                                </div>

                                                {/* <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <ComboBox
                                                        label="Empresa"
                                                        autoComplete='off'
                                                        useComboBoxAsMenuWidth
                                                        calloutProps={{ directionalHintFixed: true }}
                                                        options={empresa}
                                                        onChange={_onChangeEmpresa}
                                                        selectedKey={model ? model.empresaId : ''}
                                                        required
                                                    />
                                                </div> */}

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <TextField
                                                        label='Placa'
                                                        placeholder='Placa'
                                                        onChange={_onChangePlaca}
                                                        autoComplete='off'
                                                        value={model ? model.placa : ''} />
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <TextField
                                                        label="Año Fabricación"
                                                        onGetErrorMessage={_getErrorMessagePeriodo}
                                                        onChange={_onChangeAnio}
                                                        autoComplete='off'
                                                        value={model ? model.anioFabricacion?.toString() : ''} />
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <TextField
                                                        label='Pasajeros'
                                                        placeholder='Pasajeros'
                                                        onChange={_onChangePasajero}
                                                        autoComplete='off'
                                                        value={model ? (!isNaN(model.pasajeros) ? String(model.pasajeros) : '') : ''}
                                                        required />
                                                </div>


                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <TextField
                                                        label='Codigo Interno'
                                                        placeholder='Codigo'
                                                        onChange={_onChangeCodigoInterno}
                                                        autoComplete='off'
                                                        value={model ? model.codigoInterno : ''} />
                                                </div>

                                            </div>

                                            <br />
                                        </div>

                                        {/* Third option */}
                                        <br />
                                        {model && model.isEdit &&
                                            <>
                                                <ButtonForm back={goBack} register={() => editTransporte(false)} disabledRegister={!isComplete}></ButtonForm>

                                                {/* <div className="ms-Grid" dir="ltr">
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <div className="ms-Grid" dir="ltr">
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                        <DefaultButton text='Borrador' allowDisabledFocus theme={warningButtonTheme} onClick={() => editTransporte(true)} />
                                                                    </div>

                                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                        <ButtonForm back={goBack} register={() => editTransporte(false)} disabledRegister={!isComplete}></ButtonForm>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </>
                                        }
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            }

        </>
    )
}