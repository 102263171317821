import React from "react";
import { Dialog, Icon, DialogFooter, PrimaryButton, DefaultButton, getTheme, IDialogContentProps, FontSizes, ActivityItem, FontIcon } from '@fluentui/react';
import { ITheme } from '@fluentui/react/lib/Styling';
import { NeutralColors } from '@fluentui/theme';

const theme = getTheme();

const _hideDialog: boolean = true;
const _toggleHideDialog = () => { };
const _title: string = "";
const _subText: string = "";
const _iconName: string | undefined = "";
const _onclickButtonPrimary = () => { };
const _nameButtonPrimary: string = "";
const _onclickButtonDefault = () => { };
const _nameButtonDefault: string = "";


const DialogBaseWarning = ({
    hideDialog = _hideDialog,
    toggleHideDialog = () => _toggleHideDialog(),
    title = _title,
    subText = _subText,
    // iconName = _iconName,
    // onclickButtonPrimary = (param: any) => _onclickButtonPrimary(parametro)
    onclickButtonPrimary  = () =>_onclickButtonPrimary()
}) => {

    let param: string = "";
    const myElement = <div>
        <ActivityItem
            style={{
                marginTop: 3,

            }}
            activityDescription={[
                <div>
                    <div className="LayoutPage-demoBlock">
                        <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size14 }}> <strong>¡Advertencia!</strong></div>
                        <br />
                        <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>{subText}</div>

                    </div>
                </div>

            ]}
            activityIcon={
                <FontIcon
                    iconName="Info"
                    style={{
                        //color: "#FFF4CD",
                        color: theme.palette.yellow,
                        fontSize: 35,
                        height: 35,
                        width: 35,
                        marginTop: 45
                        // margin: "0 5px",

                    }}
                />
            }
        />
    </div>

    return (

        <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={{
                title: myElement,
                // subText: subText,
            }}
            styles={{
                main: {
                    // background: "#FED9CC",
                    background: "#fff",
                    //borderTop: `4px solid ${theme.palette.themePrimary}`,
                }
            }}
        >
            <DialogFooter styles={{ actions: { marginTop: "40px" } }}>
                <PrimaryButton
                    onClick={() => {
                        toggleHideDialog();
                        onclickButtonPrimary();

                    }}
                    text="Aceptar"
                    style={{ height: 25, marginTop: 1 }}
                />
            </DialogFooter>
        </Dialog>
    );


}

export { DialogBaseWarning };