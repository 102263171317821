import { DefaultButton, Dialog, DialogFooter, PrimaryButton, ProgressIndicator } from "@fluentui/react"
import React from "react";
import { IDialogBase } from "../../interfaces/base/IComponent"

const dialogStyle = { main: { maxWidth: 500 } };

export const DialogBase = ({ isHidden,
    tipo,
    title,
    subTitle,
    toggleHidden,
    child,
    dependency,
    styles,
    confirm,
    isDisableConfirm,
    titleLoading,
    subTitleLoading
}: IDialogBase): JSX.Element => {
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: styles ?? dialogStyle,
        }),
        dependency,
    );

    return (
        <>
            <Dialog
                hidden={isHidden}
                onDismiss={toggleHidden}
                dialogContentProps={{
                    type: tipo,
                    title: title ?? '¿Está seguro?',
                    subText: subTitle ?? '',
                }}
                modalProps={modalProps}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ textAlign: 'left', paddingLeft: '0px' }}>
                            {child}
                        </div>
                    </div>
                </div>

                {isDisableConfirm &&
                    <ProgressIndicator label={titleLoading} description={subTitleLoading} />
                }

                <DialogFooter>
                    {confirm &&
                        <PrimaryButton onClick={confirm} text='Confirmar' disabled={isDisableConfirm} />
                    }
                    <DefaultButton onClick={toggleHidden} text='Cancelar' />
                </DialogFooter>
            </Dialog>
        </>
    )
}