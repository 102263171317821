import { ComboBox, IButtonStyles, IColumn, IComboBox, IComboBoxOption, IIconProps, IStackItemStyles, IStackStyles, IStackTokens, Icon, Label, MessageBar, MessageBarButton, MessageBarType, Panel, PanelType, Persona, PersonaPresence, PersonaSize, PrimaryButton, ProgressIndicator, Separator, Stack, TextField, Toggle, mergeStyles } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { PanelGenerico } from "../../../components/Generic/PanelGenerico";
import { IItemComboBaseDTO, ISearch } from "../../../interfaces/base/IBase";
import { IConductor, IManifiesto } from "../../../interfaces/IManifiesto";
import PersonaService from "../../../services/PersonaService";
import { baseImageUrl, splitExceptionDetail, stackTokens, _initialFilter } from "../../../utils/Helper/helper";
import { IPersona } from "../../../interfaces/IPersona";

import { useBoolean } from '@fluentui/react-hooks';
import { PanelPlantilla } from "../../../components/PanelUpload/PanelPlantilla";
import ProgramacionBusService from "../../../services/programacionBusService";


const stackStyles: IStackStyles = {
    root: {
        background: "#ffffff",
        paddingTop: 30
    }
};

const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    // padding: 1

}; const verticalStyle = mergeStyles({
    height: '130px',
});

const stackItemStyles: IStackItemStyles = {
    root: {
        background: "#ffff",
        // color: "#217346",
        padding: 0,
    },
};

const btnPrimaryStyle: Partial<IButtonStyles> = {
    root: {
        borderRadius: '10%',
    },
    iconHovered: {
        color: 'white'
    }

}

const AgregarManifiesto = (props) => {

    const [model, setModel] = useState<IManifiesto>(null!);
    const [conductor, setConductor] = useState<IConductor>(null!);

    const [persona, setPersona] = useState<IItemComboBaseDTO[]>([]);
    const [listaConductores, setListaConductores] = useState<IConductor[]>([]);
    const [filterPersona, setFilterPersona] = useState<ISearch>(_initialFilter);
    const [personaIsLoad, setpersonaIsLoad] = useState(false);

    /* Message Error & Choice */
    const plusIcon: IIconProps = { iconName: "CirclePlus" };
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');
    const [tipoMensaje, setTipoMensaje] = useState(0);
    const [mensajeTransaccion, setMensajeTransaccion] = useState("");
    const [verLoading, setVerLoading] = useState(false);
    const [hideTelefonoSatelital, { toggle: toggleHidTelefonoSatelital }] = useBoolean(true);

    const comboBoxRef = React.useRef<IComboBox>(null);
    const _openOptionCondutores = React.useCallback(() => comboBoxRef.current?.focus(true), []);
    const [searchConductor, setSearchConductor] = useState("");

    /* Api */
    const addManifiesto = () => {
        // setIsSend(true);
        console.log("lista conductores ANTES", listaConductores);

        console.log(model);
        let modelManif: IManifiesto = {
            ...model,
            programacionId: props.programacionId,
            transporteId: props.transporteId,
            listaConductores: [...listaConductores]
        };


        console.log("------------ post model Manifiesto --------------");
        console.log(modelManif);

        ProgramacionBusService.actualizarManifiesto(modelManif).then((res) => {
            if (res.status == 200) {
                console.log("Se envió correctamente a BD")
                setMensajeTransaccion("Se ha registrado la información correctamente");
                setTipoMensaje(2);
            }
        }).catch((e) => {
            alert(splitExceptionDetail(e));
        });

    }

    const dataPersona = () => {
        PersonaService.listarPersonaMMG(filterPersona)
            .then((res) => {

                if (res.status === 200) {
                    /* v2 persona */
                    let d: IPersona[] = res.data;
                    let t: IItemComboBaseDTO[] = d.map((x) => {
                        return {
                            key: x.id,
                            text: `${x.nroDOI}  | ${x.persona} | ${x.tipo}`,
                        };
                    });

                    setPersona(t);
                    setpersonaIsLoad(true)
                }

            })
            .catch((e: Error) => {
                alert(e.message);
            });
    };

    useEffect(() => {
        setConductor({
            ...conductor
        })
    }, [props.programacionId])

    useEffect(() => {
        if (props.isOpen) {
            dataPersona()
        }
    }, [props.isOpen])

    useEffect(() => {
        if (!props.isOpen) {
            setListaConductores([]);
        }
    }, [props.isOpen])

    useEffect(() => {
        if (filterPersona.search != null) {
            dataPersona();
        }
    }, [filterPersona]);

    const _onChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setModel({
            ...model,
            tieneTelefonoSatelital: !!checked
        })
        if (checked) {
            toggleHidTelefonoSatelital()
        }

    }
    const _onChangeConductor = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {

                switch (type) {
                    case 'conductor':
                        setConductor({
                            ...conductor,
                            personaId: option.key.toString(),
                            nombre: option.text
                        })
                        console.log("modeloConductor", conductor);
                        break;

                    default:
                        break;
                }
            }
        }
    }

    const _goBack = () => {
        setListaConductores([]);
        setModel({
            kilometraje: "",
            combustible: "",
            transporteId: "",
            numeroConvoy: "",
            numeroTelefonoSatelital: "",
            programacionId: "",
            horaLlegada: "",
            horaSalida: "",
            tieneTelefonoSatelital: false,
            tieneListaVerificacion: false,
            tieneRadioComunicacion: false,
        });
        setMensajeTransaccion("");
        setTipoMensaje(0);
        setFilterPersona({
            ...filterPersona,
            search: null,
        });
        setConductor(null!);
        props.dismiss();
    }

    const handleInputChange = (parameter, value) => {
        const intValue = parseInt(value || '0', 10);
        if (!isNaN(intValue)) {
            setModel({ ...model, [parameter]: String(intValue) });
        }
    };

    const addConductoresTemporal = () => {
        console.log("lista conductores", listaConductores)
        if (listaConductores.length < 3) {
            let estaEnLista = listaConductores.includes(conductor)
            console.log("Esta en lista", estaEnLista)
            if (!estaEnLista) {
                setListaConductores([...listaConductores, conductor]);
            }
        } else {
            setTipoMensaje(1);
            setMensajeTransaccion("Sólo se debe ingresar datos de 3 conductores como máximo")
        }
    }
    const _onBlurCombo = (type: string) => {
        setConductor(null!);
    }
    useEffect(() => {
        console.log(listaConductores)
    }, [listaConductores]);
    const _column: IColumn[] = [
        {
            key: "column1",
            name: "Conductores",
            fieldName: "nombre",
            minWidth: 400,
            maxWidth: 400,
        }
    ]

    const _onChangeSpinButton = (
        evento: React.SyntheticEvent<HTMLElement>,
        opcion?: string
    ) => {
        if (evento !== undefined) {
            if (opcion !== undefined) {
                setModel({
                    ...model,
                    combustible: opcion || "",
                });
            }
        }
    }

    const _onInputPersona = (e: React.KeyboardEvent<IComboBox>) => {
        let { value } = e?.target as HTMLInputElement;
        console.log(value)
        _openOptionCondutores()
        if (value.length > 2) {
            setFilterPersona({
                ...filterPersona,
                search: value,
            });
        } else {
            setFilterPersona({
                ...filterPersona,
                search: "",
            });
        }

    };


    return (
        <>
            {/* <PanelGenerico
                isOpen={props.isOpen}
                onDismiss={props.dismiss}
                title="Agregar Manifiesto"
                subTitle="Completar datos para manifiesto"
                isOnRenderHeader
                cancelar={props.dismiss}
                registrar={addManifiesto}
                ={{...conductor}}
            > */}
            <PanelPlantilla
                textHeader='Agregar Manifiesto'
                subTextHeader='Completar datos para manifiesto'
                textBoton='Agregar'
                onclickBoton={addManifiesto}
                openPanel={props.isOpen}
                closePanel={_goBack}
                size={PanelType.medium}
                disabledBoton={false}
                estadoBarraEnvio={false}
            >
                <>
                    {tipoMensaje == 1 && (
                        <>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                onDismiss={() => setTipoMensaje(0)}
                            >
                                <b>{mensajeTransaccion}</b>{" "}
                            </MessageBar>
                            <br></br>
                        </>
                    )}
                    {tipoMensaje == 2 && (
                        <>
                            <MessageBar
                                actions={
                                    <div>
                                        <MessageBarButton onClick={() => { _goBack() }}>
                                            Conforme
                                        </MessageBarButton>
                                    </div>
                                }
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                            >
                                {mensajeTransaccion}
                            </MessageBar>
                            <br></br>
                        </>
                    )}

                    {!verLoading && (
                        <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                            <div className="ms-Grid" dir="ltr">
                                <br />
                                <Stack tokens={stackTokens}>
                                    <div className="ms-Grid-row">
                                        {/* HORA SALIDA LLEGADA */}
                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                            <TextField
                                                label="Hora Salida"
                                                type="time"
                                                name="horaSalida"
                                                value={model ? model.horaSalida : ""}
                                                onChange={(e, value) => setModel({ ...model, horaSalida: value || "" })}
                                            />
                                            <TextField
                                                label="Hora Llegada"
                                                name="horaLlegada"
                                                type="time"
                                                value={model ? model.horaLlegada : ""}
                                                onChange={(e, value) => setModel({ ...model, horaLlegada: value || "" })}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                            <Stack.Item className={verticalStyle}>
                                                <Separator vertical />
                                            </Stack.Item>
                                        </div>
                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                            <TextField
                                                label="Kilometraje"
                                                name="km"
                                                type="text"
                                                inputMode="numeric"
                                                pattern="^[1-9][0-9]*$"
                                                placeholder="Ingrese kilometraje (en km)"
                                                value={model ? model.kilometraje : ''}
                                                onChange={(e, value) => handleInputChange("kilometraje", value)}
                                                autoComplete="off"
                                                required
                                            />
                                            {/* (sólo enteros) */}
                                            <TextField
                                                label="Combustible"
                                                type="text"
                                                name="combustible"
                                                inputMode="numeric"
                                                pattern="^[1-9][0-9]*$"
                                                placeholder="Ingrese combustible (en %)"
                                                value={model ? model.combustible : ""}
                                                onChange={(e, value) => handleInputChange("combustible", value)}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                            <TextField
                                                label="Nro. de Convoy"
                                                name="numComvoy"
                                                type="text"
                                                inputMode="numeric"
                                                pattern="^[1-9][0-9]*$"
                                                placeholder="Ingrese número de convoy"
                                                value={model ? model.numeroConvoy : ""}
                                                onChange={(e, value) => handleInputChange("numeroConvoy", value)}
                                                autoComplete="off"
                                                required
                                                maxLength={100}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    {/*COMBO CONDUCTORES */}
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7">
                                            <ComboBox
                                                componentRef={comboBoxRef}
                                                openOnKeyboardFocus
                                                // onKeyUp={_openOptionCondutores}
                                                onMouseEnter={_openOptionCondutores}
                                                label="Selecionar Conductores"
                                                id="conductor"
                                                allowFreeform
                                                autoComplete="off"
                                                useComboBoxAsMenuWidth
                                                calloutProps={{ directionalHintFixed: true }}
                                                options={persona}
                                                onInput={_onInputPersona}
                                                // onBlur={() => _onBlurCombo}
                                                onChange={(e, o) => _onChangeConductor("conductor", e, o)}
                                                selectedKey={conductor ? conductor.personaId : ""}
                                                required
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                            <TextField
                                                label="Nro. de Licencia"
                                                name="numLicencia"
                                                placeholder="Ingrese número de licencia"
                                                value={conductor ? conductor.licencia : ""}
                                                onChange={(e, value) => setConductor({ ...conductor, licencia: value || "" })}
                                                autoComplete="off"
                                                required
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                            <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
                                                <Stack.Item align="end" styles={stackItemStyles}>
                                                    <PrimaryButton iconProps={plusIcon} styles={btnPrimaryStyle} onClick={() => addConductoresTemporal()} allowDisabledFocus />
                                                </Stack.Item>
                                            </Stack>
                                        </div>
                                    </div>
                                    {/* LISTA DE CONDUCTORES */}
                                    <div className="ms-Grid-row" style={{ minHeight: 130, paddingTop: 10 }}>
                                        {listaConductores.length === 0 &&
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Separator>Conductor 1</Separator>
                                                <Separator>Conductor 2</Separator>
                                                <Separator>Conductor 3</Separator>
                                            </div>
                                        }
                                        {listaConductores && listaConductores.map((item, index) => {
                                            return <div key={item.personaId}>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ padding: '5px', borderBottom: '1px solid #e1dfdd' }}>
                                                    <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10">
                                                        <Persona
                                                            key={item.personaId}
                                                            imageUrl={baseImageUrl}
                                                            text={item.nombre}
                                                            size={PersonaSize.size32}
                                                            secondaryText={`Licencia (${item.licencia})`}
                                                            imageAlt={item.nombre}
                                                            presence={PersonaPresence.online}
                                                            showSecondaryText={true}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                                        <Icon
                                                            iconName="Delete"
                                                            onClick={() => {
                                                                let aux = listaConductores.filter(x => x.personaId !== item.personaId)
                                                                console.log(aux)
                                                                setListaConductores([...aux])
                                                            }}
                                                            style={{ color: '#b3b0ad', cursor: 'pointer' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <br />
                                    {/* GRUPO DE OPCIONES */}
                                    <div className="ms-Grid-row">
                                        <div hidden={false} className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <Toggle
                                                label="¿Tiene Teléfono Satelital?"
                                                onText="Si"
                                                offText="No"
                                                onChange={_onChange}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <TextField
                                                label="Nro. de Teléfono Satelital"
                                                name="numTelSatelital"
                                                type="text"
                                                inputMode="numeric"
                                                pattern="^[1-9][0-9]*$"
                                                placeholder="Ingrese número de teléfono satelital"
                                                value={model ? model.numeroTelefonoSatelital : ""}
                                                onChange={(e, value) => handleInputChange("numeroTelefonoSatelital", value)}
                                                autoComplete="off"
                                                disabled={hideTelefonoSatelital}
                                            />
                                        </div>
                                    </div>

                                    <div className="ms-Grid-row">
                                        <div hidden={false} className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <Toggle
                                                label="Tiene Radio de Comunicaciones?"
                                                onText="Si"
                                                offText="No"
                                                //onChange={_onToggleRadioCom}
                                                onChange={(e, checked) => setModel({ ...model, tieneRadioComunicacion: !!checked })}
                                            />
                                        </div>
                                        <div hidden={false} className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <Toggle
                                                label="Tiene Lista de Verificación?"
                                                onText="Si"
                                                offText="No"
                                                onChange={(e, checked) => setModel({ ...model, tieneListaVerificacion: !!checked })}
                                            />
                                        </div>
                                    </div>
                                </Stack>
                            </div>
                        </form>
                    )}
                    {verLoading && (
                        <>
                            <br></br>
                            <ProgressIndicator
                                barHeight={5}
                                label="Enviando información"
                                description="Favor Espere un momento"
                            />
                        </>
                    )}
                </>

            </PanelPlantilla>

            {/* </PanelGenerico> */}
        </>
    )
}

export { AgregarManifiesto }