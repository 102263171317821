import { ComboBox, DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack, TextField, Toggle } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { IUbicaciones, IUbicacionNuevo } from "../../../../interfaces/ubicacion/IUbicacion";
import UbicacionService from "../../../../services/ubicacionesServices";
import { useBoolean } from '@fluentui/react-hooks';
interface CrearUbicacionProps {
  isOpen: boolean;
  onDismiss: () => void;
  setUpActualizar: () => void;
}

const CrearUbicacion: React.FunctionComponent<CrearUbicacionProps> = (prop) => {
  const [formularioInputs, setformularioInputs] = useState<IUbicacionNuevo | undefined>();
  // TextField validar ingreso de dato
const [nameData,setNameData]= useState("");
const [codData, setCodData]= useState("");
const [habilitarBoton, sethabilitarBoton]= useState(true)

  function _onChangeLE(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
    setformularioInputs({ ...formularioInputs, lugarDeEmbarque: checked })
  };
  function _onChangeLA(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
    setformularioInputs({ ...formularioInputs, lugarAutorizado: checked })
  };
  function _onChangeVA(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
    setformularioInputs({ ...formularioInputs, viajeAutorizado: checked })
  };


  const onChangeInputNm = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    if (newValue !== undefined) {
      setNameData(newValue)
      setformularioInputs({ ...formularioInputs, nombre: newValue })
    }
  };
  const onChangeInputCod = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    if (newValue !== undefined) {
      setCodData(newValue)
      setformularioInputs({ ...formularioInputs, codigo: newValue })
    }
  };
/* Validaciones para habilitar BOTON REGISTRAR */
  useEffect(()=>{
    if(nameData == "" ||codData == "" ){
      sethabilitarBoton(true)
    }else {
      sethabilitarBoton(false)

    }
  
  },[nameData,codData])

  const onClickRegistrar = () => {
    console.log("click")
    if (formularioInputs !== undefined) {
      UbicacionService.ubicacionCrear(formularioInputs).then(
        (res) => {
          if (res.status == 200) {
            prop.setUpActualizar()
          }
          prop.onDismiss()
        }
      )
        .catch(
          (e: Error) => {
            console.log(e.message)
          }
        )


    }
  }
  const gapb = { childrenGap: 5 }
  const onRenderFooterContent = React.useCallback(
    () => (
      <div>
        <Stack horizontal horizontalAlign="end" tokens={gapb} >
          <PrimaryButton onClick={onClickRegistrar} disabled={habilitarBoton} styles={{ root: { marginLeft: "30px" } }} >
            Registrar
          </PrimaryButton>
          <DefaultButton onClick={prop.onDismiss} styles={{ root: { marginLeft: "0px" } }}>Cancelar</DefaultButton>
        </Stack>
      </div>
    ),
    [formularioInputs],
  );

  return (
    <>
      <Panel
        headerText="Nueva Ubicación"
        isOpen={prop.isOpen}
        onDismiss={prop.onDismiss}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <TextField label="Nombre"  onChange={onChangeInputNm}  />
          <TextField label="Código" onChange={onChangeInputCod} />

          <Stack >
            <Toggle
              label="Lugar de Embarque"
              // defaultChecked
              onText="Sí"
              offText="No"
              onChange={_onChangeLE} />
            <Toggle
              label="Lugar Autorizado"
              // defaultChecked
              onText="Sí"
              offText="No"
              onChange={_onChangeLA} />

            <Toggle
              label="Viaje Autorizado"
              // defaultChecked
              onText="Sí"
              offText="No"
              onChange={_onChangeVA} />
                {/* <pre>{JSON.stringify(nameData, null, 2)}</pre>
                <pre>{JSON.stringify(codData, null, 2)}</pre> */}


          </Stack>
      </Panel>
    </>
  );

}

export default CrearUbicacion;
