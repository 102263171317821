import { IComboBox, IColumn, ICommandBarItemProps, ComboBox, TooltipHost, Persona, PersonaSize, IComboBoxOption } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomCommandBar } from "../../../components/Generic/customCommandBar";
import RepoteBase from "../../../components/Generic/ReporteBase";
import { TableBase } from "../../../components/Table/TableBase";
import { IItemComboBaseDTO, ISearch, IBase, IException } from "../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { IFilterAsignacion, IProgramacionPersona, IFilterPersonaTransporte } from "../../../interfaces/IProgramacion";
import ConfiguracionService from "../../../services/configuracionService";
import OrganigramaService from "../../../services/organigramaService";
import PersonaService from "../../../services/PersonaService";
import ProgramacionBusService from "../../../services/programacionBusService";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import { _initialFilter, _initialPagination, ItemNoneSelect, splitExceptionDetail, baseImageUrl, IndexNone } from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";

export const ListarEmbarcadoVuelo = ({ programacionId = '' }) => {
    const navigate = useNavigate();
    const [hidden, setHidden] = useState(false);

    /* Combo */
    const [destino, setDestino] = useState<IItemComboBaseDTO[]>([]);
    const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
    const [gerencia, setGerencia] = useState<IItemComboBaseDTO[]>([]);

    /* Filter */
    const [filter, setFilter] = useState<IFilterAsignacion>({
        ..._initialFilter(),
        programacionId: programacionId,
    });
    const [filterDestino, setFilterDestino] = useState<ISearch>(_initialFilter);
    const [filterEmpresa, setFilterEmpresa] = useState<ISearch>(_initialFilter);
    const [filterGerencia, setFilterGerencia] = useState<ISearch>(_initialFilter);

    /* Ref */
    const refDestino = React.useRef<IComboBox>(null);
    const refEmpresa = React.useRef<IComboBox>(null);
    const refGerencia = React.useRef<IComboBox>(null);

    const _openCampamento = React.useCallback(() => refDestino.current?.focus(true), []);
    const _openEmpresa = React.useCallback(() => refEmpresa.current?.focus(true), []);
    const _openGerencia = React.useCallback(() => refGerencia.current?.focus(true), []);

    /* Data & Colum */
    const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    /* Report Telerik */
    const [isOpenReport, { setTrue: openReport, setFalse: dismissReport }] = useBoolean(false);

    /* Hook */
    useEffect(() => {
        if (programacionId != null) {
            reloadInitial();
        }
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    /* Hook Filter Combo */
    useEffect(() => {
        if (filterDestino.search != null) {
            dataDestino();
        }
    }, [filterDestino]);

    useEffect(() => {
        if (filterEmpresa.search != null) {
            dataEmpresa();
        }
    }, [filterEmpresa]);

    useEffect(() => {
        if (filterGerencia.search != null) {
            dataGerencia();
        }
    }, [filterGerencia]);

    const dataDestino = () => {
        PersonaService.getComboCampamento1(filterDestino).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            t.unshift(ItemNoneSelect());

            setDestino(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataEmpresa = () => {
        setEmpresa([]);
        ConfiguracionService.allEmpresa(filterEmpresa)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemNoneSelect());

                    setEmpresa(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataGerencia = () => {
        setGerencia([]);
        OrganigramaService.organigramaByTipo('01', filterGerencia)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemNoneSelect());

                    setGerencia(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataEmbarcado = (param = filter) => {
        if (programacionId != '') {
            setData(_initialDataCollection);
            ProgramacionBusService.allEmbarcadoVuelo(param)
                .then((res) => {
                    if (res.status == 200) {
                        setData(res.data);
                        setHidden(true);
                        setTotal(res.data.total);
                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));
                    setHidden(true);
                });
        }
    }

    const _leftBarGeneral = () => {
        const _items: ICommandBarItemProps[] = [
            {
                key: 'Destino',
                onRender: () => <>
                    <ComboBox
                        componentRef={refDestino}
                        placeholder='Destino'
                        style={{
                            ...ThemeBase.notPaddingTopCombo,
                            width: '200px',
                        }}
                        allowFreeform
                        autoComplete='off'
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={destino}
                        onKeyUp={(e) => _onUpCombo('destino', e)}
                        selectedKey={filter.campamentoId}
                        onChange={(e, o) => _onChangeCombo('destino', e, o)}
                        onBlur={() => _onBlurCombo('destino')}
                        required
                    />
                </>,
            },
            {
                key: 'Empresa',
                onRender: () => <>
                    <ComboBox
                        componentRef={refEmpresa}
                        placeholder='Empresa'
                        style={{
                            ...ThemeBase.notPaddingTopCombo,
                            width: '470px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                        }}
                        allowFreeform
                        autoComplete='off'
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={empresa}
                        onKeyUp={(e) => _onUpCombo('empresa', e)}
                        selectedKey={filter.empresaId}
                        onChange={(e, o) => _onChangeCombo('empresa', e, o)}
                        onBlur={() => _onBlurCombo('empresa')}
                        required
                    />
                </>,
            },
            {
                key: 'Gerencia',
                onRender: () => <>
                    <ComboBox
                        componentRef={refGerencia}
                        placeholder='Gerencia'
                        style={{
                            ...ThemeBase.notPaddingTopCombo,
                            width: '400px',
                        }}
                        allowFreeform
                        autoComplete='off'
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={gerencia}
                        onKeyUp={(e) => _onUpCombo('gerencia', e)}
                        selectedKey={filter.gerenciaId}
                        onChange={(e, o) => _onChangeCombo('gerencia', e, o)}
                        onBlur={() => _onBlurCombo('gerencia')}
                        required
                    />
                </>,
            },
        ];

        return _items;
    };

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => { navigate(`${ConfigHelper.urlBase()}/empresa.transporte`) },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },
        ];

        return _items;
    };
    const _rigthBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: () => { openReport() },
            }
        ];

        return _items;
    };

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setTitleColumn();
        dataDestino();
        dataEmpresa();
        dataGerencia();
        dataEmbarcado(param);
    }

    const _clearSearch = (type: string) => {
        switch (type) {
            case 'destino':
                setFilterDestino({
                    ...filterDestino,
                    search: '',
                });
                break;
            case 'empresa':
                setFilterEmpresa({
                    ...filterEmpresa,
                    search: '',
                });
                break;
            case 'gerencia':
                setFilterGerencia({
                    ...filterGerencia,
                    search: '',
                })

                break;
            default:
                break;
        }
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Foto",
                ariaLabel:
                    "Column operations for File type, Press to sort on File type",
                iconName: "-",
                isIconOnly: true,
                fieldName: "foto",
                minWidth: 30,
                maxWidth: 30,
                onRender: (item: IProgramacionPersona) => (
                    <TooltipHost content={`Foto`}>
                        <Persona
                            text=""
                            size={PersonaSize.size24}
                            imageUrl={baseImageUrl}
                        />
                    </TooltipHost>
                ),
            },
            {
                key: "column2",
                name: "Nombre",
                fieldName: "nombres",
                minWidth: 170,
                maxWidth: 170,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span style={{ fontSize: '90%' }}>{item.nombres}</span>
                    );
                },
            },
            {
                key: "column3",
                name: "Identificación",
                fieldName: "nroDOI",
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: "number",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span
                            style={{ fontSize: "95%" }}>{`${item.tipoDocumento} ${item.nroDOI}`}</span>
                    );
                },
            },
            {
                key: 'column4',
                name: 'Nivel',
                fieldName: 'nivel',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Level',
                fieldName: 'level',
                minWidth: 30,
                maxWidth: 30,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Gerencia',
                fieldName: 'gerencia',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column7',
                name: 'Grupo',
                fieldName: 'grupo',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column8',
                name: 'Prog. Helo',
                fieldName: 'horaProgramadaHelo',
                minWidth: 60,
                maxWidth: 60,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            // {
            //     key: 'column4',
            //     name: 'Hora Sale',
            //     fieldName: 'horaSale',
            //     minWidth: 90,
            //     maxWidth: 90,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            //     isPadded: true,
            // },
            {
                key: 'column9',
                name: 'Hora Llega',
                fieldName: 'horaLlega',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column10',
                name: 'Transporte',
                fieldName: 'transporte',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column11',
                name: 'Origen',
                fieldName: 'origen',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column12',
                name: 'Destino',
                fieldName: 'destino',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column13',
                name: 'Empresa Transportista',
                fieldName: 'empresaTransporte',
                minWidth: 180,
                maxWidth: 180,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: "column14",
                name: "Empresa Persona",
                fieldName: "empresaPersona",
                minWidth: 180,
                maxWidth: 180,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column15",
                name: "Proceso-Estado",
                fieldName: "nivelActual",
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
        ];

        setColumn(col);
    };

    /* Event Filter */
    const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
        let { value } = ev?.target as HTMLInputElement;

        switch (type) {
            case 'destino':
                _openCampamento();

                if (filter.campamentoId != null) {
                    setFilter({
                        ...filter,
                        campamentoId: null,
                    });

                    reloadInitial();
                }

                setFilterDestino({
                    ...filterDestino,
                    search: value,
                })
                break;
            case 'empresa':
                _openEmpresa();

                if (filter.empresaId != null) {
                    setFilter({
                        ...filter,
                        empresaId: null,
                    });

                    reloadInitial();
                }

                setFilterEmpresa({
                    ...filterEmpresa,
                    search: value,
                })
                break;
            case 'gerencia':
                _openGerencia();

                if (filter.gerenciaId != null) {
                    setFilter({
                        ...filter,
                        gerenciaId: null,
                    });

                    reloadInitial();
                }

                setFilterGerencia({
                    ...filterGerencia,
                    search: value,
                })
                break;
            default:
                break;
        }
    };

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                let k: string | null = null;

                let isNone = option.key == IndexNone;

                if (isNone) {
                    _clearSearch(type);
                }

                switch (type) {
                    case 'destino':
                        if (!isNone) {
                            k = String(option.key);
                        }

                        let fDes: IFilterAsignacion = ({
                            ...filter,
                            campamentoId: k
                        })

                        setFilter(fDes);
                        reloadInitial(fDes);
                        break;
                    case 'empresa':
                        if (!isNone) {
                            k = String(option.key);
                        }

                        let fEmp: IFilterAsignacion = ({
                            ...filter,
                            empresaId: k,
                        })

                        setFilter(fEmp);
                        reloadInitial(fEmp);
                        break;
                    case 'gerencia':
                        if (!isNone) {
                            k = String(option.key);
                        }

                        let fGer: IFilterAsignacion = ({
                            ...filter,
                            gerenciaId: k,
                        })

                        setFilter(fGer);
                        reloadInitial(fGer);
                        break;
                    default:
                        break;
                }
            }
        }
    };

    /* Event Tab */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterPersonaTransporte = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    const _onBlurCombo = (type: string) => {
        switch (type) {
            case 'destino':
                if ((filter.campamentoId == '' || filter.campamentoId == undefined) && filter.campamentoId != null) {
                    setFilter({
                        ...filter,
                        campamentoId: null,
                    });
                }
                break;
            case 'empresa':
                if ((filter.empresaId == '' || filter.empresaId == undefined) && filter.empresaId != null) {
                    setFilter({
                        ...filter,
                        empresaId: null,
                    });
                }
                break;
            case 'gerencia':
                if ((filter.gerenciaId == '' || filter.gerenciaId == undefined) && filter.gerenciaId != null) {
                    setFilter({
                        ...filter,
                        gerenciaId: null,
                    });
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <CustomCommandBar item={_leftBarGeneral()}></CustomCommandBar>
            <TableBase leftBar={_leftBar()} rigthBar={_rigthBar()} column={column} data={data.items} style={ThemeBase.notPaddingTableBase} pagination={pager} isLoading={!hidden}></TableBase>
            {/* Report personas programadas en su totalidad - similar admin */}
            <RepoteBase
                // headerName={`Reporte Embarcados Helos`}
                NameReport={"MOVReporteHelosEmbarcados.trdx"}
                Parameters={{ ProgramacionId: programacionId }}
                openTelerik={isOpenReport}
                dismissTelerik={dismissReport}
            ></RepoteBase>
        </>
    )
}