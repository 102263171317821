import { IBlob } from "../../interfaces/base/IBase";

const urlCargaProgramacionPersona: IBlob = {
    url: 'https://mmgbsoaastr09e3.blob.core.windows.net/misviajes/plantillas/PlantillaInscripcion.xlsx',
    nombre: 'CargaInscripcionPersona',
    extension: 'xlsx',
}

const urlRetiroProgramacionPersona: IBlob = {
    url: 'https://mmgbsoaastr09e3.blob.core.windows.net/misviajes/plantillas/PlantillaRetiro.xlsx',
    nombre: 'RetiroMasivoPersona',
    extension: 'xlsx',
}

const urlCargaPruebaCovid: IBlob = {
    url: 'https://mmgbsoaastr09e3.blob.core.windows.net/misviajes/plantillas/CargaPruebaCovid.xlsx',
    nombre: 'CargaPruebaCovid',
    extension: 'xlsx',
}

const urlActualizacionPersonaKeyUser: IBlob = {
    url: 'https://mmgbsoaastr09e3.blob.core.windows.net/misviajes/plantillas/actualizacionPersonaKeyUser_U.xlsx',
    nombre: 'ActualizacionPersonaKeyUser',
    extension: 'xlsx',
}

const urlActualizacionPersonaTransporte: IBlob = {
    url: 'https://mmgbsoaastr09e3.blob.core.windows.net/misviajes/plantillas/actualizacionPersonaTransporte_U.xlsx',
    nombre: 'ActualizacionPersonaTransporte',
    extension: 'xlsx',
}

const urlActualizacionPersonaSSEE: IBlob = {
    url: 'https://mmgbsoaastr09e3.blob.core.windows.net/misviajes/plantillas/actualizacionPersonaSSEE_U.xlsx',
    nombre: 'ActualizacionPersonaSSEE',
    extension: 'xlsx',
}

const urlActualizarDatosHelo: IBlob = {
    url: 'https://mmgbsoaastr09e3.blob.core.windows.net/misviajes/plantillas/actualizacionDatosHeloQAS.xlsx',
    nombre: 'ActualizacionDatosHelo',
    extension: 'xlsx',
}

export const BlobHelper = {
    urlCargaProgramacionPersona,
    urlCargaPruebaCovid,
    urlActualizacionPersonaKeyUser,
    urlActualizacionPersonaTransporte,
    urlActualizacionPersonaSSEE,
    urlActualizarDatosHelo,
    urlRetiroProgramacionPersona
}