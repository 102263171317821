import { INavLinkGroup } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthService from "../../services/AuthService";
import TokenService from "../../services/TokenService";
import { keyAccess, keyJwt } from "../../utils/Helper/helper";
import {
  CLAIM_ADRESS,
  CLAIM_EMPRESA,
  CLAIM_EMPRESA_USUARIO,
  CLAIM_ES_EMPRESA_TITULAR,
  CLAIM_ES_EMPRESA_TRANSPORTISTA,
  CLAIM_ES_EMPRESA_VUELO,
  CLAIM_ROLE,
  CLAIM_COD_CONT,
  CLAIM_TOKEN_SESION_C,
} from "../../utils/Helper/roleHelper";
import { AuthContext } from "../context/auth-context";
import { IUserAuthenticate, IUserState } from "../interface/IAuth";
import { fakeAuthProvider } from "./fake-provider";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();

  const [token, setToken] = useState<string | null>(TokenService.getToken());
  const [access, setAccess] = useState<IUserAuthenticate | null>(
    AuthService.getAccess()
  );
  const [sidebar, setSidebar] = useState<INavLinkGroup[]>([]);

  // UseEffect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  /* Effect */
  useEffect(() => {
    if (token) {
      localStorage.setItem(keyJwt, `"${token}"`);

      let t: any = JSON.parse(atob(token.split(".")[1]));

      setAccess({
        ...access,
        email: t[CLAIM_ADRESS],
        role: validateRole(t[CLAIM_ROLE]),
        empresa: t[CLAIM_EMPRESA],
        empresaUsuario: t[CLAIM_EMPRESA_USUARIO],
        esEmpresaTransportista: Boolean(
          Number(t[CLAIM_ES_EMPRESA_TRANSPORTISTA])
        ),
        esEmpresaTitular: Boolean(Number(t[CLAIM_ES_EMPRESA_TITULAR])),
        esEmpresaVuelo: Boolean(Number(t[CLAIM_ES_EMPRESA_VUELO])),
        codCont: t[CLAIM_COD_CONT],
        tokenSesionC: t[CLAIM_TOKEN_SESION_C],
      });
      getAccess();
    } else {
      localStorage.removeItem(keyJwt);
      sessionStorage.clear();
    }
  }, [token]);

  useEffect(() => {
    if (token && sidebar.length > 0) {
      setAccess({
        ...access,
        sidebar: sidebar,
      });
    } else {
      localStorage.removeItem(keyAccess);
      sessionStorage.clear();
    }
  }, [sidebar]);

  useEffect(() => {
    if (access) {
      localStorage.setItem(keyAccess, JSON.stringify(access));
    } else {
      localStorage.removeItem(keyAccess);
      sessionStorage.clear();
    }
  }, [access]);

  const getAccess = () => {
    AuthService.access()
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setSidebar(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
      });
  };

  const validateRole = (claim: any) => {
    if (Array.isArray(claim)) {
      return claim;
    } else {
      if (typeof claim == "undefined" || claim == null) {
        return [];
      } else {
        let r = [claim];

        return r;
      }
    }
  };

  let signin = (authenticate: IUserState, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setToken(authenticate.token ?? null);
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setToken(null);
      setAccess(null);
      callback();
    });
  };

  let value = { token, access, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
