import { ISearch } from "../interfaces/base/IBase";
import { IFilterAdmContrato, IFilterUsuarioContratista } from "../interfaces/filter/IFilterContrato";
import { ICreateContrato, ICreateUsuarioContratista, IUpdatePasswordUsuarioEmpresa } from "../interfaces/IContrato";
import { Ifilter } from "../interfaces/IFilter";
import { ICreateEmpresaUsuario } from "../interfaces/IUsuario";
import { fetchContrato } from "./http-common";

let urlContrato = 'Contrato';
let urlCentroCosto = 'CentroCosto';
let urlAdmContrato = 'PersonaRol';
let urlContratoTipo = 'ContratoTipo';
let urlDispositivo = 'Dispositivo';

const allCentroCosto = () => {
    return fetchContrato.get(`${urlCentroCosto}`);
}
/*Centro de costos filter */
const allCentroCosto1 = (filter:ISearch) => {
    return fetchContrato.get(`${urlCentroCosto}`,{params:filter});
}

const allAdmContrato = (filter: IFilterAdmContrato) => {
    return fetchContrato.get(`${urlAdmContrato}`, { params: filter });
}

const allContratoTipo = () => {
    return fetchContrato.get(`${urlContratoTipo}`);
}

const addContrato = (data: ICreateContrato) => {
    return fetchContrato.post(`${urlContrato}`, data);
}

const detalleContrato = (id: string) => {
    return fetchContrato.get(`${urlContrato}/${id}`, );
}

const allContratos = (filter:Ifilter) => {
    return fetchContrato.get(`${urlContrato}/listar`,{params:filter})
}

const allContratistas = (filter:IFilterUsuarioContratista) => {
    return fetchContrato.get(`${urlAdmContrato}/Contratitas`,{params:filter})
}
const addUsuarioContratista = (data: ICreateUsuarioContratista) => {
    return fetchContrato.post(`${urlContrato}/Usuario`, data);
}

/* Usuario Empresa */
const allEmpresaUsuario = (empresaId: string, filter: Ifilter) => {
    return fetchContrato.get(`${urlAdmContrato}/Usuario/${empresaId}`, { params: filter });
}

const crearEmpresaUsuario = (data: ICreateEmpresaUsuario) => {
    return fetchContrato.post(`${urlContrato}/UsuarioEmpresa`, data);
}

const actualizarContraseniaUsuarioEmpresa = (data: IUpdatePasswordUsuarioEmpresa) => {
    return fetchContrato.put(`${urlContrato}/UsuarioEmpresa`, data);
}

const ContratoService = {
    allContratos,
    /*Lista Centro Costos */
    allCentroCosto,
    allCentroCosto1,
    
    allAdmContrato,
    allContratoTipo,
    allContratistas,
    addContrato,
    detalleContrato,
    addUsuarioContratista,

    /* Empresa Usuario */
    allEmpresaUsuario,
    crearEmpresaUsuario,
    actualizarContraseniaUsuarioEmpresa,
}

export default ContratoService;