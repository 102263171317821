import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import GuardiaService from "../../../services/guardiaService";
import { IGuardiaPax, IGuardiaPaxFilter, IPostGuardiaPax, IUpdateGuardiaPax } from "../../../interfaces/IGuadia";
import {
  IException,
  IItemComboBaseDTO,
  ISearch,
} from "../../../interfaces/base/IBase";
import { TableBase } from "../../../components/Table/TableBase";
import {
  Search20Regular,
} from "@fluentui/react-icons";
import {
  ComboBox,
  IColumn,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  ProgressIndicator,
  Stack,
  Toggle,
} from "@fluentui/react";
import PersonaService from "../../../services/PersonaService";
import { IPersona } from "../../../interfaces/IPersona";
import { _initialFilter, stackTokens } from "../../../utils/Helper/helper";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import GuardiaPaxService from "../../../services/guardiaPaxService";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { HeadingSearch } from "../../../components/Generic/HeadingSearch";
import { Field, Input } from "@fluentui/react-components";
import { PaginationFooter } from "../../../components/PaginationFooter/PaginationFooter";

interface ListaPaxProps {
  GuardiaId: string;
}

interface IModel {
  GaurdiaId?: String;
  PersonaId?: string;
}

const ListaPax: FunctionComponent<ListaPaxProps> = (props) => {
  const [guardiasPaxs, setGuardiasPaxs] = useState<IGuardiaPax[]>(null!!);
  const [updateGuardiaPax, setUpdateGuardiasPaxs] = useState<IUpdateGuardiaPax>(null!!);
  const [postGuardiasPaxs, setPostGuardiasPaxs] = useState<IPostGuardiaPax>(null!!);
  const [column, setColumn] = useState<IColumn[]>([]);
  const [validarPersona, setValidarPersona] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [cargando, setCargando] = useState(false)

  const [searchListaPax, setSearchListaPax] = useState("")

  let initialFilter: IGuardiaPaxFilter = {
    guardiaId: props.GuardiaId,
    skip: 0,
    take: 50,
    search: "",
  };

  const comboBoxRef = useRef<IComboBox>(null);
  const _openOptionPersona = useCallback(
    () => comboBoxRef.current?.focus(true),
    []
  );

  const[filterGuardiaPax, setFilterGuardiaPax] = useState<IGuardiaPaxFilter>(null!!);

  const [filterPersona, setFilterPersona] = useState<ISearch>(_initialFilter);
  const [persona, setPersona] = useState<IItemComboBaseDTO[]>([]);
  const [personaIsLoad, setpersonaIsLoad] = useState(false);
  const [personaOptionSelect, setpersonaOptionSelect] =
    useState<IComboBoxOption>();

  const INITIAL_MODULE: IModel = { GaurdiaId: props.GuardiaId, PersonaId: "" };
  const [model, setmodel] = useState<IModel>(INITIAL_MODULE);

  const controllerRef = useRef<AbortController>(new AbortController())

  useEffect(() => {
    setTitleColumn();
    dataPersona();
    dataListaPax(initialFilter);
    setmodel({
      ...model,
      GaurdiaId: props.GuardiaId,
    });

    setFilterGuardiaPax(initialFilter)
    /* console.log("inicial", initialFilter) */

    setPostGuardiasPaxs({
      ...postGuardiasPaxs,
      guardiaId : props.GuardiaId,
    })
  }, []);

  /* useEffect(() => {
    setFilterGuardiaPax{(
      ...filterGuardiaPax,
      search : 
      
    )}
  }, [filterGuardiaPax.search]); */

  useEffect(() => {
    validarAgregarPersona()
  }, [model.PersonaId]);

  useEffect(() => {
    if (filterPersona.search != null) {
      dataPersona();
    }
  }, [filterPersona]);

  useEffect(() => {
    if (personaOptionSelect)
    {
      setmodel({
        ...model,
        PersonaId: personaOptionSelect.key.toString(),
      });

      setPostGuardiasPaxs({
        ...postGuardiasPaxs,
        personaId: personaOptionSelect.key.toString(),
      })
    }
  }, [personaOptionSelect]);

  /* Api */
  const dataPersona = () => {
    PersonaService.listarPersonaMMG(filterPersona)
      .then((res) => {
        /* v2 persona */
        let d: IPersona[] = res.data;
        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: `${x.nroDOI}  | ${x.persona} | ${x.tipo}`,
          };
        });
        setPersona(t);
        setpersonaIsLoad(true);
      })
      .catch((e: Error) => {
        alert(e.message);
      });
  };

 
  const _onChangeToggle = (fieldName: string, isChecked: boolean, item: IGuardiaPax) => {
    // Usa el ítem junto con otros parámetros según sea necesario
    //console.log(`Campo "${fieldName}" está ahora ${isChecked ? 'habilitado' : 'deshabilitado'}`);

    let ListaGuardiaPax: IGuardiaPaxFilter = {
      guardiaId: props.GuardiaId,
      skip: 0,
      take: 50,
      search: searchListaPax,
    };
    /* console.log("data1", ListaGuardiaPax) */

    let newUpdate : IUpdateGuardiaPax = {
      guardiaId : item.guardiaId,
      personaId : item.personaId,
      habilitar : item.isPaxActive == true ? false: true
    }
    setIsLoading(true);
    setTimeout(() => {
    GuardiaPaxService.ActualizarIsPax(newUpdate).then(
      (res) => {
        if (res.status == 200) {
          
          dataListaPax(ListaGuardiaPax)
        }
      }
    )
      .catch(
        (e: Error) => {
          console.log(e.message)
          setCargando(false)
        }
      )
      }, 1000);
      //console.log('Ítem:', item);
    /* console.log('ÍtemUpd:', newUpdate); */
  };

  const asignarPersona = () => {
    /* console.log(filterGuardiaPax) */
    if (postGuardiasPaxs !== undefined) {
      setCargando(true);
      setTimeout(() => {
        GuardiaPaxService.addGuardiaPax(postGuardiasPaxs).then(
          (res) => {
            if (res.status == 200) {
              dataListaPax(filterGuardiaPax)
              setCargando(false)
            }
          }
        )
          .catch(
            (e: Error) => {
              console.log(e.message)
              setCargando(false)
            }
          )
      }, 3000);
    }
  }

  

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "persona",
        name: "Persona",
        fieldName: "Persona",
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: IGuardiaPax) => {
          return <span>{`${item.nombreCompleto}`}</span>;
        },
      },
      {
        key: "bloq",
        name: "Habilitado",
        fieldName: "Habilitado",
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: IGuardiaPax) => {
          return (
            <span>
              <Toggle
                defaultChecked = {item.isPaxActive}
                checked = {item.isPaxActive}
                //disabled
                onChange={(e, isChecked) => _onChangeToggle("habilitado", item.isPaxActive, item)}
              />
            </span>
          );
        },
      },
    ];

    setColumn(col);
  };

  const dataListaPax = (data : IGuardiaPaxFilter) => {
    //let id = props.GuardiaId;
    /* console.log("dataaa", data) */
    controllerRef.current.abort()
    const newController = new AbortController()
    controllerRef.current = newController;

    setTimeout(() => {
    GuardiaService.guardiaListaPax(data, newController)
      .then((res) => {
        /* console.log("reees", res) */
        setGuardiasPaxs(res.data.items);
        setIsLoading(false)
        setFilterGuardiaPax(data)
      })
      .catch((e: IException) => {
        //console.log(e.response.data.detail);
        setIsLoading(false)
      })
    }, 1000);
  };

  const validarAgregarPersona = () => 
  {
    if( model?.PersonaId )
    {
      let listaGuids : string[] = guardiasPaxs.map( x => {
        return x.personaId
      })
      if(listaGuids.includes(model.PersonaId))
      {setValidarPersona(false)} else{ setValidarPersona(true)}

    }else{
      setValidarPersona(false)
    }
  }

  const _onInputPersona = (e: React.KeyboardEvent<IComboBox>) => {
    _openOptionPersona();
    let { value } = e?.target as HTMLInputElement;
    /* console.log(value); */
    setmodel(INITIAL_MODULE);
    if (value.length > 2) {
      setFilterPersona({
        ...filterPersona,
        search: value,
      });
    }
  };
  const _onChangePersona = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event !== undefined) {
      if (option !== undefined) {
        setpersonaOptionSelect(option);
      }
    }
  };

  const _onBlurPersona = () => {
    if (model?.PersonaId === "" || model?.PersonaId === undefined) {
      setFilterPersona({
        ...filterPersona,
        search: "",
      });
    }
  };

  const NoDataOptions = (sizeData: number = 0) => {
    return (
      <>
        {sizeData === 0 && (
          <div style={{ textAlign: "center", padding: 20 }}>
            Persona no encontrada en teseo
          </div>
        )}
      </>
    );
  };

  const _onChangeText = (evento: any) => {
    const { value } = evento.target;

      let newFilter: IGuardiaPaxFilter ={
        ...filterGuardiaPax,
        search:value
      }
      dataListaPax(newFilter);
      setFilterGuardiaPax(newFilter)

      setSearchListaPax(value)
 
    //setSearch(value);
  };
  

  return (
    <>
     {/* <pre>{JSON.stringify(filterGuardiaPax, null, 2)}</pre> */}
      <br />
      <div style={{margin:"0 10px"}}>
      <div style={{position:"relative",display:"flex"}}>
        <div style={{width:"70%"}}>
          <ComboBox
            componentRef={comboBoxRef}
            label="Asignar Persona"
            allowFreeform
            autoComplete="off"
            useComboBoxAsMenuWidth
            calloutProps={{ directionalHintFixed: true }}
            options={persona}
            // errorMessage={empresa}
            //onKeyUp={_onUpPersona}
            onInput={_onInputPersona}
            selectedKey={model?.PersonaId}
            onChange={_onChangePersona}
            onBlur={_onBlurPersona}
            onRenderLowerContent={() => NoDataOptions(persona.length)}
            required
          />
        </div>
        <div style={{marginTop : "8px",width:"20%"}}>
          <ButtonForm back={undefined} isBack = {false} register={() => asignarPersona() } disabledRegister={!validarPersona || cargando}></ButtonForm>
        </div>   
      </div>
      <div style={{width:"100%", paddingTop: "30px", paddingLeft: "10px"}} hidden = {cargando}>
        <Input
        
          contentAfter={
            <Search20Regular
              aria-label="Enter by voice"
              onClick={() => undefined}
            />
          }
          id={"afterId"}
          placeholder={"Buscar Persona..."}
          value={filterGuardiaPax?.search}
          style={{
            height: "20px",
            fontSize: "12px",
            border: "none",
            marginRight: "130px",
            width: "250px",
            borderRadius: "0px",
            borderBottom: "1px solid #087cd4",
            zIndex: "99",
          }}
          onChange={_onChangeText}
          onKeyPress={undefined}
        />
      </div>

      </div>
      
      {cargando && (
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" /* style={{top : "10px"}} */>
          <ProgressIndicator description="Registrando persona en la Guardia" />
          <br />
        </div>
      )}
        <TableBase
        leftBar={[]}
        column={column}
        data={guardiasPaxs}
        isLoading={isLoading}
        ></TableBase>
    </>
  );
};

export default ListaPax;
