import {
  Checkbox,
  ComboBox,
  DatePicker,
  DefaultButton,
  IComboBox,
  IComboBoxOption,
  Panel,
  PanelType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import {
  CustomMessageBar,
  typeMessage,
} from "../../components/MessageBar/customMessageBar";
import {
  IDetalleNivelLevel,
  IDetalleTemporada,
  IItemDetalleLevel,
  IUpdateTemporada,
} from "../../interfaces/ITemporada";
import TemporadaService from "../../services/temporadaService";
import {
  _esBisiesto,
  _formatDate,
  _formatDatePrefix,
  _parseDateFromString,
} from "../../utils/Helper/datetimeHelper";
import { splitExceptionDetail, stackTokens } from "../../utils/Helper/helper";
import ThemeBase from "../../utils/Theme/themeBase";
import { AccesoGeneric } from "../accesoPersona/generic/accesoGeneric";
import { IComboOptions } from "../../components/Generic/combo";

export const ActualizarTemporada = ({
  id = "",
  isOpen = false,
  dissmiss = () => {},
  reloadInitial = () => {},
}) => {
  const [inicio, setInicio] = useState<Date | undefined>(undefined);
  const [fin, setFin] = useState<Date | undefined>(undefined);

  /* Detalle */
  const [detalle, setDetalle] = useState<IDetalleTemporada>(null!);
  const [form, setForm] = useState<IUpdateTemporada>(null!);

  /* Message Error & Choice */
  const [isSend, setIsSend] = useState(false);
  const [choice, setChoice] = React.useState<string | undefined>(undefined);
  const resetChoice = React.useCallback(() => setChoice(undefined), []);
  const [textError, settextError] = useState("Error");
  const [meses, setMeses] = useState<IComboOptions[]>([]);
  const [search, setSearch] = useState("");
  const [keyToCodigoMap, setKeyToCodigoMap] = useState({});
  const [maxDiaInicio, setMaxDiaInicio] = useState(31);
  const [maxDiaFin, setMaxDiaFin] = useState(31);
  const [errorDiaInicio, setErrorDiaInicio] = useState("");
  const [errorDiaFin, setErrorDiaFin] = useState("");
  const [disableForm, setDisableForm] = useState(true);
  /* Hook */
  useEffect(() => {
    if (id != "") {
      detalleTemporada();
      dataComboMeses();
    }
  }, [isOpen]);

  useEffect(() => {
    if (form && form.mesInicioId) {
      updateMaxDia(form?.mesInicioId, true);
    }
    if (form && form.mesFinId) {
      updateMaxDia(form?.mesFinId, false);
    }
  }, [form]);

  const updateMaxDia = (mesKey, esInicio: boolean) => {
    const diasPorMes = {
      "01": 31,
      "02": _esBisiesto() ? 29 : 28,
      "03": 31,
      "04": 30,
      "05": 31,
      "06": 30,
      "07": 31,
      "08": 31,
      "09": 30,
      "10": 31,
      "11": 30,
      "12": 31,
    };
    const mesCodigo = keyToCodigoMap[mesKey];
    if (esInicio) {
      setMaxDiaInicio(diasPorMes[mesCodigo] || 31);
    } else {
      setMaxDiaFin(diasPorMes[mesCodigo] || 31);
    }
  };

  useEffect(() => {
    if (inicio != undefined) {
      setForm({
        ...form,
        inicio: _formatDate(inicio, ","),
      });
    }
  }, [inicio]);

  useEffect(() => {
    if (fin != undefined) {
      setForm({
        ...form,
        fin: _formatDate(fin, ","),
      });
    }
  }, [fin]);

  useEffect(() => {
    /* Level selected */
    if (detalle) {
      let current: IItemDetalleLevel[] = [];

      detalle?.psGroup.map((n) => {
        n.level.map((l) => {
          current = [...current, l];
        });
      });

      setForm({
        ...form,
        id: id,
        inicio: detalle.inicio,
        fin: detalle.fin,
        level: current.filter((x) => x.isChecked).map((x) => x.detalleId),
        tipo: detalle.tipo,
        diaFin: detalle.diaFin,
        diaInicio: detalle.diaInicio,
        mesFinId: detalle.mesFinId,
        mesInicioId: detalle.mesInicioId,
      });

      setInicio(_parseDateFromString(detalle.inicio));
      setFin(_parseDateFromString(detalle.fin));
    }
  }, [detalle]);

  /* Callback reload */
  const refForm = React.useCallback(() => {
    resetChoice();
  }, [dissmiss]);

  const dataComboMeses = () => {
    TemporadaService.comboMeses(search)
      .then((response: any) => {
        if (response.status == 200) {
          setMeses(response.data);
          const keyToCodigo = {};
          const options = response.data.map((m) => {
            keyToCodigo[m.key] = m.codigo;
            return { key: m.key, text: m.text };
          });
          setKeyToCodigoMap(keyToCodigo);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  /* Api */
  const detalleTemporada = () => {
    TemporadaService.detalleTemporada(id)
      .then((res) => {
        if (res.status == 200) {
          let d: IDetalleTemporada = res.data;
          setDetalle(d);

          let current: IItemDetalleLevel[] = [];

          d.psGroup.map((n) => {
            n.level.map((l) => {
              current = [...current, l];
            });
          });
        }
      })
      .catch((e) => {
        alert(splitExceptionDetail(e));
      });
  };

  const actualizarTemporada = () => {
    setIsSend(true);

    TemporadaService.actualizarTemporada(form)
      .then((res) => {
        if (res.status == 200) {
          setIsSend(false);
          refForm();
          setChoice("send");
          reloadInitial();
        }
      })
      .catch((e) => {
        setIsSend(false);
        setChoice("error");
        settextError(splitExceptionDetail(e));
      });
  };

  const _onChangeText = (
    type: string,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        switch (type) {
          case "diaInicio":
            setForm({
              ...form,
              diaInicio: newValue,
            });
            break;
          case "diaFin":
            setForm({
              ...form,
              diaFin: newValue,
            });
            break;
          default:
            break;
        }
      }
    }
  };
  useEffect(() => {
    validarForm();
  }, [form, errorDiaInicio, errorDiaFin]);

  const validarForm = () => {
    //!dataGuidancees.some((item) => item.value === "")
    if (form == null) setDisableForm(true);
    if (form !== null) {
      if (
        form.tipo &&
        form.mesFinId &&
        form.mesInicioId &&
        form.diaFin &&
        form.diaInicio &&
        form.level &&
        form.level.length > 0 &&
        errorDiaInicio == "" &&
        errorDiaFin == ""
      ) {
        setDisableForm(false);
      } else {
        setDisableForm(true);
      }
    }
  };

  /* Event */
  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "tipo":
            setForm({
              ...form,
              tipo: String(option.key),
            });
            break;
          case "mesInicio":
            setForm({
              ...form,
              mesInicioId: String(option.key),
            });
            break;
          case "mesFin":
            setForm({
              ...form,
              mesFinId: String(option.key),
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const _onChangeLevel = (
    nivelId: string,
    levelId: string,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    if (isChecked != undefined) {
      /* Detalle is object */
      /* PsGroup es el detalle */
      let d = detalle;

      let t = d.psGroup.map((n) => {
        let nivelTemp = n;

        if (n.nivelId == nivelId) {
          let exis = n.level.find((z) => z.detalleId == levelId);

          if (exis) {
            let levelTemp = n.level.map((z) => {
              if (z.detalleId == levelId) {
                return {
                  ...z,
                  isChecked: isChecked,
                };
              } else {
                return z;
              }
            });

            nivelTemp = {
              ...n,
              level: levelTemp,
            };
          }
        }

        return nivelTemp;
      });

      setDetalle({
        ...detalle,
        psGroup: t,
      });
    }
  };

  const _onChangeAllNivel = (
    nivelId: string,
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    if (isChecked != undefined) {
      /* Detalle is object */
      /* PsGroup es el detalle */
      let d = detalle;

      let t = d.psGroup.map((n) => {
        let nivelTemp = n;

        if (n.nivelId == nivelId) {
          let levelTemp = n.level.map((z) => {
            return {
              ...z,
              isChecked: isChecked,
            };
          });

          nivelTemp = {
            ...n,
            level: levelTemp,
            isAll: isChecked,
          };
        }

        return nivelTemp;
      });

      setDetalle({
        ...detalle,
        psGroup: t,
      });
    }
  };

  const handleDiaInicioChange = (e, newValue) => {
    const dia = parseInt(newValue, 10);
    _onChangeText("diaInicio", e, newValue);
    if (dia <= 0) {
      setErrorDiaInicio(`El día no puede ser menor que 0`);
    } else if (dia > maxDiaInicio) {
      setErrorDiaInicio(`El día no puede ser mayor que ${maxDiaInicio}`);
    } else {
      setErrorDiaInicio("");
    }
  };

  const handleDiaFinChange = (e, newValue) => {
    const dia = parseInt(newValue, 10);
    _onChangeText("diaFin", e, newValue);
    if (dia <= 0) {
      setErrorDiaFin(`El día no puede ser menor que 0`);
    } else if (dia > maxDiaFin) {
      setErrorDiaFin(`El día no puede ser mayor que ${maxDiaFin}`);
    } else {
      setErrorDiaFin("");
    }
  };

  /* Footer Panel */
  const onRenderFooter = React.useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={actualizarTemporada}
          styles={ThemeBase.panelButtonStyle}
          disabled={disableForm}
        >
          Actualizar
        </PrimaryButton>
        <DefaultButton onClick={dissmiss}>Cancelar</DefaultButton>
      </div>
    ),
    [isSend, id, isOpen, detalle, dissmiss, form, inicio, fin, disableForm]
  );

  return (
    <>
      <Panel
        isOpen={isOpen}
        onDismiss={dissmiss}
        type={PanelType.medium}
        headerText="Actualizar Temporada"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooter}
        isFooterAtBottom={true}
      >
        <div style={{ paddingTop: "5%" }}>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                <ProgressIndicator
                  description="Enviando espere un momento"
                  barHeight={3}
                />
              </div>

              <div
                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                style={ThemeBase.notPaddingSide}
              >
                {choice === "error" && (
                  <CustomMessageBar
                    type={typeMessage.TYPE_ERROR}
                    message={textError}
                    dissmiss={resetChoice}
                  ></CustomMessageBar>
                )}
                {choice === "send" && (
                  <CustomMessageBar
                    dissmiss={() => {
                      refForm();
                      resetChoice();
                    }}
                  ></CustomMessageBar>
                )}
              </div>
            </div>
          </div>

          <form
            ref={refForm}
            hidden={
              choice === "error" || choice === "send" || isSend ? true : false
            }
          >
            <Stack tokens={stackTokens}>
              <div className="ms-Grid" dir="ltr">
                <Stack tokens={stackTokens}>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <ComboBox
                        label="Mes Inicio"
                        autoComplete="off"
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={meses}
                        selectedKey={form?.mesInicioId}
                        onChange={(e, o) => _onChangeCombo("mesInicio", e, o)}
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <TextField
                        label="Día Inicio"
                        name="diaInicio"
                        placeholder="Día de Inicio"
                        value={form?.diaInicio.toString()}
                        min={1}
                        max={maxDiaInicio}
                        onChange={handleDiaInicioChange}
                        type="number"
                        errorMessage={errorDiaInicio}
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <ComboBox
                        label="Mes Final"
                        autoComplete="off"
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={meses}
                        selectedKey={form?.mesFinId}
                        onChange={(e, o) => _onChangeCombo("mesFin", e, o)}
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <TextField
                        label="Día Fin"
                        name="diaFin"
                        placeholder="Día de Fin"
                        value={form?.diaFin.toString()}
                        min={1}
                        max={maxDiaFin}
                        onChange={handleDiaFinChange}
                        type="number"
                        errorMessage={errorDiaFin}
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <ComboBox
                        label="Tipo Temporada"
                        allowFreeform
                        autoComplete="on"
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={AccesoGeneric.initialTipoTemporada}
                        selectedKey={form ? form.tipo : ""}
                        onChange={(e, o) => _onChangeCombo("tipo", e, o)}
                        required
                      />
                    </div>
                  </div>

                  <br />
                  <div className="ms-Grid-row">
                    {detalle?.psGroup.map(function (i: IDetalleNivelLevel) {
                      return (
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                          <Checkbox
                            label={i.nivel}
                            boxSide="start"
                            onChange={(e, o) =>
                              _onChangeAllNivel(i.nivelId, e, o)
                            }
                            checked={i.isAll}
                            styles={ThemeBase.checkBoxStyles}
                          />
                          <br />

                          <Stack tokens={stackTokens}>
                            {i.level.map(function (l: IItemDetalleLevel) {
                              return (
                                <Checkbox
                                  id={l.detalleId}
                                  key={l.detalleId}
                                  label={l.level}
                                  onChange={(e, o) =>
                                    _onChangeLevel(i.nivelId, l.detalleId, e, o)
                                  }
                                  checked={l.isChecked}
                                />
                              );
                            })}
                          </Stack>
                        </div>
                      );
                    })}
                  </div>
                </Stack>
              </div>
            </Stack>
          </form>
        </div>
      </Panel>
    </>
  );
};
