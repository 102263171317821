import { FontIcon } from "@fluentui/react"
import { ICard } from "../../interfaces/base/IBase";
import ThemeBase from "../../utils/Theme/themeBase"

const _data: ICard = { card: [] };

export const Card = ({ data = _data }) => {
    return(
        <>
        <div className="ms-Grid" dir="ltr" style={data.style}>
                <div className="ms-Grid-row">
                    {data.card.map((card, i) => (
                        <div key={i} className={data.size ? data.size : 'ms-Grid-col ms-sm12 ms-md3 ms-lg3'}  onClick={card.onClick}>
                            <div className="ms-depth-16" style={{
                                width: '90%',
                                maxWidth: '90%',
                            }}>
                                <div style={{
                                     backgroundColor: '#fff',
                                     margin: 'auto',
                                     cursor: 'pointer',
                                     transition: 'box-shadow 0.3s, background-color 0.3s',
                                     borderRadius: '4px',
                                     display: 'flex',
                                     alignItems: 'center',
                                     justifyContent: 'space-between',
                                     flexDirection: 'column-reverse',
                                
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = 'lightgray';
                                    e.currentTarget.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.2)';
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = '#fff';
                                    e.currentTarget.style.boxShadow = '';
                                  }}
                                
                                >
                                    <div style={{
                                        padding: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexDirection: 'column-reverse',
                                    }}>
                                        <div className="ms-fontSize-20">
                                            <div className='ms-fontWeight-semibold' style={{
                                                margin: '0px',
                                                textAlign: 'center',
                                            }}>
                                                {card.title}
                                            </div>
                                        </div>
                                        <FontIcon aria-label={card.icon} iconName={card.icon} className={ThemeBase.classes.cardIcon}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}