const MESSAGE_LOADING = 'Enviando espere un momento';
const MESSAGE_EXPIRED = 'Su sesión ha expirado, inicie sesión nuevamente';

const MESSAGE_CREATE = 'Se registró correctamente';
const MESSAGE_UPDATE = 'Se actualizó correctamente';
const MESSAGE_DELETE = 'Se eliminó correctamente';

const MESSAGE_FILTRO = 'Elija las condiciones para el filtro personalizado';

const _getMessageText = (type: number) => {
    let m = '';

    switch (type) {
        case 1:
            m = MESSAGE_CREATE;
            break;

        case 2:
            m = MESSAGE_UPDATE;
            break;
        case 3:
            m = MESSAGE_DELETE;
            break;
        default:
            break;
    }

    return m;
}

export const MessageHelper = {
    MESSAGE_LOADING,
    MESSAGE_EXPIRED,
    _getMessageText,
    MESSAGE_FILTRO,
}