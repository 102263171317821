import {
  ComboBox,
  DatePicker,
  IComboBox,
  IComboBoxOption,
  IDatePicker,
  IPersonaSharedProps,
  Panel,
  PanelType,
  Persona,
  PersonaPresence,
  PersonaSize,
  Stack,
  TextField,
  Toggle,
  values,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { IUserAuthenticate } from "../../auth/interface/IAuth";
import { ButtonForm } from "../../components/Generic/buttonForm";
import {
  IBase,
  IException,
  IItemComboBaseDTO,
  ISearch,
} from "../../interfaces/base/IBase";
import { IHotel } from "../../interfaces/IHotel";
import {
  IListarPersonaById,
  IPersona,
  IUpdateKeyUser,
} from "../../interfaces/IPersona";
import HoteleriaService from "../../services/HoteleriaService";
import PersonaService from "../../services/PersonaService";
import ProgramacionService from "../../services/ProgramacionService";
import UbicacionService from "../../services/ubicacionesServices";
import {
  _formatDate,
  _formatDatePrefix,
  _formatDateShort,
} from "../../utils/Helper/datetimeHelper";
import {
  EXPRESION_CELULAR,
  EXPRESION_EMAIL,
  keyAccess,
  splitExceptionDetail,
  stackTokens,
  _initialFilter,
} from "../../utils/Helper/helper";

let _openPanel: boolean;
let _headerPanelName: string;
const _dismissPanel = () => {};
const _setup = () => {};
let _dataDetailId: IPersona;

const INITIAL_MODEL = {
  persona: "string",
  personaId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  lugarAcuartelamientoId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  hotelId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  celular: "",
  email: "",
  fechaNacimineto: new Date(),
};

function EditarPersonaKeyUser({
  openPanel = _openPanel,
  dismissPanel = _dismissPanel,
  dataDetail = _dataDetailId,
  setup = _setup,
}) {
  const [inputForm, setInputForm] = useState<IUpdateKeyUser>(INITIAL_MODEL);
  const [sendForm, setSendForm] = useState(false);
  const [emailIsCorrect, setEmailIsCorrect] = useState(true);
  const [celularIsCorrrect, setCelularIsCorrrect] = useState(true);

  const [lugarCuarentena, setlugarCuarentena] = useState<IItemComboBaseDTO[]>(
    []
  );
  const [hotel, setHotel] = useState<IItemComboBaseDTO[]>([]);
  const refFecNacRef = React.useRef<IDatePicker>(null);
  const cuarentenaRef = React.useRef<IComboBox>(null);
  const _openOptionCuarentena = React.useCallback(
    () => cuarentenaRef.current?.focus(true),
    []
  );

  /* Filters */
  const [filterCuarentena, setfilterCuarentena] =
    useState<ISearch>(_initialFilter);

  const _loadForm = () => {
    if (dataDetail?.celular != "" && dataDetail?.email != "") setSendForm(true);
    setInputForm({
      ...inputForm,
      personaId: dataDetail?.personaId,
      persona: dataDetail?.persona,
      celular: dataDetail?.celular,
      fechaNacimineto: new Date(dataDetail?.fechaNacimineto),
      email: dataDetail?.email,
      lugarAcuartelamientoId: dataDetail?.lugarAcuartelamientoId,
    });
  };

  useEffect(() => {
    _loadForm();
    dataLugarCuarentena();
    dataHotel();
  }, [dataDetail]);

  const onRenderFooter = () => (
    <div>
      <ButtonForm
        back={dismissPanel}
        register={_updateDataPersona}
        disabledRegister={!sendForm}
      ></ButtonForm>
    </div>
  );

  useEffect(() => {
    (celularIsCorrrect && emailIsCorrect)? setSendForm(true):setSendForm(false)
  }, [celularIsCorrrect,emailIsCorrect]);

  const _updateDataPersona = () => {
    inputForm.email != ""
      ? PersonaService.putPersonaKeyUser(inputForm)
          .then((res) => {
            if (res.status == 200) {
              alert("Datos Actulizados");
              dismissPanel();
              setup();
            }
          })
          .catch((e: IException) => {
            alert(splitExceptionDetail(e));
            dismissPanel();
            setup();
          })
      : alert("Complete campos requeridos");
  };

  const dataHotel = () => {
    HoteleriaService.getHoteles(50)
      .then((res) => {
        let d: IHotel[] = res.data.items;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.hotelID != null ? x.hotelID : "",
            text: `${x.nombre} | ${x.direccion}`,
          };
        });

        setHotel(t);
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  const _onChangeEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != null) {
      setInputForm({
        ...inputForm,
        email: newValue,
      });
    }
  };

  const _onChangeCelular = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setInputForm({
        ...inputForm,
        celular: newValue,
      });
    }
  };

  const dataLugarCuarentena = () => {
    UbicacionService.comboUbicaciones(2)
      .then((res) => {
        let d: IBase[] = res.data.data;
        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });
        setlugarCuarentena(t);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const _onSelectFechaNac = (e?) => {
    if (e != null) {
      setInputForm({
        ...inputForm,
        fechaNacimineto: e,
      });
    }
  };

  const _onChangeComboLugarC = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setInputForm({
          ...inputForm,
          lugarAcuartelamientoId: String(option.key),
        });
      }
    }
  };

  // const _onChangeComboHotel = (
  //   event: React.FormEvent<IComboBox>,
  //   option?: IComboBoxOption
  // ) => {
  //   if (event != undefined) {
  //     if (option != undefined) {
  //       setInputForm({
  //         ...inputForm,
  //         hotelId: String(option.key),
  //       });
  //     }
  //   }
  // };

  const _onBlurComboCuarentena = () => {
    if (
      inputForm.lugarAcuartelamientoId == "" ||
      inputForm.lugarAcuartelamientoId == undefined
    ) {
      setfilterCuarentena({
        ...filterCuarentena,
        search: "",
      });
    }
  };
  const _onInputCuarentena = (e?: any) => {
    _openOptionCuarentena();
    let { value } = e?.target as HTMLInputElement;
    setInputForm({
      ...inputForm,
      lugarAcuartelamientoId: "",
    });
    setfilterCuarentena({
      ...filterCuarentena,
      search: value,
    });
  };

  const getErrorMessageEmail = (value: string): string => {
    EXPRESION_EMAIL.test(value)
      ? setEmailIsCorrect(true)
      : setEmailIsCorrect(false);

    return value.length > 0 || value != ""
      ? EXPRESION_EMAIL.test(value)
        ? ""
        : "Email invalido"
      : `Este valor no puede ser vacio`;
  };

  const getErrorMessageCelular = (value: string): string => {
    EXPRESION_CELULAR.test(value)
      ? setCelularIsCorrrect(true)
      : setCelularIsCorrrect(false);

    return value.length > 0 || value != ""
      ? EXPRESION_CELULAR.test(value)
        ? ""
        : "Celular invalido"
      : `Este valor no puede ser vacio`;
  };

  /**Detalle corto de Persona */
  const detailPersona: IPersonaSharedProps = {
    imageUrl: dataDetail?.foto,
    imageInitials: dataDetail?.primerNombre.charAt(0).toUpperCase(),
    text: dataDetail?.persona,
    secondaryText: dataDetail?.gerencia,
  };

  return (
    <>
      <Panel
        isOpen={openPanel}
        onDismiss={dismissPanel}
        headerText="Actualizacion Persona - Rol KeyUser"
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooter}
        isFooterAtBottom={true}
      >
        <form>
          <div style={{ paddingTop: "20%" }}>
            <Stack tokens={stackTokens}>
              <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-lg12">
                    <Persona
                      {...detailPersona}
                      size={PersonaSize.size56}
                      presence={PersonaPresence.none}
                      imageAlt={dataDetail?.primerNombre}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <DatePicker
                      label="Fecha Nac."
                      allowTextInput
                      componentRef={refFecNacRef}
                      ariaLabel="Select a date. Input format is day slash month slash year."
                      formatDate={(d) => _formatDateShort(d)}
                      onSelectDate={_onSelectFechaNac}
                      value={inputForm?.fechaNacimineto}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <TextField
                      label="Celular"
                      value={inputForm?.celular}
                      defaultValue={inputForm?.celular}
                      placeholder={inputForm?.celular}
                      onChange={_onChangeCelular}
                      autoComplete="off"
                      onGetErrorMessage={getErrorMessageCelular}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <TextField
                      label="Email"
                      defaultValue={inputForm?.email}
                      value={inputForm?.email}
                      placeholder={inputForm?.email}
                      onChange={_onChangeEmail}
                      autoComplete="off"
                      onGetErrorMessage={getErrorMessageEmail}
                      required
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <ComboBox
                      componentRef={cuarentenaRef}
                      label="Lugar de cuarentena"
                      allowFreeform
                      options={lugarCuarentena}
                      calloutProps={{ directionalHintFixed: true }}
                      selectedKey={
                        inputForm ? inputForm.lugarAcuartelamientoId : ""
                      }
                      useComboBoxAsMenuWidth
                      openOnKeyboardFocus
                      autoComplete="off"
                      onInput={_onInputCuarentena}
                      onChange={_onChangeComboLugarC}
                      onBlur={_onBlurComboCuarentena}
                    />
                  </div>
                </div>

                {/* <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-lg6">
                    <ComboBox 
                    label="Hotel" 
                    options={hotel} 
                     calloutProps={{ directionalHintFixed: true }}
                      selectedKey={inputForm ? inputForm.hotelId : ""}
                      useComboBoxAsMenuWidth
                      autoComplete="off"
                      onChange={_onChangeComboHotel}
                      openOnKeyboardFocus
                    /> 
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-lg6"></div>
                </div> */}
              </div>
            </Stack>
          </div>
          {/* {JSON.stringify(model?.celular, null, 2)}
          {JSON.stringify(model?.personaId, null, 2)} */}
        </form>
      </Panel>
    </>
  );
}

export default EditarPersonaKeyUser;
