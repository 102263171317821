import { ActivityItem, ComboBox, DatePicker, FontIcon, IColumn, ICommandBarItemProps, Icon, IconButton, Label, Link, Panel, Persona, PersonaSize, Separator, Stack, TextField, TooltipHost } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { HeadingBar } from '../../components/Generic/HeadingBar'
import { TableBase } from '../../components/Table/TableBase'
import { IDataCollection, IPagination } from '../../interfaces/base/ITable';
import { Ifilter } from '../../interfaces/IFilter';
import { IHistoricoAcceso } from '../../interfaces/IProgramacion';
import ProgramacionPersonaService from '../../services/programacionPersonaService';
import { baseImageUrl, ItemNoneSelect, splitExceptionDetail, stackTokensHorizontal, _initialFilter, _initialPagination } from '../../utils/Helper/helper';
import { IconHelper } from '../../utils/Helper/iconHelper';
import { _initialDataCollection } from '../../utils/Helper/tableHelper';
import ThemeBase from '../../utils/Theme/themeBase';
import { useBoolean } from '@fluentui/react-hooks';
import { LogActividad } from '../accesoPersona/logActividad/logActividad';
import { State } from '../../components/Generic/state';
import { BusquedaHistorico } from './busquedaHistorico';
import { IFilterHistorico } from '../../interfaces/filter/IFilterHistorico';
import { IException, IItemComboBaseDTO } from '../../interfaces/base/IBase';
import FileSaver from 'file-saver';
import { IFilterDownLoadHistorico } from '../../interfaces/IHistorico';
import IdicadorProgreso from '../../components/Generic/IndicadorProgreso';
import { _formatDate, _formatDatePrefix } from '../../utils/Helper/datetimeHelper';
import OrganigramaService from '../../services/organigramaService';

export const ListarHistorico = () => {
    const [hidden, setHidden] = useState(false);
    const [data, setData] = useState<IDataCollection<IHistoricoAcceso>>(_initialDataCollection);
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Panel */
    const [selId, setSelId] = useState<string | undefined>(undefined);
    const [isOpenLog, { setTrue: openLog, setFalse: dismissLog },] = useBoolean(false);
    const [isOpenBusqueda, { setTrue: openBusqueda, setFalse: dismissBusqueda }] = useBoolean(false);

    /* Filter */
    const [filter, setFilter] = useState<IFilterHistorico>(_initialFilter);

    /*Historico*/
    const [filterDownloadHistorico, setFilterDownloadHistorico] = useState<IFilterDownLoadHistorico>();
    const [isDownloadHistorico, setIsDownloadHistorico] = useState(false);
    const [isOpenDownload, { setTrue: openPanelDownload, setFalse: dismissPanelDownload }] = useBoolean(false);
    const [inicio, setInicio] = useState<Date>();
    const [fin, setFin] = useState<Date>();
    const [gerencia, setGerencia] = useState<IItemComboBaseDTO[]>([]);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(
        _initialPagination().currentPage
    );
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    /* Hook */
    useEffect(() => {
        reloadInitial();
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    /* Api */
    const dataHistorico = (param = filter) => {
        setData(_initialDataCollection);
        ProgramacionPersonaService.historico(param).then(res => {
            console.log(res);
            if (res.status == 200) {
                setData(res.data);
                setTotal(res.data.total);
            }
            setHidden(true);
        }).catch((e: IException) => {
            setHidden(true);
            alert(splitExceptionDetail(e));
        })
    }

    const downloadHistorico = () => {
        setIsDownloadHistorico(true)
        console.log(filterDownloadHistorico)
        ProgramacionPersonaService.downLoadhistorico(filterDownloadHistorico).then((arg) => {
            console.log(arg.data)
            FileSaver.saveAs(arg.data)
            setIsDownloadHistorico(false)
        }).catch((e: Error) => {
            alert(e.message)
            setIsDownloadHistorico(false)
        })
        return
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        dataHistorico(param);
        setTitleColumn();
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial()
            },
        ];
        return _items;
    }

    const _rightBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_FILTER,
                onClick: openBusqueda,
            },
            {
                ...IconHelper.ICON_EXCEL,
                onClick: openPanelDownload
            },
        ];
        return _items;
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Foto',
                ariaLabel:
                    'Column operations for File type, Press to sort on File type',
                isIconOnly: true,
                fieldName: 'foto',
                minWidth: 45,
                maxWidth: 45,
                onRender: (item: IHistoricoAcceso) => (
                    <TooltipHost content={`Foto`}>
                        <Persona
                            text=''
                            size={PersonaSize.size24}
                            imageUrl={baseImageUrl}
                        />
                    </TooltipHost>
                ),
            },
            {
                key: 'column2',
                name: 'Nombre',
                fieldName: 'nombres',
                minWidth: 160,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                isPadded: true,
                onRender: (item: IHistoricoAcceso) => {
                    return (
                        <span
                            style={{
                                fontSize: '90%',
                                textDecorationLine:
                                    item.motivoRetiro == '' ? 'none' : 'line-through',
                            }}
                        >
                            {item.nombres}
                        </span>
                    );
                },
            },
            {
                key: 'column3',
                name: 'Identificación',
                fieldName: 'nroDOI',
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                isPadded: true,
                onRender: (item: IHistoricoAcceso) => {
                    return (
                        <span
                            style={{ fontSize: '95%' }}
                        >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
                    );
                },
            },
            {
                key: 'column4',
                name: 'Fecha Mov.',
                fieldName: 'fechaMovilizacion',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column5',
                name: 'Actividad',
                fieldName: 'actividad',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column6',
                name: 'Turno',
                fieldName: 'turno',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column7',
                name: 'Gerencia',
                fieldName: 'gerencia',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column8',
                name: 'Empresa Persona',
                fieldName: 'empresa',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column9',
                name: 'Transporte',
                fieldName: 'requiereTransporte',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onRender: (item: IHistoricoAcceso) => {
                    return (
                        <span
                            style={{ fontSize: '95%', ...ThemeBase.justifiedStyle }}>{item.requiereTransporte ? 'Si' : 'No'}</span>
                    );
                },
            },
            {
                key: 'column10',
                name: 'Comunidad',
                fieldName: 'esComunidad',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onRender: (item: IHistoricoAcceso) => {
                    return (
                        <span
                            style={{ fontSize: '95%', ...ThemeBase.justifiedStyle }}>{item.esComunidad ? 'Si' : 'No'}</span>
                    );
                },
            },
            {
                key: 'column11',
                name: 'Visita',
                fieldName: 'esVisita',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onRender: (item: IHistoricoAcceso) => {
                    return (
                        <span
                            style={{ fontSize: '95%', ...ThemeBase.justifiedStyle }}>{item.esVisita ? 'Si' : 'No'}</span>
                    );
                },
            },
            {
                key: 'column12',
                name: 'Empresa Transportista',
                fieldName: 'transporte',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column13',
                name: 'Es Adicional',
                fieldName: 'esAdicional',
                minWidth: 70,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column14',
                name: 'Proceso - Estado',
                fieldName: 'nivelActual',
                minWidth: 250,
                maxWidth: 250,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column15',
                name: 'Lugar Mov.',
                fieldName: 'lugarMovilizacion',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column16',
                name: 'Origen',
                fieldName: 'origen',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column17',
                name: 'Destino',
                fieldName: 'destino',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column18',
                name: 'Ctro Costo',
                fieldName: 'centroCosto',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column19',
                name: 'Usuario Registra',
                fieldName: 'nombreUsuarioRegistra',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: "column20",
                name: "Estado",
                fieldName: "estado",
                minWidth: 70,
                maxWidth: 80,
                isResizable: true,
                isMultiline: true,
                data: "string",
                isPadded: true,
                onRender: (item: IHistoricoAcceso) => (
                    <>
                        <State estado={item.estadoProgramacion} colorEstado={item.colorProgramacion} width={100}></State>
                    </>
                )
            },
            {
                key: 'column21',
                name: 'Opciones',
                fieldName: 'opciones',
                minWidth: 90,
                maxWidth: 110,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
                onRender: (item: IHistoricoAcceso) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <TooltipHost content={`Actividades`}>
                                <Icon
                                    iconName={IconHelper.ICON_INFO.iconProps?.iconName}
                                    style={{ cursor: 'pointer' }}
                                    className={`${ThemeBase.classes.iconFont}`}
                                    onClick={() => {
                                        setSelId(item.programacionPersonaId);
                                        openLog();
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    }

    /* Event Pagination */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: Ifilter = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    /* Event Filter */
    const _onChangeText = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {
            if (newValue === '') {
                _onClearFilter();
            } else {
                setFilter({
                    ...filter,
                    search: newValue,
                })
            }
        }
    };
    const dataGerencia = () => {
        setGerencia([]);
        OrganigramaService.organigramaByTipo('01', {})
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemNoneSelect());
                    setGerencia(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };
    const _onPressFilter = (newValue: string) => {
        let f: Ifilter = {
            ...filter,
            search: newValue,
        };

        setFilter(f);
        reloadInitial(f);
    };

    const _onClearFilter = () => {
        let f: Ifilter = {
            ..._initialFilter()
        }

        setFilter(f);
        reloadInitial(f);
    }

    useEffect(() => {
        if (inicio != undefined) {
            setFilterDownloadHistorico({
                ...filterDownloadHistorico,
                FechaInicio: _formatDate(inicio, ','),
            })
        }
    }, [inicio]);

    useEffect(() => {
        if (fin != undefined) {
            setFilterDownloadHistorico({
                ...filterDownloadHistorico,
                FechaFin: _formatDate(fin, ',')
            })
        }
    }, [fin]);

    useEffect(() => {
        if (isOpenDownload) {
            dataGerencia()
        }
        setInicio(undefined)
        setFin(undefined)
        setFilterDownloadHistorico({})
    }, [isOpenDownload]);

    
    
    return (
        <>
            <HeadingBar
                title='Historico'
                subTitle='Personal Inscrito'
                searchChange={_onChangeText}
                searchHandler={_onPressFilter}
                searchValue={filter.search ?? ''}
                onClearHandler={_onClearFilter}
            ></HeadingBar>

            {/* Descarga de historial */}
            <Panel
                headerText="Descarga De Historial"
                isOpen={isOpenDownload}
                onDismiss={dismissPanelDownload}
                closeButtonAriaLabel="Close"

            >
                <br />
                <Label>Intervalo de fecha para descarga. 
                    <TooltipHost
                        content="Si no selecciona un rango de fecha, se descarga reporte con la fecha actual"
                    >
                        <FontIcon iconName="info" style={{ fontSize: 14, cursor: 'pointer',fontWeight:'bold',color:'#0078D3' }} />
                    </TooltipHost>
                </Label>
                <DatePicker
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    placeholder="Seleccione Inicio..."
                    ariaLabel="Select a date. Input format is day slash month slash year."
                    formatDate={(arg) => _formatDatePrefix('F. Inicio', arg)}
                    onSelectDate={setInicio as (arg) => void}
                    value={inicio}
                    maxDate={fin}
                // strings={defaultDatePickerStrings}
                />
                <br />
                <DatePicker
                    showWeekNumbers={true}
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay={true}
                    placeholder="Seleccione Fin..."
                    ariaLabel="Select a date. Input format is day slash month slash year."
                    formatDate={(arg) => _formatDatePrefix('F. Fin', arg)}
                    onSelectDate={setFin as (arg) => void}
                    value={fin}
                    minDate={inicio}
                // disabled={inicio == null ? true : false}
                />
                <br />
                <ComboBox
                    label='Gerencia'
                    options={gerencia}
                    onChange={(eve, item) => {
                        if (item != undefined && item != null) {
                            setFilterDownloadHistorico({ ...filterDownloadHistorico, GerenciaId: item.key.toString() })
                        }
                    }}
                />
                <br />
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Separator>
                        <Icon iconName="Download" />
                    </Separator>
                    <Stack horizontalAlign='center'>
                        <FontIcon
                            aria-label={IconHelper.ICON_EXCEL.iconProps?.iconName}
                            iconName={IconHelper.ICON_EXCEL.iconProps?.iconName}
                            style={{
                                color: ThemeBase.theme.palette.green,
                                fontSize: 40,
                                height: 40,
                                width: 40,
                                margin: "0 25px",
                                cursor: 'pointer'
                            }}
                            onClick={downloadHistorico}
                        />
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ActivityItem
                                key="1"
                                activityDescription={[
                                    <Link
                                        key='1'
                                        className={ThemeBase.activityClass.nameText}
                                        disabled={isDownloadHistorico}
                                        onClick={downloadHistorico}
                                    >
                                        Descargar
                                    </Link>,
                                ]}
                                timeStamp={`Archivo en formato Excel`}
                            />
                        </div>
                        <IdicadorProgreso subText='Generando Archivo Excel' isSend={isDownloadHistorico} />
                    </Stack>
                    <Separator />
                </div>

            </Panel>

            {/* Tabla */}
            <TableBase leftBar={_leftBar()} rigthBar={_rightBar()} column={column} data={data.items} pagination={pager} isLoading={!hidden}></TableBase>

            {/* Panel Busqueda */}
            <BusquedaHistorico filterObject={filter} setFilterObject={setFilter} isOpen={isOpenBusqueda} onDismiss={dismissBusqueda} reloadInitial={reloadInitial}></BusquedaHistorico>

            {/* Panel Log */}
            <LogActividad programacionPersonaId={selId} isOpen={isOpenLog} dismiss={dismissLog}></LogActividad>
        </>
    )
}