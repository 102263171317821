import {
  Checkbox,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  Stack,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import ThemeBase from "../../../utils/Theme/themeBase";
import ConfiguracionService from "../../../services/configuracionService";
import { Divider } from "@fluentui/react-components";

export const PanelRolesSupervisor = ({
  isOpen = false,
  dissmiss = () => {},
  id,
}) => {
  useEffect(() => {
    if (!isOpen) {
    }
  }, [isOpen]);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<any>([]);
  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await ConfiguracionService.rolByPersonaId(id);
      if (response.status === 200) {
        setRoles(response.data.role);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) getRoles();
  }, [isOpen, id]);

  const _cerrar = () => {
    dissmiss();
    setRoles([]);
  };

  /* Footer panel */
  const onRenderFooter = React.useCallback(
    () => (
      <div>
        <PrimaryButton onClick={_cerrar} styles={ThemeBase.panelButtonStyle}>
          ACEPTAR
        </PrimaryButton>
      </div>
    ),
    []
  );

  return (
    <>
      <Panel
        isOpen={isOpen}
        onDismiss={_cerrar}
        type={PanelType.smallFixedFar}
        headerText="Roles de Supervisor"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooter}
        isFooterAtBottom={true}
      >
        <Stack>
          <div
            className="ms-Grid m-2"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px",
              borderTopWidth: "1px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                {roles.map((rol) => (
                  <Checkbox checked={!!rol.text} label={rol.text} />
                ))}
              </>
            )}
          </div>
        </Stack>
      </Panel>
    </>
  );
};
