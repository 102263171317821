import React from 'react'
import { fetchDispositivo } from './http-common'
import { axiosTeseo } from './Teseo/AxiosTeseo'

let dispositivoUrl = 'Dispositivo'

const LitarDispositivoPersonaID = (id :string) => {
    return fetchDispositivo.get(`Dispositivo/ListarDispositivoPorPersonaId?PersonaId=${id}`)
}
//id: string, IsSendEmail: boolean

const AutorizarDispositivo = (objetoAutorizar:any) => {
    return fetchDispositivo.post(`Dispositivo/AutorizarDispositivoPorPersonaId`,objetoAutorizar)
}

const listarInfoDispositivo = (infoWebId: string) => {
  return fetchDispositivo.get(`/Dispositivo/ListarDispositivoInfoWeb?InfoWeb=${infoWebId}`)
}
const DispositivoService = {
        LitarDispositivoPersonaID,
        AutorizarDispositivo,
        listarInfoDispositivo
}

export default DispositivoService