import { IColumn, ICommandBarItemProps, Icon, Pivot, PivotItem, Stack, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { TableBase } from "../../components/Table/TableBase";
import { IException } from "../../interfaces/base/IBase";
import { IDataCollection } from "../../interfaces/base/ITable";
import { Ifilter } from "../../interfaces/IFilter";
import { IProgramacion } from "../../interfaces/IProgramacion";
import PersonaService from "../../services/PersonaService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { splitExceptionDetail, stackTokensHorizontal, TAB_BAJADA, TAB_SUBIDA, _initialFilter } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import ThemeBase from "../../utils/Theme/themeBase";

export const ListarProgAreaTransporte = () => {
    const navigate = useNavigate();
    const [hidden, setHidden] = useState(false);

    const [ingreso, setIngreso] = useState<IDataCollection<IProgramacion>>(_initialDataCollection);
    const [salida, setSalida] = useState<IDataCollection<IProgramacion>>(_initialDataCollection);
    const [columnIngreso, setColumnIngreso] = useState<IColumn[]>([]);
    const [columnSalida, setColumnSalida] = useState<IColumn[]>([]);

    const [tabIndex, setTabIndex] = useState('0');
    const [filter, setFilter] = useState<Ifilter>(_initialFilter);

    useEffect(() => {
        switch (tabIndex) {
            case '0':
                setHidden(false);
                setTitleIngresoColumn();
                dataIngreso();
                break;
            case '1':
                setHidden(false);
                setTitleSalida();
                dataSalida();
                break;
            default:
                break;
        }
    }, [tabIndex]);

    /* Effect */
    useEffect(() => {
        setTitleIngresoColumn();
        setup();
    }, []);

    /* Api */
    const dataIngreso = () => {
        PersonaService.getListaProgramacionesTransportes(1, filter).then(res => {
            setIngreso(res.data);
            setHidden(true);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        });
    }

    const dataSalida = () => {
        setSalida(_initialDataCollection);
        PersonaService.getListaProgramaSalidas(filter).then(res => {
            setSalida(res.data);
            setHidden(true);
        }).catch((err) => {
            alert(err.message);
            setHidden(true);
        });
    }


    /* Function */
    const setup = () => {
        setHidden(false);
        dataIngreso();
    }

    const setTitleIngresoColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Programacion',
                fieldName: 'titulo',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column1.2',
                name: 'Tipo',
                fieldName: 'tipoPersonal',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Fecha Cierre',
                fieldName: 'fechaCierre',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Fecha de Movilización',
                fieldName: 'fechaIngreso',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Fecha de Bajada',
                fieldName: 'fechaSalida',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Guardia',
                fieldName: 'guardia',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Estado',
                fieldName: 'estado',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Inscritos',
                fieldName: 'inscritos',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
                isPadded: true,
            },
            {
                key: "column9",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IProgramacion) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <TooltipHost content={`Detalle`}>
                                <Icon iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                                    style={{ cursor: 'pointer' }}
                                    className={ThemeBase.classes.iconFont}
                                    onClick={() => navigate(`${ConfigHelper.urlBase()}/area.transporte/${item.programacionId}`)}
                                />
                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumnIngreso(col);
    };

    const setTitleSalida = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Programacion',
                fieldName: 'titulo',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                isPadded: true,
            },
            {
                key: "column2",
                name: "Tipo",
                fieldName: "tipoPersonal",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                data: "string",
                isPadded: true,
            },
            {
                key: 'column2.1',
                name: 'Fecha Salida',
                fieldName: 'fechaIngreso',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Guardia',
                fieldName: 'guardia',
                minWidth: 220,
                maxWidth: 220,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Estado',
                fieldName: 'estado',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Inscritos',
                fieldName: 'inscritos',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
                isPadded: true,
            },
            {
                key: "column6",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IProgramacion) => (
                    <>
                        <TooltipHost content={`Detalle`}>
                            <Icon iconName='ContactList'
                                style={{ cursor: 'pointer' }}
                                className={ThemeBase.classes.iconFont}
                                onClick={() => { navigate(`${ConfigHelper.urlBase()}/programaciones/${item.programacionId}`) }}
                            />
                        </TooltipHost>
                    </>
                ),
            },
        ];

        setColumnSalida(col);
    };

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: setup
            },
        ];
        return _items;
    }

    /* Event */
    const _onChangeTab = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (item != undefined) {
            let { itemKey } = item.props;

            if (itemKey != undefined) {
                setTabIndex(itemKey);
            }
        }
    }

    return (
        <>
            <HeadingBar
                title='Área Transporte'
                subTitle='Gestión de programaciones'
                searchShow={false}
            ></HeadingBar>

            <div style={{ padding: '20px' }}>
                <Pivot aria-label="Basic Pivot Example" selectedKey={tabIndex} onLinkClick={_onChangeTab}>
                    <PivotItem itemKey='0' headerText={TAB_SUBIDA}>
                        <TableBase leftBar={_leftBar()} column={columnIngreso} data={ingreso.items} isLoading={!hidden} style={ThemeBase.notPaddingTableBase}></TableBase>
                    </PivotItem>

                    <PivotItem itemKey='1' headerText={TAB_BAJADA}>
                        <TableBase column={columnSalida} data={salida.items} style={ThemeBase.notPaddingTableBase} isLoading={!hidden}></TableBase>
                    </PivotItem>
                </Pivot>
            </div>
        </>
    )
}