import { ActivityItem, DefaultButton, FontIcon, IColumn, ICommandBarItemProps, Link, Panel, PanelType, PrimaryButton, ProgressIndicator, setVirtualParent, Stack } from "@fluentui/react"
import React, { useEffect, useState } from "react";
import { CustomCommandBar } from "../../../../components/Generic/customCommandBar";
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar";
import { IFile } from "../../../../interfaces/base/IBase";
import { IResultadoCargaMasiva } from "../../../../interfaces/IProgramacion";
import ProgramacionPersonaService from "../../../../services/programacionPersonaService";
import { BlobHelper } from "../../../../utils/Helper/blobHelper";
import { downloadBlob, splitExceptionDetail, stackTokens } from "../../../../utils/Helper/helper";
import { IconHelper } from "../../../../utils/Helper/iconHelper";
import { MessageHelper } from "../../../../utils/Helper/messageHelper";
import ThemeBase from "../../../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { HeadingBar } from "../../../../components/Generic/HeadingBar";
import { TableBase } from "../../../../components/Table/TableBase";

export const MasivoDatosVuelo = ({ programacionId = '', isTabPersona = false, isOpen = false, dismiss = () => { }, reloadParent = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* File */
    const [fileDetail, setfileDetail] = React.useState<IFile>(null!);
    const [excel, setExcel] = React.useState<FormData>(null!);
    const [detalleMasivo, setDetalleMasivo] = useState<IResultadoCargaMasiva>(null!);
    const [isDetalleMasivo, { setTrue: openDetalleMasivo, setFalse: dismissDetalleMasivo },] = useBoolean(false);
    const [loadingDetalleMasivo, setLoadingDetalleMasivo] = useState(true);

    /* Masivo */
    const [columnMasivo, setColumnMasivo] = useState<IColumn[]>([]);
    const [isOpenMasivo, { setTrue: openMasivo, setFalse: dismissMasiva },] = useBoolean(false);

    /* Hook */
    useEffect(() => {
        setExcel(null!);
        setfileDetail(null!);
    }, [isOpen]);

    /* Api */
    const loadExcel = () => {
        if (programacionId != '') {

            setIsSend(true);
            setChoice(undefined);

            ProgramacionPersonaService.actualizarHeloExcel(excel, programacionId, isTabPersona)
                .then((res) => {
                    setIsSend(false)

                    if (res.status == 200) {
                        let d: IResultadoCargaMasiva = res.data;

                        if (d.detalle.length == 0) {
                            alert("Se registró correctamente");
                            dismiss();
                            reloadParent();
                        } else {
                            /* Panel resultado expcepciones */
                            alert(d.respuesta);
                            reloadParent();
                            setTitleDetalleCarga();
                            dismissMasiva();
                            setDetalleMasivo(d);
                            openDetalleMasivo();
                        }

                        setLoadingDetalleMasivo(false);
                    }
                })
                .catch((e) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    }

    /* Function */
    const downloadBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_DOWNLOAD,
                text: "Descargar Plantilla",
                onClick: () => downloadBlob(BlobHelper.urlActualizarDatosHelo),
            },
        ];

        return _items;
    };

    const uploadBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_UPLOAD,
                text: "Cargar Plantilla",
                preferMenuTargetAsEventTarget: true,
                onClick: (
                    ev?:
                        | React.MouseEvent<HTMLElement, MouseEvent>
                        | React.KeyboardEvent<HTMLElement>
                        | undefined
                ) => {
                    ev?.persist();

                    Promise.resolve().then(() => {
                        const inputElement = document.createElement("input");
                        inputElement.style.visibility = "hidden";
                        inputElement.setAttribute("type", "file");
                        inputElement.setAttribute(
                            "accept",
                            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        );
                        inputElement.onchange = function (ev) {
                            let { files } = ev.target as HTMLInputElement;

                            if (files != undefined) {
                                const file: File = files[0];

                                var formData = new FormData();
                                formData.append("file", file, file.name);

                                setExcel(formData);

                                setfileDetail({
                                    nombre: file.name,
                                    tamanio: file.size,
                                    tipo: file.type,
                                });
                            }
                        };

                        document.body.appendChild(inputElement);

                        const target = ev?.target as HTMLElement | undefined;

                        if (target) {
                            setVirtualParent(inputElement, target);
                        }

                        inputElement.click();

                        if (target) {
                            setVirtualParent(inputElement, null);
                        }

                        setTimeout(() => {
                            inputElement.remove();
                        }, 10000);
                    });
                },
            },
        ];

        return _items;
    };

    /* Masivo */
    const setTitleDetalleCarga = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Tipo DOI",
                fieldName: "tipoDoi",
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column1",
                name: "Nº. DOI",
                fieldName: "doi",
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column2",
                name: "Observación",
                fieldName: "log",
                minWidth: 400,
                maxWidth: 400,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
        ];

        setColumnMasivo(col);
    };

    const _rigthBarDetalleMasivo = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: () => { },
            },
        ];

        return _items;
    };

    /* Render footer */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <Stack tokens={stackTokens}>
                    <PrimaryButton onClick={loadExcel} styles={ThemeBase.panelButtonStyle} disabled={isSend || excel == null}>
                        Actualizar
                    </PrimaryButton>
                </Stack>
            </div>
        ),
        [programacionId, isOpen, excel, dismiss, isSend]
    );

    const _onRenderFooterDetalleMasivo = React.useCallback(
        () => (
            <div>
                <DefaultButton onClick={dismissDetalleMasivo}>Cancelar</DefaultButton>
            </div>
        ),
        [dismissDetalleMasivo, detalleMasivo]
    );

    return (
        <>
            {/* Load Excel */}
            <Panel
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText='Actualizar datos vuelo'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                            <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                            {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                            {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                reloadParent();
                            }}></CustomMessageBar>}
                        </div>
                    </div>
                </div>


                <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                    <div style={{ paddingTop: "20%", paddingLeft: "0px" }}>
                        <CustomCommandBar item={downloadBar()} style={{ paddingRight: "0px", paddingLeft: "0px" }}></CustomCommandBar>
                    </div>

                    <div style={{ paddingTop: "30%", paddingLeft: "0px" }}>
                        <CustomCommandBar item={uploadBar()} style={{ paddingRight: "0px", paddingLeft: "0px" }}></CustomCommandBar>
                    </div>

                    <div style={{ paddingTop: "40%", paddingLeft: "0px" }}>
                        {fileDetail && (
                            <>
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div
                                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <FontIcon
                                                aria-label={IconHelper.ICON_EXCEL.iconProps?.iconName}
                                                iconName={IconHelper.ICON_EXCEL.iconProps?.iconName}
                                                style={{
                                                    color: ThemeBase.theme.palette.blackTranslucent40,
                                                    fontSize: 40,
                                                    height: 40,
                                                    width: 40,
                                                    margin: "0 25px",
                                                }}
                                            />
                                        </div>

                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <ActivityItem
                                                activityDescription={[
                                                    <Link className={ThemeBase.activityClass.nameText}>
                                                        {fileDetail.nombre}
                                                    </Link>,
                                                    <span> </span>,
                                                    <span className={ThemeBase.activityClass.nameText}>
                                                        {fileDetail.tipo}
                                                    </span>,
                                                ]}
                                                timeStamp={`${(fileDetail.tamanio / 1048576).toFixed(
                                                    2
                                                )} mb`}
                                                className={ThemeBase.activityClass.exampleRoot}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </form>

            </Panel>

            {/* Detalle Load Excel */}
            <Panel
                isOpen={isDetalleMasivo}
                onDismiss={dismissDetalleMasivo}
                headerText=""
                closeButtonAriaLabel="Close"
                type={PanelType.medium}
                onRenderFooterContent={_onRenderFooterDetalleMasivo}
                isFooterAtBottom={true}
            >
                <div>
                    <HeadingBar
                        title='Personas No Actualizadas'
                        subTitle="Resultado Actualización Masiva"
                        searchShow={false}
                    ></HeadingBar>
                    <TableBase
                        leftBar={_rigthBarDetalleMasivo()}
                        column={columnMasivo}
                        data={detalleMasivo ? detalleMasivo.detalle : []}
                        isLoading={loadingDetalleMasivo}
                    ></TableBase>
                </div>
            </Panel>
        </>
    )
}