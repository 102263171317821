
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HeadingBar } from "../../../components/Generic/HeadingBar"
import { _initialFilter } from "../../../utils/Helper/helper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import { _onRenderHeader, _onRenderRow } from "../../../utils/Helper/helper";
import { useBoolean } from "@fluentui/react-hooks";
import { PendienteAdicionalGerencia } from "./tab/pendienteAdicionalGerencia";
import { HistorialAdicionalGerencia } from "./tab/historiaAdicionalGerencia";
import { Pivot, PivotItem } from "@fluentui/react";

export const ListarAdicionalGerencia = () => {
    /* Tabs */
    const [tabIndex, setTabIndex] = useState('1');

    const childRef: any = useRef(null);
    
    const [search, setSearch] = useState("")

    /* Event Filter */
    const _onChangeText = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {

            setSearch(newValue)

            switch (tabIndex) {
                case '1':
                case '2':
                    if (newValue === '') {
                        if (childRef) {
                            childRef.current.filterFromParent(newValue);
                        }
                    }

                    break;
                default:
                    break;
            }
        }
    };

    const _onPress = (newValue: string) => {
        //loadBase(newValue as string);

        switch (tabIndex) {
            case '1':
            case '2':
                console.log(childRef);
                if (childRef) {
                    childRef.current.filterFromParent(newValue);
                }
                break;
            default:
                break;
        }
    };

    const _onClearSearch = () => {

        setSearch("")

        switch (tabIndex) {
            case '1':
            case '2':
                if (childRef) {
                    childRef.current.filterFromParent('');
                }
                break;
            default:
                break;
        }
    };

    const _onChangeTab = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (item != undefined) {
            let { itemKey } = item.props;
            if (itemKey != undefined) {
                setTabIndex(itemKey);

                console.log(itemKey)
            }
        }
    };

    return (
        <>

            <HeadingBar
                title={`Adicionales`}
                subTitle={`Nivel Gerencia`}
                subTitle2='Adicionales - Nivel Gerencia'
                searchPlaceholder="Buscar por Nombres o DNI"
                searchChange={_onChangeText}
                searchHandler={_onPress}
                searchValue={search ?? ''}
                onClearHandler={_onClearSearch}
            ></HeadingBar>
            <div style={{ padding: '20px', paddingTop: '10px' }}>
                <Pivot
                    aria-label="Pivot Overflow Menu Example"
                    // linkFormat={'tabs'}
                    overflowBehavior={'none'}
                    overflowAriaLabel="more items"
                    style={{ paddingTop: '10px' }}
                    onLinkClick={_onChangeTab}
                >
                    <PivotItem itemKey="1" headerText='Pendientes'>
                        <PendienteAdicionalGerencia ref={childRef} ></PendienteAdicionalGerencia>
                    </PivotItem>
                    <PivotItem itemKey="2" headerText='Historial'>
                        <HistorialAdicionalGerencia ref={childRef}></HistorialAdicionalGerencia>
                    </PivotItem>
                </Pivot>
            </div>
        </>
    )
}