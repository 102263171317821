import {
  Customizer,
  FocusTrapZone,
  FocusZone,
  ICommandBarItemProps,
  IFocusTrapZoneProps,
  ILayerProps,
  LayerHost,
  mergeStyles,
  Panel,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import { urlTelerik } from "../../services/http-common";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { useId, useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useRef } from "react";
import HeaderGeneric from "../../components/Generic/headerPanel";
import TokenService from "../../services/TokenService";

const NameReport = "MOVGraficosPersonas.trdx";
const parameters: object = {
  GerenciaId: "51F64566-1C4F-4DAD-8C8B-18DDF702B1B3",
  other: "",
};

const CuadroPersona = () => {
  let t = TokenService.getToken();
  const viewerRef = useRef(); //equivalnete id de componente

  const [isPanelOpen, { setTrue: showPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [trapPanel, { toggle: toggleTrapPanel }] = useBoolean(false);

  const _rigthBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_INFO,
        // onClick: ,
      },
    ];
    return _items;
  };

  useEffect(() => {
    EnviarToken();
  }, [t]);

  const EnviarToken = () => {
    var { viewerObject } =
      viewerRef.current != null ? viewerRef.current : viewerObject;
    viewerObject.authenticationToken(t);
    //console.log("-----REport TElerik 1------");
    //console.log(viewerObject);
  };

  return (
    <>
      <div>
        <div className="ms-Grid" dir="ltr">
          <br />
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <HeaderGeneric
                title="Cuadro Mando Personas"
                subTitle="Gestión de programaciones"
              />
            </div>
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              {/* <CustomCommandBar
                                farItem={_rigthBar()}
                                style={{ paddingRight: "0px", paddingLeft: "0px" }}
                            ></CustomCommandBar> */}
            </div>
          </div>
          <br />
        </div>

        <FocusTrapZone
          disabled={false}
          isClickableOutsideFocusTrap
          forceFocusInsideTrap={true}>
          <TelerikReportViewer
            ref={viewerRef}
            serviceUrl={urlTelerik}
            reportSource={{
              parameters: { editors: { multiSelect: parameters } },
              report: NameReport,
            }}
            viewerContainerStyle={ThemeBase.telerikStyle}
            viewMode="INTERACTIVE"
            scaleMode="FIT_PAGE_WIDTH"
            scale={1.0}
            enableAccessibility={false}
          />
        </FocusTrapZone>
      </div>
    </>
  );
};
export default CuadroPersona;
