import { IAddEmpresa, IHabilitarServicioEmpresa, IServicioEmpresa } from "../interfaces/IServicioEmpresa";
import { fetchMovilizacion } from "./http-common";

const urlServicioEmpresa = 'ServicioEmpresa';

const allEmpresaByUsuario = () => {
    return fetchMovilizacion.get<IServicioEmpresa>(urlServicioEmpresa);
}

const addEmpresaByUsuario = (data: IAddEmpresa) => {
    return fetchMovilizacion.post<IAddEmpresa>(urlServicioEmpresa, data);
}

const habilitarServicioEmpresa = (data: IHabilitarServicioEmpresa) => {
    return fetchMovilizacion.put<IHabilitarServicioEmpresa>(`${urlServicioEmpresa}/Habilitar`, data);
}

const eliminarServicioEmpresa = (id: string) => {
    return fetchMovilizacion.delete<string>(`${urlServicioEmpresa}/${id}`);
}

const ServicioEmpresaService = {
    allEmpresaByUsuario,
    addEmpresaByUsuario,
    habilitarServicioEmpresa,
    eliminarServicioEmpresa,
}

export default ServicioEmpresaService;