import { IActualizarTransporteGrupo } from "../interfaces/IAccesoTransporteGrupo";
import { fetchMovilizacion } from "./http-common";
let urlAccesoTransporteGrupo = 'AccesoTransporteGrupo';

const detalleByProgramacionBus = (programacionBusId: string) => {
    return fetchMovilizacion.get(`${urlAccesoTransporteGrupo}/DetalleByProgramacionBus/${programacionBusId}`);
}

const actualizarTransporteGrupo = (data: IActualizarTransporteGrupo) => {
    return fetchMovilizacion.put(`${urlAccesoTransporteGrupo}`, data);
}

export const AccesoGrupoTransporteService = {
    detalleByProgramacionBus,
    actualizarTransporteGrupo
}