
import { ActivityItem, ComboBox, FontIcon, FontSizes, IColumn, IComboBox, IComboBoxOption, IStackItemStyles, IStackStyles, IStackTokens, MessageBar, MessageBarButton, MessageBarType, NeutralColors, Panel, PanelType, PrimaryButton, ProgressIndicator, SpinButton, Stack, TextField } from "@fluentui/react"
import React, { useEffect, useState } from "react"
import { PanelPlantilla } from "../../../components/PanelUpload/PanelPlantilla"
import { TableBase } from "../../../components/Table/TableBase"
import { IException, IItemComboBaseDTO } from "../../../interfaces/base/IBase"
import { IEmpresa } from "../../../interfaces/IEmpresa"
import { Ifilter } from "../../../interfaces/IFilter"
import { IModeloCupos, IPostModeloCupos } from "../../../interfaces/ITransporte"
import EmpresaService from "../../../services/empresaService"
import PersonaService from "../../../services/PersonaService"
import { splitExceptionDetail, _initialFilter } from "../../../utils/Helper/helper"
import ThemeBase from "../../../utils/Theme/themeBase"

//_________________________________________________
const stackStyles: IStackStyles = {
    root: {
        background: "#ffffff",
        paddingTop: 30
    }
};

const stackItemStyles: IStackItemStyles = {
    root: {
        background: "#ffff",
        // color: "#217346",
        padding: 0,
    },
};

const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    // padding: 1
};
//_________________________________________________

const PanelCupos = (props) => {


    let total = props._inscritos.split("/")
    let inscritos = total[0]
    let totalCupos = total[1]

    let noInscritos = parseInt(totalCupos) - parseInt(inscritos)

    const [modelCupos, setModelCupos] = useState<IModeloCupos>(null!!)

    const data: IModeloCupos[] = [];
    const [tablaCupos, setTablaCupos] = useState(data)
    const [tCupos, setTCupos] = useState()

    const [empresaPersonaCombo, setEmpresaPersonaCombo] = useState<IItemComboBaseDTO[]>([]);
    const empresaPersonaRef = React.useRef<IComboBox>(null);
    const _openOptionPersona = React.useCallback(() => empresaPersonaRef.current?.focus(true), []);
    const [filterEmpresaPersona, setFilterEmpresaPersona] = useState<Ifilter>({
        ..._initialFilter()
    });

    //mensajes de estado
    const [verLoading, setVerLoading] = useState(false);
    const [mensajeTransaccion, setMensajeTransaccion] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState(0);
    const [verLoadingBoton, setVerLoadingBoton] = useState(false);

    useEffect(() => {
        setModelCupos({
            ...modelCupos,      
        })

    }, [props.programacionId])

    useEffect(() => {
        dataEmprea()
    }, [])

    useEffect(() => {
        if(props.isOpen)
        {
            dataCupoEmpresa();
        }
    }, [props.isOpen])

    useEffect(() => {
        if(!props.isOpen)
        {
            setTablaCupos([]);
            setMensajeTransaccion("");
            setTipoMensaje(0);
        }
    },[props.isOpen])

    //API
    function dataEmprea() {

        EmpresaService.all(filterEmpresaPersona)
            .then((res) => {
                if (res.status == 200) {

                    let d: IEmpresa[] = res.data.items;
                    let t: IItemComboBaseDTO[] = d.map((x) => {
                        return {
                            key: x.empresaId,
                            text: x.nombre as string,
                        };
                    });
                    setEmpresaPersonaCombo(t);


                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });

    }

    function dataCupoEmpresa() {
        PersonaService.getCupoEmpresa(props.programacionId)
                .then((res) => {
                    if(res.status == 200)
                    {
                        console.log(res.data);
                        setTablaCupos(res.data);
                    }
                })
                .catch((e: IException) => {
                    console.log(splitExceptionDetail(e))
                }) 
    }

    const _asignarCupos = () => {

        let postCupos: IPostModeloCupos = {
            listaCupos: tablaCupos, 
            programacionId: props.programacionId,
        }

        console.log("postcupos modelo",postCupos)

        PersonaService.postCupoEmpresa(postCupos)
                .then((res) => {
                    if (res.status == 200) {
                        setTipoMensaje(2);
                        setMensajeTransaccion("Se ha registrado correctamente");
                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));
                });
        /* setModelCupos({
            ...modelCupos,
            programacionId:id
        }) */
    }

    const _column: IColumn[] = [
        {
            key: "column1",
            name: "Empresa",
            fieldName: "empresa",
            minWidth: 400,
            maxWidth: 400,
        },
        {
            key: "column2",
            name: "Nº Cupos",
            fieldName: "cupo",
            minWidth: 200,
            maxWidth: 200,
        }
    ]


    const _onChangeText = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let nameInput = event.currentTarget.getAttribute("name");

                switch (nameInput) {
                    case "cupos":
                        setModelCupos({
                            ...modelCupos,
                            cupo: parseInt(newValue),
                        });

                        break;

                    default:
                        break;
                }
            }
        }
    };

    const _onChangeSpinButton = (
        evento: React.SyntheticEvent<HTMLElement>,
        opcion?: string
      ) => {
        if(evento !== undefined) {
          if(opcion !== undefined) {
            setModelCupos({
                ...modelCupos,
                cupo: parseInt(opcion),
            });

          }
        }
      }
    

    const _onChangeEmpresa = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {

                switch (type) {
                    case 'empresa':
                        setModelCupos({
                            ...modelCupos,
                            empresaId: option.key.toString(),
                            empresa:option.text
                        })

                        break;

                    default:
                        break;
                }
            }
        }
    };

    const addCuposTemporal = () => {

        const remainingQuota = totalCupos - tablaCupos.reduce((acc, curr) => acc + curr.cupo, 0);
        const inscritosA = tablaCupos.reduce((acc, curr) => acc + curr.cupo, 0);

        console.log("totalCupos:", totalCupos, "inscritos:", inscritosA, "remaining quota", remainingQuota);

        if(modelCupos.cupo > remainingQuota)
        {
            setTipoMensaje(1);
            setMensajeTransaccion("Se ha superado el límite de cupos disponibles")
            return;
        }

        setTablaCupos(prevTablaCupos => [...prevTablaCupos, modelCupos]);
        setModelCupos({
            empresaId: "",
            cupo: 0,
        });
        console.log("se agrego a la lista el modelo de cupos", modelCupos);

    }
    const _goBack = () => {
        setTablaCupos([]);
        setMensajeTransaccion("");
        setTipoMensaje(0);
        props.dismiss();
    }
    return (
        <>
            <PanelPlantilla
                textHeader='Asignar Cupos'
                subTextHeader=' Asigne cupos a las empresas correspondientes'
                textBoton='Asignar'
                onclickBoton={_asignarCupos}
                openPanel={props.isOpen}
                closePanel={props.dismiss}
                size={PanelType.medium}
                disabledBoton={false}
                estadoBarraEnvio={false}
            >
                <>
                    {tipoMensaje == 1 && (
                        <>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                //onDismiss={p.resetChoice}
                                dismissButtonAriaLabel="Close"
                            //truncated={true}
                            //overflowButtonAriaLabel="See more"
                            >
                                <b>{mensajeTransaccion}</b>{" "}
                            </MessageBar>
                            <br></br>
                        </>
                    )}
                    {tipoMensaje == 2 && (
                        <>
                            <MessageBar
                                actions={
                                    <div>
                                        <MessageBarButton onClick={() => {_goBack()}}>
                                            Conforme
                                        </MessageBarButton>
                                    </div>
                                }
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                            >
                                {mensajeTransaccion}
                            </MessageBar>
                            <br></br>
                        </>
                    )}
                    {!verLoading && (
                        <form>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">

                                        {/* <pre>{JSON.stringify(modelCupos, null, 2)}</pre> */}

                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                        <TextField
                                            label="Total de cupos: "
                                            value={totalCupos}
                                            disabled
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">

                                        <TextField
                                            label="Cupos disponibles:"
                                            value={noInscritos.toString()}
                                            disabled

                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                        <TextField
                                            label="Cupos ocupados: "
                                            value={inscritos}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Empresa:"
                                            id="empresa"
                                            autoComplete="off"
                                            options={empresaPersonaCombo}
                                            //componentRef={empresaTransporteRef}
                                            //selectedKey={modelItem?.empresaId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeEmpresa("empresa", e, o)}
                                        //onKeyUp={(e) => _onUpCombo("empresa", e)}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className="ms-Grid-row">
                                    {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Nº Cupos: "
                                            value={modelCupos?.cupo?.toString()}
                                            type="number"
                                            name="cupos"
                                            onChange={(e, o) => _onChangeText(e, o)}

                                        />
                                    </div> */}
                                    
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                            <label style={{fontWeight: 600, marginBottom: "20px"}}>N° Cupos</label>
                                            <SpinButton
                                                defaultValue="0"
                                                min={0}
                                                max={1200}
                                                step={1}
                                                value={modelCupos?.cupo?.toString()}
                                                incrementButtonAriaLabel="Incrementa el valor en 1"
                                                decrementButtonAriaLabel="Decrementa el valor en 1"
                                                //styles={styles}
                                                onChange={(e, o) => _onChangeSpinButton(e, o)}
                                            />
                                        </div>
                                    
                                    
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
                                            <Stack.Item align="end" styles={stackItemStyles}>
                                                <PrimaryButton text="Agregar" onClick={() => addCuposTemporal()} allowDisabledFocus />
                                            </Stack.Item>
                                        </Stack>
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <br />
                                        <TableBase column={_column} data={tablaCupos} isLoading={false}></TableBase>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                    {verLoading && (
                        <>
                            <br></br>
                            <ProgressIndicator
                                barHeight={5}
                                label="Enviando información"
                                description="Favor Espere un momento"
                            />
                        </>
                    )}
                </>

            </PanelPlantilla>
            {/* <Panel
                isOpen={props.isOpen}
                onDismiss={props.dismiss}
                onRenderHeader={onRenderHeader}
                type={PanelType.medium}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
                isLightDismiss
            >
               

            </Panel> */}


        </>
    )

}

export { PanelCupos }