import { Panel, PanelType, TextField } from "@fluentui/react"
import { UseForm } from "../../../hooks/useForm"
import { ICreateHotel } from "../../../interfaces/IHotel"

export const PanelCrearHotel = ({ isOpen = false, open = () => { }, dissmiss = () => { }, type = PanelType.medium }) => {
    const {form, _handleChange } = UseForm<ICreateHotel>({
        nombre: '',
        codigo: '',
        direccion: '',
        ubicacionId: '',
    });

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dissmiss}
                headerText='Nuevo Hotel'
                closeButtonAriaLabel="Close"
                type={type}
                isFooterAtBottom={true}
            >
                <>
                <pre>{JSON.stringify(form, null, 2)}</pre>

                
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                {/* <TextField
                                    label='Nombre Usuario'
                                    placeholder='Nombre Usuario'
                                    value={formRegistrar ? formRegistrar.nombres : ''}
                                    onChange={(e, o) => _onChangeText('nombreUsuario', e, o)}
                                    autoComplete="off" /> */}
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">

                            </div>
                        </div>
                    </div>
                </>
            </Panel>
        </>
    )
}