import { MessageBar, MessageBarType } from "@fluentui/react";
import { MessageHelper } from "../../utils/Helper/messageHelper";

const TYPE_SUCCESS = 1;
const TYPE_ERROR = 2;

export const typeMessage = {
    TYPE_SUCCESS,
    TYPE_ERROR,
}

const ErrorExample = ({ message = '', dissmiss = () => { } }) => {
    return (
        <MessageBar
            messageBarType={MessageBarType.blocked}
            isMultiline={false}
            onDismiss={dissmiss}
            dismissButtonAriaLabel='Close'
            truncated={true}
            overflowButtonAriaLabel='See more'
        >
            {message}
        </MessageBar>
    );
};
const SuccessExample = ({ message = '', dissmiss = () => { } }) => {
    return (
        <>
            <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={false}
                onDismiss={dissmiss}
            >
                {message === '' ? MessageHelper._getMessageText(typeMessage.TYPE_SUCCESS) : message}
            </MessageBar>
        </>
    )
};

export const CustomMessageBar = ({ type = typeMessage.TYPE_SUCCESS, message = '', dissmiss = () => { } }) => {
    switch (type) {
        case typeMessage.TYPE_SUCCESS:
            return <SuccessExample message={message} dissmiss={dissmiss}></SuccessExample>
        case typeMessage.TYPE_ERROR:
            return <ErrorExample message={message} dissmiss={dissmiss}></ErrorExample>
        default:
            return <></>;
    }
}