import { useContext } from "react";
import { AnimationClassNames, mergeStyles, getTheme } from "@fluentui/style-utilities";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { IIconProps } from "@fluentui/react";
import { IButtonStyles, PrimaryButton } from "@fluentui/react/lib/Button";
import { PersonaSize, PersonaPresence, IPersonaSharedProps, Persona } from "@fluentui/react/lib/Persona";
import { AuthContext } from "../../auth/context/auth-context";
import { useNavigate } from "react-router-dom";
import { baseImageUrl } from "../../utils/Helper/helper";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import Logout from "../Logout/logout";

initializeIcons();

const emojiIcon: IIconProps = { iconName: "WaffleOffice365" };
const lockIcon: IIconProps = { iconName: "Lock" };
const keyIcon: IIconProps = { iconName: 'Permissions' };
const LightbulbIcon: IIconProps = { iconName: "Lightbulb" };
const notificationIcon: IIconProps = { iconName: "Ringer" };
const SettingsIcon: IIconProps = { iconName: "Settings" };

const Navbar = () => {
  const { signout, access } = useContext(AuthContext);
  const navigate = useNavigate();

  const _handleSignout = () => {
    signout(() => navigate(ConfigHelper.urlLogin()))
  }

  const _handleChangePassword = () => {
    navigate(`${ConfigHelper.urlBase()}/account`);
  }

  return (
    <div>
      <div className={navHeader}>
        <div className={navHeaderLeft}>
          <PrimaryButton iconProps={emojiIcon} className={btnClass} />
        </div>
        <div className={navHeaderCenter}>Teseo</div>
        <div className={navHeaderRight}>
          {/* <PrimaryButton iconProps={keyIcon} ariaLabel='Permissions' title='Permissions' className={btnClass} onClick={_handleChangePassword} /> */}
          {/* <PrimaryButton iconProps={lockIcon} ariaLabel='Signout' title='Signout' className={btnClass} onClick={_handleSignout} /> */}
          <PrimaryButton iconProps={notificationIcon} className={btnClass} />
          <PrimaryButton iconProps={SettingsIcon} className={btnClass} />
          <PrimaryButton iconProps={LightbulbIcon} className={btnClass} />
       
          <Logout
            userName={access?.email ? access.email! : ""}
            email={access ? access.email : ""}
            handleSignout={_handleSignout}
          />
        </div>
      </div>
    </div>
  );
};

const theme = getTheme();

const navHeader = mergeStyles([
  {
    height: "48px",
    lineHeight: "48px",
    backgroundColor: theme.palette.themePrimary,
    color: theme.palette.white,
    width: "100%",
    display: "flex",
  },
  AnimationClassNames.scaleUpIn100,
]);

const navHeaderLeft = mergeStyles([
  {
    padding: "0px",
    margin: "0px",
  },
  AnimationClassNames.scaleUpIn100,
]);

const navHeaderCenter = mergeStyles([
  {
    padding: "0px",
    margin: "0px",
    flex: "1 0 auto",
  },
]);

const navHeaderRight = mergeStyles([
  {
    padding: "0px",
    margin: "0px",
    flex: "0 0 auto",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
  },
]);

const btnClass = mergeStyles([
  {
    height: "48px",
    width: "48px",
    minWidth: "48px",
  },
]);

export default Navbar;
