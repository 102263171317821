import { IColumn, ICommandBarItemProps, Stack, TooltipHost, Icon, SharedColors, classNamesFunction, mergeStyleSets } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../auth/context/auth-context";
import { IUserAuthenticate } from "../../auth/interface/IAuth";
import { DialogBaseAlert } from "../../components/Dialog/DialogBaseAlert";
import { DialogBaseError } from "../../components/Dialog/DialogBaseError";
import { DialogBaseSuccess } from "../../components/Dialog/DialogBaseSuccess";
import { DialogBaseWait } from "../../components/Dialog/DialogBaseWait";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import RepoteBase from "../../components/Generic/ReporteBase";
import { State } from "../../components/Generic/state";
import { TableBase } from "../../components/Table/TableBase";
import { IReportTelerik } from "../../interfaces/base/IBase";
import { IPagination } from "../../interfaces/base/ITable";
import { Ifilter } from "../../interfaces/IFilter";
import { ITablaMarca, ITablaTransporte, ITransporte, IUpdateMarca } from "../../interfaces/ITransporte";
import AuthService from "../../services/AuthService";
import PersonaService from "../../services/PersonaService";
import TransporteService from "../../services/transporteService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { _initialFilter, stackTokensHorizontal, baseSearch, _initialPagination } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { isRoleAccessAll, roleAdmin, ROLE_TRANSPORTE, CLAIM_EMPRESA_USUARIO_ID } from "../../utils/Helper/roleHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { NewVehiculo } from "./NewVehiculo";
import { PanelActualizarTransporte } from "./PanelActualizarTransporte";


const classNames = mergeStyleSets({

    iconFont: {
        padding: 0,
        fontSize: "14px",
        marginRight: 5,
        marginLeft: 2,
    },
});



const reportByRole = (access: IUserAuthenticate | null) => {
    let role = access?.role ?? [];

    let reportName = '';
    let param = {};

    let isRolAdmin = isRoleAccessAll(role, roleAdmin());

    if (isRolAdmin) {
        reportName = 'MOVReporteVehiculos.trdx';
    } else {
        let isRolTransporte = isRoleAccessAll(role, [ROLE_TRANSPORTE]);

        if (isRolTransporte) {
            reportName = 'MOVVehiculosTransportista.trdx';
            param = {
                TransporteId: AuthService.getInfoToken(CLAIM_EMPRESA_USUARIO_ID),
            }
        }
    }

    let report: IReportTelerik = {
        reportName: reportName,
        param: param
    }

    return report;
}

export const TablaVehiculos = () => {
    let navigate = useNavigate();

    /*ACCCESO Y ROLE */
 
     const { access } = useContext(AuthContext);
    let allRole = access?.role ?? [];
    let isRoleEliminarTransporteAdmin = isRoleAccessAll(allRole, roleAdmin());
    let isRoleActualizarTransporteAdmin = isRoleAccessAll(allRole, roleAdmin())

   // console.log("----------------------------------------------------------------")
   // console.log(allRole)

    const [hidden, setHidden] = useState(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [transporte, setTransporte] = useState<ITransporte[]>([]);
    const [filter, setFilter] = useState<Ifilter>(_initialFilter);
    const [searchParam, setSearchParam] = useSearchParams();


    //Estados 

    const data: ITablaMarca[] = [];
    const [mensajeTransaccion, setMensajeTransaccion] = useState("");
    const [modelItem, setModelItem] = useState<ITablaTransporte>(null!)
    const [items, setItems] = useState(data);
    /* Panel Telerik */
    const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);

    /* Access & Role */
    let reportTelerik = reportByRole(access as IUserAuthenticate);

    /* Panel Nuevo Vehiculo */
    const [isOpenNuevo, { setTrue: openNuevo, setFalse: dismissNuevo }] = useBoolean(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });




    //dialog
    const [hideAlertEliminar, { toggle: toggleHideAlertEliminar }] = useBoolean(true);
    const [hideWaitEliminar, { toggle: toggleHideWaitEliminar }] = useBoolean(true);
    const [hideSuccessEliminar, { toggle: toggleHideSuccessEliminar }] = useBoolean(true);
    const [hideErrorEliminar, { toggle: toggleHideErrorEliminar }] = useBoolean(true);


    //panel
    const [isOpenTransporteEditar, { setTrue: openTransporteEditar, setFalse: dismissTransporteEditar },] = useBoolean(false);


    const [transporteId, setTransporteId] = useState("");
    const [marcaVehiculoId, SetmarcaVehiculoId] = useState("");
    
    const [tipoTransporte, setTipoTransporte] = useState("");


    /* Hook */
    useEffect(() => {
        reloadInitial();
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    const dataTablaMarca = (search) => {

        setHidden(false);
        TransporteService.tablaMarca(search).then(res => {
            setItems(res.data.items);
            setTotal(res.data.total);
            setHidden(true);
        }).catch((err) => {
            alert(err.message);
            // setHidden(true);
        })

    }
    const EliminarMarca = (transporteId: string) => {

        toggleHideWaitEliminar()
        TransporteService.deleteVehiculo(transporteId)
            .then((res) => {
                if (res.status == 200) {
                    // alert("Se registró correctamente!!");
                    reloadInitial()
                    toggleHideWaitEliminar()
                    toggleHideSuccessEliminar()
                    //dataTablaMarca(search);
                }

            }).catch(function (error) {
                if (error.response) {
                    toggleHideWaitEliminar()
                    setMensajeTransaccion(error.response.data.detail)
                    toggleHideErrorEliminar()
                }
            });
        console.log(transporteId)
    }

    /* Api */
    const dataVehiculo = (param = filter) => {
        PersonaService.getTransporte(param).then(res => {
            setTransporte(res.data.data);
            setTotal(res.data.total);
            setHidden(true);
        }).catch((err) => {
            alert(err.message);
            setHidden(true);
        })
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setTitleColumn();
        dataVehiculo(param);
    }

    const editVehiculo = (id: string) => {
        navigate(`${ConfigHelper.urlBase()}/vehiculos/${id}`);
    }

    const _leftItemBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },
            {
                ...IconHelper.ICON_ADD,
                onClick: openNuevo,
            },
        ];

        return _items;
    }

    const _rightItemBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: openTelerik,
            },
        ];

        return _items;
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Código Interno',
                fieldName: 'codigoInterno',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Placa',
                fieldName: 'placa',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                data: 'number',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Tipo Vehículo',
                fieldName: 'tipoTransporte',
                minWidth: 120,
                maxWidth: 120,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Categoria',
                fieldName: 'categoria',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Marca',
                fieldName: 'marca',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Modelo',
                fieldName: 'modelo',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Pasajeros',
                fieldName: 'pasajeros',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },

            {
                key: 'column8',
                name: 'Año fabricacion',
                fieldName: 'anioFabricacion',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column10',
                name: 'Observación',
                fieldName: 'observacion',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column11',
                name: 'Empresa',
                fieldName: 'empresa',
                minWidth: 280,
                maxWidth: 290,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column9',
                name: 'Estado',
                fieldName: 'estado',
                minWidth: 160,
                maxWidth: 160,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
                onRender: (item: ITransporte) => (
                    <>
                        <State estado={item.estado} colorEstado={item.colorEstado}></State>
                    </>
                )
            },
            {
                key: "column12",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: ITransporte) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>

                            <TooltipHost content={`Validar información`}>
                            {isRoleActualizarTransporteAdmin &&
                                <TooltipHost content={`Editar`}>
                                    <Icon
                                        iconName={IconHelper.ICON_EDIT.iconProps?.iconName}
                                        style={{ cursor: 'pointer' }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            setTransporteId(item.transporteId)
                                            setTipoTransporte(item.tipoTransporte)
                                            openTransporteEditar()
                           

                                        }}
                                    />
                                </TooltipHost>
                            }   
                                {isRoleEliminarTransporteAdmin &&        
                                <TooltipHost content="Eliminar">
                                    <Icon
                                        style={{ color: SharedColors.red10, cursor: "pointer" }}
                                        iconName={IconHelper.ICON_DELETE.iconProps?.iconName}
                                        className={classNames.iconFont}
                                        onClick={() => {
                                            setModelItem({
                                                ...modelItem,
                                                transporteId: item.transporteId,
                                                nombre: item.empresa,
                                                marca: item.marca
                                            })
                                            toggleHideAlertEliminar();
                                            
                                        }}
                                    />

                                </TooltipHost>
                                 }



                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ]
        setColumn(col);
    }

    /* Event Pagination */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: Ifilter = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    /* Event Filter */
    const _onChangeTextFilter = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {
            if (newValue === '') {
                _onClearFilter();
            } else {
                setFilter({
                    ...filter,
                    search: newValue,
                })
            }
        }
    };

    const _onPressFilter = (newValue: string) => {
        let f: Ifilter = {
            ..._initialFilter(),
            search: newValue,
        };

        setFilter(f);
        setCurrentPage(_initialPagination().currentPage);
        reloadInitial(f);
    };

    const _onClearFilter = () => {
        let f: Ifilter = {
            ..._initialFilter()
        }

        setFilter(f);
        reloadInitial(f);
    }

    return (
        <>
            <HeadingBar
                title='Vehículos'
                subTitle='Gestión de unidades'
                searchChange={_onChangeTextFilter}
                searchHandler={_onPressFilter}
                searchValue={filter.search ?? ''}
                onClearHandler={_onClearFilter}
            ></HeadingBar>
            

            <TableBase leftBar={_leftItemBar()} rigthBar={_rightItemBar()} column={column} data={transporte} pagination={pager} isLoading={!hidden}></TableBase>


            {/* Panel - Reporte - Telerik */}
            <RepoteBase
                headerName='Transporte'
                NameReport={reportTelerik.reportName}
                Parameters={reportTelerik.param}
                openTelerik={isOpenTelerik}
                dismissTelerik={dismissTelerik}
            ></RepoteBase>

            {/* Panel Nuevo Vehiculo*/}
            <NewVehiculo isOpen={isOpenNuevo} dismiss={dismissNuevo} registar={reloadInitial}></NewVehiculo>
{/*             <PanelActualizarTransporte isOpenPanel={isOpenTransporteEditar} dismissPanel={dismissTransporteEditar} reloadParent={reloadInitial}> </PanelActualizarTransporte>
 */}
            <PanelActualizarTransporte transporteId={transporteId} isOpenPanel={isOpenTransporteEditar} dismissPanel={dismissTransporteEditar} reloadParent={reloadInitial} ></PanelActualizarTransporte>  
            {/* Dialog Eliminar*/}
            
            <div id="dialogAlertElimnar">
                <DialogBaseAlert
                    hideDialog={hideAlertEliminar}
                    toggleHideDialog={toggleHideAlertEliminar}
                    title="¡Atención!"
                    subText={`¿Está seguro que desea eliminar a ${modelItem?.nombre}?`}
                    onclickButtonPrimary={() => EliminarMarca(modelItem?.transporteId)}
                    

                
                />
            </div>
            <div id="dialogWaitElimnar">
                <DialogBaseWait
                    hideDialog={hideWaitEliminar}
                    toggleHideDialog={toggleHideWaitEliminar}
                />
            </div>
            <div id="dialogSuccessElimnar">
                <DialogBaseSuccess

                    hideDialog={hideSuccessEliminar}
                    toggleHideDialog={toggleHideSuccessEliminar}
                    subText={`Se ha eliminado la marca de vehículo ${modelItem?.nombre}?`}
                />
            </div>
            <div id="dialogErrorEliminar">
                <DialogBaseError
                    hideDialog={hideErrorEliminar}
                    toggleHideDialog={toggleHideErrorEliminar}
                    subText={mensajeTransaccion}
                />
            </div>
            
        </>
    )


}