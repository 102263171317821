import { fetchMovilizacion } from "./http-common";

let urlAccion = 'AccesoProgramadoAccion';

const allByCod = (proceso: string) => {
    return fetchMovilizacion.get(`${urlAccion}/${proceso}`);
}

const AccionService = {
    allByCod,
}

export default AccionService;