import { ComboBox, FontIcon, getTheme, IComboBox, IComboBoxOption, ICommandBarItemProps, IStackProps, Label, Stack } from "@fluentui/react";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { HeadingBar } from "../../components/Generic/HeadingBar"
import { IException, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import { IDataCollection } from "../../interfaces/base/ITable";
import { IEmpresa } from "../../interfaces/IEmpresa";
import { Ifilter } from "../../interfaces/IFilter";
import { IAddEmpresa } from "../../interfaces/IServicioEmpresa";
import EmpresaService from "../../services/empresaService";
import ServicioEmpresaService from "../../services/servicioEmpresaService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { splitExceptionDetail, stackTokensSpace, _initialFilter } from "../../utils/Helper/helper";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import ThemeBase from "../../utils/Theme/themeBase";

const theme = getTheme();

export const RegistrarEmpresa = ({ dismiss = () => { }, registar = () => { } }) => {
    // const navigate = useNavigate();
    const { id } = useParams();
    const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
    const [data, setData] = useState<IDataCollection<IEmpresa>>(_initialDataCollection);
    const [model, setModel] = useState<IAddEmpresa>(null!);
    const [filter, setFitler] = useState<Ifilter>(_initialFilter);
    /**Habilitar registro*/
    const [disabledRegister, setdisabledRegister] = useState(true);

    const empresaRef = React.useRef<IComboBox>(null);
    const _openOptionEmpresa = React.useCallback(() => empresaRef.current?.focus(true), []);


    /* effect */
    useEffect(() => {
        dataEmpresa();
    }, []);

    useEffect(() => {
        if (id != undefined) {
            setModel(
                {
                    ...model,
                    empresaId: id
                }
            )
        }
    }, [id]);


    useEffect(() => {
        if (filter) {
            dataEmpresa();
        }
    }, [filter]);

    useEffect(() => {
        (model?.empresaContratistaId == undefined || model?.empresaContratistaId == "")
            ? setdisabledRegister(true)
            : setdisabledRegister(false)

    }, [model]);

    /* api */
    const dataEmpresa = () => {
        EmpresaService.all(filter)
            .then((res) => {
                if (res.status == 200) {
                    let t: IEmpresa[] = res.data.items;

                    let e: IItemComboBaseDTO[] = t.map(x => {
                        return ({
                            key: x.empresaId,
                            text: x.nombre as string,
                        })
                    })
                    setEmpresa(e);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    }

    const _addEmpresa = () => {
        ServicioEmpresaService.addEmpresaByUsuario(model)
            .then((response: any) => {
                if (response.status == 200) {
                    alert('Se registró correctamente');
                    dismiss();
                    registar();
                    // goBack();
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    }

    // const _generateItemBar = () => {
    //     const _items: ICommandBarItemProps[] = [
    //         {
    //             key: 'back',
    //             text: 'Atrás',
    //             iconProps: { iconName: 'Back' },
    //             onClick: goBack,
    //         },
    //     ];

    //     return _items;
    // }

    // /* Function */
    // const goBack = () => {
    // navigate(`${ConfigHelper.urlBase()}/empresas`);
    // }

    const _OnChangeComboEmpresa = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                console.log(option.text)
                setModel({
                    ...model,
                    empresaContratistaId: String(option.key)
                })
            }
        }
    }

    const _onInputEmpresa = (e: React.FormEvent<IComboBox>) => {
        _openOptionEmpresa()
        let { value } = e?.target as HTMLInputElement;
        setModel({
            ...model,
            empresaContratistaId: ''
        })
        setFitler({
            ...filter,
            search: value,
        })
    }

    const _onBlurEmpresa = () => {
        setFitler({ ...filter, search: '' })
    }


    return (
        <>
            {/* <HeadingBar itemBar={_generateItemBar()} searchShow={false}></HeadingBar> */}

            <div style={{ margin: '1em', padding: '1em' }}>
                <br />
                <Stack tokens={stackTokensSpace.size25} >
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                            <ComboBox
                                componentRef={empresaRef}
                                label="Empresa Contratista"
                                options={empresa}
                                onChange={_OnChangeComboEmpresa}
                                allowFreeform
                                useComboBoxAsMenuWidth
                                calloutProps={{ directionalHintFixed: true }}
                                onInput={_onInputEmpresa}
                                autoComplete='off'
                                selectedKey={model ? model.empresaContratistaId : ''}
                                onBlur={_onBlurEmpresa}
                                onClick={() => _openOptionEmpresa()}
                                styles={ThemeBase.comboBoxStyles}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                            <ButtonForm back={dismiss} register={_addEmpresa} disabledRegister={disabledRegister}></ButtonForm>
                        </div>
                    </div>
                </Stack>
            </div>
        </>
    )
}