import { DefaultButton, DefaultEffects, Label, Spinner } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import { dispositivoUrl } from "../../services/http-common";
interface IInfoWebDispositivo {
  DispositivoId: string;
  back: string;
}

const axios = require("axios");

export function EscanerQr() {
  let { infoWeb } = useParams();
  const [infoDispositivo, setInfoDispositivo] = useState<IInfoWebDispositivo>({
    back: "",
    DispositivoId: "",
  });

  function convertJSONString(data: any) {
    let jsonString = JSON.stringify(data);
    return jsonString;
  }

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${dispositivoUrl}/Dispositivo/ListarDispositivoInfoWeb?InfoWeb=${infoWeb}`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        const { backService, dispositivoId } = response.data;
        setInfoDispositivo({back:backService,DispositivoId:dispositivoId});
      })
      .catch((error) => {
        alert("RQ no valido");
      });
  }, []);

  return (
    <>
      <div
        key={"header"}
        style={{
          display: "flex",
          flexDirection: "column",
          // margin:'0px 20px',
          alignItems: "left",
          justifyContent: "left",
          width: "100vw",
          height: "100vh",
          background: "#F5F2F2",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "50px",
            paddingLeft: "20px",
            backgroundColor: "#E5E7E9",
          }}
        >
          <Label
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: "96%",
              fontSize: "25px",
            }}
          >
            IDS Latam SAC
          </Label>
        </div>

        <div
          key={"body"}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <div>
            <Label style={{ textAlign: "center", fontSize: "35px" }}>
              Mantén tus datos seguros
            </Label>
          </div>
          {/*  <pre>{JSON.stringify(state, null,2)}</pre> */}
          <div
            key={"Panel"}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
              backgroundColor: "white",
              boxShadow: DefaultEffects.elevation8,
              height: "70%",
            }}
          >
            <Label
              key={"titulo"}
              style={{
                width: "100%",
                textAlign: "left",
                fontSize: "30px",
                paddingLeft: "20px",
              }}
            >
              Teseo (SaaS) Autenticador para dispositivos móviles
            </Label>
            <div
              key={"panelBody"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                margin: "40px",
                height: "100%",
              }}
            >
              <div></div>
              <Label
                style={{ width: "100%", textAlign: "left", fontSize: "20px" }}
              >
                Escanea el Código QR
              </Label>
              <Label>
                Usa Teseo (SaaS) app para escanear el código QR, esto verificará
                el dispositivo y asociará a tu cuenta de acceso con la
                plataforma Teseo (SaaS).
              </Label>
              <Label>
                Después de escanear el código QR, usted podrá iniciar sesión en
                su dispositivo móvil iOS o Android.
              </Label>
              {/* Tu contenido adicional aquí */}
              <div
                key={"codeQR"}
                style={{ height: "100%", width: "300px", paddingTop: "30px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    width: "300px",
                  }}
                >
                  {infoDispositivo ? (
                    <QRCode
                      size={200}
                      value={convertJSONString(infoDispositivo)}
                    />
                  ) : (
                    <div>
                      <Spinner
                        label="Generando Código QR"
                        ariaLive="assertive"
                        labelPosition="top"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "flex-end",
                margin: "20px",
                width: "90%",
              }}
            >
              {/* Ir a APPStore ir a PlaceStore */}
              <div style={{ paddingRight: "20px" }}>
                <DefaultButton
                  text="Ir a App Store"
                  href="https://apps.apple.com/pe/app/teseo-saas/id1560227787"
                />
              </div>
              <DefaultButton
                text="Ir a Play Store"
                href="https://play.google.com/store/apps/details?id=com.idslatam.teseo"
              />
              {/*  <PrimaryButton text="Ir a Place Store" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
