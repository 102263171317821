import { Checkbox, ComboBox, DatePicker, IComboBox, IComboBoxOption, ICommandBarItemProps, Label, mergeStyles, Separator, Stack, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { CustomCommandBar } from "../../components/Generic/customCommandBar"
import TitleSeparator from "../../components/Generic/TitleSeparator";
import { IException, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import { IFilterAdmContrato } from "../../interfaces/filter/IFilterContrato";
import { ICreateContrato } from "../../interfaces/IContrato";
import ConfiguracionService from "../../services/configuracionService";
import ContratoService from "../../services/contratoService";
import EmpresaService from "../../services/empresaService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { _formatDate, _formatDatePrefix } from "../../utils/Helper/datetimeHelper";
import { splitExceptionDetail, stackTokens, _initialFilter } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";

export const RegistrarContrato = () => {
    const navigate = useNavigate();

    const [centroCosto, setCentroCosto] = useState<IItemComboBaseDTO[]>([]);
    const [adm, setAdm] = useState<IItemComboBaseDTO[]>([]);
    const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
    const [tipoContrato, setTipoContrato] = useState<IItemComboBaseDTO[]>([]);
    const [inicio, setInicio] = useState<Date | undefined>(undefined);
    const [vencimiento, setVencimiento] = useState<Date | undefined>(undefined);
    const [area, setArea] = useState<IItemComboBaseDTO[]>([]);
    const [form, setForm] = useState<ICreateContrato>(null!);
    const [isComplete, setIsComplete] = useState(false);

    /* Filter */
    const [filterAdm, setFilterAdm] = useState<IFilterAdmContrato>({
        ..._initialFilter(),
        codigo: '06'
    });

    /* Hook */
    useEffect(() => {
        dataCentroCosto();
        dataAdm();
        dataEmpresa();
        dataTipoContrato();
        dataArea();
    }, []);

    useEffect(() => {
        if (inicio != undefined) {
            setForm({
                ...form,
                fechaInicio: _formatDate(inicio, ',')
            });
        }
    }, [inicio]);

    useEffect(() => {
        if (vencimiento != undefined) {
            setForm({
                ...form,
                fechaVencimiento: _formatDate(vencimiento, ',')
            });
        }
    }, [vencimiento])

    useEffect(() => {
        setIsComplete(_validateForm);
    }, [form]);

    /* Api */
    const dataCentroCosto = () => {
        ContratoService.allCentroCosto().then(res => {
            setCentroCosto(res.data);
        }
        ).catch((e: Error) => {
            alert(splitExceptionDetail(e));
        })
    }

    const dataAdm = () => {
        ContratoService.allAdmContrato(filterAdm).then(res => {
            setAdm(res.data);
        }
        ).catch((e: Error) => {;
            alert(splitExceptionDetail(e));
        })
    }

    const dataEmpresa = () => {
        EmpresaService.all(_initialFilter()).then(res => {
            setEmpresa(res.data);
        }
        ).catch((e: Error) => {
            alert(splitExceptionDetail(e));
        })
    }

    const dataTipoContrato = () => {
        ContratoService.allContratoTipo().then(res => {
            setTipoContrato(res.data);
        }
        ).catch((e: Error) => {
            alert(splitExceptionDetail(e));
        })
    }

    const dataArea = () => {
        ConfiguracionService.allArea().then(res => {
            setArea(res.data);
        }
        ).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        })
    }

    const registrar = () => {
        if (!form) {
            return alert('Completar los datos');
        }

        ContratoService.addContrato(form).then(res => {
            if (res.status == 200) {
                _goBack();
                alert('Se registró correctamente');
            }
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    /* Function */
    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => _goBack(),
            },
        ];

        return _items;
    }

    const _goBack = () => {
        navigate(`${ConfigHelper.urlBase()}/contrato`);
    }

    const _validateForm = () => {
        if (!form) {
            return false;
        }

        if (!form.codigo || form.codigo == '') {
            return false;
        }

        if (!form.centroCostoId || form.centroCostoId == '') {
            return false;
        }

        if (!form.admContratoId || form.admContratoId == '') {
            return false;
        }

        if (!form.empresaId || form.empresaId == '') {
            return false;
        }

        if (!form.contratoTipoId || form.contratoTipoId == '') {
            return false;
        }

        if (!form.areaId || form.areaId == '') {
            return false;
        }

        if (!form.fechaInicio || form.fechaInicio == '') {
            return false;
        }

        if (!form.fechaInicio || form.fechaInicio == '') {
            return false;
        }

        if (!form.fechaVencimiento || form.fechaVencimiento == '') {
            return false;
        }

        if (!form.contactoNombre || form.contactoNombre == '') {
            return false;
        }

        return true;
    }

    /* Event */
    const _onChangeText = (type: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                switch (type) {
                    case 'codigo':
                        setForm({
                            ...form,
                            codigo: newValue,
                        })
                        break;
                    case 'servicio':
                        setForm({
                            ...form,
                            servicio: newValue,
                        })
                        break;
                    case 'nombreContacto':
                        setForm({
                            ...form,
                            contactoNombre: newValue,
                        })
                        break;
                    case 'correoContacto':
                        setForm({
                            ...form,
                            contactoCorreo: newValue,
                        })
                        break;
                    case 'celularContacto':
                        setForm({
                            ...form,
                            contactoCelular: newValue,
                        })
                        break;
                    case 'fijoContacto':
                        setForm({
                            ...form,
                            contactoFijo: newValue,
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'centroCosto':
                        setForm({
                            ...form,
                            centroCostoId: String(option.key),
                        })
                        break;
                    case 'adm':
                        setForm({
                            ...form,
                            admContratoId: String(option.key),
                        })
                        break;
                    case 'empresa':
                        setForm({
                            ...form,
                            empresaId: String(option.key),
                        })
                        break;
                    case 'tipoContrato':
                        setForm({
                            ...form,
                            contratoTipoId: String(option.key),
                        })
                        break;
                    case 'area':
                        setForm({
                            ...form,
                            areaId: String(option.key),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const _onChangeCheckbox = (type: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        if (isChecked != undefined) {
            switch (type) {
                case 'alimentacion':
                    setForm({
                        ...form,
                        incluyeAlimentacion: isChecked,
                    })
                    break;
                case 'hoteleria':
                    setForm({
                        ...form,
                        incluyeHoteleria: isChecked,
                    })
                    break;
                case 'transporte':
                    setForm({
                        ...form,
                        incluyeTransporte: isChecked,
                    })
                    break;
                case 'combustible':
                    setForm({
                        ...form,
                        incluyeCombustible: isChecked,
                    })
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <>
            <CustomCommandBar item={_leftBar()}></CustomCommandBar>

            <form>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        {/* <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1"></div> */}

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{
                            padding: '2%',
                        }}>
                            <div className="ms-depth-8" style={{
                                padding: '25px',
                            }}>
                                <TitleSeparator title='Registrar Contrato'></TitleSeparator>
                                <br />
                                <div className="ms-Grid" dir="ltr">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <div className="ms-fontSize-16" style={{ color: ThemeBase.theme.palette.themePrimary }}>Datos de Contrato</div>
                                                </div>

                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <Label></Label>
                                                    <Stack tokens={stackTokens}>
                                                        <div className="ms-Grid" dir="ltr">
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                    <TextField
                                                                        label='Código'
                                                                        placeholder='Código'
                                                                        autoComplete='off'
                                                                        onChange={(e, o) => _onChangeText('codigo', e, o)}
                                                                        required />
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                    <TextField
                                                                        label='Servicio'
                                                                        placeholder='Servicio'
                                                                        autoComplete='off'
                                                                        onChange={(e, o) => _onChangeText('servicio', e, o)} />
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                    <ComboBox
                                                                        label="Centro Costo"
                                                                        autoComplete='off'
                                                                        useComboBoxAsMenuWidth
                                                                        calloutProps={{ directionalHintFixed: true }}
                                                                        options={centroCosto}
                                                                        onChange={(e, o) => _onChangeCombo('centroCosto', e, o)}
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                    <ComboBox
                                                                        label="Adm. Contrato"
                                                                        autoComplete='off'
                                                                        useComboBoxAsMenuWidth
                                                                        calloutProps={{ directionalHintFixed: true }}
                                                                        options={adm}
                                                                        onChange={(e, o) => _onChangeCombo('adm', e, o)}
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                    <ComboBox
                                                                        label="Empresa"
                                                                        allowFreeform
                                                                        autoComplete='on'
                                                                        useComboBoxAsMenuWidth
                                                                        calloutProps={{ directionalHintFixed: true }}
                                                                        options={empresa}
                                                                        onChange={(e, o) => _onChangeCombo('empresa', e, o)}
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                    <ComboBox
                                                                        label="Tipo Contrato"
                                                                        autoComplete='off'
                                                                        useComboBoxAsMenuWidth
                                                                        calloutProps={{ directionalHintFixed: true }}
                                                                        options={tipoContrato}
                                                                        onChange={(e, o) => _onChangeCombo('tipoContrato', e, o)}
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                    <ComboBox
                                                                        label='Área'
                                                                        autoComplete='off'
                                                                        useComboBoxAsMenuWidth
                                                                        calloutProps={{ directionalHintFixed: true }}
                                                                        options={area}
                                                                        onChange={(e, o) => _onChangeCombo('area', e, o)}
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6" style={{ paddingTop: '5px' }}>
                                                                    <DatePicker
                                                                        placeholder='Fecha Inicio *'
                                                                        allowTextInput
                                                                        ariaLabel="Select a date. Input format is day slash month slash year."
                                                                        value={inicio}
                                                                        onSelectDate={setInicio as (date?: any) => void}
                                                                        formatDate={(d) => _formatDatePrefix('Inicio', d)}
                                                                        style={{ paddingTop: '4px' }}
                                                                    />
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6" style={{ paddingTop: '5px' }}>
                                                                    <DatePicker
                                                                        placeholder='Fecha Vencimiento *'
                                                                        allowTextInput
                                                                        ariaLabel="Select a date. Input format is day slash month slash year."
                                                                        value={vencimiento}
                                                                        onSelectDate={setVencimiento as (date?: any) => void}
                                                                        formatDate={(d) => _formatDatePrefix('Vencimiento', d)}
                                                                        style={{ paddingTop: '4px' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Stack>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                            <Stack tokens={stackTokens}>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <div className="ms-fontSize-16" style={{ color: ThemeBase.theme.palette.themePrimary }}>Contacto Comercial</div>
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <Label></Label>
                                                        <Stack tokens={stackTokens}>
                                                            <div className="ms-Grid" dir="ltr">
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                        <TextField
                                                                            label='Nombre'
                                                                            placeholder='Nombre Contacto'
                                                                            autoComplete='off'
                                                                            onChange={(e, o) => _onChangeText('nombreContacto', e, o)}
                                                                            required />
                                                                    </div>

                                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                        <TextField
                                                                            label='Correo'
                                                                            placeholder='Correo'
                                                                            autoComplete='off'
                                                                            onChange={(e, o) => _onChangeText('correoContacto', e, o)}
                                                                        />
                                                                    </div>

                                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                        <TextField
                                                                            label='Celular'
                                                                            placeholder='Celular'
                                                                            autoComplete='off'
                                                                            onChange={(e, o) => _onChangeText('celularContacto', e, o)}
                                                                        />
                                                                    </div>

                                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                        <TextField
                                                                            label='Fijo'
                                                                            placeholder='Fijo'
                                                                            autoComplete='off'
                                                                            onChange={(e, o) => _onChangeText('fijoContacto', e, o)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Stack>
                                                    </div>
                                                </div>

                                                <br /><br />

                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <div className="ms-fontSize-16" style={{ color: ThemeBase.theme.palette.themePrimary }}>Facilidades</div>
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <Label></Label>
                                                        <Stack tokens={stackTokens}>
                                                            <div className="ms-Grid" dir="ltr">
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                        <Stack tokens={stackTokens}>
                                                                            <Checkbox label='Alimentación' onChange={(e, o) => _onChangeCheckbox('alimentacion', e, o)} />
                                                                            <Checkbox label='Hotelería' onChange={(e, o) => _onChangeCheckbox('hoteleria', e, o)} />
                                                                            <Checkbox label='Transporte' onChange={(e, o) => _onChangeCheckbox('transporte', e, o)} />
                                                                            <Checkbox label='Combustible' onChange={(e, o) => _onChangeCheckbox('combustible', e, o)} />
                                                                        </Stack>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Stack>
                                                    </div>
                                                </div>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>

                                <ButtonForm register={registrar} back={_goBack} disabledRegister={!isComplete}></ButtonForm>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}