import { ConstrainMode, DefaultButton, MessageBar, MessageBarButton, MessageBarType, Panel, PanelType, PrimaryButton, Stack, TextField, Toggle } from "@fluentui/react";
import { json } from "d3";
import React, { useEffect } from "react";
import { FunctionComponent, useState } from "react";
import { IUbicaciones, IUbicacionEditar } from "../../../../interfaces/ubicacion/IUbicacion";
import UbicacionService from "../../../../services/ubicacionesServices";
import { useBoolean } from '@fluentui/react-hooks';
interface EditarUbicacionProps {
  isOpen: boolean;
  onDismiss: () => void;
  item: IUbicaciones;
  setActualizar: () => void;
}

const EditarUbicacion: FunctionComponent<EditarUbicacionProps> = (prop) => {
  const [formulariosEditados, setformulariosEditados] = useState<IUbicacionEditar>();
  const [choice, setChoice] = React.useState<string | undefined>(undefined);
  const [textError, settextError] = useState('Error');
  const resetChoice = React.useCallback(() => setChoice(undefined), []);
   //Mensaje de estado
  const [tipoMensaje, setTipoMensaje] = useState(0);
  const [verLoading, setVerLoading] = useState(false);
  const [verLoadingBoton, setVerLoadingBoton] = useState(false);

  useEffect(() => {

    if (prop.item !== undefined) {
      let objetoInterface: IUbicacionEditar = {
        ubicacionId: prop.item.ubicacionId,
        nombre: prop.item.nombre,
        codigo: prop.item.codigo,
        viajeAutorizado: prop.item.viajeAutorizado,
        lugarAutorizado: prop.item.lugarAutorizado,
        lugarDeEmbarque: prop.item.lugarEmbarque,
      }
      setformulariosEditados(objetoInterface)
    }
  }, [prop.isOpen]);

  const onChangeInputName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    if (newValue !== undefined) {
      setformulariosEditados({
        ...formulariosEditados, nombre: newValue!
      }
      )
    }
  }

  const onChangeInputCodigo = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    if (newValue !== undefined) {
      console.log("pass")
      setformulariosEditados({
        ...formulariosEditados, codigo: newValue
      }
      )
    }
  }

  function _onChangeEmbarque(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
    setformulariosEditados({ ...formulariosEditados, lugarDeEmbarque: checked })
  };

  function _onChangeVueloAutorizado(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
    setformulariosEditados({ ...formulariosEditados, viajeAutorizado: checked })
  };

  function _onChangeLugarAutorizado(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
    setformulariosEditados({ ...formulariosEditados, lugarAutorizado: checked })
  };

  // const SuccessExample = () => (
  //   <MessageBar
  //     messageBarType={MessageBarType.success}
  //     isMultiline={false}
  //     actions={
  //       <div>
  //         <MessageBarButton onClick={() => prop.setActualizar()
  //         }>
  //           Conforme
  //         </MessageBarButton>
  //       </div>
  //     }
  //   >
  //     Ubicación editada con éxito
  //   </MessageBar>
  // );
  // const ErrorExample = (p: any) => {
  //   return (
  //     <MessageBar
  //       messageBarType={MessageBarType.blocked}
  //       isMultiline={false}
  //       onDismiss={p.resetChoice}
  //       dismissButtonAriaLabel="Close"
  //       truncated={true}
  //       overflowButtonAriaLabel="See more"
  //     >
  //       {textError}
  //     </MessageBar>
  //   );
  // };

  const onClickRegistrar = () => {
    if (formulariosEditados !== undefined) {
      UbicacionService.ubicacionEditar(formulariosEditados).then(
        (res) => {
        
          if (res.status == 200) {
            console.log(res)
            setVerLoading(true);
            setTipoMensaje(2);
            setVerLoadingBoton(false)
          }
        }
      )
        .catch(
          (e: Error) => {
            console.log(e.message)
            // setChoice('error')
            setVerLoading(false);
            setTipoMensaje(1);
            setVerLoadingBoton(false)
          }
        )
    }
  }
  const _return =()=>{
    prop.setActualizar()
    prop.onDismiss()
    
  }
  const gapb = { childrenGap: 5 }
  const onRenderFooterContent = React.useCallback(
    () => (
      <div>
        <Stack horizontal horizontalAlign="end" tokens={gapb} >
          <PrimaryButton onClick={onClickRegistrar} styles={{ root: { marginLeft: "30px" } }} >
            Guardar
          </PrimaryButton>
          <DefaultButton onClick={prop.onDismiss} styles={{ root: { marginLeft: "0px" } }}>Cancelar</DefaultButton>
        </Stack>
      </div>
    ),
    [formulariosEditados],
  );



  return (
    <Panel
      headerText="Editar Ubicación"
      isOpen={prop.isOpen}
      onDismiss={prop.onDismiss}
      type={PanelType.medium}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
    >
      {tipoMensaje == 1 && (
        <>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => {
              setTipoMensaje(0);
              setVerLoadingBoton(false)
             
            }}
            dismissButtonAriaLabel="Close"
          //truncated={true}
          //overflowButtonAriaLabel="See more"
          >
            "Error"
          </MessageBar>
          <br></br>
        </>
      )}

      {tipoMensaje == 2 &&

        <>
          <MessageBar
            actions={
              <div>
                <MessageBarButton  onClick={_return}>
                  Conforme
                </MessageBarButton>
              </div>
            }
            messageBarType={MessageBarType.success}
            isMultiline={false}
          >
            Ubicacion editada exitosamente
          </MessageBar>
          <br></br>
        </>
      }
      {!verLoading && (
        <>
      <Stack >
        <TextField label="Nombre" value={formulariosEditados == undefined ? "" : formulariosEditados.nombre} onChange={onChangeInputName} />
        <TextField label="Código" value={formulariosEditados == undefined ? "" : formulariosEditados.codigo} onChange={onChangeInputCodigo} />
        <Toggle
          label="Lugar de Embarque"
          checked={formulariosEditados == undefined ? true : formulariosEditados.lugarDeEmbarque}
          onText="Sí"
          offText="No"
          onChange={_onChangeEmbarque} />
        <Toggle
          label="Lugar Autorizado"
          onText="Sí"
          offText="No"
          checked={formulariosEditados == undefined ? true : formulariosEditados.lugarAutorizado}
          onChange={_onChangeLugarAutorizado} />
        <Toggle
          label="Viaje Autorizado"
          onText="Sí"
          offText="No"
          onChange={_onChangeVueloAutorizado}
          checked={formulariosEditados == undefined ? true : formulariosEditados.viajeAutorizado}
        />

      </Stack>
      </>)}
    </Panel>
  );
}



export default EditarUbicacion;