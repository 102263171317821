import { DetailsList, ICommandBarItemProps, IColumn, SelectionMode, DetailsListLayoutMode, IGroup } from '@fluentui/react';
import { CSSProperties } from "styled-components";
import { CustomCommandBar } from '../Generic/customCommandBar';
import SpinnerCenter from '../Generic/SpinnerCenter';
import { _onRenderHeader, _onRenderRow } from '../../utils/Helper/helper';


const _rigthBar: ICommandBarItemProps[] = [];
const _leftBar: ICommandBarItemProps[] = [];
let _data: any[];
let _column: IColumn[];
let _groups: IGroup[];
const _style: CSSProperties = { padding: '20px' };
/* let _pagination: IPagination = _initialPagination(); */

export const TableBase1 = ({ rigthBar = _rigthBar, leftBar = _leftBar, data = _data, column = _column, groups = _groups, style = _style, isLoading = true, /* pagination = _pagination */ }) => {
    /* const footerLeft = () => {
        let start = data.length > 0 ? (pagination.currentPage - 1) * pagination.pageSize + 1 : 0;
        let end = data.length > 0 ? start + data.length - 1 : 0;

        const _items: ICommandBarItemProps[] = [
            {
                key: 'Info',
                text: `${start} - ${end} de ${pagination.total}`,
            },
        ];
        return _items;
    } */

    const footerRigth = () => {
        const _items: ICommandBarItemProps[] = [
            {
                key: 'Pagination',
                onRender: () => <>
                    {/* <Pagination
                        currentPage={pagination.currentPage}
                        totalCount={pagination.total}
                        pageSize={pagination.pageSize}
                        onPageChange={pagination.onPageChange}
                    /> */}
                </>
            }
        ];
        return _items;
    }

    function onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    paddingTop: '0px',
                    textAlign: ''
                },
            },
        })
    }

    return (
        <>
            <div style={style}>
                {(rigthBar.length > 0 || leftBar.length > 0) &&
                    <CustomCommandBar item={leftBar} farItem={rigthBar} style={{ paddingLeft: '0px', paddingRight: '0px' }}></CustomCommandBar>

                }

                <div className="ms-Grid" dir="ltr" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <SpinnerCenter isLoad={isLoading} />
                            {!isLoading &&
                                <>
                                    <DetailsList
                                        items={data}
                                        compact={true}
                                        columns={column}
                                        groups={groups}
                                        selectionMode={SelectionMode.none}
                                        setKey='Key'
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        selectionPreservedOnEmptyClick={true}
                                        enterModalSelectionOnTouch={true}
                                        ariaLabelForSelectionColumn="Toggle selection"
                                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                        checkButtonAriaLabel="select row"
                                        styles={_onRenderHeader}
                                        onRenderRow={_onRenderRow}
                                        onRenderDetailsHeader={onRenderDetailsHeader}
                                    />

                                    {/* <CustomCommandBar item={footerLeft()} farItem={footerRigth()} style={{ paddingLeft: '0px', paddingRight: '0px', height: '15px' }}></CustomCommandBar> */}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}