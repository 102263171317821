import { fetchIdentidad } from "./http-common";

const cambiarContrasenia = (data: {}) => {
  return fetchIdentidad.put(`/ActualizarContraseniaActual`, data);
};

const politicasContrasenia = () => {
  return fetchIdentidad.get(`/PoliticaContrasenia`);
};

const usuarioServicio = {
  cambiarContrasenia,
  politicasContrasenia,
};

export default usuarioServicio;
