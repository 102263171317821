import { fetchMovilizacion } from "./http-common";

const urlGrupo = 'Grupo';

const allGrupo = () => {
    return fetchMovilizacion.get(`${urlGrupo}`);
}

const GrupoService = {
    allGrupo,
}

export default GrupoService;