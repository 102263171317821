import { ICommandBarItemProps, Persona, PersonaSize, TooltipHost, IActivityItemProps, Link, Icon, PanelType, TextField, ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType } from "@fluentui/react";
import { IColumn, Selection } from "@fluentui/react/lib/DetailsList";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import RepoteBase from "../../components/Generic/ReporteBase";
import { TableBase } from "../../components/Table/TableBase";
import { IPagination } from "../../interfaces/base/ITable";
import { IFilterProgramacionBus } from "../../interfaces/filter/IFilterProgramacionBus";
import { IProgramacionPersona } from "../../interfaces/IProgramacion";
import { baseImageUrl, ItemNoneSelect, ItemSelectAll, splitExceptionDetail, _initialFilter, _initialPagination } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { useBoolean } from "@fluentui/react-hooks";
import ProgramacionBusService from "../../services/programacionBusService";
import ThemeBase from "../../utils/Theme/themeBase";
import { ReasignarTransporte } from "./panel/reasignarTransporte";
import { ICollectionAsignadoPorTransporte } from "../../interfaces/IProgramacionBus";
import { PanelPlantillaFiltro } from "../../components/PanelUpload/PanelPlantillaFiltro";
import { IException, IItemComboBaseDTO, ISearch } from "../../interfaces/base/IBase";
import ConfiguracionService from "../../services/configuracionService";
import OrganigramaService from "../../services/organigramaService";
import { Ifilter } from "../../interfaces/IFilter";
import EmpresaService from "../../services/empresaService";
import { IEmpresa } from "../../interfaces/IEmpresa";
import UbicacionService from "../../services/ubicacionesServices";
import { IFilterUbicacion } from "../../interfaces/filter/IFilterUbicacion";
import { UbicacionHelper } from "../../utils/Helper/ubicacionHelper";
import ProgramacionService from "../../services/ProgramacionService";
import React from "react";
import { AgregarManifiesto } from "./panel/agregarManifiesto";

const MANIFIESTO = {
    SUBIDA: 'MOVReporteManifiesto.trdx',
    BAJADA: 'MOVReporteManifiestoBajada.trdx'
}

export const ListarPersonaTransporteAsignado = () => {
    let { id } = useParams();

    const [hidden, setHidden] = useState(false);
    const [asignado, setAsignado] = useState<ICollectionAsignadoPorTransporte>(_initialDataCollection);
    const [filter, setFilter] = useState<IFilterProgramacionBus>({
        ..._initialFilter(),
        programacionBusId: id
    });
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Manifiesto */
    const [reportTelerik, setReportTelerik] = useState('');
    const [isOpenManifiesto, { setTrue: openManifiesto, setFalse: dismissManifiesto }] = useBoolean(false);
    const [isOpenReasignar, { setTrue: openReasignar, setFalse: dismissReasignar }] = useBoolean(false);
    const [isOpenAgregarManifiesto, { setTrue: openAgregarManifiesto, setFalse: dismissAgregarManifiesto }] = useBoolean(false);
    const [isBajada, setIsBajada] = useState(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    /* Filtro */
    const [isOpenFiltro, { setTrue: openFiltro, setFalse: dismissFiltro }] = useBoolean(false);

    /* Selection */
    const [sel, setSel] = useState<any[]>([]);

    const selection = new Selection({
        onSelectionChanged: () => {
            let d: any[] = selection.getSelection();

            let t: IActivityItemProps[] = d.map((x) => {
                return {
                    key: `id_${x.programacionPersonaId}`,
                    activityDescription: [
                        <Link key={x.programacionPersonaId} className={ThemeBase.activityClass.nameText}>
                            {x.nombres}
                        </Link>,
                        <span key={`2_${x.programacionPersonaId}`}> </span>,
                        <span
                            key={`3_${x.programacionPersonaId}`}
                            className={ThemeBase.activityClass.nameText}
                        >
                        </span>,
                    ],
                    activityIcon: <Icon iconName={'ReminderPerson'} />,
                    timeStamp: `DNI: ${x.nroDOI}`,
                    programacionPersonaBusId: `${x.programacionPersonaBusId}`,
                };
            });

            setSel(t);
        },
        getKey: (item: any) => item.programacionPersonaBusId
    });

    const [search, setSearch] = useState("");

    /* Hook */
    useEffect(() => {
        reloadInitial();
    }, [filter]);

    useEffect(() => {
        if (asignado.actividad) {
            switch (asignado.actividad) {
                case 1:
                    setReportTelerik(MANIFIESTO.SUBIDA);
                    break;
                case 2:
                    setReportTelerik(MANIFIESTO.BAJADA);
                    setIsBajada(true);
                    break;
                default:
                    break;
            }
        }
    }, [asignado]);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);


    /* Api */
    const dataAsignado = (param = filter) => {
        setAsignado(_initialDataCollection());
        ProgramacionBusService.personaTransporteasignado(param).then(res => {
            if (res.status == 200) {
                setAsignado(res.data);
                setTotal(res.data.total);
            }
            setHidden(true);
        }).catch((err) => {
            setHidden(true);
            alert(err.message);
        })
    }

    const reloadInitial = (param = filter) => {
        setSel([]);
        setHidden(false);
        setTitleColumn();
        dataAsignado(param);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => { window.history.go(-1); },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },
            {
                ...IconHelper.ICON_REPLY_MIRRORED,
                text: 'Reasignar',
                onClick: () => {
                    if (sel.length == 0) {
                        return alert("Debe seleccionar al menos un item");
                    }

                    openReasignar();
                }
            },
            {
                ...IconHelper.ICON_ADD,
                text: 'Agregar Manifiesto',
                onClick: () => {
                    openAgregarManifiesto();
                }
            },
        ];

        return _items;
    }

    const _rightBar = () => {
        const _items: ICommandBarItemProps[] = [

            {
                ...IconHelper.ICON_FILTER,
                onClick: () => {
                    //dataSupervisores()
                    openFiltro();
                },
                disabled: asignado.actividad == null
            },
        ];

        if (!isBajada) {
            _items.push(
                {
                    ...IconHelper.ICON_EXCEL,
                    onClick: openManifiesto,
                    disabled: asignado.actividad == null
                },
            );
        } else {
            _items.push({
                ...IconHelper.ICON_EXCEL,
                disabled: asignado.actividad == null,
                subMenuProps: {
                    items: [
                        {
                            ...IconHelper.ICON_EXCEL,
                            key: '1',
                            text: 'Manifiesto Asignados',
                            onClick: () => {
                                // setReportTelerik(MANIFIESTO.BAJADA);
                                openManifiesto();

                            }
                        },
                        {
                            ...IconHelper.ICON_EXCEL,
                            key: '2',
                            text: 'Manifiesto Embarcados',
                            onClick: () => {
                                setReportTelerik(MANIFIESTO.SUBIDA);
                                openManifiesto();
                            }
                        },
                    ],
                },
            })
        }

        return _items;
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Foto',
                ariaLabel: 'Column operations for File type, Press to sort on File type',
                isIconOnly: true,
                fieldName: 'foto',
                minWidth: 45,
                maxWidth: 45,
                onRender: (item: IProgramacionPersona) => (
                    <TooltipHost content={`Foto`}>
                        <Persona
                            text=''
                            size={PersonaSize.size24}
                            imageUrl={baseImageUrl}
                        />
                    </TooltipHost>
                ),
            },
            {
                key: 'column2',
                name: 'Nombre',
                fieldName: 'nombres',
                minWidth: 160,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span style={{ fontSize: "90%" }}>{item.nombres}</span>
                    );
                },
            },
            {
                key: 'column3',
                name: 'Identificación',
                fieldName: 'nroDOI',
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: 'number',
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return <span style={{ fontSize: '95%' }}>{`${item.tipoDocumento} ${item.nroDOI}`}</span>
                },
            },
            {
                key: 'column4',
                name: 'Nivel',
                fieldName: 'nivel',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column5',
                name: 'Level',
                fieldName: 'level',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column5.1',
                name: 'Gerencia',
                fieldName: 'gerencia',
                minWidth: 160,
                maxWidth: 160,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column18',
                name: 'Lugar Movilizacion',
                fieldName: 'lugarMovilizacion',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
            {
                key: 'column6',
                name: 'Origen',
                fieldName: 'origen',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Destino',
                fieldName: 'destino',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            // {
            //     key: 'column8',
            //     name: 'Email',
            //     fieldName: 'email',
            //     minWidth: 170,
            //     maxWidth: 170,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'number',
            // },
            // {
            //     key: 'column9',
            //     name: 'Celular',
            //     fieldName: 'celular',
            //     minWidth: 70,
            //     maxWidth: 70,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            //     isPadded: true,
            // },
            {
                key: 'column10',
                name: 'Empresa Transportista',
                fieldName: 'transporte',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            // {
            //     key: 'column11',
            //     name: 'Hotel',
            //     fieldName: 'hotel',
            //     minWidth: 70,
            //     maxWidth: 90,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            // },
            // {
            //     key: 'column15',
            //     name: 'Clasificación',
            //     fieldName: 'clasificacion',
            //     minWidth: 70,
            //     maxWidth: 90,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            // },
            {
                key: 'column16',
                name: 'Proceso - Estado',
                fieldName: 'nivelActual',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
            },
        ];

        setColumn(col);
    }

    /* Event Pagination */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterProgramacionBus = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    /* Event Filter */
    const _onChangeTextFilter = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {
            /* if (newValue === '') {
                _onClearFilter();
            } 
            else {
                setFilter({
                    ...filter,
                    search: newValue,
                })

                
            } */


            setSearch(newValue);
        }

        if (newValue == "") {

            let f: IFilterProgramacionBus = {
                ...filter,
                search: newValue,
            };

            setFilter(f);
            //reloadInitial(f);

        }
    };

    const _onPressFilter = (newValue: string) => {
        let f: IFilterProgramacionBus = {
            ...filter,
            search: newValue,
        };

        setFilter(f);
        reloadInitial(f);
    };

    const _onClearFilter = () => {
        let f: IFilterProgramacionBus = {
            programacionBusId: id,
            ..._initialFilter()
        }

        setFilter(f);
        //reloadInitial(f);
        setSearch("");
    }

    //------------- filtro----------------

    const [nivelCombo, setNivelCombo] = useState<IItemComboBaseDTO[]>([]);
    const [keysNivel, setKeysNivel] = useState<string[]>([]);
    const [filterNivel, setFilterNivel] = useState<ISearch>({
        search: ""
    }); 

    const [levelCombo, setLevelCombo] = useState<IItemComboBaseDTO[]>([]);
    const [keysLevel, setKeysLevel] = useState<string[]>([]);
    const [filterLevel, setFilterLevel] = useState<ISearch>({
        search: ""
    });

    const [gerenciaCombo, setGerenciaCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterGerencia, setFilterGerencia] = useState<ISearch>({
        search: ""
    });
    const comboBoxRefGerencia = React.useRef<IComboBox>(null); 
    const _openOptionGerencia= React.useCallback(() => comboBoxRefGerencia.current?.focus(true),[]); 

    const [empresaCombo, setEmpresaCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterEmpresa, setFilterEmpresa] = useState<Ifilter>({
        ..._initialFilter()
    });
    const comboBoxRefEmpresa = React.useRef<IComboBox>(null); 
    const _openOptionEmpresa= React.useCallback(() => comboBoxRefEmpresa.current?.focus(true),[]); 

    const [embarqueCombo, setEmbaqueCombo] = useState<IItemComboBaseDTO[]>([]);
    const [destinoCombo, setDestinoCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterEmbarque, setFilterEmbarque] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_ORIGEN
    });
    const [filterDestino, setFilterDestino] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_DESTINO,
    });

    const [lugarMovilizacionCombo, setLugarMovilizacionCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterMovilizacion, setfilterMovilizacion] = useState<ISearch>(_initialFilter);
    const LugarMovilizacionRef = React.useRef<IComboBox>(null);
    const _openOptionMovilizacion = React.useCallback(() => LugarMovilizacionRef.current?.focus(true), []);

    const [modelFilter, setModelFilter] = useState<IFilterProgramacionBus>(null!);


    useEffect(() => {
        dataNivel();

    }, [filterNivel]);

    useEffect(() => {
        dataLevel();

    }, [filterLevel]);

    useEffect(() => {
        dataGerencia();

    }, [filterGerencia]);

    useEffect(() => {
        dataEmpresa();

    }, [filterEmpresa]);

    useEffect(() => {
        dataUbicacionOrigen();

    }, [filterEmbarque]);

    useEffect(() => {
        dataUbicacionDestino();

    }, [filterEmbarque]);


    useEffect(() => {

        /* if (filterMovilizacion.search != null) {
            
        }
 */
        dataLugarMovilizacion();

    }, [filterMovilizacion]);

    const dataNivel = () => {

        ConfiguracionService.allNivel(filterNivel)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemSelectAll());
                    setNivelCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataLevel = () => {

        ConfiguracionService.allLevel(filterLevel)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemSelectAll());
                    setLevelCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataGerencia = () => {
        setGerenciaCombo([]);
        OrganigramaService.organigramaByTipo('01', filterGerencia)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    //t.unshift(ItemNoneSelect());

                    setGerenciaCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataEmpresa = () => {

        EmpresaService.all(filterEmpresa)
            .then((res) => {
                if (res.status == 200) {

                    // console.log("***** empresa *****");
                    // console.table(res.data.items);
                    let d: IEmpresa[] = res.data.items;
                    let t: IItemComboBaseDTO[] = d.map((x) => {
                        return {
                            key: x.empresaId,
                            text: x.nombre as string,
                        };
                    });

                    setEmpresaCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataUbicacionOrigen = () => {

        dataUbicacion(filterEmbarque, setEmbaqueCombo);

    }

    const dataUbicacionDestino = () => {

        dataUbicacion(filterDestino, setDestinoCombo);

    }

    const dataUbicacion = (filtro: any, setCombo: React.Dispatch<React.SetStateAction<any>>) => {
        UbicacionService.ubicacionPorFiltro(filtro)
            .then((res) => {
                setCombo(res.data);

            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion()
            .then((res) => {
                // console.log("************** lugar **********");
                // console.log(res.data);
                setLugarMovilizacionCombo(res.data);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const _buscarFiltro = () => {

        let keyNivelList = keysNivel.filter(key => key !== "selectAll")
        let keyLevelList = keysLevel.filter(key => key !== "selectAll")

        let listNivel = keyNivelList.join(",");
        let listLevel = keyLevelList.join(",");

        setFilter({
            ...filter,
            gerenciaId: modelFilter?.gerenciaId,
            nivelId: listNivel,
            levelId: listLevel,
            personaEmpresaId: modelFilter?.personaEmpresaId,
            lugarOrigenId: modelFilter?.lugarOrigenId,
            lugarDestinoId: modelFilter?.lugarDestinoId,
            lugarMovilizacionId: modelFilter?.lugarMovilizacionId
        })

        dismissFiltro();

    }

    const _limpiarFiltro = () => {

        setFilter({
            ...filter,
            gerenciaId: "",
            nivelId: "",
            levelId: "",
            personaEmpresaId: "",
            lugarOrigenId:"",
            lugarDestinoId:"",
            lugarMovilizacionId:""
        })

        setModelFilter({
            ...modelFilter,
            gerenciaId: "",
            personaEmpresaId: "",
            lugarOrigenId:"",
            lugarDestinoId:"",
            lugarMovilizacionId:""
        })

        setKeysNivel([])
        setKeysLevel([])

        // reloadInitial(filter);

        dismissFiltro();

    }

    const _onChangeComboFiltro = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {

                switch (type) {
                    case 'nivel':
                        /* let data1 = (prevSelectedKeys: any[]) =>
                            option.selected
                                ? [...prevSelectedKeys, option!.key as string]
                                : prevSelectedKeys.filter((k) => k !== option!.key);

                        //console.log(" ====== data1 ==========");
                        setKeysNivel(data1); */

                        _selectionItemsKeys(option, setKeysNivel, setNivelCombo, nivelCombo, keysNivel)

                        break;
                    case 'level':
                        /* let data2 = (prevSelectedKeys: any[]) =>
                            option.selected
                                ? [...prevSelectedKeys, option!.key as string]
                                : prevSelectedKeys.filter((k) => k !== option!.key);

                        setKeysLevel(data2);*/

                        _selectionItemsKeys(option, setKeysLevel, setLevelCombo, levelCombo, keysLevel)

                        break;
                    case 'gerencia':
                        /* setFilter({
                            ...filter,
                            gerenciaId: option.key.toString(),
                        }) */

                        setModelFilter({
                            ...modelFilter,
                            gerenciaId: option.key.toString(),
                        })

                        break;

                    case 'empresa':
                        setModelFilter({
                            ...modelFilter,
                            personaEmpresaId: option.key.toString(),
                        })

                        break;

                    case 'origen':
                        setModelFilter({
                            ...modelFilter,
                            lugarOrigenId: option.key.toString(),
                        })

                        break;

                    case 'destino':
                        setModelFilter({
                            ...modelFilter,
                            lugarDestinoId: option.key.toString(),
                        })

                        break;
                    case 'movilizacion':
                            setModelFilter({
                                ...modelFilter,
                                lugarMovilizacionId: option.key.toString(),
                            })
    
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const _selectionItemsKeys = (option: IComboBoxOption, setKeysCombo: React.Dispatch<React.SetStateAction<any[]>>, setlistObjetoCombo: React.Dispatch<React.SetStateAction<any[]>>, listObjetocombo: IItemComboBaseDTO[], keysCombo: any[]) => {

        const selected = option?.selected;
        const currentSelectedOptionKeys = keysCombo.filter(key => key !== 'selectAll');
        const selectAllState = currentSelectedOptionKeys.length === selectableOptionsCombo(listObjetocombo).length;


        if (option) {

            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {

                selectAllState

                    ? setKeysCombo([])

                    : setKeysCombo(['selectAll', ...selectableOptionsCombo(listObjetocombo).map(o => o.key as string)]);

            } else {


                const updatedKeys = selected

                    ? [...currentSelectedOptionKeys, option!.key as string]

                    : currentSelectedOptionKeys.filter(k => k !== option.key);




                if (updatedKeys.length === selectableOptionsCombo(listObjetocombo).length) {

                    updatedKeys.push('selectAll');

                }


                let listUpdateKeys = updatedKeys.filter(x => x !== 'selectAll')
                let ListEstadoCombo = listObjetocombo.filter(x => x.key !== 'selectAll')

                let listMarcado: IItemComboBaseDTO[] = [];
                let listNoMarcado: IItemComboBaseDTO[] = [];

                for (let i = 0; i < ListEstadoCombo.length; i++) {

                    let element = ListEstadoCombo[i].key

                    if (listUpdateKeys.includes(element)) {

                        //console.log(`coincide '${element}'`) 

                        listMarcado.push(ListEstadoCombo[i])

                    }

                    else {

                        listNoMarcado.push(ListEstadoCombo[i])

                    }

                }

                let lista1UnionLista2 = listMarcado.concat(listNoMarcado);


                setKeysCombo(updatedKeys);

                lista1UnionLista2.unshift(ItemSelectAll());
                setlistObjetoCombo(lista1UnionLista2)
            }

        }

    }

    const selectableOptionsCombo = (keyCombo: IItemComboBaseDTO[]) => {

        let selectableOptions = keyCombo.filter(
            option =>

                (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,

        )
        return selectableOptions;
    }

    const _onKeyUpCombo = (e: React.FormEvent<IComboBox>) => {

        let { id } = e?.target as HTMLInputElement;
        let { value } = e?.target as HTMLInputElement;

        switch (id) {

            case "gerencia-input":
                _openOptionGerencia()
        
                setFilterGerencia({
                  ...filterGerencia,
                  search: value,
                }) 

                break;
            case "empresa-input":
                _openOptionEmpresa()
        
                setFilterEmpresa({
                  ...filterEmpresa,
                  search: value,
                })
        
                break;
            case "movilizacion-input":
                _openOptionMovilizacion();

                setfilterMovilizacion({
                    ...filterMovilizacion,
                    search: value
                })

                break;

            case "centroCosto-input":
                /* _openOptionCentroCosto();
        
                setFilterCentroCosto({
                  ...filterCentroCosto,
                  search: value
                }) */

                break;
            default:
                break;
        }
    }

    return (
        <>
            <HeadingBar
                title={asignado.title ?? ''}
                subTitle={asignado.subTitle ?? ''}
                subTitle2={asignado.subTitleTwo ?? ''}
                searchChange={_onChangeTextFilter}
                searchHandler={_onPressFilter}
                //searchValue={filter.search ?? ''}
                searchValue={search}
                onClearHandler={_onClearFilter}
            ></HeadingBar>

            <TableBase leftBar={_leftBar()} rigthBar={_rightBar()} column={column} data={asignado.items} selection={selection} pagination={pager} isLoading={!hidden}></TableBase>

            <RepoteBase
                NameReport={reportTelerik}
                Parameters={{
                    ProgramacionBusId: id,
                }}
                openTelerik={isOpenManifiesto}
                dismissTelerik={dismissManifiesto}
            />

            <ReasignarTransporte programacionId={asignado.programacionId} programacionBusId={asignado.programacionBusId} transporteId={asignado.transporteId} isOpen={isOpenReasignar} dismiss={dismissReasignar} sel={sel} reloadParent={reloadInitial}></ReasignarTransporte>
            
            <AgregarManifiesto programacionId={asignado.programacionId} transporteId={asignado.transporteId} isOpen={isOpenAgregarManifiesto} dismiss={dismissAgregarManifiesto} reloadParent={reloadInitial}></AgregarManifiesto>
            
            <PanelPlantillaFiltro
                textHeader='Filtro'
                subTextHeader='Elija las condiciones para el filtro personalizado'
                textBoton='Buscar'
                textBotonDefault='Limpiar'
                onclickBoton={_buscarFiltro}
                onclickBotonDefault={_limpiarFiltro}
                openPanel={isOpenFiltro}
                closePanel={dismissFiltro}
                // onRenderHeader={onRenderHeaderFilter}
                size={PanelType.medium}
                disabledBoton={false}
                estadoBarraEnvio={false}
                nameIconHeader={"Filter"}
                nameIconButton={"Search"}
            >
                <>
                    <div className="ms-Grid" dir="ltr">
                        {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg612" >
                                        <TextField
                                            // label='Nro Documento Identidad'
                                            placeholder='Busque por nombres o DOI'
                                            name='doi'
                                            value={filter?.search != undefined ? filter?.search : ""}
                                            //onChange={(e, o) => _onChangeTextFiltro(e, o)}
                                        />
                                    </div>
                                </div>
                                <br /> */}
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                <ComboBox
                                    label="Nivel:"
                                    options={nivelCombo}
                                    // componentRef={comboBoxRef}
                                    selectedKey={keysNivel}
                                    calloutProps={{ directionalHintFixed: true }}
                                    useComboBoxAsMenuWidth
                                    allowFreeform
                                    multiSelect
                                    onChange={(e, o) => _onChangeComboFiltro("nivel", e, o)}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                <ComboBox
                                    label="Level:"
                                    options={levelCombo}
                                    // componentRef={comboBoxRef}
                                    selectedKey={keysLevel}
                                    // calloutProps={{ doNotLayer: true }}
                                    useComboBoxAsMenuWidth
                                    allowFreeform
                                    multiSelect
                                    onChange={(e, o) => _onChangeComboFiltro("level", e, o)}
                                    // placeholder='Gerencia'
                                    autoComplete='off'
                                    calloutProps={{ directionalHintFixed: true }}
                                // onKeyUp={(e) => _onUpCombo('gerencia', e)}
                                // onBlur={() => _onBlurCombo('gerencia')}
                                />

                            </div>
                        </div>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <ComboBox
                                    label="Gerencia:"
                                    options={gerenciaCombo}
                                    id="gerencia"
                                    componentRef={comboBoxRefGerencia}
                                    selectedKey={modelFilter?.gerenciaId}
                                    calloutProps={{ directionalHintFixed: true }}
                                    useComboBoxAsMenuWidth
                                    allowFreeform
                                    // multiSelect
                                    onChange={(e, o) => _onChangeComboFiltro("gerencia", e, o)}
                                    onKeyUp={_onKeyUpCombo}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <ComboBox
                                    label="Empresa:"
                                    id="empresa"
                                    options={empresaCombo}
                                    componentRef={comboBoxRefEmpresa}
                                    selectedKey={modelFilter?.personaEmpresaId}
                                    calloutProps={{ directionalHintFixed: true }}
                                    useComboBoxAsMenuWidth
                                    allowFreeform
                                    // multiSelect
                                    onChange={(e, o) => _onChangeComboFiltro("empresa", e, o)}
                                    onKeyUp={_onKeyUpCombo}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                    //componentRef={embarqueRef}
                                    label='Origen (Embarque)'
                                    allowFreeform
                                    autoComplete="off"
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                    options={embarqueCombo}
                                    // onKeyUp={_onUpEmbarque}
                                    selectedKey={modelFilter?.lugarOrigenId}
                                    onChange={(e, o) => _onChangeComboFiltro("origen", e, o)}
                                // onBlur={_onBlurEmbarque}

                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                <ComboBox
                                    //componentRef={campamentoRef}
                                    label='Destino (Desembarque)'
                                    allowFreeform
                                    autoComplete="off"
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                    // onKeyUp={_onUpCampamento}
                                    options={destinoCombo}
                                    selectedKey={modelFilter?.lugarDestinoId}
                                    onChange={(e, o) => _onChangeComboFiltro("destino", e, o)}
                                // onBlur={_onBlurCampamento}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                <ComboBox
                                    label="Lugar Movilización:"
                                    id="movilizacion"
                                    options={lugarMovilizacionCombo}
                                    componentRef={LugarMovilizacionRef}
                                    selectedKey={modelFilter?.lugarMovilizacionId}
                                    useComboBoxAsMenuWidth
                                    allowFreeform
                                    // placeholder='Gerencia'
                                    autoComplete='off'
                                    calloutProps={{ directionalHintFixed: true }}
                                    onChange={(e, o) => _onChangeComboFiltro("movilizacion", e, o)}
                                    onKeyUp={_onKeyUpCombo}
                                // onBlur={() => _onBlurCombo('gerencia')}
                                />

                            </div>
                        </div>
                    </div>
                </>

            </PanelPlantillaFiltro>


        </>
    )
}