import { IColumn, ICommandBarItemProps, Icon, Pivot, PivotItem, Stack, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import { HeadingBar } from "../../components/Generic/HeadingBar"
import { TableBase } from "../../components/Table/TableBase"
import { IContrato } from "../../interfaces/IContrato";
import { Ifilter } from "../../interfaces/IFilter";
import ContratoService from "../../services/contratoService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { stackTokensHorizontal, _initialFilter } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";

export const ListarContrato = () => {
    const navigate = useNavigate();

    const [hidden, setHidden] = useState(false);
    const [contratouser, setContratoUser] = useState<IContrato[]>([]);
    const [contrato, setContrato] = useState<IContrato[]>([]);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [filter, setFilter] = useState<Ifilter>(_initialFilter);
    const [tabIndex, setTabIndex] = useState('0');

    /* Hook */
    useEffect(() => {
        _setTitleColumn();
        dataContrato();
    }, []);

    useEffect(() => {
        switch (tabIndex) {
            case '0':
                setHidden(false);
                _setTitleColumn();
                dataContratoByUser();
                break;
            case '1':
                setHidden(false);
                _setTitleColumn();
                dataContrato();
                break;
            default:
                break;
        }
    }, [tabIndex]);

    /* Api */
    const dataContratoByUser = () => {
        setContratoUser([]);

        ContratoService.allContratos(filter).then((res) => {
            console.log(res);
            setContratoUser(res.data.data);
            setHidden(true);
        }).catch((err) => {
            console.log(err);
            setHidden(true);
        })
    }

    const dataContrato = () => {
        setContrato([]);

        // TeseoContratoService.all(filter).then((res) => {
        //     console.log(res);
        //     setContrato(res.data.data);
        //     setHidden(true);
        // }).catch((err) => {
        //     console.log(err);
        //     setHidden(true);
        // })

        ContratoService.allContratos(filter).then((res) => {
            console.log(res);
            setContrato(res.data);
            setHidden(true);
        }).catch((err) =>{
            console.log(err)
            setHidden(true);
        })
    }

    /* Function */
    const _setup = () => {
        setHidden(false);
        switch (tabIndex) {
            case '0':
                dataContratoByUser();
                break;
            case '1':
                dataContrato();
                break;
            default:
                break;
        }
    };

    const _setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Codigo',
                fieldName: 'codigo',
                minWidth: 90,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Empresa',
                fieldName: 'empresa',
                minWidth: 290,
                maxWidth: 300,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Área',
                fieldName: 'area',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Tipo',
                fieldName: 'contratoTipo',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Fecha Inicio',
                fieldName: 'fechaInicio',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Fecha Vencimiento',
                fieldName: 'fechaVencimiento',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Servicio',
                fieldName: 'servicio',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column8',
                name: 'Comprador',
                fieldName: 'nombresCompradores',
                minWidth: 190,
                maxWidth: 200,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IContrato) => {
                //  return <span>{item.nombresCompradores.map(x => x).join(', ')}</span>
                }
            },
            {
                key: 'column9',
                name: 'Max. Personal',
                fieldName: 'personalContratadoLimite',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'number',
                isPadded: true,
            },
            {
                key: "column10",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IContrato) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <>
                                <TooltipHost content={IconHelper.ICON_INFO.text}>
                                    <Icon iconName={IconHelper.ICON_INFO.iconProps?.iconName}
                                        style={{ cursor: 'pointer' }}
                                        className={`${ThemeBase.classes.iconFont}`}
                                        onClick={() => { navigate(`${ConfigHelper.urlBase()}/contrato/${item.id}`) }} />
                                </TooltipHost>
                            </>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_ADD,
                onClick: () => { navigate(`${ConfigHelper.urlBase()}/contrato/crear`) },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: _setup,
            },
        ];

        return _items;
    }

    /* Event */
    const _onChangeTab = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (item != undefined) {
            let { itemKey } = item.props;

            if (itemKey != undefined) {
                setTabIndex(itemKey);
            }
        }
    }

    return (
        <>
            <HeadingBar
                title='Contratos'
                subTitle='Gestión de Contratos'
                searchShow={false}
            ></HeadingBar>

            <div style={{ padding: '20px', paddingTop: '10px' }}>
                <CustomCommandBar item={_leftBar()}></CustomCommandBar>

                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <TableBase column={column} data={contrato} isLoading={!hidden} style={ThemeBase.notPaddingTableBase}></TableBase>
                            {/* <Pivot aria-label="Basic Pivot Example" selectedKey={tabIndex} onLinkClick={_onChangeTab}>
                                <PivotItem itemKey='0'
                                    headerText='Contratos por usuario'
                                    headerButtonProps={{
                                        'data-order': 1,
                                        'data-title': 'My Files Title',
                                    }}
                                >
                                    <TableBase column={column} data={contratouser} isLoading={!hidden} style={ThemeBase.notPaddingTableBase}></TableBase>
                                </PivotItem>
                                <PivotItem itemKey='1'
                                    headerText="Todos los Contratos"
                                    headerButtonProps={{
                                        'data-order': 1,
                                        'data-title': 'My Files Title',
                                    }}>
                                </PivotItem>
                            </Pivot> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}