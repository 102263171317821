import { FunctionComponent, useEffect, useState } from "react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import ubicacionesServices from "../../../../services/ubicacionesServices";
import { IUbicaciones, IUbicacionNuevo } from "../../../../interfaces/ubicacion/IUbicacion";
import { DialogType, IColumn, ICommandBarItemProps, Icon, TooltipHost } from "@fluentui/react";
import ThemeBase from "../../../../utils/Theme/themeBase";
import { IconHelper } from "../../../../utils/Helper/iconHelper";
import { HeadingBar } from "../../../../components/Generic/HeadingBar";
import CrearUbicacion from "./CrearUbicacion";
import { TableBase } from "../../../../components/Table/TableBase";
import { ActualizarUbicacion } from "./ActualizarUbicacion";
import { DialogBase } from "../../../../components/Dialog/DialogBase";
import EditarUbicacion from "./EditarUbicacion";
import { _initialFilter, _initialPagination } from "../../../../utils/Helper/helper";
import { IPagination } from "../../../../interfaces/base/ITable";
import { IFilterProgramacion } from "../../../../interfaces/IProgramacion";

interface VistaUbicacionesProps {

}

const VistaUbicaciones: FunctionComponent<VistaUbicacionesProps> = () => {
    const [columns, setcolumns] = useState<IColumn[]>([]);
    const [item, setitem] = useState<IUbicaciones[]>([]);
    const [isOpenCrear, { setTrue: openCrear, setFalse: dismissCrear }] = useBoolean(false);
    const [hidden, setHidden] = useState(false);

    //Eliminar
    const [hideEliminarUbicacion, { toggle: toggleEliminarEliminar }] = useBoolean(true);
    const [saveId, setsaveId] = useState("");
    //Editar
    const [isOpenEditarUbi, { setTrue: openEditarUbi, setFalse: dismissEditarUbi },] = useBoolean(false);
    const [saveIdEdit, setsaveIdEdit] = useState<IUbicaciones>();
    const [total, setTotal] = useState(_initialPagination().total);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);



    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });
    const [filter, setFilter] = useState<IUbicaciones>({
        ..._initialFilter(),
    })


    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    /* Event Pagination */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: currentPage,
            });

            let f: IUbicaciones = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial();
        }
    }

    useEffect(() => {
        setHidden(false)
        ubicacionesServices.listaUbicaciones(filter)
            .then((res) => {
                if (res.status == 200) {
                    setHidden(true)
                    setitem(res.data.items)
                    setTotal(res.data.total)


                } else {
                    console.log("error");

                }
            })
    }, [filter])






    const table = () => {
        setHidden(false)
        ubicacionesServices.listaUbicaciones(filter)
            .then((res) => {
                if (res.status == 200) {
                    let datos: IUbicacionNuevo[] = res.data.items;
                    setHidden(true)
                    setitem(res.data.items)
                    setTotal(res.data.total)


                } else {
                    console.log("error");

                }
            })
    }
    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_ADD,
                onClick: openCrear,
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },

        ]
        return _items;
    }

    const eliminarUbicacion = () => {
        if (saveId != undefined) {
            ubicacionesServices.ubicacionEliminar(saveId).then(res => {
                if (res.status == 200) {
                    toggleEliminarEliminar();
                     table() 
                }
            }).catch((e: IUbicaciones) => {
                alert("Error");
            })
        }
    }
    const reloadInitial = () => {
         table()
    }

    const columna = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Nombre',
                fieldName: 'nombre',
                minWidth: 150,
                maxWidth: 170,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Codigo',
                fieldName: 'codigo',
                minWidth: 100,
                maxWidth: 110,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Viaje Autorizado',
                fieldName: 'viajeAutorizado',
                minWidth: 100,
                maxWidth: 120,
                isRowHeader: true,
                isResizable: true,
                isPadded: true,
                onRender: (item: IUbicaciones) => (
                    <>{
                        item.viajeAutorizado ? "Si" : "No"}
                    </>
                )
            },
            {
                key: 'column4',
                name: 'Lugar Autorizado',
                fieldName: 'lugarAutorizado',
                minWidth: 100,
                maxWidth: 120,
                isRowHeader: true,
                isResizable: true,
                isPadded: true,
                onRender: (item: IUbicaciones) => (
                    <>{
                        item.lugarAutorizado ? "Si" : "No"}
                    </>
                )
            },
            {
                key: 'column5',
                name: 'Lugar de Embarque',
                fieldName: 'lugarEmbarque',
                minWidth: 100,
                maxWidth: 120,
                isRowHeader: true,
                isResizable: true,
                isPadded: true,
                onRender: (item: IUbicaciones) => (
                    <>{
                        item.lugarEmbarque ? "Si" : "No"}
                    </>
                )
            },
            {
                key: 'column6',
                name: 'Fecha de Creacion',
                fieldName: 'fechaCreacion',
                minWidth: 100,
                maxWidth: 120,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,

            },
            {
                key: 'column7',
                name: 'Opciones',
                fieldName: 'opciones',
                minWidth: 150,
                maxWidth: 170,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                onRender: (item: IUbicaciones) => (
                    <>
                        <TooltipHost content={`Editar`}>
                            <Icon iconName='Edit'
                                style={{ cursor: 'pointer' }}
                                className={`${ThemeBase.classes.iconFont}`}

                                onClick={() => {
                                    setsaveIdEdit(item)
                                    openEditarUbi()
                                }} />
                        </TooltipHost>

                        <TooltipHost content={`Eliminar`}>
                            <Icon iconName='Delete'
                                style={{ cursor: 'pointer' }}
                                className={`${ThemeBase.classes.iconFont}`}
                                onClick={() => {
                                    setsaveId(item.ubicacionId ? item.ubicacionId : "")
                                    toggleEliminarEliminar()
                                }
                                } />
                        </TooltipHost>

                    </>
                )
            },


        ]
        setcolumns(col)
    }
    useEffect(() => {
        columna();
         table(); 
    }, []);

    return (
        <>
            <HeadingBar
                title='Ubicaciones'
                subTitle='Gestión de Ubicaciones'
                searchShow={false}
            ></HeadingBar>
            {<CrearUbicacion isOpen={isOpenCrear} onDismiss={dismissCrear} setUpActualizar={table}></CrearUbicacion>
            }           
             <EditarUbicacion isOpen={isOpenEditarUbi} onDismiss={dismissEditarUbi} item={saveIdEdit!} setActualizar={table}></EditarUbicacion>
            <DialogBase isHidden={hideEliminarUbicacion}
                subTitle='La Ubicación será eliminada permanentemente '
                tipo={DialogType.normal}
                toggleHidden={toggleEliminarEliminar}
                dependency={[]}
                confirm={eliminarUbicacion}
            ></DialogBase>
            <TableBase
                isLoading={!hidden}
                column={columns}
                data={item}
                pagination={pager}
                leftBar={_leftBar()}

            />
        </>
    );
}

export default VistaUbicaciones;