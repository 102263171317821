import "./NuevoRecortePersonaStyles.css";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTrigger,
  Slider,
  makeStyles,
} from "@fluentui/react-components";

import { useRef, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/recorte/cropImage";

const useStyle = makeStyles({
  content: {
    width: "100vw",
    height: "100vh",
  },
});

const RecortePersona = ({ isOpen, onClose, setImagen }: any) => {
  const style = useStyle();
  const inputRef = useRef<HTMLInputElement>(null);

  const triggerFileSelectPopup = () => inputRef.current?.click();

  const [image, setImage] = useState<string | null>(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (
      inputRef.current &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const archivo = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result as string);
        setImagen({
          foto: reader.result as string,
          extension: getFileExtension(archivo.name),
          // extension: archivo.name.split('.').pop() || ''
        });
      });
    }
  };
  const getFileExtension = (filename: string): string => {
    return filename.split(".").pop() || "";
  };

  const onCut = async () => {
    if (!croppedArea || !image) {
      return;
    }

    const canvas = await getCroppedImg(image, croppedArea);

    canvas.toBlob(
      (blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            if (reader.result) {
              setImagen({
                foto: reader.result as string,
                extension: getFileExtension("image.jpeg"),
              });
            }
          };
        }
      },
      "image/jpeg",
      0.66
    );

    onClose();
    setImage("");
  };

  /* const onDownload = () => {
        generateDownload(image, croppedArea);
    } */

  return (
    <Dialog modalType="alert" open={isOpen}>
      <DialogSurface className={style.content}>
        <DialogBody>
          {/* <DialogTitle>
                        <h4>Seleccionar Archivo</h4>
                    </DialogTitle> */}
          <DialogContent
            style={{
              minHeight: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div className="container">
              <div className="container-cropper">
                {image ? (
                  <>
                    <div className="cropper">
                      <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                      />
                    </div>

                    <div className="slider">
                      <Slider
                        className="zoom-range"
                        style={{ width: "100%" }}
                        // step={1}
                        value={zoom}
                        defaultValue={1}
                        min={1}
                        max={5}
                        id={"range"}
                        onChange={(ev, data) => {
                          setZoom(data.value);
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>
              {/* <pre>{JSON.stringify(imagen, null, 2)}</pre> */}
            </div>
          </DialogContent>
          <div className="container-actions">
            <div className="container-buttons">
              <div className="buttons-left">
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={onSelectFile}
                  style={{ display: "none" }}
                />
                <Button
                  appearance="primary"
                  onClick={triggerFileSelectPopup}
                  style={{ marginRight: "10px" }}
                >
                  Cargar
                </Button>

                <Button appearance="secondary" onClick={onCut}>
                  Recortar
                </Button>
                {/* <Button color='secondary' onClick={onDownload}>
                                    Download
                                </Button> */}
              </div>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => {
                    onClose();
                    setImage("");
                    setImagen({
                      foto: "",
                      extension: "",
                    });
                  }}
                  size="medium"
                >
                  Cerrar
                </Button>
              </DialogTrigger>
            </div>
          </div>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export { RecortePersona };
