import { DetailsListLayoutMode, IColumn, ICommandBarItemProps, Icon, Stack, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { TableBase } from "../../../components/Table/TableBase";
import { IException, IItemComboBaseDTO } from "../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { IEmpresa } from "../../../interfaces/IEmpresa";
import { Ifilter } from "../../../interfaces/IFilter";
import EmpresaService from "../../../services/empresaService";
import { baseSearch, splitExceptionDetail, stackTokensHorizontal, _initialFilter, _initialPagination } from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { ActualizarEmpresa } from "./actualizarEmpresa";
import { ConfigHelper } from "../../../utils/Helper/configHelper";

export const ListarConfiguracionEmpresa = () => {
    const navigate = useNavigate();

    const [hidden, setHidden] = useState(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [empresa, setEmpresa] = useState<IDataCollection<IEmpresa[]>>(_initialDataCollection);
    const [filter, setFilter] = useState<Ifilter>(_initialFilter);
    const [searchParam, setSearchParam] = useSearchParams();

    /* Form */
    const [ isOpenEdit, { setTrue: openEdit, setFalse: dismissEdit },] = useBoolean(false);
    const [ selItem, setSelItem] = useState<IEmpresa>(null!);

     /* Pagination */
     const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
     const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
     const [total, setTotal] = useState(_initialPagination().total);
 
     const [pager, setPager] = useState<IPagination>({
         ..._initialPagination(),
         onPageChange: (page) => dataByPager(page),
         onSizeChange: (pageSize) => setPageSize(pageSize),
     });

    /* Hook */
    useEffect(() => {
        reloadInitial();
    }, []);

    /* useEffect(() => {
        if (filter) {
            dataEmpresa();
        }
    }, [filter]); */

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    /* Api */
    const dataEmpresa = (param = filter) => {
        setEmpresa(_initialDataCollection);
        EmpresaService.all(param).then(res => {
            if (res.status == 200) {
                setEmpresa(res.data);
                setTotal(res.data.total);
            }
            setHidden(true);
        }).catch((e: IException) => {
            setHidden(true);
            alert(splitExceptionDetail(e));
        })
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setTitleColumn();
        dataEmpresa(param);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial()
            },
        ];
        return _items;
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Nombre',
                fieldName: 'nombre',
                minWidth: 250,
                maxWidth: 250,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Nombre Corto',
                fieldName: 'nombreCorto',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'DOI',
                fieldName: 'doi',
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Es Titular',
                fieldName: 'esTitular',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Es Transportista',
                fieldName: 'esTransportista',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Es Vuelo',
                fieldName: 'esVuelo',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column7",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 90,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IEmpresa) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <>
                                <TooltipHost content={`Editar Empresa`}>
                                    <Icon
                                        iconName="Edit"
                                        style={{ cursor: "pointer" }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            openEdit(); 
                                            setSelItem(item);
                                        }} />
                                </TooltipHost>
                                <TooltipHost content={`Usuarios`}>
                                    <Icon iconName='Group'
                                        style={{ cursor: 'pointer' }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            navigate(`${ConfigHelper.urlBase()}/configuraciones/empresas/usuarios/${item.empresaId}`);
                                        }}
                                    />
                                </TooltipHost>
                            </>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    }

    const _getFilter = () => {
        return searchParam.get(baseSearch) ?? '';
    }

    /* Event */
    const _onChangeText = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue === '') {
            let f: Ifilter = _initialFilter();
            setFilter(f);
            reloadInitial(f);
        }

        if (newValue != undefined) {
            setSearchParam({
                [baseSearch]: newValue
            });
        }
    };

    const _onPress = (newValue: string) => {
        let f: Ifilter = {
            ..._initialFilter(),
            search: newValue,
        }

        setPager({
            ...pager,
            currentPage: _initialPagination().currentPage
        })

        reloadInitial(f);
        setFilter(f);
    };

    /* Event Filter */
    const _onClearSearch = () => {
        let f: Ifilter = {
            ..._initialFilter(),
            search: ''
        }
        reloadInitial(f);
        setFilter(f);

        setSearchParam({
            [baseSearch]: ''
        });
    }

    /* Event Pagination */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: Ifilter = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    return (
        <>
            <HeadingBar
                title='Empresas'
                subTitle='Gestión de Empresas'
                searchChange={_onChangeText}
                searchHandler={_onPress}
                searchValue={_getFilter()}
                onClearHandler={_onClearSearch}
            ></HeadingBar>

            <TableBase leftBar={_leftBar()} column={column} data={empresa?.items} pagination={pager} isLoading={!hidden}></TableBase>

            <ActualizarEmpresa empresa={selItem} isOpen={isOpenEdit} dismiss={dismissEdit} reloadParent={reloadInitial}></ActualizarEmpresa>
        </>
    )
}