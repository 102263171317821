import { Pivot, PivotItem } from "@fluentui/react";
import { CustomCommandBar } from "../../../components/Generic/customCommandBar";
import { IFilterAsignacion } from "../../../interfaces/IProgramacion";
import { _initialFilter } from "../../../utils/Helper/helper";
import { ListarPendienteAsignarBus } from "../../programacionBus/listarPendienteAsignarBus";
import { ListarAsignadoBus } from "./listarAsignadoBus";
import { ListarInscritoBus } from "./listarInscritoBus";
import { ListarPendienteBus } from "./listarPendienteBus";

export const TabAsignacionBus = ({ programacionId = "", isBajada = false }) => {
  const filter: IFilterAsignacion = {
    ..._initialFilter(),
    programacionId: programacionId,
  };

  return (
    <>
      <Pivot
        aria-label="Pivot Overflow Menu Example"
        linkFormat={"tabs"}
        overflowBehavior={"none"}
        overflowAriaLabel="more items"
        style={{ paddingTop: "10px" }}
      >
        <PivotItem headerText={`Inscripcion`}>
          <ListarInscritoBus
            programacionId={programacionId}
          ></ListarInscritoBus>
        </PivotItem>
        <PivotItem headerText="Pendientes">
          {isBajada ? (
            <ListarPendienteAsignarBus
              filter={filter}
            ></ListarPendienteAsignarBus>
          ) : (
            <ListarPendienteBus
              programacionId={programacionId}
            ></ListarPendienteBus>
          )}
        </PivotItem>
        <PivotItem headerText={"Asignados"}>
          <ListarAsignadoBus
            programacionId={programacionId}
            estaAbordado={false}
          ></ListarAsignadoBus>
        </PivotItem>

        <PivotItem headerText={"Abordados"}>
          <ListarAsignadoBus
            programacionId={programacionId}
            estaAbordado={true}
          ></ListarAsignadoBus>
        </PivotItem>
      </Pivot>
    </>
  );
};
