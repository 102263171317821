import { DetailsListLayoutMode, DialogType, IColumn, ICommandBarItemProps, Icon, Stack, Toggle, TooltipHost } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useBoolean } from "@fluentui/react-hooks";
import { HeadingBar } from '../../components/Generic/HeadingBar'
import { TableBase } from '../../components/Table/TableBase'
import { IDataCollection, IPagination } from '../../interfaces/base/ITable';
import { Ifilter } from '../../interfaces/IFilter';
import { IRegla } from '../../interfaces/IRegla';
import { ReglaService } from '../../services/reglaService';
import { splitExceptionDetail, stackTokensHorizontal, _initialFilter, _initialPagination } from '../../utils/Helper/helper';
import { IconHelper } from '../../utils/Helper/iconHelper';
import { _initialDataCollection } from '../../utils/Helper/tableHelper';
import ThemeBase from '../../utils/Theme/themeBase';
import { CrearRegla } from './crearRegla';
import { DialogBase } from '../../components/Dialog/DialogBase';

export const ListarRegla = () => {
    const [hidden, setHidden] = useState(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [data, setData] = useState<IDataCollection<IRegla>>(_initialDataCollection);
    const [filter, setFilter] = useState<Ifilter>(_initialFilter);

    /* Form */
    const [isOpenForm, { setTrue: openForm, setFalse: dismissForm },] = useBoolean(false);
    const [itemSel, setItemSel] = useState<IRegla>(null!);

    /* Dialog */
    const [hideEliminar, { toggle: toggleEliminar }] = useBoolean(true);
    const [selId, setSelId] = useState<string | undefined>(undefined);

    /* Loading */
    const [isLoadingELiminar, { setTrue: loadingEliminar, setFalse: dismissLoadingEliminar }] = useBoolean(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => setCurrentPage(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    /* Hook Pagination */
    useEffect(() => {
        reloadInitial();
    }, []);

    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            total: total,
        });
    }, [total]);

    /* Api */
    const dataRegla = () => {
        setData(_initialDataCollection);

        ReglaService.all(filter)
            .then((res) => {
                if (res.status == 200) {
                    setData(res.data);
                    setTotal(res.data.total);
                    setHidden(true);
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
                setHidden(true);
            });
    }

    const eliminarRegla = () => {
        if (selId != undefined) {
            loadingEliminar();

            ReglaService.elminarRegla(selId)
                .then((res) => {
                    if (res.status == 200) {
                        reloadInitial();
                        toggleEliminar();
                        dismissLoadingEliminar();
                    }
                })
                .catch((e) => {
                    alert(splitExceptionDetail(e));
                    dismissLoadingEliminar();
                });
        }
    }

    /* Function */
    const reloadInitial = () => {
        setHidden(false);
        setTitleColumn();
        dataRegla();
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_ADD,
                onClick: () => {
                    setItemSel(null!);
                    openForm();
                }
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: reloadInitial,
            },
        ];

        return _items;
    };

    const _rightBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_FILTER,
                disabled: true,
            },
        ];

        return _items;
    };

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Tipo',
                fieldName: 'tipoProgramacion',
                minWidth: 80,
                maxWidth: 80,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Lugar Mov.',
                fieldName: 'lugarMovilizacion',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Origen',
                fieldName: 'origen',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Destino',
                fieldName: 'destino',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Destino Bajada',
                fieldName: 'destinoBajada',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Invierte',
                fieldName: 'invierte',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IRegla) => {
                    return <span style={{ ...ThemeBase.justifiedStyle }}>{item.invierte ? 'Si' : 'No'}</span>
                }
            },
            {
                key: 'column7',
                name: 'Es Helo',
                fieldName: 'esVuelo',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IRegla) => {
                    return <span style={{ ...ThemeBase.justifiedStyle }}>{item.esVuelo ? 'Si' : 'No'}</span>
                }
            },
            {
                key: 'column8',
                name: 'Transporte',
                fieldName: 'requiereTransporte',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IRegla) => {
                    return <span style={{ ...ThemeBase.justifiedStyle }}>{item.requiereTransporte ? 'Si' : 'No'}</span>
                }
            },
            {
                key: 'column9',
                name: 'Opciones',
                fieldName: '',
                minWidth: 110,
                maxWidth: 110,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onRender: (item: IRegla) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <>
                                <TooltipHost content={'Editar'}>
                                    <Icon iconName='Edit'
                                        style={{ cursor: 'pointer' }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            setItemSel(item);
                                            openForm();
                                        }}
                                    />
                                </TooltipHost>

                                <TooltipHost content={`Eliminar`}>
                                    <Icon iconName='Delete'
                                        style={ThemeBase.iconRemove}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            setSelId(item.reglaId);
                                            toggleEliminar();
                                        }}
                                    />
                                </TooltipHost>
                            </>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    };

    return (
        <>
            <HeadingBar
                title='Reglas'
                subTitle='Automatización de Ubicaciones'
                searchPlaceholder='Buscar'
                searchShow={false}
            ></HeadingBar>

            <TableBase leftBar={_leftBar()} rigthBar={_rightBar()} column={column} data={data.items} isLoading={!hidden} pagination={pager} ></TableBase>
            <CrearRegla regla={itemSel} isOpen={isOpenForm} onDismiss={dismissForm} reloadInitial={reloadInitial} ></CrearRegla>

            <DialogBase
                isHidden={hideEliminar}
                subTitle={'La regla se eliminará correctamente'}
                tipo={DialogType.normal}
                toggleHidden={() => {
                    dismissLoadingEliminar();
                    toggleEliminar();
                }}
                dependency={[]}
                confirm={eliminarRegla}
                isDisableConfirm={isLoadingELiminar}
            ></DialogBase>
        </>
    )
}