import { ICommandBarItemProps } from "@fluentui/react";
import { IPagination } from "../../interfaces/base/ITable";
import { _initialPagination } from "../../utils/Helper/helper";
import { CustomCommandBar } from "../Generic/customCommandBar";
import Pagination from "../Pagination/pagination";

let _data: any[];
let _pagination: IPagination = _initialPagination();

export const PaginationFooter = ({ data = _data, pagination = _pagination }) => {
    const footerLeft = () => {
        let start = data.length > 0 ? (pagination.currentPage - 1) * pagination.pageSize + 1 : 0;
        let end = data.length > 0 ? start + data.length - 1 : 0;

        const _items: ICommandBarItemProps[] = [
            {
                key: 'Info',
                text: `${start} - ${end} de ${pagination.total}`,
            },
        ];
        return _items;
    }

    const footerRigth = () => {
        const _items: ICommandBarItemProps[] = [
            {
                key: 'Pagination',
                onRender: () => <>
                    <Pagination
                        currentPage={pagination.currentPage}
                        totalCount={pagination.total}
                        pageSize={pagination.pageSize}
                        onPageChange={pagination.onPageChange}
                    />
                </>
            }
        ];
        return _items;
    }
    return (
        <div style={{  padding: 10,}}>
            <CustomCommandBar item={footerLeft()} farItem={footerRigth()} /*style={{ paddingLeft: '0px', paddingRight: '0px', height: '15px' }}*/></CustomCommandBar>
        </div>
    )
}