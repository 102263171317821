import { Panel, ProgressIndicator, PrimaryButton, Stack, PanelType, DatePicker, TextField } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar";
import { IInfoVuelo, IUpdateVuelo } from "../../../../interfaces/IAccesoPersona";
import ProgramacionPersonaService from "../../../../services/programacionPersonaService";
import { _formatDate, _formatDatePrefix, _parseDateFromString } from "../../../../utils/Helper/datetimeHelper";
import { splitExceptionDetail, stackTokens } from "../../../../utils/Helper/helper";
import { MessageHelper } from "../../../../utils/Helper/messageHelper";
import ThemeBase from "../../../../utils/Theme/themeBase";

export const ActualizarDatosVuelo = ({ programacionPersonaId = '', isOpen = false, dismiss = () => { }, reloadParent = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    const [info, setInfo] = useState<IInfoVuelo>(null!);
    const [form, setForm] = useState<IUpdateVuelo>(null!);

    /* Form */
    const [fechaVuelo, setFechaVuelo] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (programacionPersonaId != '') {
            reloadInitial();
        }
    }, [isOpen]);

    useEffect(() => {
        if (fechaVuelo != undefined) {
            console.log(fechaVuelo);

            setForm({
                ...form,
                fechaVuelo: _formatDate(fechaVuelo, ',')
            });
        }
    }, [fechaVuelo]);

    useEffect(() => {
        if (info) {
            let d: IInfoVuelo = info;

            setForm({
                id: d.id,
                aerolinea: d.aerolinea,
                vuelo: d.vuelo,
                ruta: d.ruta,
                horaSale: d.horaSale,
                horaLlega: d.horaLlega,
                codigoAerolinea: d.codigoAerolinea,
                codigoGds: d.codigoGds,
                tarifa: d.tarifa,
                status: d.status,
            });

            setFechaVuelo(_parseDateFromString(d.fechaVuelo ?? ''));
        }
    }, [info]);

    /* Api */
    const dataInfo = () => {
        ProgramacionPersonaService.infoVuelo(programacionPersonaId)
            .then((res) => {
                if (res.status == 200) {
                    setInfo(res.data);
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
            });
    }

    const actualizarInfoVuelo = () => {
        if (form) {
            setIsSend(true);
            setChoice(undefined);

            ProgramacionPersonaService.actualizarInfoVuelo(form)
                .then((res) => {
                    setIsSend(false)
                    if (res.status == 200) {
                        reloadParent();
                        setChoice('send');
                    }
                })
                .catch((e) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    }

    /* Function */
    const reloadInitial = () => {
        setChoice(undefined);
        dataInfo();
    }

    /* Event */
    const _onChangeText = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let { name } = event?.target as HTMLInputElement;

                switch (name) {
                    case 'aerolinea':
                        setForm({
                            ...form,
                            aerolinea: newValue,
                        })
                        break;
                    case 'vuelo':
                        setForm({
                            ...form,
                            vuelo: newValue,
                        })
                        break;
                    case 'ruta':
                        setForm({
                            ...form,
                            ruta: newValue,
                        })
                        break;
                    case 'horaSale':
                        setForm({
                            ...form,
                            horaSale: newValue,
                        })
                        break;
                    case 'horaLlega':
                        setForm({
                            ...form,
                            horaLlega: newValue,
                        })
                        break;
                    case 'codigoAerolinea':
                        setForm({
                            ...form,
                            codigoAerolinea: newValue,
                        })
                        break;
                    case 'codigoGds':
                        setForm({
                            ...form,
                            codigoGds: newValue,
                        })
                        break;
                    case 'tarifa':
                        setForm({
                            ...form,
                            tarifa: newValue,
                        })
                        break;
                    case 'status':
                        setForm({
                            ...form,
                            status: newValue,
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <Stack tokens={stackTokens}>
                    <PrimaryButton onClick={actualizarInfoVuelo} styles={ThemeBase.panelButtonStyle} disabled={isSend}>
                        Actualizar
                    </PrimaryButton>
                </Stack>
            </div>
        ),
        [programacionPersonaId, isOpen, form, fechaVuelo, dismiss, isSend]
    );

    return (
        <>
            <Panel
                type={PanelType.medium}
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText='Actualizar datos vuelo'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    reloadInitial();
                                }}></CustomMessageBar>}
                            </div>
                        </div>
                    </div>

                    {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}

                    <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                        <div className="ms-Grid" dir="ltr" style={{ paddingTop: '15%' }}>
                            <Stack tokens={stackTokens}>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <DatePicker
                                            label='Fecha Vuelo'
                                            placeholder='Fecha Vuelo *'
                                            allowTextInput
                                            ariaLabel="Select a date. Input format is day slash month slash year."
                                            value={fechaVuelo}
                                            onSelectDate={setFechaVuelo as (date?: any) => void}
                                            formatDate={(d) => _formatDatePrefix('F. Vuelo', d)}
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            name="aerolinea"
                                            label='Aerolínea'
                                            placeholder="Aerolínea"
                                            value={form?.aerolinea ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            name="vuelo"
                                            label='Vuelo'
                                            placeholder="Vuelo"
                                            value={form?.vuelo ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            name="ruta"
                                            label='Ruta'
                                            placeholder="Ruta"
                                            value={form?.ruta ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            type='time'
                                            label='Hora Sale'
                                            name='horaSale'
                                            placeholder='Hora Sale'
                                            value={form?.horaSale ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete='off'
                                            required
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            type='time'
                                            label='Hora Llega'
                                            name='horaLlega'
                                            placeholder='Hora Llega'
                                            value={form?.horaLlega ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete='off'
                                            required
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            name="codigoAerolinea"
                                            label='Código Aeroelínea'
                                            placeholder="Código Aeroelínea"
                                            value={form?.codigoAerolinea ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            name="codigoGds"
                                            label='Código GDS'
                                            placeholder="Código GDS"
                                            value={form?.codigoGds ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            name="tarifa"
                                            label='Tarifa'
                                            placeholder="Tarifa"
                                            value={form?.tarifa ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            name="status"
                                            label='Status'
                                            placeholder="Status"
                                            value={form?.status ?? ''}
                                            onChange={_onChangeText}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </Stack>
                        </div>
                    </form>
                </>
            </Panel>
        </>
    )
}