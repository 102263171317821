
import { Panel, PanelType, ActivityItem, FontIcon, FontSizes, IStackStyles, Stack, IComboBox, IComboBoxOption, MessageBar, MessageBarType, MessageBarButton, TextField, ComboBox, ProgressIndicator, IColumn, DialogType } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import React, { useEffect, useState } from 'react';
import { NeutralColors } from '@fluentui/theme';
import { useBoolean } from '@fluentui/react-hooks';
import { IItemCatalogoAdjunto, ITransporte, IListarTransportes, IUpdateTransporte, IActualizarTransporte, IMarcar } from '../../interfaces/ITransporte';
import transporteService from '../../services/transporteService';
import { baseImageUrl, baseSearch, IndexNone, isObjectEmpty, ItemNoneSelect, splitExceptionDetail, stackTokensHorizontal, _initialFilter, _initialPagination, _selectionItemsKeys } from "../../utils/Helper/helper";
import ThemeBase from "../../utils/Theme/themeBase";
import { IBase, IException, IFile, IItemComboBaseDTO, ISearch } from '../../interfaces/base/IBase';
import OrganigramaService from '../../services/organigramaService';
import { Ifilter } from '../../interfaces/IFilter';

import TransporteService from '../../services/transporteService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ConfiguracionService from '../../services/configuracionService';
import { IFilterTransporteVigente } from '../../interfaces/filter/IFilterTransporte';
import { IResultadoCargaMasiva } from '../../interfaces/IProgramacion';
import { IconHelper } from "../../utils/Helper/iconHelper";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { ColorHelper } from "../../utils/Helper/colorHelper";
import { BlobHelper } from "../../utils/Helper/blobHelper";
import { IListarPersonaById } from '../../interfaces/IPersona';
import { DialogBase } from '../../components/Dialog/DialogBase';
import { DialogBaseAlert } from '../../components/Dialog/DialogBaseAlert';
import { DialogBaseSuccess } from '../../components/Dialog/DialogBaseSuccess';
import { map } from 'd3';
const stackStyles: IStackStyles = {
    root: {
        // background: '#ffff',
    },
};


function PanelActualizarTransporte({
    transporteId = "",
    isOpenPanel = false,
    dismissPanel = () => { },
    registar = () => { },
    reloadParent = () => { }

}) {

    //filter 
    const [filterEmpresa, setFilterEmpresa] = useState<ISearch>(_initialFilter);
    const [filterGerencia, setFilterGerencia] = useState<ISearch>(_initialFilter);
    const [isOpenEditar, { setTrue: openEditar, setFalse: dismissEditar },] = useBoolean(false);

    // useRef
    const empresaTransporteRef = React.useRef<IComboBox>(null);
    const marcaTransporteRef = React.useRef<IComboBox>(null);
    const modeloTransporteRef = React.useRef<IComboBox>(null);
    const tipoTransporteRef = React.useRef<IComboBox>(null);
    const categoriaRef = React.useRef<IComboBox>(null);


    const [catalogo, setCatalogo] = useState<IItemCatalogoAdjunto[]>([]);

    const [isKeyUser, setIskeyUser] = useState(false);
    const [isTransporte, setIsTransporte] = useState(false);
    const [isSSEE, setIsSSEE] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    /* Opcion seleccionada - Rol Admin - 3 submenus */
    const [optionMasivo, setOptionMasivo] = useState<number>(0);

    const _openOptionTransporte = React.useCallback(() => empresaTransporteRef.current?.focus(true), []);

    const [filterEmpresaTransporte, setFilterEmpresaTransporte] = useState<Ifilter>({
        ..._initialFilter()
    });

    /* Log actualizacion masiva */
    const [columnLog, setColumnLog] = useState<IColumn[]>([]);
    const [detalleLog, setDetalleLog] = useState<IResultadoCargaMasiva>(null!);
    const [isOpenLog, { setTrue: openLog, setFalse: dismissLog }] = useBoolean(false);
    const [isOpenReportLog, { setTrue: openReportLog, setFalse: dismissReportLog },] = useBoolean(false);

    /* Panel Upload - Actualizacion masiva*/
    const [isOpenUpload, { setTrue: openUpload, setFalse: dismissUpload }] =
        useBoolean(false);
    const [fileDetail, setfileDetail] = React.useState<IFile>(null!);
    const [excel, setExcel] = React.useState<FormData>(null!);

    // Estado del opcion ComboBox
    let navigate = useNavigate();


    const [hidden, setHidden] = useState(false);
    const [data, setData] = useState<ITransporte[]>([]);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [searchParam, setSearchParam] = useSearchParams();
    const [filter, setFilter] = useState<IFilterTransporteVigente>(_initialFilter)
    const [area, setArea] = useState<IItemComboBaseDTO[]>([]);

    const [categoriaCombo, setCategoriaCombo] = useState<IItemComboBaseDTO[]>([])
    const [tipoTransporteCombo, setTipoTransporteCombo] = useState<IItemComboBaseDTO[]>([])
    const [modeloCombo, setModeloCombo] = useState<IItemComboBaseDTO[]>([]);
    const [marca, setMarca] = useState<IItemComboBaseDTO[]>([]);
    const [empresaCombo, setEmpresaCombo] = useState<IItemComboBaseDTO[]>([]);


    const [modelItem, setModelItem] = useState<IUpdateTransporte>(null!);
    const [info, setInfo] = useState<ITransporte>(null!);
    const [model, setModel] = useState<IUpdateTransporte>(null!);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);


    const [isSearch, setisSearch] = useState(false);
    //mensajes de estado
    const [verLoading, setVerLoading] = useState(false);
    const [mensajeTransaccion, setMensajeTransaccion] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState(0);
    const [verLoadingBoton, setVerLoadingBoton] = useState(false);


    const [isComplete, setIsComplete] = useState(false);

    const [hideEmailCierre, { toggle: toggleEmailCierre }] = useBoolean(true);
    const [isLoadingEmail, { setTrue: loadingEmail, setFalse: dismissLoadingEmail }] = useBoolean(false);
    const [allMarcaId, setAllMarcaId] = useState("");

    /**Panel de busquedas */
    const [isOpenPanelSearch, { setTrue: OpenPanelSearch, setFalse: dismissPanelSearch },] = useBoolean(false);
  /* Api */
  const infoTrasporte = () => {
    if (transporteId != undefined) {

        TransporteService.detalleTransporteById(transporteId)
            .then((resultado) => {

                if (resultado.status === 200) {
                    let d: IListarTransportes = resultado.data
                    setAllMarcaId(resultado.data.marcaId)
                    setModelItem({
                        ...modelItem,

                        empresaId: d.empresaId,
                        marcaId: d.marcaId,
                        modeloId: d.modeloId?.toString(),
                        tipoTransporteId: d.tipoTransporteId,
                        categoriaId: d.categoriaId,
                        placa: d.placa,
                        pasajeros: d.pasajeros,
                        anioFabricacion: d.anioFabricacion,
                        codigoInterno: d.codigoInterno,
                        observacion: d.observacion,
                    })

             
                }
            })
    }
};

    const ActualizarDatosTransporte = () => {

        let modelUpdateTransporte: IActualizarTransporte = {
            isBorrador: true,
            codigoInterno: modelItem.codigoInterno,
            tipoTransporteId: modelItem.tipoTransporteId,
            categoriaId: modelItem.categoriaId,
            placa: modelItem.placa,
            anioFabricacion: modelItem.anioFabricacion,
            empresaId: modelItem.empresaId,
            modeloId: modelItem.modeloId?.toString(),
            pasajeros: modelItem.pasajeros,
            tarjetaPropiedad: modelItem.tarjetaPropiedad, //tarjeta
            observacion: modelItem.observacion,
            id: transporteId,
            /*isEdit: modelItem.isEdit,*/
            estado: modelItem.estado,
            colorEstado: modelItem.colorEstado
        }
        //console.log(modelUpdateTransporte);
/*         console.log("adfasdfasdfasdfdsafsadfasdfasd",modelUpdateTransporte)
 */        TransporteService.putTransporte(modelUpdateTransporte).then((res) => {
            if (res.status === 200) {
                /* alert("Se actualizo correctamente") */
                setMensajeTransaccion("Se ha actualizado correctamente")
               

                setTipoMensaje(2);
                toggleEmailCierre()
                setVerLoading(false);
                setVerLoadingBoton(true);
            }
        }).catch(function (error) {
            if (error.response) {
                // alert(e.message);
                setMensajeTransaccion(error.response.data.detail)
                setTipoMensaje(1);
                setVerLoading(false);
            }
        })

    }
    /* Hook */



    useEffect(() => {
        filter.empresaId != null || filter.areaReporteId != null
            ? setisSearch(true)
            : setisSearch(false);
    }, [isOpenPanel]);

    const clearPanelSearch = () => {
        dismissPanelSearch();
        _onClearFilter();
    };
     
    useEffect(() => {
        if (isOpenPanel && transporteId !== '') {
            infoTrasporte();
        
            dataEmpresa(); //para llenar el conmbo EMPRESA
            setupCombo();
            dataCategoria();
            dataMarca();
/*             dataModeloByMarcaId(allMarcaId)
 */           dataTipoTranporte();
            dataCategoria();
        }
    }, [isOpenPanel]);

    useEffect(() => {
        reloadInitial();
    }, [])
    {/* useEffect(() => {
        dataMarca();
        dataTipoTranporte();
        dataCategoria();
        dataEmpresa();
        /* dataCatalogoAdjunto(); 
    }, []); */}

    useEffect(() => {
        if (allMarcaId != "") {
            dataModeloByMarcaId(allMarcaId) 
        }else{
            console.log("ERRRORRRRRRR")
        }
    }, [ allMarcaId]);

    useEffect(() => {
        if (filterEmpresa.search != null) {
            dataEmpresa();
        }
    }, [filterEmpresa]);

    /* Validar campos requeridos */
    useEffect(() => {
        setIsComplete(_validateForm);
    }, [model]);

    useEffect(() => {
        setIsComplete(_validateForm);
    }, [catalogo]);



    /* Filter */


  
    //API
    const _uploadFile = (ev: React.ChangeEvent, id: string) => {
        let { files } = ev.target as HTMLInputElement;

        if (files && files[0]) {
            let f = files[0];
            getBase64(f).then(
                data => {
                    let ot = catalogo.map(x => {
                        if (x.id == id) {
                            return ({
                                ...x,
                                archivo: f.name,
                                file: data as string,
                                extension: `.${f.name.split('.').pop()}` as string,
                            });
                        } else {
                            return x;
                        }
                    })

                    setCatalogo(ot)
                }
            );
        } else {
            let ot = catalogo.map(x => {
                if (x.id == id) {
                    return ({
                        ...x,
                        archivo: '',
                        file: '',
                        extension: '',
                    });
                } else {
                    return x;
                }
            })

            setCatalogo(ot)
        }
    }

    const _validateForm = () => {
        if (!model) {
            return false;
        }

        let st = true;

        if (catalogo.length > 0) {
            let reqLst = catalogo.filter(x => x.required);
            if (reqLst.length > 0 && reqLst.filter(x => (x.file == '' || !x.file)).length > 0) {
                st = false;
            }
        }

        if (model) {
            if (!model.modelo || model.modelo == '') {
                st = false;
            }

            if (!model.tipoTransporte || model.tipoTransporte == '') {
                st = false;
            }

            if (!model.categoria || model.categoria == '') {
                st = false;
            }

             if (!model.empresaId || model.empresaId == '') {
                st = false;
            } 

            if (!model.placa || model.placa == '') {
                st = false;
            }

            if (!model.pasajeros || model.pasajeros <= 0) {
                st = false;
            }
        }

        return st;
    }

    /* Event */
    const _onChangeEmpresa = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                dataEmpresa()
                setModelItem({
                    ...modelItem,
                    empresaId: option.key.toString(),
                })
            }
        }
    }
    const _onChangePasajero = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                pasajeros: parseInt(newValue),
            });
        }
    };
    const _onChangeMarca = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                dataModeloByMarcaId(option.key.toString())
                setModelItem({
                    ...modelItem,
                    marcaId: option.key.toString(),
                })
            }
        }
    }
    const _onChangeModelo = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModelItem({
                    ...modelItem,
                    modeloId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeTipo = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModelItem({
                    ...modelItem,
                    tipoTransporteId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeCategoria = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                dataCategoria()
                setModelItem({
                    ...modelItem,
                    categoriaId: option.key.toString(),
                })
            }
        }
    }
    
    const _onChangeAnio = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let val = event?.target as HTMLInputElement;

        let an: number | null = null;

        if (val) {
            if (parseInt(val.value)) {
                an = parseInt(val.value);
            }
        }

        setModel({
            ...model,
            anioFabricacion: an,
        });
    }


    // api
    /*     const dataTransporte = (param = filter) => {
            setData([]);
            TransporteService.getListaTrasporte(param)
            .then((res) => {
                if (res.status == 200) {
                    setData(res.data.data);
                    setTotal(res.data.total);
                }
                setHidden(true);
                })
                .catch((e: IException) => {
                setHidden(true);
                alert(splitExceptionDetail(e));
                });
            };
         */

    const dataEmpresa = () => {
        ConfiguracionService.allEmpresa(filterEmpresa)
            .then((res) => {
                if (res.status == 200) {
                    setEmpresaCombo(res.data);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataTipoTranporte = () => {
        TransporteService.allTipo().then(res => {
            setTipoTransporteCombo(res.data);
        }).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataCategoria = () => {
        setCategoriaCombo([]);
        TransporteService.allCategoria().then(res => {
            setCategoriaCombo(res.data);
        }).catch((e: Error) => {
            alert(e.message);
        })
    }
    const dataModeloByMarcaId = (marcaId: string) => {
        setModeloCombo([]);
        TransporteService.allModeloByMarcaId(marcaId).then(res => {
            setModeloCombo(res.data);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataMarca = () => {
    
        TransporteService.allMarca().then(res => {
            if (res.status === 200) {
                setMarca(res.data)
                
            }
        }).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataArea = () => {
        setArea([]);
        OrganigramaService.organigramaByTipo('01', filterGerencia)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemNoneSelect());

                    setArea(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };
    const validateLog = (res) => {
        let d: IResultadoCargaMasiva = res.data;

        dismissUpload();
        reloadInitial();

        if (d.detalle.length == 0) {
            alert("Se actualizó correctamente");
        } else {
            /* Panel resultado expcepciones */
            alert(d.respuesta);

            setTitleDetalleCarga();
            setDetalleLog(d);
            openLog();
        }
    };

    /* Api - Actualizacion masiva */
    const actualizarMasivoKeyUser = () => {
        TransporteService.actualizarMasivoKeyUser(excel)
            .then((res) => {
                if (res.status == 200) {
                    validateLog(res);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const actualizarMasivoTransporte = () => {
        TransporteService.actualizarMasivoTransporte(excel)
            .then((res) => {
                if (res.status == 200) {
                    reloadInitial();
                    dismissUpload();
                    alert("Se actualizó correctamente");
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const actualizarMasivoSSEE = () => {
        TransporteService.actualizarMasivoSSEE(excel)
            .then((res) => {
                if (res.status == 200) {
                    validateLog(res);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };
    /* Function */
    /* Function */

    const setupCombo = () => {
        dataEmpresa();
        dataArea();
    };
    const reloadInitial = (param = filter) => {
        setHidden(false);
        //dataTransporte(param);

    }
    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }


    const actualizarMasivo = () => {
        if (isKeyUser) {
            actualizarMasivoKeyUser();
        } else if (isTransporte) {
            actualizarMasivoTransporte();
        } else if (isSSEE) {
            actualizarMasivoSSEE();
        } else {
            if (isAdmin) {
                switch (optionMasivo) {
                    case 1:
                        actualizarMasivoKeyUser();
                        break;
                    case 2:
                        actualizarMasivoSSEE();
                        break;
                    case 3:
                        actualizarMasivoTransporte();
                        break;
                    default:
                        break;
                }
            }
        }
    };

    /* Event */

    const setTitleDetalleCarga = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Tipo DOI",
                fieldName: "tipoDoi",
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column1",
                name: "N. DOI",
                fieldName: "doi",
                minWidth: 80,
                maxWidth: 100,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column2",
                name: "Observación",
                fieldName: "log",
                minWidth: 1000,
                maxWidth: 1000,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
        ];

        setColumnLog(col);
    };
    // Eventos
    const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
        let { value } = ev?.target as HTMLInputElement;

        switch (type) {
            case "empresa":
                //_openComboEmpresa();

                if (model) {
                    if (model.empresaId != null) {
                        setModel({
                            ...model,
                            empresaId: "",
                        });
                    }
                }

                setFilterEmpresa({
                    ...filterEmpresa,
                    search: value,
                });
                break;
        }
    };
    const _onChangeTextEditarTransporte = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let nameInput = event.currentTarget.getAttribute("name");

                switch (nameInput) {
                    case "placa":
                        setModelItem({
                            ...modelItem,
                            placa: newValue.toUpperCase(),
                        });

                        break;
                    case "observacion":
                        setModelItem({
                            ...modelItem,
                            observacion: newValue.toUpperCase(),
                        });

                        break;
                    case "pasajeros":
                        setModelItem({
                            ...modelItem,
                            pasajeros: parseInt(newValue),
                        });

                        break;

                    case "anioFabricacion":
                        setModelItem({
                            ...modelItem,
                            anioFabricacion: parseInt(newValue.toUpperCase()),
                        });

                        break;
                    case "codigoInterno":
                        setModelItem({
                            ...modelItem,
                            codigoInterno: newValue.toUpperCase(),
                        });

                        break;
                    case "color":
                        setModelItem({
                            ...modelItem,
                            color: newValue.toUpperCase(),
                        });

                        break;
                    case "empresa":
                        setModelItem({
                            ...modelItem,
                            empresaId: newValue.toUpperCase(),
                        });

                        break;
                    case "marca":
                        setModelItem({
                            ...modelItem,
                            marca: newValue,
                        });

                        break;
                    case "modelo":
                        setModelItem({
                            ...modelItem,
                            modelo: newValue,
                        });

                        break;
                    case "tarjetadePropiedad":
                        setModelItem({
                            ...modelItem,
                            tarjetaPropiedad: newValue,
                        });

                        break;
                    case "tipoTransporte":
                        setModelItem({
                            ...modelItem,
                            tipoTransporte: newValue,
                        });

                        break;

                    case "categoria":
                        setModelItem({
                            ...modelItem,
                            categoriaId: newValue,
                        });

                        break;

                    /*case "codigoInterno":
                       setModelItem({
                           ...modelItem,
                           codigoInterno: newValue,
                       });
 
                    break;*/

                    case "estado":
                        setModelItem({
                            ...modelItem,
                            estado: newValue,
                        });

                        break;
                    case "colorEstado":
                        setModelItem({
                            ...modelItem,
                            colorEstado: newValue,
                        });

                        break;

                    default:
                        break;
                }
            }
        }
    };
   

    const _onKeyUpCombo = (e: React.FormEvent<IComboBox>) => {

        let { id } = e?.target as HTMLInputElement;
        let { value } = e?.target as HTMLInputElement;

        switch (id) {
            case "empresa-input":
                _openOptionTransporte()
                setFilterEmpresaTransporte({
                    ...filterEmpresaTransporte,
                    search: value,
                })

                break;/*
            case "gerencia-input":
                _openOptionGerencia()

                setFilteGerencia({
                    ...filteGerencia,
                    search: value,
                })

                break;
            case "superintendencia-input":
                _openOptionSuperintendencia();
                setFilterSuperintendencia({
                    ...filterSuperintendencia,
                    search: value
                })

                break;

            case "roster-input":
                _openOptionRoster();
                setFilterRoster({
                    ...filterRoster,
                    search: value
                })

                break;
            case "lugarMovilizacion-input":
                _openOptionMovilizacion();
                setfilterMovilizacion({
                    ...filterMovilizacion,
                    search: value
                })

                break;
            case "puesto-input":
                _openOptionPuesto();
                setSearchPuesto(value);

                break;
            default:
                break;*/
        }
    }
    const _goBackEditar = () => {
        dismissPanel();
        // dataTablaMarca(search);
        reloadParent()
        setVerLoadingBoton(false);
        setTipoMensaje(3);
        
    }
    const onRenderHeader = React.useCallback(
        () => (
            <>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingLeft: '2em' }} >
                    <ActivityItem
                        style={{
                            marginTop: 3
                        }}
                        activityDescription={[
                            <div>
                                <div className="LayoutPage-demoBlock">
                                    <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size18 }}>Editar Transporte</div>
                                </div>
                            </div>

                        ]}
                        activityIcon={
                            <FontIcon
                                iconName="Contact"
                                style={{
                                    color: ThemeBase.theme.palette.blackTranslucent40,
                                    fontSize: 35,
                                    height: 35,
                                    width: 35,
                                    marginTop: 10
                                    // margin: "0 5px",

                                }}
                            />
                        }
                        comments={[
                            <div className="LayoutPage-demoBlock">
                                <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>Edite datos de los tranporte: {info?.empresa}</div>
                                <br />
                                <br />
                            </div>
                        ]}
                        // timeStamp={}
                        className={ThemeBase.activityClass.exampleRoot}
                    />
                </div>
            </>
        ),
        [openEditar, info?.tipoTransporte],
    );
    const onRenderFooter = React.useCallback(
        () => (
            <>
                {verLoadingBoton == false && (
                    <>
                        <Stack horizontal horizontalAlign="end" styles={stackStyles}>
                            {/* <DefaultButton onClick={onclickBotonDefault}>{textBotonDefault}</DefaultButton> */}
                            <PrimaryButton
                                onClick={ActualizarDatosTransporte}
                                style={{ marginLeft: '12px', width: "100%" }}
                                text="Actualizar"
                            /* iconProps={
                                {
                                    iconName: `${nameIconButton}`
                                }
                            } */
                            />
                        </Stack>
                    </>
                )}
            </>
        ),
        [modelItem, isOpenPanel, dismissEditar, ActualizarDatosTransporte, reloadParent],
    );
    const _getErrorMessagePeriodo = (value: string): string => {
        let msg = '';

        if (value == '') {
            /* msg = `El valor no puede ser vacío`; */
        } else if (isNaN(value as any)) {
            msg = `Debe de seleccionar un año válido`;
        } else if (value.length != 4) {
            msg = `Debe de seleccionar un año válido`;
        } else {
            msg = ``;
        }

        return msg;
    };
    /* Event Filter */
    const _onChangeTextFilter = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {
            if (newValue === '') {
                _onClearFilter();
            } else {
                setFilter({ ...filter })
            }
        }
    };

    const _onPressFilter = (newValue: string) => {
        let f: IFilterTransporteVigente = {
            ..._initialFilter(),
            search: newValue,
        };

        setFilter(f);
        setCurrentPage(_initialPagination().currentPage);
        reloadInitial(f);
    };

    const _onClearFilter = () => {
        let f: IFilterTransporteVigente = {
            ..._initialFilter()
        }

        setFilter(f);
        reloadInitial(f);
    }

    
    return (
        <>
            {/* <DialogBaseSuccess
                hideDialog={hideEmailCierre}
                subText={'Atualizacion correcta'}
                //tipo={DialogType.normal}
                toggleHideDialog={() => {
                    dismissPanel();
                    toggleEmailCierre();
                }}
                //dependency={[]}
              //  confirm={emailCierreDatos}
            ></DialogBaseSuccess> */}
            <Panel
                isOpen={isOpenPanel}
                onDismiss={dismissPanel}
                onRenderHeader={onRenderHeader}
                type={PanelType.medium}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
                isLightDismiss
            >
                <>
                    {tipoMensaje == 1 && (
                        <>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                //onDismiss={p.resetChoice}
                                dismissButtonAriaLabel="Close"
                            //truncated={true}
                            //overflowButtonAriaLabel="See more"
                            >
                                <b>{mensajeTransaccion}</b>{" "}
                            </MessageBar>
                            <br></br>
                        </>
                    )}
                    {tipoMensaje == 2 && (
                        <>
                            <MessageBar
                                actions={
                                    <div>
                                        <MessageBarButton onClick={() => _goBackEditar()}>
                                            Conforme
                                        </MessageBarButton>
                                    </div>
                                }
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                            >
                                {mensajeTransaccion}
                            </MessageBar>
                            <br></br>
                        </>
                    )}
                    {!verLoading && (
                        <form action="">
                            <div className="ms-Grid" dir="ltr">
                                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        
                                        <pre>{JSON.stringify(modelItem, null, 2)}</pre>
                                    </div>
                                </div> */}
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Empresa:"
                                            id="empresa"
                                            autoComplete="off"
                                            options={empresaCombo}
                                            componentRef={empresaTransporteRef}
                                            selectedKey={modelItem?.empresaId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={_onChangeEmpresa}
                                            onKeyUp={(e) => _onUpCombo("empresa", e)}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label='Placa'
                                            placeholder='Placa'
                                            name='placa'
                                            value={modelItem?.placa}
                                            autoComplete='off'
                                            type="placa"
                                            onChange={(e, o) => _onChangeTextEditarTransporte(e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label='Pasajeros'
                                            placeholder='Pasajeros'
                                            name='pasajeros'
                                            type="number"
                                            value={modelItem?.pasajeros?.toString()}
                                            autoComplete='off'
                                            onChange={(e, o) => _onChangeTextEditarTransporte(e, o)}
                                            required />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Marca:"
                                            id="marca"
                                            autoComplete='off'
                                            options={marca}
                                            componentRef={marcaTransporteRef}
                                            selectedKey={modelItem?.marcaId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={_onChangeMarca}
                                            onKeyUp={(e) => _onUpCombo("marca", e)}
                                        //   onBlur={_onBlurMarca}
                                        />
                                        <br />
                                        <ComboBox
                                            label="Modelo:"
                                            id="modelo"
                                            autoComplete='off'
                                            options={modeloCombo}
                                            componentRef={modeloTransporteRef}
                                            required
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            selectedKey={modelItem?.modeloId} 
                                            onKeyUp={(e)=>_onUpCombo("modelo", e)}
                                            onChange={_onChangeModelo}
                                        //  onKeyUp={(e)=>_onUpCombo("modelo", e)}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                        <br />
                                        <ComboBox
                                            label="Tipo Vehiculo:"
                                            id="tipoTransporte"
                                            options={tipoTransporteCombo}
                                            componentRef={tipoTransporteRef}
                                            selectedKey={modelItem?.tipoTransporteId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            //onChange={(e, o) => _onChangeComboEditarTransporte("tipoVehiculo", e, o)}

                                            onChange={_onChangeTipo}
                                            onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                        <br />
                                        <ComboBox
                                            label="Categoria Vehiculo:"
                                            id="categoria"
                                            options={categoriaCombo}
                                            componentRef={categoriaRef}
                                            selectedKey={modelItem?.categoriaId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onKeyUp={_onKeyUpCombo}
                                            onChange={_onChangeCategoria}

                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Año Fabricación"
                                            placeholder='Año Fabricación'
                                            name='anioFabricacion'
                                            onGetErrorMessage={_getErrorMessagePeriodo}
                                            onChange={(e, o) => _onChangeTextEditarTransporte(e, o)}
                                            autoComplete='off'
                                            value={modelItem ? modelItem.anioFabricacion?.toString() : ''} />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Codigo Interno "
                                            placeholder="codigo Interno"
                                            name='codigoInterno'

                                            value={modelItem?.codigoInterno}
                                            onChange={(e, o) => _onChangeTextEditarTransporte(e, o)}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <TextField
                                            label='Observacion'
                                            placeholder='Observacion'
                                            name='observacion'
                                            value={modelItem?.observacion}
                                            autoComplete='off'
                                            onChange={(e, o) => _onChangeTextEditarTransporte(e, o)}
                                            required />
                                    </div>
                                </div>

                            </div>
                        </form>
                    )}
                    {verLoading && (
                        <>
                            <br></br>
                            <ProgressIndicator
                                barHeight={5}
                                label="Enviando información"
                                description="Favor Espere un momento"
                            />
                        </>
                    )}
                </>

            </Panel>

        </>
    );
}
export { PanelActualizarTransporte }
