import { IColumn, Persona, PersonaSize, TooltipHost } from "@fluentui/react";
import { IProgramacionPersona } from "../../../interfaces/IProgramacion";
import { baseImageUrl } from "../../../utils/Helper/helper";

export const ColumnPersonaAdicional = () => {
    let col: IColumn[] = [
        {
            key: 'column1',
            name: 'Foto',
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            isIconOnly: true,
            fieldName: 'foto',
            minWidth: 45,
            maxWidth: 45,
            onRender: (item: IProgramacionPersona) => (
                <TooltipHost content={`Foto`}>
                    <Persona
                        text=''
                        size={PersonaSize.size24}
                        imageUrl={baseImageUrl}
                    />
                </TooltipHost>
            ),
        },
        {
            key: 'column2',
            name: 'Nombre',
            fieldName: 'nombres',
            minWidth: 160,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: IProgramacionPersona) => {
                return (<span style={{ fontSize: "90%" }}>{item.nombres}</span>
                );
            },
        },
        {
            key: 'column3',
            name: 'Identificación',
            fieldName: 'nroDOI',
            minWidth: 80,
            maxWidth: 90,
            isResizable: true,
            data: 'number',
            isPadded: true,
            onRender: (item: IProgramacionPersona) => {
                return <span style={{ fontSize: '95%' }}>{`${item.tipoDocumento} ${item.nroDOI}`}</span>
            },
        },
        {
            key: 'column4',
            name: 'Email',
            fieldName: 'email',
            minWidth: 170,
            maxWidth: 180,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        },
        {
            key: 'column4.1',
            name: 'Nivel',
            fieldName: 'nivel',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        },
        {
            key: 'column4.2',
            name: 'Level',
            fieldName: 'level',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        },
        {
            key: 'column4.3',
            name: 'Gerencia',
            fieldName: 'gerencia',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        },
        {
            key: 'column5',
            name: 'Celular',
            fieldName: 'celular',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column6',
            name: 'Origen',
            fieldName: 'origen',
            minWidth: 70,
            maxWidth: 70,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column6.1',
            name: 'Destino',
            fieldName: 'destino',
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
            isPadded: true,
        },
        /* {
            key: 'column8',
            name: 'Motivo Retiro',
            fieldName: 'motivoRetiro',
            minWidth: 100,
            maxWidth: 110,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        }, */
        /* {
            key: 'column9',
            name: 'Fecha Salida',
            fieldName: 'fechaSalida',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        }, */
        {
            key: 'column10',
            name: 'Empresa Transportista',
            fieldName: 'transporte',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
        },
        // {
        //     key: 'column11',
        //     name: 'Hotel',
        //     fieldName: 'hotel',
        //     minWidth: 70,
        //     maxWidth: 90,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        // },
        // {
        //     key: 'column12',
        //     name: 'Registrado por',
        //     fieldName: 'usuarioRegistra',
        //     minWidth: 150,
        //     maxWidth: 150,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        // },
        // {
        //     key: 'column13',
        //     name: 'Validado por',
        //     fieldName: 'usuarioValida',
        //     minWidth: 150,
        //     maxWidth: 150,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        // },
        // {
        //     key: 'column13.1',
        //     name: 'F. Validación',
        //     fieldName: 'fechaValida',
        //     minWidth: 150,
        //     maxWidth: 150,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        // },
        /* {
            key: 'column14',
            name: 'En Espera',
            fieldName: 'enEspera',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
        }, */
        {
            key: 'column15',
            name: 'Es Adicional',
            fieldName: 'esAdicional',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'string',
        },
        // {
        //     key: 'column16',
        //     name: 'Clasificación',
        //     fieldName: 'clasificacion',
        //     minWidth: 70,
        //     maxWidth: 90,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        // },
        {
            key: 'column17',
            name: 'Proceso-Estado',
            fieldName: 'nivelActual',
            minWidth: 180,
            maxWidth: 180,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        },
        // {
        //     key: 'column18',
        //     name: 'Estado',
        //     fieldName: 'accionExcepcion',
        //     minWidth: 150,
        //     maxWidth: 160,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'number',
        // },
        {
            key: 'column19',
            name: 'Lugar Movilizacion',
            fieldName: 'lugarMovilizacion',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        },
        {
            key: 'column20',
            name: 'Centro Costo',
            fieldName: 'centroCosto',
            minWidth: 150,
            maxWidth: 160,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
        },
    ];

    return col;
}