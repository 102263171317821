import { IComboBox, ICommandBarItemProps, ComboBox, TooltipHost, Persona, PersonaSize, IComboBoxOption, IActivityItemProps, Link, Icon, Panel, PrimaryButton, Stack, PanelType, SelectableOptionMenuItemType, DefaultButton } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { IColumn, Selection } from "@fluentui/react/lib/DetailsList";
import { TableBase } from "../../../components/Table/TableBase"
import { IItemComboBaseDTO, ISearch, IBase, IException } from "../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { IFilterAsignacion, IProgramacionPersona, IFilterPersonaTransporte } from "../../../interfaces/IProgramacion";
import ConfiguracionService from "../../../services/configuracionService";
import OrganigramaService from "../../../services/organigramaService";
import PersonaService from "../../../services/PersonaService";
import ProgramacionBusService from "../../../services/programacionBusService";
import { _initialFilter, _initialPagination, ItemNoneSelect, splitExceptionDetail, baseImageUrl, IndexNone, isObjectEmpty, stackTokens, ItemSelectAll, KeyAllSelect, getSortedData } from "../../../utils/Helper/helper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase"
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { useBoolean } from "@fluentui/react-hooks";
import { ValidarTransporteAccesoHelo } from "./panel/validarTransporteAccesoHelo";
import { useNavigate } from "react-router-dom";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import ProgramacionPersonaService from "../../../services/programacionPersonaService";
import RepoteBase from "../../../components/Generic/ReporteBase";

export const ListarPendienteVuelo = ({ programacionId = '', isBajada = false }) => {
    const navigate = useNavigate();
    const [hidden, setHidden] = useState(false);

    /* Combo */
    const [destino, setDestino] = useState<IItemComboBaseDTO[]>([]);
    const [nivel, setNivel] = useState<IItemComboBaseDTO[]>([]);
    const [level, setLevel] = useState<IItemComboBaseDTO[]>([]);
    const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
    const [gerencia, setGerencia] = useState<IItemComboBaseDTO[]>([]);

    /* Filter */
    const [filter, setFilter] = useState<IFilterAsignacion>({
        ..._initialFilter(),
        programacionId: programacionId,
        isPendiente: true,
    });
    /* Report Telerik */
    const [isOpenReport, { setTrue: openReport, setFalse: dismissReport }] = useBoolean(false);

    const [filterNivel, setFilterNivel] = useState<ISearch>(_initialFilter);
    const [filterLevel, setFilterLevel] = useState<ISearch>(_initialFilter);
    const [filterDestino, setFilterDestino] = useState<ISearch>(_initialFilter);
    const [filterEmpresa, setFilterEmpresa] = useState<ISearch>(_initialFilter);
    const [filterGerencia, setFilterGerencia] = useState<ISearch>(_initialFilter);

    /* Ref */
    const refNivel = React.useRef<IComboBox>(null);
    const refLevel = React.useRef<IComboBox>(null);
    const refDestino = React.useRef<IComboBox>(null);
    const refEmpresa = React.useRef<IComboBox>(null);
    const refGerencia = React.useRef<IComboBox>(null);

    const _openNivel = React.useCallback(() => refNivel.current?.focus(true), []);
    const _openLevel = React.useCallback(() => refLevel.current?.focus(true), []);
    const _openCampamento = React.useCallback(() => refDestino.current?.focus(true), []);
    const _openEmpresa = React.useCallback(() => refEmpresa.current?.focus(true), []);
    const _openGerencia = React.useCallback(() => refGerencia.current?.focus(true), []);

    /* Data & Colum */
    const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);
    const [grupoInicial, setGrupoInicial] = useState<string | undefined>(undefined);
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Filter */
    const [isOpenFilter, { setTrue: openFilter, setFalse: dismissFilter }] = useBoolean(false);

    /* Event Multiselect */
    const [selectedKeyPsGroup, setSelectedKeyPsGroup] = React.useState<string[]>([]);
    const [selectedKeyLevel, setSelectedKeyLevel] = React.useState<string[]>([]);

    const selectableOptionPsGroup = nivel.filter(
        option =>
            (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
    );

    const selectableOptionLevel = level.filter(
        option =>
            (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
    );

    /* Panel Aprobar */
    const [isOpenValidar, { setTrue: openValidar, setFalse: dismissValidar }] = useBoolean(false);

    /* Selection */
    const [sel, setSel] = useState<any[]>([]);

    const selection = new Selection({
        onSelectionChanged: () => {
            let d: any[] = selection.getSelection();

            let t: IActivityItemProps[] = d.map((x) => {
                return {
                    key: `id_${x.programacionPersonaId}`,
                    activityDescription: [
                        <Link key={x.programacionPersonaId} className={ThemeBase.activityClass.nameText}>
                            {x.nombres}
                        </Link>,
                        <span key={`2_${x.programacionPersonaId}`}> </span>,
                        <span
                            key={`3_${x.programacionPersonaId}`}
                            className={ThemeBase.activityClass.nameText}
                        >
                        </span>,
                    ],
                    activityIcon: <Icon iconName={'ReminderPerson'} />,
                    timeStamp: `DNI: ${x.nroDOI}`,
                    programacionPersonaId: x.programacionPersonaId,
                    programacionPersonaBusId: `${x.programacionPersonaBusId}`,
                    horaHelo: x.horaProgramadaHelo,
                    grupoId: x.grupoId,
                    indiceGrupo: x.indiceGrupo,
                };
            });

            setSel(t);
        },
        getKey: (item: any) => item.programacionPersonaBusId
    });

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    /* Hook */
    useEffect(() => {
        if (programacionId != null) {
            reloadInitial();
        }
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    useEffect(() => {
        if (filterNivel.search != null) {
            dataNivel();
        }
    }, [filterNivel]);

    useEffect(() => {
        if (filterLevel.search != null) {
            dataLevel();
        }
    }, [filterLevel])

    useEffect(() => {
        if (filterDestino.search != null) {
            dataDestino();
        }
    }, [filterDestino]);

    useEffect(() => {
        if (filterEmpresa.search != null) {
            dataEmpresa();
        }
    }, [filterEmpresa]);

    useEffect(() => {
        if (filterGerencia.search != null) {
            dataGerencia();
        }
    }, [filterGerencia]);

    const dataNivel = () => {
        ConfiguracionService.allNivel(filterNivel).then(res => {
            let t: IItemComboBaseDTO[] = res.data;
            t.unshift(ItemSelectAll());

            setNivel(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataLevel = () => {
        ConfiguracionService.allLevel(filterLevel).then(res => {
            let t: IItemComboBaseDTO[] = res.data;
            t.unshift(ItemSelectAll());

            setLevel(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataDestino = () => {
        PersonaService.getComboCampamento1(filterDestino).then(res => {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map(x => {
                return ({
                    key: x.id,
                    text: x.nombre,
                })
            });

            t.unshift(ItemNoneSelect());

            setDestino(t);
        })
            .catch((e: Error) => {
                console.log(e);
            });;
    }

    const dataEmpresa = () => {
        setEmpresa([]);
        ConfiguracionService.allEmpresa(filterEmpresa)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemNoneSelect());

                    setEmpresa(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataGerencia = () => {
        setGerencia([]);
        OrganigramaService.organigramaByTipo('01', filterGerencia)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemNoneSelect());

                    setGerencia(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataPendiente = (param = filter) => {
        if (programacionId != '') {
            setData(_initialDataCollection);

            if (!isBajada) {
                ProgramacionBusService.allPendienteVuelo(param)
                    .then((res) => {
                        if (res.status == 200) {
                            let d: IDataCollection<IProgramacionPersona> = res.data;

                            /* Primer grupo pre asignado */
                            let ini = undefined;
                            if (d.items.length > 0) {
                                let ord = getSortedData(d.items, 'indiceGrupo', 1)[0].grupoId;
                                ini = ord;
                            }

                            setGrupoInicial(ini);

                            setData(d);
                            setHidden(true);
                            setTotal(res.data.total);
                        }
                    })
                    .catch((e: IException) => {
                        alert(splitExceptionDetail(e));
                        setHidden(true);
                    });
            } else {
                ProgramacionPersonaService.allBajadaPorAsignarHelo(param)
                    .then((res) => {
                        if (res.status == 200) {
                            let d: IDataCollection<IProgramacionPersona> = res.data;

                            /* Primer grupo pre asignado */
                            let ini = undefined;
                            if (d.items.length > 0) {
                                let ord = getSortedData(d.items, 'indiceGrupo', 1)[0].grupoId;
                                ini = ord;
                            }

                            setGrupoInicial(ini);

                            setData(d);
                            setHidden(true);
                            setTotal(res.data.total);
                        }
                    })
                    .catch((e: IException) => {
                        alert(splitExceptionDetail(e));
                        setHidden(true);
                    });
            }
        }
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => { navigate(`${ConfigHelper.urlBase()}/empresa.transporte`) },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },
            {
                ...IconHelper.ICON_CHECK,
                text: 'Asignar',
                onClick: () => {
                    if (sel.length == 0) {
                        return alert("Debe seleccionar al menos un item");
                    }

                    openValidar();
                },
            },
        ];

        return _items;
    };

    const _rigthBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: () => {
                    openReport()
                }
            },
            {
                ...IconHelper.ICON_FILTER,
                onClick: () => {
                    openFilter();
                },
            },
        ];

        return _items;
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setTitleColumn();
        dataNivel();
        dataLevel();
        dataDestino();
        dataEmpresa();
        dataGerencia();
        dataPendiente(param);
    }

    const _clearSearch = (type: string) => {
        switch (type) {
            case 'nivel':
                setFilterNivel({
                    ...filterNivel,
                    search: '',
                })
                break;
            case 'level':
                setFilterLevel({
                    ...filterLevel,
                    search: '',
                })
                break;
            case 'destino':
                setFilterDestino({
                    ...filterDestino,
                    search: '',
                });
                break;
            case 'empresa':
                setFilterEmpresa({
                    ...filterEmpresa,
                    search: '',
                });
                break;
            case 'gerencia':
                setFilterGerencia({
                    ...filterGerencia,
                    search: '',
                })

                break;
            default:
                break;
        }
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Foto",
                ariaLabel:
                    "Column operations for File type, Press to sort on File type",
                iconName: "-",
                isIconOnly: true,
                fieldName: "foto",
                minWidth: 30,
                maxWidth: 30,
                onRender: (item: IProgramacionPersona) => (
                    <TooltipHost content={`Foto`}>
                        <Persona
                            text=""
                            size={PersonaSize.size24}
                            imageUrl={baseImageUrl}
                        />
                    </TooltipHost>
                ),
            },
            {
                key: "column2",
                name: "Nombre",
                fieldName: "nombres",
                minWidth: 170,
                maxWidth: 170,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span style={{ fontSize: '90%' }}>{item.nombres}</span>
                    );
                },
            },
            {
                key: "column3",
                name: "Identificación",
                fieldName: "nroDOI",
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: "number",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span
                            style={{ fontSize: "95%" }}>{`${item.tipoDocumento} ${item.nroDOI}`}</span>
                    );
                },
            },
            {
                key: 'column4',
                name: 'Nivel',
                fieldName: 'nivel',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Level',
                fieldName: 'level',
                minWidth: 30,
                maxWidth: 30,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Gerencia',
                fieldName: 'gerencia',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column7',
                name: 'Grupo',
                fieldName: 'grupo',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column8',
                name: 'Prog. Helo',
                fieldName: 'horaProgramadaHelo',
                minWidth: 60,
                maxWidth: 60,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column9',
                name: 'Hora Llega',
                fieldName: 'horaLlega',
                minWidth: 60,
                maxWidth: 60,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column10",
                name: "Fecha Ingreso",
                fieldName: "fechaIngreso",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: "column11",
                name: "Antiguedad",
                fieldName: "antiguedad",
                minWidth: 180,
                maxWidth: 180,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            {
                key: 'column12',
                name: 'Origen',
                fieldName: 'origen',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column13',
                name: 'Destino',
                fieldName: 'destino',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: 'column14',
                name: 'Empresa Transportista',
                fieldName: 'empresaTransporte',
                minWidth: 180,
                maxWidth: 180,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
            },
            {
                key: "column15",
                name: "Empresa Persona",
                fieldName: "empresaPersona",
                minWidth: 180,
                maxWidth: 180,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
            {
                key: "column16",
                name: "Proceso-Estado",
                fieldName: "nivelActual",
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: "string",
            },
        ];

        setColumn(col);
    };

    /* Event Filter */
    const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
        let { value } = ev?.target as HTMLInputElement;

        switch (type) {
            case 'nivel':
                _openNivel();

                if (filter.nivelId != null) {
                    setFilter({
                        ...filter,
                        nivelId: null,
                    })

                    reloadInitial();
                }

                setFilterNivel({
                    ...filterNivel,
                    search: value,
                })
                break;
            case 'level':
                _openLevel();

                if (filter.levelId != null) {
                    setFilter({
                        ...filter,
                        levelId: null,
                    })

                    reloadInitial();
                }

                setFilterLevel({
                    ...filterLevel,
                    search: value,
                })
                break;
            case 'destino':
                _openCampamento();

                if (filter.campamentoId != null) {
                    setFilter({
                        ...filter,
                        campamentoId: null,
                    });
                }

                setFilterDestino({
                    ...filterDestino,
                    search: value,
                })
                break;
            case 'empresa':
                _openEmpresa();

                if (filter.empresaId != null) {
                    setFilter({
                        ...filter,
                        empresaId: null,
                    });
                }

                setFilterEmpresa({
                    ...filterEmpresa,
                    search: value,
                })
                break;
            case 'gerencia':
                _openGerencia();

                if (filter.gerenciaId != null) {
                    setFilter({
                        ...filter,
                        gerenciaId: null,
                    });
                }

                setFilterGerencia({
                    ...filterGerencia,
                    search: value,
                })
                break;
            default:
                break;
        }
    };

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                let k: string | null = null;

                let isNone = option.key == IndexNone;

                if (isNone) {
                    _clearSearch(type);
                }

                switch (type) {
                    case 'nivel':
                        if (!isNone) {
                            k = String(option.key);
                        }

                        let fNiv: IFilterAsignacion = ({
                            ...filter,
                            nivelId: k
                        })

                        setFilter(fNiv);
                        reloadInitial(fNiv);
                        break;
                    case 'level':
                        if (!isNone) {
                            k = String(option.key);
                        }

                        let fLev: IFilterAsignacion = ({
                            ...filter,
                            levelId: k
                        })

                        setFilter(fLev);
                        reloadInitial(fLev);
                        break;
                    case 'destino':
                        if (!isNone) {
                            k = String(option.key);
                        }

                        let fDes: IFilterAsignacion = ({
                            ...filter,
                            campamentoId: k
                        })

                        setFilter(fDes);
                        break;
                    case 'empresa':
                        if (!isNone) {
                            k = String(option.key);
                        }

                        let fEmp: IFilterAsignacion = ({
                            ...filter,
                            empresaId: k,
                        })

                        setFilter(fEmp);
                        break;
                    case 'gerencia':
                        if (!isNone) {
                            k = String(option.key);
                        }

                        let fGer: IFilterAsignacion = ({
                            ...filter,
                            gerenciaId: k,
                        })

                        setFilter(fGer);
                        break;
                    default:
                        break;
                }
            }
        }
    };

    /* Event Tab */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterPersonaTransporte = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    const _onBlurCombo = (type: string) => {
        switch (type) {
            case 'nivel':
                if ((filter.nivelId == '' || filter.nivelId == undefined) && filter.nivelId != null) {
                    setFilter({
                        ...filter,
                        nivelId: null,
                    });
                }
                break;
            case 'level':
                if ((filter.levelId == '' || filter.levelId == undefined) && filter.levelId != null) {
                    setFilter({
                        ...filter,
                        levelId: null,
                    });
                }
                break;
            case 'destino':
                if ((filter.campamentoId == '' || filter.campamentoId == undefined) && filter.campamentoId != null) {
                    setFilter({
                        ...filter,
                        campamentoId: null,
                    });
                }
                break;
            case 'empresa':
                if ((filter.empresaId == '' || filter.empresaId == undefined) && filter.empresaId != null) {
                    setFilter({
                        ...filter,
                        empresaId: null,
                    });
                }
                break;
            case 'gerencia':
                if ((filter.gerenciaId == '' || filter.gerenciaId == undefined) && filter.gerenciaId != null) {
                    setFilter({
                        ...filter,
                        gerenciaId: null,
                    });
                }
                break;
            default:
                break;
        }
    };

    /* Event MultiSelect Nivel & Level v2 */
    const onChangeNivel = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeyPsGroup.filter(key => key !== KeyAllSelect);
        const selectAllState = currentSelectedOptionKeys.length === selectableOptionPsGroup.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setSelectedKeyPsGroup([])
                    : setSelectedKeyPsGroup(['selectAll', ...selectableOptionPsGroup.map(o => o.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === selectableOptionPsGroup.length) {
                    updatedKeys.push(KeyAllSelect);
                }
                setSelectedKeyPsGroup(updatedKeys);
            }
        }
    };

    const onChangeLevel = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeyLevel.filter(key => key !== KeyAllSelect);
        const selectAllState = currentSelectedOptionKeys.length === selectableOptionLevel.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                selectAllState
                    ? setSelectedKeyLevel([])
                    : setSelectedKeyLevel([KeyAllSelect, ...selectableOptionLevel.map(o => o.key as string)]);
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === selectableOptionLevel.length) {
                    updatedKeys.push(KeyAllSelect);
                }
                setSelectedKeyLevel(updatedKeys);
            }
        }
    };

    /* Footer Filter */
    const onRenderFooterFilter = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={() => {
                    let f: IFilterAsignacion = {
                        ...filter,
                        nivelLst: selectedKeyPsGroup.filter(x => x != KeyAllSelect),
                        levelLst: selectedKeyLevel.filter(x => x != KeyAllSelect),
                    }

                    reloadInitial(f);
                    dismissFilter();
                }} styles={ThemeBase.panelButtonStyle}>
                    Buscar
                </PrimaryButton>
                <DefaultButton onClick={() => {
                    let f: IFilterAsignacion = {
                        ..._initialFilter(),
                        programacionId: programacionId,
                    }

                    setFilter(f);
                    setSelectedKeyPsGroup([]);
                    setSelectedKeyLevel([]);
                    reloadInitial(f);
                    dismissFilter();
                }}>Limpiar</DefaultButton>
            </div>
        ),
        [programacionId, dismissFilter, sel, filter, selectedKeyPsGroup, selectedKeyLevel]
    );

    return (
        <>
            <TableBase leftBar={_leftBar()} rigthBar={_rigthBar()} column={column} data={data.items} style={ThemeBase.notPaddingTableBase} pagination={pager} isLoading={!hidden} selection={selection}></TableBase>
            <ValidarTransporteAccesoHelo programacionId={programacionId} grupoInicial={grupoInicial} isBajada={isBajada} isOpen={isOpenValidar} dismiss={dismissValidar} sel={sel} reloadParent={reloadInitial}></ValidarTransporteAccesoHelo>

            {/* Reporte */}
            {/* Report personas programadas en su totalidad - similar admin */}
            <RepoteBase
                // headerName={`Reporte Pendiente Helos`}
                NameReport={"MOVReporteHelosPendientes.trdx"}
                Parameters={{ ProgramacionId: programacionId }}
                openTelerik={isOpenReport}
                dismissTelerik={dismissReport}
            ></RepoteBase>
            {/* Nivel & Level
            Destino & Gerencia & empresa persona & gerencia */}
            {/* Panel Filter */}
            <Panel
                type={PanelType.medium}
                isOpen={isOpenFilter}
                onDismiss={dismissFilter}
                headerText='Filtro Avanzado'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooterFilter}
                isFooterAtBottom={true}
            >
                <div className="ms-Grid" dir="ltr" style={{ paddingTop: '20%' }}>
                    <Stack tokens={stackTokens}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                    label="PsGroup"
                                    multiSelect
                                    options={nivel}
                                    selectedKey={selectedKeyPsGroup}
                                    onChange={onChangeNivel}
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                    label="Level"
                                    multiSelect
                                    options={level}
                                    selectedKey={selectedKeyLevel}
                                    onChange={onChangeLevel}
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                />
                            </div>

                            {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                    label='Nivel'
                                    componentRef={refNivel}
                                    placeholder='Nivel'
                                    allowFreeform
                                    autoComplete='off'
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                    options={nivel}
                                    onKeyUp={(e) => _onUpCombo('nivel', e)}
                                    selectedKey={filter.nivelId}
                                    onChange={(e, o) => _onChangeCombo('nivel', e, o)}
                                    onBlur={() => _onBlurCombo('nivel')}
                                    required
                                />
                            </div> */}

                            {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                    label='Level'
                                    componentRef={refLevel}
                                    placeholder='Level'
                                    allowFreeform
                                    autoComplete='off'
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                    options={level}
                                    onKeyUp={(e) => _onUpCombo('level', e)}
                                    selectedKey={filter.levelId}
                                    onChange={(e, o) => _onChangeCombo('level', e, o)}
                                    onBlur={() => _onBlurCombo('level')}
                                    required
                                />
                            </div> */}
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg6">
                                <ComboBox
                                    label='Destino'
                                    componentRef={refDestino}
                                    placeholder='Destino'
                                    allowFreeform
                                    autoComplete='off'
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                    options={destino}
                                    onKeyUp={(e) => _onUpCombo('destino', e)}
                                    selectedKey={filter.campamentoId}
                                    onChange={(e, o) => _onChangeCombo('destino', e, o)}
                                    onBlur={() => _onBlurCombo('destino')}
                                    required
                                />
                            </div>

                            <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg6">
                                <ComboBox
                                    label='Gerencia'
                                    componentRef={refGerencia}
                                    placeholder='Gerencia'
                                    allowFreeform
                                    autoComplete='off'
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                    options={gerencia}
                                    onKeyUp={(e) => _onUpCombo('gerencia', e)}
                                    selectedKey={filter.gerenciaId}
                                    onChange={(e, o) => _onChangeCombo('gerencia', e, o)}
                                    onBlur={() => _onBlurCombo('gerencia')}
                                    required
                                />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <ComboBox
                                    label='Empresa Persona'
                                    componentRef={refEmpresa}
                                    placeholder='Empresa Persona'
                                    allowFreeform
                                    autoComplete='off'
                                    useComboBoxAsMenuWidth
                                    calloutProps={{ directionalHintFixed: true }}
                                    options={empresa}
                                    onKeyUp={(e) => _onUpCombo('empresa', e)}
                                    selectedKey={filter.empresaId}
                                    onChange={(e, o) => _onChangeCombo('empresa', e, o)}
                                    onBlur={() => _onBlurCombo('empresa')}
                                    required
                                />
                            </div>
                        </div>
                    </Stack>
                </div>
            </Panel>
        </>
    )
}