import { ActivityItem, IActivityItemProps, Icon, Link, Panel, PanelType } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { IException } from "../../../interfaces/base/IBase";
import { IActividadLog } from "../../../interfaces/IAccesoPersona";
import ProgramacionPersonaService from "../../../services/programacionPersonaService";
import { splitExceptionDetail } from "../../../utils/Helper/helper";
import ThemeBase from "../../../utils/Theme/themeBase";

export const LogActividad = ({ programacionPersonaId = '', isOpen = false, dismiss = () => { } }) => {
    const [actividad, setActividad] = useState<IActividadLog[]>([]);
    const [log, setLog] = useState<any[]>([]);

    /* Hook */
    useEffect(() => {
        if (isOpen) {
            dataActividad();
        }
    }, [isOpen]);

    useEffect(() => {
        let t = actividad.map((x) => {
            return {
                key: `id_${x.actividadId}`,
                activityDescription: [
                    <Link
                        key={`link_${x.actividadId}`}
                        className={ThemeBase.activityClass.nameText}
                    >
                        {x.proceso} - {x.estado}
                    </Link>,
                ],
                activityIcon: <Icon iconName={'TaskLogo'} />,
                comments: [
                    <Link
                        key={2}
                        className={ThemeBase.activityClass.nameText}
                        style={{
                            color: 'black'
                        }}
                    >
                        {x.usuario}
                    </Link>,
                    <br />,
                    <span key={3}>{x.comentario}</span>,
                ],
                timeStamp: `${x.fecha}`,
            }
        });

        setLog(t);
    }, [actividad]);

    /* Api */
    const dataActividad = () => {
        ProgramacionPersonaService.actividadLog(programacionPersonaId)
            .then((res) => {
                if (res.status == 200) {
                    setActividad(res.data);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    }

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <></>
        ),
        [programacionPersonaId, actividad, log, isOpen, dismiss]
    );

    return (
        <>
            <Panel
                type={PanelType.medium}
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText='Actividades'
                closeButtonAriaLabel='Close'
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <>
                    {log.map((item: { key: string | number }) => (
                        <ActivityItem
                            {...item}
                            key={item.key}
                            className={ThemeBase.activityClass.exampleRoot}
                        />
                    ))}
                </>
            </Panel>
        </>
    )
}