import { ComboBox, DialogType, IColumn, IComboBox, IComboBoxOption, ICommandBarItemProps, Icon, IconButton, Modal, Stack, TextField, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { HeadingBar } from "../../../components/Generic/HeadingBar"
import { TableBase } from "../../../components/Table/TableBase"
import { IException } from "../../../interfaces/base/IBase";
import { ICreateRoster, IRoster } from "../../../interfaces/IRoster";
import RosterService from "../../../services/rosterService";
import { baseSearch, splitExceptionDetail, stackTokens, stackTokensHorizontal, _initialFilter } from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useBoolean } from '@fluentui/react-hooks';
import { ModalHelper } from "../../../utils/Helper/modalHelper";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import { IComboOptions } from "../../../components/Generic/combo";
import { DialogBase } from "../../../components/Dialog/DialogBase";
import PersonaService from "../../../services/PersonaService";
import { useSearchParams } from "react-router-dom";
import { Ifilter } from "../../../interfaces/IFilter";
import GuardiaService from "../../../services/guardiaService";
import ProgramacionService from "../../../services/ProgramacionService";

const initialRoster = () => {
    let t: ICreateRoster = {
        codigo: null
    };

    return t;
}

export const ListarRoster = () => {
    const [roster, setRoster] = useState<IRoster[]>([]);
    const [hidden, setHidden] = useState(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [form, setForm] = useState<ICreateRoster>(initialRoster());
    const [guardia, setGuardia] = useState<IComboOptions[]>([]);
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    /* Modal */
    const [isOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    /* Dialog */
    const [hideEliminar, { toggle: toggleEliminar }] = useBoolean(true);
    const [selId, setSelId] = useState<string | undefined>(undefined);

    /* Filter */
    const [searchParam, setSearchParam] = useSearchParams();
    const [filter, setFilter] = useState<Ifilter>(_initialFilter);

    /* Hook */
    useEffect(() => {
        setTitleColumn();
        setup();
    }, []);

    useEffect(() => {
        if (filter.search != null) {
            setup();
        }
    }, [filter]);

    /* Api */
    const dataRoster = () => {
        setRoster([]);
        RosterService.all(filter).then(res => {
            setRoster(res.data);
            setHidden(true);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        })
    }

    const dataGuardia = () => {
        PersonaService.comboGuardiaProgramacion(_initialFilter())
            .then((response: any) => {
                if (response.status == 200) {
                    setGuardia(response.data);
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const crearRoster = () => {
        RosterService.crearRoster(form).then(res => {
            if (res.status == 200) {
                alert('Se registró correctamente');
                hideModal();
                setup();
            }
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        })
    }

    const eliminarRoster = () => {
        if (form.id) {
            RosterService.elimminarRoster(form.id).then(res => {
                if (res.status == 200) {
                    alert('Se eliminó correctamente');
                    toggleEliminar();
                    setup();
                }
            }).catch((e: IException) => {
                alert(splitExceptionDetail(e));
                setHidden(true);
            })
        }
    }
    /* Event */


    /* Function */
    const setup = () => {
        dataRoster();
        dataGuardia();
    }

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Codigo',
                fieldName: 'codigo',
                minWidth: 130,
                maxWidth: 130,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Guardia',
                fieldName: 'guardia',
                minWidth: 250,
                maxWidth: 250,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Turno Día',
                fieldName: 'dia',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IRoster) => {
                    return <span>{item.dia ? 'Si' : 'No'}</span>
                }
            },
            {
                key: 'column5',
                name: 'Turno Noche',
                fieldName: 'noche',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IRoster) => {
                    return <span>{item.noche ? 'Si' : 'No'}</span>
                }
            },
            {
                key: 'column6',
                name: 'Opciones',
                fieldName: '',
                minWidth: 110,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                onRender: (item: IRoster) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <>
                                <TooltipHost content={'Editar'}>
                                    <Icon iconName='Edit'
                                        style={{ cursor: 'pointer' }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            setForm({
                                                ...item,
                                            })
                                            setIsAdd(false);
                                            setIsEdit(true);
                                            showModal();
                                        }}
                                    />
                                </TooltipHost>

                                <TooltipHost content={`Eliminar`}>
                                    <Icon iconName='Delete'
                                        style={{ cursor: 'pointer' }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            setForm(item);
                                            setSelId(item.id);
                                            toggleEliminar();
                                        }}
                                    />
                                </TooltipHost>
                            </>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_ADD,
                onClick: () => {
                    setForm(initialRoster);
                    setIsAdd(true);
                    setIsEdit(false);
                    showModal();
                },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: setup
            },
        ];
        return _items;
    }

    const _getFilter = () => {
        return searchParam.get(baseSearch) ?? '';
    }

    /* Event */
    const _onChangeText = (type: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                switch (type) {
                    case 'codigo':
                        setForm({
                            ...form,
                            codigo: newValue,
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'guardia':
                        setForm({
                            ...form,
                            guardiaProgramacionId: option.key.toString(),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    }

    /* Event Filter */
    const _onChangeSearch = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        console.log(newValue);

        if (newValue != undefined) {
            setSearchParam({
                [baseSearch]: newValue
            });
        }
    };

    const _onPressSearch = (newValue: string) => {
        setFilter({
            ...filter,
            search: newValue,
        })
    };

    const _onClearSearch = () => {
        setFilter({
            ...filter,
            search: '',
        })

        setSearchParam({
            [baseSearch]: ''
        });
    }

    return (
        <>
            <HeadingBar
                title='Rosters'
                subTitle='Gestión de Rosters'
                searchChange={_onChangeSearch}
                searchValue={_getFilter()}
                searchHandler={_onPressSearch}
                onClearHandler={_onClearSearch}
            ></HeadingBar>

            <TableBase leftBar={_leftBar()} column={column} data={roster} isLoading={!hidden}></TableBase>

            {/* Modal Registrar / Editar */}
            <Modal
                isOpen={isOpen}
                onDismiss={hideModal}
                isBlocking={false}
                containerClassName={ModalHelper.modalStyle.container}
            >
                <form>
                    <div className={ModalHelper.modalStyle.header}>
                        <span id={''}>{`${form.id ? 'Actualizar' : 'Nuevo'} Roster`}</span>
                        <IconButton
                            styles={ModalHelper.modalIconButtonStyles}
                            iconProps={ModalHelper.modalCancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={hideModal}
                        />
                    </div>

                    <div className={ModalHelper.modalStyle.body} style={{ minWidth: 500 }}>
                        <Stack tokens={stackTokens}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md5 ms-lg5">
                                        <TextField
                                            label='Código'
                                            placeholder='Código'
                                            value={form.codigo ?? ''}
                                            onChange={(e, o) => _onChangeText('codigo', e, o)}
                                            autoComplete="off" />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md7 ms-lg7">
                                        <ComboBox
                                            label='Guardia'
                                            autoComplete='off'
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={guardia}
                                            selectedKey={form ? form.guardiaProgramacionId : ''}
                                            onChange={(e, o) => _onChangeCombo('guardia', e, o)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <ButtonForm back={hideModal} register={crearRoster}></ButtonForm>
                        </Stack>
                    </div>
                </form>
            </Modal>

            <DialogBase isHidden={hideEliminar} subTitle='El Roster será eliminado permanentemente' tipo={DialogType.normal} toggleHidden={toggleEliminar} dependency={[]} confirm={eliminarRoster}></DialogBase>
        </>
    )
}