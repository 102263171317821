import { DefaultButton, IStackStyles, PrimaryButton, Stack } from "@fluentui/react"

const stackTokens = { childrenGap: 10 };
const stackStyles: IStackStyles = { root: { padding: 20 } };

const goBack = () => { }
const goRegister = () => { }

export const ButtonForm = ({ back = goBack, register = goRegister, disabledRegister = false, textRegister = 'Registrar', isBack = true }) => {
    return (
        <>
            <Stack horizontal horizontalAlign="end" verticalAlign="end" tokens={stackTokens} styles={stackStyles}>
                {isBack &&
                    <DefaultButton text="Cancelar" onClick={back} allowDisabledFocus />
                }
                <PrimaryButton
                    text={textRegister}
                    onClick={register}
                    allowDisabledFocus
                    disabled={disabledRegister} />
            </Stack>
        </>
    )
}