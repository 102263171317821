import { ISearch } from "../interfaces/base/IBase";
import { IFilterTransporteVigente } from "../interfaces/filter/IFilterTransporte";
import { Ifilter } from "../interfaces/IFilter";
import { IEliminarProgramacionBus } from "../interfaces/IProgTransporte";
import { IUpdateTemporada } from "../interfaces/ITemporada";
import { ICreateCategoriaTransporte, ICreateMarca, ICreateModelo, IEditarRutaTransporte, IEditTransporte, IUpdateTransporte, IValidarTransporte } from "../interfaces/ITransporte";
import { fetchMovilizacion } from "./http-common";

const urlMarca = 'Marca';
const urlModelo = 'Modelo';
const urlTipo = 'TipoTransporte'
const urlCategoria = 'CategoriaTransporte';
const urlCatalogoAdjunto = 'CatalogoAdjuntoTransporte';
const urlTransporte = 'Transporte';
const urlProgramacionBus = 'AccesoProgramadoProgramacionBus';
const urlEmpresa = 'Empresa';


const transporteVigente = (filter: IFilterTransporteVigente) => {
    return fetchMovilizacion.get(`${urlTransporte}/Vigente`, { params: filter });
};

const allMarca = () => {
    return fetchMovilizacion.get(`${urlMarca}`);
}

const postMarca = (data: ICreateMarca) => {
    return fetchMovilizacion.post(`${urlMarca}/crear`, data);
}
const postTransporte = (data: any) => {
    return fetchMovilizacion.post(`transporte`, data);
};

const tablaMarca = (search: string) => {
    return fetchMovilizacion.get(`${urlMarca}/Tabla?skip=0&take=50&search=${search}`);
}

const putMarca = (data: any) => {
    return fetchMovilizacion.put(`${urlMarca}/Actualizar`, data);
}

const deleteMarca = (id: string) => {
    return fetchMovilizacion.delete(`${urlMarca}/Eliminar/${id}`);
}

const allModeloByMarcaId = (id: string) => {
    return fetchMovilizacion.get(`${urlModelo}/${id}`);
}

const postModelo = (data: ICreateModelo) => {
    return fetchMovilizacion.post(`${urlModelo}/crear`, data);
}

const tablaModelo = (search: string) => {
    return fetchMovilizacion.get(`${urlModelo}/Tabla?skip=0&take=50&search=${search}`);
}

const putModelo = (data: any) => {
    return fetchMovilizacion.get(`${urlModelo}/Actualizar`, data);
}

const deleteModelo = (id: string) => {
    return fetchMovilizacion.delete(`${urlModelo}/Eliminar/${id}`);
}

const allTipo = () => {
    return fetchMovilizacion.get(`${urlTipo}`);
}

const allCategoria = () => {
    return fetchMovilizacion.get(`${urlCategoria}`);
}

const postCategoriaVehiculo = (data: ICreateCategoriaTransporte) => {
    return fetchMovilizacion.post(`${urlCategoria}/crear`, data);
}

const putCategoriaVehiculo = (data: any) => {
    return fetchMovilizacion.put(`${urlCategoria}/Actualizar`, data);
}

const deleteCategoriaVehiculo = (id: string) => {
    return fetchMovilizacion.delete(`${urlCategoria}/Eliminar/${id}`);
}

const tablaCategoriaVehiculo = (search: string) => {
    return fetchMovilizacion.get(`${urlCategoria}/Tabla?skip=0&take=50&search=${search}`);
}

const catalogoAdjunto = () => {
    return fetchMovilizacion.get(`${urlCatalogoAdjunto}`);
}

const pendienteAprobacion = (filter: Ifilter) => {
    return fetchMovilizacion.get(`${urlTransporte}/Pendiente`, { params: filter });
}




const detalleTransporteById = (id: any) => {
    return fetchMovilizacion.get(`${urlTransporte}/${id}`);
}





const actualizarTransporte = (data: IEditTransporte) => {
    return fetchMovilizacion.put(`${urlTransporte}`, data);
}

const estado = () => {
    return fetchMovilizacion.get(`${urlTransporte}/estado`);
}

const validarTransporte = (data: IValidarTransporte) => {
    return fetchMovilizacion.post(`${urlTransporte}/Validar`, data);
}

const reporteVehiculos = () => {
    return fetchMovilizacion.get(
        `Transporte/ReporteVehiculo`,
        {
            responseType: "blob"
        }
    );
}

const reporteTrasportePersona = (programacionId: any) => {

    return fetchMovilizacion.get(

        `AccesoProgramadoTransporte/ReporteTransportePersona?ProgramacionId=${programacionId}`,
        {
            responseType: "blob"
        }

    );
}

/* Rutas */
const ActualizarRuta = (data: IEditarRutaTransporte) => {
    return fetchMovilizacion.put(`${urlProgramacionBus}`, data);
}

const EliminarRuta = (data: IEliminarProgramacionBus) => {
    return fetchMovilizacion.post(`${urlProgramacionBus}/RetirarBus`, data);
}

/* Empresa Transportista */
const allSocioTransportista = () => {
    return fetchMovilizacion.get(`${urlEmpresa}/SocioTransportista`);
}

const allSocioTrByEmpresaContratista = (id: string) => {
    return fetchMovilizacion.get(`${urlEmpresa}/SocioTransportista/${id}`);
}

/* Email Pendiente Aprobacion */
const emailPendienteAprobacion = (id: string) => {
    return fetchMovilizacion.post(`${urlTransporte}/NotificarPendientes?EmpresatransporteId=${id}`);
}
// delete vehiculo
const deleteVehiculo = (id: string) => {
    return fetchMovilizacion.delete(`${urlTransporte}/Eliminar/${id}`);
}

/* Actualizacion personas masiva - roles */
const actualizarMasivoKeyUser = (file: FormData) => {
    return fetchMovilizacion.post(`${urlTransporte}/MMG/Masivo/KeyUser`, file, { headers: { "Content-Type": "multipart/form-data", }, });
};

const actualizarMasivoTransporte = (file: FormData) => {
    return fetchMovilizacion.post(`${urlTransporte}/MMG/Masivo/Transporte`, file, { headers: { "Content-Type": "multipart/form-data", }, });
};

const actualizarMasivoSSEE = (file: FormData) => {
    return fetchMovilizacion.post(`${urlTransporte}/MMG/Masivo/SSEE`, file, { headers: { "Content-Type": "multipart/form-data", }, });
};

const putTransporte = (data:any) =>{
    return fetchMovilizacion.put(`${urlTransporte}`,data);
}
const TransporteService = {
    postTransporte,
    transporteVigente,
    allMarca,
    allTipo,
    allCategoria,
    catalogoAdjunto,
    pendienteAprobacion,
    detalleTransporteById,
    actualizarTransporte,
    estado,
    validarTransporte,
    reporteVehiculos,
    reporteTrasportePersona,
    emailPendienteAprobacion,

    /* Rutas */
    ActualizarRuta,
    EliminarRuta,

    allSocioTransportista,
    allSocioTrByEmpresaContratista,
    postCategoriaVehiculo,
    putCategoriaVehiculo,
    deleteCategoriaVehiculo,
    tablaCategoriaVehiculo,
    postMarca,
    tablaMarca,
    putMarca,
    deleteMarca,
    deleteVehiculo,


    /* Actualizacion personas masivo */
    actualizarMasivoKeyUser,
    actualizarMasivoTransporte,
    actualizarMasivoSSEE,
    
    /* Empresa Transportista */

    postModelo,
    putModelo,
    deleteModelo,
    tablaModelo,
    putTransporte,
    allModeloByMarcaId,
};

export default TransporteService;