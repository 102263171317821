//import * as React from "react";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import PersonaService from "../../services/PersonaService";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { ComboBox, DatePicker, DialogType, IComboBox, IComboBoxOption, ICommandBarItemProps, Icon, Label, PanelType, Pivot, PivotItem, Stack, TooltipHost } from "@fluentui/react";
import { useContext, useEffect, useState, useId, useCallback } from "react";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { Ifilter } from "../../interfaces/IFilter";
import { splitExceptionDetail, stackTokensHorizontal, TAB_BAJADA, TAB_SUBIDA, _initialFilter, _initialPagination, _onRenderHeader, _onRenderRow, _selectionItemsKeys, ItemNoneSelect } from "../../utils/Helper/helper";
import { IFiltroExcelProgramacion, IProgramacion } from "../../interfaces/IProgramacion";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { AuthContext } from "../../auth/context/auth-context";
import { isRoleAccessAll, roleAdmin } from "../../utils/Helper/roleHelper";
import { TableBase } from "../../components/Table/TableBase";
import ThemeBase from "../../utils/Theme/themeBase";
import { IDataCollection, IPagination } from "../../interfaces/base/ITable";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import RepoteBase from "../../components/Generic/ReporteBase";
import { IException, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import { NewProgramming } from "./NewProgramming";
import { EditProgramming } from "./EditProgramming";
import { DialogBase } from "../../components/Dialog/DialogBase";
import ProgramacionService from "../../services/ProgramacionService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { State } from "../../components/Generic/state";
import { ColorHelper } from "../../utils/Helper/colorHelper";
import { PanelPlantilla } from "../../components/PanelUpload/PanelPlantilla";
import { PanelPlantillaFiltro } from "../../components/PanelUpload/PanelPlantillaFiltro";
import { Input } from "@fluentui/react-components";
import IdicadorProgreso, { LineaProgreso } from "../../components/Generic/IndicadorProgreso";
import { _formatDate, _formatDatePrefix } from "../../utils/Helper/datetimeHelper";
import { iconWithTooltip } from "../../utils/Helper/detalleArchivoHelper";
import ProgramacionPersonaService from "../../services/programacionPersonaService";
import empresaService from "../../services/empresaService";
import { IEmpresa } from "../../interfaces/IEmpresa";

export const TablaProgramaciones = () => {
  let navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState("0");
  const [hidden, setHidden] = useState(false);

  const [columnIngreso, setColumnIngreso] = useState<IColumn[]>([]);
  const [columnSalida, setColumnSalida] = useState<IColumn[]>([]);
  const [ingreso, setIngreso] = useState<IDataCollection<IProgramacion[]>>(
    _initialDataCollection
  );
  const [salida, setSalida] = useState<IDataCollection<IProgramacion>>(
    _initialDataCollection
  );

  const [filterEmpresa, setFilterEmpresa] = useState<Ifilter>({
    skip: 0,
    take: 50
  });

  //Multiselect

  /* SearchParam */
  const [searchParam, setSearchParam] = useSearchParams();
  const [filter, setFilter] = useState<Ifilter>(_initialFilter);

  /* Modal de reporte */
  const [isModalOpenDown, { setTrue: showModalDonw, setFalse: hideModalDown }] = useBoolean(false);

  /* Panel Telerik */
  const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);

  /* Panel Crear */
  const [isOpenCrearProgramacion, { setTrue: openCrearProgramacion, setFalse: dismissCrearProgramacion },] = useBoolean(false);
  const [isSendForm, setisSendForm] = useState(false);

  /* Panel Editar*/
  const [isOpenEditarProgramacion, { setTrue: openEditarProgramacion, setFalse: dismissEditarProgramacion },] = useBoolean(false);

  /* Panel Descargar Reporte*/
  const [isOpenPanelDescarga, { setTrue: openPanelDescarga, setFalse: dismissPanelDescarga }] = useBoolean(false);
  const [fechaInicial, setFechaInicial] = useState<Date>(new Date());
  const [fechaFin, setFechaFin] = useState<Date>(new Date());
  const [comboEmpresas, setComboEmpresas] = useState<IItemComboBaseDTO[]>([]);
  const [keysEmpresas, setKeysEmpresas] = useState<string[]>([]);



  const [modelReporte, setModelReporte] = useState<IFiltroExcelProgramacion>({
    fechaInicio: _formatDate(fechaInicial, ","),
    fechaFin: _formatDate(fechaFin, ","),
  })
  const [fileDetail, setFileDetail] = useState<Blob>(null!);
  const [showTooltip, setShowTooltip] = useState(false);
  //const tooltipId = useId();
  const [isSend, setIsSend] = useState(false);


  /* Dialog Eliminar */
  const [hideEliminarProgramacion, { toggle: toggleEliminarProgramacion }] = useBoolean(true);
  const [hideEliminarProgramacionBajada, { toggle: toggleEliminarProgramacionBajada }] = useBoolean(true);
  const [selProgramacionId, setSelProgramacionId] = useState<string | undefined>(undefined);

  /* Access */
  const { access } = useContext(AuthContext);
  let role = access?.role ?? [];

  /* Role */
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [isAreaTransporte, setIsAreaTransporte] = useState(false);
  const [isAdmin, setIsAdmin] = useState(isRoleAccessAll(role, roleAdmin()));

  /* Item */
  const [selectItem, setSelectItem] = useState<string | undefined>(undefined);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  const selection = new Selection({
    onSelectionChanged: () => {
      /* Nada */
    },
    getKey: (item: any) => item.programacionPersonaBusId
  });

  /* Hook */
  useEffect(() => {
    reloadInitial();
    dataContrata();
  }, []);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  useEffect(() => {
    if(isOpenPanelDescarga)
    {
      setModelReporte({
        /* fechaInicio : _formatDate(fechaInicial, ","),
        fechaFin : _formatDate(fechaFin, ",") */
        fechaInicio : _formatDate(new Date(), ","),
        fechaFin : _formatDate(new Date(), ",")
        
      });
    }
  }, [isOpenPanelDescarga]);

  useEffect(() => {
    setModelReporte({
      ...modelReporte,
      fechaInicio : _formatDate(fechaInicial, ",")
    });
  }, [fechaInicial]);

  useEffect(() => {
    setModelReporte({
      ...modelReporte,
      fechaFin : _formatDate(fechaFin, ",")
    });
  }, [fechaFin]);

  useEffect(() => {
    if (filterEmpresa.search != null) {
        dataContrata();
    }
  }, [filterEmpresa]);

  useEffect(() => {

    let EmpresaKeyFilter =  (keysEmpresas.filter(id => id !== 'selectAll' && id !== '-1'))

    
    setModelReporte({
    ...modelReporte,
    listaEmpresasId : EmpresaKeyFilter
    })

}, [keysEmpresas]);

  /* useEffect(() => {
    setModelReporte({
      ...modelReporte,
      fechaInicio : _formatDate(fechaInicial, ",")
    });
  }, [fechaInicial]); */

  /* Api */
  const dataIngreso = (param = filter) => {
    setIngreso(_initialDataCollection());

    PersonaService.getListaPrograma(param)
      .then((res) => {
        setIngreso(res.data);
        setTotal(res.data.total);
        setHidden(true);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        setHidden(true); 
      });
  };

  const dataSalida = (param = filter) => {
    setSalida(_initialDataCollection());

    PersonaService.getListaProgramaSalidas(param)
      .then((res) => {
        setSalida(res.data);
        setTotal(res.data.total);
        setHidden(true);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        setHidden(true);
      });
  };

  const eliminarProgramacion = () => {
    if (selProgramacionId != undefined) {
      ProgramacionService.deleteProgramacion(selProgramacionId).then(res => {
        if (res.status == 200) {
          reloadInitial();
          tabIndex === "0" ? toggleEliminarProgramacion() : toggleEliminarProgramacionBajada();
          alert('Se eliminó correctamente');
        }
      }).catch((e: IException) => {
        alert(splitExceptionDetail(e));
      })
    }
  }

  function dataContrata() {

    empresaService.all(filterEmpresa).then(res => {
        if (res.status == 200) {

            let d: IEmpresa[] = res.data.items;
            let t: IItemComboBaseDTO[] = d.map((x) => {
                return {
                    key: x.empresaId,
                    text: x.nombre as string,
                };
            });

            t.unshift(ItemNoneSelect())
            setComboEmpresas(t);

        }

    }).catch((e: IException) => {
        console.log(e.response.data.detail);
    })
  }

  /* Function */
  const reloadInitial = (param = filter, tab = tabIndex) => {
    setHidden(false);

    switch (tab) {
      case '0':
        setTitleIngresoColumn();
        dataIngreso(param);
        break;
      case '1':
        setTitleSalida();
        dataSalida(param);
        break;
      default:
        break;
    }
  }

  //probar envio FechaCierre
  function ProbarEnvioFechaCierre() {

    ProgramacionService.emailFechaCierre().then(res => {
      if (res.status == 200) {

        alert('se ha enviado correctamente la notificacion - FECHA CIERRE!!!');
      }
    }).catch((e: IException) => {
      alert(splitExceptionDetail(e));
    })


  }


  const setTitleIngresoColumn = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Titulo",
        fieldName: "titulo",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column1.0",
        name: "Guardia",
        fieldName: "guardia",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Tipo",
        fieldName: "tipoPersonal",
        minWidth: 30,
        maxWidth: 30,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      // {
      //   key: "column2.0",
      //   name: "PDP",
      //   fieldName: "",
      //   minWidth: 30,
      //   maxWidth: 30,
      //   isResizable: true,
      //   sortAscendingAriaLabel: "Sorted A to Z",
      //   sortDescendingAriaLabel: "Sorted Z to A",
      //   data: "string",
      //   isPadded: true,
      //   onRender: (item: IProgramacion) => {
      //     return (
      //       <>
      //         {
      //           item.isParadaPlanta ?
      //             <span>SI</span>
      //             :
      //             <span>NO </span>
      //         }
      //       </>
      //     )
      //   },
      // },
      {
        key: "column3",
        name: "Fecha Cierre",
        fieldName: "fechaCierre",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacion) => {
          return <span style={{ color: ColorHelper.DANGER }}>{item.fechaCierre}</span>;
        },
      },
      {
        key: "column4",
        name: "Fecha Mov.",
        fieldName: "fechaIngreso",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: "Fecha Bajada",
        fieldName: "fechaSalida",
        minWidth: 140,
        maxWidth: 140,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },

      {
        key: "column8",
        name: "Inscritos Bus",
        fieldName: "inscritos",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        isPadded: true,
      },
      {
        key: "column8.1",
        name: "Helo",
        fieldName: "inscritoHelo",
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        isPadded: true,
      },
      {
        key: "column7",
        name: "Estado",
        fieldName: "estado",
        minWidth: 70,
        maxWidth: 80,
        isResizable: true,
        isMultiline: true,
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacion) => (
          <>
            <State estado={item.estado} colorEstado={item.colorEstado} width={100}></State>
          </>
        )
      },
      {
        key: "column9",
        name: "Usuario Reg.",
        fieldName: "usuarioRegistra",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        isPadded: true,
      },
      {
        key: "column10",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IProgramacion) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              {isAdmin &&
                <TooltipHost content={`Editar Programación`}>
                  <Icon
                    iconName={IconHelper.ICON_EDIT.iconProps?.iconName}
                    style={ThemeBase.icon}
                    className={ThemeBase.classes.iconFont}
                    // onClick={() => navigate(`${ConfigHelper.urlBase()}/programaciones/editar/${item.programacionId}`)} />
                    onClick={() => { openEditarProgramacion(); setSelectItem(item.programacionId) }} />
                </TooltipHost>}

              <TooltipHost content={`Detalle`}>
                <Icon iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                  style={{ cursor: "pointer" }}
                  className={ThemeBase.classes.iconFont}
                  onClick={() => goOtherPage(item)} />
              </TooltipHost>

              {isAdmin && item.isDeleted && <TooltipHost content={IconHelper.ICON_DELETE.text}>
                <Icon
                  iconName={IconHelper.ICON_DELETE.iconProps?.iconName}
                  style={ThemeBase.iconRemove}
                  className={ThemeBase.classes.iconFont}
                  onClick={() => {
                    setSelProgramacionId(item.programacionId);
                    toggleEliminarProgramacion();
                  }} />
              </TooltipHost>}
            </Stack>
          </>
        ),
      },
    ];

    setColumnIngreso(col);
  };

  const goOtherPage = (item) => {
    navigate(`${ConfigHelper.urlBase()}/programaciones/${item.programacionId}`, {
      state: {
        tipoPersonal: `${item.tipoPersonal}`,
      },
    });
  }


  const setTitleSalida = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Titulo",
        fieldName: "titulo",
        minWidth: 200,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        onRender: (item: IProgramacion) => {
          return <span style={{ fontSize: "90%" }}>{item.titulo}</span>;
        },
      },
      {
        key: "column1.1",
        name: "Guardia",
        fieldName: "guardia",
        minWidth: 190,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Tipo",
        fieldName: "tipoPersonal",
        minWidth: 30,
        maxWidth: 30,
        isResizable: true,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
      },
      // {
      //   key: "column2.0",
      //   name: "PDP",
      //   fieldName: "",
      //   minWidth: 30,
      //   maxWidth: 30,
      //   isResizable: true,
      //   sortAscendingAriaLabel: "Sorted A to Z",
      //   sortDescendingAriaLabel: "Sorted Z to A",
      //   data: "string",
      //   isPadded: true,
      //   onRender: (item: IProgramacion) => {
      //     return (
      //       <>
      //         {
      //           item.isParadaPlanta ?
      //             <span>SI</span>
      //             :
      //             <span>NO </span>
      //         }
      //       </>
      //     )
      //   },
      // },
      {
        key: "column2.1",
        name: "Fecha Cierre",
        fieldName: "fechaCierre",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacion) => {
          return <span style={{ color: ColorHelper.DANGER }}>{item.fechaCierre}</span>;
        },
      },
      {
        key: "column3",
        name: "Fecha Mov.",
        fieldName: "fechaIngreso",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4.1",
        name: "Turno",
        fieldName: "turno",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        isPadded: true,
      },
      {
        key: "column8",
        name: "Inscritos Bus",
        fieldName: "inscritos",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        isPadded: true,
      },
      {
        key: "column8.1",
        name: "Helo",
        fieldName: "inscritoHelo",
        minWidth: 20,
        maxWidth: 20,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        isPadded: true,
      },
      {
        key: "column7.1",
        name: "Estado",
        fieldName: "estado",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isMultiline: true,
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacion) => (
          <>
            <State estado={item.estado} colorEstado={item.colorEstado}></State>
          </>
        )
      },
      {
        key: "column9",
        name: "Usuario Reg.",
        fieldName: "usuarioRegistra",
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        isPadded: true,
      },
      {
        key: "column10",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IProgramacion) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              {isAdmin &&
                <TooltipHost content={`Editar Programación`}>
                  <Icon
                    iconName="Edit"
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    // onClick={() => navigate(`${ConfigHelper.urlBase()}/programaciones/editar/${item.programacionId}`)} />
                    onClick={() => { openEditarProgramacion(); setSelectItem(item.programacionId) }} />
                </TooltipHost>}
              <TooltipHost content={`Detalle`}>
                <Icon
                  iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                  style={{ cursor: "pointer" }}
                  className={ThemeBase.classes.iconFont}
                  onClick={() => goOtherPage(item)}
                />
              </TooltipHost>
              {/* item.isDeleted && */}
              {isAdmin && 
              <TooltipHost content={IconHelper.ICON_DELETE.text}>
                <Icon
                  iconName={IconHelper.ICON_DELETE.iconProps?.iconName}
                  style={ThemeBase.iconRemove}
                  className={ThemeBase.classes.iconFont}
                  onClick={() => {
                    setSelProgramacionId(item.programacionId);
                    toggleEliminarProgramacionBajada()
                  }}
                />
              </TooltipHost>}
            </Stack>
            {/* <TooltipHost content={`Editar Programación`}>
                            <Link href={`${ConfigHelper.urlBase()}/programaciones/editar=${item.key}`}>
                                <Icon
                                    iconName="PageEdit"
                                    className={classNames.iconFont}
                                    style={{ margin: '0 5px 0 5px', fontSize: 17, color: 'coral' }}
                                />
                            </Link>
                        </TooltipHost>
                        <TooltipHost content={`Delete`}>
                            <Link>
                                <Icon
                                    iconName="Delete"
                                    className={classNames.iconFont}
                                    style={{ margin: '0 5px 0 5px', fontSize: 17, color: 'blueviolet' }}
                                />
                            </Link>
                        </TooltipHost>
                        <TooltipHost content={`Calendario`}>
                            <Icon
                                iconName="Calendar"
                                className={classNames.iconFont}
                                style={{ margin: '0 5px 0 5px', fontSize: 17, color: 'red' }}
                            />
                        </TooltipHost> */}
          </>
        ),
      },
    ];

    setColumnSalida(col);
  };

  const goAdd = () => {
    navigate(`${ConfigHelper.urlBase()}/programaciones/crear`);
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => reloadInitial(),
      },
    ];

    if (isAdmin) {
      _items.push({
        ...IconHelper.ICON_ADD,
        onClick: openCrearProgramacion,
        // onClick: goAdd,
      });
    }

    return _items;
  };

  const _rigthBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_DOWNLOAD,
        onClick: descargaReporte,
      },
      {
        ...IconHelper.ICON_EXCEL,
        text : "Reporte de Personas",
        onClick: openPanelDescarga,
      },
    ];
    return _items;
  };

  const _onChangeTab = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
    if (item != undefined) {
      let { itemKey } = item.props;

      if (itemKey != undefined) {
        setTabIndex(itemKey);

        let f: Ifilter = _initialFilter();

        setFilter(f);
        setCurrentPage(_initialPagination().currentPage);
        reloadInitial(f, itemKey);
      }
    }
  };

  const descargaReporte = () => {
    openTelerik();
  };

  const onRenderBodyEditProgramacion = useCallback(
    () => (
      <div
        className="ms-Grid-col ms-sm12 ms-md4 ms-lg12"
        style={{
          padding: "0 2em",
        }}
      >
        <EditProgramming
          id={selectItem}
          dismiss={dismissEditarProgramacion}
          setup={reloadInitial}
        ></EditProgramming>
      </div>
    ),
    [isOpenEditarProgramacion]
  );

  /* Event Pagination */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: Ifilter = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      }

      setFilter(f);
      setCurrentPage(page);

      reloadInitial(f);
    }
  }

  /* Event Filter */
  const _onChangeTextFilter = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    if (newValue != undefined) {
      if (newValue === '') {
        _onClearFilter();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        })
      }
    }
  };

  const _onPressFilter = (newValue: string) => {
    let f: Ifilter = {
      ..._initialFilter(),
      search: newValue,
    };

    setCurrentPage(_initialPagination().currentPage);
    reloadInitial(f);
  };

  const _onChangeInputs = (nombreInput: any, evento: any, valor: any) => {
    if (evento !== undefined) {
      if (valor != undefined) {
        switch (nombreInput) {
          case "fechaInicio":
            /* let rangoI = parseInt(evento.target.value);
                        if (rangoI < 0) {
                            setErrorMsgInt("El rango debe ser positivo");
                        } */
              setModelReporte({
              ...modelReporte,
              fechaInicio:  valor.value,
            });
            break;
          case "fechaFin":
            /* let rangoF = parseInt(evento.target.value);
                        if (rangoF < 0) {
                            setErrorMsgInt("El rango debe ser positivo");
                        } */
              setModelReporte({
              ...modelReporte,
              fechaFin: valor.value,
            });
            break;
        }
      }
    }
  };

 const cerrarPanelDescarga = () => {
    dismissPanelDescarga()
    setFechaInicial(new Date())
    setFechaFin(new Date())

 }

  const reporteExcel = (ev?:| React.MouseEvent<HTMLElement, MouseEvent>
    | React.KeyboardEvent<HTMLElement> | undefined) => 
  {
    ev?.persist()

    setIsSend(true);

    let newModelReporte: IFiltroExcelProgramacion = {
      fechaInicio: modelReporte.fechaInicio?.replace(/-/g, ','),
      fechaFin: modelReporte.fechaFin?.replace(/-/g, ','),
      listaEmpresasId: modelReporte.listaEmpresasId
    }
    ProgramacionPersonaService.descargarReporteExcel(newModelReporte)
      .then((res) => {
        if (res.status === 200) {

          //setFileDetail(res.data);

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", "ReporteProgramacion.xlsx");
          document.body.appendChild(link);
          link.click();
          link.remove();

          setIsSend(false);
          //limpiarInputs();
        }

      })
      .catch((e: Error) => {
        setIsSend(false);
        console.log(e.message);
      }); 
  }

  const _onUpEmpresa = (ev?: React.KeyboardEvent<IComboBox>) => {
    //_openOptionEmpresa();
    let { value } = ev?.target as HTMLInputElement;
    //console.log(value);

    setFilterEmpresa({
        ...filterEmpresa,
        search: value,
    });
}

  const _onChangeCombo = (
    param: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
) => {
    if (event != undefined) {
        if (option != undefined) {

            /* console.log(option.key.toString()) */

            switch (param) {

                case "empresas":
                    _selectionItemsKeys(
                        option,
                        setKeysEmpresas,
                        setComboEmpresas,
                        comboEmpresas,
                        keysEmpresas
                    );
                    break;
                
                default:
                    break;
            }
        }
    }
};

  const leftDescargarArchivo = () => {

    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_DOWNLOAD,
        text: `Descargar excel `,
        preferMenuTargetAsEventTarget: true,
        onClick: (
            ev?:
              | React.MouseEvent<HTMLElement, MouseEvent>
              | React.KeyboardEvent<HTMLElement>
              | undefined
          ) => {

            ev?.persist()

            setIsSend(true);

            let newModelReporte: IFiltroExcelProgramacion = {
              fechaInicio: modelReporte.fechaInicio?.replace(/-/g, ','),
              fechaFin: modelReporte.fechaFin?.replace(/-/g, ','),
              //ComedorId: modelReporte.hasOwnProperty('ComedorId')? modelReporte.ComedorId: null
            }
          ProgramacionPersonaService.descargarReporteExcel(newModelReporte)
            .then((res) => {
              if (res.status === 200) {

                //setFileDetail(res.data);

                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", "ReporteProgramacion.xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();

                setIsSend(false);
                //limpiarInputs();
              }

            })
            .catch((e: Error) => {
              setIsSend(false);
              console.log(e.message);
            }); 

        },
      },
    ];

    return _items
  };

  const _onClearFilter = () => {
    let f: Ifilter = {
      ..._initialFilter()
    }

    setFilter(f);
    reloadInitial(f);
  }

  return (
    <>
      <HeadingBar
        title="Programaciones"
        subTitle="Gestión de programaciones"
        searchPlaceholder="Buscar"
        searchChange={_onChangeTextFilter}
        searchHandler={_onPressFilter}
        searchValue={filter.search ?? ''}
        onClearHandler={_onClearFilter}
      ></HeadingBar>

      <div style={{ padding: "20px" }}>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <CustomCommandBar
              item={_leftBar()}
              farItem={_rigthBar()}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            ></CustomCommandBar>
          </div>
        </div>

        <Pivot selectedKey={tabIndex} onLinkClick={_onChangeTab}>
          <PivotItem itemKey='0' headerText={TAB_SUBIDA}>
            <TableBase column={columnIngreso} data={ingreso?.items} isLoading={!hidden} style={ThemeBase.notPaddingTableBase} /* selection={selection} */ pagination={pager}></TableBase>
          </PivotItem>

          <PivotItem itemKey='1' headerText={TAB_BAJADA}>
            <TableBase column={columnSalida} data={salida.items} isLoading={!hidden} style={ThemeBase.notPaddingTableBase} /* selection={selection} */ pagination={pager}></TableBase>
          </PivotItem>
        </Pivot>
      </div>

      {/* Panel Crear nueva Programacion */}
      <NewProgramming isOpen={isOpenCrearProgramacion} onDismiss={dismissCrearProgramacion} setup={reloadInitial} />

      {/* Panel Editar */}
      <EditProgramming id={selectItem} isOpen={isOpenEditarProgramacion} dismiss={dismissEditarProgramacion} setup={reloadInitial} />

      {/* Dialog Eliminar Programacion */}
      <DialogBase isHidden={hideEliminarProgramacion}
        subTitle='La programación será eliminado permanentemente junto con sus salidas relacionadas'
        tipo={DialogType.normal}
        toggleHidden={toggleEliminarProgramacion}
        dependency={[]}
        confirm={eliminarProgramacion}
      ></DialogBase>

      {/* Dialog Eliminar Programacion Bajada */}
      <DialogBase
        isHidden={hideEliminarProgramacionBajada}
        subTitle='La programación será eliminado permanentemente, esta accion puede crear conflictos con las recurencias'
        tipo={DialogType.normal}
        toggleHidden={toggleEliminarProgramacionBajada}
        dependency={[]}
        confirm={eliminarProgramacion}
      ></DialogBase>

      {/* Panel - Reporte - Telerik */}
      <RepoteBase
        headerName="Programaciones"
        NameReport="MOVReporteProgramacion.trdx"
        openTelerik={isOpenTelerik}
        dismissTelerik={dismissTelerik}
      ></RepoteBase>

      <div>
        <div id="panelDescarga">
          <>
            <PanelPlantilla
              subTextHeader=""
              disabledBoton = {false}
              textHeader="Descargar Reporte"
              textBoton="Descargar"
              openPanel={isOpenPanelDescarga}
              onclickBoton={reporteExcel}
              closePanel={cerrarPanelDescarga}
              size={PanelType.smallFixedFar}
              estadoBarraEnvio={false}

            >
              <>
              {/* <pre>{JSON.stringify(modelReporte, null, 2)}</pre>  */}
                <div className="ms-Grid" dir="ltr">                   
                  <div className="ms-Grid-row">
                    <br />
                    <Stack
                      className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                      onKeyPress={(e) => {
                      if (
                        e.charCode === 101 ||
                        e.charCode === 69 ||
                        e.charCode === 45 ||
                        e.charCode === 46 ||
                        e.charCode === 43
                        ) {
                          e.preventDefault();
                        }
                      }}
                    >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                          <DatePicker
                            placeholder='Fecha Inicio *'
                            disabled = {isSend}
                            allowTextInput
                            ariaLabel='Select a date. Input format is day slash month slash year.'
                            value={fechaInicial}
                            //onSelectDate={...modelReporte,   as (date?: any) => void}
                            onSelectDate={setFechaInicial as (date?: any) => void}
                            formatDate= {(d) => _formatDatePrefix('Fecha Inicio:', d)}
                            style={{ paddingTop: '4px' }}
                          />
                        </div>
                    </Stack>
                    <Stack
                    className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                    onKeyPress={(e) => {
                      if (
                        e.charCode === 101 ||
                        e.charCode === 69 ||
                        e.charCode === 45 ||
                        e.charCode === 46 ||
                        e.charCode === 43
                      ) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <br />
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <DatePicker
                              placeholder='Fecha Fin *'
                              allowTextInput
                              ariaLabel='Select a date. Input format is day slash month slash year.'
                              value={fechaFin}
                              //onSelectDate={...modelReporte,   as (date?: any) => void}
                              onSelectDate={setFechaFin as (date?: any) => void}
                              formatDate= {(d) => _formatDatePrefix('Fecha Fin:', d)}
                              style={{ paddingTop: '4px' }}
                              minDate={fechaInicial}
                              disabled = {!fechaInicial || isSend}
                      />
                    </div>

                    
                  </Stack>
                  <br />
                  <Stack  className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <ComboBox
                          label="Empresa:"
                          options={comboEmpresas}
                          selectedKey={keysEmpresas}
                          autoComplete="off"
                          useComboBoxAsMenuWidth
                          calloutProps={{ directionalHintFixed: true }}
                          allowFreeform
                          onChange={(e, o) => _onChangeCombo("empresas", e, o)}
                          //componentRef={comboBoxRefEmpresa}
                          onKeyUp={_onUpEmpresa}
                          multiSelect
                      />
                    </div>
                  </Stack>
              </div>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <br />
                      <LineaProgreso
                        prefix="Generando Archivo Excel"
                        ocultar={!isSend}
                      />
                      {/* {!isSend && (

                        <>
                          <CustomCommandBar
                            item={leftDescargarArchivo()}
                            style={{ paddingRight: "0px", paddingLeft: "0px" }}
                          ></CustomCommandBar>
                        </>
                      )} */}

                    </div>
                  </div>

                </div>
              </>
              
            </PanelPlantilla>
          </>
        </div>
      </div>



    </>
  );
};
