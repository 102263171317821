import {
  CommandBarButton,
  initializeIcons,
  IStackStyles,
  Link,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../auth/context/auth-context";
import { IUser } from "../../auth/interface/IAuth";
import { UseForm } from "../../hooks/useForm";
import AuthService from "../../services/AuthService";
import ThemeBase from "../../utils/Theme/themeBase";
import { Home } from "../../pages/Home/home";
import { useBoolean } from "@fluentui/react-hooks";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { splitExceptionDetail, stackTokens } from "../../utils/Helper/helper";
import { IException } from "../../interfaces/base/IBase";
import { urlRecuperarContraseniaIdentidad } from "../../services/http-common";

declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        execute: (
          siteKey: string,
          options: { action: string }
        ) => Promise<string>;
      };
    };
  }
}

const stackStyles: Partial<IStackStyles> = { root: { height: 44 } };

export const LoginPrincipal = () => {
  initializeIcons();
  const { signin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [verLoading, setVerLoading] = useState(false);
  const [textError, setTextError] = useState("");
  const [tReCaptcha, setTReCaptcha] = useState("");

  const { email, password, form, _handleChange } = UseForm<IUser>({
    email: "",
    password: "",
    tokenCaptcha: "",
  });
  const [hideHome, { toggle: toggleHome }] = useBoolean(false);

  /* Functions */
  // const _pressLogin = (
  //   ev?: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   if (ev != undefined) {
  //     if (ev.code === "Enter" || ev.code === "NumpadEnter") {
  //       _handleLogin();
  //     }
  //   }
  // };

  /* Event */
  const _handleLogin = (token: string) => {
    setVerLoading(true);
    const updatedForm = { ...form, tokenCaptcha: token };
    AuthService.login(updatedForm)
      .then((response) => {
        if (response.status == 200) {
          let res = JSON.parse(response.request.response);
          if (res.succeeded) {
            if (res.accessToken != undefined && res.accessToken !== "") {
              signin(
                {
                  username: email,
                  token: res.accessToken,
                },
                () => window.location.replace(ConfigHelper.urlBase(true))
                //navigate(ConfigHelper.urlBase(true), { replace: true })
              );
            }
          }
        }
        //setVerLoading(false);
      })
      .catch((e: IException) => {
        //setVerLoading(false);
        //setTextError(splitExceptionDetail(e));
        if (e !== undefined) {
          //console.log(e.response);
          let rpta = e.response.data ? e.response.data : "";
          if (e.response.status === 400 && rpta === "Captcha no válido") {
            // let rpt = e.response.data
            //   ? e.response.data
            //   : "El captcha no pudo ser validado";
            //setMensajeRespuesta(rpt.toString());
            setTextError("El captcha no pudo ser validado");
          } else {
            let respuestaError =
              e.response?.data?.detail?.split("\n")[0] ??
              "Ingrese credenciales válidas.";
            setTextError(respuestaError);
          }
          //console.log(e.response);
          // setVerLoading(false);
        }
      })
      .finally(() => {
        setVerLoading(false);
      });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // Aquí puedes verificar la respuesta del reCaptcha y enviar el formulario
    window.grecaptcha.enterprise
      //.execute("6Lf5lCMpAAAAAEuGVqC2ET6DtxQ_siYhvjoT4zVM", { action: "submit" })
      .execute("6Le2AzMpAAAAAE1QA-tdoJxLiOH2Yz7CN-JtILrV", { action: "submit" })
      .then((token: string) => {
        // Aquí puedes verificar la respuesta del reCaptcha utilizando el token
        if (token) {
          // El reCaptcha se ha completado, puedes enviar el formulario
          // También puedes enviar el token a tu servidor para su verificación adicional si es necesario
          //console.log("Token de reCaptcha:", token);
          setTReCaptcha(token);
          _handleLogin(token);
          // Aquí puedes enviar el formulario o realizar otras acciones
        } else {
          // El reCaptcha no se ha completado, puedes mostrar un mensaje de error
          console.log("El reCaptcha no se ha completado");
        }
      });
  };

  // useEffect(() => {
  //   const container = document.getElementById("recaptcha-container");
  //   if (container) {
  //     // Inicializar reCaptcha aquí
  //     const script = document.createElement("script");
  //     // script.src =
  //     //   "https://www.google.com/recaptcha/enterprise.js?render=6Lf5lCMpAAAAAEuGVqC2ET6DtxQ_siYhvjoT4zVM";
  //     script.src =
  //       "https://www.google.com/recaptcha/enterprise.js?render=6LciwikpAAAAAMUgfs2GRNGyJzzHCbkJff3N7ImR";
  //     script.async = true;
  //     document.body.appendChild(script);

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, []);

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row">
          <div
            className="ms-Grid-col ms-sm6 ms-md8 ms-lg7"
            style={{
              backgroundImage: `url("./backgroundLogin.png")`,
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}></div>

          <div
            className="ms-Grid-col ms-sm6 ms-md4 ms-lg5"
            style={{
              backgroundColor: ThemeBase.theme.palette.neutralLighter,
              height: "100vh",
            }}>
            {/* Message Error */}
            {textError !== "" && (
              <>
                <div
                  style={{
                    paddingTop: "40px",
                  }}>
                  <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={() => {
                      setTextError("");
                    }}
                    dismissButtonAriaLabel="Close">
                    {textError}
                  </MessageBar>
                </div>
              </>
            )}

            {hideHome && (
              <div
                className="ms-Grid"
                dir="ltr"
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <Stack horizontal styles={stackStyles}>
                      <CommandBarButton
                        iconProps={{
                          iconName: "ChevronLeft",
                        }}
                        text="Átras"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        styles={{
                          root: {
                            backgroundColor:
                              ThemeBase.theme.palette.neutralLighter,
                            height: "48px",
                          },
                        }}
                        onClick={() => {
                          setTextError("");
                          toggleHome();
                        }}
                      />
                    </Stack>
                  </div>
                </div>

                <br />
                <br />
              </div>
            )}

            <div
              style={{
                ...ThemeBase.justifiedStyle,
                height: "100vh",
              }}>
              {!verLoading && (
                <div
                  style={{
                    minWidth: 400,
                    minHeight: 500,
                  }}>
                  {!hideHome ? (
                    <>
                      <Home toggleHome={toggleHome} />
                    </>
                  ) : (
                    <>
                      <div
                        className="ms-Grid"
                        dir="ltr" /* style={{ paddingTop: '30%' }} */
                      >
                        <div className="ms-Grid-row">
                          <div
                            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                            style={{
                              paddingTop: "30%",
                            }}>
                            <Text className="ms-fontSize-42">Ingresar</Text>

                            {/* <div id="recaptcha-container"> */}
                            <form onSubmit={handleSubmit}>
                              <TextField
                                name="email"
                                label="Usuario"
                                autoComplete="off"
                                value={email}
                                onChange={_handleChange}
                                //onKeyPress={_pressLogin}
                              />
                              <TextField
                                name="password"
                                label="Contraseña"
                                type="password"
                                canRevealPassword
                                revealPasswordAriaLabel="Show password"
                                value={password}
                                onChange={_handleChange}
                                //onKeyPress={_pressLogin}
                              />
                              <br></br>
                              <Stack tokens={stackTokens}>
                                <PrimaryButton
                                  ariaDescription="Detailed description used for screen reader."
                                  type="submit"
                                  //onClick={_handleLogin}
                                >
                                  Ingresar
                                </PrimaryButton>
                              </Stack>
                            </form>
                            {/* </div> */}

                            <br></br>
                            <br></br>
                            <Link href={urlRecuperarContraseniaIdentidad}>
                              Recuperar Contraseña
                            </Link>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br />
                          </div>
                        </div>
                      </div>
                      <br></br>
                    </>
                  )}
                </div>
              )}
              {verLoading && (
                <>
                  <div
                    style={{
                      paddingTop: "50%",
                    }}>
                    <ProgressIndicator
                      barHeight={5}
                      label="Enviando información"
                      description="Favor Espere un momento"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
