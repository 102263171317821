import {
  ComboBox,
  DatePicker,
  DefaultButton,
  FontIcon,
  getTheme,
  IComboBoxOptionStyles,
  IComboBoxStyles,
  IDatePicker,
  IStackProps,
  Label,
  PrimaryButton,
  Stack,
  TextField,
  IComboBoxOption,
  IComboBox,
  Toggle,
} from "@fluentui/react";

import { useParams } from "react-router";

import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonaService from "../../services/PersonaService";
import { IComboOptions } from "../../components/Generic/combo";
import { IItemComboBaseDTO } from "../../interfaces/base/IBase";
import ProgramacionService from "../../services/ProgramacionService";
import TitleSeparator from "../../components/Generic/TitleSeparator";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { _formatDatePrefix } from "../../utils/Helper/datetimeHelper";
import { _initialFilter } from "../../utils/Helper/helper";
import { ConfigHelper } from "../../utils/Helper/configHelper";
export interface inputsFormulario {
  titulo?: string;
  acceso?: Date;
  cupos?: number;
  tieneCuarentena?: boolean;
  diasCierre?: number;
  guardiaId?: any;
  fechaRecurrencia?: Date;
  programacionId?: any;
  tipoProgramacionId?: string | null;
}

const INITIAL_MODEL = {
  titulo: "Subida a MMG las Bambas",
  acceso: new Date(),
  cupos: 0,
  tieneCuarentena: false,
  diasCierre: 0,
  guardiaPersonalId: "afdc932f-b2c3-4891-bb7d-0001a59cba1a",
  fechaRecurrencia: new Date(),
  programacionId: "afdc932f-b2c3-4891-bb7d-0001a59cba1a",
};

const buscador = {
  search: "",
};

const onFormatDate = (date?: Date): string => {
  console.log("Fecha", date);
  var fecha: any;
  let dia: string;
  let mes: string;
  dia = "";
  mes = "";
  if (date?.getDate() != undefined) {
    if (date.getDate() > 0 && date.getDate() < 10) {
      dia = "0" + date.getDate();
    } else {
      dia = date.getDate().toString();
    }
  }
  if (date?.getMonth() != undefined) {
    if (date.getMonth() + 1 > 0 && date.getMonth() < 10) {
      mes = "0" + date.getMonth();
    } else {
      mes = date.getMonth().toString();
    }
  }

  if (date != undefined) {
    fecha = "Acceso: " + dia + "/" + mes + "/" + date.getFullYear();
  } else {
    fecha = "";
  }
  return fecha;
};

const onFormatR = (date?: Date): string => {
  var fecha: any;
  let dia: string;
  let mes: string;
  dia = "";
  mes = "";
  if (date?.getDate() != undefined) {
    if (date.getDate() > 0 && date.getDate() < 10) {
      dia = "0" + date.getDate();
    } else {
      dia = date.getDate().toString();
    }
  }
  if (date?.getMonth() != undefined) {
    if (date.getMonth() + 1 > 0 && date.getMonth() < 10) {
      mes = "0" + date.getMonth();
    } else {
      mes = date.getMonth().toString();
    }
  }

  if (date != undefined) {
    fecha = "Fecha Recurrencia: " + dia + "/" + mes + "/" + date.getFullYear();
  } else {
    fecha = "";
  }
  return fecha;
};


export const EditarProgramacion: React.FunctionComponent = () => {
  const [model, setmodel] = useState<inputsFormulario | undefined>(undefined);
  // const [titulo,setTitulo] = useState<string>();
  // const [guardiaId, setGuardia]= useState<any>();
  let navigate = useNavigate();
  let { id } = useParams();
  // const [guardia, setComboGuardia] = useState<IComboBoxOption[]>([]);
  const [guardia, setComboGuardia] = useState<IComboOptions[]>([]);
  const [tipoProgramacion, setTipoProgramacion] = useState<IItemComboBaseDTO[]>(
    []
  );

  function _onChangeTextFild(e: any, newValue: any) {
    console.log(e.target.name);
    setmodel({ ...model, [e.target.name]: newValue });
    console.log(newValue, model);
  }
  function _onChangeTextFildCierre(e: any, newValue: any) {
    console.log(e.target.name);
    setmodel({ ...model, [e.target.name]: newValue });
    console.log(newValue, model);
  }

  function goBack() {
    navigate(`${ConfigHelper.urlBase()}/programaciones`);
  }
  const editProgramacion = () => {
    PersonaService.putProgramacion(model)
      .then((response) => {
        if (response.status == 200) {
          console.log("Actualizado");
          alert("Actualizado");
          setmodel(model);
          goBack();
        }
      })
      .catch((e: Error) => {
        console.log(e);
        alert("Informacion no enviada");
      });
    console.log(model);
  };
  useEffect(() => {
    infoDataProgramacion();
    dataComboGuardia();
    dataTipoProgramacion();
  }, []);

  const infoDataProgramacion = () => {
    PersonaService.getInfoProgramacion(id)
      .then((response: any) => {
        if (response.status == 200) {
          setmodel({
            ...model,
            titulo: response.data.titulo,
            guardiaId: response.data.guardiaPersonalId,
            tieneCuarentena: response.data.tieneCuarentena,
            cupos: response.data.cupos,
            acceso: new Date(response.data.acceso),
            fechaRecurrencia: new Date(response.data.fechaRecurrencia),
            diasCierre: response.data.diasCierre,
            tipoProgramacionId: response.data.tipoProgramacionId,
            programacionId:id,
          });
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataComboGuardia = () => {
    PersonaService.comboGuardiaProgramacion(_initialFilter())
      .then((response: any) => {
        if (response.status == 200) {
          setComboGuardia(response.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataTipoProgramacion = () => {
    ProgramacionService.allTipoProgramacion()
      .then((res: any) => {
        if (res.status == 200) {
          setTipoProgramacion(res.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log("toggle is " + (checked ? "checked" : "not checked"));
    setmodel({
      ...model,
      tieneCuarentena: checked ? true : false,
    });
  }

  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "tipo":
            setmodel({
              ...model,
              tipoProgramacionId: String(option.key),
            });
            break;
          case "guardia":
            setmodel({
              ...model,
              guardiaId: String(option.key),
            });
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <>
      <form>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg2"></div>

            <div
              className="ms-Grid-col ms-sm12 ms-md12 ms-lg8"
              style={{
                padding: "5%",
              }}
            >
              <div
                className="ms-depth-8"
                style={{
                  padding: "15px",
                }}
              >
                <div>
             {/*

                  <ComboBox
                                    placeholder="Guardia"
                                    allowFreeform
                                    calloutProps={{ doNotLayer: true }}
                                    styles={comboBoxStyles}
                                    autoComplete="on"
                                    useComboBoxAsMenuWidth
                                    options={guardia}
                                    onChange={(e, item) => {
                                        console.log(item?.text)
                                        setmodel({
                                            ...model,
                                            guardiaPersonalId: item?.key
                                        })
                                    }}
                                    selectedKey={model?.guardiaPersonalId}
                                /> 
              </Stack>
            </div> */}
                  <TitleSeparator
                    title="Editar Programación"
                    icon="DependencyAdd"
                  ></TitleSeparator>
                  <br />
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <TextField
                          label="Titulo"
                          name="titulo"
                          placeholder={model?.titulo}
                          onChange={_onChangeTextFild}
                          defaultValue={model?.titulo}
                          value={model?.titulo}
                          required
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <ComboBox
                          label="Tipo"
                          autoComplete="off"
                          useComboBoxAsMenuWidth
                          calloutProps={{ directionalHintFixed: true }}
                          options={tipoProgramacion}
                          selectedKey={model?.tipoProgramacionId}
                          onChange={(e, o) => _onChangeCombo("tipo", e, o)}
                          required
                        />
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <Toggle
                          label="Acuartelamiento"
                          placeholder="Tiene cuarentena"
                          onText="Tiene Acuartelamiento"
                          offText="No Tiene Acuartelamiento"
                          checked={model ? model.tieneCuarentena : false}
                          onChange={_onChange}
                        />
                      </div>

                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <ComboBox
                          label="Guardia"
                          autoComplete="off"
                          useComboBoxAsMenuWidth
                          calloutProps={{ directionalHintFixed: true }}
                          options={guardia}
                          selectedKey={model?.guardiaId}
                          onChange={(e, o) => _onChangeCombo("guardia", e, o)}
                          required
                        />
                      </div>
                    </div>

                    <div className="ms-Grid-row" style={{ paddingTop: "5px" }}>
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <DatePicker
                          label="Fecha Acceso"
                          allowTextInput
                          ariaLabel="Select a date. Input format is day slash month slash year."
                          onSelectDate={(date?: any) => {
                            setmodel({
                              ...model,
                              acceso: date,
                            });
                            // console.log(date?.toISOString());
                          }}
                          formatDate={(d) => _formatDatePrefix("F. Acceso", d)}
                          onChange={(e: any) => console.log(model)}
                          value={model?.acceso}
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <DatePicker
                          label="Fecha Límite"
                          placeholder={model?.fechaRecurrencia?.toDateString()}
                          allowTextInput
                          ariaLabel="Select a date. Input format is day slash month slash year."
                          formatDate={(d) => _formatDatePrefix('F. Recurrencia', d)}
                          value={model?.fechaRecurrencia}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <TextField
                          label="Días Cierre"
                          name="diasCierre"
                          placeholder="Días Cierre"
                          onChange={_onChangeTextFildCierre}
                          value={model?.diasCierre?.toString()}
                          type="number"
                        />
                      </div>
                      <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <TextField
                          label="Cupos"
                          name="cupos"
                          placeholder="Cupos"
                          onChange={_onChangeTextFild}
                          type="number"
                          value={model?.cupos?.toString()}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <ButtonForm
                      back={goBack}
                      register={editProgramacion}
                      // disabledRegister={}
                    ></ButtonForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
