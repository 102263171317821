import { ActivityItem, ComboBox, IComboBox, IComboBoxOption, Panel, PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar";
import { IException, IItemComboBaseDTO } from "../../../../interfaces/base/IBase";
import { IRetirarPersonaMasivo } from "../../../../interfaces/IProgramacion";
import MotivoService from "../../../../services/motivoService";
import { splitExceptionDetail, stackTokens } from "../../../../utils/Helper/helper";
import { MessageHelper } from "../../../../utils/Helper/messageHelper";
import ThemeBase from "../../../../utils/Theme/themeBase";

let _sel: any[] = [];

export const RetiroMasivoVuelo = ({ programacionId = '', isOpen = false, dismiss = () => { }, sel = _sel, reloadParent = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Form */
    const [motivo, setMotivo] = useState<IItemComboBaseDTO[]>([]);
    const [selMotivoId, setSelMotivoId] = useState<string | null>(null);

    /* Hook */
    useEffect(() => {
        if (isOpen) {
            if (isOpen) {
                dataMotivo();
            } else {
                setSelMotivoId(null);
            }
        }
    }, [isOpen]);

    /* Api */
    const dataMotivo = () => {
        MotivoService.all()
            .then((res) => {
                setMotivo(res.data);
            })
            .catch((err) => {
                alert(err.message);
            });
    };

    const retirarPersonaMasivo = () => {
        if (programacionId != '' && selMotivoId) {
            setIsSend(true);
            setChoice(undefined);

            let t: IRetirarPersonaMasivo = {
                programacionId: programacionId,
                motivoId: selMotivoId,
                lista: sel.map((x) => x.programacionPersonaId),
            };

            MotivoService.retirarPersonaMasivo(t)
                .then((res) => {
                    if (res.status == 200) {
                        reloadParent();
                        resetChoice();
                        dismiss();
                    }

                    setIsSend(false)
                })
                .catch((e: IException) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    };

    /* Event */
    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case 'motivo':
                        setSelMotivoId(option.key as string);
                        break;
                    default:
                        break;
                }
            }
        }
    };

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <Stack tokens={stackTokens}>
                    <PrimaryButton
                        onClick={retirarPersonaMasivo}
                        styles={ThemeBase.panelButtonStyle}
                        disabled={!selMotivoId || isSend}
                    >
                        Retirar
                    </PrimaryButton>
                </Stack>
            </div>
        ),
        [programacionId, isOpen, dismiss, sel, isSend, reloadParent, selMotivoId]
    );

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText='Retiro Helo'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    setChoice('');
                                    dismiss();
                                }}></CustomMessageBar>}
                            </div>
                        </div>
                    </div>

                    <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                        <ComboBox
                            label="Motivo Retiro"
                            autoComplete="off"
                            useComboBoxAsMenuWidth
                            calloutProps={{ directionalHintFixed: true }}
                            options={motivo}
                            openOnKeyboardFocus
                            selectedKey={selMotivoId ?? ''}
                            onChange={(e, o) => _onChangeCombo("motivo", e, o)}
                            required
                        />

                        {sel.map((item: { key: string | number }) => (
                            <ActivityItem
                                {...item}
                                key={item.key}
                                className={ThemeBase.activityClass.exampleRoot}
                            />
                        ))}
                    </form>
                </>
            </Panel>
        </>
    )
}