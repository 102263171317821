import { IColumn } from "@fluentui/react/lib/DetailsList";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { ICommandBarItemProps, Icon, Pivot, PivotItem, Stack, TooltipHost } from '@fluentui/react';
import PersonaService from '../../services/PersonaService';

import { splitExceptionDetail, stackTokensHorizontal, TAB_BAJADA, TAB_SUBIDA, _initialFilter, _initialPagination, _onRenderHeader, _onRenderRow } from '../../utils/Helper/helper';
import { useEffect, useState } from "react";
import { IProgramacion } from "../../interfaces/IProgramacion";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { useNavigate } from "react-router-dom";
import ThemeBase from "../../utils/Theme/themeBase";
import { IException } from "../../interfaces/base/IBase";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { TableBase } from "../../components/Table/TableBase";
import { IDataCollection, IPagination } from "../../interfaces/base/ITable";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { Ifilter } from "../../interfaces/IFilter";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { State } from "../../components/Generic/state";
import { useBoolean } from "@fluentui/react-hooks";
import { PanelCupos } from "./cupos/PanelCupos";

export const ProgramacionesEmpresaTransporte = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState<Ifilter>(_initialFilter);

    /* Ingreso */
    const [hiddenIngreso, setHiddenIngreso] = useState(false);
    const [columnIngreso, setColumnIngreso] = useState<IColumn[]>([]);
    const [ingreso, setIngreso] = useState<IDataCollection<IProgramacion>>(_initialDataCollection);
    const [totalIngreso, setTotalIngreso] = useState(_initialPagination().total);

    /* Salida */
    const [hiddenSalida, setHiddenSalida] = useState(false);
    const [columnSalida, setColumnSalida] = useState<IColumn[]>([]);
    const [salida, setSalida] = useState<IDataCollection<IProgramacion>>(_initialDataCollection);
    const [totalSalida, setTotalSalida] = useState(_initialPagination().total);

    const [programacionId, setProgramacionId] = useState("");
    const [inscritos, setInscritos] = useState("");

    /* V2. Programacion de salida */
    const [tabIndex, setTabIndex] = useState('0');

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);

    //Panal
    const [isOpenCupos, { setTrue: openCupos, setFalse: dismissCupos }] = useBoolean(false)

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    const [pagerSalida, setPagerSalida] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPagerSalida(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    const selection = new Selection({
        onSelectionChanged: () => {
            /* Nada */
        },
        getKey: (item: any) => item.programacionPersonaBusId
    });

    /* Effect */
    useEffect(() => {
        reloadInitial();
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: totalIngreso,
        });
    }, [totalIngreso]);

    useEffect(() => {
        setPagerSalida({
            ...pagerSalida,
            onPageChange: (page) => dataByPagerSalida(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: totalSalida,
        });
    }, [totalSalida]);

    /* Api */
    const dataIngreso = (param = filter) => {
        PersonaService.getListaProgramacionesTransportes(1, param).then(res => {
            setIngreso(res.data);
            setTotalIngreso(res.data.total);
            setHiddenIngreso(true);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHiddenIngreso(true);
        });
    }

    const dataSalida = (param = filter) => {
        setSalida(_initialDataCollection);
        PersonaService.getListaProgramacionesTransportes(2, param).then(res => {
            setSalida(res.data);
            setTotalSalida(res.data.total);
            setHiddenSalida(true);
        }).catch((err) => {
            alert(err.message);
            setHiddenSalida(true);
        });
    }

    /* Function */
    const reloadInitial = (param = filter, index = tabIndex) => {
        switch (index) {
            case '0':
                setHiddenIngreso(false);
                setTitleIngresoColumn();
                dataIngreso(param);
                break;
            case '1':
                setHiddenSalida(false);
                setTitleSalida();
                dataSalida(param);
                break;
            default:
                break;
        }
    }

    const setTitleIngresoColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Programacion',
                fieldName: 'titulo',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column1.2',
                name: 'Tipo',
                fieldName: 'tipoPersonal',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Fecha Cierre',
                fieldName: 'fechaCierre',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Fecha de Movilización',
                fieldName: 'fechaIngreso',
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Fecha de Bajada',
                fieldName: 'fechaSalida',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Guardia',
                fieldName: 'guardia',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column8",
                name: "Inscritos Bus",
                fieldName: "inscritos",
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column8.1",
                name: "Inscritos Helo",
                fieldName: "inscritoHelo",
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column7.1",
                name: "Estado",
                fieldName: "estado",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isMultiline: true,
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacion) => (
                    <>
                        <State estado={item.estado} colorEstado={item.colorEstado}></State>
                    </>
                )
            },
            {
                key: "column9",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IProgramacion) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <TooltipHost content={`Detalle`}>
                                <Icon iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                                    style={{ cursor: 'pointer' }}
                                    className={ThemeBase.classes.iconFont}
                                    onClick={() => navigate(`${ConfigHelper.urlBase()}/empresa.transporte/${item.programacionId}`)}
                                />
                            </TooltipHost>
                            <TooltipHost content={'Definir Cupos'}>
                                <Icon iconName="Share"
                                    style={{ cursor: 'pointer' }}
                                    className={ThemeBase.classes.iconFont}
                                    onClick={() => {
                                        openCupos()
                                        setProgramacionId(item.programacionId)
                                        setInscritos(item.inscritos)
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumnIngreso(col);
    };

    const setTitleSalida = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Programacion',
                fieldName: 'titulo',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                isPadded: true,
            },
            {
                key: "column2",
                name: "Tipo",
                fieldName: "tipoPersonal",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                data: "string",
                isPadded: true,
            },
            {
                key: 'column2.1',
                name: 'Fecha Salida',
                fieldName: 'fechaIngreso',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Guardia',
                fieldName: 'guardia',
                minWidth: 220,
                maxWidth: 220,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column8",
                name: "Inscritos Bus",
                fieldName: "inscritos",
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column8.1",
                name: "Inscritos Helo",
                fieldName: "inscritoHelo",
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column7.1",
                name: "Estado",
                fieldName: "estado",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isMultiline: true,
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacion) => (
                    <>
                        <State estado={item.estado} colorEstado={item.colorEstado}></State>
                    </>
                )
            },
            {
                key: "column6",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 120,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IProgramacion) => (
                    <>
                        <TooltipHost content={`Detalle`}>
                            <Icon iconName='ContactList'
                                style={{ cursor: 'pointer' }}
                                className={ThemeBase.classes.iconFont}
                                onClick={() => { navigate(`${ConfigHelper.urlBase()}/empresa.transporte/${item.programacionId}`) }}
                            />
                        </TooltipHost>
                        <TooltipHost content={'Definir Cupos'}>
                            <Icon iconName="Share"
                                style={{ cursor: 'pointer' }}
                                className={ThemeBase.classes.iconFont}
                                onClick={() => {
                                    openCupos()
                                    setProgramacionId(item.programacionId)
                                    setInscritos(item.inscritos)
                                }}
                            />
                        </TooltipHost>
                    </>
                ),
            },
        ];

        setColumnSalida(col);
    };

    const _leftBarIngreso = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial()
            },
        ];
        return _items;
    }

    const _leftBarSalida = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial()
            },
        ];
        return _items;
    }

    /* Event */
    const _onChangeTab = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (item != undefined) {
            let { itemKey } = item.props;

            if (itemKey != undefined) {
                setTabIndex(itemKey);
                let f: Ifilter = _initialFilter();
                setFilter(f);
                reloadInitial(f, itemKey);
            }
        }
    }

    /* Event Pagination */
    const dataByPager = (page) => {
        if (true) {
            setTotalIngreso(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: Ifilter = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    const dataByPagerSalida = (page) => {
        if (true) {
            setTotalSalida(0);

            setPagerSalida({
                ...pagerSalida,
                currentPage: page,
            });

            let f: Ifilter = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    /* Event Filter */
    const _onChangeTextFilter = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {
            if (newValue === '') {
                _onClearFilter();
            } else {
                setFilter({
                    ...filter,
                    search: newValue,
                })
            }
        }
    };

    const _onPressFilter = (newValue: string) => {
        let f: Ifilter = {
            ...filter,
            search: newValue,
        };

        setFilter(f);
        reloadInitial(f);
    };

    const _onClearFilter = () => {
        let f: Ifilter = {
            ..._initialFilter()
        }

        setFilter(f);
        reloadInitial(f);
    }

    return (
        <>
            <HeadingBar
                title='Usuario Transportista'
                subTitle='Gestión de programaciones'
                searchChange={_onChangeTextFilter}
                searchHandler={_onPressFilter}
                searchValue={filter.search ?? ''}
                onClearHandler={_onClearFilter}
            ></HeadingBar>

            <div style={{ padding: '20px' }}>
                <Pivot aria-label="Basic Pivot Example" selectedKey={tabIndex} onLinkClick={_onChangeTab}>
                    <PivotItem itemKey='0' headerText={TAB_SUBIDA}>
                        <TableBase leftBar={_leftBarIngreso()} column={columnIngreso} data={ingreso.items} pagination={pager} selection={selection} isLoading={!hiddenIngreso} style={ThemeBase.notPaddingTableBase}></TableBase>
                    </PivotItem>

                    <PivotItem itemKey='1' headerText={TAB_BAJADA}>
                        <TableBase leftBar={_leftBarSalida()} column={columnSalida} data={salida.items} style={ThemeBase.notPaddingTableBase} pagination={pagerSalida} selection={selection} isLoading={!hiddenSalida}></TableBase>
                    </PivotItem>
                </Pivot>
            </div>

            <PanelCupos isOpen={isOpenCupos} dismiss={dismissCupos} programacionId={programacionId} _inscritos={inscritos} />
        </>
    )
}