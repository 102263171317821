import React from "react";
import { IPanelV2 } from "../../interfaces/base/IPanel";
import {
  ActivityItem,
  DefaultButton,
  FontIcon,
  FontSizes,
  IStackStyles,
  NeutralColors,
  Panel,
  PrimaryButton,
  SharedColors,
  Stack,
} from "@fluentui/react";

import { v4 as uuidv4 } from "uuid";
import ThemeBase from "../../utils/Theme/themeBase";
import { FluentProvider } from "@fluentui/react-components";

const stackStyles: IStackStyles = {
  root: {
    background: "#ffff",
    justifyContent: "space-between",
  },
};
let uuid = uuidv4();

export function PanelPlantillaV2({
  textHeader = "",
  subTextHeader = "",
  textBoton = "",
  textBotonDefault = "",
  textBotonNext = "",
  textBotonNext2 = "",
  textBotonBack = "",
  textBotonAccept = "",
  onclickNext,
  onclickNext2,
  onclickBack,
  onclickAccept,
  onclickBoton,
  onclickBotonDefault,
  openPanel,
  closePanel,
  size,
  disabledBoton,
  disabledBotonNext,
  estadoBarraEnvio,
  nameIconHeader = "",
  isIcon = false,
  nameIconButton = "",
  numeroDeBotones = 0,
  textHeaderColorBlue = false,
  children,
  hasCloseButton,
}: IPanelV2) {
  const onRenderHeader = React.useCallback(
    () => (
      <>
        {isIcon == true ? (
          <div
            key={uuid}
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg11"
            style={{ paddingLeft: "2em" }}>
            <ActivityItem
              key={uuid}
              style={{
                marginTop: 3,
              }}
              activityDescription={[
                <div key={uuid}>
                  <div className="LayoutPage-demoBlock">
                    <div
                      style={{
                        color: NeutralColors.gray130,
                        fontSize: FontSizes.size18,
                      }}>
                      {textHeader == undefined ? "" : textHeader}
                    </div>
                  </div>
                </div>,
              ]}
              activityIcon={
                <FontIcon
                  key={uuid}
                  iconName={nameIconHeader == undefined ? "" : nameIconHeader}
                  style={{
                    color: ThemeBase.theme.palette.blackTranslucent40,
                    fontSize: 35,
                    height: 35,
                    width: 35,
                    marginTop: 10,
                  }}
                />
              }
              comments={[
                <div key={uuid} className="LayoutPage-demoBlock">
                  <div
                    style={{
                      color: NeutralColors.gray130,
                      fontSize: FontSizes.size12,
                    }}>
                    {subTextHeader === undefined ? "" : subTextHeader}
                  </div>
                  <br />
                  <br />
                </div>,
              ]}
              // timeStamp={}
              className={ThemeBase.activityClass.exampleRoot}
            />
          </div>
        ) : (
          <div
            className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
            style={{ paddingLeft: "1.3em" }}>
            <ActivityItem
              key={uuid}
              style={{
                marginTop: 3,
              }}
              activityDescription={[
                <div>
                  <div className="LayoutPage-demoBlock">
                    {textHeaderColorBlue === true ? (
                      <div
                        style={{
                          color: SharedColors.cyanBlue10,
                          fontSize: FontSizes.size20,
                          // fontWeight: "550"
                        }}>
                        {textHeader}
                      </div>
                    ) : (
                      <div
                        style={{
                          color: NeutralColors.gray130,
                          fontSize: FontSizes.size18,
                        }}>
                        {textHeader}
                      </div>
                    )}
                  </div>
                </div>,
              ]}
              comments={[
                <div className="LayoutPage-demoBlock">
                  <div
                    style={{
                      color: NeutralColors.gray130,
                      fontSize: FontSizes.size12,
                    }}>
                    {subTextHeader === undefined ? "" : subTextHeader}
                  </div>
                  <br />
                  <br />
                </div>,
              ]}
            />
          </div>
        )}
      </>
    ),
    [textHeader]
  );

  const onRenderFooter = React.useCallback(
    () => (
      <>
        {estadoBarraEnvio == false && (
          <div key={uuid}>
            <Stack horizontal horizontalAlign="start" styles={stackStyles}>
              {/* boton == 1 SOLO BOTÓN DE CERRRAR */}
              {numeroDeBotones == 1 ? (
                <>
                  <DefaultButton onClick={onclickBotonDefault}>
                    {textBotonDefault}
                  </DefaultButton>
                </>
              ) : // boton == 2 BOTON DE REGISTRAR Y CERRAR
              numeroDeBotones == 2 ? (
                <>
                  <PrimaryButton
                    onClick={onclickBoton}
                    style={{ marginLeft: "5px" }}
                    text={textBoton}
                    disabled={disabledBoton}
                    iconProps={{
                      iconName: `${nameIconButton}`,
                    }}
                  />
                  <DefaultButton onClick={onclickBotonDefault}>
                    {textBotonDefault}
                  </DefaultButton>
                </>
              ) : // boton == 3 BOTON DE REGISTRAR, SIGUIENTE Y CERRAR
              numeroDeBotones == 3 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "35%",
                    }}>
                    <PrimaryButton
                      onClick={onclickBoton}
                      style={{ marginLeft: "5px", width: "100%" }}
                      text={textBoton}
                      disabled={disabledBoton}
                      iconProps={{
                        iconName: `${nameIconButton}`,
                      }}
                    />
                    <PrimaryButton
                      onClick={onclickNext}
                      style={{ marginLeft: "5px", width: "100%" }}
                      text={textBotonNext}
                      disabled={disabledBoton}
                      iconProps={{
                        iconName: `${nameIconButton}`,
                      }}
                    />
                  </div>
                  <DefaultButton onClick={onclickBotonDefault}>
                    {textBotonDefault}
                  </DefaultButton>
                </>
              ) : // boton == 4 BOTON DE ACEPTAR CUANDO SE REGISTRA UN FORMULARIO
              numeroDeBotones == 4 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "35%",
                    }}>
                    <PrimaryButton
                      onClick={onclickAccept}
                      style={{ marginLeft: "5px", width: "50%" }}
                      text={textBotonAccept}
                      iconProps={{
                        iconName: `${nameIconButton}`,
                      }}
                    />
                  </div>
                </>
              ) : // boton == 5 BOTON DE SIGUENTE Y CERRAR
              numeroDeBotones == 5 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "18%",
                    }}>
                    <PrimaryButton
                      onClick={onclickNext}
                      style={{ marginLeft: "5px", width: "100%" }}
                      text={textBotonNext}
                      disabled={disabledBotonNext}
                      iconProps={{
                        iconName: `${nameIconButton}`,
                      }}
                    />
                  </div>
                  <DefaultButton onClick={onclickBotonDefault}>
                    {textBotonDefault}
                  </DefaultButton>
                </>
              ) : // boton == 6 BOTON DE ATRÁS, REGISTRAR Y CERRAR
              numeroDeBotones == 6 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "35%",
                    }}>
                    <PrimaryButton
                      onClick={onclickBack}
                      style={{ marginLeft: "5px", width: "100%" }}
                      text={textBotonBack}
                      iconProps={{
                        iconName: `${nameIconButton}`,
                      }}
                    />
                    <PrimaryButton
                      onClick={onclickBoton}
                      style={{ marginLeft: "5px", width: "100%" }}
                      text={textBoton}
                      disabled={disabledBoton}
                      iconProps={{
                        iconName: `${nameIconButton}`,
                      }}
                    />
                  </div>
                  <DefaultButton onClick={onclickBotonDefault}>
                    {textBotonDefault}
                  </DefaultButton>
                </>
              ) : (
                // Boton == 7 Botón de Atrás, Siguiente y Cerrar
                numeroDeBotones == 7 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "35%",
                      }}>
                      <PrimaryButton
                        onClick={onclickBack}
                        style={{ marginLeft: "5px", width: "100%" }}
                        text={textBotonBack}
                        // disabled={disabledBoton}
                        iconProps={{
                          iconName: `${nameIconButton}`,
                        }}
                      />
                      <PrimaryButton
                        onClick={onclickNext2}
                        style={{ marginLeft: "5px", width: "100%" }}
                        text={textBotonNext2}
                        disabled={disabledBotonNext}
                        iconProps={{
                          iconName: `${nameIconButton}`,
                        }}
                      />
                    </div>
                    <DefaultButton onClick={onclickBotonDefault}>
                      {textBotonDefault}
                    </DefaultButton>
                  </>
                )
              )}
            </Stack>
          </div>
        )}
      </>
    ),
    [textBoton, closePanel, onclickBoton]
  );

  return (
    <>
      <div>
        <Panel
          isOpen={openPanel}
          onDismiss={closePanel}
          onRenderHeader={onRenderHeader}
          type={size}
          closeButtonAriaLabel="Close"
          onRenderFooterContent={onRenderFooter}
          isFooterAtBottom={true}
          hasCloseButton={hasCloseButton}
          // isLightDismiss
        >
          <FluentProvider>{children}</FluentProvider>
        </Panel>
      </div>
    </>
  );
}
