import { INavLink, INavLinkGroup, INavStyles, Nav } from "@fluentui/react";
import { useState } from "react";

const navStyles: Partial<INavStyles> = {
    root: {
        width: 194,
        // height: 400,
        height: "calc(100vh - 48px)",
        boxSizing: 'border-box',
        border: '1px solid #eee',
        overflowY: 'auto',
    },
};

export const ReporteGerencial = () => {

    const [key, setKey] = useState<string | undefined>("key1");

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: 'Reportes',
                    url: '',
                    expandAriaLabel: 'Expand Home section',
                    collapseAriaLabel: 'Collapse Home section',
                    links: [
                        {
                            name: 'Reporte 1',
                            url: '',
                            icon: 'ContactInfo',
                            key: 'key1',
                            // target: '_blank',
    
                        },
                        {
                            name: 'Reporte 2',
                            url: '',
                            icon: 'EntitlementRedemption',
                            // disabled: true,
                            key: 'key2',
                            // target: '_blank',
                        },
                    ],
                    isExpanded: true,
                },
    
            ],
        },
    ];

    const _clickNavigate = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        if (ev) {
            ev.preventDefault();
            if (item) {
                // navigate(item.url);
                // alert(item.key);
                setKey(item.key);
            }
        }
    };

    return (
        <>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2" style={{ paddingLeft: '0px' }}>
                        {/* <NavBasicExample /> */}
                        <Nav
                            onLinkClick={_clickNavigate}
                            // onLinkClick={_onLinkClick}
                            selectedKey={key}
                            ariaLabel="Nav basic example"
                            styles={navStyles}
                            groups={navLinkGroups}

                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">

                        {key == 'key1' && (

                            <>
                                <br />
                                <h1>Reporte 1</h1>
                                
                            </>
                        )}

                        {key == 'key2' && (

                            <>
                                 <h1>Reporte 2</h1>
                            </>
                        )}

                       
                    </div>
                </div>
            </div>
        </>
    )
}