import React from "react";
import {
  Panel,
  PanelType,
  ActivityItem,
  FontIcon,
  FontSizes,
  IStackStyles,
  Stack,
  IComboBox,
  IComboBoxOption,
  MessageBar,
  MessageBarType,
  MessageBarButton,
  TextField,
  ComboBox,
  ProgressIndicator,
} from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useBoolean } from "@fluentui/react-hooks";
import { NeutralColors } from "@fluentui/theme";
import ThemeBase from "../../utils/Theme/themeBase";
import { useState } from "react";
import {
  IListarPersonaById,
  IPersona,
  IRegistroPersona,
  IUpdatePersona,
} from "../../interfaces/IPersona";
import {
  IBase,
  IException,
  IItemComboBaseDTO,
  ISearch,
} from "../../interfaces/base/IBase";
import { Ifilter } from "../../interfaces/IFilter";
import {
  ItemNoneSelect,
  splitExceptionDetail,
  _initialFilter,
} from "../../utils/Helper/helper";
import { useEffect } from "react";
import EmpresaService from "../../services/empresaService";
import { IEmpresa } from "../../interfaces/IEmpresa";
import OrganigramaService from "../../services/organigramaService";
import RosterService from "../../services/rosterService";
import { IRoster } from "../../interfaces/IRoster";
import ProgramacionService from "../../services/ProgramacionService";
import ConfiguracionService from "../../services/configuracionService";
import PersonaService from "../../services/PersonaService";
import { Field, InfoLabel, Label } from "@fluentui/react-components";
import { RecortePersona } from "./RecortePersona";

const stackStyles: IStackStyles = {
  root: {
    // background: '#ffff',
  },
};

export interface ImagenData {
  foto: string;
  extension: string;
}

function PanelAgregarPersona({
  isOpenPanel = false,
  dismissPanel = () => {},
  reloadParent = () => {},
}) {
  const [imageUrl, setImageUrl] = useState<string>(
    "https://teseoback.idslatam.com/Content/Resources/Fotos/person.png"
  );
  const [imagen, setImagen] = useState<ImagenData>({
    extension: "",
    foto: "",
  });
  const [dialogRecorte, setDialogRecorte] = useState<boolean>(false);

  const [modelItem, setModelItem] = useState<IRegistroPersona>(null!);
  const [info, setInfo] = useState<IPersona>(null!);
  const [tipoPersona, setTipoPersona] = useState("");
  const [Ubigueo, setUbigueo] = useState<IItemComboBaseDTO[]>([]); //-----------lista------------
  const [Provincias, setProvincias] = useState<IItemComboBaseDTO[]>([]);
  const [Distritos, setDistritos] = useState<IItemComboBaseDTO[]>([]);
  const [comboTipoDocumentos, setComboTipoDocumentos] = useState<
    IItemComboBaseDTO[]
  >([]);

  const [disableForm, setDisableForm] = useState(true);

  const [empresaPersonaCombo, setEmpresaPersonaCombo] = useState<
    IItemComboBaseDTO[]
  >([]);
  const empresaPersonaRef = React.useRef<IComboBox>(null);
  const _openOptionPersona = React.useCallback(
    () => empresaPersonaRef.current?.focus(true),
    []
  );
  const [filterEmpresaPersona, setFilterEmpresaPersona] = useState<Ifilter>({
    ..._initialFilter(),
  });

  const [gerenciaCombo, setGerenciaCombo] = useState<IItemComboBaseDTO[]>([]);
  const gerenciaRef = React.useRef<IComboBox>(null);
  const _openOptionGerencia = React.useCallback(
    () => gerenciaRef.current?.focus(true),
    []
  );
  const [filteGerencia, setFilteGerencia] = useState<ISearch>({
    search: "",
  });

  const [superintendenciaCombo, setSuperintendenciaCombo] = useState<
    IItemComboBaseDTO[]
  >([]);
  const superintendenciaRef = React.useRef<IComboBox>(null);
  const _openOptionSuperintendencia = React.useCallback(
    () => superintendenciaRef.current?.focus(true),
    []
  );
  const [filterSuperintendencia, setFilterSuperintendencia] =
    useState<ISearch>(_initialFilter);

  const [rosterCombo, setRosterCombo] = useState<IItemComboBaseDTO[]>([]);
  const rosterRef = React.useRef<IComboBox>(null);
  const _openOptionRoster = React.useCallback(
    () => rosterRef.current?.focus(true),
    []
  );
  const [filterRoster, setFilterRoster] = useState<Ifilter>(_initialFilter);

  const [lugarMovilizacionCombo, setLugarMovilizacionCombo] = useState<
    IItemComboBaseDTO[]
  >([]);
  const lugarMovilizacionRef = React.useRef<IComboBox>(null);
  const _openOptionMovilizacion = React.useCallback(
    () => lugarMovilizacionRef.current?.focus(true),
    []
  );
  const [filterMovilizacion, setfilterMovilizacion] =
    useState<ISearch>(_initialFilter);

  const [puestoCombo, setPuestoCombo] = useState<IItemComboBaseDTO[]>([]);

  const [searchPuesto, setSearchPuesto] = useState("");
  const puestoRef = React.useRef<IComboBox>(null);
  const _openOptionPuesto = React.useCallback(
    () => puestoRef.current?.focus(true),
    []
  );

  const [nombrePuesto, setNombrePuesto] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");

  const [nivelCombo, setNivelCombo] = useState<IItemComboBaseDTO[]>([]);
  const [filterNivel, setFilterNivel] = useState<ISearch>({
    search: "",
  });

  const [levelCombo, setLevelCombo] = useState<IItemComboBaseDTO[]>([]);
  const [filterLevel, setFilterLevel] = useState<ISearch>({
    search: "",
  });

  const [personaTipoCombo, setPersonaTipoCombo] = useState<IItemComboBaseDTO[]>(
    []
  );
  const [searchTipo, setSearchTipo] = useState("");

  const [personaEstadoCombo, setPersonaEstadoCombo] = useState<
    IItemComboBaseDTO[]
  >([]);

  const [comboSexo, setComboSexo] = useState<IItemComboBaseDTO[]>([
    { key: "M", text: "Masculino" },
    { key: "F", text: "Femenino" },
  ]);

  const [searchEstado, setSearchEstado] = useState("");

  //mensajes de estado
  const [verLoading, setVerLoading] = useState(false);
  const [mensajeTransaccion, setMensajeTransaccion] = useState("");
  const [tipoMensaje, setTipoMensaje] = useState(0);
  const [verLoadingBoton, setVerLoadingBoton] = useState(false);

  /* useEffect(() => {
        dataEmpresaPersona();

    }, [filterEmpresaPersona]); */

  useEffect(() => {
    dataGerencia();
  }, [filteGerencia]);

  useEffect(() => {
    dataSuperintendencia();
  }, [filterSuperintendencia]);

  useEffect(() => {
    dataRoster();
  }, [filterRoster, modelItem?.tipoId]);

  useEffect(() => {
    /* if (filterMovilizacion.search != null) {
            
        } */

    dataLugarMovilizacion();
  }, [filterMovilizacion, modelItem?.tipoId]);

  useEffect(() => {
    dataNivel();
  }, [filterNivel, modelItem?.tipoId]);

  useEffect(() => {
    if (imagen.extension != "" && imagen.foto != "") {
      setModelItem({
        ...modelItem,
        foto: imagen.foto,
        extension: imagen.extension,
      });
    }
  }, [imagen]);

  useEffect(() => {
    dataLevel();
  }, [filterLevel, modelItem?.tipoId]);

  useEffect(() => {
    dataPersonaEstado();
  }, [searchEstado]);

  useEffect(() => {
    dataPersonaTipo();
  }, [searchTipo]);

  useEffect(() => {
    // dataPuesto();

    if (isOpenPanel === true) {
      /* console.log("----------------------------------");
            console.log("panel Abierto Puesto?: " + isOpenPanel); */

      PersonaService.getPuestoCombo(searchPuesto)
        .then((res) => {
          if (res.status == 200) {
            let d: IBase[] = res.data.data;

            let t: IItemComboBaseDTO[] = d.map((x) => {
              return {
                key: x.id,
                text: x.nombre,
              };
            });
            setPuestoCombo(t);

            // setPuestoCombo(t);
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
      //DataDepartamentos
      dataDepartamento();
    } else {
      // console.log("----------------------------------");
      // console.log("panel Abierto?: " + isOpenPanel);
      puestoCombo.splice(0, 1);
      setPuestoCombo(puestoCombo);
      setImagen({ extension: "", foto: "" });
    }
  }, [searchPuesto, isOpenPanel, nombrePuesto]);

  useEffect(() => {
    // dataPuesto();

    if (isOpenPanel === true) {
      // console.log("----------------------------------");
      // console.log("panel Abierto?: " + isOpenPanel);

      EmpresaService.all(filterEmpresaPersona)
        .then((res) => {
          if (res.status == 200) {
            let d: IEmpresa[] = res.data.items;

            let t: IItemComboBaseDTO[] = d.map((x) => {
              return {
                key: x.empresaId,
                text: x.nombre as string,
              };
            });

            setEmpresaPersonaCombo(t);

            // setPuestoCombo(t);
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    } else {
      // console.log("----------------------------------");
      // console.log("panel Abierto?: " + isOpenPanel);

      empresaPersonaCombo.splice(0, 1);
      setEmpresaPersonaCombo(empresaPersonaCombo);
      dataTipoDocumento();
    }
  }, [filterEmpresaPersona?.search, isOpenPanel, nombreEmpresa]);

  const dataGerencia = () => {
    // setGerenciaCombo([]);
    OrganigramaService.organigramaByTipo("01", filteGerencia)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          // t.unshift(ItemNoneSelect());
          setGerenciaCombo(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataSuperintendencia = () => {
    // setArea([]);
    OrganigramaService.organigramaByTipo("06", filterSuperintendencia)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          // t.unshift(ItemNoneSelect());

          setSuperintendenciaCombo(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataRoster = () => {
    // setArea([]);
    RosterService.all(filterRoster)
      .then((res) => {
        if (res.status == 200) {
          let d: IRoster[] = res.data;

          let t: IItemComboBaseDTO[] = d.map((x) => {
            return {
              key: x.id,
              text: x.codigo,
            };
          });

          if (modelItem?.tipo == "SSEE") {
            // console.log("----------------- persona tipo ---------------");
            // console.log(personaTipo);

            t.unshift(ItemNoneSelect());
            setRosterCombo(t);
          } else {
            setRosterCombo(t);
          }

          setRosterCombo(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataLugarMovilizacion = () => {
    ProgramacionService.allLugarMovilizacion()
      .then((res) => {
        // console.log("************** lugar **********");
        // console.log(res.data);
        let t: IItemComboBaseDTO[] = res.data;

        if (modelItem?.tipo == "SSEE") {
          t.unshift(ItemNoneSelect());
          setLugarMovilizacionCombo(t);
        } else {
          setLugarMovilizacionCombo(t);
        }
        setLugarMovilizacionCombo(t);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataNivel = () => {
    ConfiguracionService.allNivel(filterNivel)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;

          if (modelItem?.tipo == "SSEE") {
            t.unshift(ItemNoneSelect());
            setNivelCombo(t);
          } else {
            setNivelCombo(t);
          }
          setNivelCombo(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataLevel = () => {
    ConfiguracionService.allLevel(filterLevel)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;

          if (modelItem?.tipo == "SSEE") {
            t.unshift(ItemNoneSelect());
            setLevelCombo(t);
          } else {
            setLevelCombo(t);
          }

          setLevelCombo(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataPersonaTipo = () => {
    PersonaService.getPersonaTipo(searchTipo)
      .then((res) => {
        if (res.status == 200) {
          let d: IBase[] = res.data.data;

          let t: IItemComboBaseDTO[] = d.map((x) => {
            return {
              key: x.id,
              text: x.nombre,
            };
          });
          // t.unshift(ItemSelectAll());
          setPersonaTipoCombo(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataPersonaEstado = () => {
    PersonaService.getPersonaEstado(searchEstado)
      .then((res) => {
        if (res.status == 200) {
          let d: IBase[] = res.data.data;

          let t: IItemComboBaseDTO[] = d.map((x) => {
            return {
              key: x.id,
              text: x.nombre,
            };
          });

          // t.unshift(ItemSelectAll());
          setPersonaEstadoCombo(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  /* const dataPuesto = () => {

        PersonaService.getPuestoCombo(searchPuesto)
            .then((res) => {
                if (res.status == 200) {

                    let d: IBase[] = res.data.data;

                    let t: IItemComboBaseDTO[] = d.map(x => {
                        return {
                            key: x.id,
                            text: x.nombre
                        }
                    });

                    setPuestoCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    }; */

  const AgregarPersona = () => {
    console.log("************ persona ******************");
    // console.log(modelItem);

    let modelUpdatePersona: IRegistroPersona = {
      tipoDocumentoIdentidad: modelItem.tipoDocumentoIdentidad,
      apellidoPaterno: modelItem.apellidoPaterno,
      apellidoMaterno:
        modelItem?.apellidoMaterno != null &&
        modelItem?.apellidoMaterno != "" &&
        modelItem.apellidoMaterno != undefined
          ? modelItem.apellidoMaterno
          : null,

      primerNombre: modelItem.primerNombre,
      segundoNombre:
        modelItem?.segundoNombre != null &&
        modelItem.segundoNombre != undefined &&
        modelItem?.segundoNombre != ""
          ? modelItem.segundoNombre
          : null,
      nroDOI: modelItem.nroDOI,
      sexo: modelItem.sexo,
      email:
        modelItem?.email != null &&
        modelItem.email != undefined &&
        modelItem?.email != ""
          ? modelItem.email
          : null,
      celular:
        modelItem?.celular != null &&
        modelItem.celular != undefined &&
        modelItem?.celular != ""
          ? modelItem.celular
          : null,
      estadoId: modelItem.estadoId,
      tipoId: modelItem.tipoId,
      catalogoRosterId:
        modelItem?.catalogoRosterId != undefined &&
        modelItem?.catalogoRosterId != null &&
        modelItem.catalogoRosterId != "-1" &&
        modelItem?.catalogoRosterId != ""
          ? modelItem.catalogoRosterId
          : null,
      lugarMovilizacionId:
        modelItem?.lugarMovilizacionId != undefined &&
        modelItem?.lugarMovilizacionId != null &&
        modelItem.lugarMovilizacionId != "-1" &&
        modelItem?.lugarMovilizacionId != ""
          ? modelItem.lugarMovilizacionId
          : null,
      nivelId:
        modelItem?.nivelId != undefined &&
        modelItem?.nivelId != null &&
        modelItem.nivelId != "-1" &&
        modelItem?.nivelId != ""
          ? modelItem.nivelId
          : null,
      levelId:
        modelItem?.levelId != undefined &&
        modelItem?.levelId != null &&
        modelItem.levelId != "-1" &&
        modelItem?.levelId != ""
          ? modelItem.levelId
          : null,
      foto:
        modelItem?.foto != undefined &&
        modelItem?.foto != null &&
        modelItem?.foto != ""
          ? modelItem.foto
          : null,
      extension:
        modelItem?.extension != undefined &&
        modelItem?.extension != null &&
        modelItem?.extension != ""
          ? modelItem.extension
          : null,
      empresaId: modelItem.empresaId,
      areaId: modelItem.areaId,

      areaReporteId: modelItem.areaReporteId,
      puestoId: modelItem.puestoId,

      ubigeoId:
        modelItem?.ubigeoId != undefined &&
        modelItem?.ubigeoId != null &&
        modelItem?.ubigeoId != ""
          ? modelItem.ubigeoId
          : null,
    };

    // console.log(modelUpdatePersona);
    setVerLoading(true);
    setVerLoadingBoton(true);
    setDisableForm(true);

    PersonaService.addPersonaMMG(modelUpdatePersona)
      .then((res) => {
        if (res.status == 200) {
          // alert("Se registró correctamente!!");
          setMensajeTransaccion("Se ha actualizado correctamente");
          setTipoMensaje(2);
          setVerLoading(false);
          setVerLoadingBoton(true);
          setModelItem(null!!);
          setImagen({ extension: "", foto: "" });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // alert(e.message);
          setMensajeTransaccion(error.response.data.detail);

          setTipoMensaje(1);
          setVerLoading(false);
        }
      });
  };

  const _onChangeTextEditarPersona = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        let nameInput = event.currentTarget.getAttribute("name");

        switch (nameInput) {
          case "primerNombre":
            setModelItem({
              ...modelItem,
              primerNombre: newValue.toUpperCase(),
            });

            break;
          case "segundoNombre":
            setModelItem({
              ...modelItem,
              segundoNombre: newValue,
            });

            break;
          case "primerApellido":
            setModelItem({
              ...modelItem,
              apellidoPaterno: newValue.toUpperCase(),
            });

            break;
          case "segundoApellido":
            setModelItem({
              ...modelItem,
              apellidoMaterno: newValue,
            });

            break;
          case "email":
            setModelItem({
              ...modelItem,
              email: newValue,
            });

            break;
          case "celular":
            setModelItem({
              ...modelItem,
              celular: newValue,
            });

            break;
          case "doi":
            // Filtrar solo números
            var numerosLimitados = newValue;
            if (modelItem.tipoDocumentoIdentidad === "DNI") {
              const numeros = newValue.replace(/\D/g, "");
              numerosLimitados = numeros.slice(0, 8);
            }
            // Limitar a 8 caracteres
            setModelItem({
              ...modelItem,
              nroDOI: numerosLimitados,
            });

            break;

          default:
            break;
        }
      }
    }
  };

  const _onChangeComboAgregarPersona = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "empresa":
            setModelItem({
              ...modelItem,
              empresaId: option.key.toString(),
            });
            // setEmpresaId(option.key.toString());

            break;

          case "sexo":
            setModelItem({
              ...modelItem,
              sexo: option.key.toString(),
            });
            // setEmpresaId(option.key.toString());
            break;
          case "gerencia":
            setModelItem({
              ...modelItem,
              areaReporteId: option.key.toString(),
            });

            _ObtenerSuperIntendencia(option.key.toString());

            break;

          case "superintendencia":
            setModelItem({
              ...modelItem,
              areaId: option.key.toString(),
            });

            break;
          case "roster":
            /* console.log("----------------- roster combo ---------------")
                        console.log(option.key.toString()) */

            setModelItem({
              ...modelItem,
              catalogoRosterId: option.key.toString(),
            });

            break;
          case "lugarMovilizacion":
            setModelItem({
              ...modelItem,
              lugarMovilizacionId: option.key.toString(),
            });

            break;
          case "nivel":
            setModelItem({
              ...modelItem,
              nivelId: option.key.toString(),
            });

            break;
          case "level":
            setModelItem({
              ...modelItem,
              levelId: option.key.toString(),
            });

            break;
          case "tipoPersona":
            setModelItem({
              ...modelItem,
              tipoId: option.key.toString(),
              tipo: option.text,
            });
            break;
          case "estadoPersona":
            setModelItem({
              ...modelItem,
              estadoId: option.key.toString(),
            });
            break;
          case "puesto":
            setModelItem({
              ...modelItem,
              puestoId: option.key.toString(),
            });
            break;

          case "departamento":
            setModelItem({
              ...modelItem,
              departamentoId: String(option.key) as string,
            });
            _LoadProvincia(option.key);
            break;

          case "provincia":
            setModelItem({
              ...modelItem,
              provinciaId: String(option.key) as string,
            });
            _LoadDistritos(option.key);
            break;

          case "distrito":
            setModelItem({
              ...modelItem,
              ubigeoId: String(option.key) as string,
            });
            break;

          case "tipoDoc":
            setModelItem({
              ...modelItem,
              tipoDocumentoIdentidad: String(option.text) as string,
              tipoDocumentoIdentidadId: String(option.key) as string,
              nroDOI: "",
            });

            // setModelItem({
            //   ...modelItem,
            //   nroDOI: "",
            // });
            break;

          default:
            break;
        }
      }
    }
  };

  const _ObtenerSuperIntendencia = (key: any) => {
    OrganigramaService.allComboSuperIntendencia(key, "")
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setSuperintendenciaCombo(t);
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  const _onKeyUpCombo = (e: React.FormEvent<IComboBox>) => {
    let { id } = e?.target as HTMLInputElement;
    let { value } = e?.target as HTMLInputElement;

    switch (id) {
      case "empresa-input":
        _openOptionPersona();
        setFilterEmpresaPersona({
          ...filterEmpresaPersona,
          search: value,
        });

        break;
      case "gerencia-input":
        _openOptionGerencia();

        setFilteGerencia({
          ...filteGerencia,
          search: value,
        });

        break;
      case "superintendencia-input":
        _openOptionSuperintendencia();
        setFilterSuperintendencia({
          ...filterSuperintendencia,
          search: value,
        });

        break;

      case "roster-input":
        _openOptionRoster();
        setFilterRoster({
          ...filterRoster,
          search: value,
        });

        break;
      case "lugarMovilizacion-input":
        _openOptionMovilizacion();
        setfilterMovilizacion({
          ...filterMovilizacion,
          search: value,
        });

        break;
      case "puesto-input":
        _openOptionPuesto();
        setSearchPuesto(value);

        break;
      default:
        break;
    }
  };

  const _goBack = () => {
    // dataTablaMarca(search);
    reloadParent();
    setVerLoadingBoton(false);
    setTipoMensaje(3);
    setModelItem(null!!);
    setImagen({
      extension: "",
      foto: "",
    });
    setVerLoading(false);
    dismissPanel();
  };

  const _LoadProvincia = (key: any) => {
    PersonaService.getComboUbigueo(key)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setProvincias(t);
      })
      .catch((e) => {
        alert(e.message);
      });
  };

  const _LoadDistritos = (key: any) => {
    PersonaService.getComboUbigueo(key)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setDistritos(t);
      })
      .catch((e) => {
        return alert(e.message);
      });
  };

  const dataDepartamento = () => {
    PersonaService.getComboUbigueo()
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        setUbigueo(t);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataTipoDocumento = () => {
    PersonaService.getTiposdocumentos()
      .then((res) => {
        if (res.status === 200) {
          setComboTipoDocumentos(res.data);
          console.log(res.data);
        }
      })
      .catch((e: IException) => {
        // alert(splitExceptionDetail(e));
        console.log(e);
      });
  };
  useEffect(() => {
    validarForm();
  }, [modelItem, isOpenPanel]);

  const validarForm = () => {
    if (modelItem == null) setDisableForm(true);
    if (modelItem !== null) {
      if (
        modelItem.tipoDocumentoIdentidad &&
        modelItem.nroDOI &&
        modelItem.sexo &&
        modelItem.primerNombre &&
        modelItem.apellidoPaterno &&
        modelItem.tipoId &&
        modelItem.estadoId &&
        modelItem.empresaId &&
        modelItem.puestoId &&
        modelItem.areaId &&
        modelItem.areaReporteId
      ) {
        setDisableForm(false);
      } else {
        setDisableForm(true);
      }
    }
  };

  const onRenderHeader = React.useCallback(
    () => (
      <>
        <div
          className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
          style={{ paddingLeft: "2em" }}
        >
          <ActivityItem
            style={{
              marginTop: 3,
            }}
            activityDescription={[
              <div>
                <div className="LayoutPage-demoBlock">
                  <div
                    style={{
                      color: NeutralColors.gray130,
                      fontSize: FontSizes.size18,
                    }}
                  >
                    Agregar Persona
                  </div>
                </div>
              </div>,
            ]}
            activityIcon={
              <FontIcon
                iconName="Contact"
                style={{
                  color: ThemeBase.theme.palette.blackTranslucent40,
                  fontSize: 35,
                  height: 35,
                  width: 35,
                  marginTop: 10,
                  // margin: "0 5px",
                }}
              />
            }
            comments={[
              <div className="LayoutPage-demoBlock">
                <div
                  style={{
                    color: NeutralColors.gray130,
                    fontSize: FontSizes.size12,
                  }}
                >
                  Agregar datos de la persona:
                </div>
                <br />
                <br />
              </div>,
            ]}
            // timeStamp={}
            className={ThemeBase.activityClass.exampleRoot}
          />
        </div>
      </>
    ),
    []
  );

  const onRenderFooter = React.useCallback(
    () => (
      <>
        {verLoadingBoton == false && (
          <>
            <Stack horizontal horizontalAlign="end" styles={stackStyles}>
              {/* <DefaultButton onClick={onclickBotonDefault}>{textBotonDefault}</DefaultButton> */}
              <PrimaryButton
                onClick={AgregarPersona}
                style={{ marginLeft: "12px", width: "100%" }}
                text="Agregar"
                disabled={disableForm}
              />
            </Stack>
          </>
        )}
      </>
    ),
    [modelItem, isOpenPanel, AgregarPersona, reloadParent]
  );

  const openDialog = () => {
    setDialogRecorte(true);
  };
  const closeDialog = () => {
    setDialogRecorte(false);
    setImagen({
      extension: "",
      foto: "",
    });
  };
  return (
    <>
      <Panel
        isOpen={isOpenPanel}
        onDismiss={_goBack}
        onRenderHeader={onRenderHeader}
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooter}
        isFooterAtBottom={true}
        isLightDismiss
      >
        <>
          {/* <pre>{JSON.stringify(modelItem, null, 2)}</pre> */}
          {tipoMensaje == 1 && (
            <>
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                //onDismiss={p.resetChoice}
                dismissButtonAriaLabel="Close"
                //truncated={true}
                //overflowButtonAriaLabel="See more"
              >
                <b>{mensajeTransaccion}</b>{" "}
              </MessageBar>
              <br></br>
            </>
          )}
          {tipoMensaje == 2 && (
            <>
              <MessageBar
                actions={
                  <div>
                    <MessageBarButton onClick={() => _goBack()}>
                      Conforme
                    </MessageBarButton>
                  </div>
                }
                messageBarType={MessageBarType.success}
                isMultiline={false}
              >
                {mensajeTransaccion}
              </MessageBar>
              <br></br>
            </>
          )}
          {!verLoading && (
            <form action="">
              <div className="ms-Grid" dir="ltr">
                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        
                                        <pre>{JSON.stringify(modelItem, null, 2)}</pre>
                                    </div>

                                </div> */}

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <div
                      style={{
                        width: "100%",
                        height: "320px",
                        border: "1px  red",
                        boxSizing: "border-box",
                        padding: "5px 20px",
                      }}
                    >
                      <img
                        alt="Sin imagen"
                        src={imagen.foto != "" ? imagen.foto : imageUrl}
                        height="92%"
                        width="100%"
                        // shape="rounded"
                      />
                      <DefaultButton
                        text="Cargar nueva imagen"
                        iconProps={{ iconName: "Upload" }}
                        onClick={() => {
                          openDialog();
                          console.log("CLICK");
                        }}
                        style={{ width: "100%", border: "none" }}
                        allowDisabledFocus
                        disabled={false}
                        // checked={checked}
                      />

                      {/* <Button
                        appearance={"subtle"}
                        icon={<ArrowUpload24Regular />}
                        style={{ width: "100%" }}
                        onClick={() => {
                          openDialog();
                          console.log("CLICK");
                        }}
                      >
                        Cargar nueva imagen
                      </Button> */}
                    </div>
                  </div>
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Field
                          label={
                            <InfoLabel info="Información">
                              Descripción{" "}
                            </InfoLabel>
                          }
                          style={{ fontWeight: "600" }}
                        >
                          <Label style={{ fontWeight: "400" }}>
                            <Label required>Considerar los campos con </Label>{" "}
                            como necesarios para el registro de un nuevo usuario
                          </Label>
                        </Field>
                      </div>
                    </div>
                    <br />
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ComboBox
                          label="Tipo de Documento de Identidad:"
                          id="tipoDocumento"
                          required
                          options={comboTipoDocumentos}
                          // componentRef={empresaRef}
                          selectedKey={modelItem?.tipoDocumentoIdentidadId}
                          calloutProps={{ directionalHintFixed: true }}
                          useComboBoxAsMenuWidth
                          allowFreeform
                          onChange={(e, o) =>
                            _onChangeComboAgregarPersona("tipoDoc", e, o)
                          }
                          // onKeyUp={_onKeyUpCombo}
                          // onBlur={_onBlurEmpresa}
                        />
                        <TextField
                          label="Número de Documento(DOI)"
                          required
                          placeholder=""
                          name="doi"
                          value={modelItem?.nroDOI}
                          onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                        />
                        <ComboBox
                          label="Sexo:"
                          id="sexo"
                          required
                          options={comboSexo}
                          // componentRef={empresaRef}
                          selectedKey={modelItem?.sexo}
                          calloutProps={{ directionalHintFixed: true }}
                          useComboBoxAsMenuWidth
                          allowFreeform
                          onChange={(e, o) =>
                            _onChangeComboAgregarPersona("sexo", e, o)
                          }
                          // onKeyUp={_onKeyUpCombo}
                          // onBlur={_onBlurEmpresa}
                        />
                      </div>
                    </div>
                    <br />

                    <br />
                  </div>
                </div>
                <br />

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label="Primer Nombre: "
                      required
                      placeholder=""
                      name="primerNombre"
                      value={modelItem?.primerNombre}
                      onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label="Segundo Nombre: "
                      placeholder=""
                      name="segundoNombre"
                      value={modelItem?.segundoNombre!}
                      onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label="Apellido Paterno: "
                      placeholder=""
                      required
                      name="primerApellido"
                      value={modelItem?.apellidoPaterno}
                      onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label="Apellido Materno: "
                      placeholder=""
                      name="segundoApellido"
                      value={modelItem?.apellidoMaterno!}
                      onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label="Email: "
                      placeholder=""
                      type="email"
                      name="email"
                      value={modelItem?.email!}
                      onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label="N° Celular:"
                      placeholder=""
                      name="celular"
                      value={modelItem?.celular!}
                      onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Tipo:"
                      id="tipoPersona"
                      options={personaTipoCombo}
                      required
                      // componentRef={empresaRef}
                      selectedKey={modelItem?.tipoId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("tipoPersona", e, o)
                      }
                      // onKeyUp={_onKeyUpCombo}
                      // onBlur={_onBlurEmpresa}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Estado:"
                      id="estadoPersona"
                      required
                      options={personaEstadoCombo}
                      // componentRef={empresaRef}
                      selectedKey={modelItem?.estadoId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("estadoPersona", e, o)
                      }
                      // onKeyUp={_onKeyUpCombo}
                      // onBlur={_onBlurEmpresa}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Empresa:"
                      id="empresa"
                      required
                      options={empresaPersonaCombo}
                      componentRef={empresaPersonaRef}
                      selectedKey={modelItem?.empresaId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("empresa", e, o)
                      }
                      onKeyUp={_onKeyUpCombo}
                      // onBlur={_onBlurEmpresa}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Puesto:"
                      id="puesto"
                      required
                      options={puestoCombo}
                      componentRef={puestoRef}
                      selectedKey={modelItem?.puestoId}
                      defaultSelectedKey={modelItem?.puestoId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("puesto", e, o)
                      }
                      onKeyUp={_onKeyUpCombo}
                      // onBlur={_onBlurEmpresa}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Gerencia:"
                      id="gerencia"
                      required
                      options={gerenciaCombo}
                      componentRef={gerenciaRef}
                      selectedKey={modelItem?.areaReporteId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("gerencia", e, o)
                      }
                      onKeyUp={_onKeyUpCombo}
                      // onBlur={_onBlurEmpresa}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Superintendencia:"
                      id="superintendencia"
                      required
                      options={superintendenciaCombo}
                      componentRef={superintendenciaRef}
                      selectedKey={modelItem?.areaId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("superintendencia", e, o)
                      }
                      onKeyUp={_onKeyUpCombo}
                      // onBlur={_onBlurEmpresa}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Roster:"
                      id="roster"
                      options={rosterCombo}
                      componentRef={rosterRef}
                      selectedKey={modelItem?.catalogoRosterId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("roster", e, o)
                      }
                      onKeyUp={_onKeyUpCombo}
                      // onBlur={_onBlurEmpresa}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Lugar Movilización:"
                      id="lugarMovilizacion"
                      options={lugarMovilizacionCombo}
                      componentRef={lugarMovilizacionRef}
                      selectedKey={modelItem?.lugarMovilizacionId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("lugarMovilizacion", e, o)
                      }
                      onKeyUp={_onKeyUpCombo}
                      // onBlur={_onBlurEmpresa}
                    />
                  </div>
                </div>
                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <pre>nombre puesto: {nombrePuesto}</pre>
                                        <pre>cantidad: {nombrePuesto?.length}</pre>
                                        <pre>{JSON.stringify(puestoCombo, null, 2)}</pre>
                                    </div>

                                </div> */}
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Nivel:"
                      id="nivel"
                      options={nivelCombo}
                      selectedKey={modelItem?.nivelId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("nivel", e, o)
                      }
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <ComboBox
                      label="Level:"
                      id="level"
                      options={levelCombo}
                      selectedKey={modelItem?.levelId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("level", e, o)
                      }
                    />
                  </div>
                </div>
                <br />

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Departamento"
                      id="departamento"
                      options={Ubigueo}
                      selectedKey={modelItem?.departamentoId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("departamento", e, o)
                      }
                    />
                  </div>
                </div>

                <br />

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Provincia"
                      id="provincia"
                      options={Provincias}
                      selectedKey={modelItem?.provinciaId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("provincia", e, o)
                      }
                    />
                  </div>
                </div>

                <br />

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Distrito"
                      id="distrito"
                      options={Distritos}
                      selectedKey={modelItem?.ubigeoId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) =>
                        _onChangeComboAgregarPersona("distrito", e, o)
                      }
                    />
                  </div>
                </div>
                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Tarjeta: "
                                            name='tarjeta'
                                            value={modelItem?.codigoEnrolar != null ? modelItem?.codigoEnrolar : ""}
                                            onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Tipo:"
                                            id="tipoPersona"

                                            options={personaTipoCombo}
                                            // componentRef={empresaRef}
                                            selectedKey={modelItem?.tipoId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboAgregarPersona("tipoPersona", e, o)}
                                        // onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div> */}
              </div>
            </form>
          )}
          {verLoading && (
            <>
              <br></br>
              <ProgressIndicator
                barHeight={5}
                label="Enviando información"
                description="Favor Espere un momento"
              />
            </>
          )}

          <RecortePersona
            isOpen={dialogRecorte}
            onClose={closeDialog}
            setImagen={setImagen}
          />
        </>
      </Panel>
    </>
  );
}

export { PanelAgregarPersona };
