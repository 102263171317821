import {
  DetailsListLayoutMode,
  DialogType,
  IColumn,
  ICommandBarItemProps,
  Icon,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { filter } from "d3";
import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { TableBase } from "../../../components/Table/TableBase";
import { IException } from "../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { Ifilter } from "../../../interfaces/IFilter";
import {
  IOrganigramaPorSupervisor,
  ISupervisorOrganigrama,
} from "../../../interfaces/IOrganigrama";
import OrganigramaService from "../../../services/organigramaService";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import {
  splitExceptionDetail,
  stackTokensHorizontal,
  _initialFilter,
  _initialPagination,
} from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { DialogBase } from "../../../components/Dialog/DialogBase";
import { AsignarOrganigrama } from "./panel/asignarOrganigrama";

export const ListarGerenciaAsignada = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);

  const [hidden, setHidden] = useState(false);
  const [data, setData] = useState<IDataCollection<IOrganigramaPorSupervisor>>(
    _initialDataCollection
  );
  const [column, setColumn] = useState<IColumn[]>([]);
  const [filter, setFilter] = useState<Ifilter>(_initialFilter);

  /* Panel */
  const [isOpenForm, { setTrue: openForm, setFalse: dismissForm }] =
    useBoolean(false);

  /* Dialog */
  const [selId, setSelId] = useState<string | undefined>(undefined);
  const [hideEliminar, { toggle: toggleEliminar }] = useBoolean(true);

  /* Loading */
  const [
    isLoadingELiminar,
    { setTrue: loadingEliminar, setFalse: dismissLoadingEliminar },
  ] = useBoolean(false);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  /* Hook */
  useEffect(() => {
    reloadInitial();
  }, []);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  /* Api */
  const dataOrganigrama = (param = filter) => {
    setData(_initialDataCollection);
    OrganigramaService.allOrganigramaPorSupervisor(
      id as string,
      getTipo(),
      param
    )
      .then((res) => {
        if (res.status == 200) {
          setData(res.data);
          setTotal(res.data.total);
        }
        setHidden(true);
      })
      .catch((e: IException) => {
        setHidden(true);
        alert(splitExceptionDetail(e));
      });
  };

  const eliminarGerencia = () => {
    if (selId != undefined) {
      loadingEliminar();

      OrganigramaService.eliminarAsignacion(selId)
        .then((res) => {
          if (res.status == 200) {
            reloadInitial();
            toggleEliminar();
            dismissLoadingEliminar();
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
          dismissLoadingEliminar();
        });
    }
  };

  /* Function */
  const reloadInitial = (param = filter) => {
    setHidden(false);
    dataOrganigrama(param);
    setTitleColumn();
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        // onClick: () => navigate(`${ConfigHelper.urlBase()}/configuraciones/asignacion.gerencia`)
        onClick: () => navigate(-1),
      },
      {
        ...IconHelper.ICON_ADD,
        onClick: openForm,
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => reloadInitial(),
      },
    ];
    return _items;
  };

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Usuario Supervisor",
        fieldName: "supervisor",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Tipo",
        fieldName: "tipo",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Gerencia",
        fieldName: "organigrama",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: IOrganigramaPorSupervisor) => {
          return (
            <span
              style={{
                textDecorationLine: item.fin == "" ? "none" : "line-through",
              }}
            >
              {" "}
              {item.organigrama}
            </span>
          );
        },
      },
      {
        key: "column4",
        name: "Fecha Inicio",
        fieldName: "inicio",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: "Fecha Fin",
        fieldName: "fin",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column6",
        name: "Fecha Registro",
        fieldName: "created",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column7",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IOrganigramaPorSupervisor) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              <>
                {item.fin == "" && (
                  <TooltipHost content={`Eliminar`}>
                    <Icon
                      iconName="Delete"
                      style={{ cursor: "pointer" }}
                      className={ThemeBase.classes.iconFont}
                      onClick={() => {
                        setSelId(item.supervisorOrganigramaId);
                        toggleEliminar();
                      }}
                    />
                  </TooltipHost>
                )}
              </>
            </Stack>
          </>
        ),
      },
    ];

    setColumn(col);
  };

  const getTipo = () => {
    let t = !isNaN(query.get("type") as any)
      ? parseInt(query.get("type") as any)
      : 0;
    return t;
  };

  /* Event Pagination */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: Ifilter = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      };

      setFilter(f);
      setCurrentPage(page);

      reloadInitial(f);
    }
  };

  /* Event Filter */
  const _onChangeTextFilter = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      if (newValue === "") {
        _onClearFilter();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        });
      }
    }
  };

  const _onPressFilter = (newValue: string) => {
    let f: Ifilter = {
      ...filter,
      search: newValue,
    };

    setFilter(f);
    reloadInitial(f);
  };

  const _onClearFilter = () => {
    let f: Ifilter = {
      ..._initialFilter(),
    };

    setFilter(f);
    reloadInitial(f);
  };

  return (
    <>
      <HeadingBar
        title="Usuarios"
        subTitle="Gestión de Usuarios"
        searchChange={_onChangeTextFilter}
        searchHandler={_onPressFilter}
        searchValue={filter.search ?? ""}
        onClearHandler={_onClearFilter}
      ></HeadingBar>

      <TableBase
        leftBar={_leftBar()}
        column={column}
        data={data.items}
        pagination={pager}
        isLoading={!hidden}
      ></TableBase>

      <AsignarOrganigrama
        supervisorId={id}
        tipo={getTipo()}
        isOpen={isOpenForm}
        dissmiss={dismissForm}
        reloadInitial={reloadInitial}
      ></AsignarOrganigrama>

      <DialogBase
        isHidden={hideEliminar}
        subTitle={"Se eliminará la gerencia asignada"}
        tipo={DialogType.normal}
        toggleHidden={() => {
          dismissLoadingEliminar();
          toggleEliminar();
        }}
        dependency={[]}
        confirm={eliminarGerencia}
        isDisableConfirm={isLoadingELiminar}
      ></DialogBase>
    </>
  );
};
