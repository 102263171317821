import { Label, Pivot, PivotItem } from "@fluentui/react";
import { useEffect } from "react"
import { ListarAsignadoVuelo } from "./listarAsigandoVuelo";
import { ListarEmbarcadoVuelo } from "./listarEmbarcadoVuelo";
import { ListarPendienteVuelo } from "./listarPendienteVuelo";

export const TabAsignacionVuelo = ({ programacionId = '', isBajada = false }) => {
    /* Hook */
    useEffect(() => {
        if (programacionId != '') {

        }
    }, [programacionId]);

    return (
        <>
            <Pivot
                aria-label="Pivot Overflow Menu Example"
                linkFormat={'tabs'}
                overflowBehavior={'none'}
                overflowAriaLabel="more items"
                style={{ paddingTop: '10px' }}
            >
                <PivotItem headerText='Pendientes'>
                    <ListarPendienteVuelo programacionId={programacionId} isBajada={isBajada}></ListarPendienteVuelo>
                </PivotItem>
                <PivotItem headerText='Asignados'>
                    <ListarAsignadoVuelo programacionId={programacionId}></ListarAsignadoVuelo>
                </PivotItem>
                <PivotItem headerText='Embarcados'>
                    <ListarEmbarcadoVuelo programacionId={programacionId}></ListarEmbarcadoVuelo>
                </PivotItem>
            </Pivot>
        </>
    )
}