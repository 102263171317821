import { Pivot, PivotItem } from "@fluentui/react"
import { HeadingBar } from "../../../components/Generic/HeadingBar"
import { TAB_BAJADA, TAB_SUBIDA } from "../../../utils/Helper/helper"
import { ProgAdicionalLogistica } from "./tab/progAdicionalLogistica"

export const TabProgAdicionalLogistica = () => {
    return (
        <>
            <HeadingBar
                title='Programaciones'
                subTitle='Gestión de programaciones'
                searchShow={false}
            ></HeadingBar>

            <div style={{ padding: '20px', paddingTop: '10px' }}>
                <Pivot
                    aria-label="Pivot Overflow Menu Example"
                    // linkFormat={'tabs'}
                    overflowBehavior={'none'}
                    overflowAriaLabel="more items"
                    style={{ paddingTop: '10px' }}
                >
                    <PivotItem headerText={TAB_SUBIDA}>
                        <ProgAdicionalLogistica actividad={1}></ProgAdicionalLogistica>
                    </PivotItem>
                    <PivotItem headerText={TAB_BAJADA}>
                        <ProgAdicionalLogistica actividad={2}></ProgAdicionalLogistica>
                    </PivotItem>
                </Pivot>
            </div>
        </>
    )
}