import { IColumn, ICommandBarItemProps, Icon, Stack, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadingBar } from "../../components/Generic/HeadingBar"
import { TableBase } from "../../components/Table/TableBase";
import { IException } from "../../interfaces/base/IBase";
import { IDataCollection } from "../../interfaces/base/ITable";
import { Ifilter } from "../../interfaces/IFilter";
import { ITransporte } from "../../interfaces/ITransporte";
import TransporteService from "../../services/transporteService";
import { splitExceptionDetail, stackTokensHorizontal, _initialFilter, _onRenderHeader, _onRenderRow } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import { ValidarVehiculo } from "./validarVehiculo";
import { ConfigHelper } from "../../utils/Helper/configHelper";

export const ListaValidarVehiculo = () => {
    let navigate = useNavigate();

    const [hidden, setHidden] = useState(false);
    const [column, setColumn] = useState<IColumn[]>([]);
    const [data, setData] = useState<IDataCollection<ITransporte>>(_initialDataCollection);
    const [filter, setFilter] = useState<Ifilter>(_initialFilter());

    /* Panel Nuevo Vehiculo */
    const [isOpenValidacion, { setTrue: openValidacion, setFalse: dismissValidacion }] = useBoolean(false);
    /**Detalle de vehiculo para validacion*/
    const [itemTransporte, setitemTransporte] = useState<ITransporte>();

    /* Effect */
    useEffect(() => {
        setTitleColumn();
        dataPendiente();
    }, []);

    const setup = () => {
        setHidden(false);
        dataPendiente();
    }

    /* Api */
    const dataPendiente = () => {
        setData(_initialDataCollection);
        TransporteService.pendienteAprobacion(filter).then(res => {
            console.log(res);
            setData(res.data);
            setHidden(true);
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
            setHidden(true);
        })
    }

    /* Function */
    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Tipo de Trasporte',
                fieldName: 'tipoTransporte',
                minWidth: 120,
                maxWidth: 120,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column2',
                name: 'Placa',
                fieldName: 'placa',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                data: 'number',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Año fabricacion',
                fieldName: 'anioFabricacion',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Empresa',
                fieldName: 'empresa',
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Pasajeros',
                fieldName: 'pasajeros',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Modelo',
                fieldName: 'modelo',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Estado',
                fieldName: 'estado',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column8",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (i: ITransporte) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <TooltipHost content={`Validar`}>
                                <Icon iconName='CheckListText'
                                    style={{ cursor: 'pointer' }}
                                    className={ThemeBase.classes.iconFont}
                                    // onClick={() => { navigate(`${ConfigHelper.urlBase()}/vehiculos/lista.validar/${i.transporteId}`) }} 
                                    onClick={() => { setitemTransporte(i); openValidacion(); }}
                                />
                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ]

        setColumn(col);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: setup,
            },
        ];

        return _items;
    }

    return (
        <>
            <HeadingBar
                title='Validación Vehículos'
                subTitle='Lista Vehículos pendientes de aprobación'
                searchShow={false}
            ></HeadingBar>

            <TableBase leftBar={_leftBar()} column={column} data={data.items} isLoading={!hidden}></TableBase>

            <PanelGenerico isOpen={isOpenValidacion} onDismiss={dismissValidacion} isOnRenderHeader disabledFooter
                iconHeaderName="bus"
                title='Validación Vehículo'
                subTitle="Detalle de Vehículo pendinete de validación">
                <ValidarVehiculo dismiss={dismissValidacion} id={itemTransporte?.transporteId} register={setup}></ValidarVehiculo>
            </PanelGenerico>
        </>
    )
}