import { ComboBox, IComboBox, IComboBoxOption, Stack, TextField } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import '../Hoteles/hotelcss.css';
import React, { useContext, useEffect, useState } from "react";
import PersonaService from "../../services/PersonaService";
import { splitExceptionDetail, stackTokensSpace, _initialFilter } from "../../utils/Helper/helper";
import { IException, IItemComboBaseDTO, ISearch } from "../../interfaces/base/IBase";
import TransporteService from "../../services/transporteService";
import { ICreateTransporte, IItemCatalogoAdjunto } from "../../interfaces/ITransporte";
import { ButtonForm } from "../../components/Generic/buttonForm";
import ThemeBase from "../../utils/Theme/themeBase";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import { AuthContext } from "../../auth/context/auth-context";
import { isRoleAccessAll, roleAdmin } from "../../utils/Helper/roleHelper";
import ConfiguracionService from "../../services/configuracionService";

export const NewVehiculo = ({ isOpen = false, dismiss = () => { }, registar = () => { } }) => {
    const { access } = useContext(AuthContext);
    let role = access?.role ?? [];
    const [isAdmin, setIsAdmin] = useState(isRoleAccessAll(role, roleAdmin()));

    /* Filter */
    const [filterEmpresa, setFilterEmpresa] = useState<ISearch>(_initialFilter);

    /* Ref */
    const refComboEmpresa = React.useRef<IComboBox>(null);
    const _openComboEmpresa = React.useCallback(() => refComboEmpresa.current?.focus(true), []);

    let navigate = useNavigate();
    const [model, setModel] = useState<ICreateTransporte>(null!);
    const [marca, setMarca] = useState<IItemComboBaseDTO[]>([]);
    const [modelo, setModelo] = useState<IItemComboBaseDTO[]>([]);
    const [tipo, setTipo] = useState<IItemComboBaseDTO[]>([]);
    const [categoria, setCategoria] = useState<IItemComboBaseDTO[]>([]);
    const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
    const [catalogo, setCatalogo] = useState<IItemCatalogoAdjunto[]>([]);

    const [isComplete, setIsComplete] = useState(false);

    /* Effect */
    useEffect(() => {
        dataMarca();
        dataTipo();
        dataCategoria();
        dataEmpresa();
        /* dataCatalogoAdjunto(); */
    }, []);

    useEffect(() => {
        if (filterEmpresa.search != null) {
            dataEmpresa();
        }
    }, [filterEmpresa]);

    /* Validar campos requeridos */
    useEffect(() => {
        setIsComplete(_validateForm);
    }, [model]);

    useEffect(() => {
        setIsComplete(_validateForm);
    }, [catalogo]);

    useEffect(() => {
        if (isOpen) {
            dataEmpresa();
            setModel(null!);
        }
    }, [isOpen]);

    /* Api */
    const dataMarca = () => {
        TransporteService.allMarca().then(res => {
            setMarca(res.data);
        }).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataModeloByMarcaId = (marcaId: string) => {
        TransporteService.allModeloByMarcaId(marcaId).then(res => {
            setModelo(res.data);
        }
        ).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataTipo = () => {
        TransporteService.allTipo().then(res => {
            setTipo(res.data);
        }).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataCategoria = () => {
        TransporteService.allCategoria().then(res => {
            setCategoria(res.data);
        }).catch((e: Error) => {
            alert(e.message);
        })
    }

    const dataEmpresa = () => {
        setEmpresa([]);
        ConfiguracionService.allEmpresa(filterEmpresa)
            .then((res) => {
                if (res.status == 200) {
                    setEmpresa(res.data);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataCatalogoAdjunto = () => {
        TransporteService.catalogoAdjunto().then((res) => {
            if (res.status == 200) {
                setCatalogo(res.data);
            }
        }).catch((e) => {
            alert(e.message);
        })
    }

    const addTransporte = (state: boolean) => {
        if (!model) {
            return alert('Datos incompletos');
        }

        if (state) {
            if (!model.placa || model.placa == '') {
                return alert('Debe de seleccionar una placa');
            }

            if (!model.modeloId || model.modeloId == '') {
                return alert('Debe de seleccionar un modelo');
            }

            if (!model.tipoTransporteId || model.tipoTransporteId == '') {
                return alert('Debe de seleccionar un tipo transporte');
            }

            if (!model.categoriaId || model.categoriaId == '') {
                return alert('Debe de seleccionar una categoría');
            }

            /* if (!model.empresaId || model.empresaId == '') {
                return alert('Debe de seleccionar una empresa');
            } */
        }

        model.isBorrador = state;
        model.adjunto = catalogo;
        model.codigo ="01";
        // state && _sendEmail() //Quitar y solo dejar el de editar
        PersonaService.postTransporte(model).then((res) => {
            if (res.status == 200) {
                alert('Se registró correctamente');
                // goBack();
                dismiss()
                registar()
            }
        }).catch((e: IException) => {
            alert(splitExceptionDetail(e));
        })
    }
    /*Email pendientes aprobacion*/
    const _sendEmail = () => {
        if (model.empresaId != '')
            TransporteService.emailPendienteAprobacion("5ad0793d-f40f-4340-bceb-39da2a4cfbb0").then((res) => {
                if (res.status == 200) {
                    console.log("email enviado")
                }
            })
    }

    /* Function */
    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    /* Event */
    const _onChangeMarca = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                dataModeloByMarcaId(option.key.toString());
            }
        }
    }

    const _onChangeModelo = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    modeloId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeTipo = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    tipoTransporteId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeCategoria = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    categoriaId: option.key.toString(),
                })
            }
        }
    }

    const _onChangeEmpresa = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModel({
                    ...model,
                    empresaId: option.key.toString(),
                })
            }
        }
    }

    const _onChangePlaca = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                placa: newValue,
            });
        }
    }

    const _onChangeCodigoInterno = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                codigoInterno: newValue,
            });
        }
    }

    const _getErrorMessagePeriodo = (value: string): string => {
        let msg = '';

        if (value == '') {
            /* msg = `El valor no puede ser vacío`; */
        } else if (isNaN(value as any)) {
            msg = `Debe de seleccionar un año válido`;
        } else if (value.length != 4) {
            msg = `Debe de seleccionar un año válido`;
        } else {
            msg = ``;
        }

        return msg;
    };

    const _onChangeAnio = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let val = event?.target as HTMLInputElement;

        let an: number | null = null;

        if (val) {
            if (parseInt(val.value)) {
                an = parseInt(val.value);
            }
        }

        setModel({
            ...model,
            anioFabricacion: an,
        });
    }

    const _onChangePasajero = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                pasajeros: parseInt(newValue),
            });
        }
    }

    const _onChangeColor = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                color: newValue,
            });
        }
    }

    const _onChangeTarjeta = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined) {
            setModel({
                ...model,
                tarjetaPropiedad: newValue,
            });
        }
    }

    const _uploadFile = (ev: React.ChangeEvent, id: string) => {
        let { files } = ev.target as HTMLInputElement;

        if (files && files[0]) {
            let f = files[0];
            getBase64(f).then(
                data => {
                    let ot = catalogo.map(x => {
                        if (x.id == id) {
                            return ({
                                ...x,
                                archivo: f.name,
                                file: data as string,
                                extension: `.${f.name.split('.').pop()}` as string,
                            });
                        } else {
                            return x;
                        }
                    })

                    setCatalogo(ot)
                }
            );
        } else {
            let ot = catalogo.map(x => {
                if (x.id == id) {
                    return ({
                        ...x,
                        archivo: '',
                        file: '',
                        extension: '',
                    });
                } else {
                    return x;
                }
            })

            setCatalogo(ot)
        }
    }

    const _validateForm = () => {
        if (!model) {
            return false;
        }

        let st = true;

        if (catalogo.length > 0) {
            let reqLst = catalogo.filter(x => x.required);
            if (reqLst.length > 0 && reqLst.filter(x => (x.file == '' || !x.file)).length > 0) {
                st = false;
            }
        }

        if (model) {
            if (!model.modeloId || model.modeloId == '') {
                st = false;
            }

            if (!model.tipoTransporteId || model.tipoTransporteId == '') {
                st = false;
            }

            if (!model.categoriaId || model.categoriaId == '') {
                st = false;
            }

            /* if (!model.empresaId || model.empresaId == '') {
                st = false;
            } */

            if (!model.placa || model.placa == '') {
                st = false;
            }

            if (!model.pasajeros || model.pasajeros <= 0) {
                st = false;
            }
        }

        return st;
    }

    const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
        let { value } = ev?.target as HTMLInputElement;

        switch (type) {
            case "empresa":
                _openComboEmpresa();

                if (model) {
                    if (model.empresaId != null) {
                        setModel({
                            ...model,
                            empresaId: null,
                        });
                    }
                }

                setFilterEmpresa({
                    ...filterEmpresa,
                    search: value,
                });
                break;
        }
    };

    const _onBlurCombo = (type: string) => {
        switch (type) {
            case 'empresa':
                if (model) {
                    if (model.empresaId == "" || model.empresaId == null) {
                        setFilterEmpresa({
                            ...filterEmpresa,
                            search: "",
                        });
                    }
                }
                break;
            default:
                break;
        }
    };

    /* Footer Panel */
    const onRenderFooter = React.useCallback(() => {
        return (
            <>
                <ButtonForm back={dismiss} register={() => addTransporte(false)} disabledRegister={!isComplete}></ButtonForm>
            </>
        )
    }, [model, dismiss, empresa]);

    return (
        <>
            <PanelGenerico
                isOpen={isOpen}
                onDismiss={dismiss}
                isOnRenderHeader
                disabledFooter
                iconHeaderName='BusSolid'
                title="Registrar Vehiculo"
                subTitle="Complete el formulario para agregar vehiculo"
                onRendeFooter={onRenderFooter}
            >
                <form>
                    <div className="ms-Grid" dir="ltr">
                        <Stack tokens={stackTokensSpace.size15}>
                            {isAdmin &&
                                <div className="ms-Grid-row" >
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            componentRef={refComboEmpresa}
                                            label="Empresa"
                                            allowFreeform
                                            autoComplete="off"
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={empresa}
                                            selectedKey={model ? model.empresaId : ""}
                                            onChange={_onChangeEmpresa}
                                            onKeyUp={(e) => _onUpCombo("empresa", e)}
                                            onBlur={() => _onBlurCombo("empresa")}
                                        />
                                    </div>
                                </div>
                            }

                            <div className="ms-Grid-row" >
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <TextField
                                        label='Placa'
                                        // placeholder='Placa'
                                        onChange={_onChangePlaca}
                                        autoComplete='off'
                                        styles={ThemeBase.textFileStyles}
                                        maxLength={10}
                                        required />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <TextField
                                        label='Pasajeros'
                                        // placeholder='Pasajeros'
                                        onChange={_onChangePasajero}
                                        autoComplete='off'
                                        iconProps={{ iconName: 'group' }}
                                        min={0}
                                        type="number"
                                        styles={ThemeBase.textFileStyles}
                                        required />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label="Marca"
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={marca}
                                        onChange={_onChangeMarca}
                                        styles={ThemeBase.comboBoxStyles}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label="Modelo"
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={modelo}
                                        onChange={_onChangeModelo}
                                        selectedKey=""
                                        required
                                        styles={ThemeBase.comboBoxStyles}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row ">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <ComboBox
                                        label="Tipo Vehículo"
                                        // placeholder="Tipo Vehículo"
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={tipo}
                                        onChange={_onChangeTipo}
                                        required
                                        styles={ThemeBase.comboBoxStyles}
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                    <ComboBox
                                        label="Categoría Vehículo"
                                        // placeholder="Categoría Vehículo"
                                        autoComplete='off'
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={categoria}
                                        onChange={_onChangeCategoria}
                                        styles={ThemeBase.comboBoxStyles}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <TextField
                                        label="Año Fabricación"
                                        // placeholder="Año Fabricación"
                                        onGetErrorMessage={_getErrorMessagePeriodo}
                                        onChange={_onChangeAnio}
                                        autoComplete='off'
                                        value={model ? model.anioFabricacion?.toString() : ''}
                                        iconProps={{ iconName: 'calendar' }}
                                        styles={ThemeBase.textFileStyles} />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                    <TextField
                                        label='Codigo Interno'
                                        // placeholder='Codigo Interno'
                                        onChange={_onChangeCodigoInterno}
                                        autoComplete='off'
                                        styles={ThemeBase.textFileStyles} />
                                </div>
                            </div>
                        </Stack>
                    </div>
                </form>
            </PanelGenerico>
        </>
    );
}