import { Panel, PanelType } from "@fluentui/react";
import { urlTelerik } from "../../services/http-common";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import React, { Fragment } from "react";
import TokenService from "../../services/TokenService";

let _nameReport: string;
let _parameters: object;
let _openTelerik: boolean;
let _headerName: string;
//let _t: string;
const _dismissTelerik = () => {};

function RepoteBase({
  NameReport = _nameReport,
  Parameters = _parameters,
  openTelerik = _openTelerik,
  headerName = _headerName,
  //t = _t,
  dismissTelerik = _dismissTelerik,
  type = PanelType.large,
}) {
  return (
    <>
      {/* Panel - Telerik */}
      <Panel
        isOpen={openTelerik}
        onDismiss={dismissTelerik}
        headerText={headerName}
        type={type}
        closeButtonAriaLabel="Close">
        <TelerikReportViewer
          serviceUrl={urlTelerik}
          reportSource={{
            report: NameReport,
            parameters: { ...Parameters },
          }}
          viewerContainerStyle={{
            position: "absolute",
            left: "5px",
            right: "5px",
            top: "40px",
            bottom: "5px",
            overflow: "hidden",
            clear: "both",
            fontFamily: "ms sans serif",
          }}
          viewMode="INTERACTIVE"
          scaleMode="SPECIFIC"
          scale={1.0}
          enableAccessibility={false}
          authenticationToken={TokenService.getToken()}
        />
      </Panel>
    </>
  );
}
export default RepoteBase;
