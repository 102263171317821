import { Icon, SharedColors, Text } from "@fluentui/react"
import { IIconChart } from "../../../interfaces/base/IBase"
import ThemeBase from "../../../utils/Theme/themeBase"

export const IconCard = ({ size, style, card }: IIconChart): JSX.Element => {
    return (
        <>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    {card.map((card, i) => (
                        <div className={size ? size : 'ms-Grid-col ms-sm6 ms-md6 ms-lg6'} key={`c_${i}`}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'initial',
                                    height: 80,
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: card.color ?? ThemeBase.theme.palette.themeDark,
                                        display: 'flex',
                                        width: 80,
                                        height: 80,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Icon
                                        iconName={card.icon}
                                        style={{ color: ThemeBase.theme.palette.white }}
                                        className='ms-fontSize-42'
                                    ></Icon>
                                </div>

                                <div
                                    style={{
                                        backgroundColor: card.subColor ?? ThemeBase.theme.palette.themeDarkAlt,
                                        width: 'calc(100% - 80px)',
                                        height: 80,
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: "center",
                                        color: ThemeBase.theme.palette.white,
                                    }}
                                >
                                    <div style={{ width: '100%' }}>
                                        <div style={{ marginLeft: 10 }}>
                                            <Text
                                                style={{ color: ThemeBase.theme.palette.white }}
                                                className='ms-fontSize-28 ms-fontWeight-semibold'
                                            >
                                                {card.title}
                                            </Text>
                                            <br></br>
                                            <Text
                                                variant='small'
                                                style={{ color: ThemeBase.theme.palette.white }}
                                            >
                                                {card.subTitle}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}