import React from "react";
import { INavLinkGroup, Nav, INavLink} from '@fluentui/react/lib/Nav';
import { useState } from 'react';
import { CategoriaTransporte } from "./CategoriaTransporte";
import { Marca } from "./Marca";
import { Modelo } from "./Modelo";

function MenuTransporte() {

    const [key, setKey] = useState<string | undefined>("key1");

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: "Mantenimiento vehículo",
                    url: "",
                    expandAriaLabel: "Expand Home section",
                    collapseAriaLabel: "Collapse Home section",
                    links: [
                        {
                            name: "Categoria vehículos",
                            url: "",
                            icon: "Bus",
                            key: "key1",
                            // target: '_blank',
                        },
                        {
                            name: "Marca vehículo",
                            url: "",
                            icon: "Car",
                            // disabled: true,
                            key: "key2",
                            // target: '_blank',
                        },
                        {
                            name: "Modelo vehículo",
                            url: "",
                            icon: "Car",
                            // disabled: true,
                            key: "key3",
                            // target: '_blank',
                        },
                    ],
                    isExpanded: true,
                },
            ],
        },
    ];

    const _clickNavigate = (
        ev?: React.MouseEvent<HTMLElement>,
        item?: INavLink
    ) => {
        if (ev) {
            ev.preventDefault();
            if (item) {
                // navigate(item.url);
                // alert(item.key);
                setKey(item.key);
            }
        }
    };

    return (
        <>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg3"
                        style={{ paddingLeft: "0px" }}
                    >
                        {/* <NavBasicExample /> */}
                        <Nav
                            onLinkClick={_clickNavigate}
                            // onLinkClick={_onLinkClick}
                            selectedKey={key}
                            ariaLabel="Nav basic example"
                            // styles={navStyles}
                            groups={navLinkGroups}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                        <br />
                        {key == "key1" && (
                            <>
                                <CategoriaTransporte />
                            </>
                        )}

                        {key == "key2" && (
                            <>

                                <Marca />

                            </>
                        )}

                        {key == "key3" && (
                            <>
                                <Modelo />
                                
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );

}

export { MenuTransporte }