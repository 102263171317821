/* Datetime */
const _formatDatePrefix = (type: string, date?: Date): string => {
  return !date
    ? ""
    : `${type} ` +
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        (date.getFullYear() % 100);
};
const _formatDateShort = (date?: Date): string => {
  return !date
    ? ""
    : date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
};

const _formatDate = (date: Date, format: string) => {
  let s = "";

  switch (format) {
    case ",":
      s = `${date.getFullYear()},${date.getMonth() + 1},${date.getDate()}`;
      break;
    case "/":
      s = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      break;
    default:
      break;
  }

  return s;
};

const _parseDateFromString = (d: string) => {
  let part = d.split(",");

  if (part.length < 3) {
    return undefined;
  }

  let year = !isNaN(part[0] as any) ? parseInt(part[0] as any) : 0;
  let month = !isNaN(part[1] as any) ? parseInt(part[1] as any) - 1 : 0;
  let day = !isNaN(part[2] as any) ? parseInt(part[2] as any) : 0;

  return new Date(year, month, day);
};

const _esBisiesto = () => {
  const year = new Date().getFullYear();
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export {
  _formatDatePrefix,
  _formatDate,
  _formatDateShort,
  _parseDateFromString,
  _esBisiesto,
};
