import { ISearch } from "../interfaces/base/IBase";
import { IFilterProgramacionBus } from "../interfaces/filter/IFilterProgramacionBus";
import { Ifilter } from "../interfaces/IFilter";
import { IManifiesto } from "../interfaces/IManifiesto";
import { IFilterAsignacion, IValidarTransporteHelo } from "../interfaces/IProgramacion";
import { IAsignarBus, IAsignarRutaProgramacionBus, IAsignarTransporteMasivo, IReasignarTransporte } from "../interfaces/IProgramacionBus";
import { IBodyPUT } from "../pages/accesoPersona/vuelo/panel/actualizarComunicado";
import { fetchMovilizacion } from "./http-common";

const urlProgramacionBus = 'AccesoProgramadoBus';
const urlAccesoProgrmadoBus = 'AccesoProgramadoProgramacionBus';
const urlRutaProgramacionBus = 'RutaProgramacionBus';

const allPendienteAsignacionBus = (filter: IFilterAsignacion) => {
    return fetchMovilizacion.get(`${urlProgramacionBus}/PendienteAsignacionBus`, { params: filter });
}

const allAsignadoBus = (filter: IFilterAsignacion) => {
    return fetchMovilizacion.get(`${urlProgramacionBus}/Bus/Asignado`, { params: filter }); 
}

const asignarBusV2 = (data: IAsignarBus) => {
    return fetchMovilizacion.post(`${urlProgramacionBus}/AsignarBus`, data);
}

const busvigenteV2 = (programacionId: string, filter: Ifilter) => {
    return fetchMovilizacion.get(`${urlAccesoProgrmadoBus}/Vigente/${programacionId}`, { params: filter });
}

/* Ruta */
const rutaPorProgramacionBus = (programacionBusId: string) => {
    return fetchMovilizacion.get(`${urlRutaProgramacionBus}/${programacionBusId}`);
}

const asignarRuta = (data: IAsignarRutaProgramacionBus) => {
    return fetchMovilizacion.post(`${urlRutaProgramacionBus}/`, data);
}

/* Vuelo */
const heloVigente = (programacionId: string, filter: ISearch) => {
    return fetchMovilizacion.get(`${urlAccesoProgrmadoBus}/Vuelo/Vigente/${programacionId}`, { params: filter });
}

const allBusAndVuelo = (filter: IFilterAsignacion) => {
    return fetchMovilizacion.post(`${urlProgramacionBus}/BusAndVuelo`, filter); 
}

const allPendienteVuelo = (filter: IFilterAsignacion) => {
    return fetchMovilizacion.post(`${urlProgramacionBus}/Vuelo/Pendiente`, filter); 
}

const allAsignadoVuelo = (filter: IFilterAsignacion) => {
    return fetchMovilizacion.get(`${urlProgramacionBus}/Vuelo/Asignado`, { params: filter }); 
}

const allEmbarcadoVuelo = (filter: IFilterAsignacion) => {
    return fetchMovilizacion.get(`${urlProgramacionBus}/Vuelo/Embarcado`, { params: filter }); 
}

const asignarHelo = (data: IValidarTransporteHelo) => {
    return fetchMovilizacion.post(`${urlProgramacionBus}/Vuelo/Asignar`, data);
}

/* v2. Asignacion Transporte Masivo */
const grupoTransporte = (programacionId: string) => {
    return fetchMovilizacion.get(`${urlAccesoProgrmadoBus}/GrupoTransporte/${programacionId}`);
}

const asignarTransporteMasivo = (data: IAsignarTransporteMasivo) => {
    return fetchMovilizacion.put(`${urlAccesoProgrmadoBus}/GrupoTransporte`, data);
}

/* Actualizar Manifiesto*/

const actualizarManifiesto = (data: IManifiesto) => {
    console.log(data)
    return fetchMovilizacion.put(`${urlAccesoProgrmadoBus}/ActualizarManifiesto`, data);
}

/* Reasignacion */
const personaTransporteasignado = (filter: IFilterProgramacionBus) => {
    return fetchMovilizacion.get(`${urlProgramacionBus}/AsignadoPorTransporte`, { params: filter });
}

const reasignarTransporte = (data: IReasignarTransporte) => {
    return fetchMovilizacion.put(`${urlProgramacionBus}/ReasignarTransporte`, data);
}

/* Email CAE */
const emailCAE = (programacionId: string) => {
    return fetchMovilizacion.post(`${urlProgramacionBus}/Email/CAE/${programacionId}`);
}

/* Sms CAE */
const smsCAE = (programacionId: string) => {
    return fetchMovilizacion.post(`${urlProgramacionBus}/Sms/CAE/${programacionId}`);
}
/* Comunicado en dispositivo */
const comunicado = (data: IBodyPUT) => {
    return fetchMovilizacion.put(`${urlProgramacionBus}/Comunicado`,data);
}

const ProgramacionBusService = {
    allPendienteAsignacionBus,
    allAsignadoBus,
    asignarBusV2,
    busvigenteV2,
    heloVigente,

    /* Asignacion Ruta x Transporte */
    rutaPorProgramacionBus,
    asignarRuta,

    /* Vuelo */
    allBusAndVuelo,
    allPendienteVuelo,
    allAsignadoVuelo,
    allEmbarcadoVuelo,
    asignarHelo,

    /* Actualizar Manifiesto */
    actualizarManifiesto,
    /* v2. Asignacion transporte masivo */
    grupoTransporte,
    asignarTransporteMasivo,

    /* Reasignacion */
    personaTransporteasignado,
    reasignarTransporte,

    /* Email & Sms CAE */
    emailCAE,
    smsCAE,
    comunicado
}

export default ProgramacionBusService;