import { ComboBox, DatePicker, Stack, TextField, IComboBoxOption, IComboBox, Toggle } from "@fluentui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { IComboOptions } from "../../components/Generic/combo";
import TitleSeparator from "../../components/Generic/TitleSeparator";
import { IException, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import { Ifilter } from "../../interfaces/IFilter";
import { ICreateProgramacion } from "../../interfaces/IProgramacion";
import PersonaService from "../../services/PersonaService";
import ProgramacionService from "../../services/ProgramacionService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import {
  _formatDate,
  _formatDatePrefix,
} from "../../utils/Helper/datetimeHelper";
import { splitExceptionDetail, stackTokens, _initialFilter } from "../../utils/Helper/helper";

export const CrearProgramacion: React.FunctionComponent = () => {
  let navigate = useNavigate();

  //const [model, setmodel] = useState<ICreateProgramacion | undefined>(null!);
  const [model, setmodel] = useState<ICreateProgramacion>(null!);
  const [guardia, setComboGuardia] = useState<IComboOptions[]>([]);
  const [tipoProgramacion, setTipoProgramacion] = useState<IItemComboBaseDTO[]>([]);

  /* V2 */
  const [inicio, setInicio] = useState<Date | undefined>(undefined);
  const [fin, setFin] = useState<Date | undefined>(undefined);
  const [isSend, setIsSend] = useState(false);
  const [filterGuardia, setFilterGuardia] = useState<Ifilter>(_initialFilter);

  /* Hook */
  useEffect(() => {
    dataComboGuardia();
    dataTipoProgramacion();
  }, []);

  useEffect(() => {
    if (inicio != undefined) {
        setmodel({
            ...model,
            acceso: _formatDate(inicio, ',')
        });
    }
}, [inicio]);

useEffect(() => {
  if (fin != undefined) {
      setmodel({
          ...model,
          recurrencia: _formatDate(fin, ',')
      });
  }
}, [fin]);

  /* Api */
  const crearProgramacion = () => {
    PersonaService.postPrograma(model)
      .then((response) => {
        if (response.status == 200) {
          alert("Se registró correctamente");
          goBack();
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  function goBack() {
    navigate(`${ConfigHelper.urlBase()}/programaciones`);
  }

  const dataComboGuardia = () => {
    PersonaService.comboGuardiaProgramacion(filterGuardia)
      .then((response: any) => {
        if (response.status == 200) {
          setComboGuardia(response.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataTipoProgramacion = () => {
    ProgramacionService.allTipoProgramacion()
      .then((res: any) => {
        if (res.status == 200) {
          setTipoProgramacion(res.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  function _onChangeTooggle(
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) {
    setmodel({
      ...model,
      tieneCuarentena: checked ? true : false,
    });
  }

  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "tipo":
            setmodel({
              ...model,
              tipoProgramacionId: String(option.key),
            });
            break;
          case "guardia":
            setmodel({
              ...model,
              guardiaId: String(option.key),
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const _onChangeText = (
    type: string,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        switch (type) {
          case 'titulo':
            setmodel({
              ...model,
              titulo: newValue,
            });
            break;
          case "diaCierre":
            setmodel({
              ...model,
              diasCierre: parseInt(newValue),
            });
            break;
          case "cupo":
            setmodel({
              ...model,
              cupos: newValue,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <>
      <form>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg2"></div>

            <div
              className="ms-Grid-col ms-sm12 ms-md12 ms-lg8"
              style={{
                padding: "5%",
              }}
            >
              <div
                className="ms-depth-8"
                style={{
                  padding: "15px",
                }}
              >
                <>
                  <TitleSeparator
                    title="Nueva Programación"
                    icon="DependencyAdd"
                  ></TitleSeparator>

                  <Stack tokens={stackTokens}>
                    <div className="ms-Grid" dir="ltr">
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                          <TextField
                            label='Programación'
                            name='titulo'
                            placeholder='Programación'
                            value={model ? model.titulo : ''}
                            onChange={(e, o) => _onChangeText('titulo', e, o)}
                            autoComplete='off'
                            required
                          />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                          <ComboBox
                            label="Tipo"
                            autoComplete="off"
                            useComboBoxAsMenuWidth
                            calloutProps={{ directionalHintFixed: true }}
                            options={tipoProgramacion}
                            selectedKey={model?.tipoProgramacionId}
                            onChange={(e, o) => _onChangeCombo("tipo", e, o)}
                            required
                          />
                        </div>
                      </div>

                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                          <Toggle
                            label="Acuartelamiento"
                            placeholder="Tiene cuarentena"
                            onText="Tiene Acuartelamiento"
                            offText="No Tiene Acuartelamiento"
                            onChange={_onChangeTooggle}
                          />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                          <ComboBox
                            label="Guardia"
                            autoComplete="off"
                            useComboBoxAsMenuWidth
                            calloutProps={{ directionalHintFixed: true }}
                            options={guardia}
                            selectedKey={model?.guardiaId}
                            onChange={(e, o) => _onChangeCombo("guardia", e, o)}
                            required
                          />
                        </div>
                      </div>

                      <div
                        className="ms-Grid-row"
                        style={{ paddingTop: "5px" }}
                      >
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                          <DatePicker
                            placeholder='Fecha Acceso *'
                            allowTextInput
                            ariaLabel='Select a date. Input format is day slash month slash year.'
                            value={inicio}
                            onSelectDate={setInicio as (date?: any) => void}
                            formatDate={(d) => _formatDatePrefix('F. Acceso', d)}
                            style={{ paddingTop: '4px' }}
                          />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                          <DatePicker
                            placeholder='Fecha Recurrencia *'
                            allowTextInput
                            ariaLabel='Select a date. Input format is day slash month slash year.'
                            value={fin}
                            onSelectDate={setFin as (date?: any) => void}
                            formatDate={(d) => _formatDatePrefix('F. Recurrencia', d)}
                            style={{ paddingTop: '4px' }}
                          />
                        </div>
                      </div>

                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                          <TextField
                            label="Días Cierre"
                            name="diaCierre"
                            placeholder="Días Cierre"
                            min={0}
                            onChange={(e, o) => _onChangeText("diaCierre", e, o)}
                            type="number"
                          />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                          <TextField
                            label="Cupos"
                            name="cupo"
                            placeholder="Cupos"
                            min={0}
                            onChange={(e, o) => _onChangeText("cupo", e, o)}
                            type="number"
                          />
                        </div>
                      </div>
                    </div>

                    <ButtonForm
                      back={goBack}
                      register={crearProgramacion}
                      disabledRegister={isSend}
                    ></ButtonForm>
                  </Stack>
                </>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
