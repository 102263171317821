import { ProgressIndicator } from "@fluentui/react";

function IdicadorProgreso({ isSend = false,subText='' }) {
    return (
        <div hidden={!isSend}>
            <ProgressIndicator description={`${subText} Espere Un Momento`} barHeight={3} />
        </div>
    );
}

export default IdicadorProgreso;

export  function LineaProgreso({ ocultar = true, prefix = '' }) {
    return (
        <div hidden={ocultar}>
            <ProgressIndicator description={`${prefix} Espere Un Momento`} barHeight={3} />
        </div>
    );
}