import { ISearch } from "../interfaces/base/IBase";
import { IFilterUsuarioSupervisor } from "../interfaces/filter/IFilterUsuarioSupervisor";
import { Ifilter } from "../interfaces/IFilter";
import { ICreateSupervisorOrganigrama } from "../interfaces/IOrganigrama";
import { IAsignarMultipleOrganigrama } from "../interfaces/ISupervisorOrganigrama";
import { fetchAdministracion } from "./http-common";

const urlSupervisorOrganigrama = 'SupervisorOrganigrama';
const urlOrganigrama = 'Organigrama';

const allSupervisorOrganigrama = () => {
    return fetchAdministracion.get(`${urlSupervisorOrganigrama}`);
}

const organigramaByTipo = (tipo: string, filter: ISearch) => {
    return fetchAdministracion.get(`${urlOrganigrama}/${tipo}`, { params: filter });
}

const asignarGerencia = (data: ICreateSupervisorOrganigrama) => {
    return fetchAdministracion.post(`${urlSupervisorOrganigrama}/`, data);
}

const eliminarAsignacion = (id: string) => {
    return fetchAdministracion.delete(`${urlSupervisorOrganigrama}/${id}`);
}

/* v2. Asignacion Gerencia */
const allUsarioSupervisor = (filter: IFilterUsuarioSupervisor) => {
    return fetchAdministracion.get(`${urlSupervisorOrganigrama}/Usuario`, { params: filter });
}

const allOrganigramaPorSupervisor = (supervisorId: string, tipo: number, filter: Ifilter) => {
    return fetchAdministracion.get(`${urlSupervisorOrganigrama}/Usuario/Organigrama/${supervisorId}/${tipo}`, { params: filter });
}

const asignarMultipleGerencia = (data: IAsignarMultipleOrganigrama) => {
    return fetchAdministracion.post(`${urlSupervisorOrganigrama}/Asignar/Multiple`, data);
}

const allOrganigramaAsignado = (supervisorId: string, tipo: number) => {
    return fetchAdministracion.get(`${urlSupervisorOrganigrama}/Organigrama/Asignado/${supervisorId}/${tipo}`);
}

const allComboSuperIntendencia = (gerenciaId: string, search: string) => {
    return fetchAdministracion.get(`${urlOrganigrama}/SuperIntendencia/Combo?Id=${gerenciaId}&search=${search}`);
}
const tablaSupervisorPorOrganigrama = (data) =>
    fetchAdministracion.post(`${urlSupervisorOrganigrama}/TablaSupervisorPorOrganigrama`, data);
  

const OrganigramaService = {
    allSupervisorOrganigrama,
    organigramaByTipo,
    asignarGerencia,
    eliminarAsignacion,

    /* v2. Asignacion Gerencia */
    allUsarioSupervisor,
    allOrganigramaPorSupervisor,
    asignarMultipleGerencia,
    allOrganigramaAsignado,
    allComboSuperIntendencia,
    tablaSupervisorPorOrganigrama

}

export default OrganigramaService;