import {
  IColumn,
  ICommandBarItemProps,
  Icon,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { TableBase } from "../../../components/Table/TableBase";
import { IUsuarioSupervisor } from "../../../interfaces/ISupervisorOrganigrama";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { IFilterUsuarioSupervisor } from "../../../interfaces/filter/IFilterUsuarioSupervisor";
import OrganigramaService from "../../../services/organigramaService";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import {
  _initialFilter,
  _initialPagination,
  stackTokensHorizontal,
} from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { AsignarSupervisorOrganigrama } from "./panel/asignarSupervisorOrganigrama";
import { PanelRolesSupervisor } from "./PanelRolesSupervisor";

export const ListarUsuarioSupervisor = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  if (!state) {
    navigate("/configuraciones/asignacion.gerencia");
  }
  const [selectedSupervisor, setSelectedSupervisor] = useState<IUsuarioSupervisor>();

  const [usuario, setUsuario] = useState<IDataCollection<IUsuarioSupervisor>>(
    _initialDataCollection
  );
  const [hidden, setHidden] = useState(false);
  const [column, setColumn] = useState<IColumn[]>([]);

  /* Form */
  const [isOpenAsignar, { setTrue: openAsignar, setFalse: dismisAsignar }] =
    useBoolean(false);
  const [isOpenRole, { setTrue: openRole, setFalse: dissmisRole }] =
    useBoolean(false);
  /* Filter */
  const [filter, setFilter] =
    useState<IFilterUsuarioSupervisor>(_initialFilter);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  /* Hook */
  useEffect(() => {
    reloadInitial();
  }, []);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  /* Api */
  const dataUsuario = (param = filter) => {
    // setUsuario(_initialDataCollection);
    // OrganigramaService.allUsarioSupervisor(param)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setUsuario(res.data);
    //       setTotal(res.data.total);
    //     }
    //     setHidden(true);
    //   })
    //   .catch((e: IException) => {
    //     setHidden(true);
    //     alert(splitExceptionDetail(e));
    //   });
    listarPersonasPorOrganigrama(param);
  };
  const listarPersonasPorOrganigrama = async (param = filter) => {
    try {
      // setHidden(false);
      const response = await OrganigramaService.tablaSupervisorPorOrganigrama({
        ...param,
        organigramaId: state.gerencia.key,
      });
      if (response.status == 200) {
        setUsuario(response.data);
        setTotal(response.data?.total);
        setHidden(true);
      }
    } catch (e) {
    } finally {
    }
  };

  /* Function */
  const reloadInitial = (param = filter) => {
    setHidden(false);
    dataUsuario(param);
    setTitleColumn();
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        onClick: () =>
          navigate(
            `${ConfigHelper.urlBase()}/configuraciones/asignacion.gerencia`
          ),
      },
      {
        ...IconHelper.ICON_ADD,
        text: "Asignar",
        onClick: openAsignar,
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => reloadInitial(),
      },
    ];
    return _items;
  };

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Nombre",
        fieldName: "nombre",
        minWidth: 180,
        maxWidth: 180,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Identificacion",
        fieldName: "nroDOI",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        data: "number",
        isPadded: true,
        onRender: (item: IUsuarioSupervisor) => {
          return (
            <span>
              {item.tipoDoi}: {item.doi}
            </span>
          );
        },
      },
      {
        key: "column3",
        name: "Email",
        fieldName: "email",
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Puesto",
        fieldName: "puesto",
        minWidth: 180,
        maxWidth: 180,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: "Tipo",
        fieldName: "tipo",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column7",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IUsuarioSupervisor) => (
          <>
            <div>
              <Stack horizontal tokens={stackTokensHorizontal}>
                <>
                  <TooltipHost content={`Ver Gerencias`}>
                    <Icon
                      iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                      style={{ cursor: "pointer" }}
                      className={ThemeBase.classes.iconFont}
                      onClick={() => {
                        // navigate(
                        //   `${ConfigHelper.urlBase()}/configuraciones/asignacion.gerencia/${
                        //     item.supevisorId
                        //   }?type=${item.tipoEnum}`
                        // );
                        navigate(
                          `${ConfigHelper.urlBase()}/configuraciones/asignacion.gerencia/usuarios/${
                            item.supervisorId
                          }?type=${item.tipoEnum}`
                        );
                      }}
                    />
                  </TooltipHost>
                  <TooltipHost content={`Ver Roles`}>
                    <Icon
                      iconName={IconHelper.ICON_INFO.iconProps?.iconName}
                      style={{ cursor: "pointer" }}
                      className={ThemeBase.classes.iconFont}
                      onClick={() => {
                        setSelectedSupervisor(item);
                        openRole()
                      }}
                    />
                  </TooltipHost>
                </>
              </Stack>
            </div>
          </>
        ),
      },
    ];

    setColumn(col);
  };

  /* Event Pagination */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: IFilterUsuarioSupervisor = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      };

      setFilter(f);
      setCurrentPage(page);

      reloadInitial(f);
    }
  };

  /* Event Filter */
  const _onChangeTextFilter = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      if (newValue === "") {
        _onClearFilter();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        });
      }
    }
  };

  const _onPressFilter = (newValue: string) => {
    let f: IFilterUsuarioSupervisor = {
      ...filter,
      search: newValue,
    };

    setFilter(f);
    reloadInitial(f);
  };

  const _onClearFilter = () => {
    let f: IFilterUsuarioSupervisor = {
      ..._initialFilter(),
    };

    setFilter(f);
    reloadInitial(f);
  };

  return (
    <>
      <HeadingBar
        title="Asignación Gerencias"
        subTitle={` ${state.gerencia.text} | Gestión de Usuarios `}
        searchChange={_onChangeTextFilter}
        searchHandler={_onPressFilter}
        searchValue={filter.search ?? ""}
        onClearHandler={_onClearFilter}
      ></HeadingBar>

      <TableBase
        leftBar={_leftBar()}
        column={column}
        data={usuario.items}
        pagination={pager}
        isLoading={!hidden}
      ></TableBase>

      <AsignarSupervisorOrganigrama
        isOpen={isOpenAsignar}
        dissmiss={dismisAsignar}
        reloadInitial={reloadInitial}
      ></AsignarSupervisorOrganigrama>
      <PanelRolesSupervisor
        isOpen={isOpenRole}
        dissmiss={dissmisRole}
        id={selectedSupervisor?.supervisorId}
      />
    </>
  );
};
