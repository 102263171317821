import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IPersonaSharedProps,
  Panel,
  PanelType,
  Persona,
  PersonaPresence,
  PersonaSize,
  TextField,
  Toggle,
} from "@fluentui/react";
import React from "react";
import { useEffect, useState } from "react";
import { ButtonForm } from "../../components/Generic/buttonForm";
import {
  IBase,
  IException,
  IItemComboBaseDTO,
  ISearch,
} from "../../interfaces/base/IBase";
import { IPersona, IUpdateSEE } from "../../interfaces/IPersona";
import ContratoService from "../../services/contratoService";
import PersonaService from "../../services/PersonaService";
import ProgramacionService from "../../services/ProgramacionService";
import UbicacionService from "../../services/ubicacionesServices";
import {
  EXPRESION_CELULAR,
  EXPRESION_EMAIL,
  splitExceptionDetail,
  _initialFilter,
} from "../../utils/Helper/helper";

let _openPanel: boolean;
let _headerPanelName: string;
const _dismissPanel = () => {};
let _dataDetail: IPersona;
const _setup = () => {};

const INITIAL_MODEL = {
  persona: "",
  personaId: "",
  celular: "",
  email: "",
  lugarAcuartelamientoId: "",
  lugarMovilizacionId: "",
  centroCostoId: "",
  esCritico: true,
  esTitular: true,
};

function EditarPersonaSEE({
  openPanel = _openPanel,
  dismissPanel = _dismissPanel,
  dataDetail = _dataDetail,
  setup = _setup,
}) {
  const [inputForm, setInputForm] = useState<IUpdateSEE>(INITIAL_MODEL);

  /**Data combos */
  const [lugarMovilizacion, setLugarMovilizacion] = useState<
    IItemComboBaseDTO[]
  >([]);
  const [lugarAcuartelamiento, setlugarAcuartelamiento] = useState<
    IItemComboBaseDTO[]
  >([]);
  const [centroCosto, setCentroCosto] = useState<IItemComboBaseDTO[]>([]);

  /**Ref combos*/
  const cuarentenaRef = React.useRef<IComboBox>(null);
  const movilizacionRef = React.useRef<IComboBox>(null);
  const centroCostoRef = React.useRef<IComboBox>(null);
  const _openOptionCuarentena = React.useCallback(
    () => cuarentenaRef.current?.focus(true),
    []
  );
  const _openOptionMovilizacion = React.useCallback(
    () => movilizacionRef.current?.focus(true),
    []
  );
  const _openOptionCentroCosto = React.useCallback(
    () => centroCostoRef.current?.focus(true),
    []
  );

  /* Filters */
  const [filterCuarentena, setfilterCuarentena] =
    useState<ISearch>(_initialFilter);
  const [filterMovilizacion, setfilterMovilizacion] =
    useState<ISearch>(_initialFilter);
  const [filterCentroCosto, setFilterCentroCosto] =
    useState<ISearch>(_initialFilter);

  /**Validacion entrada texto */
  const [emailIsCorrect, setEmailIsCorrect] = useState(true);
  const [sendForm, setSendForm] = useState(false);
  const [celularIsCorrrect, setCelularIsCorrrect] = useState(true);

  useEffect(() => {
    if (dataDetail?.celular != "" && dataDetail?.email != "") setSendForm(true);

    setInputForm({
      ...inputForm,
      personaId: dataDetail?.personaId,
      persona: dataDetail?.persona,
      email: dataDetail?.email,
      celular: dataDetail?.celular,
      centroCostoId: dataDetail?.centroCostoId,
      lugarMovilizacionId: dataDetail?.lugarMovilizacionId,
      lugarAcuartelamientoId: dataDetail?.lugarAcuartelamientoId,
      esCritico: dataDetail?.esCritico,
      esTitular: dataDetail?.esTitular,
    });
    dataCentroCosto();
    dataLugarCuarentena();
    dataMovilizacion();
  }, [dataDetail]);

  /**Evet combo Centro costos */
  const dataCentroCosto = () => {
    ContratoService.allCentroCosto()
      .then((res) => {
        setCentroCosto(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };
  const _onBlurComboCentroCosto = () => {
    if (inputForm.centroCostoId == "" || inputForm.centroCostoId == undefined) {
      setFilterCentroCosto({
        ...filterCentroCosto,
        search: "",
      });
    }
  };
  const _onInputCentroCosto = (e?: any) => {
    _openOptionCentroCosto();
    let { value } = e?.target as HTMLInputElement;
    setInputForm({
      ...inputForm,
      centroCostoId: "",
    });
    setFilterCentroCosto({
      ...filterCentroCosto,
      search: value,
    });
  };
  const _onChangeComboCentroCosto = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setInputForm({
          ...inputForm,
          centroCostoId: String(option.key),
        });
      }
    }
  };

  /**Evet combo acuartelamiento */
  const dataLugarCuarentena = () => {
    UbicacionService.comboUbicaciones(2)
      .then((res) => {
        let d: IBase[] = res.data.data;
        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });
        setlugarAcuartelamiento(t);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };
  const _onBlurComboCuarentena = () => {
    if (
      inputForm.lugarAcuartelamientoId == "" ||
      inputForm.lugarAcuartelamientoId == undefined
    ) {
      setfilterCuarentena({
        ...filterCuarentena,
        search: "",
      });
    }
  };
  const _onInputCuarentena = (e?: any) => {
    _openOptionCuarentena();
    let { value } = e?.target as HTMLInputElement;
    setInputForm({
      ...inputForm,
      lugarAcuartelamientoId: "",
    });
    setfilterCuarentena({
      ...filterCuarentena,
      search: value,
    });
  };
  const _onChangeComboCuarentena = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setInputForm({
          ...inputForm,
          lugarAcuartelamientoId: String(option.key),
        });
      }
    }
  };

  /**Evet combo lugar movilizacion */
  const dataMovilizacion = () => {
    ProgramacionService.allLugarMovilizacion()
      .then((res) => {
        setLugarMovilizacion(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const _onChangeMovilizacion = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != null) {
      if (option != null) {
        setInputForm({
          ...inputForm,
          lugarMovilizacionId: option.key.toString(),
        });
      }
    }
  };

  const _onInputMovilizacion = (e?: any) => {
    _openOptionMovilizacion();
    let { value } = e?.target as HTMLInputElement;
    setInputForm({
      ...inputForm,
      lugarMovilizacionId: "",
    });
    setfilterMovilizacion({
      ...filterMovilizacion,
      search: value,
    });
  };

  const _onBlurMovilizacion = () => {
    if (
      inputForm.lugarMovilizacionId == "" ||
      inputForm.lugarMovilizacionId == undefined
    ) {
      setfilterMovilizacion({
        ...filterMovilizacion,
        search: "",
      });
    }
  };

  const onRenderFooter = () => (
    <div>
      <ButtonForm
        back={dismissPanel}
        register={_updateDataPersona}
        disabledRegister={!sendForm}
      ></ButtonForm>
    </div>
  );

  const _onChangeEsTitular = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setInputForm({
      ...inputForm,
      esTitular: checked ? true : false,
    });
  };

  const _onChangeEsCritico = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    setInputForm({
      ...inputForm,
      esCritico: checked ? true : false,
    });
  };

  const _onChangeEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != null) {
      console.log(newValue);
      setInputForm({
        ...inputForm,
        email: newValue,
      });
    }
  };
  const _onChangeCelular = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setInputForm({
        ...inputForm,
        celular: newValue,
      });
      console.log(inputForm?.celular);
    }
  };

  const _updateDataPersona = () => {
    PersonaService.putPersonaSEE(inputForm)
      .then((res) => {
        if (res.status == 200) {
          alert("Datos Actulizados");
          dismissPanel();
          setup();
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        dismissPanel();
        setup();
      });
  };

  /**Detalle corto de Persona */
  const detailPersona: IPersonaSharedProps = {
    imageUrl: dataDetail?.foto,
    imageInitials: dataDetail?.primerNombre.charAt(0).toUpperCase(),
    text: dataDetail?.persona,
    secondaryText: dataDetail?.gerencia,
  };

  /**Validacines Entrada de textos*/

  useEffect(() => {
    console.log(celularIsCorrrect, " | ", emailIsCorrect);
    celularIsCorrrect && emailIsCorrect
      ? setSendForm(true)
      : setSendForm(false);
  }, [celularIsCorrrect, emailIsCorrect]);

  const getErrorMessageEmail = (value: string): string => {
    EXPRESION_EMAIL.test(value)
      ? setEmailIsCorrect(true)
      : setEmailIsCorrect(false);

    return value.length > 0 || value != ""
      ? EXPRESION_EMAIL.test(value)
        ? ""
        : "Email invalido"
      : `Este valor no puede ser vacio`;
  };

  const getErrorMessageCelular = (value: string): string => {
    EXPRESION_CELULAR.test(value)
      ? setCelularIsCorrrect(true)
      : setCelularIsCorrrect(false);

    return value.length > 0 || value != ""
      ? EXPRESION_CELULAR.test(value)
        ? ""
        : "Celular invalido"
      : `Este valor no puede ser vacio`;
  };

  return (
    <>
      <Panel
        isOpen={openPanel}
        onDismiss={dismissPanel}
        headerText="Actualizacion Persona - Rol SEE"
        type={PanelType.medium}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooter}
        isFooterAtBottom={true}
      >
        <div style={{ paddingTop: "15%" }}>
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg12">
                <Persona
                  {...detailPersona}
                  size={PersonaSize.size56}
                  presence={PersonaPresence.none}
                  imageAlt={dataDetail?.primerNombre}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <TextField
                  label="Email"
                  defaultValue={inputForm?.email}
                  value={inputForm?.email}
                  placeholder={inputForm?.email}
                  onChange={_onChangeEmail}
                  autoComplete="off"
                  onGetErrorMessage={getErrorMessageEmail}
                  required
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <TextField
                  label="Celular"
                  value={inputForm?.celular}
                  defaultValue={inputForm?.celular}
                  placeholder={inputForm?.celular}
                  onChange={_onChangeCelular}
                  autoComplete="off"
                  onGetErrorMessage={getErrorMessageCelular}
                  required
                />
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <ComboBox
                  componentRef={cuarentenaRef}
                  label="Lugar de Acuartelamiento"
                  allowFreeform
                  options={lugarAcuartelamiento}
                  calloutProps={{ directionalHintFixed: true }}
                  selectedKey={
                    inputForm ? inputForm.lugarAcuartelamientoId : ""
                  }
                  useComboBoxAsMenuWidth
                  openOnKeyboardFocus
                  autoComplete="off"
                  onInput={_onInputCuarentena}
                  onChange={_onChangeComboCuarentena}
                  onBlur={_onBlurComboCuarentena}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <ComboBox
                  componentRef={centroCostoRef}
                  label="Centro Costo"
                  allowFreeform
                  options={centroCosto}
                  calloutProps={{ directionalHintFixed: true }}
                  selectedKey={inputForm ? inputForm.centroCostoId : ""}
                  openOnKeyboardFocus
                  useComboBoxAsMenuWidth
                  autoComplete="off"
                  onChange={_onChangeComboCentroCosto}
                  onInput={_onInputCentroCosto}
                  onBlur={_onBlurComboCentroCosto}
                />
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <ComboBox
                  componentRef={movilizacionRef}
                  label="Lugar de Movilización"
                  allowFreeform
                  options={lugarMovilizacion}
                  calloutProps={{ directionalHintFixed: true }}
                  selectedKey={inputForm ? inputForm.lugarMovilizacionId : ""}
                  useComboBoxAsMenuWidth
                  openOnKeyboardFocus
                  autoComplete="off"
                  onInput={_onInputMovilizacion}
                  onChange={_onChangeMovilizacion}
                  onBlur={_onBlurMovilizacion}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <Toggle
                  label="Es Critico"
                  placeholder="Es Critico"
                  onText="Si"
                  offText="No"
                  defaultChecked={inputForm?.esCritico}
                  onChange={_onChangeEsCritico}
                />
              </div>
              <div className="ms-Grid-col ms-sm12 ms-lg6">
                <Toggle
                  label="Es Titular"
                  placeholder="Es Titular"
                  onText="Si"
                  offText="No"
                  defaultChecked={inputForm?.esTitular}
                  onChange={_onChangeEsTitular}
                />
              </div>
            </div>
            {/* {JSON.stringify(dataDetail?.esCritico, null, 2)}
            {JSON.stringify(dataDetail?.esTitular, null, 2)} */}
          </div>
        </div>
      </Panel>
    </>
  );
}

export default EditarPersonaSEE;
