import { IPostGuardiaPax, IUpdateGuardiaPax } from "../interfaces/IGuadia";
import { fetchMovilizacion } from "./http-common";

const urlGuardiaPax = 'GuardiaPax';

const addGuardiaPax = (data: IPostGuardiaPax) => {
    return fetchMovilizacion.post(`${urlGuardiaPax}/AsignarPersona`, data);
}


const ActualizarIsPax = (data: IUpdateGuardiaPax) => {
    return fetchMovilizacion.put(`${urlGuardiaPax}/Habilitar`, data);
}

const GuardiaPaxService = {
    addGuardiaPax,
    ActualizarIsPax
}

export default GuardiaPaxService;