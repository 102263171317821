import { CommandBar, CommandBarButton, concatStyleSets, getTheme, IButtonProps, IButtonStyles, ICommandBarItemProps, ICommandBarStyleProps, ICommandBarStyles, IContextualMenuItemStyles, IStyleFunctionOrObject, memoizeFunction } from "@fluentui/react"
import { CSSProperties } from "styled-components";

const theme = getTheme();
const _item: ICommandBarItemProps[] = [];
const _farItem: ICommandBarItemProps[] = [];
const _style: CSSProperties = {};

export const CustomCommandBar = ({ item = _item, farItem = _farItem, style = _style }) => {
    const itemStyles: Partial<IContextualMenuItemStyles> = {
        label: { fontSize: 13 },
        icon: { color: theme.palette.blue },
        iconHovered: { color: theme.palette.blueDark },
    };

    const CustomButton: React.FunctionComponent<IButtonProps> = (props) => {
        return (
            <CommandBarButton
                {...props}
                styles={getCommandBarButtonStyles(props.styles)}
            />
        );
    };

    const getCommandBarButtonStyles = memoizeFunction(
        (
            originalStyles: IButtonStyles | undefined
        ): Partial<IContextualMenuItemStyles> => {
            if (!originalStyles) {
                return itemStyles;
            }

            return concatStyleSets(originalStyles, itemStyles);
        }
    );

    const commadStyles: IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> = {
        root: {
            padding: 0,
            margin: 0,
            /* border: '1px solid' */
        },
    };

    return (
        <>
            <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12' style={style}>
                <CommandBar
                    buttonAs={CustomButton}
                    items={item}
                    farItems={farItem}
                    style={{
                        borderBottom: theme.palette.neutralLight,
                        borderBottomStyle: 'solid',
                        padding: 0,
                        margin: 0,
                    }}
                    styles={commadStyles}
                />
            </div>
        </>
    )
}