import { ComboBox, IComboBox, IComboBoxOption, ProgressIndicator, Stack, TextField, Toggle } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { ButtonForm } from '../../components/Generic/buttonForm'
import { PanelGenerico } from '../../components/Generic/PanelGenerico'
import { CustomMessageBar, typeMessage } from '../../components/MessageBar/customMessageBar'
import { IException, IItemComboBaseDTO } from '../../interfaces/base/IBase'
import { IFilterUbicacion } from '../../interfaces/filter/IFilterUbicacion'
import { ICreateRegla, IRegla } from '../../interfaces/IRegla'
import ProgramacionService from '../../services/ProgramacionService'
import { ReglaService } from '../../services/reglaService'
import UbicacionService from '../../services/ubicacionesServices'
import { IndexNone, ItemNoneSelect, splitExceptionDetail, stackTokens } from '../../utils/Helper/helper'
import { UbicacionHelper } from '../../utils/Helper/ubicacionHelper'
import ThemeBase from '../../utils/Theme/themeBase'

let _regla: IRegla | null = null;

const convertForm = (regla: IRegla | null) => {
    if (regla == null) {
        return null;
    }

    let t: ICreateRegla = {
        reglaId: regla.reglaId,
        tipoProgramacionId: regla.tipoProgramacionId,
        lugarMovilizacionId: regla.lugarMovilizacionId,
        origenId: regla.origenId,
        destinoId: regla.destinoId,
        destinoBajadaId: regla.destinoBajadaId,
        invierte: regla.invierte,
        esVuelo: regla.esVuelo,
        requiereTransporte: regla.requiereTransporte
    }
    return t;
}
export const CrearRegla = ({ regla = _regla, isOpen = false, onDismiss = () => { }, reloadInitial = () => { } }) => {
    /* Form */
    const [item, setItem] = useState<ICreateRegla>(null!);
    const [form, setForm] = useState<ICreateRegla>(null!);
    const [tipoProgramacion, setTipoProgramacion] = useState<IItemComboBaseDTO[]>([]);
    const [lugarMovilizacion, setLugarMovilizacion] = useState<IItemComboBaseDTO[]>([]);
    const [origen, setOrigen] = useState<IItemComboBaseDTO[]>([]);
    const [destino, setDestino] = useState<IItemComboBaseDTO[]>([]);
    const [ubicacion, setUbicacion] = useState<IItemComboBaseDTO[]>([]);

    /* Filter */
    const [filterOrigen, setFilterOrigen] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_AMBOS
    });

    const [filterDestino, setFilterDestino] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_AMBOS
    });

    const [filterUbicacion, setFilteUbicacion] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_AMBOS,
    });

    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Hook */
    useEffect(() => {
        if (isOpen) {
            if (regla) {
                let r = convertForm(regla);
                setItem(r!);
            }

            dataTipoProgramacion();
            dataLugarMovilizacion();
            dataOrigen();
            dataDestino();
            dataUbicacion();
        }
    }, [isOpen]);

    useEffect(() => {
        if (item) {
            setForm(item);
        }
    }, [item]);

    /* Api */
    const dataTipoProgramacion = () => {
        ProgramacionService.allTipoProgramacion()
            .then((res: any) => {
                if (res.status == 200) {
                    setTipoProgramacion(res.data);
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion()
            .then((res) => {
                let t: IItemComboBaseDTO[] = res.data;
                t.unshift(ItemNoneSelect());

                setLugarMovilizacion(t);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    }

    const dataOrigen = () => {
        UbicacionService.ubicacionPorFiltro(filterOrigen)
            .then((res) => {
                setOrigen(res.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const crerRegla = () => {
        if (form) {
            setIsSend(true);

            ReglaService.crearRegla(form)
                .then((res) => {
                    if (res.status == 200) {
                        reloadInitial();
                        onDismiss();
                        setIsSend(false);
                    }
                })
                .catch((e: IException) => {
                    setIsSend(false);
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    }

    const dataDestino = () => {
        UbicacionService.ubicacionPorFiltro(filterDestino)
            .then((res) => {
                setDestino(res.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const dataUbicacion = () => {
        UbicacionService.ubicacionPorFiltro(filterUbicacion)
            .then((res) => {
                let t: IItemComboBaseDTO[] = res.data;
                t.unshift(ItemNoneSelect());

                setUbicacion(res.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    /* Event */
    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                let k: string | null = null;

                switch (type) {
                    case 'tipo':
                        setForm({
                            ...form,
                            tipoProgramacionId: String(option.key),
                        });
                        break;
                    case 'lugMov':
                        if (option.key != IndexNone) {
                            k = String(option.key);
                        }

                        setForm({
                            ...form,
                            lugarMovilizacionId: k,
                        });
                        break;
                    case 'origen':
                        setForm({
                            ...form,
                            origenId: String(option.key),
                        });
                        break;
                    case 'destino':
                        setForm({
                            ...form,
                            destinoId: String(option.key),
                        });
                        break;
                    case 'ubicacion':
                        if (option.key != IndexNone) {
                            k = String(option.key);
                        }

                        setForm({
                            ...form,
                            destinoBajadaId: k,
                        });
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const _onTooggle = (type: string, ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked != null) {
            switch (type) {
                case 'invierte':
                    setForm({
                        ...form,
                        invierte: checked,
                    })
                    break;
                case 'esVuelo':
                    setForm({
                        ...form,
                        esVuelo: checked,
                    })
                    break;
                case 'requiereTransporte':
                    setForm({
                        ...form,
                        requiereTransporte: checked
                    })
                    break;

                default:
                    break;
            }
        }
    }

    /* Render */
    const onRenderFooter = React.useCallback(() => {
        return (
            <>
                <ButtonForm
                    back={onDismiss}
                    register={crerRegla}
                    disabledRegister={isSend}
                ></ButtonForm>
            </>
        )
    }, [item, form, onDismiss])

    /* Callback reload */
    const refForm = React.useCallback(() => {
        setForm(null!);
        resetChoice();
    }, [onDismiss])

    return (
        <>
            <PanelGenerico
                isOpen={isOpen}
                onDismiss={onDismiss}
                isOnRenderHeader
                disabledFooter
                iconHeaderName='DependencyAdd'
                title='Nueva Regla'
                subTitle='Complete el formulario para crear la regla'
                onRendeFooter={onRenderFooter}
            >
                <div>
                    <div className='ms-Grid' dir='ltr'>
                        <div className='ms-Grid-row'>
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description='Enviando espere un momento' barHeight={3} />
                            </div>

                            <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12' style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    refForm()
                                    resetChoice()
                                }}></CustomMessageBar>}
                            </div>
                        </div>
                    </div>

                    {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(regla, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(form, null, 2)}</pre> */}

                    <form ref={refForm} hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                        <div className='ms-Grid' dir='ltr' style={{ paddingTop: '10%' }}>
                            <Stack tokens={stackTokens}>
                                <div className='ms-Grid-row'>
                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                        <ComboBox
                                            label='Tipo'
                                            autoComplete='off'
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={tipoProgramacion}
                                            selectedKey={form ? form.tipoProgramacionId : ''}
                                            onChange={(e, o) => _onChangeCombo('tipo', e, o)}
                                            required
                                        />
                                    </div>

                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                        <ComboBox
                                            // componentRef={LugarMovilizacionRef}
                                            label='Lugar Movilización'
                                            autoComplete='off'
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            // onKeyUp={_onUpMOvilizacion}
                                            options={lugarMovilizacion}
                                            selectedKey={form ? form.lugarMovilizacionId : ''}
                                            onChange={(e, o) => _onChangeCombo('lugMov', e, o)}
                                            required
                                        />
                                    </div>
                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                        <ComboBox
                                            // componentRef={origenRef}
                                            label='Origen (Embarque)'
                                            allowFreeform
                                            autoComplete='off'
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={origen}
                                            // onKeyUp={_onUpOrigen}
                                            selectedKey={form ? form.origenId : ''}
                                            onChange={(e, o) => _onChangeCombo('origen', e, o)}
                                            required
                                        />
                                    </div>
                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                        <ComboBox
                                            // componentRef={destinoRef}
                                            label='Destino (Desembarque)'
                                            allowFreeform
                                            autoComplete='off'
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={destino}
                                            // onKeyUp={_onUpDestino}
                                            selectedKey={form ? form.destinoId : ''}
                                            onChange={(e, o) => _onChangeCombo('destino', e, o)}
                                            required
                                        />
                                    </div>
                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                        <ComboBox
                                            // componentRef={destinoBajadaRef}
                                            label='Destino Bajada'
                                            allowFreeform
                                            autoComplete='off'
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={ubicacion}
                                            // onKeyUp={_onUpDestinoBajada}
                                            selectedKey={form ? form.destinoBajadaId : ''}
                                            onChange={(e, o) => _onChangeCombo('ubicacion', e, o)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className='ms-Grid-row'>
                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg4'>
                                        <Toggle
                                            label='Invierte Ubicaciones'
                                            placeholder='Invierte?'
                                            onText='Si'
                                            offText='No'
                                            onChange={(e, c) => _onTooggle('invierte', e, c)}
                                            checked={form ? form.invierte : false}
                                        />
                                    </div>
                                </div>

                                <div className='ms-Grid-row'>
                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg4'>
                                        <Toggle
                                            label='Aplica Helo'
                                            placeholder='Es Helo?'
                                            onText='Si'
                                            offText='No'
                                            onChange={(e, c) => _onTooggle('esVuelo', e, c)}
                                            checked={form ? form.esVuelo : false}
                                        />
                                    </div>
                                </div>

                                <div className='ms-Grid-row'>
                                    <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg4'>
                                        <Toggle
                                            label='Requiere Transporte'
                                            placeholder='Transporte?'
                                            onText='Si'
                                            offText='No'
                                            onChange={(e, c) => _onTooggle('requiereTransporte', e, c)}
                                            checked={form ? form.requiereTransporte : false}
                                        />
                                    </div>
                                </div>
                            </Stack>
                        </div>
                    </form>
                </div>

            </PanelGenerico>
        </>
    )
}