import {
  IColumn,
  ICommandBarItemProps,
  Icon,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { TableBase } from "../../../components/Table/TableBase";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { useNavigate } from "react-router-dom";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useCallback, useEffect, useState } from "react";
import OrganigramaService from "../../../services/organigramaService";
import {
  _initialFilter,
  _initialPagination,
} from "../../../utils/Helper/helper";
import { Ifilter } from "../../../interfaces/IFilter";
import { IPagination } from "../../../interfaces/base/ITable";
const TIPO_GERENCIA = "01";
const TablaGerencias = () => {
  const navigate = useNavigate();
  const [gerencias, setGerencias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState<Ifilter>(_initialFilter);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: Ifilter = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
        
      };

      setFilter(f);
      setCurrentPage(page);

      // reloadInitial(f);
    }
  };
  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });
  const leftBar = useCallback(() => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => {},
      },
    ];
    return _items;
  }, []);
  const stackTokensHorizontal = { childrenGap: 10 };
  const listOrganigrama = async (filter) => {
    try {
      setIsLoading(true);
      const response = await OrganigramaService.organigramaByTipo(
        TIPO_GERENCIA,
        filter
      );
      if (response.status == 200) {
        setGerencias(response.data);
      }
    } catch (e) {}
    setIsLoading(false);
  };

  const setTitleColumn = useCallback((): IColumn[] => {
    return [
      {
        key: "column1",
        name: "N°.",
        fieldName: "onciones",
        minWidth: 50,
        maxWidth: 80,
        isResizable: true,
        data: "number",
        isCollapsible: true,
        onRender: (item: any, index: any) => <div>{index + 1}</div>,
      },
      {
        key: "column2",
        name: "Nombre",
        fieldName: "text",
        minWidth: 900,
        maxWidth: 900,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column7",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: any) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              <>
                <TooltipHost content="Ver Usuarios">
                  <Icon
                    iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      navigate(
                        `${ConfigHelper.urlBase()}/configuraciones/asignacion.gerencia/usuarios`,
                        { state: { gerencia: item } }
                      );
                    }}
                  />
                </TooltipHost>
              </>
            </Stack>
          </>
        ),
      },
    ];
  }, []);
  /* Event Filter */
  const _onChangeTextFilter = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      if (newValue === "") {
        _onClearFilter();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        });
      }
    }
  };

  const _onPressFilter = (newValue: string) => {
    let f: Ifilter = {
      ...filter,
      search: newValue,
    };

    setFilter(f);
    listOrganigrama(f);
  };

  const _onClearFilter = () => {
    let f: Ifilter = {
      ..._initialFilter(),
    };

    setFilter(f);
    listOrganigrama(f);
  };

  useEffect(() => {
    listOrganigrama(filter);
  }, []);
  useEffect(() => {
    setPager({
        ...pager,
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
        total: total,
    });
}, [total]);
useEffect(() => {
  setPager({
    ...pager,
    currentPage: currentPage,
  });

  if (filter) {
    setFilter({
      ...filter,
      skip: (currentPage - 1) * pageSize,
      take: pageSize,
    });
  }
}, [currentPage]);

  return (
    <div style={{ height: "100%" , width: "100%", padding:20}}>
      <HeadingBar
        title="Gerencias"
        subTitle="Lista de gerencias"
        searchChange={_onChangeTextFilter}
        searchHandler={_onPressFilter}
        searchValue={filter.search ?? ""}
        onClearHandler={_onClearFilter}
      />
      <TableBase
        leftBar={leftBar()}
        column={setTitleColumn()}
        data={gerencias}
        isLoading={isLoading}
        pagination={pager}
      />
    </div>
  );
};

export default TablaGerencias;
