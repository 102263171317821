import { IFilterProgramacionPersona } from "../interfaces/filter/IFilterProgramacionPersona";
import {
  IEditProgramacionPersona,
  IEmailHeloValido,
  IFilterAsignacion,
  IFilterProgramacion,
  IValidarMasivo,
  IValidarPruebaCovid,
  IValidarTransporteHelo,
} from "../interfaces/IProgramacion";
import { fetchMovilizacion } from "./http-common";
import { IAdicional } from "../interfaces/IAdicional";
import { IUpdateVuelo } from "../interfaces/IAccesoPersona";
import { Ifilter } from "../interfaces/IFilter";
import { IFilterDownLoadHistorico } from "../interfaces/IHistorico";

const urlProgramacionPersona = "AccesoProgramadoPersona";

const actualizar = (data: IEditProgramacionPersona) => {
  return fetchMovilizacion.put(`${urlProgramacionPersona}`, data);
};

const email = (id: string) => {
  return fetchMovilizacion.post(`${urlProgramacionPersona}/Email/${id}`);
};

/* Reporte */
const plantillaCargaMasiva = () => {
  return fetchMovilizacion.get(`AccesoProgramadoPersona/Plantilla`, {
    responseType: "blob",
  });
};

const plantillaCargaPruebasCovid = () => {
  return fetchMovilizacion.get(`AccesoProgramadoPersona/PlantillaCovid`, {
    responseType: "blob",
  });
};

const reporteLogCargaMasiva = (id: string) => {
  return fetchMovilizacion.get(
    `AccesoProgramadoPersona/Reporte/CargaMasiva/${id}`,
    { responseType: "blob" }
  );
};

/* Nivel KeyUser */
const pendienteKeyUser = (filter: IFilterProgramacion) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/PendienteKeyUser`, {
    params: filter,
  });
};

/* Transporte - Inscritos */
const inscritoTransporte = (filter: IFilterProgramacion) => {
  return fetchMovilizacion.get(
    `${urlProgramacionPersona}/Transporte/Inscrito`,
    { params: filter }
  );
};

/* Unidad medica */
const validarPruebaCovid = (data: IValidarPruebaCovid) => {
  return fetchMovilizacion.post(`${urlProgramacionPersona}/PruebaCovid`, data);
};

/* Adicionales Todos */
const allTodoAdicional = (filter: IFilterProgramacionPersona) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/Adicional`, {
    params: filter,
  });
};

/* Adicional Gerencia / Logistica */
const allAdicionalGerencia = (filter: IFilterProgramacionPersona) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/Adicional/Gerente`, {
    params: filter,
  });
};

const allAdicionalLogistica = (filter: IFilterProgramacionPersona) => {
  return fetchMovilizacion.get(
    `${urlProgramacionPersona}/Adicional/Logistica`,
    { params: filter }
  );
};

const validarAdicional = (data: IAdicional) => {
  return fetchMovilizacion.put(
    `${urlProgramacionPersona}/Adicional/Validar`,
    data
  );
};

const descargarReporteExcel = (data:any) => {
  return fetchMovilizacion.post(`${urlProgramacionPersona}/ReporteExcel`,data,{responseType: "blob"})
}


const allAdicionalValidado = (filter: IFilterProgramacionPersona) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/Adicional/Validado`, {
    params: filter,
  });
};

const allv2AccesoHelo = (filter: IFilterProgramacionPersona) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/Vuelo/Todo`, {
    params: filter,
  });
};

const allAccesoVuelo = (filter: IFilterProgramacionPersona) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/Vuelo/Pendiente`, {
    params: filter,
  });
};

const allBajadaPorAsignarHelo = (filter: IFilterAsignacion) => {
  return fetchMovilizacion.get(
    `${urlProgramacionPersona}/Vuelo/Bajada/PorAsignar`,
    { params: filter }
  );
};

const asignarHeloBajada = (data: IValidarTransporteHelo) => {
  return fetchMovilizacion.post(
    `${urlProgramacionPersona}/Vuelo/Bajada/Asignar`,
    data
  );
};

const validarVuelo = (data: IValidarMasivo) => {
  return fetchMovilizacion.post(
    `${urlProgramacionPersona}/Vuelo/Validar`,
    data
  );
};

const infoVuelo = (id: string) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/Vuelo/Info/${id}`);
};

const actualizarInfoVuelo = (data: IUpdateVuelo) => {
  return fetchMovilizacion.put(`${urlProgramacionPersona}/Vuelo/Info`, data);
};

const actualizarHeloExcel = (
  file: any,
  programacionId: string,
  isTabPersona
) => {
  return fetchMovilizacion.post(
    `${urlProgramacionPersona}/Vuelo/Masivo/${programacionId}/${isTabPersona}`,
    file,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

/* Email */
const emailHeloValido = (data: IEmailHeloValido) => {
  return fetchMovilizacion.post(
    `${urlProgramacionPersona}/Vuelo/Email/Validado`,
    data
  );
};

/* Actividad Log */
const actividadLog = (programacionPersonaId: string) => {
  return fetchMovilizacion.get(
    `${urlProgramacionPersona}/Log/${programacionPersonaId}`
  );
};

/* Historico */
const historico = (filter: Ifilter) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/Historico`, {
    params: filter,
  });
};

/* Historico Por Usuario*/
const historicoByPersona = (id: string) => {
  return fetchMovilizacion.get(
    `${urlProgramacionPersona}/HistoricoByPersona/${id}`
  );
};

const downLoadhistorico = (IntervaloFecha?: IFilterDownLoadHistorico) => {
  return fetchMovilizacion.get(`${urlProgramacionPersona}/DownloadHistorico`, {
    responseType: "blob",
    params: IntervaloFecha,
  });
};

const agregarAccesoProgramacionPersona = (data: any) => {
  return fetchMovilizacion.post(
    `${urlProgramacionPersona}/AgregarProgramacionPersona`,
    data
  );
};

const actualizarComunicadosMasivo = (
  file: any,
  programacionId: string,
  isTabPersona
) => {
  return fetchMovilizacion.post(
    `${urlProgramacionPersona}/Vuelo/MasivoComunicados/${programacionId}/${isTabPersona}`,
    file,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

const ProgramacionPersonaService = {
  actualizar,
  email,
  plantillaCargaMasiva,
  plantillaCargaPruebasCovid,
  reporteLogCargaMasiva,
  pendienteKeyUser,
  validarPruebaCovid,
  inscritoTransporte,
  agregarAccesoProgramacionPersona,
  descargarReporteExcel,
  /* Adicionales */
  allTodoAdicional,
  allAdicionalGerencia,
  allAdicionalLogistica,
  validarAdicional,
  allAdicionalValidado,

  /* Vuelo */
  allv2AccesoHelo,
  allAccesoVuelo,
  validarVuelo,
  infoVuelo,
  actualizarInfoVuelo,
  actualizarHeloExcel,
  allBajadaPorAsignarHelo,
  asignarHeloBajada,

  /* Email */
  emailHeloValido,

  /* ActividadLog */
  actividadLog,

  /* Historico */
  historico,
  downLoadhistorico,
  historicoByPersona,
  actualizarComunicadosMasivo
};

export default ProgramacionPersonaService;
