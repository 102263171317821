import {
  ComboBox,
  IColumn,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  Panel,
  PanelType,
  Persona,
  PersonaSize,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import { filter, selection } from "d3";
import { useEffect, useState } from "react";
import { TableBase } from "../../../components/Table/TableBase";
import {
  IBase,
  IException,
  IItemComboBaseDTO,
  ISearch,
} from "../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import {
  IFilterAsignacion,
  IFilterAsignacionAcciones,
  IProgramacionPersona,
} from "../../../interfaces/IProgramacion";
import ProgramacionBusService from "../../../services/programacionBusService";
import {
  baseImageUrl,
  IndexNone,
  ItemNoneSelect,
  splitExceptionDetail,
  stackTokens,
  _initialFilter,
  _initialPagination,
} from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import React from "react";
import ConfiguracionService from "../../../services/configuracionService";
import OrganigramaService from "../../../services/organigramaService";
import PersonaService from "../../../services/PersonaService";

export const ListarAsignadoBus = ({
  programacionId = "",
  estaAbordado = false,
}) => {
  const [hidden, setHidden] = useState(false);

  const [destino, setDestino] = useState<IItemComboBaseDTO[]>([]);
  const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
  const [gerencia, setGerencia] = useState<IItemComboBaseDTO[]>([]);

  /* Filter */
  const [filter, setFilter] = useState<IFilterAsignacionAcciones>({
    ..._initialFilter(),
    programacionId: programacionId,
    estaAbordado: estaAbordado,
  });
  const [filterDestino, setFilterDestino] = useState<ISearch>(_initialFilter);
  const [filterEmpresa, setFilterEmpresa] = useState<ISearch>(_initialFilter);
  const [filterGerencia, setFilterGerencia] = useState<ISearch>(_initialFilter);

  /* Ref */
  const refDestino = React.useRef<IComboBox>(null);
  const refEmpresa = React.useRef<IComboBox>(null);
  const refGerencia = React.useRef<IComboBox>(null);

  const _openCampamento = React.useCallback(
    () => refDestino.current?.focus(true),
    []
  );
  const _openEmpresa = React.useCallback(
    () => refEmpresa.current?.focus(true),
    []
  );
  const _openGerencia = React.useCallback(
    () => refGerencia.current?.focus(true),
    []
  );

  /* Panel Filter */
  const [isOpenFilter, { setTrue: openFilter, setFalse: dismissFilter }] =
    useBoolean(false);

  /* Data & Colum */
  const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(
    _initialDataCollection
  );
  const [column, setColumn] = useState<IColumn[]>([]);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  /* Hook */
  useEffect(() => {
    if (programacionId != "") {
      reloadInitial();
    }
  }, []);

  useEffect(() => {
    if (filterDestino.search != null) {
      dataDestino();
    }
  }, [filterDestino]);

  useEffect(() => {
    if (filterEmpresa.search != null) {
      dataEmpresa();
    }
  }, [filterEmpresa]);

  useEffect(() => {
    if (filterGerencia.search != null) {
      dataGerencia();
    }
  }, [filterGerencia]);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  /* Api */
  const dataAsignado = (param = filter) => {
    if (programacionId != "") {
      setData(_initialDataCollection);
      ProgramacionBusService.allAsignadoBus(param)
        .then((res) => {
          if (res.status == 200) {
            setData(res.data);
            setHidden(true);
            setTotal(res.data.total);
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
          setHidden(true);
        });
    }
  };

  const dataDestino = () => {
    PersonaService.getComboCampamento1(filterDestino)
      .then((res) => {
        let d: IBase[] = res.data.data;

        let t: IItemComboBaseDTO[] = d.map((x) => {
          return {
            key: x.id,
            text: x.nombre,
          };
        });

        t.unshift(ItemNoneSelect());

        setDestino(t);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataEmpresa = () => {
    setEmpresa([]);
    ConfiguracionService.allEmpresa(filterEmpresa)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          t.unshift(ItemNoneSelect());

          setEmpresa(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataGerencia = () => {
    setGerencia([]);
    OrganigramaService.organigramaByTipo("01", filterGerencia)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          t.unshift(ItemNoneSelect());

          setGerencia(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  /* Function */
  const reloadInitial = (param = filter) => {
    setHidden(false);
    setTitleColumn();
    dataAsignado(param);
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        onClick: () => {
          alert("back");
        },
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => reloadInitial(),
      },
      // {
      //     ...IconHelper.ICON_FILTER,
      //     onClick: () => {
      //         openFilter();
      //     },
      // },
    ];

    return _items;
  };

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Foto",
        ariaLabel:
          "Column operations for File type, Press to sort on File type",
        iconName: "-",
        isIconOnly: true,
        fieldName: "foto",
        minWidth: 30,
        maxWidth: 30,
        onRender: (item: IProgramacionPersona) => (
          <TooltipHost content={`Foto`}>
            <Persona
              text=""
              size={PersonaSize.size24}
              imageUrl={baseImageUrl}
            />
          </TooltipHost>
        ),
      },
      {
        key: "column2",
        name: "Nombre",
        fieldName: "nombres",
        minWidth: 170,
        maxWidth: 170,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return <span style={{ fontSize: "90%" }}>{item.nombres}</span>;
        },
      },
      {
        key: "column3",
        name: "Identificación",
        fieldName: "nroDOI",
        minWidth: 80,
        maxWidth: 90,
        isResizable: true,
        data: "number",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%" }}
            >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
          );
        },
      },
      // {
      //     key: 'column4',
      //     name: 'Email',
      //     fieldName: 'email',
      //     minWidth: 180,
      //     maxWidth: 180,
      //     isResizable: true,
      //     isCollapsible: true,
      //     data: 'string',
      //     isPadded: true,
      // },
      // {
      //     key: 'column5',
      //     name: 'Celular',
      //     fieldName: 'celular',
      //     minWidth: 70,
      //     maxWidth: 90,
      //     isResizable: true,
      //     isCollapsible: true,
      //     data: 'string',
      //     isPadded: true,
      // },
      {
        key: "column6",
        name: "Gerencia",
        fieldName: "gerencia",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column7",
        name: "Lugar Mov.",
        fieldName: "lugarMovilizacion",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column8",
        name: "Embarque",
        fieldName: "embarque",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column9",
        name: "Destino",
        fieldName: "destino",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column10",
        name: "Empresa Transportista",
        fieldName: "empresaTransporte",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column11",
        name: "Placa",
        fieldName: "placa",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column12",
        name: "Código Interno",
        fieldName: "codigoInterno",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column13",
        name: "Empresa Persona",
        fieldName: "empresaPersona",
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      // {
      //     key: "column13",
      //     name: "Fecha Ingreso",
      //     fieldName: "fechaIngreso",
      //     minWidth: 100,
      //     maxWidth: 100,
      //     isResizable: true,
      //     isCollapsible: true,
      //     data: "number",
      // },
      {
        key: "column14",
        name: "Nivel",
        fieldName: "nivelActual",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column15",
        name: "Estado",
        fieldName: "estadoActual",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
    ];

    setColumn(col);
  };

  /* Event Tab */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: IFilterAsignacionAcciones = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      };

      setFilter(f);
      setCurrentPage(page);

      reloadInitial(f);
    }
  };

  const _clearSearch = (type: string) => {
    switch (type) {
      case "destino":
        setFilterDestino({
          ...filterDestino,
          search: "",
        });
        break;
      case "empresa":
        setFilterEmpresa({
          ...filterEmpresa,
          search: "",
        });
        break;
      case "gerencia":
        setFilterGerencia({
          ...filterGerencia,
          search: "",
        });

        break;
      default:
        break;
    }
  };

  /* Event Filter */
  const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
    let { value } = ev?.target as HTMLInputElement;

    switch (type) {
      case "destino":
        _openCampamento();

        if (filter.campamentoId != null) {
          setFilter({
            ...filter,
            campamentoId: null,
          });

          reloadInitial();
        }

        setFilterDestino({
          ...filterDestino,
          search: value,
        });
        break;
      case "empresa":
        _openEmpresa();

        if (filter.empresaId != null) {
          setFilter({
            ...filter,
            empresaId: null,
          });

          reloadInitial();
        }

        setFilterEmpresa({
          ...filterEmpresa,
          search: value,
        });
        break;
      case "gerencia":
        _openGerencia();

        if (filter.gerenciaId != null) {
          setFilter({
            ...filter,
            gerenciaId: null,
          });

          reloadInitial();
        }

        setFilterGerencia({
          ...filterGerencia,
          search: value,
        });
        break;
      default:
        break;
    }
  };

  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        let k: string | null = null;

        let isNone = option.key == IndexNone;

        if (isNone) {
          _clearSearch(type);
        }

        switch (type) {
          case "destino":
            if (!isNone) {
              k = String(option.key);
            }

            let fDes: IFilterAsignacionAcciones = {
              ...filter,
              campamentoId: k,
            };

            setFilter(fDes);
            reloadInitial(fDes);
            break;
          case "empresa":
            if (!isNone) {
              k = String(option.key);
            }

            let fEmp: IFilterAsignacionAcciones = {
              ...filter,
              empresaId: k,
            };

            setFilter(fEmp);
            reloadInitial(fEmp);
            break;
          case "gerencia":
            if (!isNone) {
              k = String(option.key);
            }

            let fGer: IFilterAsignacionAcciones = {
              ...filter,
              gerenciaId: k,
            };

            setFilter(fGer);
            reloadInitial(fGer);
            break;
          default:
            break;
        }
      }
    }
  };

  const _onBlurCombo = (type: string) => {
    switch (type) {
      case "destino":
        if (
          (filter.campamentoId == "" || filter.campamentoId == undefined) &&
          filter.campamentoId != null
        ) {
          setFilter({
            ...filter,
            campamentoId: null,
          });
        }
        break;
      case "empresa":
        if (
          (filter.empresaId == "" || filter.empresaId == undefined) &&
          filter.empresaId != null
        ) {
          setFilter({
            ...filter,
            empresaId: null,
          });
        }
        break;
      case "gerencia":
        if (
          (filter.gerenciaId == "" || filter.gerenciaId == undefined) &&
          filter.gerenciaId != null
        ) {
          setFilter({
            ...filter,
            gerenciaId: null,
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TableBase
        leftBar={_leftBar()}
        column={column}
        data={data.items}
        style={ThemeBase.notPaddingTableBase}
        pagination={pager}
        isLoading={!hidden}
        selection={selection}
      ></TableBase>

      <Panel
        type={PanelType.medium}
        isOpen={isOpenFilter}
        onDismiss={dismissFilter}
        headerText="Asignar a helicóptero"
        closeButtonAriaLabel="Close"
        // onRenderFooterContent={onRenderFooterFilter}
        isFooterAtBottom={true}
      >
        <div className="ms-Grid" dir="ltr" style={{ paddingTop: "20%" }}>
          <Stack tokens={stackTokens}>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg6">
                <ComboBox
                  label="Destino"
                  componentRef={refDestino}
                  placeholder="Destino"
                  allowFreeform
                  autoComplete="off"
                  useComboBoxAsMenuWidth
                  calloutProps={{ directionalHintFixed: true }}
                  options={destino}
                  onKeyUp={(e) => _onUpCombo("destino", e)}
                  selectedKey={filter.campamentoId}
                  onChange={(e, o) => _onChangeCombo("destino", e, o)}
                  onBlur={() => _onBlurCombo("destino")}
                  required
                />
              </div>

              <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg6">
                <ComboBox
                  label="Empresa"
                  componentRef={refEmpresa}
                  placeholder="Empresa"
                  allowFreeform
                  autoComplete="off"
                  useComboBoxAsMenuWidth
                  calloutProps={{ directionalHintFixed: true }}
                  options={empresa}
                  onKeyUp={(e) => _onUpCombo("empresa", e)}
                  selectedKey={filter.empresaId}
                  onChange={(e, o) => _onChangeCombo("empresa", e, o)}
                  onBlur={() => _onBlurCombo("empresa")}
                  required
                />
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg6">
                <ComboBox
                  label="Gerencia"
                  componentRef={refGerencia}
                  placeholder="Gerencia"
                  allowFreeform
                  autoComplete="off"
                  useComboBoxAsMenuWidth
                  calloutProps={{ directionalHintFixed: true }}
                  options={gerencia}
                  onKeyUp={(e) => _onUpCombo("gerencia", e)}
                  selectedKey={filter.gerenciaId}
                  onChange={(e, o) => _onChangeCombo("gerencia", e, o)}
                  onBlur={() => _onBlurCombo("gerencia")}
                  required
                />
              </div>
            </div>
          </Stack>
        </div>
      </Panel>
    </>
  );
};
