import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, ICommandBarItemProps, Icon, IconButton, Label, Persona, PersonaSize, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, Stack, TextField, TooltipHost } from "@fluentui/react";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { IBase, IException, IItemComboBaseDTO, IMotivoEliminacion, ISearch } from "../../interfaces/base/IBase";
import { IFilterAsignacion, IFilterPersonaTransporte, IInfoProgramacion, IProgramacionPersona } from "../../interfaces/IProgramacion";
import PersonaService from "../../services/PersonaService";
import { useBoolean } from "@fluentui/react-hooks";
import { IAsignarTransporte, IEliminarProgramacionBus, IItemAsignar, IRegistrarRuta, ITransporteRuta } from "../../interfaces/IProgTransporte";
import { baseImageUrl, IndexNone, ItemNoneSelect, modalCancelIcon, modalIconButtonStyles, modalStyle, splitExceptionDetail, stackTokens, stackTokensHorizontal, _initialFilter, _initialPagination } from "../../utils/Helper/helper";
import { ButtonForm } from "../../components/Generic/buttonForm";
import TransporteService from "../../services/transporteService";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { IEditarRutaTransporte } from "../../interfaces/ITransporte";
import ProgramacionService from "../../services/ProgramacionService";
import { ModuloHelper } from "../../utils/Helper/moduloHelper";
import RepoteBase from "../../components/Generic/ReporteBase";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { TabAsignacionBus } from "../asignacion/bus/tabAsignacionBus";
import { TabAsignacionVuelo } from "../asignacion/vuelo/tabAsignacionVuelo";
import { IPagination } from "../../interfaces/base/ITable";
import { ListarTransporteAsignado } from "./v2.Ruta/listarTransporteAsignado";
import { AuthContext } from "../../auth/context/auth-context";
import { isRoleAccessAll, roleAdmin, ROLE_KEY_USER_EMPLEADO, ROLE_KEY_USER_SOCIO } from "../../utils/Helper/roleHelper";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { TabAccesoPersonaVuelo } from "../accesoPersona/vuelo/tabAccesoPersonaVuelo";

const initialFilter = () => {
  let t: IFilterAsignacion = {
    skip: 0,
    take: 50,
    programacionId: null,
  };
  return t;
};

const roleTabHelo = () => {
  let a = [
    ROLE_KEY_USER_EMPLEADO,
    ROLE_KEY_USER_SOCIO,
  ];

  return roleAdmin().concat(a);
}


export const Asignacion = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  // const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const [tabIndex, setTabIndex] = useState("0");
  const [filter, setFilter] = useState<IFilterAsignacion>({
    ...initialFilter(),
    programacionId: id,
  });
  // const [bus, setBus] = useState<IItemComboBaseDTO[]>([]);
  // const [paradero, setParadero] = useState<IItemComboBaseDTO[]>([]);
  // const [ruta, setRuta] = useState<IItemComboBaseDTO[]>([]);
  const [modelRuta, setModelRuta] = useState<IRegistrarRuta>(null!);

  // const [pendiente, setPendiente] = useState<IProgramacionPersona[]>([]);
  // const [asignado, setAsignado] = useState<IProgramacionPersona[]>([]);
  // const [transporteRuta, setTransporteRuta] = useState<IDataCollection<ITransporteRuta>>(_initialDataCollection);
  // const [column, setColumn] = useState<IColumn[]>([]);
  // const [columnTransporteRuta, setColumnTransporteRuta] = useState<IColumn[]>([]);
  const [sel, setSel] = useState<any[]>([]);
  const [formEditRuta, setFormEditRuta] = useState<IEditarRutaTransporte>(null!);
  // const [hiddenRuta, setHiddenRuta] = useState(false);

  /* Modal Transporte Ruta */
  const [isOpenRuta, setIsOpenRuta] = useState(false);
  const [isEditRuta, { setTrue: showEditRuta, setFalse: hideEditRuta }] = useBoolean(false);
  const [isModalOpenDown, { setTrue: showModalDonw, setFalse: hideModalDown }] = useBoolean(false);

  /* Panel Telerik */
  const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);
  const [selAccesoBusId, setSelAccesoBusId] = useState<string | undefined>(undefined);
  const [isOpenAccesoPersona, { setTrue: openAccesoPersona, setFalse: dismissAccesoPersona }] = useBoolean(false);

  /* Tab Asignacion de bus */
  // const [filterBus, setFilterBus] = useState<IFilterAsignacion>({ ..._initialFilter(), programacionId: id });
  const [filterCampamento, setfilterCampamento] = useState<ISearch>(_initialFilter);
  const campamentoRef = React.useRef<IComboBox>(null);
  // const [campamento, setCampamento] = useState<IItemComboBaseDTO[]>([]);
  // const _openOptionCampamento = React.useCallback(() => campamentoRef.current?.focus(true), []);

  /* Tab 2 - Personas */
  const [filterPersonaTransporte, setFilterPersonaTransporte] = useState<IFilterPersonaTransporte>({
    ...initialFilter(),
    programacionId: id,
  });

  const [reloadPersonaTransporte, setReloadPersonaTransporte] = useState(false);

  /* Personal Asignado */
  // const [personaAsignado, setPersonaAsignado] = useState<ICollectionPersonaBus>(_initialDataCollection);
  // const [columnPersonaAsignado, setColumnPersonaAsignado] = useState<IColumn[]>([]);

  /* Modal Eliminar Transporte */
  const [isEliminarTransporte, { setTrue: showEliminarTransporte, setFalse: dismissEliminarTransporte },] = useBoolean(false);
  const [motivoEliminacion, setMotivoEliminacion] = useState<IMotivoEliminacion[]>([]);
  const [formEliminar, setFormEliminar] = useState<IEliminarProgramacionBus>(null!);

  /* Info programacion - validar si es bajada */
  const [programacion, setProgramacion] = useState<IInfoProgramacion>(null!);

  /* Access & Role */
  const { access } = useContext(AuthContext);
  let role = access?.role ?? [];
  let isAdmin = isRoleAccessAll(role, roleAdmin());
  let isEmpresaTransportista = access?.esEmpresaTransportista;
  let isEmpresaVuelo = access?.esEmpresaVuelo;

  /* Role Tab */
  const childRef: any = useRef(null);
  const [isShowTabHelo, setIsShowTabHelo] = useState(isRoleAccessAll(role, roleTabHelo()));

  /* V2. Solo se mantendra el tab de Transportes */
  /* Pagination */
  const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  // useEffect(() => {
  //   dataBus();
  // }, []);

  useEffect(() => {
    if (id != undefined) {
      setFilter({
        ...filter,
        programacionId: id,
      });

      setModelRuta({
        ...modelRuta,
        programacionId: id,
      });

      infoProgramacion();
    }
  }, [id]);

  // useEffect(() => {
  //   if (filter.transporteId) {
  //     dataPendiente();
  //   }
  // }, [filter]);

  useEffect(() => {
    reloadTab();
  }, [tabIndex]);

  // useEffect(() => {
  //   if (isOpenRuta || isEditRuta) {
  //     dataRuta();
  //   }
  // }, [isOpenRuta, isEditRuta]);

  useEffect(() => {
    if (isEliminarTransporte) {
      dataMotivoEliminacion();
    }
  }, [isEliminarTransporte]);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  /* Tab Personas */
  // useEffect(() => {
  //   dataPersonaAsignado();
  // }, [filterPersonaTransporte]);

  /* Api */
  const infoProgramacion = () => {
    PersonaService.getInfoProgramacion(id)
      .then((res) => {
        let d: IInfoProgramacion = res.data;
        setProgramacion(d);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  // const dataBus = () => {
  //   PersonaService.getComboTransportes()
  //     .then((res) => {
  //       if (res.status == 200) {
  //         let d: IBase[] = res.data.data;
  //         let t: IItemComboBaseDTO[] = [];

  //         t = d.map((x) => {
  //           return {
  //             key: x.id,
  //             text: x.nombre,
  //           };
  //         });

  //         t.unshift(ItemNoneSelect());

  //         setBus(t);
  //       }
  //     })
  //     .catch((e) => {
  //       alert(e.message);
  //     });
  // };

  // const dataCampamento = () => {
  //   PersonaService.getComboCampamento1(filterCampamento).then(res => {
  //     let d: IBase[] = res.data.data;

  //     let t: IItemComboBaseDTO[] = d.map(x => {
  //       return ({
  //         key: x.id,
  //         text: x.nombre,
  //       })
  //     });

  //     t.unshift(ItemNoneSelect());

  //     setCampamento(t);
  //   })
  //     .catch((e: Error) => {
  //       console.log(e);
  //     });;
  // }

  // const dataRuta = () => {
  //   let search = "";
  //   PersonaService.getComboTransporteRuta(search)
  //     .then((res: any) => {
  //       if (res.status == 200) {
  //         var comboP = res.data.data;
  //         let c: any = [];
  //         comboP.map((resp: any) => {
  //           c.push({
  //             key: resp.id,
  //             text: resp.nombre,
  //           });
  //         });
  //         setRuta(c);
  //       }
  //     })
  //     .catch((e: Error) => {
  //       console.log(e);
  //     });
  // };

  // const dataPendiente = () => {
  //   setPendiente([]);
  //   generateColumnPendiente();

  //   PersonaService.getPendientesBus(filter)
  //     .then((res) => {
  //       setPendiente(res.data.data);
  //     })
  //     .catch((e) => {
  //       alert(e);
  //     });
  // };

  // const dataAsignado = () => {
  //   setAsignado([]);

  //   if (filter.programacionId != undefined) {
  //     PersonaService.getPersonasAsignadas(50, filter.programacionId)
  //       .then((res) => {
  //         setAsignado(res.data.data);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   }
  // };

  // const dataTransporteRuta = (param = filter) => {
  //   setHiddenRuta(true);
  //   setTransporteRuta(_initialDataCollection);

  //   if (filter.programacionId != undefined) {
  //     PersonaService.getTableTransporteRuta(param)
  //       .then((res) => {
  //         console.log(res.data);
  //         setTransporteRuta(res.data);
  //         setTotal(res.data.total);
  //         setHiddenRuta(false);
  //       })
  //       .catch((e: IException) => {
  //         console.log(splitExceptionDetail(e));
  //         setHiddenRuta(false);
  //       });
  //   }
  // };

  // const asignar = () => {
  //   console.log(filter);
  //   console.log(sel);

  //   if (!filter.transporteId || filter.transporteId == undefined) {
  //     return alert("Debe seleccionar el transporte");
  //   }

  //   if (sel.length == 0) {
  //     return alert(
  //       "Debe de seleccionar al menos una persona para poder asignar"
  //     );
  //   }

  //   let lst: IItemAsignar[] = sel.map((x) => {
  //     return {
  //       programacionPersonaId: x.programacionPersonaId,
  //       asiento: parseInt(x.asiento),
  //     };
  //   });

  //   let d: IAsignarTransporte = {
  //     transporteId: filter.transporteId,
  //     lista: lst,
  //   };

  //   console.log(d);

  //   PersonaService.postAgregarAsientoMasivo(d)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         dataPendiente();
  //         dismissPanel();
  //         alert("Se asignó correctamente");
  //       }
  //     })
  //     .catch((e) => {
  //       alert(e.message);
  //     });
  // };

  // const asignarVehiculo = () => {
  //   PersonaService.postTransporteParadero(modelRuta)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         dataTransporteRuta();
  //         setIsOpenRuta(false);
  //         alert("registrado");
  //       }
  //     })
  //     .catch((e: IException) => {
  //       alert(splitExceptionDetail(e));
  //     });
  // };

  // const actualizarRuta = () => {
  //   TransporteService.ActualizarRuta(formEditRuta)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         hideEditRuta();
  //         generateColumnTransporteRuta();
  //         reloadRuta();
  //       }
  //     })
  //     .catch((e: IException) => {
  //       alert(splitExceptionDetail(e));
  //     });
  // };

  // const eliminarRuta = () => {
  //   TransporteService.EliminarRuta(formEliminar)
  //     .then((response) => {
  //       if (response.status == 200) {
  //         generateColumnTransporteRuta();
  //         dismissEliminarTransporte();
  //         reloadRuta();
  //       }
  //     })
  //     .catch((e: IException) => {
  //       alert(splitExceptionDetail(e));
  //     });
  // };

  // const dataPersonaAsignado = () => {
  //   if (id != undefined) {
  //     ProgramacionService.personalAsignadoEmpresaTransportista(id, filterPersonaTransporte)
  //       .then((res) => {
  //         if (res.status == 200) {
  //           setPersonaAsignado(res.data);
  //         }
  //       })
  //       .catch((e: IException) => {
  //         alert(splitExceptionDetail(e));
  //       });
  //   }
  // };

  const dataMotivoEliminacion = () => {
    ProgramacionService.allMotivoEliminacion(ModuloHelper.programacionBus)
      .then((res: any) => {
        if (res.status == 200) {
          setMotivoEliminacion(res.data);

          let t: IMotivoEliminacion[] = res.data;
          let d = t.find(x => x.defecto);

          setFormEliminar({
            ...formEliminar,
            motivoEliminacionId: d ? d.key : '',
          })
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  /* Function */
  // const reloadInitial = (param = filter) => {
  //   dataTransporteRuta(param);
  // }

  // const generateColumnPendiente = () => {
  //   let col: IColumn[] = [
  //     {
  //       key: "column1",
  //       name: "Foto",
  //       ariaLabel:
  //         "Column operations for File type, Press to sort on File type",
  //       iconName: "-",
  //       isIconOnly: true,
  //       fieldName: "foto",
  //       minWidth: 45,
  //       maxWidth: 45,
  //       onRender: (item: IProgramacionPersona) => (
  //         <TooltipHost content={`Foto`}>
  //           <Persona
  //             text=""
  //             size={PersonaSize.size24}
  //             imageUrl={baseImageUrl}
  //           />
  //         </TooltipHost>
  //       ),
  //     },
  //     {
  //       key: "column2",
  //       name: "Nombre",
  //       fieldName: "nombres",
  //       minWidth: 130,
  //       maxWidth: 150,
  //       isRowHeader: true,
  //       isResizable: true,
  //       isSorted: true,
  //       isSortedDescending: false,
  //       sortAscendingAriaLabel: "Sorted A to Z",
  //       sortDescendingAriaLabel: "Sorted Z to A",
  //       data: "string",
  //       isPadded: true,
  //       onRender: (item: IProgramacionPersona) => {
  //         return (
  //           <span
  //             style={{
  //               fontSize: "90%",
  //               textDecorationLine:
  //                 item.motivoRetiro == "" ? "none" : "line-through",
  //             }}
  //           >
  //             {item.nombres}
  //           </span>
  //         );
  //       },
  //     },
  //     {
  //       key: "column3",
  //       name: "Documento",
  //       fieldName: "nroDOI",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       data: "number",
  //       isPadded: true,
  //     },
  //     /* {
  //               key: 'column4',
  //               name: 'Celular',
  //               fieldName: 'celular',
  //               minWidth: 70,
  //               maxWidth: 90,
  //               isResizable: true,
  //               isCollapsible: true,
  //               data: 'string',
  //               isPadded: true,
  //           }, */
  //     {
  //       key: "column5",
  //       name: "Nivel",
  //       fieldName: "nivelActual",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column6",
  //       name: "Estado",
  //       fieldName: "estadoActual",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column8",
  //       name: "Tipo de Documento",
  //       fieldName: "tipoDocumento",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column9",
  //       name: "Empresa",
  //       fieldName: "area",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column10",
  //       name: "Email",
  //       fieldName: "email",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column11",
  //       name: "Transporte",
  //       fieldName: "transporte",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column12",
  //       name: "Asiento",
  //       fieldName: "asiento",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "text",
  //     },
  //   ];

  //   setColumn(col);
  // };

  // const generateColumnTransporteRuta = () => {
  //   let col: IColumn[] = [
  //     {
  //       key: "column1",
  //       name: "Placa",
  //       fieldName: "transporte",
  //       minWidth: 90,
  //       maxWidth: 100,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //       onRender: (item: ITransporteRuta) => {
  //         return (
  //           <span
  //             style={{
  //               textDecorationLine:
  //                 item.deleted == "" ? "none" : "line-through",
  //             }}
  //           >
  //             {item.transporte}
  //           </span>
  //         );
  //       },
  //     },
  //     {
  //       key: "column2",
  //       name: "Tipo Vehículo",
  //       fieldName: "tipo",
  //       minWidth: 90,
  //       maxWidth: 100,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column3",
  //       name: "Categoría",
  //       fieldName: "categoria",
  //       minWidth: 90,
  //       maxWidth: 100,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column4",
  //       name: "Marca",
  //       fieldName: "marca",
  //       minWidth: 90,
  //       maxWidth: 100,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column5",
  //       name: "Modelo",
  //       fieldName: "modelo",
  //       minWidth: 100,
  //       maxWidth: 110,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column6",
  //       name: "Año Fabricación",
  //       fieldName: "anioFabricacion",
  //       minWidth: 100,
  //       maxWidth: 110,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column7",
  //       name: "Pasajeros",
  //       fieldName: "pasajeros",
  //       minWidth: 80,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column8",
  //       name: "F. Registro",
  //       fieldName: "fecha",
  //       minWidth: 100,
  //       maxWidth: 110,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column9",
  //       name: "Registrado Por",
  //       fieldName: "usuarioRegistra",
  //       minWidth: 100,
  //       maxWidth: 110,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column10",
  //       name: "Motivo Eliminación",
  //       fieldName: "motivoEliminacion",
  //       minWidth: 160,
  //       maxWidth: 170,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column10",
  //       name: "Comentario",
  //       fieldName: "comentario",
  //       minWidth: 160,
  //       maxWidth: 170,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column11",
  //       name: "F. Eliminación",
  //       fieldName: "deleted",
  //       minWidth: 100,
  //       maxWidth: 110,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column12",
  //       name: "Eliminado Por",
  //       fieldName: "usuarioElimina",
  //       minWidth: 100,
  //       maxWidth: 110,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column12",
  //       name: "Embarcados",
  //       fieldName: "embarcado",
  //       minWidth: 100,
  //       maxWidth: 110,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column14",
  //       name: "Empresa Transportista",
  //       fieldName: "empresa",
  //       minWidth: 140,
  //       maxWidth: 150,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column15",
  //       name: "Opciones",
  //       fieldName: "opciones",
  //       minWidth: 100,
  //       maxWidth: 120,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //       onRender: (item: ITransporteRuta) => (
  //         <>
  //           <Stack horizontal tokens={stackTokensHorizontal}>
  //             <TooltipHost content="Manifiesto">
  //               <Icon
  //                 iconName={IconHelper.ICON_REPORT_DOCUMENT.iconProps?.iconName}
  //                 style={{ cursor: "pointer" }}
  //                 className={ThemeBase.classes.iconFont}
  //                 onClick={() => {
  //                   setSelAccesoBusId(item.programacionBusId);
  //                   openTelerik();
  //                   console.log(selAccesoBusId)
  //                 }}
  //               />
  //             </TooltipHost>
  //             <TooltipHost content="Embarcados">
  //               <Icon
  //                 iconName="ReminderGroup"
  //                 style={{ cursor: "pointer" }}
  //                 className={ThemeBase.classes.iconFont}
  //                 onClick={() =>
  //                   navigate(
  //                     `${ConfigHelper.urlBase()}/empresa.transporte/asignado/${item.programacionBusId}`
  //                   )
  //                 }
  //               />
  //             </TooltipHost>

  //             {item.deleted == "" && (
  //               <TooltipHost content={`Eliminar`}>
  //                 <Icon
  //                   iconName="Trash"
  //                   style={{ cursor: "pointer" }}
  //                   className={ThemeBase.classes.iconFont}
  //                   onClick={() => {
  //                     showEliminarTransporte();

  //                     setFormEliminar({
  //                       ...formEliminar,
  //                       programacionBusId: item.programacionBusId,
  //                     });
  //                   }}
  //                 />
  //               </TooltipHost>
  //             )}
  //           </Stack>
  //         </>
  //       ),
  //     },
  //   ];

  //   setColumnTransporteRuta(col);
  // };

  // const generateColumnPersonaAsignado = () => {
  //   let col: IColumn[] = [
  //     {
  //       key: "column1",
  //       name: "Foto",
  //       ariaLabel:
  //         "Column operations for File type, Press to sort on File type",
  //       isIconOnly: true,
  //       fieldName: "foto",
  //       minWidth: 45,
  //       maxWidth: 45,
  //       onRender: (item: IProgramacionPersona) => (
  //         <TooltipHost content={`Foto`}>
  //           <Persona
  //             text=""
  //             size={PersonaSize.size24}
  //             imageUrl="http://www.consuladohondurasmadrid.es/wp-content/uploads/2017/05/male-300x300.png"
  //           />
  //         </TooltipHost>
  //       ),
  //     },
  //     {
  //       key: "column2",
  //       name: "Nombre",
  //       fieldName: "nombres",
  //       minWidth: 160,
  //       maxWidth: 200,
  //       isRowHeader: true,
  //       isResizable: true,
  //       isSorted: false,
  //       isSortedDescending: false,
  //       sortAscendingAriaLabel: "Sorted A to Z",
  //       sortDescendingAriaLabel: "Sorted Z to A",
  //       data: "string",
  //       isPadded: true,
  //       onRender: (item: IProgramacionPersona) => {
  //         return (
  //           <span
  //             style={{
  //               fontSize: "90%",
  //               textDecorationLine:
  //                 item.motivoRetiro == "" ? "none" : "line-through",
  //             }}
  //           >
  //             {item.nombres}
  //           </span>
  //         );
  //       },
  //     },
  //     {
  //       key: "column3",
  //       name: "Identificación",
  //       fieldName: "nroDOI",
  //       minWidth: 80,
  //       maxWidth: 90,
  //       isResizable: true,
  //       data: "number",
  //       isPadded: true,
  //       onRender: (item: IProgramacionPersona) => {
  //         return (
  //           <span
  //             style={{ fontSize: "95%" }}
  //           >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
  //         );
  //       },
  //     },
  //     {
  //       key: "column4",
  //       name: "Email",
  //       fieldName: "email",
  //       minWidth: 170,
  //       maxWidth: 180,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column5",
  //       name: "Celular",
  //       fieldName: "celular",
  //       minWidth: 70,
  //       maxWidth: 70,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //       isPadded: true,
  //     },
  //     {
  //       key: "column16",
  //       name: "Nivel",
  //       fieldName: "nivelActual",
  //       minWidth: 150,
  //       maxWidth: 160,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column17",
  //       name: "Estado",
  //       fieldName: "estadoActual",
  //       minWidth: 150,
  //       maxWidth: 160,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "number",
  //     },
  //     {
  //       key: "column15",
  //       name: "Clasificación",
  //       fieldName: "clasificacion",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //     },
  //     {
  //       key: "column15",
  //       name: "Es Adicional",
  //       fieldName: "esAdicional",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //     },
  //     {
  //       key: "column12",
  //       name: "Registrado por",
  //       fieldName: "nombreUsuarioRegistra",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //     },
  //     {
  //       key: "column13",
  //       name: "Validado por",
  //       fieldName: "nombreUsuarioValida",
  //       minWidth: 70,
  //       maxWidth: 90,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //     },
  //     {
  //       key: "column10",
  //       name: "Empresa Transportista",
  //       fieldName: "transporte",
  //       minWidth: 200,
  //       maxWidth: 200,
  //       isResizable: true,
  //       isCollapsible: true,
  //       data: "string",
  //     },
  //   ];

  //   setColumnPersonaAsignado(col);
  // };

  // const reloadRuta = () => {
  //   dataTransporteRuta();
  // };

  // const reloadAsignado = () => {
  //   dataAsignado();
  // };

  const reloadTab = () => {
    switch (tabIndex) {
      case "1":
        setReloadPersonaTransporte(!reloadPersonaTransporte);
        break;
      case "2":
        // generateColumnPersonaAsignado();
        // dataPersonaAsignado();
        // dataBus();
        // dataCampamento();
        break;
      case "3":
        /* Asignados */
        // generateColumnPendiente();
        // dataAsignado();
        break;
      default:
        break;
    }
  };

  // const downbload = () => {
  //   showModalDonw();
  //   TransporteService.reporteTrasportePersona(id).then((x) => {
  //     console.log(x);
  //     saveAs(x.data, "Reporte Trasporte Persona.xlsx");
  //     hideModalDown();
  //   });
  // };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        onClick: () => {
          navigate(`${ConfigHelper.urlBase()}/empresa.transporte`);
        },
      },
    ];

    return _items;
  };

  // const _rightBar = () => {
  //   const _items: ICommandBarItemProps[] = [
  //     {
  //       ...IconHelper.ICON_EXCEL,
  //       onClick: openAccesoPersona,
  //     },
  //   ];

  //   return _items;
  // };

  // const _generateItemBar = () => {
  //   const _items: ICommandBarItemProps[] = [
  //     {
  //       key: "CheckMark",
  //       text: "Asignar",
  //       iconProps: { iconName: "CheckMark" },
  //       onClick: () => {
  //         if (sel.length == 0) {
  //           return alert("Debe seleccionar al menos un item");
  //         }
  //         openPanel();
  //       },
  //     },
  //   ];

  //   return _items;
  // };

  // const generateItemBarTransporteRuta = () => {
  //   const _items: ICommandBarItemProps[] = [
  //     {
  //       ...IconHelper.ICON_RELOAD,
  //       onClick: () => {
  //         reloadRuta();
  //       },
  //     },
  //     {
  //       ...IconHelper.ICON_ADD,
  //       onClick: () => {
  //         setIsOpenRuta(true);
  //       },
  //     },
  //   ];

  //   return _items;
  // };

  // const generateItemBarAsignado = () => {
  //   const _items: ICommandBarItemProps[] = [
  //     {
  //       key: "Reload",
  //       text: "Recargar",
  //       iconProps: { iconName: "Sync" },
  //       onClick: () => {
  //         reloadAsignado();
  //       },
  //     },
  //   ];
  //   return _items;
  // };

  /* Event */
  const _onChangeTab = (
    item?: PivotItem,
    ev?: React.MouseEvent<HTMLElement>
  ) => {
    if (item != undefined) {
      let { itemKey } = item.props;

      if (itemKey != undefined) {
        setTabIndex(itemKey);
      }
    }
  };

  // const onChangeBus = (
  //   event: React.FormEvent<IComboBox>,
  //   option?: IComboBoxOption,
  //   index?: number,
  //   value?: string
  // ) => {
  //   if (event != undefined) {
  //     if (option != undefined) {
  //       setFilter({
  //         ...filter,
  //         transporteId: option.key.toString(),
  //       });
  //     }
  //   }
  // };

  /* Event Modal */
  // const onChangeModalBus = (
  //   event: React.FormEvent<IComboBox>,
  //   option?: IComboBoxOption,
  //   index?: number,
  //   value?: string
  // ) => {
  //   if (event != undefined) {
  //     if (option != undefined) {
  //       setModelRuta({
  //         ...modelRuta,
  //         transporteId: option.key.toString(),
  //       });
  //     }
  //   }
  // };

  // const onChangeModalParadero = (
  //   event: React.FormEvent<IComboBox>,
  //   option?: IComboBoxOption,
  //   index?: number,
  //   value?: string
  // ) => {
  //   if (event != undefined) {
  //     if (option != undefined) {
  //       setModelRuta({
  //         ...modelRuta,
  //         rutaId: option.key.toString(),
  //       });
  //     }
  //   }
  // };

  /* Editar ruta */
  const onChangeParaderoEdit = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setFormEditRuta({
          ...formEditRuta,
          rutaId: option.key.toString(),
        });
      }
    }
  };

  /* Event Eliminar Transporte */
  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "motivoEliminar":
            console.log(option);
            setFormEliminar({
              ...formEliminar,
              motivoEliminacionId: String(option.key),
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const _onChangeText = (
    type: string,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        switch (type) {
          case "comentario":
            setFormEliminar({
              ...formEliminar,
              comentario: newValue,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  /* Event Campamento */
  // const _onUpCampamento = (e?: React.KeyboardEvent<IComboBox>) => {
  //   _openOptionCampamento();
  //   let { value } = e?.target as HTMLInputElement;
  //   /* setmodel({
  //       ...model,
  //       ubicacionCampamentoId: ''
  //   }) */
  //   setfilterCampamento({
  //     ...filterCampamento,
  //     search: value,
  //   })
  // }

  // const _onChangeCampamento = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
  //   if (event != undefined) {
  //     if (option != undefined) {
  //       setFilter({
  //         ...filter,
  //         campamentoId: option.key.toString(),
  //       })
  //     }
  //   }
  // }

  // const _onBlurCampamento = () => {
  //   if (filter.campamentoId == '' || filter.campamentoId == undefined) {
  //     setFilter({
  //       ...filter,
  //       search: '',
  //     })
  //   }
  // }

  /* Event Tab2 - Persona transporte */
  // const clearComboPersonaTransporte = (type: string) => {
  //   switch (type) {
  //     case 'bus':
  //       setFilterPersonaTransporte({
  //         ...filterPersonaTransporte,
  //         busId: null
  //       });
  //       break;
  //     case 'destino':
  //       setFilterPersonaTransporte({
  //         ...filterPersonaTransporte,
  //         destinoId: null
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // const onChageComboPersonaTransporte = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
  //   if (event != undefined) {
  //     if (option != undefined) {
  //       let isNone = option.key == IndexNone;

  //       if (isNone) {
  //         clearComboPersonaTransporte(type);
  //       }

  //       switch (type) {
  //         case 'bus':
  //           if (!isNone) {
  //             setFilterPersonaTransporte({
  //               ...filterPersonaTransporte,
  //               busId: option.key.toString(),
  //             })
  //           }
  //           break;
  //         case 'destino':
  //           if (!isNone) {
  //             setFilterPersonaTransporte({
  //               ...filterPersonaTransporte,
  //               destinoId: option.key.toString(),
  //             })
  //           }
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   }
  // }

  /* Event Tab */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: IFilterPersonaTransporte = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      }

      setFilter(f);
      setCurrentPage(page);

      // reloadInitial(f);
    }
  }

  /* Footer Panel */
  // const onRenderFooterContent = React.useCallback(
  //   () => (
  //     <div>
  //       <PrimaryButton onClick={asignar} styles={ThemeBase.panelButtonStyle}>
  //         Confirmar
  //       </PrimaryButton>
  //       <DefaultButton onClick={dismissPanel}>Cancelar</DefaultButton>
  //     </div>
  //   ),
  //   [dismissPanel, sel]
  // );

  /* Footer Telerik */
  // const onRenderFooterTelerik = React.useCallback(
  //   () => (
  //     <div>
  //       <DefaultButton onClick={dismissTelerik}>Cancelar</DefaultButton>
  //     </div>
  //   ),
  //   [dismissTelerik, selAccesoBusId]
  // );

  return (
    <>
      <HeadingBar
        title={`${programacion ? programacion.titulo : ''}`}
        subTitle={`${programacion ? programacion.subTitle : ''}`}
        searchShow={false}
        itemBar={_leftBar()}
      ></HeadingBar>

      <div style={{ padding: "20px" }}>
        <Pivot
          aria-label="Basic Pivot Example"
          selectedKey={tabIndex}
          onLinkClick={_onChangeTab}
        >
          <PivotItem itemKey='0' headerText='Asignación Transporte'>
            <ListarTransporteAsignado programacionId={id}></ListarTransporteAsignado>
          </PivotItem>

          {(isAdmin || isEmpresaTransportista) &&
            <PivotItem itemKey='2' headerText='Buses'>
              <TabAsignacionBus programacionId={id} isBajada={programacion ? programacion.isBajada : false}></TabAsignacionBus>
            </PivotItem>
          }

          {
            isShowTabHelo &&
            <PivotItem itemKey='5' headerText='Agencia Viaje'>
              <TabAccesoPersonaVuelo ref={childRef} programacionId={id as string} isSubida={programacion ? !programacion.isBajada : false} ></TabAccesoPersonaVuelo>
            </PivotItem>
          }

          {(isAdmin || (isEmpresaVuelo)) &&
            <PivotItem itemKey='4' headerText='Helos'>
              <TabAsignacionVuelo programacionId={id} isBajada={programacion ? programacion.isBajada : false}></TabAsignacionVuelo>
            </PivotItem>
          }
        </Pivot>
      </div>

      {/* <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        headerText="Asignar"
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <div>
          {sel.map((item: { key: string | number }) => (
            <ActivityItem
              {...item}
              key={item.key}
              className={ThemeBase.activityClass.exampleRoot}
            />
          ))}
        </div>
      </Panel> */}

      {/* Modal Transporte Ruta */}
      {/* <Modal
        isOpen={isOpenRuta}
        onDismiss={() => {
          setIsOpenRuta(false);
        }}
        isBlocking={false}
        containerClassName={modalStyle.container}
      >
        <form>
          <div className={modalStyle.header}>
            <span id={""}>Asignar nuevo Vehiculo</span>
            <IconButton
              styles={modalIconButtonStyles}
              iconProps={modalCancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => {
                setIsOpenRuta(false);
              }}
            />
          </div>

          <div className={modalStyle.body} style={{ minWidth: 500 }}>
            <Stack tokens={stackTokens}>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <ComboBox
                      label="Transporte"
                      autoComplete="off"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      options={bus}
                      onChange={onChangeModalBus}
                      required
                    />
                  </div>
                </div>
              </div>

              <ButtonForm
                back={() => {
                  setIsOpenRuta(false);
                }}
                register={asignarVehiculo}
              ></ButtonForm>
            </Stack>
          </div>
        </form>
      </Modal> */}

      {/* Modal Transporte - editar ruta */}
      {/* <Modal
        isOpen={isEditRuta}
        onDismiss={hideEditRuta}
        isBlocking={false}
        containerClassName={modalStyle.container}
      >
        <form>
          <div className={modalStyle.header}>
            <span id={""}>Editar Ruta Transporte</span>
            <IconButton
              styles={modalIconButtonStyles}
              iconProps={modalCancelIcon}
              ariaLabel="Close popup modal"
              onClick={hideEditRuta}
            />
          </div>

          <div className={modalStyle.body} style={{ minWidth: 500 }}>
            <Stack tokens={stackTokens}>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <ComboBox
                      label="Transporte"
                      autoComplete="off"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      options={bus}
                      selectedKey={
                        formEditRuta ? formEditRuta.transporteId : ""
                      }
                      disabled
                      required
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <ComboBox
                      label="Paradero Destino"
                      autoComplete="off"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      options={ruta}
                      selectedKey={formEditRuta ? formEditRuta.rutaId : ""}
                      onChange={onChangeParaderoEdit}
                      required
                    />
                  </div>
                </div>
              </div>

              <ButtonForm
                back={hideEditRuta}
                register={actualizarRuta}
              ></ButtonForm>
            </Stack>
          </div>
        </form>
      </Modal> */}

      {/* <Modal
        isOpen={isEliminarTransporte}
        onDismiss={dismissEliminarTransporte}
        isBlocking={false}
        containerClassName={modalStyle.container}
      >
        <form>
          <div className={modalStyle.header}>
            <span id={""}>Eliminar Vehículo Asignado</span>
            <IconButton
              styles={modalIconButtonStyles}
              iconProps={modalCancelIcon}
              ariaLabel="Close popup modal"
              onClick={dismissEliminarTransporte}
            />
          </div>

          <div className={modalStyle.body} style={{ minWidth: 500 }}>
            <Stack tokens={stackTokens}>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Motivo de retiro"
                      autoComplete="off"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      options={motivoEliminacion}
                      selectedKey={
                        formEliminar ? formEliminar.motivoEliminacionId : ""
                      }
                      onChange={(e, o) =>
                        _onChangeCombo("motivoEliminar", e, o)
                      }
                      required
                    />
                  </div>

                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <TextField
                      label="Comentario"
                      placeholder="comentario"
                      value={formEliminar ? formEliminar.comentario : ""}
                      onChange={(e, o) => _onChangeText("comentario", e, o)}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>

              <ButtonForm
                back={dismissEliminarTransporte}
                register={eliminarRuta}
              ></ButtonForm>
            </Stack>
          </div>
        </form>
      </Modal> */}

      {/* DESCARGA DE REPORTE  */}
      {/* <Modal
        isOpen={isModalOpenDown}
        onDismiss={hideModalDown}
        isBlocking={false}
      >
        <Label
          style={{
            fontSize: "20pt",
            textAlign: "center",
            margin: "0 0 50px 0",
          }}
        >
          Descargando Reporte
        </Label>
        <Spinner
          label="Enviando datos excel"
          style={{
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
          size={SpinnerSize.large}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 20,
            minWidth: 450,
          }}
        >
          <TooltipHost content="Cancelar descarga">
            <PrimaryButton
              text="Cancelar"
              iconProps={{
                iconName: "DrillDownSolid",
                style: { fontSize: "12pt" },
              }}
              onClick={hideModalDown}
            />
          </TooltipHost>
        </div>
      </Modal> */}

      {/* Panel - Reporte - Telerik */}
      <RepoteBase
        headerName="Manifiesto"
        NameReport="MOVReporteManifiesto.trdx"
        Parameters={{
          //   ProgramacionBusId: `${selAccesoBusId}`,
          ProgramacionBusId: selAccesoBusId,
        }}
        openTelerik={isOpenTelerik}
        dismissTelerik={dismissTelerik}
      />

      {/* Panel - Reporte - Telerik */}
      <RepoteBase
        headerName={`Personas asignadas`}
        NameReport="AccesoPersonaTransporte.trdx"
        Parameters={{ ProgramacionId: id }}
        openTelerik={isOpenAccesoPersona}
        dismissTelerik={dismissAccesoPersona}
      ></RepoteBase>

    </>
  );
};
