import { createTheme, FontSizes, getTheme, IComboBoxStyles, ILabelStyleProps, ILabelStyles, IStackStyles, IStackTokens, ITextFieldStyles, ITheme, mergeStyleSets, NeutralColors } from "@fluentui/react";
import { CSSProperties } from "styled-components";

const theme = getTheme();

const themeTitle: ITheme = createTheme({
    fonts: {
        medium: {
            fontSize: '17px',
        },
    },
});

const classes = mergeStyleSets({
    icon: {
        fontSize: 50,
        height: 50,
        width: 50,
        margin: '0 25px',
    },
    iconFont: {
        padding: 0,
        fontSize: "16px",
        marginRight: 5,
    },
    base: {
        padding: '20px'
    },
    cardIcon: {
        marginBottom: '16px',
        height: 32,
        width: 32,
        fontSize: 32,
        color: theme.palette.themePrimary,
    },
});

const notPaddingSide: CSSProperties = {
    paddingRight: '0px',
    paddingLeft: '0px',
}

const notPaddingTableBase: CSSProperties = {
    paddingTop: '10px',
    ...notPaddingSide,
}

const stackTokens = { childrenGap: 10 };

const panelButtonStyle = { root: { marginRight: 8 } };

/* Table */
function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
    return defaultRender({
        ...headerProps,
        styles: {
            root: {
                paddingTop: '0px',
                textAlign: ''
            },
        },
    })
}

/* ComboBox inside CommandBar */
const notPaddingTopCombo: CSSProperties = {
    paddingTop: '6px',
}

/* Activity style - panel - list personas */
const activityClass = mergeStyleSets({
    exampleRoot: {
        marginTop: "20px",
    },
    nameText: {
        fontWeight: "bold",
    },
})

/**Estilo para ComboBox*/
const comboBoxStyles: Partial<IComboBoxStyles> = {
    label: {
        fontSize: FontSizes.size14,
        color: NeutralColors.gray140,
        // fontFamily: 'Arial,regular, Helvetica, sans-serif',
        // fontWeight: 'Semibold',
    }
};

const getLabelStyles = (props: ILabelStyleProps): ILabelStyles => {
    return {
        root: {
            fontSize: FontSizes.size14,
            color: NeutralColors.gray140,
            // fontFamily: 'Arial,regular, Helvetica, sans-serif',
            // fontWeight: 'Semibold'
        },
    };
}

const textFileStyles: Partial<ITextFieldStyles> = {
    subComponentStyles: {
        label: getLabelStyles,
    }
};

const telerikStyle: CSSProperties = {
    position: 'relative',
    width: '100%',
    height: '85vh',
    // position: 'relative',
    // left: '20%',
    // right: '1px',
    // top: '140px',
    // bottom: '10px',
    // overflow: 'auto',
    // clear: 'both',
    fontFamily: 'ms sans serif'
}

/* Login button style */
const buttonSesionLogin: CSSProperties = {
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: '2px',
    padding: '5px',
    border: '1px solid transparent',
    fontSize: '14px',
    width: '100px',
    alignItems: 'center',
    boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px'
}

const alignLeft: CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end'
}

const checkBoxStyles = {
    text: {
        fontWeight: "bold",
        /* color: '#4676DD', */
    },
    root: {
        paddingTop: '5px',
    }
};

const comboBoxStylesDos = {
    root: {
        height: '27px',
    }
}

const justifiedStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center'
}

const DangerButtonTheme: ITheme = createTheme({
    palette: {
        white: theme.palette.red,
        neutralPrimary: '#fff',
    },
})

const buttonHome = {
    root: {
        color: 'white', backgroundColor: theme.palette.themeDark
    },
    rootHovered: { color: theme.palette.themeDark },
};


const parentFlexEnd: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
}

const icon: CSSProperties = {
    cursor: 'pointer'
}

const iconRemove: CSSProperties = {
    ...icon,
    color: 'rgb(209, 52, 56)',
}

const iconEdit: CSSProperties = {
    ...icon,
    color: theme.palette.yellowDark,
}

/* Estado */
const estadoStyle: CSSProperties = {
    alignItems: 'center',
    background: '#ffff',
    color: getTheme().palette.neutralSecondary,
    display: 'flex',
    height: 15,
    paddingLeft: '5px',
    justifyContent: 'left',
    width: 70,
}

const estadoStyleDos: CSSProperties = {
    ...estadoStyle,
    width: 90,
}

const estadoStackStyle: IStackStyles = {
    root: {
      background: '#ffff',
      width: 100,
    },
  };

  const estadoStackToken: IStackTokens = {
    childrenGap: 10,
    padding: 5,
  };

const ThemeBase = {
    theme,
    themeTitle,
    classes,
    stackTokens,
    notPaddingTableBase,
    notPaddingSide,
    panelButtonStyle,
    /* Table */
    _onRenderDetailsHeader,

    /* Activity */
    activityClass,

    /* Combo inside CommandBar */
    notPaddingTopCombo,

    /*ComboBox estilos*/
    comboBoxStyles,
    textFileStyles,

    /**Estilo Telerik*/
    telerikStyle,

    /* Style Button sesion login */
    buttonSesionLogin,
    alignLeft,
    justifiedStyle,

    /* CheckBox title */
    checkBoxStyles,

    /* Altura min combo */
    comboBoxStylesDos,

    // Button Theme
    DangerButtonTheme,

    // Button Home v2
    buttonHome,
    parentFlexEnd,

    /* Icon */
    icon,
    iconEdit,
    iconRemove,

    /* Estado */
    estadoStyle,
    estadoStyleDos,
    estadoStackStyle,
    estadoStackToken,
}

export default ThemeBase;