import { Checkbox, Panel, PanelType, ProgressIndicator, Stack } from "@fluentui/react"
import React, { useEffect, useState } from "react";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import { CustomMessageBar, typeMessage } from "../../../components/MessageBar/customMessageBar";
import { ICheckItemComboBaseDTO } from "../../../interfaces/base/IBase";
import { IAsignarTransporteMasivo, IGrupoCategoriaTransporte, IGrupoTransporte } from "../../../interfaces/IProgramacionBus";
import { IItemDetalleLevel } from "../../../interfaces/ITemporada";
import ProgramacionBusService from "../../../services/programacionBusService";
import { splitExceptionDetail, stackTokens } from "../../../utils/Helper/helper";
import ThemeBase from "../../../utils/Theme/themeBase";

export const AsignacionMultipleTransporte = ({ programacionId = '', isOpen = false, dissmiss = () => { }, reloadInitial = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Form */
    const [detalle, setDetalle] = useState<IGrupoTransporte>(null!);
    const [form, setForm] = useState<IAsignarTransporteMasivo>(null!);

    /* Callback */
    const refForm = React.useCallback(() => {
        resetChoice();
    }, [dissmiss])

    /* Hook */
    useEffect(() => {
        if (isOpen) {
            if (programacionId != '') {
                dataGrupoTransporte();
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (detalle) {
            let current: ICheckItemComboBaseDTO[] = [];

            detalle?.categoria.map(n => {
                n.transporte.map(l => {
                    current = [...current, l];
                })
            })

            setForm({
                ...form,
                programacionId: programacionId,
                lista: current.filter(x => x.isChecked).map(x => x.key),
            });
        }
    }, [detalle]);

    /* Api */
    const dataGrupoTransporte = () => {
        ProgramacionBusService.grupoTransporte(programacionId)
            .then((res) => {
                if (res.status == 200) {
                    setDetalle(res.data);
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
            });
    }

    const asignar = () => {
        if (programacionId != '') {
            setIsSend(true);
            setChoice(undefined);

            ProgramacionBusService.asignarTransporteMasivo(form)
                .then((res) => {
                    setIsSend(false)
                    if (res.status == 200) {
                        reloadInitial();
                        setChoice('send');
                    }
                })
                .catch((e) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    }

    /* Event */
    const _onChangeAllCategoria = (categoriaId: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        /* {
            "categoria": [
                {
                    "categoriaId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    "nombre": "string",
                    "transporte": [
                        {
                            "key": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                            "text": "string",
                            "isChecked": true
                        }
                    ]
                }
            ]
        } */

        if (isChecked != undefined) {
            let c = detalle.categoria;

            let t = c.map(cat => {
                let categoriaTemp = cat;

                if (categoriaTemp.categoriaId == categoriaId) {
                    let transporteTemp = categoriaTemp.transporte.map(tra => {
                        return ({
                            ...tra,
                            isChecked: isChecked
                        })
                    })

                    categoriaTemp = {
                        ...cat,
                        transporte: transporteTemp
                    }
                }

                return categoriaTemp;
            })

            setDetalle({
                ...detalle,
                categoria: t
            })
        }
    }

    const _onChangeTransporte = (categoriaId: string, transporteId: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
        if (isChecked != undefined) {
            let c = detalle.categoria;

            let t = c.map(cat => {
                let categoriaTemp = cat;

                if (categoriaTemp.categoriaId == categoriaId) {
                    let transporteTemp = categoriaTemp.transporte.map(tra => {
                        if (tra.key == transporteId) {
                            return ({
                                ...tra,
                                isChecked: isChecked
                            })
                        } else {
                            return tra;
                        }
                    })

                    categoriaTemp = {
                        ...cat,
                        transporte: transporteTemp
                    }
                }

                return categoriaTemp;
            })

            setDetalle({
                ...detalle,
                categoria: t
            })
        }
    }

    /* Footer Panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <ButtonForm register={asignar} textRegister='Asignar' back={dissmiss} disabledRegister={isSend}></ButtonForm>
            </div>
        ),
        [isSend, programacionId, isOpen, detalle, dissmiss, form]
    );

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dissmiss}
                type={PanelType.medium}
                headerText='Asignación Transporte'
                closeButtonAriaLabel='Close'
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                {/* <pre>{JSON.stringify(detalle, null, 2)}</pre> */}
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                            <ProgressIndicator description="Enviando espere un momento" barHeight={3} />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                            {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                            {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                refForm()
                                resetChoice()
                                dataGrupoTransporte()
                            }}></CustomMessageBar>}
                        </div>

                        <form ref={refForm} hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                            <div className="ms-Grid-row" style={{ paddingTop: '5%' }}>
                                {detalle?.categoria.map(function (i: IGrupoCategoriaTransporte, index) {
                                    return <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <Checkbox label={i.nombre} boxSide='start' onChange={(e, o) => _onChangeAllCategoria(i.categoriaId, e, o)} checked={i.isAll} styles={ThemeBase.checkBoxStyles} />
                                        <br />

                                        <Stack tokens={stackTokens}>
                                            {i.transporte.map(function (l: ICheckItemComboBaseDTO, j) {
                                                return <Checkbox id={l.key} key={l.key} label={`${j + 1}. ${l.text}`} onChange={(e, o) => _onChangeTransporte(i.categoriaId, l.key, e, o)} checked={l.isChecked} />
                                            })}
                                        </Stack>
                                    </div>
                                })}
                            </div>
                        </form>
                    </div>
                </div>
            </Panel>
        </>
    )
}