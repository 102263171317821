import { Ifilter } from "../interfaces/IFilter";
import { ICreateTemporada, IListarPersonaEsHelo, IUpdateTemporada } from "../interfaces/ITemporada";
import { fetchMovilizacion } from "./http-common";

const urlTemporada = 'Temporada';
const urlDetalleNivelLevel = 'DetalleNivelLevel';
const urlMes = 'Mes';

const all = (filter: Ifilter) => {
    return fetchMovilizacion.get(`${urlTemporada}`, { params: filter });
}

const allPersonaByTemporadaLevel = (programacionId: string, filter: Ifilter) => {
    return fetchMovilizacion.get(`${urlTemporada}/Persona/${programacionId}`, { params: filter });
}

const detalleNivelLevel = () => {
    return fetchMovilizacion.get(`${urlDetalleNivelLevel}`);
}

const crearTemporada = (data: ICreateTemporada) => {
    return fetchMovilizacion.post(`${urlTemporada}`, data);
}

const actualizarTemporada = (data: IUpdateTemporada) => {
    return fetchMovilizacion.put(`${urlTemporada}`, data);
}

const eliminarTemporada = (id: string) => {
    return fetchMovilizacion.delete(`${urlTemporada}/${id}`);
}

/* Info Temporada / psGroup & level */
const detalleTemporada = (id: string) => {
    return fetchMovilizacion.get(`${urlTemporada}/${id}`);
}

/* Listar personas - info si pertenece a Helo segun la fecha de la movilizacion */
const listarPersonaInfoHelo = (data: IListarPersonaEsHelo) => {
    return fetchMovilizacion.post(`${urlTemporada}/ListarPersonaPorId`, data);
}

const comboMeses = (search: string) => {
    return fetchMovilizacion.get(`${urlMes}/ComboMeses?search=${search}`);
}

const TemporadaService = {
    all,
    allPersonaByTemporadaLevel,
    detalleNivelLevel,
    crearTemporada,
    actualizarTemporada,
    eliminarTemporada,
    detalleTemporada,

    /* Persona - Info Helo */
    listarPersonaInfoHelo,
    comboMeses
}

export default TemporadaService;