import { DefaultButton, FontSizes, NeutralColors, Panel, PrimaryButton, Stack } from "@fluentui/react";
import React from "react";
import { IPanel } from "../../interfaces/base/IPanel";
import ThemeBase from "../../utils/Theme/themeBase";

const stackTokens = { childrenGap: 10 };

export function PanelPlantilla({
    textHeader = '',
    subTextHeader = '',
    textBoton = '',
    onclickBoton,
    openPanel,
    closePanel,
    size,
    disabledBoton,
    estadoBarraEnvio,
    children

}: IPanel) {
    const onRenderHeader = React.useCallback(
        () => (
            <>

                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg11" style={{ paddingLeft: '2em' }} >
                    {/* <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg1">
                        <FontIcon style={{ fontSize: 12, color: "#0078d4" }} iconName="Add" />
                    </div> */}
                    <div className="LayoutPage-demoBlock">
                        <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size16 }}>{textHeader}</div>
                    </div>
                    <div className="LayoutPage-demoBlock">
                        <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>{subTextHeader}</div>
                    </div>
                    <br />

                </div>

            </>
        ),
        [openPanel],
    );

    const onRenderFooter = React.useCallback(
        () => (
            <>

                {estadoBarraEnvio == false && (
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12" style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginLeft: '12px'
                            }}>
                                <Stack horizontal tokens={stackTokens}>
                                    <div>
                                        <DefaultButton onClick={closePanel} style={{ marginRight: '12px' }}>Cancelar</DefaultButton>
                                        <PrimaryButton
                                            onClick={onclickBoton}
                                            styles={ThemeBase.panelButtonStyle}
                                            text={textBoton}
                                            // primaryDisabled 
                                            disabled={disabledBoton}
                                        />

                                    </div>

                                </Stack>
                            </div>
                        </div>
                    </div>

                )}
            </>
        ),
        [textBoton, closePanel, onclickBoton],
    );

    return (
        <>
            <div>
                <Panel
                    isOpen={openPanel}
                    onDismiss={closePanel}
                    onRenderHeader={onRenderHeader}
                    type={size}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={onRenderFooter}
                    isFooterAtBottom={true}
                    isLightDismiss
                >

                    {children}


                </Panel>
            </div>

        </>
    )
}
