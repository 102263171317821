import { Panel, ActivityItem, PrimaryButton, Stack, ProgressIndicator } from "@fluentui/react"
import React, { useState } from "react";
import { CustomMessageBar, typeMessage } from "../../../../components/MessageBar/customMessageBar";
import { IValidarMasivo } from "../../../../interfaces/IProgramacion";
import ProgramacionPersonaService from "../../../../services/programacionPersonaService";
import { splitExceptionDetail, stackTokens } from "../../../../utils/Helper/helper";
import { MessageHelper } from "../../../../utils/Helper/messageHelper";
import ThemeBase from "../../../../utils/Theme/themeBase"

let _sel: any[] = [];

export const ValidarAccesoVuelo = ({ programacionId = '', isOpen = false, dismiss = () => { }, sel = _sel, reloadParent = () => { } }) => {
    /* Message Error & Choice */
    const [isSend, setIsSend] = useState(false);
    const [choice, setChoice] = React.useState<string | undefined>(undefined);
    const resetChoice = React.useCallback(() => setChoice(undefined), []);
    const [textError, settextError] = useState('Error');

    /* Api */
    const validar = () => {
        if (programacionId != '') {
            setIsSend(true);
            setChoice(undefined);

            let t: IValidarMasivo = {
                lista: sel.map((x) => x.programacionPersonaId),
                programacionId: programacionId
            };

            ProgramacionPersonaService.validarVuelo(t)
                .then((res) => {
                    setIsSend(false)
                    if (res.status == 200) {
                        reloadParent();
                        setChoice('');
                        dismiss();
                    }
                })
                .catch((e) => {
                    setIsSend(false)
                    setChoice('error')
                    settextError(splitExceptionDetail(e))
                });
        }
    };

    /* Footer panel */
    const onRenderFooter = React.useCallback(
        () => (
            <div>
                <Stack tokens={stackTokens}>
                    <PrimaryButton onClick={validar} styles={ThemeBase.panelButtonStyle} disabled={isSend}>
                        Validar
                    </PrimaryButton>
                </Stack>
            </div>
        ),
        [programacionId, isOpen, dismiss, sel, isSend, reloadParent]
    );

    return (
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dismiss}
                headerText='Pasar a Agencia de Viaje'
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
            >
                <>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
                                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
                            </div>

                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                                {(choice === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textError} dissmiss={resetChoice}></CustomMessageBar>}
                                {(choice === 'send') && <CustomMessageBar dissmiss={() => {
                                    setChoice('');
                                    dismiss();
                                }}></CustomMessageBar>}
                            </div>
                        </div>
                    </div>

                    <form hidden={(choice === 'error' || choice === 'send' || isSend) ? true : false}>
                        {sel.map((item: { key: string | number }) => (
                            <ActivityItem
                                {...item}
                                key={item.key}
                                className={ThemeBase.activityClass.exampleRoot}
                            />
                        ))}
                    </form>

                </>
            </Panel>
        </>
    )
}