import React from "react";
import { Dialog, Icon, DialogFooter, PrimaryButton, DefaultButton, getTheme, IDialogContentProps, ActivityItem, FontIcon, FontSizes } from '@fluentui/react';
import { ITheme } from '@fluentui/react/lib/Styling';
import { NeutralColors } from '@fluentui/theme';

const theme = getTheme();

const _hideDialog: boolean = true;
const _toggleHideDialog = () => { };
const _title: string = "";
const _subText: string = "";
const _iconName: string | undefined = "";
const _onclickButtonPrimary = (param) => { };
const _nameButtonPrimary: string = "";
const _onclickButtonDefault = () => { };
const _nameButtonDefault: string = "";


const DialogBaseSuccess = ({
    hideDialog = _hideDialog,
    toggleHideDialog = _toggleHideDialog,
    title = _title,
    subText = _subText,
    // iconName = _iconName,
    // onclickButtonPrimary = (param: any) => _onclickButtonPrimary(parametro)
    onclickButtonPrimary = (parametro) => _onclickButtonPrimary(parametro)
}) => {

    let param: string = "";
    const myElement = <div>
        <ActivityItem
            style={{
                marginTop: 3
            }}
            activityDescription={[
                <div>
                    <div className="LayoutPage-demoBlock">
                        <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size14 }}> <strong>¡Correcto!</strong></div>
                        <br />
                        <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>
                            {subText}</div>
                    </div>
                </div>

            ]}
            activityIcon={
                <FontIcon
                    // iconName="Accept"
                    iconName="CheckMark"
                    style={{
                        color: theme.palette.green,
                        fontSize: 35,
                        height: 35,
                        width: 35,
                        marginTop: 35
                        // margin: "0 5px",

                    }}
                />
            }
        // comments={[
        //     <div className="LayoutPage-demoBlock">
        //         <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>Edite datos de la persona: {info?.persona}</div>
        //         <br />
        //         <br />
        //     </div>
        // ]}
        // timeStamp={}
        // className={ThemeBase.activityClass.exampleRoot}
        />
    </div>

    return (
        <div>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={{
                    title: myElement,
                    // title: "Proceso Correcto",
                    // subText: subText,
                }}
                styles={{
                    main: {
                        // background: "#DFF6DD",
                        background: "#fff",
                        //borderTop: `4px solid ${theme.palette.themePrimary}`,
                    }
                }}
            >
                {/* <div style={{ width: "100%" }}>
                    <div
                        style={{
                            width: "42px",
                            height: "42px",
                            margin: "0 auto",
                            marginBottom: "30px"
                        }}
                    >
                        <Icon
                            // iconName={iconName}
                            iconName="Accept"
                            style={{
                                color: theme.palette.themePrimary,
                            }}
                            className="ms-fontSize-42"
                        ></Icon>
                    </div>
                </div> */}
                <DialogFooter styles={{ actions: { marginTop: "40px" } }}>
                    <PrimaryButton
                        onClick={() => {
                            toggleHideDialog();
                            // onclickButtonPrimary(param);
                        }}
                        text="Aceptar"
                        style={{ height: 25 }}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export { DialogBaseSuccess };